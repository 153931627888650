import React, { useRef, useState } from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactMarkdown from "react-markdown";
import { gql, useMutation } from "urql";

import { Label } from "../../../../components/src/form";

const imageMimeTypes = ["image/png", "image/jpeg", "image/gif"];

const mdParser = new MarkdownIt();

const CREATE_ATTACHMENT = gql`
    mutation createAttachment($clientId: GraphQLObjectId!, $input: CreateAttachmentInput!) {
        createAttachment(clientId: $clientId, input: $input) {
            fileId
            fileName
            mimeType
            mD5
            updateTimestamp
        }
    }
`;

const readFile = (inputFile) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject();
        };

        reader.onload = async (event) => {
            const dataUri = event.target.result as string;
            const base64 = dataUri.split(",", 2)[1];
            const result = {
                fileName: inputFile.name.replace(" ", "-"),
                base64
            };
            resolve(result);
        };
        reader.readAsDataURL(inputFile);
    });
};

type MarkDownFieldPropTypes = FieldHookConfig<string> & {
    graphqlApiBaseUrl: string;
    label: string;
    className?: string;
    initialEditMode?: boolean;
    height?: number;
    style?: React.CSSProperties;
    useAttachment?: boolean;
};

export function MarkDownField({
    graphqlApiBaseUrl,
    label,
    className,
    height,
    style,
    initialEditMode,
    useAttachment = false,
    ...props
}: MarkDownFieldPropTypes): React.ReactElement {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const [edit, setEdit] = useState(typeof initialEditMode === "undefined" ? false : initialEditMode);
    const [_, createAttachment] = useMutation(CREATE_ATTACHMENT);
    const editorRef = useRef(null);

    const customStyle = style || {};
    if (height) {
        customStyle.height = `${height}px`;
    }

    const uploadFiles = (files): Promise<string> => {
        return Promise.all(files).then(async (files) => {
            const result: string[] = [];
            for (let i = 0; i < files.length; i++) {
                await createAttachment({ clientId: "000000000000000000000000", input: files[i] })
                    .then(({ data }) => {
                        console.log(data);
                        const isImage = imageMimeTypes.includes(data.createAttachment.mimeType);
                        const mdUrl = isImage
                            ? `![${data.createAttachment.fileName}](${graphqlApiBaseUrl}/public/api/images/${data.createAttachment.fileId})`
                            : `[${data.createAttachment.fileName}](${window.location.origin}/files/${data.createAttachment.fileId})`;
                        result.push(mdUrl);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            return result.join("\n");
        });
    };

    const handleDropEvent = async (e: any) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files].map(async (f) => await readFile(f));
        editorRef.current.insertPlaceholder("[uploading...]", uploadFiles(files));
    };

    async function onImageUpload(data) {
        const file = await readFile(data);
        return await createAttachment({ clientId: "000000000000000000000000", input: file }).then(({ data }) => {
            return `${graphqlApiBaseUrl}/public/api/images/${data.createAttachment.fileId}`;
        });
    }

    const handleClick = (event) => {
        event.preventDefault();
        // a little bit heuristic approach, since markdown does not implement 'target' attribute
        const href = event.target.attributes["href"];
        if (href) {
            window.open(href.value, "_blank");
        }
    };

    const renderMdEditor = () => {
        return (
            <MdEditor
                ref={editorRef}
                view={{ menu: true, md: true, html: false }}
                name={field.name}
                value={field.value || ""}
                renderHTML={(text) => mdParser.render(text)}
                onChange={({ text }) => {
                    setFieldValue(field.name, text, true);
                }}
                style={customStyle}
                onImageUpload={useAttachment ? onImageUpload : undefined}
            />
        );
    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <div
                onClick={() => {
                    if (edit) {
                        setFieldValue(field.name, field.value);
                        setEdit(false);
                    } else {
                        setEdit(true);
                    }
                }}
            >
                <Label name={field.name} value={label} />
            </div>
            {edit ? (
                useAttachment ? (
                    <div onDrop={handleDropEvent} onClick={handleClick}>
                        {renderMdEditor()}
                    </div>
                ) : (
                    renderMdEditor()
                )
            ) : (
                <div className="markdown-container">
                    <React.Fragment>
                        <ReactMarkdown>{field.value || ""}</ReactMarkdown>
                    </React.Fragment>
                </div>
            )}
            {meta.error ? <div className="form-field-error">{meta.error}</div> : null}
        </div>
    );
}
