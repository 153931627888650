/* eslint-disable max-len */
import React from "react";

interface RiskLevelChartProps {
    value: number;
}

export class RiskLevelChart extends React.Component<RiskLevelChartProps> {
    static volatilityToRiskLevel(v) {
        if (v < 0.005) return 1;
        if (v < 0.02) return 2;
        if (v < 0.05) return 3;
        if (v < 0.1) return 4;
        if (v < 0.15) return 5;
        if (v < 0.25) return 6;
        return 7;
    }
    render() {
        const { value } = this.props;
        return (
            <div className="risk-level-chart">
                <div className="risk">
                    <span className="left">Lägre risk</span>
                    <span className="right">Högre risk</span>
                    <svg viewBox="0 0 280 21">
                        <g transform="translate(1,10.5)">
                            <path d="M0,0m10.50,-8.81l-10.50,8.81m10.50,8.81l-10.50,-8.81m0.00,0.00H278.00m-10.50,-8.81l10.50,8.81m-10.50,8.81l10.50,-8.81" />
                        </g>
                    </svg>
                </div>
                <table>
                    <tbody>
                        <tr>
                            {[...Array(7)].map((d, i) => (
                                <td key={i}>
                                    {i + 1 !== value ? (
                                        i + 1
                                    ) : (
                                        <span
                                            className={
                                                "marked" + (i === 0 ? " wide" : i === 2 || i === 3 || i === 4 || i === 5 ? " narrow" : "")
                                            }
                                        >
                                            {i + 1}
                                        </span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <div className="return">
                    <span className="left">Lägre möjlig avkastning</span>
                    <span className="right">Högre möjlig avkastning</span>
                </div>
            </div>
        );
    }
}
