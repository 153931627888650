import React, { useMemo } from "react";
import { useQuery, gql } from "urql";
import { cloneDeep, sortBy } from "lodash";
import { Link } from "react-router-dom";
import { SelectColumnFilter } from "../../../components/src/react-table/ReactTableFilters";
import { ReactTable } from "../../../components/src/react-table/ReactTable";

const getScreens = gql`
    query {
        screens {
            _id
            screenType
            comment
            date
            objectId
            approved
            clientId
            client {
                name
                _id
            }
            issuerProgram {
                _id
                name
                category
                spoProvider {
                    name
                    _id
                }
            }
            issuer {
                _id
                name
                issuer {
                    esg {
                        globalStandardScreen {
                            norm
                            date
                            status
                            principle
                            comment
                        }
                        productInvolvement {
                            range
                            involvement
                        }
                    }
                }
            }
            updateTimestamp
            updateUserInfo {
                name
            }
        }
    }
`;
interface Props {
    screens: any[];
}

export const Screens = ({ screens }: Props): React.ReactElement => {
    const data: any[] = sortBy(cloneDeep(screens), "updateTimestamp").reverse();

    data.forEach((screen) => {
        screen.updatedBy = screen.updateUserInfo.name;
        screen.dateOfChange = screen.updateTimestamp.slice(0, 10);
        screen.approved = screen.approved ? "Yes" : "No";
        let globalStandardScreenStatus;
        let globalStandardScreenDate = "1999-01-01";
        if (
            screen.issuer &&
            screen.issuer.issuer &&
            screen.issuer.issuer.esg.globalStandardScreen &&
            screen.issuer.issuer.esg.globalStandardScreen.length > 0
        ) {
            screen.issuer.issuer.esg.globalStandardScreen.forEach((screening) => {
                if (screening.date > globalStandardScreenDate) {
                    globalStandardScreenDate = screening.date;
                    globalStandardScreenStatus = screening.status;
                }
            });
        } else {
            globalStandardScreenStatus = "None";
            globalStandardScreenDate = "None";
        }
        screen.globalStandardScreenStatus = globalStandardScreenStatus;
        screen.globalStandardScreenDate = globalStandardScreenDate;
    });

    const columns = useMemo(
        () => [
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.clientId}`}>{row.original.client.name}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 75
            },
            {
                header: "Screen",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/screens/${row.original._id}`}>{"#"}</Link>;
                },
                size: 30
            },
            {
                header: "Issuer",
                id: "issuer.name",
                accessorKey: "issuer.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuer) {
                        return <Link to={`/parties/${row.original.issuer._id}`}>{row.original.issuer.name}</Link>;
                    }
                    return null;
                }
            },
            {
                header: "SPO provider",
                id: "issuerProgram.spoProvider.name",
                accessorKey: "issuerProgram.spoProvider.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerProgram && row.original.issuerProgram.spoProvider) {
                        return (
                            <Link to={`/parties/${row.original.issuerProgram.spoProvider._id}`}>
                                {row.original.issuerProgram.spoProvider.name}
                            </Link>
                        );
                    }
                    return null;
                }
            },
            {
                header: "Category",
                id: "issuerProgram.category",
                accessorKey: "issuerProgram.category",
                filter: SelectColumnFilter
            },
            {
                header: "Global standard screen",
                accessorKey: "globalStandardScreen",
                filter: null,
                columns: [
                    {
                        header: "Status",
                        accessorKey: "globalStandardScreenStatus",
                        filter: SelectColumnFilter
                    },
                    {
                        header: "Date",
                        accessorKey: "globalStandardScreenDate",
                        filter: SelectColumnFilter
                    }
                ]
            },
            {
                header: "Type",
                accessorKey: "screenType",
                filter: SelectColumnFilter
            },
            {
                header: "Issuer program",
                id: "issuerProgram.name",
                accessorKey: "issuerProgram.name",
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.issuerProgram) {
                        return <Link to={`/issuerprograms/${row.original.issuerProgram._id}`}>{row.original.issuerProgram.name}</Link>;
                    }
                    return null;
                }
            },
            {
                header: "Approved",
                accessorKey: "approved",
                filter: SelectColumnFilter
            },
            {
                header: "Product involvement",
                id: "productInvolvement",
                accessorKey: "productInvolvement",
                accessorFn: (row) =>
                    row.issuer && row.issuer.issuer && row.issuer.issuer.esg ? row.issuer.issuer.esg.productInvolvement : null,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const involvementString = {
                        R0To5: "0 - 5%",
                        R5To10: "5 - 10%",
                        R10To25: "10 - 25%",
                        R25To50: "25 - 50%",
                        R50T100: "50 - 100%"
                    };
                    const value =
                        row.original.issuer && row.original.issuer.issuer && row.original.issuer.issuer.esg
                            ? row.original.issuer.issuer.esg.productInvolvement
                            : null;
                    if (value) {
                        return value.map((i) => {
                            return (
                                <div className="row" key={i.involvement}>
                                    <div className="col-6">{i.involvement}</div>
                                    <div className="col-6">{involvementString[i.range]}</div>
                                </div>
                            );
                        });
                    } else {
                        return "";
                    }
                }
            },
            {
                header: "Date",
                accessorKey: "date",
                filter: SelectColumnFilter
            },
            {
                header: "Date of change",
                accessorKey: "dateOfChange",
                filter: SelectColumnFilter
            },
            {
                header: "Comment",
                accessorKey: "comment",
                filter: SelectColumnFilter
            },
            {
                header: "Updated by",
                accessorKey: "updatedBy",
                filter: SelectColumnFilter
            }
        ],
        []
    );

    return <ReactTable columns={columns} data={data} defaultHiddenColumns={[]} />;
};

export const ScreensPage = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getScreens, requestPolicy: "network-only" });

    if (fetching) return <p>Loading Screens</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return <Screens screens={data.screens} />;
};
