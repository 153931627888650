import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { Grid, Column } from "../../../../components/src";
import { numberFormatFun } from "../../../../common/src";

import { TooltipLabel } from "../../components/TooltipLabel";
import { usePrevious } from "../../../../components/src/common/Utils";
import { IssuerTypeEnum, TradingManagerColumn, InstrumentModelTypeEnum } from "../../types.generated";
import { DateForm } from "../../../../components/src/dateForm";
import { Alert } from "react-bootstrap";

const getTradingmanager = gql`
    query getTradingmanager($clientIds: [GraphQLObjectId!], $endDate: GraphQLDateString, $lookThrough: Boolean) {
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: $lookThrough) {
            name
            isin
            quantity
            instrumentId
            localExposure
            exposure
            issuerName
            issuerId
            issuerType
            bloombergTicker
            modelType
            maturityDate
            fxRate
            currency
            baseCurrency
            instrument {
                model {
                    legs {
                        notional
                    }
                }
                description
            }
        }
    }
`;

interface IssuerExposure {
    issuer: string;
    issuerType: IssuerTypeEnum;
    issuerId: string;
    exposure: number;
    percent: number;
    subscRed: number;
    sumRow: boolean;
}

interface ModelTypeExposure {
    modelType: string;
    exposure: number;
    percent: number;
}

interface CurrencyExposure {
    currency: string;
    percentTotal: number;
    localExposure: number;
    //exposure: number;
    percentAssets: number;
    cash: number;
    percentCash: number;
}

interface CdsExposure {
    name: string;
    instrumentId: string;
    currency: string;
    fxRate: number;
    notional: number;
    divisor: number;
    notionalPortfolioCcy: number;
    ratioToNav: number;
}

interface EsgExposure {
    cdsName: string;
    cdsInstrumentId: string;
    cdsPosition: number;

    hedgeName: string;
    hedgeInstrumentId: string;
    hedgePosition: number;
    hedgeNotional: number;

    currency: string;
    dirtyNoNames: number;
    indexNoNames: number;
    grossDirtyExposure: number;
    netDirtyExposure: number;
}

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

// Sum for each issuer
const getIssuerExposure = (data: TradingManagerColumn[]) => {
    const issuerExposure: IssuerExposure[] = [];

    const sumFxExposure = function (tradingmanager: TradingManagerColumn[], issuer_sum: string) {
        return tradingmanager
            .filter(({ issuerName }) => issuerName === issuer_sum)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };

    const sumAll = function (tradingmanager: TradingManagerColumn[]) {
        return tradingmanager.reduce(function (prev, cur) {
            return prev + cur.exposure;
        }, 0);
    };

    const nav = sumAll(data);
    let sumOver5Exposure = 0;
    const uniqueIssuersSorted = [...new Set(data.map(({ issuerName }) => issuerName))].sort();
    uniqueIssuersSorted.forEach((issuerName) => {
        const sumTemp: number = sumFxExposure(data, issuerName);
        const tempItem: TradingManagerColumn[] = data.filter((obj) => {
            return obj.issuerName === issuerName;
        });
        issuerExposure.push({
            issuer: issuerName,
            issuerId: tempItem[0].issuerId,
            issuerType: tempItem[0].issuerType,
            exposure: sumTemp,
            percent: sumTemp / nav,
            subscRed: null,
            sumRow: false
        });
        if (
            sumTemp / nav > 0.05 &&
            tempItem[0].issuerType !== IssuerTypeEnum.Government &&
            issuerName !== null &&
            tempItem[0].issuerId !== "5c46ff650a40e100016db4d4" //"IHS Markit Benchmark Administration Limited"
        ) {
            sumOver5Exposure = sumOver5Exposure + sumTemp;
        }
    });
    issuerExposure.push({
        issuer: "Sum over 5% ex gov, markit",
        issuerId: null,
        issuerType: null,
        exposure: sumOver5Exposure,
        percent: sumOver5Exposure / nav,
        subscRed: null,
        sumRow: true
    });
    return issuerExposure;
};

// Sum for model type
const getModelTypeExposure = (data: TradingManagerColumn[]) => {
    const modeTypeExposure: ModelTypeExposure[] = [];

    const sumModelTypeExposure = function (tradingmanager: TradingManagerColumn[], modelTypeSum: string) {
        return tradingmanager
            .filter(({ modelType }) => modelType === modelTypeSum)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };

    const sumAll = function (tradingmanager: TradingManagerColumn[]) {
        return tradingmanager.reduce(function (prev, cur) {
            return prev + cur.exposure;
        }, 0);
    };

    const nav = sumAll(data);
    const uniqueModelTypesSorted = [...new Set(data.map(({ modelType }) => modelType))].sort();
    uniqueModelTypesSorted.forEach((modelType) => {
        const sumTemp: number = sumModelTypeExposure(data, modelType);
        const tempItem: TradingManagerColumn[] = data.filter((obj) => {
            return obj.modelType === modelType;
        });
        modeTypeExposure.push({ modelType: tempItem[0].modelType, exposure: sumTemp, percent: sumTemp / nav });
    });
    return modeTypeExposure;
};

// Sum for currency
const getCurrencyExposure = (data: TradingManagerColumn[]) => {
    const currencyExposure: CurrencyExposure[] = [];
    const cashModel = InstrumentModelTypeEnum.Balance;

    const sumCurrencyExposure = function (tradingmanager: TradingManagerColumn[], currencySum: string) {
        return tradingmanager
            .filter(({ currency }) => currency === currencySum)
            .filter(({ modelType }) => modelType !== cashModel)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };

    const sumLocalCurrencyExposure = function (tradingmanager: TradingManagerColumn[], currencySum: string) {
        return tradingmanager
            .filter(({ currency }) => currency === currencySum)
            .filter(({ modelType }) => modelType !== cashModel)
            .reduce(function (prev, cur) {
                return prev + cur.localExposure;
            }, 0);
    };

    const sumCurrencyExposureCash = function (tradingmanager: TradingManagerColumn[], currencySum: string) {
        return tradingmanager
            .filter(({ currency }) => currency === currencySum)
            .filter(({ modelType }) => modelType === cashModel)
            .reduce(function (prev, cur) {
                return prev + cur.exposure;
            }, 0);
    };

    const sumLocalCurrencyExposureCash = function (tradingmanager: TradingManagerColumn[], currencySum: string) {
        return tradingmanager
            .filter(({ currency }) => currency === currencySum)
            .filter(({ modelType }) => modelType === cashModel)
            .reduce(function (prev, cur) {
                return prev + cur.localExposure;
            }, 0);
    };

    const sumAll = function (tradingmanager: TradingManagerColumn[]) {
        return tradingmanager.reduce(function (prev, cur) {
            return prev + cur.exposure;
        }, 0);
    };

    const nav = sumAll(data);
    const uniqueCurrenciesSorted = [...new Set(data.map(({ currency }) => currency))].sort();
    uniqueCurrenciesSorted.forEach((currency) => {
        const sumTemp: number = sumCurrencyExposure(data, currency);
        const sumTempLocal: number = sumLocalCurrencyExposure(data, currency);
        const sumTempCash: number = sumLocalCurrencyExposureCash(data, currency);
        const sumTempLocalCash: number = sumCurrencyExposureCash(data, currency);

        const tempItem: TradingManagerColumn[] = data.filter((obj) => {
            return obj.currency === currency;
        });
        currencyExposure.push({
            currency: tempItem[0].currency,
            percentTotal: (sumTemp + sumTempLocalCash) / nav,
            localExposure: sumTempLocal,
            //exposure: sumTemp,
            percentAssets: sumTemp / nav,
            cash: sumTempCash,
            percentCash: sumTempLocalCash / nav
        });
    });
    return currencyExposure;
};

// Milliseconds per day 24*60*60*1000 = 86400000
const MS_PER_DAY = 86400000;
export const yearFracActual365 = (d1: Date, d2: Date) => (d2.getTime() - d1.getTime()) / 365.0 / MS_PER_DAY;

const calculateDivisor = (maturityDate: string, referenceTenor: number) => {
    const roundedMaturityYears = Math.round(yearFracActual365(new Date(), new Date(maturityDate)));
    const divisor_hedge = referenceTenor / Math.max(roundedMaturityYears, 1);
    return divisor_hedge;
};

const getcdsIndexExposure = (data: TradingManagerColumn[], referenceTenor: number) => {
    const cdsExposure: CdsExposure[] = [];
    const esgExposure: EsgExposure[] = [];

    const sumAll = function (tradingmanager: TradingManagerColumn[]) {
        return tradingmanager.reduce(function (prev, cur) {
            return prev + cur.exposure;
        }, 0);
    };
    const nav = sumAll(data);

    let temp_sum_notional = 0;
    data.forEach((item) => {
        let igHyFlag;
        if (item.name.includes("CDX HY ") || item.name.includes("ITRX XOVER CDSI ")) {
            igHyFlag = "HY";
        } else {
            igHyFlag = "IG";
        }
        const hedgeItem = data.find(
            (x) =>
                x.modelType === InstrumentModelTypeEnum.CdsBasket && x.currency === item.currency && x.name.includes("_" + igHyFlag + "_")
        );

        if (item.modelType == InstrumentModelTypeEnum.CdsIndex) {
            const divisorNotional = calculateDivisor(item.maturityDate, referenceTenor);
            temp_sum_notional = temp_sum_notional + item.quantity * item.fxRate;
            cdsExposure.push({
                name: item.name,
                instrumentId: item.instrumentId,
                currency: item.currency,
                fxRate: item.fxRate,
                notional: item.quantity,
                divisor: divisorNotional,
                notionalPortfolioCcy: item.quantity * item.fxRate,
                ratioToNav: (item.quantity * item.fxRate) / nav
            });

            let hedgeNotional = null;
            let cdsHoldings = null;
            let indexNoNames = null;
            let grossDirtyExposure = null;
            let hedgeItemName = null;
            let hedgeItemInstrumentId = null;
            let hedgeItemQuantity = null;
            let netDirtyExposure = null;

            if (typeof hedgeItem !== "undefined") {
                const parseString = "{" + hedgeItem.instrument.description + "}";
                const instr_des = JSON.parse(parseString);
                grossDirtyExposure = (instr_des["cdsHoldings"] / instr_des["indexHoldings"]) * item.quantity;
                hedgeNotional = hedgeItem.quantity * hedgeItem.instrument.model.legs[0].notional;
                cdsHoldings = instr_des["cdsHoldings"];
                indexNoNames = instr_des["indexHoldings"];
                hedgeItemName = hedgeItem.name;
                hedgeItemInstrumentId = hedgeItem.instrumentId;
                hedgeItemQuantity = hedgeItem.quantity;
                netDirtyExposure = grossDirtyExposure - hedgeNotional;
            } else {
                hedgeItemName = "No hedge found";
            }

            esgExposure.push({
                cdsName: item.name,
                cdsInstrumentId: item.instrumentId,
                currency: item.currency,
                cdsPosition: item.quantity,

                hedgeName: hedgeItemName,
                hedgeInstrumentId: hedgeItemInstrumentId,
                hedgePosition: hedgeItemQuantity,
                hedgeNotional: hedgeNotional,

                dirtyNoNames: cdsHoldings,
                indexNoNames: indexNoNames,

                grossDirtyExposure: grossDirtyExposure,
                netDirtyExposure: netDirtyExposure
            });
        } else if (item.modelType == InstrumentModelTypeEnum.CdsBasket) {
            const divisorNotional = calculateDivisor(item.maturityDate, referenceTenor);
            const ntl_temp = item.instrument.model.legs[0].notional;
            temp_sum_notional = temp_sum_notional - (ntl_temp / divisorNotional) * item.quantity * item.fxRate;
            cdsExposure.push({
                name: item.name,
                instrumentId: item.instrumentId,
                currency: item.currency,
                fxRate: item.fxRate,
                notional: -item.quantity * ntl_temp,
                divisor: divisorNotional,
                notionalPortfolioCcy: -(ntl_temp / divisorNotional) * item.quantity * item.fxRate,
                ratioToNav: -((ntl_temp / divisorNotional) * item.quantity * item.fxRate) / nav
            });
        }
    });
    const baseCurrency = data.length === 0 ? null : data[0].baseCurrency;
    cdsExposure.push({
        name: "SUM",
        instrumentId: null,
        currency: baseCurrency,
        fxRate: null,
        notional: null,
        divisor: nav,
        notionalPortfolioCcy: temp_sum_notional,
        ratioToNav: temp_sum_notional / nav
    });
    return [cdsExposure, esgExposure];
};

const calculateCdsExposurePreview = (
    cdsExposure: CdsExposure[] | EsgExposure[],
    newTrades: number[],
    subscrValue: number,
    esgExposure: CdsExposure[] | EsgExposure[]
): CdsExposure[] => {
    // calculate CdsExposure trade preview
    const sumItem: CdsExposure = cdsExposure.find((x: CdsExposure) => x.name === "SUM") as CdsExposure;

    // early calculate divisor, since 'ratioToNav' depend on new SUM.Divisor
    const divisor = subscrValue ? sumItem.divisor + subscrValue * 1000000 : sumItem.divisor;

    const cdsExposurePreview = cdsExposure.map((item, index) => {
        const preItem = cloneDeep(item);
        if (newTrades.length > 0 && newTrades[index]) {
            const newTrade = newTrades[index];
            preItem.newTrade = newTrade;
            preItem.notional = preItem.notional + newTrade * 1000000;
            preItem.notionalPortfolioCcy = (preItem.notional * preItem.fxRate) / preItem.divisor;
        }
        preItem.ratioToNav = sumItem ? preItem.notionalPortfolioCcy / divisor : null;

        // EsgExposure.cdsPosition update
        const matchingEsg: EsgExposure = esgExposure.find((esg: EsgExposure) => esg.cdsInstrumentId == preItem.instrumentId) as EsgExposure;

        if (matchingEsg) {
            matchingEsg.cdsPosition = preItem.notional;
            matchingEsg.grossDirtyExposure = (matchingEsg.dirtyNoNames / matchingEsg.indexNoNames) * preItem.notional;
            matchingEsg.netDirtyExposure = matchingEsg.grossDirtyExposure - matchingEsg.hedgeNotional;
        }

        return preItem;
    });

    // calculate SUM row
    const sumRow: CdsExposure = cdsExposurePreview.find((x: CdsExposure) => x.name === "SUM") as CdsExposure;
    // In Divisor column it should be previous value + value in column New trade (M) on SUM row.
    sumRow.divisor = divisor;
    // In Ntl Port ccy column in SUM row should be sum of all values in the Ntl Port ccy column.
    sumRow.notionalPortfolioCcy = cdsExposurePreview
        .filter((x: CdsExposure) => x.name !== "SUM")
        .reduce((acc, item: CdsExposure) => acc + item.notionalPortfolioCcy, 0);
    // In Ratio to nav column in SUM row should be value in Ntl Port ccy on SUM row / value in Divisor column on SUM row.
    sumRow.ratioToNav = sumRow.notionalPortfolioCcy / sumRow.divisor;

    return cdsExposurePreview;
};

const calculateIssuerExposurePreview = (issuerExposure: IssuerExposure[], newTrades: number[], subscrValue: number): IssuerExposure[] => {
    const sumOfExposure: number = issuerExposure
        .filter(({ sumRow }) => sumRow === false)
        .reduce((acc, item: IssuerExposure) => acc + item.exposure, 0);

    // When calculating the percent then instead of dividing with the "sum of exposure" the denominator should be: (sum of exposure + subscription/redemption * 1 000 000)
    const denominator = subscrValue ? sumOfExposure + subscrValue * 1000000 : sumOfExposure;

    let sumPercent = 0;
    const issuerExposurePreview = issuerExposure.map((item, index) => {
        const preItem: IssuerExposure = cloneDeep(item);
        // add Subscr/Red
        preItem.subscRed = subscrValue;

        preItem.percent =
            newTrades.length > 0 && newTrades[index]
                ? (preItem.exposure + newTrades[index] * 1000000) / denominator // The percent value should be: (Exposure + input value * 1 000 000) / sum of exposure
                : subscrValue
                  ? preItem.exposure / denominator
                  : preItem.percent;

        if (
            preItem.percent > 0.05 &&
            !preItem.sumRow &&
            preItem.issuerType !== IssuerTypeEnum.Government &&
            preItem.issuer !== null &&
            preItem.issuerId !== "5c46ff650a40e100016db4d4" //"IHS Markit Benchmark Administration Limited"
        ) {
            sumPercent += preItem.percent;
        }

        return preItem;
    });

    const sumOver5Index = issuerExposurePreview.findIndex((row) => row.sumRow && row.issuer === "Sum over 5% ex gov, markit");

    issuerExposurePreview[sumOver5Index].percent = sumPercent;

    return issuerExposurePreview;
};

export function PreTrade({
    clientIds,
    endDate,
    lookThrough
}: {
    clientIds: string[];
    endDate: string;
    lookThrough: boolean;
}): React.ReactElement {
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);
    const [cdsExposureNewTrades, setCdsExposureNewTrades] = useState([]);
    const [issuerNewTrades, setIssuerNewTrades] = useState([]);
    const [subscrValue, setSubscrValue] = useState("");

    const [{ fetching: loading, error: error, data: data }] = useQuery({
        query: getTradingmanager,
        variables: { clientIds: clientIds, endDate, lookThrough },
        requestPolicy: "network-only"
    });

    const [tradingmanager, setTradingmanager] = useState(null);

    useEffect(() => {
        if (data) {
            setTradingmanager(data.tradingmanager);
        } else if (id !== previousId) {
            setTradingmanager(null);
        }
    }, [data, id, previousId]);

    useEffect(() => {
        if (previousId && previousId !== id) {
            setCdsExposureNewTrades([]);
            setIssuerNewTrades([]);
            setSubscrValue("");
        }
    }, [id, previousId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (!tradingmanager) return <div>"No data found"</div>;

    // Due to hedge instrument has short maturity than risk position divisor is used to adjust the relation between notionals
    // const divisor_hedge = 5;
    const referenceTenor = 5;
    const issuerExposure = getIssuerExposure(tradingmanager);
    const modelTypeExposure = getModelTypeExposure(tradingmanager);
    const currencyExposure = getCurrencyExposure(tradingmanager);
    const [cdsExposure, esgExposure] = getcdsIndexExposure(tradingmanager, referenceTenor);

    issuerExposure.sort(function (a, b) {
        return b.exposure - a.exposure;
    });

    const cdsExposurePreview: CdsExposure[] = calculateCdsExposurePreview(
        cdsExposure,
        cdsExposureNewTrades,
        Number(subscrValue),
        esgExposure
    );

    const issuerExposurePreview: IssuerExposure[] = calculateIssuerExposurePreview(issuerExposure, issuerNewTrades, Number(subscrValue));

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <div className="d-flex form">
                        <DateForm defaultDateString={endDate} dateName="endDate"></DateForm>
                        <div className="form-group ms-4">
                            <div className="row-col">
                                <label>Subscription/Redemption (M)</label>
                            </div>
                            <div className="row-col">
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    pattern="-?[0-9]*"
                                    className="form-control"
                                    style={{ width: "220px" }}
                                    value={subscrValue}
                                    onChange={(e) => {
                                        if (!e.target.value || e.target.value.length === 0) {
                                            setSubscrValue("");
                                            return false;
                                        }

                                        if (e.target.value == "-") {
                                            setSubscrValue("-");
                                            return false;
                                        }

                                        let value: any = Number(e.target.value);
                                        if (isNaN(value)) {
                                            value = "";
                                        }
                                        setSubscrValue(value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>{!check_today_date(endDate) ? <Alert variant="danger">Historical or future date selected</Alert> : null}</div>
                </div>
                <div className="col"></div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <Grid header="Issuers" data={issuerExposurePreview} sortable tableClassName="table-xs">
                        <Column
                            field="issuer"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.issuerId ? (
                                    <Link to={"/parties/" + item.issuerId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="exposure" format={numberFormatFun("# ##0")} />
                        <Column
                            field="newTrade"
                            title="New trade (M)"
                            className="align-middle"
                            format={(_, item, index) => {
                                if (!item.sumRow) {
                                    return (
                                        <div className="align-middle">
                                            <input
                                                type="number"
                                                tabIndex={index + 1}
                                                data-ndx={index}
                                                style={{ width: "90px" }}
                                                value={item.newTrade}
                                                onChange={(e) => {
                                                    const trades = issuerExposurePreview.map((_, index) => {
                                                        return issuerNewTrades[index] ? issuerNewTrades[index] : "";
                                                    });
                                                    trades[index] = e.target.value;
                                                    setIssuerNewTrades(trades);
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    return "";
                                }
                            }}
                        />
                        <Column field="percent" format={numberFormatFun("# ##0.00%")} />
                    </Grid>

                    <Grid header="Model types" data={modelTypeExposure} sortable tableClassName="table-xs">
                        <Column field="modelType" title="Model type" className="nowrap maxwidth150px left" />
                        <Column field="exposure" format={numberFormatFun("# ##0")} />
                        <Column field="percent" format={numberFormatFun("# ##0.00%")} />
                    </Grid>

                    <Grid header="Currencies" data={currencyExposure} sortable tableClassName="table-xs">
                        <Column field="currency" className="nowrap maxwidth150px left" />
                        <Column field="percentTotal" title="Percent total" format={numberFormatFun("# ##0.00%")} />
                        <Column field="localExposure" title="Local exposure" format={numberFormatFun("# ##0")} />
                        <Column field="percentAssets" title="Percent assets" format={numberFormatFun("# ##0.00%")} />
                        <Column field="cash" format={numberFormatFun("# ##0")} />
                        <Column field="percentCash" title="Percent cash" format={numberFormatFun("# ##0.00%")} />
                    </Grid>
                </div>

                <div className="col">
                    <Grid header="CDS Exposure" data={cdsExposurePreview} sortable={false} tableClassName="table-xs">
                        <Column
                            field="name"
                            className="align-middle nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.instrumentId ? (
                                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="currency" className="align-middle nowrap maxwidth150px left" />
                        <Column field="fxRate" title="FX rate" className="align-middle nowrap maxwidth150px left" />
                        <Column field="notional" className="align-middle nowrap" format={numberFormatFun("# ##0")} />
                        <Column
                            field="newTrade"
                            title="New trade (M)"
                            className="align-middle"
                            format={(_, item, index) => {
                                if (item.name === "SUM") {
                                    return null;
                                }
                                return (
                                    <div className="align-middle">
                                        <input
                                            type="number"
                                            tabIndex={index + 1}
                                            data-ndx={index}
                                            style={{ width: "90px" }}
                                            value={item.newTrade}
                                            onChange={(e) => {
                                                const trades = cdsExposurePreview.map((_, index) => {
                                                    return cdsExposureNewTrades[index] ? cdsExposureNewTrades[index] : "";
                                                });
                                                trades[index] = e.target.value;
                                                setCdsExposureNewTrades(trades);
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Column field="divisor" className="align-middle" format={numberFormatFun("# ##0")} />
                        <Column
                            title="Ntl port ccy"
                            field="notionalPortfolioCcy"
                            format={numberFormatFun("# ##0")}
                            className="align-middle nowrap"
                        />
                        <Column field="ratioToNav" title="Ratio to nav" className="align-middle" format={numberFormatFun("# ##0.00%")} />
                    </Grid>

                    <Grid header="CDS ESG Hedge" data={esgExposure} sortable tableClassName="table-xs">
                        <Column
                            field="cdsName"
                            title="Name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.cdsInstrumentId ? (
                                    <Link to={"/instruments/" + item.cdsInstrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="currency" className="nowrap maxwidth150px left" />
                        <Column field="cdsPosition" title="CDS position" format={numberFormatFun("# ##0")} className="nowrap" />
                        <Column
                            field="hedgeName"
                            title="Hedge name"
                            className="nowrap maxwidth150px left"
                            format={(value, item) => {
                                return item && item.hedgeInstrumentId ? (
                                    <Link to={"/instruments/" + item.hedgeInstrumentId} target="_blank">
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                );
                            }}
                        />
                        <Column field="hedgeNotional" title="Notional" format={numberFormatFun("# ##0")} className="nowrap" />
                        <Column field="dirtyNoNames" title="Dirty #" />
                        <Column field="indexNoNames" title="Index #" />
                        <Column
                            field="grossDirtyExposure"
                            title={<TooltipLabel text={"Gross dirty"} content={"Gross dirty exposure. Positive sign means exposure."} />}
                            format={numberFormatFun("# ##0")}
                            className="nowrap"
                        />
                        <Column
                            field="netDirtyExposure"
                            title={
                                <TooltipLabel
                                    text={"Net dirty"}
                                    content={"Net dirty exposure. Negative sign means no exposure and overhedged."}
                                />
                            }
                            format={numberFormatFun("# ##0")}
                            className="nowrap"
                        />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}
