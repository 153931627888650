// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { isFunction, orderBy } from "lodash";

export const formatValue = (value, formatter, item, idx, items) => {
    if (formatter) {
        return formatter(value, item, idx, items);
    } else {
        return (value || "").toString();
    }
};

export const processClassName = (className: string, item?: any, idx?: number, items?: Array<any>) => {
    if (isFunction(className)) {
        return className(item, idx, items);
    } else {
        return className;
    }
};

export const sortData = (data, sortCol, sortOrder) => {
    if (!sortCol) return data;

    return orderBy(data, sortCol, sortOrder);
};

export const isNullOrUndefined = (value): boolean => {
    return value === null || typeof value === "undefined";
};
