import React, { Fragment, useState, useMemo } from "react";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";
import { DateHelper, TimeSeries } from "../../common/src";

import * as PlotlyDefaults from "./PlotlyDefaults";

const Plot = createPlotlyComponent(Plotly);

type TimeSeriesChartInput = {
    timeseries: TimeSeries[];
    showRangeButtons?: boolean; // Made optional
    style?: React.CSSProperties | undefined;
    config?: PartialDeep<Plotly.Config>;
    layout?: PartialDeep<Plotly.Layout>;
};

export const TimeSeriesChart = ({
    timeseries,
    showRangeButtons = true, // Default value set to true
    layout,
    config,
    ...props
}: TimeSeriesChartInput): React.ReactElement => {
    const [startDate, setStartDate] = useState(DateHelper.addYears(new Date(), -1));
    const [endDate] = useState(new Date());

    const lang = (navigator.languages && navigator.languages[0]) || navigator.language;

    const traces = useMemo(() => {
        const colors = ["#66BC6B", "#BDBDBD", "#B3E5FC", "#1F5F32", "#757575", "#01579B", "#AB47BC", "#4A148C"];

        const traces = timeseries.map((ts: TimeSeries, index) =>
            PlotlyDefaults.toTrace(ts.range(startDate, endDate).normalize(1).add(-1), ts.name, colors[index])
        ) as Partial<Plotly.PlotData>[];

        return traces;
    }, [endDate, startDate, timeseries]);

    if (!traces) {
        return null;
    }

    const maxArray = traces.map((trace) => (trace.y as any).reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const max = Math.max(maxArray.reduce((p, c) => Math.max(Math.abs(c), p), 0));

    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const localLayout: PartialDeep<Plotly.Layout> = {
        font: { size: 11, family: "Gotham Rounded-Book, Gotham Rounded Book, Gotham Rounded" },
        yaxis: { tickformat: max < 0.06 ? ".1%" : ".0%" },

        xaxis: {
            title: "",
            hoverformat: "%Y-%m-%d",
            zeroline: false
        },
        width: null,
        height: null,

        margin: { l: 50, b: 70, t: 5 },
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",

            y: -0.25,
            yanchor: "bottom"
        }
    };
    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <Fragment>
            {showRangeButtons ? (
                <div className="timeseries-button row">
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            setStartDate(DateHelper.addDays(endDate, -7));
                        }}
                    >
                        {lang === "sv" ? "Vecka" : "Week"}
                    </div>
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            setStartDate(DateHelper.addMonths(endDate, -1));
                        }}
                    >
                        {lang === "sv" ? "Månad" : "Month"}
                    </div>
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            const previousYear = new Date().getFullYear() - 1;
                            setStartDate(new Date(previousYear, 11, 31)); // previousYear-12-31
                        }}
                    >
                        Ytd
                    </div>
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            setStartDate(DateHelper.addYears(endDate, -1));
                        }}
                    >
                        {lang === "sv" ? "År" : "Year"}
                    </div>
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            setStartDate(DateHelper.addYears(endDate, -5));
                        }}
                    >
                        {lang === "sv" ? "5 År" : "5years"}
                    </div>
                    <div
                        className="m-2 hover__pointer"
                        style={{ width: "auto", height: "auto" }}
                        onClick={() => {
                            setStartDate(new Date(2000, 0, 1));
                        }}
                    >
                        {lang === "sv" ? "Allt" : "All"}
                    </div>
                </div>
            ) : null}

            <Plot data={traces} config={thisConfig} layout={thisLayout} useResizeHandler={true} className="w-100" {...props} />
        </Fragment>
    );
};
