import React, { ReactElement, useState, useEffect } from "react";
import { gql, useMutation } from "urql";
import { Alert } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";

import { ArrayPagedTableField } from "./ArrayPagedTableField";
import { SearchListField, SelectField, TextField, SubmitButton } from "../../../../components/src/form";
import { Party, TimeSeries } from "../../types.generated";
import { TimeSeries as CommonTimeSeries } from "../../../../common/src";
import { TimeSeriePlot } from "./TimeSeriePlot";
import { cloneDeep, sortBy } from "lodash";
import { useAlertTimeOut, useQueryArgs } from "../../../../components/src/common/Utils";
import { formikUrqlErrorFormater } from "../../../../components/src/common/formik-urql-error-helper";
import { MarkDownField } from "../../components/form/MarkDownField";
import { REACT_APP_API_URI } from "../../env";

const UPDATE_TIMESERIE = gql`
    mutation updateTimeSerie($input: TimeSeriesInput!) {
        updateTimeSerie(input: $input) {
            _id
        }
    }
`;

// convert from timeserie items to Common.TimeSeries
const toTimeSerie = (data) => {
    const items = data.items ? data.items.filter((x) => x.date && x.value) : data.filter((x) => x.date && x.value);
    const dates = items.map((i) => new Date(i.date));
    const values = items.map((i) => i.value);
    return new CommonTimeSeries(dates, values);
};

type TimeSeriesEditorFormType = {
    ts: TimeSeries;
    instruments: { _id: string; name: string }[];
    clients: Party[];
};

export const TimeSeriesEditorForm = ({ ts, instruments, clients }: TimeSeriesEditorFormType): ReactElement => {
    const { queryArgs } = useQueryArgs();
    const initialTimeseries = cloneDeep(ts);
    initialTimeseries.items = sortBy(ts.items, "date").reverse();

    const navigate = useNavigate();
    const [timeseries, setTimeSeries] = useState(initialTimeseries);
    const [plotData, setTimeSeriePlotData] = useState(toTimeSerie(ts));

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const [_, updateTimeSerie] = useMutation(UPDATE_TIMESERIE);

    const id = ts._id || "new";

    useEffect(() => {
        if (id == "new") {
            if (
                "instrumentId" in queryArgs &&
                (!timeseries.instrumentId || timeseries.instrumentId.toString() !== queryArgs["instrumentId"].toString())
            ) {
                const newTimeSeries = cloneDeep(timeseries);
                newTimeSeries.instrumentId = queryArgs["instrumentId"] as string;
                setTimeSeries(newTimeSeries);
            }
        }
    }, [id, queryArgs, timeseries]);

    const typeOptions = ["Price(Close)", "Return(Total)", "Price(Last)"];

    if (!typeOptions.includes(ts.type)) {
        typeOptions.push(ts.type);
    }

    const handleItemsChange = (data) => {
        const items = toTimeSerie(data);
        setTimeSeriePlotData(items);
    };

    return (
        <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-12">
                <Formik
                    key={`ts-${id}`}
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={timeseries}
                    validationSchema={Yup.object({
                        type: Yup.string().required(),
                        instrumentId: Yup.string().required().min(24).max(24),
                        items: Yup.array().min(1)
                    })}
                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                        const input = {
                            _id: ts._id,
                            type: submitValues.type,
                            title: submitValues.title,
                            description: submitValues.description,
                            instrumentId: submitValues.instrumentId,
                            clientId: submitValues.clientId,
                            items: submitValues.items
                                .filter((x) => x.date && x.value)
                                .map((e) => {
                                    return { date: e.date, value: e.value * 1 };
                                })
                        };

                        if (ts._id === "new") {
                            //_id: string, // If _id is omitted, a TimeSeries will be created
                            delete input._id;
                        }

                        await updateTimeSerie({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    if (input._id) {
                                        setAlert({ color: "success", visible: true, message: "Timeseries updated." });
                                        const update = cloneDeep(submitValues);
                                        update.items = sortBy(submitValues.items, "date").reverse();
                                        setTimeSeries(update);
                                    } else {
                                        // redirect to edit page on success
                                        navigate("/timeseries/" + result.data.updateTimeSerie._id, { replace: true });
                                    }
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form autoComplete="off">
                            <div className="form-row">
                                <SearchListField
                                    name="instrumentId"
                                    label={<Link to={"/instruments/" + values.instrumentId}>Instrument</Link>}
                                    className="col-sm-12"
                                    options={instruments}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="form-row">
                                <TextField name="title" label="Title" type="text" className="col-sm-12" disabled={isSubmitting} />
                            </div>
                            <div className="form-row">
                                <SelectField
                                    name="clientId"
                                    label={
                                        <div className="pb-2">
                                            <Link to={"/parties/" + values.clientId} target="_blank">
                                                Client
                                            </Link>
                                        </div>
                                    }
                                    options={clients.map((d) => ({ key: d._id, value: d.name }))}
                                    className="col-sm-12"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="form-row">
                                <SelectField
                                    name="type"
                                    label={<div>Type</div>}
                                    options={typeOptions}
                                    className="col-sm-12"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="form-row">
                                <SelectField
                                    name="status"
                                    label="Status"
                                    options={["Active", "Deleted"]}
                                    className="col-sm-12"
                                    disabled={true}
                                />
                            </div>
                            <div className="form-row">
                                <MarkDownField
                                    name="description"
                                    graphqlApiBaseUrl={REACT_APP_API_URI}
                                    label="Description"
                                    type="text"
                                    className="col-sm-12"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="form-row">
                                <ArrayPagedTableField
                                    key={"items-" + id}
                                    name="items"
                                    label="Items*"
                                    className="col-sm-12"
                                    disabled={isSubmitting}
                                    onChange={handleItemsChange}
                                />
                            </div>
                            <div className="form-row">
                                {alert.visible ? (
                                    <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                        {alert.message}
                                    </Alert>
                                ) : null}
                            </div>
                            <div className="form-row">
                                <SubmitButton disabled={isSubmitting} label="Save" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="col-lg-8 col-md-6 col-xs-12">
                <TimeSeriePlot tss={[plotData]} instrumentIds={[ts.instrumentId]} />
            </div>
        </div>
    );
};
