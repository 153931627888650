import { isNil } from "lodash";
import { MondayToFridayCalendar, DateHelper, msPerDay, YearFractionBasis } from "../calendar";
import { RandomNumberGenerator } from "../RandomNumberGenerator";
import { stdev, skew, kurtosis, corr, quantile, mean } from "./Math";

const { timestampToComparable, timestampToString, addDays, getYear, addMonths, yearFraction } = DateHelper;

interface TimeSeriesItem {
    date: Date | string | number;
    value: number;
}

export enum AlignMethod {
    Latest = "Latest",
    Exact = "Exact",
    LatestWithinRange = "LatestWithinRange"
}

/**
 * Class to handle time series data. A time series is equal length arrays with [dates] & [values] .
 * The dates are required to be sorted in increasing order.
 */
export class TimeSeries {
    /**
     * TimeSeries constructor. Takes three arguments. Dates and values arrays are required. Name is optional.
     * @param {Date[]|string[]} dates Either a Date[] or a string[] array.
     * @param {number[]} values A number[] array
     * @param {string} name String, optional
     */
    constructor(dates: Date[] | string[], values: number[], name: string = null) {
        this.__dates = dates.map((date) => {
            if (typeof date.getMonth === "function") {
                return date;
            } else {
                return new Date(date);
            }
        });
        this.__values = typeof values === "undefined" ? [] : values.slice();
        this.name = name;
    }

    __dates: Date[]; //private
    __values: number[]; //private
    name: string;

    /**
     * Number of items in TimeSeries
     */
    get length(): number {
        if (!this.__dates) return 0;
        return this.__dates.length;
    }

    /**
     * Number of items in TimeSeries. Equal to `length`.
     */
    get count(): number {
        return this.__dates.length;
    }

    /**
     * First value in the TimeSeries
     */
    get startValue(): number {
        return this.__values[0];
    }

    /**
     * Last value in the TimeSeries
     */
    get endValue(): number {
        return this.__values[this.__values.length - 1];
    }

    /**
     * Start timestamp in the TimeSeries
     */
    get start(): Date {
        return this.__dates[0];
    }

    /**
     * End timestamp in the TimeSeries
     */
    get end(): Date {
        return this.__dates[this.__dates.length - 1];
    }
    /**
     * Returns average of TimeSeries values
     */

    get average(): number {
        let total = 0;
        const res = this.clone();
        for (let i = 0; i < res.length; i++) {
            total += res.__values[i];
        }
        return total / res.length;
    }

    get stdev(): number {
        return stdev(this.__values);
    }

    /**
     * Returns a clone of the TimeSeries
     */
    clone(): TimeSeries {
        return new TimeSeries(this.__dates, this.__values, this.name);
    }

    /**
     * Adds a value to the TimeSeries or adds two TimeSeries
     * @param {number|TimeSeries} value Is either a number or a TimeSeries. In case of number, each value of the TimeSeries is added by
     * the value. In case of TimeSeries, each value is added by corresponding value in input TimeSeries. The original TimeSeries will not
     * be modified.
     */
    add(value: number | TimeSeries): TimeSeries {
        const res = this.clone();
        if (value instanceof TimeSeries) {
            if (value.length !== this.length) throw new Error("Cannot add TimeSeries of different length");
            for (let i = 0; i < res.length; i++) {
                res.__values[i] += value.__values[i];
            }
        } else {
            for (let i = 0; i < res.length; i++) {
                res.__values[i] += value;
            }
        }
        return res;
    }

    subtract(value: number | TimeSeries): TimeSeries {
        const res = this.clone();
        if (value instanceof TimeSeries) {
            if (value.length !== this.length) throw new Error("Cannot subtract TimeSeries of different length");
            for (let i = 0; i < res.length; i++) {
                res.__values[i] -= value.__values[i];
            }
        } else {
            for (let i = 0; i < res.length; i++) {
                res.__values[i] -= value;
            }
        }
        return res;
    }

    /**
     * Multiplies a value to the TimeSeries or multiplies two TimeSeries
     * @param {number|TimeSeries} value Is either a number or a TimeSeries. In case of number, each value of the TimeSeries is multiplied
     * by the value. In case of TimeSeries, each value is multiplied by corresponding value in input TimeSeries. The original TimeSeries
     * will not be modified.
     */
    mult(value: number | TimeSeries): TimeSeries {
        const res = this.clone();
        if (value instanceof TimeSeries) {
            if (value.length !== this.length) throw new Error("Cannot multiply TimeSeries of different length");
            for (let i = 0; i < res.length; i++) {
                res.__values[i] *= value.__values[i];
            }
        } else {
            for (let i = 0; i < res.length; i++) {
                res.__values[i] *= value;
            }
        }
        return res;
    }

    /**
     * Takes log for each item in the TimeSeries. The original TimeSeries will not be modified.
     */
    log(): TimeSeries {
        const res = this.clone();
        for (let i = 0; i < res.length; i++) {
            res.__values[i] = Math.log(res.__values[i]);
        }
        return res;
    }

    /**
     * Takes exp for each item in the TimeSeries. The original TimeSeries will not be modified.
     */
    exp(): TimeSeries {
        const res = this.clone();
        for (let i = 0; i < res.length; i++) {
            res.__values[i] = Math.exp(res.__values[i]);
        }
        return res;
    }

    /**
     * Calculates the periodicity of the TimeSeries. Periodicity is calendar adjusted number of items per year.
     * Return values are daily, business daily, weekly, monthly, quarterly, semi-annually and annually.
     */
    periodicity(): number {
        if (this.count < 2) return 0;
        const dt = (this.end.getTime() - this.start.getTime()) / msPerDay / (this.count - 1);
        if (dt === 0.0) return 0;
        const peryear = 365.25 / dt;
        if (peryear > 300.0) return 365.0;
        if (peryear > 200.0) return 252.0;
        if (peryear > 40.0) return 52.0;
        if (peryear > 10.0) return 12.0;
        if (peryear > 3.0) return 4.0;
        if (peryear > 1.5) return 2.0;
        return 1.0;
    }

    /**
     * Calculates yearFraction on the price series (not return series)
     *
     */
    yearFraction(): number {
        return (this.length - 1) / this.periodicity();
    }

    /**
     * Returns true if item value is not undefined or null and number value is not NaN or unfinite.
     * @param {number} value The number value to check
     */
    static isNotNilAndFinite(value: number): boolean {
        return !isNil(value) && isFinite(value);
    }

    /**
     * Used internally for cumProd and cumSum. The function is similar to reduce on array.
     * @param {TimeSeries} ts
     * @param {number} startValue
     * @param {function} operatorFun
     */
    static cumOperator(ts: TimeSeries, startValue: number, operatorFun: (n1: number, n2: number) => number): TimeSeries {
        let v = startValue;
        for (let i = 0; i < ts.__values.length; i++) {
            v = operatorFun(v, ts.__values[i]);
            ts.__values[i] = v;
        }
        return ts;
    }

    /**
     * Returns a cumulative product sum of the TimeSeries. The original TimeSeries will not be modified.
     */
    cumProd(): TimeSeries {
        const res = this.clone();
        return TimeSeries.cumOperator(res, 1.0, (v0, v1) => {
            if (TimeSeries.isNotNilAndFinite(v0) && TimeSeries.isNotNilAndFinite(v1)) return v0 * v1;
            return Number.NaN;
        });
    }

    /**
     * Returns a cumulative product sum of the TimeSeries. The original TimeSeries will not be modified.
     */
    cumSum(): TimeSeries {
        const res = this.clone();
        return TimeSeries.cumOperator(res, 0.0, (v0, v1) => {
            if (TimeSeries.isNotNilAndFinite(v0) && TimeSeries.isNotNilAndFinite(v1)) return v0 + v1;
            return Number.NaN;
        });
    }

    /**
     * Calculates and returns stepwise returns of the TimeSeries. The original TimeSeries will not be modified.
     */
    return(): TimeSeries {
        const values = [];
        const dates = [];
        if (this.__values.length > 0) {
            let v0 = this.__values[0];
            for (let i = 1; i < this.length; i++) {
                const v1 = this.__values[i];
                let r = Number.NaN;
                if (TimeSeries.isNotNilAndFinite(v0) && v0 !== 0 && TimeSeries.isNotNilAndFinite(v1)) r = v1 / v0 - 1;
                values.push(r);
                dates.push(this.__dates[i]);
                v0 = v1;
            }
        }
        return new TimeSeries(dates, values, this.name);
    }

    /**
     * Calculates and returns stepwise diff of the TimeSeries. The original TimeSeries will not be modified.
     */
    diff(): TimeSeries {
        const values = [];
        const dates = [];
        if (this.__values.length > 0) {
            let v0 = this.__values[0];
            for (let i = 1; i < this.length; i++) {
                const v1 = this.__values[i];
                let r = Number.NaN;
                if (TimeSeries.isNotNilAndFinite(v0) && v0 !== 0 && TimeSeries.isNotNilAndFinite(v1)) r = v1 - v0;
                values.push(r);
                dates.push(this.__dates[i]);
                v0 = v1;
            }
        }
        return new TimeSeries(dates, values, this.name);
    }

    /**
     * Returns the highest index i, where t>=timestamps[i]. It returns -1 if TimeSeries is empty or t is prior to timestamps[0].
     * @param {Date} t timestamp to compare
     */
    indexOf(t: Date): number {
        const toComparable = timestampToComparable;
        const tn = toComparable(t);
        if (typeof this.__dates === "undefined") {
            return -1;
        }
        const vs = this.__dates;
        let i;
        const n = this.__dates.length;
        if (n === 0) {
            return -1;
        } else if (tn < toComparable(vs[0])) {
            return -1;
        } else if (tn >= toComparable(vs[n - 1])) {
            return n - 1;
        }
        if (n > 40) {
            let hi = n - 1;
            let low = 0;
            if (tn >= toComparable(vs[hi])) {
                return hi;
            }
            while (hi > low + 1) {
                i = Math.floor((hi + low) / 2);
                if (tn >= toComparable(vs[i])) {
                    low = i;
                } else {
                    hi = i;
                    i = low;
                }
            }
            return i;
        } else {
            i = 1;
            while (tn >= toComparable(vs[i]) && i < n - 1) {
                i++;
            }
            return i - 1;
        }
    }

    /**
     * Returns a slice of the TimeSeries. Returns items where start>=timestamps[i] and end<=timestamps[i]
     * @param {Date} start Start timestamp
     * @param {Date} end End timestamp
     */
    range(start: Date, end: Date): TimeSeries {
        const toComparable = timestampToComparable;
        const cs = toComparable(start);
        const ce = toComparable(end);
        const dates = [];
        const values = [];

        for (let i = 0; i < this.__dates.length; i++) {
            const date = toComparable(this.__dates[i]);
            if (date >= cs && date <= ce) {
                dates.push(this.__dates[i]);
                values.push(this.__values[i]);
            }
        }

        return new TimeSeries(dates, values, this.name);
    }

    /**
     * Returns latest known value of the TimeSeries. It returns values[i], where i fulfils t>=timestamp[i]
     * @param {Date} t timestamp to compare
     */
    latestValue(t: Date): number {
        const idx = this.indexOf(t);
        if (idx === -1) {
            return Number.NaN;
        }
        return this.__values[idx];
    }

    static weightedTimeSeries(ws: number[], tss: TimeSeries[]): TimeSeries {
        const dates = [];
        const values = [];

        for (let i = 0; i < tss[0].__values.length; i++) {
            let v = 0;
            for (let j = 0; j < tss.length; j++) {
                v += ws[j] * tss[j].__values[i];
            }
            dates.push(tss[0].__dates[i]);
            values.push(v);
        }
        return new TimeSeries(dates, values);
    }

    //TODO: this needs more love
    static fromJson(json: any): TimeSeries {
        if (!json || (!json.dates && !json.timestamps)) {
            return new TimeSeries([], []);
        }
        const dates: Date[] = (json.dates ? json.dates : json.timestamps).map((d) => new Date(d));
        const values: number[] = json.values.map((d) => parseFloat(d));
        return new TimeSeries(dates, values, json.name);
    }

    static toGrid(arrayOfTimeSeries: TimeSeries[]): { date: string; value: number }[] {
        const toComparable = timestampToComparable;
        const gridDataDict = {};
        arrayOfTimeSeries.forEach((d, i) =>
            d.__dates.forEach((e, j) => {
                const c = toComparable(e);
                let item = gridDataDict[c];
                if (typeof item === "undefined") {
                    item = { date: e };
                    gridDataDict[c] = item;
                }
                item["value" + i] = d.__values[j];
            })
        );
        const timestamps = Object.keys(gridDataDict);
        timestamps.sort();
        return timestamps.map((d) => gridDataDict[d]);
    }

    /**
     * Align rearranges synchTimeSeries. It takes timestamps from masterTimeSeries and values from synchTimeSeries.
     * The value taken depends on the method.
     * @param {TimeSeries} masterTimeSeries From where the timestamps are taken.
     * @param {TimeSeries} synchTimeSeries From where the values are taken.
     * @param {string} method Method for choosing values. Can be any of latest, exact, latestOnlyWithinRange.
     */
    static align(masterTimeSeries: TimeSeries, synchTimeSeries: TimeSeries, method = AlignMethod.Latest): TimeSeries {
        const tMax = timestampToComparable(new Date("9999-12-31"));
        const nextTimestamp = (j) => timestampToComparable(j + 1 < synchTimeSeries.__dates.length ? synchTimeSeries.__dates[j + 1] : tMax);
        if (masterTimeSeries.__dates.length === 0 || synchTimeSeries.__dates.length === 0) {
            return new TimeSeries([], [], synchTimeSeries.name);
        }

        if (masterTimeSeries.__dates[0].toISOString() < synchTimeSeries.__dates[0].toISOString()) {
            const st =
                "Warning: TimeSeries.align masterTimeSeries: " +
                masterTimeSeries.name +
                " " +
                masterTimeSeries.__dates[0].toISOString() +
                " starts earlier than synchTimeSeries: " +
                synchTimeSeries.name +
                " " +
                synchTimeSeries.__dates[0].toISOString() +
                "\n This is probably not what you want, you are going to get NaN in the timeseries";
            console.error(st);
        }

        let mtStart;
        const values = [];
        let j = 0;
        let st = timestampToComparable(synchTimeSeries.__dates[j]);
        let stNext = nextTimestamp(j);
        for (let i = 0; i < masterTimeSeries.__dates.length; i++) {
            const mt = timestampToComparable(masterTimeSeries.__dates[i]);
            if (i === 0) {
                mtStart = mt;
            }
            while (stNext <= mt) {
                st = stNext;
                stNext = nextTimestamp(++j);
            }
            let sv = Number.NaN;
            if (st === mt || (method !== AlignMethod.Exact && st < mt)) {
                sv = j < synchTimeSeries.__dates.length ? synchTimeSeries.__values[j] : Number.NaN;
            }
            if (method === AlignMethod.LatestWithinRange && st < mtStart) {
                sv = Number.NaN;
            }
            values.push(sv);
        }
        // We handle Date and string
        const constructor = synchTimeSeries.__dates[0].constructor;
        if (constructor === Date) {
            return new TimeSeries(
                masterTimeSeries.__dates.map((d) => new Date(d)),
                values,
                synchTimeSeries.name
            );
        } else if (constructor === String) {
            return new TimeSeries(
                masterTimeSeries.__dates.map((d) => timestampToString(d)),
                values,
                synchTimeSeries.name
            );
        } else {
            return new TimeSeries(masterTimeSeries.__dates, values, synchTimeSeries.name);
        }
    }

    static AlignSetMethod = Object.freeze({
        intersection: "intersection",
        union: "union"
    });

    // static alignTimeSeriesSet(timeSeriesArray, method = TimeSeries.AlignSetMethod.intersection) {
    //     if (timeSeriesArray.constructor === Array) {
    //         // If we only have one time series return time serie
    //         if (timeSeriesArray.length === 1) return timeSeriesArray[0];
    //         // Loop time series
    //         const numberOfTimeSeries = timeSeriesArray.length;
    //         const timeSeriesDateDict: { [key: string]: TimeSeriesItem[] } = {};
    //         const timeSeries = [];
    //         if (method === TimeSeries.AlignSetMethod.intersection) {
    //             for (let i = 0; i < numberOfTimeSeries; i++) {
    //                 let timeSerie = timeSeriesArray[i];
    //                 // Start with "empty" time series
    //                 timeSeries.push(new TimeSeries([], [], timeSerie.name));
    //                 for (let t = 0; t < timeSerie.length; t++) {
    //                     // Use YYYY-MM-DD as key
    //                     let key = timestampToString(timeSerie.dates[t]);
    //                     timeSeriesDateDict[key]
    //                         ? timeSeriesDateDict[key].push({ value: timeSerie.values[t], date: timeSerie.dates[t] })
    //                         : (timeSeriesDateDict[key] = [{ value: timeSerie.values[t], date: timeSerie.dates[t] }]);
    //                 }
    //             }
    //             for (let items of Object.values(timeSeriesDateDict)) {
    //                 // Append if we have value for all time series
    //                 if (items.length === numberOfTimeSeries) {
    //                     for (let i = 0; i < numberOfTimeSeries; i++) {
    //                         // Since dates for all time series are ordered, the intersection is ordered
    //                         timeSeries[i].dates.push(items[i].date);
    //                         timeSeries[i].values.push(items[i].value);
    //                     }
    //                 }
    //             }
    //         } else if (method === TimeSeries.AlignSetMethod.union) {
    //             for (let i = 0; i < numberOfTimeSeries; i++) {
    //                 let timeSerie = timeSeriesArray[i];
    //                 for (let t = 0; t < timeSerie.length; t++) {
    //                     // Use YYYY-MM-DD as key
    //                     timeSeriesDateDict[timestampToString(timeSerie.dates[t])] = [];
    //                 }
    //             }
    //             // Master series with all dates in ascending order. Master series now have string dates.
    //             const stringDatesSorted = Object.keys(timeSeriesDateDict).sort();
    //             for (let i = 0; i < numberOfTimeSeries; i++) {
    //                 // Align versus master using last known value. This might result in values with not a number!
    //                 if (timeSeriesArray[i].dates[0].constructor === Date) {
    //                     timeSeries.push(
    //                         TimeSeries.align(
    //                             new TimeSeries(
    //                                 stringDatesSorted.map((d) => new Date(d)),
    //                                 Object.values(timeSeriesDateDict).map(d=>d.value),
    //                                 "Master"
    //                             ),
    //                             timeSeriesArray[i],
    //                             AlignMethod.Latest
    //                         )
    //                     );
    //                 } else {
    //                     timeSeries.push(
    //                         TimeSeries.align(
    //                             new TimeSeries(stringDatesSorted, Object.values(timeSeriesDateDict).map(d=>d.va), "Master"),
    //                             timeSeriesArray[i],
    //                             AlignMethod.Latest
    //                         )
    //                     );
    //                 }
    //             }
    //         } else {
    //             throw new Error("Alignment method for set of time series unknown. Allowed values are: union and intersection");
    //         }
    //         return timeSeries;
    //     } else if (timeSeriesArray.constructor === TimeSeries) return timeSeriesArray;
    //     else {
    //         throw new Error("alignTimeSeriesSet input must be a single time serie or an array of time series");
    //     }
    // }

    maxDrawdown(fullTimeSeries: boolean): TimeSeries {
        //if (typeof fullTimeSeries === "undefined") {
        //    fullTimeSeries = false;
        //}
        let max = -9e9;
        let maxindex;
        let drawdown;
        let maxdrawdown = 0.0;
        let startindex = -1;
        let endindex = -1;
        const dates = [];
        const values = [];

        for (let i = 0; i < this.count; i++) {
            const v = this.__values[i];
            if (!isFinite(v) || v === null) {
                continue;
            }
            if (v > max) {
                max = v;
                maxindex = i;
            }
            drawdown = v / max - 1.0;
            if (drawdown < maxdrawdown) {
                maxdrawdown = drawdown;
                startindex = maxindex;
                endindex = i;
            }
            if (fullTimeSeries) {
                dates.push(this.__dates[i]);
                values.push(drawdown);
            }
        }
        if (!fullTimeSeries && startindex >= 0 && endindex > 0) {
            dates.push(this.__dates[startindex]);
            dates.push(this.__dates[endindex]);
            values.push(this.__values[startindex]);
            values.push(this.__values[endindex]);
        }
        return new TimeSeries(dates, values, this.name);
    }

    static generateRandomTimeSeries(
        start: Date,
        end: Date,

        // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
        businessDaysCheck: boolean | Function,
        yearlyreturn: number,
        yearlyvolatility: number,
        autocorrelation: number,
        correlation: number,
        seed: string
    ): TimeSeries {
        const toComparable = timestampToComparable;
        let bdc = businessDaysCheck;
        if (typeof (start as any).isBusinessDay !== "undefined") {
            bdc = businessDaysCheck === true ? (d) => d.isBusinessDay : null;
        } else if (businessDaysCheck === true) {
            const cal = new MondayToFridayCalendar();
            bdc = (d) => cal.isBusinessDay(d);
        }
        if (bdc === false) {
            bdc = null;
        }
        const rng = new RandomNumberGenerator(seed);
        let d = start;
        const dates = [];
        const values = [];
        let n = 365.0;
        if (!isNil(bdc)) {
            n = 252.0;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
            while (!(bdc as Function)(d)) {
                d = addDays(d, 1);
            }
        }
        const ce = toComparable(end);
        while (toComparable(d) <= ce) {
            values.push(dates.length === 0 ? 0 : rng.randN());
            dates.push(d);
            values.push();
            d = addDays(d, 1);
            let n = 0;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
            while (!isNil(bdc) && !(bdc as Function)(d)) {
                if (n++ >= 366) break;
                d = addDays(d, 1);
            }
        }
        if (correlation !== 0) {
            const p = correlation;
            const q = Math.sqrt(1 - p * p);
            let i = 1;
            while (i < values.length) {
                const r = values[i];
                values[i] = p * r + q * rng.randN();
                i++;
            }
        }
        let v = 100.0;
        let c0 = 0.0;
        const sigma = yearlyvolatility / Math.sqrt(n);
        const r = Math.pow(1.0 + yearlyreturn, 1.0 / n) - 1.0 - (sigma * sigma) / 2.0;
        let i = 0;
        while (i < values.length) {
            values[i] = Math.round(100 * v) / 100;
            i++;
            if (i === values.length) {
                break;
            }
            const c = sigma * values[i];
            v *= 1.0 + r + c + autocorrelation * c0;
            c0 = c;
        }
        return new TimeSeries(dates, values, String(seed));
    }

    // -----------------------------------------------
    // Time Series measures
    // -----------------------------------------------
    static AnnualizationMethod = Object.freeze({
        geometric: "geometric",
        arithmetic: "arithmetic"
    });

    /**
     * Returns annualized return using either geometric or arithmetic method, based on periodicity of timeseries
     * @param method method to calculate annualized return by, either geometric or arithmetic
     */

    annualizedReturn(method = TimeSeries.AnnualizationMethod.geometric): number {
        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(this.startValue)) {
            //return (this.endValue / this.startValue) ** (1 / this.yearFrac()) - 1;
            if (method === TimeSeries.AnnualizationMethod.geometric)
                return (this.endValue / this.startValue) ** (1 / this.yearFraction()) - 1;
            if (method === TimeSeries.AnnualizationMethod.arithmetic) {
                const tsReturns = this.return();
                return (tsReturns.average * tsReturns.count) / this.yearFraction();
            }
        }
        return Number.NaN;
    }

    /**
     * Returns annualized geometric return as a number, based on periodicity of timeseries
     */
    annualizedGeometricReturn(): number {
        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(this.startValue)) {
            return (this.endValue / this.startValue) ** (1 / this.yearFraction()) - 1;
        }
        return Number.NaN;
    }

    /**
     * Returns annualized arithmetic return as a number, based on periodicity of timeseries
     */
    annualizedArithmeticReturn(): number {
        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(this.startValue)) {
            const tsReturns = this.return();
            return (tsReturns.average * tsReturns.count) / this.yearFraction();
        }
        return Number.NaN;
    }

    /**
     * Returns annualized time-weighted return as a number, based on periodicity of timeseries
     */
    annualizedTimeWeightedReturn(): number {
        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(this.startValue)) {
            return ((this.endValue / this.startValue) ** (1 / this.length) - 1) * this.yearFraction();
        }
        return Number.NaN;
    }

    /**
     * Returns the total return of a timeseries from start to end ,
     */
    valueReturn(): number {
        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(this.startValue)) {
            return this.endValue / this.startValue - 1;
        }
    }

    /**
     * Returns the return from last known value last year start to end
     */
    yearToDateReturn(): number {
        const thisYear = getYear(new Date(this.end));
        const lastYearLatestValue = this.latestValue(new Date((thisYear - 1).toString() + "-12-31"));

        if (TimeSeries.isNotNilAndFinite(this.endValue) && TimeSeries.isNotNilAndFinite(lastYearLatestValue)) {
            return this.endValue / lastYearLatestValue - 1;
        }
        return Number.NaN;
    }

    /**
     * Returns annualized return from last known value last year start to end , based on periodicity of timeseries
     * @param method method to calculate year-to-date-return by, either arithmetic or geometric
     */
    annualizedYearToDateReturn(method = TimeSeries.AnnualizationMethod.geometric): number {
        const ytdReturn = this.yearToDateReturn();
        if (TimeSeries.isNotNilAndFinite(ytdReturn)) {
            if (method === TimeSeries.AnnualizationMethod.geometric) return (ytdReturn + 1) ** (1 / this.yearFraction()) - 1;
            if (method === TimeSeries.AnnualizationMethod.arithmetic) return ytdReturn / this.yearFraction();
        }
        return Number.NaN;
    }

    /**
     * Returns the return for a specific year, annualized per definition
     *  @param year year to calculate return for
     */
    specificYearReturn(year: number): number {
        const lastYearDate = new Date(year.toString() + "-12-31");
        const lastValueActualYear = this.latestValue(new Date(year.toString() + "-12-31"));
        const lastValuePreviousYear = this.latestValue(DateHelper.addYears(lastYearDate, -1).toString());

        if (TimeSeries.isNotNilAndFinite(lastValueActualYear) && TimeSeries.isNotNilAndFinite(lastValuePreviousYear)) {
            return lastValueActualYear / lastValuePreviousYear - 1;
        } else if (timestampToComparable(this.start) >= DateHelper.addYears(lastYearDate, -1)) {
            //console.log(typeof this.start);
            return lastValueActualYear / this.startValue - 1;
        } else {
            return Number.NaN;
        }
    }

    /**
     * Returns annualized volatility as a number
     */
    volatility(): number {
        const returns = this.return();
        return stdev(returns.__values) * Math.sqrt(this.periodicity());
    }

    /**
     * Returns the fraction of the annualized geometric return and the annualized volatility
     */
    returnVolatilityRatio(): number {
        return this.annualizedGeometricReturn() / this.volatility();
    }

    /**
     * Returns the minimum of the timeseries returns
     */
    worstReturn(): number {
        return Math.min(...this.return().__values);
    }

    /**
     * Returns the fraction of positive returns of the timeseries
     */
    positiveShare(): number {
        return this.return().__values.filter((v) => v > 0).length / this.return().__values.length;
    }

    /**
     * Returns the skew of the timeseries returns as a number
     */
    skew(): number {
        return skew(this.return().__values);
    }

    /**
     * Returns the kurtosis of the timeseries returns as a number
     */
    kurtosis(): number {
        return kurtosis(this.return().__values);
    }

    /**
     * Returns the simple correlation of returns with another timeseries (not rolling)
     *  @param other instance of TimeSeries to calculate correlation with
     */
    correlation(other: TimeSeries): number {
        if (!(other instanceof TimeSeries)) throw new Error("Object other needs to be of type TimeSeries");
        return corr(this.return().__values, other.return().__values);
    }

    /**
     * Returns the downSide Value At Risk of the timeseries returns as a number
     * @param level The level of Value At Risk expressed as number, e.g 0.95 for 95% VaR
     */
    downSideVaR(level: number): number {
        //do as quantile or just as normal inverse?
        return quantile(this.return().__values, 1 - level);
    }

    /**
     * Returns the conditional Value At Risk (i.e. Expected Shortfall) corresponding to downSideVaR
     */
    conditionalVaR(level: number): number {
        const sorted = this.return().__values.sort(function (a, b) {
            return a - b;
        });
        const index = (1 - level) * (this.return().length - 1); //what number in array
        return mean(sorted.slice(0, Math.floor(index) + 1));
    }

    /**
     * Calculates the sharpeRatio for a TimeSeries
     */
    sharpeRatio(): number {
        return this.annualizedReturn() / this.volatility();
    }

    /**
     * Returns a resampled TimeSeries of instance TimeSeries with frequency numberOfDays
     * @param numberOfDays frequency to resample with expressed in number of Days, e.g 7 for weekly, 30 for monthly,
     */
    resample(numberOfDays: number): TimeSeries {
        let t = this.end;
        const sampledDates = [];
        const sampledValues = [];
        while (this.start <= t) {
            sampledDates.unshift(t);
            sampledValues.unshift(1);
            t = addDays(t, -numberOfDays);
        }

        const resampled = new TimeSeries(sampledDates, sampledValues, "Resampled Timeseries");

        return TimeSeries.align(resampled, this, AlignMethod.Latest);
    }

    /**
     * Returns a resampled TimeSeries of instance TimeSeries with weekly frequency
     */
    resampleToWeekly(): TimeSeries {
        return this.resample(7);
    }

    /**
     * Returns a resampled TimeSeries of instance TimeSeries with monthly frequency
     */
    resampleToMonthly(): TimeSeries {
        let t = this.end;
        const sampledDates = [];
        const sampledValues = [];
        while (this.start <= t) {
            sampledDates.unshift(t);
            sampledValues.unshift(1);
            t = addMonths(t, -1);
        }
        const resampled = new TimeSeries(sampledDates, sampledValues, "Resampled Timeseries");

        return TimeSeries.align(resampled, this, AlignMethod.Latest);
    }

    /**
     * Returns a resampled TimeSeries of instance TimeSeries with calender monthly frequency
     * using the last known value each month.
     */
    resampleToCalenderMonthly(includeFirst = true): TimeSeries {
        const eomDict: { [key: string]: TimeSeriesItem } = {};

        for (let i = 0; i < this.length; i++) {
            const date = new Date(this.__dates[i]);
            // YYYY-MM as key item {date,value}
            eomDict[date.toISOString().substring(0, 7)] = { date: this.__dates[i], value: this.__values[i] };
        }
        const eomItems = Object.values(eomDict);
        const sampledDates = [];
        const sampledValues = [];
        if (includeFirst) {
            sampledDates.push(this.start);
            sampledValues.push(this.startValue);
        }
        for (let i = 0; i < eomItems.length; i++) {
            const item = eomItems[i];
            sampledDates.push(item.date);
            sampledValues.push(item.value);
        }
        return new TimeSeries(sampledDates, sampledValues, this.name);
    }

    /**
     * Returns a resampled TimeSeries of instance TimeSeries with a maximal fixed number of observations
     * @param numberOfObservations maximal number of observations in resampled timeseries,
     */
    resampleToFixedNumberOfObservations(numberOfObservations = 400): TimeSeries {
        // If we are under max number of observations. No need to resample
        if (this.length <= numberOfObservations) return this.clone();

        // Sample
        const sample = Math.ceil(this.length / numberOfObservations);
        const sampledDates = [];
        const sampledValues = [];
        for (let i = this.length - 1; i >= 0; i -= sample) {
            sampledDates.unshift(this.__dates[i]);
            sampledValues.unshift(this.__values[i]);
        }

        return new TimeSeries(sampledDates, sampledValues, this.name);
    }

    /**
     * Returns the total return of a timeseries from start to end ,
     * @param otherTimeSeries instance of TimeSeries to calculate excessReturn over
     */
    excessReturn(otherTimeSeries: TimeSeries): TimeSeries {
        if (!(otherTimeSeries instanceof TimeSeries)) throw new Error("Other time serie must be of type TimeSeries");
        if (otherTimeSeries.length !== this.length) throw new Error("TimeSeries objects needs to be of same length");
        const difference = [];
        const timeSeriesReturn = this.return();
        const otherReturn = otherTimeSeries.return();
        for (let i = 0; i < timeSeriesReturn.length; i++) {
            difference.push(Number(timeSeriesReturn.__values[i] - otherReturn.__values[i]));
        }
        return new TimeSeries(this.__dates, difference, "Excess return Timeseries");
    }

    /**
     * Verifies if a TimeSeries equals another TimeSerieSArrayStyle by checking if dates and values vector are equal
     * @param otherTimeSeries instance of TimeSeries to calculate excessReturn over
     */
    equals(otherTimeSeries: TimeSeries): boolean {
        if (!(otherTimeSeries instanceof TimeSeries)) throw new Error("Object other needs to be of type TimeSeries");

        for (let i = 0; i < this.__values.length; i++) {
            if (
                this.__values[i] !== otherTimeSeries.__values[i] ||
                timestampToComparable(this.__dates[i]) !== timestampToComparable(otherTimeSeries.__dates[i])
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Normalizes a TimeSeries by dividing each value with the startValue of the timeSeries and multiplying with a factor
     *  @param factor
     */
    normalize(factor = 1): TimeSeries {
        const startval = this.startValue;
        const normalizedVals = [];
        for (let i = 0; i < this.length; i++) {
            normalizedVals.push((this.__values[i] / startval) * factor);
        }
        return new TimeSeries(this.__dates, normalizedVals, this.name);
    }

    /**
     * Concatenates this time series to another using this serie as master
     *  @param otherTimeSeries instance of TimeSeries to concat this series to
     */
    prepend(otherTimeSeries: TimeSeries): TimeSeries {
        if (!(otherTimeSeries instanceof TimeSeries)) throw new Error("Other time serie must be of type TimeSeries");

        // If this starts earlier than other serie. Just return this
        if (timestampToComparable(this.start) < timestampToComparable(otherTimeSeries.start)) return this.clone();
        // Concat from start
        const preSeries = otherTimeSeries.range(otherTimeSeries.start, this.start);
        // To be able to concat end of pre series must equal start of this series
        if (timestampToComparable(this.start) !== timestampToComparable(preSeries.end)) {
            throw new Error("Cannot concat series since start date in this series not present in other series");
        }
        const factor = this.startValue / preSeries.endValue;

        // Loop pre series. No need to loop last value.
        const preValues = [];
        const preDates = [];

        // If this.dates of type Date. Assert others date. Otherwise ISO string
        const constructor = this.__dates[0].constructor;

        for (let i = 0; i < preSeries.length - 1; i++) {
            preValues.push(preSeries.__values[i] * factor);
            if (constructor === Date) preDates.push(new Date(preSeries.__dates[i]));
            else preDates.push(timestampToString(preSeries.__dates[i]));
        }

        return new TimeSeries([...preDates, ...this.__dates], [...preValues, ...this.__values], this.name);
    }

    /**
     * Caclculates trackingError against other timeSeries (benchmark) according to Fondbolagets Förenings standard with two year history
     * @param otherTimeSeries The timeseries to calculate tracking error against
     * @param date The date from which to calculate tracking error two years backwards, if not set to end value of timeseries
     */
    trackingError(otherTimeSeries: TimeSeries, date = this.end): number {
        if (!(otherTimeSeries instanceof TimeSeries)) throw new Error("Other time serie must be of type TimeSeries");

        const twoYearStart = DateHelper.addMonths(DateHelper.addYears(date, -2), -1).toString();

        const timeSeries = this.range(twoYearStart, date);
        const otherTimeSeriesTwoYears = otherTimeSeries.range(twoYearStart, date);

        const monthlyReturnsTimeSeries = timeSeries.resampleToMonthly().return();
        const monthlyReturnsOtherTs = otherTimeSeriesTwoYears.resampleToMonthly().return();

        const diffTs = monthlyReturnsTimeSeries.subtract(monthlyReturnsOtherTs);

        return stdev(diffTs.__values) * Math.sqrt(12);
    }

    informationRatio(otherTimeSeries: TimeSeries): number {
        if (!(otherTimeSeries instanceof TimeSeries)) throw new Error("Other time serie must be of type TimeSeries");
        if (otherTimeSeries.length !== this.length) throw new Error("TimeSeries objects needs to be of same length");

        const twoYearStart = DateHelper.addMonths(DateHelper.addYears(this.end, -2), -1).toString();

        const timeSeries = this.range(twoYearStart, this.end);
        const otherTimeSeriesTwoYears = otherTimeSeries.range(twoYearStart, otherTimeSeries.end);

        const monthlyReturnsTimeSeries = timeSeries.resampleToMonthly().return();
        const monthlyReturnsOtherTs = otherTimeSeriesTwoYears.resampleToMonthly().return();

        const diffTs = monthlyReturnsTimeSeries.subtract(monthlyReturnsOtherTs);

        return diffTs.add(1).cumProd().annualizedReturn() / this.trackingError(otherTimeSeries);
    }

    /**
     * Compound running adjustment of a time series with offset.
     *  @param adjustment yearly adjustment
     *  @param basis Yearfraction basis
     */
    compoundAdjustment(adjustment = 0, basis = YearFractionBasis.Actual365): TimeSeries {
        // If no adjustment return this
        if (adjustment === 0) return this.clone();
        const values = [];
        for (let i = 0; i < this.length; i++) {
            values.push(this.__values[i] * Math.pow(1 + adjustment, yearFraction(this.start, this.__dates[i], basis)));
        }
        return new TimeSeries(this.__dates, values, this.name);
    }

    /**
     * Running adjustment of a time series with offset.
     *  @param adjustment yearly adjustment
     *  @param basis Yearfraction basis
     */
    runningAdjustment(adjustment = 0, basis = YearFractionBasis.Actual365): TimeSeries {
        // If no adjustment return this
        if (adjustment === 0) return this.clone();
        const values = [];
        // Start running adjustment from startValue
        values.push(this.startValue);
        let previousValue = this.startValue;
        let currentValue;
        for (let i = 1; i < this.length; i++) {
            currentValue =
                previousValue *
                (this.__values[i] / this.__values[i - 1] + adjustment * yearFraction(this.__dates[i - 1], this.__dates[i], basis));
            values.push(currentValue);
            previousValue = currentValue;
        }
        return new TimeSeries(this.__dates, values, this.name);
    }
}
