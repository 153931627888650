import React, { Fragment, useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";

import { Svgs } from "../../../../components/src";

import { TAccountChartForm } from "../../components/TAccountChartForm";
import { getTAccountChart, getClients } from "./queries";
import { InstrumentTypeEnum, PermissionAssetEnum, PermissionTypeEnum } from "../../types.generated";
import { userHaveAccessTo } from "../../common/Permissions";

const GET_FORM_DATA = gql`
    query getFormData($filter: InstrumentFilterInput) {
        instruments(filter: $filter) {
            _id
            isin
            name
            longName
            bloombergTicker
            currency
        }
    }
`;

const UPDATE_T_ACCOUNTS_CHART = gql`
    mutation UpdateTAccountChart($input: UpdateTAccountChartInput) {
        updateTAccountChart(input: $input) {
            _id
        }
    }
`;

const IMPORT_TACCOUNTS = gql`
    mutation importTAccountChartAccounts($id: GraphQLObjectId!, $csv: String!) {
        importTAccountChartAccounts(_id: $id, csv: $csv) {
            _id
        }
    }
`;

const IMPORT_MAPPINGS = gql`
    mutation importTAccountChartMappings($id: GraphQLObjectId!, $csv: String!) {
        importTAccountChartMappings(_id: $id, csv: $csv) {
            _id
        }
    }
`;

export function TAccountChartPage(): React.ReactElement {
    const { id, tAccountIndex: tAccountIndexString, tAccountMappingIndex: tAccountMappingIndexString }: any = useParams();
    const [instruments, setInstruments] = useState([]);
    const [clientInstruments, setClientInstruments] = useState([]);
    const [tAccountChart, setTAccountChart] = useState(null);

    const [{ fetching: loadingInstruments, error: errorInstruments, data: dataInstruments }] = useQuery({
        query: GET_FORM_DATA,
        variables: { filter: { typeIn: [InstrumentTypeEnum.Instrument] } }
    });

    const [{ fetching: loadingTAccountChart, error: errorTAccountChart, data: dataTAccountChart }, refetchTAccountChart] = useQuery({
        query: getTAccountChart,
        variables: { id }
    });

    // Fetch clients, dropdown for creating new tAChart
    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: getClients
    });

    const [_, updateTAccountChart] = useMutation(UPDATE_T_ACCOUNTS_CHART);
    const [__, importTAccountChartAccounts] = useMutation(IMPORT_TACCOUNTS);
    const [___, importTAccountChartMappings] = useMutation(IMPORT_MAPPINGS);

    // instruments
    useEffect(() => {
        if (dataInstruments && dataInstruments.instruments) {
            const instr = cloneDeep(dataInstruments.instruments);
            setInstruments(instr);
        }
    }, [dataInstruments]);

    // tAccountChart
    useEffect(() => {
        if (dataTAccountChart && dataTAccountChart.tAccountChart) {
            const data = cloneDeep(dataTAccountChart.tAccountChart);
            delete data.__typename;
            data.tAccounts.forEach((a) => {
                delete a.__typename;
            });
            data.tAccountMappings.forEach((m) => {
                delete m.__typename;
                delete m.selector.__typename;
                m.values.forEach((v) => {
                    delete v.__typename;
                });
            });
            setTAccountChart(data);
        }
    }, [dataTAccountChart, refetchTAccountChart]);

    // tAccountChart.client.instruments
    useEffect(() => {
        if (tAccountChart) {
            const data = cloneDeep(tAccountChart);
            if (data.client && data.client.instruments) {
                setClientInstruments(data.client.instruments);
            }
        }
    }, [tAccountChart]);

    // loading
    if (loadingInstruments || loadingTAccountChart || loadingClients) {
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );
    }

    // error
    if (errorInstruments || errorTAccountChart || errorClients) {
        return (
            <div className="loader">
                <h3>
                    Failed loading data:{" "}
                    {errorInstruments ? errorInstruments.message : errorTAccountChart ? errorTAccountChart.message : errorClients.message}
                </h3>
            </div>
        );
    }

    const tAccountIndex = typeof tAccountIndexString === "undefined" ? -1 : Number(tAccountIndexString);

    const tAccountMappingIndex = typeof tAccountMappingIndexString === "undefined" ? -1 : Number(tAccountMappingIndexString);

    const accountingReadWriteAccess =
        dataClients &&
        dataClients.me &&
        dataClients.me.roles &&
        userHaveAccessTo("Any", PermissionAssetEnum.Accounting, dataClients.me.roles, PermissionTypeEnum.ReadWrite)
            ? true
            : false;

    return (
        <Fragment>
            {tAccountChart ? (
                <TAccountChartForm
                    key={`${tAccountChart._id}-${tAccountChart.version}-${tAccountChart.tAccounts.length}-${tAccountChart.tAccountMappings.length}`}
                    id={id}
                    tAccountChart={tAccountChart}
                    tAccountIndex={tAccountIndex}
                    tAccountMappingIndex={tAccountMappingIndex}
                    instruments={[...instruments, ...clientInstruments]}
                    accountingReadWriteAccess={accountingReadWriteAccess}
                    onSave={(value: any) => {
                        const input = cloneDeep(value);
                        delete input.client;
                        delete input.locked;
                        delete input.__typename;
                        input.tAccounts.forEach((a) => {
                            delete a.__typename;
                        });
                        input.tAccountMappings.forEach((m) => {
                            delete m.__typename;
                            delete m.selector.__typename;
                            m.values.forEach((v) => {
                                delete v.__typename;
                            });
                            if (m.selector && m.selector.instruments && m.selector.instruments.length) {
                                m.selector.instruments.forEach((instrument) => {
                                    delete instrument.__typename;
                                });
                            }
                        });
                        updateTAccountChart({ input });
                        refetchTAccountChart();
                    }}
                    onImport={async (args) => {
                        if (args.kind === "taccounts") {
                            await importTAccountChartAccounts({ id: tAccountChart._id, csv: args.import }).then((res) => {
                                refetchTAccountChart();
                            });
                        } else if (args.kind === "mappings") {
                            await importTAccountChartMappings({ id: tAccountChart._id, csv: args.import }).then(() => {
                                refetchTAccountChart();
                            });
                        }
                    }}
                />
            ) : (
                <div className="loader">
                    <h4>No data found</h4>
                </div>
            )}
        </Fragment>
    );
}
