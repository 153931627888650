import React, { Fragment, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { cloneDeep } from "lodash";
import { Panel, IColumn, ICommandBarItemProps } from "@fluentui/react";

import { twoDecPriceFormat } from "../../../common/src";
import { DateForm } from "../../../components/src/dateForm";

import { YesNoModal } from "../components/YesNoModal";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { MiniTransactionForm } from "./MiniTransactionForm";
import { CorporateActionForm } from "./CorporateActionForm";
import { useQueryState } from "../../../components/src/use-query-state";
import { MultipleSelectDetailsGrid } from "./MultipleSelectDetailsGrid";
import { CorporateAction, CorporateActionStatusEnum, PermissionAssetEnum } from "../types.generated";
import { GET_ME } from "../common/queries";

const GET_CORPORATE_ACTIONS = gql`
    query corporateActions($filter: CorporateActionFilterInput) {
        corporateActions(filter: $filter) {
            _id
            clientId
            client {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            instrumentId
            type
            instrument {
                _id
                name
                isin
            }
            currency
            amount
            foreignTax
            messageType
            corporateActionEvent
            status
            text
            externalId
            error
            correspondingTransactionId
            correspondingTransaction {
                _id
                status
            }
            updateTimestamp
            reportIds
            swiftId
            name
        }
    }
`;

const UPSERT_CORPORATE_ACTIONS = gql`
    mutation upsertCorporateActions($input: [CorporateActionInput!]!) {
        upsertCorporateActions(input: $input) {
            _id
            status
        }
    }
`;

const corporateActionGroups = [
    CorporateActionStatusEnum.Pending,
    CorporateActionStatusEnum.Preliminary,
    CorporateActionStatusEnum.Waiting,
    CorporateActionStatusEnum.Confirmed,
    CorporateActionStatusEnum.Settled
];

const groupByStatus = (corporateActions: CorporateAction[], corporateActionGroups: CorporateActionStatusEnum[]) => {
    const result: Record<string, CorporateAction[]> = {};
    corporateActionGroups.forEach((status) => {
        result[status] = [];
    });

    corporateActions = sortBy(corporateActions, "exDate").reverse();
    corporateActions.forEach((corporateAction) => {
        if (corporateActionGroups.includes(corporateAction.status)) {
            result[corporateAction.status].push(corporateAction);
        }
    });
    const optimalCorporateActionGroups = cloneDeep(corporateActionGroups);
    const optimalResult = cloneDeep(result);
    const emptyStatuses = [];
    for (const key in result) {
        if (result[key].length === 0) {
            delete optimalResult[key];
            emptyStatuses.push(key);
        }
    }
    for (let i = optimalCorporateActionGroups.length - 1; i >= 0; i--) {
        const status = optimalCorporateActionGroups[i];
        if (emptyStatuses.includes(status)) {
            optimalCorporateActionGroups.splice(i, 1);
        }
    }
    return { optimalResult, optimalCorporateActionGroups };
};

export const CorporateActionsUpdateGrid = ({ items, onStatusUpdated }): React.ReactElement => {
    const location = useLocation();
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [stateUpdateCorporateAction, updateCorporateActionStatus] = useMutation(UPSERT_CORPORATE_ACTIONS);

    useEffect(() => {
        if (stateUpdateCorporateAction && stateUpdateCorporateAction.data && !stateUpdateCorporateAction.error) {
            if (onStatusUpdated) {
                onStatusUpdated();
            }
            setSelected(null);
        }
    }, [onStatusUpdated, stateUpdateCorporateAction]);

    const columns: IColumn[] = [
        {
            key: "clientName",
            name: "Client",
            fieldName: "clientName",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 160,
            onRender: (item: CorporateAction) => {
                return item.client && item.client._id ? <Link to={"/parties/" + item.client._id}>{item.client.name}</Link> : null;
            }
        },

        {
            key: "externalId",
            name: "Event ref",
            fieldName: "externalId",

            isResizable: true,
            minWidth: 110,
            onRender: (item: CorporateAction) => {
                return item._id ? (
                    <Link to={"/corporateactions/corporateaction/" + item._id + location.search}>{item.externalId}</Link>
                ) : (
                    <span>{item.externalId}</span>
                );
            }
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 60
        },

        {
            key: "type",
            name: "Type",
            fieldName: "type",
            isResizable: true,
            minWidth: 50,
            onRender: (item: CorporateAction) => {
                return item._id ? (
                    <Link to={"/corporateactions/corporateaction/" + item._id + location.search}>{item.corporateActionEvent}</Link>
                ) : (
                    <span>{item.corporateActionEvent}</span>
                );
            }
        },
        {
            key: "instrumentName",
            name: "Instrument",
            fieldName: "instrumentName",
            isResizable: true,
            minWidth: 120,
            onRender: (item: CorporateAction) => {
                return item.instrument ? <Link to={"/instruments/" + item.instrument._id}>{item.instrument.name}</Link> : null;
            }
        },
        {
            key: "foreignTax",
            name: "Foreign tax",
            fieldName: "foreignTax",
            isResizable: true,
            minWidth: 80,
            onRender: (item: CorporateAction) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.foreignTax)}
                    </span>
                );
            }
        },
        {
            key: "amount",
            name: "Amount",
            fieldName: "amount",
            isResizable: true,
            minWidth: 60,
            onRender: (item: CorporateAction) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.amount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80,
            onRender: (item: CorporateAction) => {
                return <span>{item.exDate}</span>;
            }
        },
        {
            key: "paymentDate",
            name: "Value date",
            fieldName: "paymentDate",
            minWidth: 80
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            isResizable: true,
            minWidth: 120
        },
        {
            key: "correspondingTransactionId",
            name: "Matched with",
            fieldName: "correspondingTransactionId",
            minWidth: 80,
            onRender: (item: CorporateAction) => {
                return item.correspondingTransactionId ? (
                    <Link to={"/corporateactions/minitransaction/" + item.correspondingTransactionId + location.search}>
                        {item.correspondingTransactionId}
                    </Link>
                ) : (
                    <span>{item._id}</span>
                );
            }
        },
        {
            key: "updateTimestamp",
            name: "Update timestamp",
            fieldName: "updateTimestamp",
            isResizable: true,
            minWidth: 120,
            onRender: (item: CorporateAction) => {
                return <span>{dateFormater(item.updateTimestamp)}</span>;
            }
        }
    ];

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to " + CorporateActionStatusEnum.Pending,
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            updateStatus(selected, CorporateActionStatusEnum.Pending);
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to " + CorporateActionStatusEnum.Preliminary,
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            updateStatus(selected, CorporateActionStatusEnum.Preliminary);
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to " + CorporateActionStatusEnum.Confirmed,
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            updateStatus(selected, CorporateActionStatusEnum.Confirmed);
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to " + CorporateActionStatusEnum.Settled,
                        onClick: () => {
                            updateStatus(selected, CorporateActionStatusEnum.Settled);
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to " + CorporateActionStatusEnum.Deleted,
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            setModal({
                                showModal: true,
                                payload: { newStatus: CorporateActionStatusEnum.Deleted, ids: selected }
                            });
                        }
                    }
                ]
            }
        }
    ];

    const onSelectedChange = (e) => {
        setSelected(e.map((i) => i._id));
    };

    const updateStatus = async (itemIds: string[], newStatus: CorporateActionStatusEnum) => {
        const allUpdates = itemIds.map(
            async (id: string) =>
                await updateCorporateActionStatus({
                    input: { _id: id, status: newStatus }
                })
        );
        await Promise.all(allUpdates);
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete selected corporate actions?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updateStatus(modal.payload.ids, CorporateActionStatusEnum.Deleted);
                    }}
                />
            ) : null}
            <MultipleSelectDetailsGrid
                key={items}
                items={items}
                columns={columns}
                commands={selected && selected.length > 0 ? commands : []}
                onSelectedChange={onSelectedChange}
            />
        </div>
    );
};

export const CorporateActionsByStatus = ({ exDateStart }: { exDateStart: string }): React.ReactElement => {
    const recordDate = serializeSwedenDate(new Date(exDateStart).setDate(new Date(exDateStart).getDate() + 1));

    // All corporateActions except deleted
    const filterAll = {
        statusIn: [
            CorporateActionStatusEnum.Pending,
            CorporateActionStatusEnum.Preliminary,
            CorporateActionStatusEnum.Waiting,
            CorporateActionStatusEnum.Confirmed,
            CorporateActionStatusEnum.Settled
        ],
        recordDateStart: recordDate
    };
    const [{ fetching: loading, error, data: dataCorporateActions }, refetch] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterAll },
        requestPolicy: "network-only"
        //pollInterval: 5000
    });

    if (loading) return <p>Loading corporate actions</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    const allCorporateActions = dataCorporateActions.corporateActions as CorporateAction[];
    const { optimalResult, optimalCorporateActionGroups } = groupByStatus(allCorporateActions, corporateActionGroups);

    return (
        <div>
            {optimalCorporateActionGroups.map((status) => (
                <div key={status} className="mt-4">
                    <h2>{status}</h2>
                    <CorporateActionsUpdateGrid items={optimalResult[status]} onStatusUpdated={refetch} />
                </div>
            ))}
        </div>
    );
};

export function CorporateActionsPage(): React.ReactElement {
    const params: any = useParams();
    const id = params.id;
    const type = params.type;
    const [exDateStart] = useQueryState("exDateStart", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));

    const [{ fetching: loadingMe, error: errorMe, data }] = useQuery({
        query: GET_ME,
        requestPolicy: "network-only"
    });

    const location = useLocation();
    const navigate = useNavigate();

    if (loadingMe) return <div>Loading...</div>;

    if (errorMe)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorMe, null, 2)}</pre>
            </div>
        );
    return (
        <Fragment>
            <div className="row">
                <div className="col-4 d-flex">
                    <DateForm defaultDateString={exDateStart} dateName={"exDateStart"}></DateForm>
                </div>
                <div className="col-8 right">
                    {data &&
                    data.me &&
                    data.me.frontendRole &&
                    data.me.frontendRole.assets &&
                    (data.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) || data.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual/coac_management.md"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                    ) : null}
                </div>
                <div className="row">
                    <div className={"col-12"}>
                        <CorporateActionsByStatus exDateStart={exDateStart} />
                    </div>
                </div>
                <Panel
                    isOpen={id != null}
                    isBlocking={false}
                    onDismiss={() => {
                        navigate({
                            pathname: "/corporateactions",
                            search: location.search
                        });
                    }}
                    layerProps={{ eventBubblingEnabled: true }}
                >
                    {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                    {id && type === "corporateaction" ? <CorporateActionForm id={id} tradeDate={exDateStart} /> : null}
                </Panel>
            </div>
        </Fragment>
    );
}
