import React, { Fragment, ReactElement, useCallback, useState } from "react";
import { gql, useMutation, useQuery } from "urql";
import stableStringify from "json-stable-stringify";
import { isValidMongoDBObjectID, useAlertTimeOut } from "../../../components/src/common/Utils";
import { useParams } from "react-router-dom";
import { AttachmentForm } from "./AttachmentForm";
import { Alert } from "react-bootstrap";
import { SYSTEM_PARTY_ID } from "../Constants";
import { recursivelyRemoveKey } from "../../../common/src";
import { formikUrqlErrorFormater } from "../../../components/src/common/formik-urql-error-helper";

const getValuation = gql`
    query getValuation($_id: GraphQLObjectId!) {
        valuation(_id: $_id) {
            _id
            instrumentId
            date
            updateTimestamp
            records {
                source
                bloombergTicker
                writeTimestamp
                writeUser
                updateUserInfo {
                    name
                }
                prices {
                    type
                    value
                    currency
                }
            }
            attachments {
                fileId
                fileName
                mimeType
                updateTimestamp
            }
        }
    }
`;

const UPDATE_ATTACHMENTS = gql`
    mutation upsertValuationAttachments($valuationId: GraphQLObjectId!, $attachments: [AttachmentInput!]!) {
        upsertValuationAttachments(valuationId: $valuationId, attachments: $attachments) {
            _id
            attachments {
                fileId
                fileName
                mimeType
            }
        }
    }
`;

export const ValuationPage = (): ReactElement => {
    const params: any = useParams();

    const [_, upsertAttachments] = useMutation(UPDATE_ATTACHMENTS);

    let id = "000000000000000000000000";
    if (isValidMongoDBObjectID(params.id)) {
        id = params.id;
    }

    const [{ fetching, error, data }] = useQuery({ query: getValuation, variables: { _id: id } });

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            attachments = recursivelyRemoveKey(attachments, "__typename");

            upsertAttachments({ valuationId: id, attachments })
                .then((result) => {
                    if ("error" in result && result.error) {
                        const message = formikUrqlErrorFormater(result.error);
                        setAlert({ color: "danger", visible: true, message });
                    } else {
                        setAlert({ color: "success", visible: true, message: "Attachments updated successfully!" });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setAlert({ color: "danger", visible: true, message: error.toString() });
                });
        },
        [id, upsertAttachments]
    );

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    if (!isValidMongoDBObjectID(params.id)) {
        return <p>error: not a valid objectId</p>;
    }

    return (
        <Fragment>
            <pre>{stableStringify(data.valuation, { space: "  " })}</pre>
            <AttachmentForm
                clientId={SYSTEM_PARTY_ID}
                attachments={data.valuation.attachments || []}
                onChange={callBackOnChangeAttachment}
            />
            {alert.visible ? (
                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
        </Fragment>
    );
};
