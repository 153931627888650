import React, { useState, useEffect, useCallback } from "react";
import MarkdownIt from "markdown-it";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import MdEditor from "react-markdown-editor-lite";
import { gql, useMutation, useQuery } from "urql";
import { useQueryState } from "../../../../components/src/use-query-state";
import { Tabs, Tab } from "react-bootstrap";
import { DateForm } from "../../../../components/src/dateForm";

import { useAlertTimeOut, usePrevious } from "../../../../components/src/common/Utils";
import { serializeSwedenDate } from "../dateFormater";
import { cloneDeep } from "lodash";

const GET_DATA = gql`
    query getData($clientId: GraphQLObjectId!, $date: GraphQLDateString) {
        portfolioManagerComment: reports(clientId: $clientId, type: "PortfolioManagerComment", date: $date, lastOnly: false) {
            _id
            type
            data
            name
            clientId
        }
    }
`;

export const UPDATE_PORTFOLIO_MANAGER_COMMENT = gql`
    mutation updateReport($input: UpdateReportInput!) {
        update: updateReport(input: $input) {
            _id
        }
    }
`;

export const CREATE_PORTFOLIO_MANAGER_COMMENT = gql`
    mutation createReport($input: CreateReportInput!) {
        create: createReport(input: $input) {
            _id
        }
    }
`;

export interface PortfolioManagerCommentProps {
    locale: string;
    refetch?: () => void;
    date: string;
}

const tabs = {
    sv: "sv",
    en: "en"
};

export function PortfolioManagerComment({ locale = "sv", refetch, date }: PortfolioManagerCommentProps): React.ReactElement {
    const { id }: any = useParams();
    const previousDate = usePrevious(date);

    const clientId = id && id !== "new" ? id : "000000000000000000000000";

    const [comment, setComment] = useState("");
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    const [{ fetching: loading, error, data }, refetchData] = useQuery({
        query: GET_DATA,
        variables: { clientId: clientId, date: date },
        requestPolicy: "network-only"
    });

    useAlertTimeOut(alert, setAlert, 5);

    const [stateCreate, createMutation] = useMutation(CREATE_PORTFOLIO_MANAGER_COMMENT);
    const [stateUpdate, updateMutation] = useMutation(UPDATE_PORTFOLIO_MANAGER_COMMENT);

    useEffect(() => {
        if (
            previousDate !== date ||
            (stateCreate && stateCreate.data && !stateCreate.data.error) ||
            (stateUpdate && stateUpdate.data && !stateUpdate.data.error)
        ) {
            refetchData();
        }
    }, [stateCreate, stateUpdate, date, previousDate, refetchData]);

    useEffect(() => {
        if (data && data.portfolioManagerComment.length > 0) {
            setComment(data.portfolioManagerComment[0].data[locale]);
        } else {
            setComment("");
        }
    }, [data, locale, clientId, date]);

    if (typeof clientId === "undefined" || clientId === "all") {
        return <div>"Select a client"</div>;
    }

    const mdParser = new MarkdownIt();

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const isCreateMode = data && data.portfolioManagerComment.length === 0;

    return (
        <div className="page">
            <MdEditor
                style={{ height: "500px" }}
                value={comment}
                renderHTML={(text) => mdParser.render(text)}
                onChange={({ text }) => {
                    setComment(text);
                }}
            />

            <div className="mt-4">
                <Button
                    id="button"
                    size="sm"
                    variant="primary"
                    onClick={async () => {
                        if (isCreateMode) {
                            const input = {
                                clientId: clientId,
                                type: "PortfolioManagerComment",
                                date: date,
                                data: { sv: "", en: "" },
                                name: "Portfolio Manager Comment"
                            };
                            input.data[locale] = comment;

                            await createMutation({ input })
                                .then((result) => {
                                    if (result.error) {
                                        setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                    } else {
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `New portfolio manager comment'${result.data.create._id}' created successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.toString());
                                })
                                .finally(() => {
                                    refetchData();
                                    if (refetch) {
                                        refetch();
                                    }
                                });
                        } else {
                            const input = cloneDeep(data.portfolioManagerComment[0]);
                            delete input.__typename;
                            input.date = date;
                            input.data[locale] = comment;

                            await updateMutation({ input: input })
                                .then((result) => {
                                    if (result.error) {
                                        setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                    } else {
                                        setAlert({
                                            color: "success",
                                            visible: true,
                                            message: `The portfolio manager comment with id '${result.data.update._id}' updated successfully!`
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.toString());
                                })
                                .finally(() => {
                                    refetchData();
                                    if (refetch) {
                                        refetch();
                                    }
                                });
                        }
                    }}
                >
                    {isCreateMode ? "Create" : "Update"}
                </Button>
            </div>
            <div className="row mt-4">
                <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                    {alert.visible ? (
                        <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                            {alert.message}
                        </Alert>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export const PortfolioManagerCommentTabs = (): React.ReactElement => {
    const [date, _setDate] = useQueryState("date", serializeSwedenDate(new Date()));
    const [lang, _setLang] = useQueryState("lang", tabs.sv);

    if (!(lang in tabs)) {
        _setLang(tabs.sv);
    }

    const onTabChange = useCallback(
        (tabId) => {
            _setLang(tabId);
        },
        [_setLang]
    );

    return (
        <div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <DateForm defaultDateString={date} dateName={"date"}></DateForm>
                </div>
                <div className="card card-body bg-light col-12">
                    <Tabs onSelect={onTabChange} activeKey={lang} transition={false}>
                        <Tab eventKey="sv" title="sv">
                            <PortfolioManagerComment locale={"sv"} date={date} />
                        </Tab>
                        <Tab eventKey="en" title="en">
                            <PortfolioManagerComment locale={"en"} date={date} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
