import React, { Fragment, useEffect } from "react";
import { gql, useQuery } from "urql";
import { Base64 } from "js-base64";
import { saveAs } from "file-saver";

import { Button } from "react-bootstrap";

const EXPORT_MAPPINGS = gql`
    query tAccountChartMappingsExport($id: GraphQLObjectId!) {
        tAccountChartMappingsExport(_id: $id)
    }
`;

interface IExportTAccountChartMappingsButton {
    tAccountChartId: string;
    className: string;
    children?: string | React.ReactElement;
}

export const ExportTAccountChartMappingsButton = ({
    tAccountChartId,
    className = "btn-sm",
    children
}: IExportTAccountChartMappingsButton): React.ReactElement => {
    const [{ data }, refetch] = useQuery({
        query: EXPORT_MAPPINGS,
        requestPolicy: "network-only",
        variables: { id: tAccountChartId },
        pause: true
    });

    useEffect(() => {
        if (data && data.tAccountChartMappingsExport) {
            saveAs(
                "data:text/csv;charset=utf-8;base64," + Base64.encode(data.tAccountChartMappingsExport),
                `export-${tAccountChartId}-mappings.csv`
            );
        }
    }, [data, tAccountChartId]);

    return (
        <Fragment>
            <Button
                type="button"
                className={className}
                onClick={() => {
                    refetch();
                }}
            >
                {children}
            </Button>
        </Fragment>
    );
};
