import React, { ReactElement, useState } from "react";
import { cloneDeep } from "lodash";

import {
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    getSortedRowModel,
    GroupingState,
    Row,
    useReactTable
} from "@tanstack/react-table";

import { Svgs } from "../../../components/src";
import { exportToXlsx2 } from "../../../components/src/common/exportToXlsx2";

export interface Props {
    columns: any[];
    data: any;
    expanded: { [key: string]: boolean };
    groupBy?: string[];
    hiddenColumns?: string[];
    compactMode?: boolean;
    captionColumn?: string;
    sortBy?: {
        id: string;
        desc: boolean;
    }[]; //must be memoized
    showExcelButton?: boolean;
    showGroupByBox?: boolean;
    customColumnSize?: boolean;
}

export const TableGrouper = ({
    columns,
    data,
    expanded,
    groupBy = [],
    hiddenColumns = [],
    compactMode,
    captionColumn,
    sortBy,
    showExcelButton = false,
    showGroupByBox = false,
    customColumnSize = false
}: Props): ReactElement => {
    const [grouping, setGrouping] = useState<GroupingState>(groupBy);
    const [expandedState, setExpanded] = useState<ExpandedState>(expanded);
    const columnVisibility = Object.fromEntries(hiddenColumns.map((x) => [x, false]));

    const table = useReactTable({
        data,
        columns,
        // initialState: {
        //     expanded
        // },
        state: {
            columnVisibility,
            grouping,
            expanded: expandedState,
            sorting: sortBy
        },
        autoResetExpanded: false,
        onGroupingChange: setGrouping,
        onExpandedChange: setExpanded,
        getSubRows: (row: any) => row.subRows,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel()
    });

    let rowData: any[] = [];
    if (rowData.length < 1 && showExcelButton) {
        const columnHeaders = {};
        columns[0].columns.forEach((column) => {
            columnHeaders[column.accessor] = column.Header;
        });
        table.getRowModel().rows.forEach((row: Row<any>) => {
            const groupedRow = cloneDeep(row.original);

            for (const key of Object.keys(groupedRow)) {
                if (row.id && !row.id.includes(key) && typeof groupedRow[key] === "string") {
                    groupedRow[key] = "";
                }
                // rename key from columnHeaders
                if (columnHeaders[key]) {
                    groupedRow[columnHeaders[key]] = groupedRow[key];
                    delete groupedRow[key];
                }
            }
            // Not adding expanded rows twice
            if (row.id !== row.index.toString()) {
                rowData.push(groupedRow);
            }

            if (row.subRows && row.subRows.length && !row.subRows[0].subRows.length) {
                const subRows: any[] = [];
                row.subRows.forEach((subRow) => {
                    if (!subRow.getIsGrouped()) {
                        const subRowOriginal = cloneDeep(subRow);
                        for (const key of Object.keys(subRowOriginal)) {
                            if (subRow.id && !subRow.id.includes(key) && typeof subRowOriginal[key] === "string") {
                                subRowOriginal[key] = "";
                            }
                            // rename key from columnHeaders
                            if (columnHeaders[key]) {
                                subRowOriginal[columnHeaders[key]] = subRowOriginal[key];
                                delete subRowOriginal[key];
                            }
                        }
                        subRows.push(subRow.original);
                    }
                });
                rowData = rowData.concat(subRows);
            }
        });
    }

    const downloadExcel = () => {
        exportToXlsx2([rowData], "table.xlsx");
    };

    return (
        <div className="captor-table">
            {showExcelButton ? (
                <div
                    style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                    onClick={() => {
                        downloadExcel();
                    }}
                >
                    <Svgs.Excel />
                </div>
            ) : null}
            <table className="table table-striped table-xs" role="table">
                <thead>
                    {compactMode
                        ? table.getHeaderGroups().map((headerGroup) => {
                              const totalColumnsSpan = table
                                  .getHeaderGroups()[0]
                                  .headers.map((x) => x.colSpan)
                                  .reduce((acc, curr) => acc + curr, 0);
                              const columnsSpan = headerGroup.headers
                                  .filter((h) => !h.column.getIsGrouped() && h.column.id !== captionColumn)
                                  .map((x) => x.colSpan)
                                  .reduce((acc, curr) => acc + curr, 0);
                              const neededSpan = totalColumnsSpan - columnsSpan;
                              const captionHeader = headerGroup.headers.find((h) => h.column.id === captionColumn);

                              return (
                                  <tr key={headerGroup.id} className="tr" role="row">
                                      {neededSpan > 0 ? (
                                          <th
                                              key={"column0"}
                                              className="th"
                                              colSpan={neededSpan}
                                              style={customColumnSize && captionHeader ? { width: captionHeader.getSize() } : undefined}
                                          ></th>
                                      ) : null}
                                      {headerGroup.headers.map((header) =>
                                          !header.column.getIsGrouped() && header.column.id !== captionColumn ? (
                                              <th
                                                  key={header.id}
                                                  className="th"
                                                  role="columnheader"
                                                  colSpan={header.colSpan}
                                                  style={customColumnSize ? { width: header.getSize() } : undefined}
                                              >
                                                  {header.column.getCanGroup() && showGroupByBox ? (
                                                      // If the column can be grouped, let's add a toggle
                                                      <span className="me-1">
                                                          <input
                                                              id="cb"
                                                              type="checkbox"
                                                              title="Toggle GroupBy"
                                                              checked={header.column.getIsGrouped()}
                                                              onClick={header.column.getToggleGroupingHandler()}
                                                              readOnly
                                                          ></input>
                                                      </span>
                                                  ) : null}
                                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                              </th>
                                          ) : null
                                      )}
                                  </tr>
                              );
                          })
                        : table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id} className="tr" role="row">
                                  {headerGroup.headers.map((header) => (
                                      <th
                                          key={header.id}
                                          className="th"
                                          role="columnheader"
                                          colSpan={header.colSpan}
                                          style={customColumnSize ? { width: header.getSize() } : undefined}
                                      >
                                          {header.column.getCanGroup() && showGroupByBox ? (
                                              // If the column can be grouped, let's add a toggle
                                              <span className="me-1">
                                                  <input
                                                      id="cb"
                                                      type="checkbox"
                                                      title="Toggle GroupBy"
                                                      checked={header.column.getIsGrouped()}
                                                      onClick={header.column.getToggleGroupingHandler()}
                                                      readOnly
                                                  ></input>
                                              </span>
                                          ) : null}
                                          {flexRender(header.column.columnDef.header, header.getContext())}
                                      </th>
                                  ))}
                              </tr>
                          ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        if (compactMode) {
                            const groupedCells = row.getVisibleCells().filter((c) => c.column.getIsGrouped());
                            //caption cell
                            const captionCell = row
                                .getVisibleCells()
                                .find((c) => c.column.id === captionColumn && !c.getIsPlaceholder() && c.getValue());
                            if (captionCell) {
                                groupedCells.push(captionCell);
                            }
                            // other cells
                            const cells = row
                                .getVisibleCells()
                                .filter((c) => !c.getIsPlaceholder() && !c.getIsGrouped() && c.column.id !== captionColumn);
                            const totalColumnsSpan = table
                                .getHeaderGroups()[0]
                                .headers.map((x) => x.colSpan)
                                .reduce((acc, curr) => acc + curr, 0);
                            return (
                                <tr key={row.id} className="tr" role="row">
                                    <td
                                        key={"group-column" + row.id}
                                        className="td"
                                        role="cell"
                                        style={{ width: 300 }}
                                        colSpan={totalColumnsSpan - cells.length}
                                    >
                                        {groupedCells.map((cell, cellIndex) => {
                                            return cell.getIsPlaceholder() ? null : (
                                                <React.Fragment key={cell.id}>
                                                    <span
                                                        {...{
                                                            onClick: row.getToggleExpandedHandler(),
                                                            style: { cursor: "pointer", paddingRight: "5px", paddingLeft: cellIndex * 10 }
                                                        }}
                                                    >
                                                        {row.subRows.length > 0 ? (row.getIsExpanded() ? "⬇" : "➡") : null}
                                                    </span>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}{" "}
                                                    {row.subRows.length > 0 ? "(" + row.subRows.length + ")" : null}
                                                </React.Fragment>
                                            );
                                        })}
                                    </td>

                                    {cells.map((cell) => {
                                        return cell.getIsPlaceholder() ? null : (
                                            <td key={cell.id} className="td" role="cell" style={{ width: cell.column.getSize() }}>
                                                {cell.getIsAggregated()
                                                    ? flexRender(
                                                          cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                                                          cell.getContext()
                                                      )
                                                    : flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={row.id} className="tr" role="row">
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id} className="td" role="cell" style={{ width: cell.column.getSize() }}>
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        <span
                                                            {...{
                                                                onClick: row.getToggleExpandedHandler(),
                                                                style: { cursor: "pointer", paddingRight: "5px" }
                                                            }}
                                                        >
                                                            {row.getIsExpanded() ? "⬇" : "➡"}
                                                        </span>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                                                    </>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated renderer for cell
                                                    flexRender(
                                                        cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    flexRender(cell.column.columnDef.cell, cell.getContext())
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
        </div>
    );
};
