import React, { Fragment, useMemo } from "react";
import { gql } from "urql";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";

import { useGetAgreementsQuery } from "./AgreementsPage.generated";
import { AgreementTypeEnum, AgreementStatusEnum, AgreementFilterInput } from "../types.generated";
import {
    SelectColumnFilter,
    SelectColumnFilterArrayOfIds,
    SelectColumnFilterType
} from "../../../components/src/react-table/ReactTableFilters";
import { ReactTable } from "../../../components/src/react-table/ReactTable";

export const GetAgreements = gql`
    query GetAgreements($filter: AgreementFilterInput) {
        agreements(filter: $filter) {
            _id
            attachments {
                fileId
                clientId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            client {
                _id
                name
            }
            clientId
            counterParties {
                _id
                name
            }
            counterPartyIds
            createTimestamp
            description
            parties {
                _id
                name
            }
            partyIds
            status
            type
            approvedInfo {
                status
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                updateTimestamp
            }
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            attachments {
                fileId
            }
        }
    }
`;

export const AgreementsPage = (): React.ReactElement => {
    const filter: AgreementFilterInput = { statusIn: Object.values(AgreementStatusEnum) };
    const [{ fetching: loading, error, data }] = useGetAgreementsQuery({ variables: { filter } });

    const dataExtended: any[] = [];
    if (data) {
        data.agreements.forEach((agreement) => {
            const agreementExtended: any = cloneDeep(agreement);

            if (agreement.approvedInfo && agreement.approvedInfo.length) {
                agreementExtended.approved = agreement.approvedInfo[0].status;
            } else {
                agreementExtended.approved = "Not handled";
            }
            dataExtended.push(agreementExtended);
        });
    }

    const columns = useMemo(() => {
        const partyById: Record<string, { _id: string; name: string }> = {};
        const counterPartyById: Record<string, { _id: string; name: string }> = {};
        const clientById: Record<string, { _id: string; name: string }> = {};

        if (data) {
            data.agreements.forEach((agreement) => {
                agreement.counterParties.forEach((party) => {
                    if (!(party._id in counterPartyById)) {
                        counterPartyById[party._id] = party;
                    }
                });
                agreement.parties.forEach((party) => {
                    if (party && !(party._id in partyById)) {
                        partyById[party._id] = party;
                    }
                });

                if (!(agreement.client._id in clientById)) {
                    clientById[agreement.client._id] = agreement.client;
                }
            });
        }
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/agreements/${row.original._id}`}>#</Link>;
                },
                size: 20
            },
            {
                header: "Client (owner)",
                id: "client.name",
                accessorKey: "client.name",
                size: 150,
                Cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter
            },
            {
                header: "Type",
                accessorKey: "type",
                filter: SelectColumnFilterType(AgreementTypeEnum)
            },
            {
                header: "Parties",
                accessorKey: "partyIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    if (partyById[id]) {
                                        return (
                                            <Link className="row" key={id} to={`/parties/${id}`}>
                                                {partyById[id].name}
                                            </Link>
                                        );
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(partyById)),
                size: 200
            },
            {
                header: "Counterparties",
                accessorKey: "counterPartyIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    return (
                                        <Link className="row" key={id} to={`/parties/${id}`}>
                                            {counterPartyById[id].name}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(counterPartyById)),
                size: 200
            },
            {
                header: "Description",
                accessorKey: "description",
                size: 200
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(AgreementStatusEnum),
                size: 75
            },
            {
                header: "Attachments",
                id: "attachments.length",
                accessorKey: "attachments.length",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter
            },
            {
                header: "Approved",
                accessorKey: "approved",
                filter: SelectColumnFilter
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                filter: SelectColumnFilter
            }
        ];
    }, [data]);

    if (loading) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto">
                    <Link to={"/agreements/new"}>New Agreement</Link>
                </div>
            </div>

            <Fragment>
                <p>Number of Agreements: {data.agreements.length}</p>
                <ReactTable columns={columns} data={dataExtended} defaultHiddenColumns={[]} />
            </Fragment>
        </div>
    );
};
