import React, { useState, useEffect } from "react";
import { useField, useFormikContext, FieldHookConfig } from "formik";
import { Button } from "react-bootstrap";
import { TimeSeriesItemInput } from "../../types.generated";
import { cloneDeep } from "lodash";
import { serializeSwedenDate } from "../../components/dateFormater";
import { Svgs } from "../../../../components/src";
import { exportToXlsx2 } from "../../../../components/src/common/exportToXlsx2";
import { TimeSeriesItem } from "../../../../common/src/types.generated";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import { DefaultColumnFilter, Filter } from "../../../../components/src/react-table/ReactTableFilters";

const EditableCell = (cellProps) => {
    const [value, setValue] = useState(cellProps.getValue());
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        cellProps.table.options.meta(cellProps.row.index, cellProps.column.id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(cellProps.getValue());
    }, [cellProps]);

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

function Table({ columns, data, updateData, skipPageReset }) {
    const [sorting, setSorting] = useState<SortingState>([]);

    const defaultColumn: any = { filter: DefaultColumnFilter, size: 50, minSize: 1, maxSize: 500, cell: EditableCell };

    const table = useReactTable({
        data,
        columns,
        defaultColumn,
        state: {
            sorting
        },
        meta: updateData,
        autoResetPageIndex: !skipPageReset,
        initialState: { pagination: { pageIndex: 0, pageSize: 10 } },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });
    return (
        <>
            <table className="table-timeserie-items">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="tr" role="row">
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{ width: header.getSize() }}
                                    className="th"
                                    role="columnheader"
                                >
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className="header-content"
                                            title="Toggle SortBy"
                                            {...{
                                                className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                onClick: header.column.getToggleSortingHandler()
                                            }}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: " ▼",
                                                desc: " ▲"
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    )}
                                    {!header.isPlaceholder && header.column.getCanFilter() ? (
                                        <div>
                                            <Filter column={header.column} table={table} />
                                        </div>
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody role="rowgroup">
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="tr" role="row">
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="td" role="cell" style={{ width: cell.column.getSize() }}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination mt-2 d-flex justify-content-between">
                <button className="button" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} title="First">
                    &laquo;
                </button>{" "}
                <span className="p-1">
                    Page:{" "}
                    <input
                        type="number"
                        min={1}
                        max={table.getPageCount()}
                        value={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            table.setPageIndex(page);
                        }}
                        style={{ maxWidth: "70px" }}
                        className="h-100"
                    />{" "}
                    of {table.getPageCount()}
                </span>
                <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} title="Last">
                    &raquo;
                </button>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                    }}
                    className="ms-auto p-1"
                >
                    {[20, 40, 60, 80, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}

type ArrayPagedTableFieldType = FieldHookConfig<TimeSeriesItemInput[]> & {
    label: string;
    className: string;
    disabled?: boolean;
    onChange?: any;
};

export const ArrayPagedTableField = ({ label, className, disabled, onChange, ...props }: ArrayPagedTableFieldType): React.ReactElement => {
    const [field] = useField(props);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const { setFieldValue } = useFormikContext();

    const onAddNew = () => {
        setSkipPageReset(false);
        const update = cloneDeep(field.value);
        update.unshift({ date: serializeSwedenDate(new Date()), value: 0.0 });
        setFieldValue(field.name, update);
        if (onChange) {
            onChange(update);
        }
    };

    const onDuplicate = React.useCallback(
        (e) => {
            setSkipPageReset(true);
            const update = cloneDeep(field.value);
            // find index of
            const ndx = update.findIndex((x) => x.date === e.values.date);
            // insert new duplicated item
            update.splice(ndx, 0, { date: e.values.date, value: e.values.value });
            setFieldValue(field.name, update);
            if (onChange) {
                onChange(update);
            }
        },
        [setFieldValue, field, onChange]
    );

    const onDelete = React.useCallback(
        (e) => {
            if (e > -1) {
                setSkipPageReset(true);
                const update = cloneDeep(field.value);
                update.splice(e, 1);
                setFieldValue(field.name, update);
                if (onChange) {
                    onChange(update);
                }
            }
        },
        [setFieldValue, field, onChange]
    );

    const columns = React.useMemo(
        () => [
            {
                header: "Date",
                accessorKey: "date",
                size: 150
            },
            {
                header: "Value",
                accessorKey: "value",
                size: 250
            },
            {
                header: " ",
                size: 30,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Button
                            title="Duplicate"
                            className="btn btn-secondary btn-sm"
                            disabled={disabled}
                            onClick={() => {
                                onDuplicate(row);
                            }}
                        >
                            +
                        </Button>
                    );
                }
            },
            {
                header: "  ",
                size: 30,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <Button
                            title="Delete"
                            className="btn btn-danger btn-sm"
                            disabled={disabled}
                            onClick={() => {
                                onDelete(row.id);
                            }}
                        >
                            x
                        </Button>
                    );
                }
            }
        ],
        [onDelete, onDuplicate, disabled]
    );

    const updateData = (rowIndex, columnId, value) => {
        setSkipPageReset(true);
        const updatedData = field.value.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...field.value[rowIndex],
                    [columnId]: value
                };
            }
            return row;
        });
        setFieldValue(field.name, updatedData);
        if (onChange) {
            onChange(updatedData);
        }
    };

    const download = (data) => {
        const rows = data.map((r: TimeSeriesItem) => {
            return {
                date: r.date,
                value: r.value
            };
        });
        exportToXlsx2(rows, "timeserieItems.xlsx");
    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <span
                style={{ display: "inline-block", width: "20px", height: "20px", cursor: "pointer", float: "right" }}
                title="Download"
                onClick={() => {
                    download(field.value);
                }}
            >
                <Svgs.Excel />
            </span>
            <label>{label}</label>
            <span className="ms-2 me-2">{field.value.length} items</span>
            <button type="button" onClick={onAddNew} className="btn btn-secondary btn-sm">
                +
            </button>
            <Table columns={columns} data={field.value} updateData={updateData} skipPageReset={skipPageReset} />
        </div>
    );
};
