import React, { Fragment, ReactElement, useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Alert, Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { sortBy, cloneDeep } from "lodash";
import { Dropdown } from "react-bootstrap";
import save from "save-file";
import stableStringify from "json-stable-stringify";
import { Panel } from "@fluentui/react";

import { Svgs, Grid, Column } from "../../../components/src";
import { twoDecPriceFormat, numberFormatFun } from "../../../common/src";

import { YesNoModal } from "../components/YesNoModal";
import { DateForm } from "../../../components/src/dateForm";
import { MiniTransactionForm, miniTransactionTypes } from "../containers/MiniTransactionForm";
import { BrokerTransactionForm } from "./BrokerTransactionForm";
import { useQueryState } from "../../../components/src/use-query-state";
import { useAlertTimeOut } from "../../../components/src/common/Utils";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { usePrevious } from "../../../components/src/common/Utils";
import { formikUrqlErrorFormater } from "../../../components/src/common/formik-urql-error-helper";
import { PermissionAssetEnum } from "../types.generated";
import { GET_ME } from "../common/queries";

const GET_BROKER_TRANSACTIONS = gql`
    query brokerTransactions($filter: BrokerTransactionFilterInput) {
        brokerTransactions(filter: $filter) {
            _id
            broker {
                _id
                name
            }
            client {
                _id
                name
            }
            type
            description
            isin
            instrument {
                _id
                name
            }
            price
            quantity
            commission
            stampDuty
            currency
            settlementAmount
            tradeDate
            valueDate
            reportId
            externalId
            correspondingTransactionId
            error
            status
            updateTimestamp
        }
    }
`;
const GET_MINI_TRANSACTIONS = gql`
    query miniTransactions($status: [TransactionStatus], $type: [TransactionType], $filter: TransactionFilterInput) {
        miniTransactions(status: $status, type: $type, filter: $filter) {
            _id
            type
            accountId
            tradeTimestamp
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            broker {
                _id
                name
            }
            uniqueTradeId
            brokerTradeId
            source
            instrumentId
            instrument {
                _id
                name
            }
            currency
            valueDate
            settlementAmount
            clientId
            client {
                _id
                name
            }
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            accruedInterest
            agreementType
            clearingHouseId
            secondInstrumentId
            secondAmount
            secondCurrency
            bonusShares
            fee
            itemType
            updateTimestamp
        }
    }
`;

const UPDATE_STATUS_BROKER_TRANSACTION = gql`
    mutation UpdateBrokerTransaction($input: UpdateBrokerTransactionInput!) {
        updateBrokerTransaction(input: $input) {
            _id
            status
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

const transactionGroups = ["Pending"];

const groupByStatus = (transactions, transactionGroups) => {
    const result = {};
    transactionGroups.forEach((status) => {
        result[status] = [];
    });

    transactions = sortBy(transactions, "tradeDate").reverse();
    transactions.forEach((transaction) => {
        if (transactionGroups.includes(transaction.status)) {
            result[transaction.status].push(transaction);
        }
    });

    return result;
};

const GET_FILE_REPORT = gql`
    query report($_id: GraphQLObjectId!) {
        report(_id: $_id) {
            _id
            clientId
            clientName
            sourceId
            sourceName
            type
            name
            date
            reportId
            createTimestamp
            data
            status
            base64
        }
    }
`;

export const BrokerTransactionsPage = ({ startDate }: { startDate: string }): ReactElement => {
    const location = useLocation();
    const [{ fetching: loading, error, data }, refetch] = useQuery({
        query: GET_BROKER_TRANSACTIONS,
        variables: { filter: { statusIn: transactionGroups, startDate } },
        requestPolicy: "network-only"
    });

    const [{ fetching: loadingT, error: errorT, data: dataT }, refetchT] = useQuery({
        query: GET_MINI_TRANSACTIONS,
        variables: { status: "Pending", type: "StockTrade", filter: { startDate } },
        requestPolicy: "network-only"
    });

    const [__, updateBrokerTransaction] = useMutation(UPDATE_STATUS_BROKER_TRANSACTION);
    const [___, updateTransactions] = useMutation(UPDATE_TRANSACTIONS);

    const [fileReportVariables, setFileReportVariables] = useState(null);

    const [{ data: fileReportData }] = useQuery({
        query: GET_FILE_REPORT,
        variables: fileReportVariables,
        pause: fileReportVariables ? false : true
    });
    const previousFileReportData = usePrevious(fileReportData);

    useEffect(() => {
        if (fileReportData && stableStringify(fileReportData) !== stableStringify(previousFileReportData)) {
            const dataURI = "data:" + fileReportData.report.mimeType + ";base64," + fileReportData.report.base64;
            save(dataURI, fileReportData.report.name);
            setFileReportVariables(null);
        }
    }, [fileReportData, previousFileReportData]);

    const [modal, setModal] = useState({ showModal: false, payload: null });

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    if (loading) return <p>Loading broker transactions</p>;
    if (loadingT) return <p>Loading transactions</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorT) return <p>error: {JSON.stringify(errorT, null, 2)}</p>;

    // Get the transactions that match and that should be updated
    /*if (data.brokerTransactions) {
        var pendingBrokerTransactions = data.brokerTransactions.filter(function(b) {
            return b.status === "Pending";
        });
    }*/

    const brokerTransactions = data.brokerTransactions.map((item, i) => {
        const row = cloneDeep(item);
        if (row.reportId) {
            row.download = (
                <div key={i} className="grid-attachment">
                    <Svgs.Attachment title={row.reportId} onClick={() => setFileReportVariables({ _id: row.reportId })} />
                </div>
            );
        } else {
            row.download = null;
        }
        return row;
    });

    // Filter transactions to show in pending list
    const filteredMiniTransactions = dataT.miniTransactions.filter(function (t) {
        return (!t.error || t.error === "No matching broker transaction found.") && t.source !== "Coacs";
    });

    const pendingTransactionsBrokerTransactions = [...brokerTransactions, ...filteredMiniTransactions];

    const groups = groupByStatus(pendingTransactionsBrokerTransactions, transactionGroups);

    const updateStatus = (item, changeTo) => {
        // Different mutations for brokerTransactions/transactions
        if (item.type && (item.type === "Buy" || item.type === "Sell")) {
            updateBrokerTransaction({ input: { _id: item._id, status: changeTo } })
                .then((result) => {
                    if ("error" in result && result.error) {
                        const message = "Update Broker Transaction Status " + formikUrqlErrorFormater(result.error);
                        setAlert({ color: "danger", visible: true, message });
                    } else {
                        refetch();
                        refetchT();
                    }
                })
                .catch((error) => {
                    setAlert({ color: "danger", visible: true, message: "Update Broker Transaction Status " + error.toString() });
                });
        } else {
            updateTransactions({ input: [{ _id: item._id, status: changeTo }] })
                .then((result) => {
                    if ("error" in result && result.error) {
                        const message = "Update Transaction Status " + formikUrqlErrorFormater(result.error);
                        setAlert({ color: "danger", visible: true, message });
                    } else {
                        refetch();
                        refetchT();
                    }
                })
                .catch((error) => {
                    setAlert({ color: "danger", visible: true, message: "Update Transaction Status " + error.toString() });
                });
        }
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={
                        modal.payload.__typename === "MiniTransaction"
                            ? " Are you sure you want to delete transaction with id " + modal.payload._id + "?"
                            : " Are you sure you want to delete broker confirmation with id " + modal.payload._id + "?"
                    }
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updateStatus(modal.payload, "Deleted");
                    }}
                />
            ) : null}
            {alert.visible ? (
                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
            <br />
            <Button
                variant="info"
                type="submit"
                style={{ marginLeft: "2px" }}
                onClick={() => {
                    const win = window.open("/brokerconfirmations", "_blank");
                    win.focus();
                }}
            >
                Broker confirmations page
            </Button>
            <br />
            <br />
            <Fragment>
                {transactionGroups.map((status) => (
                    <div key={status}>
                        <h2>{status}</h2>
                        <Grid data={groups[status]} sortable tableClassName="table-xs">
                            <Column className="nowrap right" field="updateTimestamp" title="Update timestamp" format={dateFormater} />
                            <Column
                                className="nowrap"
                                field="__typename"
                                title="Transaction type"
                                format={(value, item) => {
                                    return item && miniTransactionTypes.find((d) => d === item.type) ? (
                                        <Link to={"/reconcile/brokertransactions/minitransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    ) : (
                                        <Link to={"/reconcile/brokertransactions/brokertransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    );
                                }}
                            ></Column>
                            <Column
                                className="nowrap"
                                field="status"
                                format={(d, item) => {
                                    return (
                                        <Dropdown>
                                            <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={item._id}>
                                                {item.status}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {["Pending", "Confirmed", "Deleted"].map((changeTo, i) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={i}
                                                            onClick={() => {
                                                                if (changeTo === "Deleted") {
                                                                    setModal({ showModal: true, payload: item });
                                                                } else {
                                                                    updateStatus(item, changeTo);
                                                                }
                                                            }}
                                                        >
                                                            Change to {changeTo}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                }}
                            ></Column>
                            <Column
                                className="grid-column-sticky nowrap"
                                field="broker"
                                format={(_, item) => {
                                    return item && item.broker ? <Link to={"/parties/" + item.broker._id}>{item.broker.name}</Link> : "";
                                }}
                            ></Column>
                            <Column
                                className="nowrap"
                                field="client"
                                format={(_, item) => {
                                    return item && item.client && item.client.name ? (
                                        <Link to={"/parties/" + item.client._id}>{item.client.name}</Link>
                                    ) : (
                                        ""
                                    );
                                }}
                            ></Column>
                            <Column
                                className="nowrap"
                                field="type"
                                format={(value, item) => {
                                    return item && miniTransactionTypes.find((d) => d === item.type) ? (
                                        <Link to={"/reconcile/brokertransactions/minitransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    ) : (
                                        <Link to={"/reconcile/brokertransactions/brokertransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    );
                                }}
                            ></Column>
                            <Column className="nowrap" field="description"></Column>
                            <Column
                                className="nowrap"
                                field="instrument"
                                format={(_, item) => {
                                    return item && item.instrument && item.instrument._id && item.instrument.name ? (
                                        <Link to={"/instruments/" + item.instrument._id}>{item.instrument.name}</Link>
                                    ) : (
                                        ""
                                    );
                                }}
                            ></Column>
                            <Column field="price" className="nowrap right" format={numberFormatFun("# ##0.#######")}></Column>
                            <Column field="quantity" format={twoDecPriceFormat} className="nowrap right"></Column>
                            <Column field="commission" format={twoDecPriceFormat} className="nowrap right"></Column>
                            <Column field="stampDuty" title="Stamp duty" format={twoDecPriceFormat} className="nowrap right"></Column>
                            <Column
                                field="settlementAmount"
                                title="Settlement amount"
                                format={twoDecPriceFormat}
                                className="nowrap right"
                            ></Column>
                            <Column className="nowrap" field="currency"></Column>
                            <Column
                                className="nowrap right"
                                field="tradeDate"
                                title="Trade date"
                                format={(value, item) => {
                                    if (item.tradeTimestamp) {
                                        return serializeSwedenDate(item.tradeTimestamp);
                                    } else {
                                        return item.tradeDate;
                                    }
                                }}
                            ></Column>
                            <Column className="nowrap right" field="valueDate" title="Value date"></Column>
                            <Column field="download" title="Original" />
                            <Column
                                field="externalId"
                                title="External id"
                                format={(value, item) => {
                                    return item && miniTransactionTypes.find((d) => d === item.type) ? (
                                        <Link to={"/reconcile/brokertransactions/minitransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    ) : (
                                        <Link to={"/reconcile/brokertransactions/brokertransaction/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    );
                                }}
                            ></Column>
                            <Column className="nowrap" field="error"></Column>
                            <Column
                                className="nowrap"
                                field="correspondingTransactionId"
                                title="Transaction"
                                format={(value, item) => {
                                    return item && item.correspondingTransactionId ? (
                                        <Link
                                            to={
                                                "/reconcile/brokertransactions/minitransaction/" +
                                                item.correspondingTransactionId +
                                                location.search
                                            }
                                        >
                                            {value}
                                        </Link>
                                    ) : (
                                        value
                                    );
                                }}
                            ></Column>
                        </Grid>
                    </div>
                ))}
            </Fragment>
        </div>
    );
};

export function BrokerTransactionsCompletePage(): ReactElement {
    const params: any = useParams();
    const { id, type } = params;
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));

    const [{ fetching: loadingMe, error: errorMe, data }] = useQuery({
        query: GET_ME,
        requestPolicy: "network-only"
    });

    const location = useLocation();
    const navigate = useNavigate();

    if (loadingMe) return <div>Loading...</div>;

    if (errorMe)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorMe, null, 2)}</pre>
            </div>
        );

    return (
        <Fragment>
            <div className="row">
                <div className="d-flex col-6">
                    <DateForm defaultDateString={startDate} dateName={"startDate"}></DateForm>
                </div>
                <div className="col-6 right">
                    {data &&
                    data.me &&
                    data.me.frontendRole &&
                    data.me.frontendRole.assets &&
                    (data.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) || data.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual/transacation_and_trade_reconciliation.md"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                    ) : null}
                </div>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <BrokerTransactionsPage startDate={startDate} />
                </div>
            </div>
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/brokertransactions${location.search}`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                {id && type === "brokertransaction" ? <BrokerTransactionForm id={id} startDate={startDate} /> : null}
            </Panel>
        </Fragment>
    );
}
