import React, { Fragment, useEffect, useState, ReactElement, useMemo } from "react";
import { gql } from "urql";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";

import { useGetPartiesQuery } from "./PartiesPage.generated";
import { PartyType } from "../types.generated";
import { useQueryState } from "../../../components/src/use-query-state";
import { ReactTable } from "../../../components/src/react-table/ReactTable";
import { SelectColumnFilterType } from "../../../components/src/react-table/ReactTableFilters";

export const getParties = gql`
    query getParties($filter: PartyFilterInput) {
        parties(filter: $filter) {
            _id
            types
            name
            legalEntityIdentifier
            legalNumber
            updateTimestamp
        }
    }
`;

export const PartiesPage = (): ReactElement => {
    const [types, setTypes] = useQueryState("types", "");

    const [typeIn, setTypeIn] = useState(null);
    const [{ fetching: loading, error, data }] = useGetPartiesQuery({
        variables: { filter: { typeIn: typeIn } },
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 200
            },
            {
                header: "Name",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 200
            },
            {
                header: "Types",
                accessorKey: "types",
                accessorFn: (row) => (row.types ? row.types.join(", ") : null),
                filter: SelectColumnFilterType(PartyType),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <div>{row.original.types.join(", ")}</div>;
                }
            },
            {
                header: "Legal entity identifier",
                accessorKey: "legalEntityIdentifier",
                size: 150
            },
            {
                header: "Legal number",
                accessorKey: "legalNumber",
                size: 75
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp"
            }
        ],
        []
    );

    useEffect(() => {
        if (types !== "") {
            const l: string[] = types.split(",");
            const localTypeIn = [];
            l.forEach((element) => {
                if (element in PartyType) {
                    localTypeIn.push(element);
                }
            });
            if (JSON.stringify(l) !== JSON.stringify(localTypeIn)) {
                setTypes(localTypeIn.join(","));
            }
            if (localTypeIn.length === 0) {
                setTypeIn(null);
            } else {
                if (JSON.stringify(typeIn) !== JSON.stringify(localTypeIn)) {
                    setTypeIn(localTypeIn);
                }
            }
        }
    }, [types, setTypes, typeIn]);

    if (loading) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    const parties = sortBy(data.parties, "types", "name");

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto p-2">
                    <Link to={"/parties/new"}>New</Link>
                </div>
            </div>
            <Fragment>
                <p>Number of parties: {parties.length}</p>
                <ReactTable columns={columns} data={parties} defaultHiddenColumns={["_id", "updateTimestamp"]} />
            </Fragment>
        </div>
    );
};
