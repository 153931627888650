import React, { ReactElement } from "react";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";
import { TimeSeriesEditorForm } from "./TimeSeriesEditorForm";

import { useQueryArgs } from "../../../../components/src/common/Utils";
import { SYSTEM_PARTY_ID } from "../../Constants";

const getTimeserie = gql`
    query getTimeserie($id: GraphQLObjectId) {
        timeserie(_id: $id, includeItems: true) {
            _id
            type
            instrumentId
            title
            description
            clientId
            status
            items {
                value
                date
            }
        }
    }
`;

const getData = gql`
    query getData {
        instruments: instruments {
            _id
            name
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

export const TimeSeriesEditorPage = (): ReactElement => {
    // id param
    const { id } = useParams<"id">();

    const isEditMode = id != "new";

    // instrumentId queryArg
    const { queryArgs } = useQueryArgs();
    const { instrumentId } = queryArgs;

    const [{ fetching, error, data }] = useQuery({ query: getTimeserie, variables: { id }, pause: !isEditMode });
    const [{ fetching: loadingDataPlus, error: errorData, data: dataPlus }] = useQuery({ query: getData });

    if (fetching || loadingDataPlus) return <p>Loading time series</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorData) return <p>error: {JSON.stringify(errorData, null, 2)}</p>;
    if (data && !data.timeserie) return <p>Time serie not found</p>;

    const ts = data
        ? data.timeserie
        : {
              //_id: string, // If _id is omitted, a TimeSeries will be created
              type: "Price(Close)",
              status: "Active",
              title: "",
              description: "",
              instrumentId: instrumentId,
              clientId: SYSTEM_PARTY_ID,
              items: []
          };

    return (
        <div>
            {isEditMode ? <h2>Edit TimeSeries</h2> : <h2>New TimeSeries</h2>}
            <TimeSeriesEditorForm ts={ts} instruments={dataPlus.instruments} clients={dataPlus.clients} />
        </div>
    );
};
