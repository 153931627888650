import React, { useMemo, useState, useEffect } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";
import { useQueryState } from "../../../components/src/use-query-state";

import { useQueryArgs, usePrevious } from "../../../components/src/common/Utils";
import { DateForm } from "../../../components/src/dateForm";

import { QuoteFilterInput } from "../types.generated";
import { ReactTable } from "../../../components/src/react-table/ReactTable";
import { SelectColumnFilter } from "../../../components/src/react-table/ReactTableFilters";

const QUOTES = gql`
    query quotes($filter: QuoteFilterInput) {
        quotes(filter: $filter) {
            _id
            instrumentId
            instrument {
                name
            }
            currency
            date
            bid
            ask
            mid
            last
            close
            type
        }
    }
`;

export const QuotesPage = (): React.ReactElement => {
    const { queryArgs } = useQueryArgs();
    const [date] = useQueryState("date", new Date().toISOString().slice(0, 10));
    const previousQueryArgs = usePrevious(queryArgs);
    const [variables, setVariables] = useState<{ filter: QuoteFilterInput }>({ filter: { dateIn: [date] } });

    const [{ fetching, data, error }] = useQuery({
        query: QUOTES,
        variables: variables,
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            let filter: QuoteFilterInput = {};
            filter = { ...variables.filter };

            if (queryArgs.date) {
                filter = { ...variables.filter, dateIn: [queryArgs.date as string] };
            }

            setVariables({ filter });
        }
    }, [previousQueryArgs, queryArgs, variables.filter]);

    const columns = useMemo(() => {
        let columns = [];
        columns.push({
            header: "Id",
            accessorKey: "_id",
            cell: (cellProps) => {
                const { row } = cellProps;
                return <Link to={`/quotes/${row.original._id}`}>#</Link>;
            },
            size: 8
        });
        /*columns.push({
            header: "Client",
            id: "client.name",
            accessorKey: "client.name",
            filter: SelectColumnFilter,
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.client) {
                    return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                } else {
                    return null;
                }
            },
            size: 100
        });*/
        columns.push({
            header: "Instrument",
            id: "instrument.name",
            accessorKey: "instrument.name",
            cell: (cellProps) => {
                const { row } = cellProps;
                return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
            },
            size: 150
        });
        columns.push({
            header: "Currency",
            accessorKey: "currency",
            filter: SelectColumnFilter,
            size: 75
        });
        columns = [
            ...columns,
            ...["date", "bid", "ask", "mid", "last", "close", "type"].map((item) => {
                return {
                    header: item.replace(/^./, (c) => c.toUpperCase()),
                    accessorKey: item,
                    filter: SelectColumnFilter
                };
            })
        ];

        return columns;
    }, []);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.quotes === null) return <div>No documents</div>;
    return (
        <div>
            <div className="d-flex mb-3">
                <DateForm defaultDateString={date} dateName={"date"}></DateForm>
            </div>

            <div className="row">
                <p>Number of documents: {data.quotes.length}</p>
                <ReactTable columns={columns} data={data.quotes} defaultHiddenColumns={[]} />
            </div>
        </div>
    );
};
