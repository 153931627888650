import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { keyBy } from "lodash";
import { useQuery } from "urql";

import { AttributionGraph } from "./Attribution";
import { PerformanceTree } from "./PerformanceTree";
import { getClients } from "./queries";
import { DateHelper, emptyObjectId } from "../../../../common/src";
import { useQueryState } from "../../../../components/src/use-query-state";
import { CurrencyEnum, PartyStatusEnum, PartyType } from "../../types.generated";
import { usePrevious } from "../../../../components/src/common/Utils";

import { useGetPerformanceDataQuery } from "./queries.generated";
import { ClientContextSelector } from "../../contexts/ClientContextSelector";
import { ClientContext } from "../../contexts/ClientContext";

import { PerformanceTabSimple } from "./PerformanceTabSimple";

const tabs = {
    attribution: "attribution",
    tree: "tree",
    simple: "simple"
};

export function PerformancePage(): React.ReactElement {
    const navigate = useNavigate();
    const { tabId } = useParams<"tabId">();
    const { client: clientContext } = useContext(ClientContext);

    const yesterday: string = DateHelper.dateToString(DateHelper.dateAddDays(new Date(), -1));

    const [endDate] = useQueryState("endDate", yesterday);
    const [client, setClient] = useState({ _id: null, accountingCurrency: CurrencyEnum.SEK });
    const previousClientName: string = usePrevious(clientContext.dashName);

    const [{ data, fetching: loading, error }] = useQuery({
        query: getClients,
        requestPolicy: "cache-and-network"
    });

    const [{ data: dataPerformance, fetching: loadingPerformance, error: errorPerformance }] = useGetPerformanceDataQuery({
        variables: {
            clientId: client._id,
            endDate: endDate,
            endDateString: endDate,
            accountingCurrency: client.accountingCurrency ? (client.accountingCurrency as CurrencyEnum) : CurrencyEnum.SEK
        },
        requestPolicy: "cache-and-network",
        pause: !client._id
    });

    useEffect(() => {
        if (data && data.clients) {
            const clientsByDashName = keyBy(data.clients, (client) => {
                return client.name.replace(/ /g, "-");
            });
            if (clientContext.dashName && clientContext._id !== emptyObjectId) {
                if (!(clientContext.dashName in clientsByDashName)) {
                    navigate("/performance");
                } else {
                    if (!(tabId in tabs)) {
                        navigate("/performance/" + clientContext.dashName + "/tree");
                    }
                    if (!client._id || previousClientName !== clientContext.dashName) {
                        setClient(clientsByDashName[clientContext.dashName]);
                    }
                }
            }
        }
    }, [client._id, clientContext, data, navigate, previousClientName, tabId]);

    const onTabChange = useCallback(
        (tabId) => {
            if (!(tabId in tabs)) {
                navigate({
                    pathname: "/performance/" + clientContext.dashName + "/tree",
                    search: ""
                });
            } else {
                navigate({
                    pathname: "/performance/" + clientContext.dashName + "/" + tabId,
                    search: ""
                });
            }
        },
        [clientContext.dashName, navigate]
    );

    if (loading || loadingPerformance)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (errorPerformance)
        return (
            <div>
                <p>error performance:</p>
                <pre> {JSON.stringify(errorPerformance, null, 2)}</pre>
            </div>
        );

    if (!tabId) {
        <div></div>;
    }

    const clientsByName = keyBy(data.clients, "name");
    if (
        clientContext &&
        clientContext._id !== emptyObjectId &&
        !Object.keys(clientsByName).includes(clientContext.dashName.replace(/-/g, " "))
    ) {
        return (
            <div className="loader">
                <h3>No client with that name</h3>
            </div>
        );
    }

    return (
        <div id="performancepage">
            <div className="row print-none">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector
                        typeIn={[PartyType.Client]}
                        carryQueryArgs={false}
                        statusIn={[PartyStatusEnum.Confirmed, PartyStatusEnum.Inactive]}
                    />
                </div>
            </div>
            {clientContext.dashName && clientContext._id !== emptyObjectId ? (
                <div className="row">
                    <div className="card card-body bg-light col-12">
                        <Tabs onSelect={onTabChange} activeKey={tabId} transition={false}>
                            <Tab eventKey="tree" title="Tree">
                                {tabId === "tree" && (
                                    <PerformanceTree
                                        client={client._id ? client : clientsByName[clientContext.dashName.replace(/-/g, " ")]}
                                        data={dataPerformance}
                                        endDate={endDate}
                                    />
                                )}
                            </Tab>

                            <Tab eventKey="attribution" title="Attribution">
                                {tabId === "attribution" && (
                                    <AttributionGraph
                                        client={client._id ? client : clientsByName[clientContext.dashName.replace(/-/g, " ")]}
                                        data={dataPerformance}
                                    />
                                )}
                            </Tab>

                            <Tab eventKey="simple" title="Simple">
                                {tabId === "simple" && (
                                    <PerformanceTabSimple
                                        client={client._id ? client : clientsByName[clientContext.dashName.replace(/-/g, " ")]}
                                        data={dataPerformance}
                                        endDate={endDate}
                                    />
                                )}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
