import React from "react";
import stableStringify from "json-stable-stringify";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";

import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { Swift } from "../types.generated";

interface SwiftPropTypes {
    swift: Swift;
}

export const SwiftView = ({ swift }: SwiftPropTypes): React.ReactElement => {
    let text = "No swift corporate action found";
    if (swift) {
        const swiftText = swift.text.split("}{");
        const textString = swiftText.toString().split("\n");
        text = stableStringify(textString, { space: "  " });
    }
    return (
        <div>
            <pre>{text}</pre>
        </div>
    );
};

const GET_SWIFT = gql`
    query swifts($idIn: [GraphQLObjectId!]) {
        swifts(idIn: $idIn) {
            _id
            text
        }
    }
`;

export const SwiftCorporateActionPage = (): React.ReactElement => {
    const { id } = useParams<"id">();

    const [{ fetching, data, error }] = useQuery({
        query: GET_SWIFT,
        variables: {
            idIn: [id]
        }
    });

    if (fetching)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.swifts.length === 0) return <div>No swift corporate action found</div>;

    const swift = recursivelyRemoveKey(data.swifts[0], "__typename");

    return <SwiftView swift={swift} />;
};
