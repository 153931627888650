import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Panel } from "@fluentui/react";

import { useQueryState } from "../../../../components/src/use-query-state";
import { DateForm } from "../../../../components/src/dateForm";
import { Reports } from ".";
import { MiniTransactionForm } from "../../containers/MiniTransactionForm";

export interface Props {
    lookThrough: boolean;
}

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const ReportsView = (props: Props): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));
    const location = useLocation();
    const navigate = useNavigate();

    const { id, tab, type, transactionId }: any = useParams();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }

    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={endDate} dateName="endDate"></DateForm>
                {!check_today_date(endDate) ? (
                    <Alert variant="danger" className="ms-2">
                        Historical or future date selected
                    </Alert>
                ) : null}
            </div>
            <br />
            <div className="row">
                <div className={"col-12"}>
                    <Reports clientIds={[id]} endDate={endDate as string} lookThrough={props.lookThrough} />
                </div>
            </div>
            <Panel
                isOpen={transactionId && tab === "positions"}
                onDismiss={() => navigate(`/pm/${id}/positions/${location.search}`)}
                isBlocking={false}
                layerProps={{ eventBubblingEnabled: true }}
            >
                <MiniTransactionForm
                    clientId={id ? id : "000000000000000000000000"}
                    id={transactionId && type === "closederivativetrade" ? transactionId : null}
                    type={"CloseDerivativeTrade"}
                    parentId={transactionId && type === "newclosederivativetrade" ? transactionId : null}
                />
            </Panel>
        </div>
    );
};
