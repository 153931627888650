// TODO: Josefine, remove the line below and fix the code
/* eslint-disable no-constant-binary-expression */

import { useMemo } from "react";

import { TimeSeries, emptyObjectId } from "../../../../common/src";
import { BenchmarkData, SelectedRowType, ProcessedData } from "./interfaces";

import { AttributionSelectorEnum } from "./Attribution";
import { InstrumentModelTypeEnum, InstrumentPerformance2, Party, PartyAccount, PartyAccountType } from "../../types.generated";
import { calculateReturnSeries, excludeInAttribution, getBenchmarkName } from "./functions";
import { cloneDeep } from "lodash";
export interface UseProcessedDataProps {
    data: any;
    endDate: string;
    startDate: string;
}

export const useProcessedData = ({ data, endDate, startDate }: UseProcessedDataProps): ProcessedData => {
    return useMemo(() => {
        if (data) {
            let treeRootExist = false;
            if ("client" in data && "accounts" in data.client) {
                if (data.client.topAccount) {
                    treeRootExist = true;
                }
            }
            // Init variables
            const dataWithLevels: any[] = [];
            const dateSeries: string[] = [];
            const benchmarksById: Record<string, BenchmarkData> = {};
            const showBenchmarksById: Record<string, BenchmarkData[]> = {};
            const firstSelectedRow: SelectedRowType = {
                name: "",
                performance: null,
                values: [],
                instrumentPerformances: [],
                benchmarks: [],
                riskFree: null,
                startDate: startDate,
                endDate: endDate,
                dateSeries: [],
                positionFlag: []
            };

            const accountsById: Record<string, PartyAccount> = {};
            const accountSeriesById: Record<string, number[]> = {};
            const accountPositionsById: Record<string, number[]> = {};
            const accountValuesById: Record<string, number[]> = {};
            const modelSeriesById: Record<string, number[]> = {};
            const modelSeriesNameById: Record<string, string> = {};
            let riskFreeSeries: TimeSeries = null;
            let maxAccountLevels: Record<string, string>[] = [];
            let attributionSelector = AttributionSelectorEnum.Instruments;
            if (treeRootExist) {
                const gqlData: InstrumentPerformance2[] = data.performance.instrumentPerformances;
                const allDates: string[] = data.performance.dates;
                const allDatesTimeSeries = new TimeSeries(allDates, [], "allDates");

                // Set date series
                for (let i = 0; i < allDates.length; i++) {
                    dateSeries.push(allDates[i]);
                }

                const validModelIndex = (account: PartyAccount): boolean => {
                    return Boolean(
                        account.modelIndexBenchmark &&
                            account.modelIndexBenchmark.timeSeries &&
                            Array.isArray(account.modelIndexBenchmark.timeSeries.items) &&
                            account.modelIndexBenchmark.timeSeries.items.length
                    );
                };

                const physicalAccounts: PartyAccount[] = [];
                const accountLevelsById: Record<string, { _id: string; description: string }[]> = {};
                const accountCashFlowsById: Record<string, number[]> = {};
                const client = data.client as unknown as Party;
                for (const account of client.accounts) {
                    accountsById[account._id] = account;
                    accountSeriesById[account._id] = [];
                    accountValuesById[account._id] = [];
                    accountCashFlowsById[account._id] = [];
                    accountPositionsById[account._id] = [];
                    modelSeriesById[account._id] = validModelIndex(account)
                        ? account.modelIndexBenchmark.timeSeries.items.map((item) => item.value)
                        : allDates.map((_d) => 1);
                    modelSeriesNameById[account._id] = validModelIndex(account) ? account.modelIndexBenchmark.name : "Model Weighted Index";
                    // Init arrays
                    for (let i = 0; i < allDates.length; i++) {
                        accountSeriesById[account._id].push(1);
                        accountValuesById[account._id].push(0);
                        accountCashFlowsById[account._id].push(0);
                        accountPositionsById[account._id].push(0);
                    }
                    if (account.type === PartyAccountType.Physical) {
                        physicalAccounts.push(account);
                        accountLevelsById[account._id] = [{ _id: account._id, description: account.description }];
                    }
                    let benchmark = account.benchmarks.find((a) => a.mainBenchmark === true);
                    if (!benchmark) benchmark = account.benchmarks.find((a) => a.showExternal === true);
                    // What to do if we have more than one???
                    if (benchmark) {
                        const timeSeries =
                            benchmark.instrument && benchmark.instrument.timeSeries
                                ? benchmark.instrument.timeSeries.find((s) => s.type === "Price(Close)")
                                : null;
                        if (timeSeries && timeSeries.dates && timeSeries.dates.length) {
                            const values = timeSeries.values.map((value) => parseFloat(value));
                            const benchmarkSeries = new TimeSeries(timeSeries.dates, values, getBenchmarkName(benchmark));
                            const offsetBenchMarkSeries = benchmark.offset
                                ? benchmarkSeries.runningAdjustment(benchmark.offset)
                                : benchmarkSeries.clone();
                            // assert data from start of allDatesTimeSeries
                            if (timeSeries.dates[0] > allDates[0]) {
                                // just flat from start
                                const offsetBenchMarkPrependedSeries = new TimeSeries(
                                    [allDates[0], ...timeSeries.dates],
                                    [values[0], ...values],
                                    benchmarkSeries.name
                                );

                                const alignedBenchmarkSeries = TimeSeries.align(allDatesTimeSeries, offsetBenchMarkPrependedSeries);
                                benchmarksById[account._id] = {
                                    instrumentId: benchmark.instrumentId,
                                    currency: benchmark.currency,
                                    series: alignedBenchmarkSeries
                                };
                            } else {
                                const alignedBenchmarkSeries = TimeSeries.align(allDatesTimeSeries, offsetBenchMarkSeries);
                                benchmarksById[account._id] = {
                                    instrumentId: benchmark.instrumentId,
                                    currency: benchmark.currency,
                                    series: alignedBenchmarkSeries
                                };
                            }
                        }
                    }
                    // Filter out all showBenchmarks excluding main benchmark
                    const showBenchmarks = account.benchmarks.filter((b) => b.showBenchmark === true);

                    for (const benchmark of showBenchmarks) {
                        const timeSeries = benchmark.instrument.timeSeries.find((s) => s.type === "Price(Close)");
                        if (timeSeries) {
                            const benchmarkSeries = new TimeSeries(
                                timeSeries.dates,
                                timeSeries.values.map((value) => parseFloat(value)),
                                getBenchmarkName(benchmark)
                            );
                            const alignedBenchmarkSeries = benchmark.offset
                                ? TimeSeries.align(allDatesTimeSeries, benchmarkSeries).runningAdjustment(benchmark.offset)
                                : TimeSeries.align(allDatesTimeSeries, benchmarkSeries);
                            if (!showBenchmarksById[account._id]) {
                                showBenchmarksById[account._id] = [
                                    {
                                        instrumentId: benchmark.instrumentId,
                                        currency: benchmark.currency,
                                        series: alignedBenchmarkSeries
                                    }
                                ];
                            } else {
                                showBenchmarksById[account._id].push({
                                    instrumentId: benchmark.instrumentId,
                                    currency: benchmark.currency,
                                    series: alignedBenchmarkSeries
                                });
                            }
                        }
                    }
                }

                // use accounts as default for attribution if over 3
                if (physicalAccounts.length > 3) attributionSelector = AttributionSelectorEnum.Accounts;

                const partyInstrumentsById: Record<string, boolean> = {};
                for (const instrument of data.client.instruments) {
                    partyInstrumentsById[instrument._id] = true;
                }

                // We can only have transactions in physical accounts so build tree upwards
                let maxLevels = 0;
                for (const account of physicalAccounts) {
                    let parentAccountId = account.parentAccountId;
                    let levels = 0;
                    while (parentAccountId !== emptyObjectId) {
                        accountLevelsById[account._id].unshift({
                            _id: parentAccountId,
                            description: accountsById[parentAccountId].description
                        });
                        parentAccountId = accountsById[parentAccountId].parentAccountId;
                        levels += 1;
                    }
                    maxLevels = maxLevels < levels ? levels : maxLevels;
                }

                // Risk free data
                const riskFree = data.riskFreeTimeSeries.find((s) => s.type === "Price(Close)");
                const riskFreeNumbers: number[] = riskFree.values.map((v) => Number(v));
                const riskFreeTimeSeries = new TimeSeries(riskFree.dates, riskFreeNumbers, "OMRXMM");
                const alignedRiskFree = TimeSeries.align(allDatesTimeSeries, riskFreeTimeSeries);
                // Update risk free series
                riskFreeSeries = alignedRiskFree.clone();

                const defaultInstrumentsValues: Record<string, number> = {};

                for (const row of gqlData) {
                    if (
                        row.instrument &&
                        row.instrument.modelType &&
                        !excludeInAttribution(row.instrument.modelType as unknown as InstrumentModelTypeEnum)
                    ) {
                        defaultInstrumentsValues[row.instrument.name] = row.series[row.series.length - 1];
                    }
                    // Check if we have specific benchmark for instrument
                    if (row.instrumentId && row.instrument && row.instrument.benchmark && !benchmarksById[row.instrumentId]) {
                        const timeSeries = row.instrument.benchmark.timeSeries.find((s) => s.type === "Price(Close)");
                        // Account benchmark comment to be refactored and used as visible label, see issue #2194
                        const name = row.instrument.benchmark.longName ? row.instrument.benchmark.longName : row.instrument.benchmark.name;
                        if (timeSeries && timeSeries.dates && timeSeries.dates.length) {
                            const values = timeSeries.values.map((value) => parseFloat(value));
                            const benchmarkSeries = new TimeSeries(timeSeries.dates, values, name);

                            // assert data from start of allDatesTimeSeries
                            if (timeSeries.dates[0] > allDates[0]) {
                                // just flat from start
                                // just flat from start
                                const benchmarkPrependedSeries = new TimeSeries(
                                    [allDates[0], ...timeSeries.dates],
                                    [values[0], ...values],
                                    benchmarkSeries.name
                                );

                                const alignedBenchmarkSeries = TimeSeries.align(allDatesTimeSeries, benchmarkPrependedSeries);
                                benchmarksById[row.instrumentId] = {
                                    instrumentId: row.instrument.benchmark._id,
                                    currency: row.instrument.benchmark.currency,
                                    series: alignedBenchmarkSeries
                                };
                            } else {
                                const alignedBenchmarkSeries = TimeSeries.align(allDatesTimeSeries, benchmarkSeries);
                                benchmarksById[row.instrumentId] = {
                                    instrumentId: row.instrument.benchmark._id,
                                    currency: row.instrument.benchmark.currency,
                                    series: alignedBenchmarkSeries
                                };
                            }
                        }
                    }
                    // Flag if we have position or not
                    const positionFlag = row.positions.map((p) => (p > 1e-6 || p < -1e-6 ? 1 : 0));
                    const accountLevels = accountLevelsById[row.accountId];
                    const isPartyInstrument = partyInstrumentsById[row.instrumentId] === true ? true : false;

                    const newRow = {
                        ...row,
                        isPartyInstrument,
                        root: accountLevels[0].description,
                        level1: null,
                        level2: null,
                        level3: null,
                        level4: null,
                        rootId: accountLevels[0]._id,
                        level1Id: null,
                        level2Id: null,
                        level3Id: null,
                        level4Id: null,
                        positionFlag
                    };

                    // Dynamic in the future?
                    if (accountLevels.length > 4) {
                        newRow.level4 = accountLevels[4].description;
                        newRow.level4Id = accountLevels[4]._id;
                    }
                    if (accountLevels.length > 3) {
                        newRow.level3 = accountLevels[3].description;
                        newRow.level3Id = accountLevels[3]._id;
                    }
                    if (accountLevels.length > 2) {
                        newRow.level2 = accountLevels[2].description;
                        newRow.level2Id = accountLevels[2]._id;
                    }
                    if (accountLevels.length > 1) {
                        newRow.level1 = accountLevels[1].description;
                        newRow.level1Id = accountLevels[1]._id;
                    }

                    // Update account data
                    for (const accountLevel of accountLevels) {
                        for (let i = 0; i < allDates.length; i++) {
                            accountValuesById[accountLevel._id][i] += newRow.values[i];
                            accountCashFlowsById[accountLevel._id][i] += newRow.cashFlows[i];
                            if (newRow.positionFlag[i] === 1) accountPositionsById[accountLevel._id][i] = 1;
                        }
                    }

                    if (maxAccountLevels.length === 0 || maxAccountLevels.length < accountLevels.length) {
                        maxAccountLevels = cloneDeep(accountLevels);
                    }

                    // Delete values and cash flows???

                    dataWithLevels.push(newRow);

                    // Update first selected row since all instrument performances included
                    // Copy to be sure...
                    const values: number[] = [];
                    const series: number[] = [];
                    const cashFlows: number[] = [];
                    for (let i = 0; i < row.values.length; i++) {
                        values.push(row.values[i]);
                        series.push(row.series[i]);
                        cashFlows.push(row.cashFlows[i]);
                    }
                    firstSelectedRow.instrumentPerformances.push({
                        values,
                        cashFlows,
                        series,
                        accountId: row.accountId,
                        instrument: {
                            currency: row.instrument.currency,
                            modelType: row.instrument.modelType as InstrumentModelTypeEnum,
                            name: row.instrument.name,
                            shortName: row.instrument.name
                        }
                    });
                }

                // Calculate account series
                for (const [key, values] of Object.entries(accountValuesById)) {
                    accountSeriesById[key] = calculateReturnSeries(values, accountCashFlowsById[key]);
                }

                dataWithLevels.sort((a, b) =>
                    a.level1 > b.level1
                        ? -1
                        : b.level1 > a.level1
                          ? 1
                          : 0 || a.level2 > b.level2
                            ? -1
                            : b.level2 > a.level2
                              ? 1
                              : 0 || a.level3 > b.level3
                                ? -1
                                : b.level3 > a.level3
                                  ? 1
                                  : 0 || a.level4 > b.level4
                                    ? -1
                                    : b.level4 > a.level4
                                      ? 1
                                      : 0
                );

                // First selected row. top node!
                // benchmarks
                const benchmarks: TimeSeries[] = [];
                const mainBenchmark = benchmarksById[dataWithLevels[0].rootId];
                if (mainBenchmark) {
                    const benchmarkAligned = TimeSeries.align(allDatesTimeSeries, mainBenchmark.series);
                    benchmarkAligned.name = mainBenchmark.series.name;
                    benchmarks.push(benchmarkAligned);
                }

                const modelSeries = modelSeriesById[dataWithLevels[0].rootId];
                const modelSeriesName = modelSeriesNameById[dataWithLevels[0].rootId]
                    ? modelSeriesNameById[dataWithLevels[0].rootId]
                    : "Model Weighted Index";
                let hasNaN = false;
                for (let i = 0; i < modelSeries.length; i++) {
                    if (isNaN(modelSeries[i])) {
                        hasNaN = true;
                        break;
                    }
                }
                if (!hasNaN) {
                    const modelTimeSeries = new TimeSeries(allDates, modelSeries, modelSeriesName);
                    const alignedModelSeries = TimeSeries.align(allDatesTimeSeries, modelTimeSeries);
                    alignedModelSeries.name = modelSeriesName;
                    benchmarks.push(alignedModelSeries);
                }
                const showBenchmarks = showBenchmarksById[dataWithLevels[0].rootId];
                if (Array.isArray(showBenchmarks) && showBenchmarks.length) {
                    for (const showBenchmark of showBenchmarks) {
                        // Filter out main benchmark since we already have added it.
                        if ((mainBenchmark && showBenchmark.instrumentId !== mainBenchmark.instrumentId) || !mainBenchmark) {
                            benchmarks.push(showBenchmark.series.clone());
                        }
                    }
                }

                const series: number[] = [];
                const values: number[] = [];
                const positionFlag: number[] = [];
                for (let i = 0; i < allDates.length; i++) {
                    series.push(data.performance.series[i]);
                    values.push(data.performance.values[i]);
                    // Always position for top node...
                    positionFlag.push(1);
                }
                const performance = new TimeSeries(allDates, series, dataWithLevels[0].root);

                firstSelectedRow.name = dataWithLevels[0].root;
                firstSelectedRow.performance = performance;
                firstSelectedRow.benchmarks = benchmarks;
                firstSelectedRow.values = values;
                firstSelectedRow.riskFree = alignedRiskFree;
                firstSelectedRow.startDate = startDate;
                firstSelectedRow.endDate = endDate;
                firstSelectedRow.dateSeries = allDates;
                firstSelectedRow.positionFlag = positionFlag;
            }

            return {
                dataWithLevels,
                dateSeries,
                benchmarksById,
                showBenchmarksById,
                maxAccountLevels,
                firstSelectedRow,
                accountsById,
                accountPositionsById,
                accountSeriesById,
                accountValuesById,
                modelSeriesById,
                modelSeriesNameById,
                riskFreeSeries,
                attributionSelector
            };
        } else {
            return null;
        }
    }, [data, endDate, startDate]);
};
