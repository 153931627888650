import React, { useContext, Fragment, useEffect, useCallback, useState } from "react";
import { Formik, Form } from "formik";
import { QRCodeCanvas } from "qrcode.react";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import { FormikErrors } from "formik";
import axios from "axios";

import { TextField } from "../../../components/src/form";
import { useInterval } from "../../../components/src/common/Utils";

import { loginContext } from "./Login";
import {
    REACT_APP_API_URI,
    REACT_APP_AUTH_URI,
    REACT_APP_DB_NAME,
    REACT_APP_NAME,
    REACT_APP_NODEJOBAPI_URI,
    REACT_APP_PYJOBAPI_URI
} from "../env";

enum BidStatus {
    start = "start", // not part of BankId spec, but we use it to start the login
    pending = "pending",
    failed = "failed",
    complete = "complete"
}

enum BidHintCode {
    certificateErr = "certificateErr",
    expiredTransaction = "expiredTransaction",
    noClient = "noClient",
    outstandingTransaction = "outstandingTransaction",
    started = "started",
    startFailed = "startFailed",
    userCancel = "userCancel",
    userSign = "userSign"
}

type BidAuth = {
    autoStartToken: string;
    orderRef: string;
    qrCode: string;
};

type BankIdData = {
    autoStartToken: string;
    qrStartToken: string;
    orderRef: string;
    status?: BidStatus;
    hintCode?: BidHintCode;
    qrCode?: string;
};

type BidCollect = {
    status: BidStatus;
    hintCode: BidHintCode;
    qrCode: string;
    access_token?: string;
    token_type?: string;
    expires_in?: string;
    user_display_name?: string;
    user_id?: string;
    accepted_terms?: boolean;
};

const setCookieOnServices = async (token: string) => {
    const config = {
        withCredentials: true,
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`
        }
    };

    if (REACT_APP_DB_NAME === "prod") {
        axios.post(REACT_APP_NODEJOBAPI_URI + "/set", {}, config).catch(function (error) {
            console.error(error);
        });
        axios.post(REACT_APP_PYJOBAPI_URI + "/set", {}, config).catch(function (error) {
            console.error(error);
        });
    }
    return axios.post(REACT_APP_API_URI + "/set", {}, config);
};

export const LoginScreen = (): React.ReactElement => {
    const [bankIdData, setBankIdData] = useState<BankIdData>(() => {
        // when doing an app switch to BankId, the orderRef is reset if we do not store it in localStorage
        const orderRef = localStorage.getItem("orderRef");
        const initData: BankIdData = {
            autoStartToken: "",
            qrStartToken: "",
            orderRef: orderRef || "",
            status: orderRef ? BidStatus.pending : BidStatus.start,
            hintCode: BidHintCode.outstandingTransaction,
            qrCode: ""
        };
        return initData;
    });

    const { login, setLogin } = useContext(loginContext);

    useInterval(
        () => {
            bidCollectFnc();
        },
        !login.loggedIn && !login.usePassword && bankIdData.status === BidStatus.pending ? 1100 : null
    );

    const bidFnc = useCallback(async () => {
        try {
            const response = await axios.get<BidAuth>(`${REACT_APP_AUTH_URI}/bid_auth`, {
                headers: {
                    Accept: "application/json; charset=utf-8",
                    "Content-Type": "application/json"
                },
                withCredentials: true
            });

            if (response.data.orderRef) {
                const data = response.data;

                localStorage.setItem("orderRef", data.orderRef);
                setBankIdData({
                    autoStartToken: data.autoStartToken,
                    qrStartToken: "",
                    orderRef: data.orderRef,
                    status: BidStatus.pending,
                    hintCode: BidHintCode.outstandingTransaction,
                    qrCode: data.qrCode
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        //console.log("bankIdData.status: ", bankIdData.status);
        //console.log("bankIdData: ", bankIdData);
        if (!login.loggedIn && !login.usePassword && bankIdData.status === BidStatus.start) {
            bidFnc();
        }
    }, [bankIdData.orderRef, bankIdData, bidFnc, login]);

    const bidCollectFnc = async () => {
        try {
            const response = await axios.get<BidCollect>(`${REACT_APP_AUTH_URI}/bid_auth/${bankIdData.orderRef}`, {
                headers: {
                    Accept: "application/json; charset=utf-8",
                    "Content-Type": "application/json"
                },
                withCredentials: true
            });

            if (response.data) {
                const data = response.data;
                if (data.status === BidStatus.complete) {
                    const expireDate = new Date();
                    expireDate.setSeconds(expireDate.getSeconds() + Number(data.expires_in));
                    const expires = expireDate.toISOString();

                    setLogin({
                        ...login,
                        expires,
                        loggedIn: true,
                        token: data.access_token,
                        username: data.user_display_name,
                        userId: data.user_id || "",
                        statusMessage: "",
                        errorMessage: "",
                        acceptedTerms: data.accepted_terms
                    });

                    setBankIdData({
                        autoStartToken: "",
                        qrStartToken: "",
                        orderRef: "",
                        status: BidStatus.pending,
                        hintCode: BidHintCode.outstandingTransaction,
                        qrCode: ""
                    });
                    localStorage.setItem("orderRef", "");

                    await setCookieOnServices(data.access_token);
                } else if (data.status === BidStatus.failed) {
                    setBankIdData({
                        ...bankIdData,
                        status: BidStatus.failed,
                        hintCode: data.hintCode,
                        qrCode: data.qrCode,
                        orderRef: ""
                    });
                    localStorage.setItem("orderRef", "");
                } else {
                    setBankIdData({
                        ...bankIdData,
                        status: data.status,
                        hintCode: data.hintCode,
                        qrCode: data.qrCode
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const passwordLoginFnc = async (personalNumber: string, password: string) => {
        const form = queryString.stringify({
            username: personalNumber,
            client_id: REACT_APP_DB_NAME,
            grant_type: "password",
            password
        });

        return axios
            .post(`${REACT_APP_AUTH_URI}/token`, form, {
                headers: {
                    Accept: "application/json; charset=utf-8",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                withCredentials: true
            })
            .then(async (result) => {
                const expireDate = new Date();
                expireDate.setSeconds(expireDate.getSeconds() + Number(result.data.expires_in));
                const expires = expireDate.toISOString();
                setLogin({
                    ...login,
                    ...{
                        expires: expires,
                        loggedIn: true,
                        token: result.data.access_token,
                        username: result.data.user_display_name,
                        userId: result.data.user_id ? result.data.user_id : "",
                        statusMessage: "",
                        errorMessage: "",
                        acceptedTerms: result.data.accepted_terms
                    }
                });
                localStorage.setItem("orderRef", "");
                await setCookieOnServices(result.data.access_token);
                return result;
            });
    };

    if (login.token) return null;

    if (login.usePassword) {
        return (
            <div
                className="loginscreen d-flex justify-content-center align-items-center"
                style={{
                    backgroundImage: "url(/background.jpg)",
                    height: "100vh"
                }}
            >
                <div className="row">
                    <div className="col me-4 ms-4" style={{ background: "white", borderRadius: "5px" }}>
                        <h2
                            className="row text-center py-2"
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                background: "#2186c5",
                                color: "white"
                            }}
                        >
                            {REACT_APP_NAME.toUpperCase()}
                        </h2>
                        <h2 className="row ms-2 me-2" style={{ justifyContent: "center", alignItems: "center" }}>
                            <span>Login with password</span>
                        </h2>
                        <div className="row">
                            <div className="col">
                                <Formik
                                    initialValues={{ personalNumber: "", password: "" }}
                                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                                        setSubmitting(true);
                                        await passwordLoginFnc(submitValues.personalNumber, submitValues.password)
                                            .catch((error) => {
                                                const formErrors: FormikErrors<Record<string, string>> = {};
                                                if (error?.response?.data?.error === "Unauthorized: incorrect password") {
                                                    formErrors["password"] = "Unauthorized: incorrect password";
                                                } else if (error?.response?.data?.error === "User not found") {
                                                    formErrors["personalNumber"] = "User not found";
                                                } else if (error?.response?.data?.error) {
                                                    formErrors["personalNumber"] = error?.response?.data?.error;
                                                } else if (error?.response?.status === 429) {
                                                    formErrors["personalNumber"] = error.response.data;
                                                } else {
                                                    console.error(error);
                                                    formErrors["personalNumber"] = "error " + error.toString();
                                                }
                                                setErrors(formErrors);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Fragment>
                                            <Form autoComplete="off">
                                                <div>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <TextField
                                                                name="personalNumber"
                                                                label="Personnummer"
                                                                className="center"
                                                                disabled={isSubmitting}
                                                                autoComplete="username"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-12">
                                                            <TextField
                                                                name="password"
                                                                label="password"
                                                                type="password"
                                                                className="center"
                                                                disabled={isSubmitting}
                                                                autoComplete={"current-password"}
                                                            />
                                                        </div>
                                                    </div>

                                                    <button
                                                        className="btn loginbtn btn-block"
                                                        id="loginsubmitbutton"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                            </Form>
                                        </Fragment>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        <div className="row mt-4" style={{ justifyContent: "center", alignItems: "center" }}>
                            <button
                                className="btn btn-link "
                                type="button"
                                onClick={() => {
                                    // sadly we do not support generation of a token for test with bankid
                                    const usePassword = REACT_APP_DB_NAME === "prod" ? !login.usePassword : true;
                                    setLogin({ ...login, usePassword, statusMessage: "", errorMessage: "" });
                                }}
                            >
                                Login with {login.usePassword ? "BankID app" : "password"} instead
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        if (!bankIdData.qrCode) {
            return (
                <div
                    className="loginscreen"
                    style={{
                        backgroundImage: "url(/background.jpg)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}
                ></div>
            );
        }
        return (
            <div
                className="loginscreen"
                style={{
                    backgroundImage: "url(/background.jpg)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh"
                }}
            >
                <div className="row">
                    <div className="col me-4 ms-4" style={{ background: "white", borderRadius: "5px" }}>
                        <h2
                            className="row"
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                background: "#2186c5",
                                color: "white"
                            }}
                        >
                            <span className="m-2">{REACT_APP_NAME.toUpperCase()}</span>
                        </h2>
                        <h2 className="d-flex me-4 ms-4 justify-content-center">
                            <span>Login with BankID</span>
                        </h2>
                        <div className="row">
                            <div className="col">
                                {bankIdData.status === BidStatus.pending ? (
                                    <div className="d-flex justify-content-center">
                                        {isMobile ? (
                                            <button
                                                className="btn mt-4"
                                                onClick={async () => {
                                                    if (bankIdData && bankIdData.autoStartToken) {
                                                        const url = `bankid:///?autostarttoken=${bankIdData.autoStartToken}&redirect=${encodeURIComponent(window.location.href)}`;
                                                        window.location.replace(url);
                                                    }
                                                    // const url = `https://app.bankid.com/?autostarttoken=${bankIdData.autoStartToken}&redirect=${window.location.href}`;
                                                    // Android intend maybe
                                                    // const url = `intent:///?autostarttoken=${bankIdData.autoStartToken}&redirect=${window.location.href}#Intent;scheme=bankid;package=com.bankid.bus;end`;
                                                    // window.location.replace(url);
                                                }}
                                            >
                                                Open BankId on same device
                                            </button>
                                        ) : (
                                            <>
                                                {bankIdData.hintCode === BidHintCode.outstandingTransaction ? (
                                                    <QRCodeCanvas value={bankIdData.qrCode} />
                                                ) : (
                                                    <div>
                                                        {bankIdData.hintCode === BidHintCode.userSign
                                                            ? "Sign in with your BankID app"
                                                            : bankIdData.hintCode}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className="d-flex mt-4 justify-content-center">
                                        <button
                                            className="btn loginbtn btn-block mt-4"
                                            onClick={() => {
                                                setBankIdData({
                                                    autoStartToken: "",
                                                    qrStartToken: "",
                                                    orderRef: "",
                                                    status: BidStatus.start,
                                                    hintCode: BidHintCode.outstandingTransaction,
                                                    qrCode: ""
                                                });
                                            }}
                                        >
                                            Restart BankID login
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="row mt-4" style={{ justifyContent: "center", alignItems: "center" }}>
                            <button
                                className="btn btn-link "
                                type="button"
                                onClick={() => {
                                    // sadly we do not support generation of a token for test with bankid
                                    const usePassword = REACT_APP_DB_NAME === "prod" ? !login.usePassword : true;
                                    setLogin({ ...login, usePassword, statusMessage: "", errorMessage: "" });
                                }}
                            >
                                Login with {login.usePassword ? "BankID app" : "password"} instead
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
