import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import { gql } from "urql";
import { cloneDeep, sortBy } from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

import { AttachmentForm } from "./AttachmentForm";
import { usePrevious, useAlertTimeOut } from "../../../components/src/common/Utils";
import { SubmitButton, TextField, SelectField, SearchMultipleSelectField } from "../../../components/src/form";
import { useGetAgreementsQuery } from "./AgreementsPage.generated";
import { useGetPartiesQuery, useUpsertAgreementsMutation } from "./AgreementPage.generated";
import {
    AgreementTypeEnum,
    AgreementStatusEnum,
    AgreementFilterInput,
    AgreementInput,
    ApprovedStatusEnum,
    ApprovedInfo
} from "../types.generated";
import { SYSTEM_PARTY_ID } from "../Constants";
import { MarkDownField } from "../components/form/MarkDownField";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { REACT_APP_API_URI } from "../env";

export const UpsertAgreements = gql`
    mutation UpsertAgreements($input: [AgreementInput!]!) {
        upsertAgreements(input: $input) {
            _id
            attachments {
                fileId
                clientId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            client {
                _id
                name
            }
            clientId
            counterParties {
                _id
                name
            }
            counterPartyIds
            createTimestamp
            description
            parties {
                _id
                name
            }
            partyIds
            status
            type
            approvedInfo {
                status
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                updateTimestamp
            }
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            versions
        }
    }
`;

export const GetParties = gql`
    query GetParties {
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }

        parties(filter: { typeIn: [Client, Broker, ClearingBroker, Custodian, ClearingHouse, EsgDataProvider] }) {
            _id
            name
        }
    }
`;

const getDefaultAgreement = (): AgreementInput => {
    const agreement: AgreementInput = {
        _id: "new",
        attachments: [],
        clientId: SYSTEM_PARTY_ID,
        counterPartyIds: [],
        description: "",
        partyIds: [],
        status: AgreementStatusEnum.Active,
        type: AgreementTypeEnum.Gmsla,
        approvedInfo: []
    };

    return agreement;
};

export function AgreementPage(): React.ReactElement {
    const navigate = useNavigate();
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);
    const isEditMode = id === "new" ? false : true;
    const isCreateMode = !isEditMode;

    const filter: AgreementFilterInput = { idIn: [id], statusIn: Object.values(AgreementStatusEnum) };
    const [{ fetching: loading, error, data }, refetch] = useGetAgreementsQuery({
        variables: { filter: filter },
        pause: isCreateMode,
        requestPolicy: "cache-and-network"
    });
    const [_, upsertMutation] = useUpsertAgreementsMutation();

    const [{ fetching: loadingParties, error: errorParties, data: dataParties }] = useGetPartiesQuery();
    const [agreement, setAgreement] = useState(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (id === "new" && !agreement) {
            setAgreement(getDefaultAgreement());
        } else if (previousId && id !== previousId) {
            setAgreement(null);
        } else if (data) {
            setAgreement(data.agreements[0]);
        }
    }, [agreement, data, id, previousId]);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            const approvedInfo: ApprovedInfo[] = [];
            attachments = recursivelyRemoveKey(attachments, "__typename");
            if (agreement.approvedInfo && agreement.approvedInfo.length) {
                for (const info of cloneDeep(agreement.approvedInfo)) {
                    if (info.updateUserInfo) {
                        delete info.updateUserInfo;
                    }
                    delete info.__typename;
                    approvedInfo.push(info);
                }
            }
            const input: AgreementInput = {
                _id: agreement._id,
                clientId: agreement.clientId,
                counterPartyIds: agreement.counterPartyIds,
                description: agreement.description,
                partyIds: agreement.partyIds,
                status: agreement.status,
                type: agreement.type,
                approvedInfo: approvedInfo,
                attachments: attachments
            };
            upsertMutation({ input: input }).then(() => {
                if (isEditMode) {
                    refetch();
                }
            });
        },
        [agreement, isEditMode, refetch, upsertMutation]
    );

    if (loading || loadingParties) return <div>Loading</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorParties) return <p>error: {JSON.stringify(errorParties, null, 2)}</p>;
    if (!agreement) return <div>No agreement found</div>;

    const partyOptions = sortBy(
        dataParties.parties.map((d) => ({ key: d._id, value: d.name, text: d.name })),
        "value"
    );

    const clientOptions = sortBy(
        dataParties.clients.map((d) => ({ key: d._id, value: d.name })),
        "value"
    );

    const defaultApprovedInfo = {
        status: ApprovedStatusEnum.Accepted,
        comment: ""
    };

    return (
        <div className="form">
            <Formik
                enableReinitialize={true}
                initialValues={agreement}
                onSubmit={async (submitValues, { setSubmitting }) => {
                    const approvedInfo: ApprovedInfo[] = [];
                    if (submitValues.approvedInfo && submitValues.approvedInfo.length) {
                        for (const info of cloneDeep(submitValues.approvedInfo)) {
                            if (info.updateUserInfo) {
                                delete info.updateUserInfo;
                            }
                            delete info.__typename;
                            approvedInfo.push(info);
                        }
                    }
                    const input: AgreementInput = {
                        _id: submitValues._id,
                        clientId: submitValues.clientId,
                        counterPartyIds: submitValues.counterPartyIds,
                        description: submitValues.description,
                        partyIds: submitValues.partyIds,
                        status: submitValues.status,
                        type: submitValues.type,
                        approvedInfo: approvedInfo
                    };
                    if (isCreateMode) {
                        delete input._id;
                    }
                    await upsertMutation({ input: [input] })
                        .then((result) => {
                            const agreement = result.data.upsertAgreements[0];
                            if (isCreateMode) {
                                navigate("/agreements/" + agreement._id, { replace: true });
                            } else {
                                setAgreement(agreement);
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: `Agreement '${agreement._id}' updated successfully!`
                                });
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values, errors }) => {
                    if (Object.keys(errors).length > 0) {
                        console.log(errors);
                    }
                    return (
                        <Fragment>
                            <h2 className="p-2">Agreement</h2>
                            <Form autoComplete="off">
                                <div className="d-sm-flex">
                                    <TextField name="_id" label="Id" type="text" className="p-2" disabled={true} />
                                    <TextField name="updateUserInfo.name" label="Updated by" type="text" className="p-2" disabled={true} />
                                </div>

                                <div className="p-2 mt-2">
                                    <MarkDownField
                                        graphqlApiBaseUrl={REACT_APP_API_URI}
                                        name="description"
                                        label="Description"
                                        className="btn btn-primary p-1 cursor-pointer"
                                        disabled={true}
                                    />
                                </div>

                                <div className="d-sm-flex">
                                    <SelectField
                                        name="clientId"
                                        label="Client (owner)"
                                        options={clientOptions}
                                        className="p-2"
                                        disabled={isSubmitting}
                                    />
                                    <SelectField
                                        name="status"
                                        label="Status"
                                        options={Object.keys(AgreementStatusEnum)}
                                        className="p-2"
                                        disabled={isSubmitting}
                                    />
                                    <SelectField
                                        name="type"
                                        label="Type"
                                        options={Object.keys(AgreementTypeEnum)}
                                        className="p-2"
                                        disabled={isSubmitting}
                                    />
                                </div>

                                <SearchMultipleSelectField
                                    name="partyIds"
                                    label="Parties"
                                    className="w-100 p-2"
                                    disabled={isSubmitting}
                                    options={partyOptions}
                                />

                                <SearchMultipleSelectField
                                    name="counterPartyIds"
                                    label="Counterparties"
                                    className="w-100 p-2"
                                    disabled={isSubmitting}
                                    options={partyOptions}
                                />

                                <div className="p-2">
                                    <label style={{ fontWeight: "bold" }}>Approved info</label>
                                    <FieldArray
                                        name="approvedInfo"
                                        render={(arrayHelpers) => (
                                            <Fragment>
                                                {values.approvedInfo && values.approvedInfo.length > 0 ? (
                                                    values.approvedInfo.map((item, index) => (
                                                        <div key={index} className="form-group form-row">
                                                            <div className="col-3">
                                                                <SelectField
                                                                    className=""
                                                                    name={`approvedInfo[${index}].status`}
                                                                    label="Status*"
                                                                    options={Object.keys(ApprovedStatusEnum)}
                                                                    disabled={item.updateUserId ? true : false}
                                                                />
                                                                <TextField
                                                                    name={`approvedInfo[${index}].comment`}
                                                                    label="Comment"
                                                                    className=""
                                                                    disabled={item.updateUserId ? true : false}
                                                                />
                                                                <TextField
                                                                    name={`approvedInfo[${index}].updateUserInfo.name`}
                                                                    label="User"
                                                                    className=""
                                                                    disabled={true}
                                                                />
                                                                <TextField
                                                                    name={`approvedInfo[${index}].updateTimestamp`}
                                                                    label="Time"
                                                                    className=""
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            {index === 0 ? (
                                                                <div className="col-2">
                                                                    <Button
                                                                        className="btn-sm mt-4"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(index, defaultApprovedInfo)}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <Button
                                                        className="btn-sm ms-1"
                                                        type="button"
                                                        onClick={() => arrayHelpers.push(defaultApprovedInfo)}
                                                    >
                                                        +
                                                    </Button>
                                                )}
                                            </Fragment>
                                        )}
                                    />
                                </div>

                                <div className="p-2">
                                    <SubmitButton disabled={isSubmitting || Object.keys(errors).length > 0} label={"Save"} />
                                </div>

                                {alert.visible ? (
                                    <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                        {alert.message}
                                    </Alert>
                                ) : null}
                            </Form>
                        </Fragment>
                    );
                }}
            </Formik>

            <div className="pb-3">
                {isEditMode ? (
                    <AttachmentForm
                        clientId={agreement.clientId}
                        attachments={agreement.attachments}
                        onChange={callBackOnChangeAttachment}
                    />
                ) : null}
            </div>
        </div>
    );
}
