import Plotly from "plotly.js-finance-dist";
import { PartialDeep } from "type-fest";
import { merge } from "lodash";

import { TimeSeries } from "../../common/src";

const defaultColor = "#2186c5";

export function getColor(index: number): string {
    try {
        const style = getComputedStyle(document.body);
        const c = style.getPropertyValue("--color" + (index % 12));
        return !c || c.trim() === "" ? defaultColor : c.trim();
    } catch {
        return defaultColor;
    }
}

export const getDefaultConfig = (): Partial<Plotly.Config> => {
    return {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: [
            "zoom2d",
            "pan2d",
            "select2d",
            "resetViews",
            "toggleSpikelines",
            "hoverClosestCartesian",
            "hoverCompareCartesian",
            "lasso2d",
            "zoomIn2d",
            "zoomOut2d",
            "hoverClosestPie"
        ],
        toImageButtonOptions: {
            format: "png",
            filename: "captor_plot_image"
        }
    };
};

export const getDefaultLayout = (): Partial<Plotly.Layout> => {
    const result: Partial<Plotly.Layout> = {
        autosize: true,
        dragmode: false,
        title: {
            y: 0.95,
            x: 0.5,
            xanchor: "center",
            yanchor: "top"
        },
        font: {
            size: 14,
            family: "Montserrat, sans-serif"
        },
        xaxis: {
            gridcolor: "#EEEEEE",
            zeroline: false
        },
        yaxis: {
            gridcolor: "#EEEEEE",
            zeroline: false
        },
        colorway: [
            "#2186c5",
            "#43a04c",
            "#f44336",
            "#f8a031",
            "#ba46b8",
            "#009688",
            "#ff5722",
            "#ec407a",
            "#c0ca33",
            "#795548",
            "#e5878a",
            "#9e9e9e"
        ],
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        showlegend: true,
        legend: {
            bgcolor: "rgba(0, 0, 0, 0)",
            x: 0.02,
            y: 0.02
        }
    };
    return result;
};

export const toTrace = (ts: TimeSeries, name: string, color?: string): Partial<Plotly.Data> => {
    let xy = { x: [], y: [] };
    if (ts.__values && ts.__dates) {
        xy = ts.__values.reduce(
            (p, c, i) => {
                const d = ts.__dates[i];
                p.x.push(d);
                p.y.push(c);
                return p;
            },
            { x: [], y: [] }
        );
    }
    if (!name && typeof ts.name !== "undefined") {
        name = ts.name;
    }
    const line: Record<string, any> = { width: 2.5 };
    const marker: Record<string, any> = {};
    const res: Record<string, any> = {
        name,
        ...xy,
        line: line,
        mode: "lines",
        hovertemplate: "<extra></extra>Date %{x| %Y-%m-%d}<br>Value %{y:.1%}",
        fill: "none",
        marker: marker
    };
    if (color) {
        res.line.color = color;
    }
    return res;
};

export const mergeConfig = (c: Partial<Plotly.Config>, override: PartialDeep<Plotly.Config>): Partial<Plotly.Config> => {
    return merge(c, override);
};

export const mergeLayout = (c: Partial<Plotly.Layout>, override: PartialDeep<Plotly.Layout>): Partial<Plotly.Layout> => {
    return merge(c, override);
};
