import React, { useMemo, Fragment, useState, ReactElement } from "react";
import { useQuery, gql, useMutation } from "urql";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cloneDeep, sortBy } from "lodash";

import { YesNoModal } from "../../components/YesNoModal";
import { Grid, Column } from "../../../../components/src";
import { dateFormater } from "../../components/dateFormater";
import { userHaveAccessTo } from "../../common/Permissions";

import { PermissionAssetEnum } from "../../types.generated";
import { useInterval } from "../../../../components/src/common/Utils";
import { REACT_APP_DB_NAME } from "../../env";
import { TooltipLabel } from "../../components/TooltipLabel";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";

export const GET_ME = gql`
    query {
        me {
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

export const GET_JOBS = gql`
    query jobDefinitions($filter: SchedulerJobDefinitionFilterInput) {
        schedulerJobDefinitions(filter: $filter) {
            _id
            clientIds
            clients {
                _id
                name
            }
            name
            type
            description
            jobUserId
            jobUser {
                name
            }
            args
            enabled
            schedule {
                day
                dayOfTheWeek
                hour
                minute
                month
            }
            timezone
            url
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                _id
                name
            }
            nextExecutionTime
            runs {
                _id
                jobDefinitionId
                logId
                logUrl
                startStatusCode
                startExecutionTime
                endExecutionTime
                status
            }
        }
    }
`;

export const UPSERT_JOB_DEFINITIONS = gql`
    mutation upsertJobDefinitions($input: [SchedulerJobDefinitionInput!]!) {
        schedulerUpsertJobDefinitions(input: $input) {
            _id
        }
    }
`;

const SCHEDULER_EXECUTE_JOB = gql`
    mutation schedulerExecuteJob($jobId: SchedulerGraphQLObjectId!) {
        schedulerExecuteJob(jobId: $jobId) {
            _id
            jobDefinitionId
            logId
            logUrl
            startStatusCode
            startExecutionTime
        }
    }
`;

enum JobResultEnum {
    Enqueued = "Enqueued",
    Failure = "Failure",
    Processing = "Processing",
    Running = "Running",
    Succeeded = "Succeeded"
}

const statusFormatter = (status: JobResultEnum): ReactElement => {
    if (status === JobResultEnum.Enqueued) {
        return <div className="btn btn-sm btn-warning btn-block">{JobResultEnum.Enqueued}</div>;
    } else if (status === JobResultEnum.Failure) {
        return <div className="btn btn-sm btn-danger btn-block">{JobResultEnum.Failure}</div>;
    } else if (status === JobResultEnum.Processing) {
        return <div className="btn btn-sm btn-warning btn-block">{JobResultEnum.Processing}</div>;
    } else if (status === JobResultEnum.Running) {
        return <div className="btn btn-sm btn-warning btn-block">{JobResultEnum.Running}</div>;
    } else if (status === JobResultEnum.Succeeded) {
        return <div className="btn btn-sm btn-success btn-block">{JobResultEnum.Succeeded}</div>;
    }
    return <div></div>;
};

function timeDifference(start: Date, end: Date): string {
    // Calculate the difference in milliseconds
    const duration = end.getTime() - start.getTime();

    // Calculate hours, minutes, seconds, and milliseconds
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const seconds = Math.floor((duration / 1000) % 60);
    const milliseconds = duration % 1000;

    // Format the time components to always have two digits
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secondsStr = seconds.toString().padStart(2, "0");
    const millisecondsStr = milliseconds.toString().padStart(3, "0");

    // Combine the parts into the final time string
    return `${hoursStr}:${minutesStr}:${secondsStr}.${millisecondsStr}`;
}

export const JobTable = ({ header, items, refetch }: { header: string; items: any[]; refetch: (args?) => void }): ReactElement => {
    const [_, schedulerExecuteJob] = useMutation(SCHEDULER_EXECUTE_JOB);
    const [__, upsertJobs] = useMutation(UPSERT_JOB_DEFINITIONS);

    const [modal, setModal] = useState({ showModal: false, payload: null });

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to run the job " + modal.payload.name + "? It cannot be undone."}
                    yesText={"Run"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        schedulerExecuteJob({ jobId: modal.payload._id })
                            .catch((error) => {
                                console.error(error.toString());
                            })
                            .finally(() => {
                                refetch({ requestPolicy: "cache-and-network" });
                            });
                    }}
                />
            ) : null}
            <Fragment>
                <p>Number of jobs: {items.length}</p>
                <Grid header={header} data={items} sortable tableClassName="table-xs" hideDownload={true}>
                    <Column
                        field="name"
                        className=""
                        format={(value, item) => {
                            return (
                                <Link to={"/job/" + item._id}>
                                    {<TooltipLabel text={value} content={item.description} delayOnShow={0} />}
                                </Link>
                            );
                        }}
                    />
                    <Column
                        field="clients"
                        title="Clients"
                        className=""
                        format={(clients) => {
                            return (
                                <div className="">
                                    {clients.map((client) => {
                                        return <div key={client._id}>{client.name}</div>;
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        field="jobUser"
                        title="Job user"
                        className=""
                        format={(jobUser) => {
                            return <div>{jobUser.name}</div>;
                        }}
                    />
                    <Column field="timezone" />
                    <Column field="type" />
                    <Column field="startExecutionTime" title="Start execution time" format={dateFormater} />
                    <Column field="nextExecutionTime" title="Next execution time" format={dateFormater} />
                    <Column field="startStatusCode" title="Start status code" />
                    <Column field="duration" title="Duration h:m:s" />
                    <Column field="status" format={statusFormatter} />
                    <Column
                        field="jobRunId"
                        title="Log"
                        className=""
                        format={(value) => {
                            return <Link to={"/jobrun/" + value}>log</Link>;
                        }}
                    />
                    <Column
                        field="enabled"
                        format={(value, item) => {
                            let color = "success";
                            if (!value) {
                                color = "danger";
                            }
                            const text = value ? "enabled" : "disabled";

                            return (
                                <Button
                                    variant={color}
                                    size="sm"
                                    onClick={async () => {
                                        let job = {
                                            enabled: !item.enabled,
                                            // keep the rest
                                            _id: item._id,
                                            name: item.name,
                                            clientIds: item.clientIds,
                                            type: item.type,
                                            description: item.description,
                                            jobUserId: item.jobUserId,
                                            args: item.args,
                                            schedule: item.schedule,
                                            timezone: item.timezone,
                                            url: item.url
                                        };

                                        job = recursivelyRemoveKey(job, "__typename");
                                        await upsertJobs({ input: [job] })
                                            .catch((error) => {
                                                console.error(error);
                                            })
                                            .finally(() => {
                                                refetch();
                                            });
                                    }}
                                >
                                    {text}
                                </Button>
                            );
                        }}
                    />
                    <Column
                        field="name"
                        title="Run"
                        format={(value, item) => {
                            return (
                                <div>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                            setModal({ showModal: true, payload: { name: item.name, _id: item._id } });
                                        }}
                                    >
                                        Run
                                    </Button>
                                </div>
                            );
                        }}
                    />
                </Grid>
            </Fragment>
        </div>
    );
};

export const JobsPage = (): ReactElement => {
    const [{ data: dataMe, fetching: loadingMe, error: errorMe }] = useQuery({
        query: GET_ME,
        requestPolicy: "cache-and-network"
    });

    const [{ fetching, error, data }, refetch] = useQuery({
        query: GET_JOBS,
        requestPolicy: "cache-and-network",
        pause: REACT_APP_DB_NAME === "prod" ? false : true
    });

    const { scheduledJobs, manualJobs } = useMemo(() => {
        let scheduledJobs = [];
        let manualJobs = [];
        if (!data || !data.schedulerJobDefinitions) {
            return { scheduledJobs, manualJobs };
        }
        data.schedulerJobDefinitions.forEach((item) => {
            let schedulerJobDefinition = cloneDeep(item);
            if (schedulerJobDefinition.runs.length === 1) {
                const { _id: jobRunId, ...run } = schedulerJobDefinition.runs[0];
                let duration = null;
                if (run.status === JobResultEnum.Failure || run.status === JobResultEnum.Succeeded) {
                    duration = timeDifference(new Date(run.startExecutionTime), new Date(run.endExecutionTime));
                }
                schedulerJobDefinition = { ...run, jobRunId, ...schedulerJobDefinition, duration };
            }
            if (schedulerJobDefinition.type === "Manual") {
                manualJobs.push(schedulerJobDefinition);
            } else {
                scheduledJobs.push(schedulerJobDefinition);
            }
        });
        scheduledJobs = sortBy(scheduledJobs, "name");
        manualJobs = sortBy(manualJobs, "name");
        return { scheduledJobs, manualJobs };
    }, [data]);

    useInterval(
        () => {
            if (REACT_APP_DB_NAME !== "prod") return;
            if (fetching) return;
            refetch({ requestPolicy: "cache-and-network" });
        },
        5000 // Delay in milliseconds or null to stop it
    );

    if (loadingMe) return <p>Loading</p>;
    if (errorMe) return <p>Error: {JSON.stringify(errorMe, null, 2)}</p>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Job, dataMe.me.roles)) {
        return <div />;
    }

    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (!data) return <p>Loading</p>;

    if (REACT_APP_DB_NAME !== "prod") {
        return <div>Jobs page is only available in production </div>;
    }

    return (
        <div>
            <JobTable header={"Scheduled jobs"} items={scheduledJobs} refetch={refetch}></JobTable>
            <JobTable header={"Manual jobs"} items={manualJobs} refetch={refetch}></JobTable>
        </div>
    );
};
