import React from "react";
import { Alert } from "react-bootstrap";
import { useQueryState } from "../../../../components/src/use-query-state";

import { DateForm } from "../../../../components/src/dateForm";

import { Fx } from ".";

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const FxView = (): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));

    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
                {!check_today_date(endDate) ? (
                    <Alert variant="danger" className="ms-2">
                        Historical or future date selected
                    </Alert>
                ) : null}
            </div>
            <br />
            <Fx endDate={endDate} />
        </div>
    );
};
