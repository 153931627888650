import React, { useState, useEffect, useContext } from "react";
import { keyBy, cloneDeep } from "lodash";
import { Tabs, Tab } from "react-bootstrap";
import { gql, useMutation, useQuery } from "urql";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Panel } from "@fluentui/react";

import { Svgs } from "../../../components/src";
import { emptyObjectId } from "../../../common/src";
import { useInterval, usePrevious, useQueryArgs } from "../../../components/src/common/Utils";
import { DateForm } from "../../../components/src/dateForm";

import { Page } from "../components/Page";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "./MiniTransactionForm";
import { UpdateTransactionsStatusGrid } from "../components/UpdateTransactionStatusGrid";
import { ITransactionDataRow } from "../components/TransactionsGrid";
import { useQueryState } from "../../../components/src/use-query-state";
import { serializeSwedenDate } from "../components/dateFormater";
import { PartyType, UpdateTransactionInput } from "../types.generated";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext, IClient } from "../contexts/ClientContext";

const GET_CASH_BALANCE = gql`
    query cashBalances($instrumentIds: [GraphQLObjectId!]!, $viewFromDate: GraphQLDateString) {
        cashBalances(instrumentIds: $instrumentIds, viewFromDate: $viewFromDate) {
            instrumentId
            instrument {
                name
                currency
            }
            balanceItems {
                transactionId
                transaction {
                    status
                    type
                    updateUserInfo {
                        name
                    }
                    updateTimestamp
                }
                tradeDate
                valueDate
                amount
                balance
            }
        }
    }
`;

const GET_CLIENTS = gql`
    query {
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
            instruments {
                name
                _id
            }
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

const defaultViewFromDateString = () => {
    return serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 30)));
};

const transactionGroups = ["Not settled", "Settled"];

const groupByStatus = (transactions, transactionGroups) => {
    const result = [];
    transactionGroups.forEach((status) => {
        result[status] = [];
    });

    transactions.forEach((item) => {
        if (item.transaction.status === "Settled") {
            item.statusGroup = item.transaction.status;
        } else {
            item.statusGroup = "Not settled";
        }
        if (transactionGroups.includes(item.statusGroup)) {
            result[item.statusGroup].push(item);
        }
    });

    return result;
};

export const PositionCashBalancePage = (): React.ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { queryArgs } = useQueryArgs();
    const { client } = useContext(ClientContext);
    const previousClient: IClient = usePrevious(client);
    const [viewFromDate] = useQueryState("viewFromDate", defaultViewFromDateString());
    const [tab, setTab] = useState(null);
    const [instrumentIds, setInstrumentIds] = useState([emptyObjectId]);

    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: GET_CLIENTS,
        requestPolicy: "cache-and-network"
    });

    const [{ error: errorUpdate }, updateTransactionsStatus] = useMutation(UPDATE_TRANSACTIONS);

    const params: any = useParams();
    let id = params.id;

    let type = miniTransactionTypes[0];

    if (id && id.startsWith("new")) {
        type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
        id = "new";
    }

    const [{ fetching: loadingCashBalance, error: errorCashBalance, data: dataCashBalances }, refetch] = useQuery({
        query: GET_CASH_BALANCE,
        variables: { viewFromDate: viewFromDate || defaultViewFromDateString(), instrumentIds },
        requestPolicy: "cache-and-network",
        pause: false
    });

    useInterval(
        () => {
            if (loadingCashBalance) return;
            refetch({ requestPolicy: "cache-and-network" });
        },
        10000 // Delay in milliseconds or null to stop it
    );

    useEffect(() => {
        if (dataClients) {
            if (client) {
                if (
                    (client && instrumentIds.length === 1 && instrumentIds[0] === emptyObjectId) ||
                    (client && previousClient && client._id !== previousClient._id)
                ) {
                    for (let i = 0; i < dataClients.clients.length; i++) {
                        const clientData = dataClients.clients[i];
                        if (client._id === clientData._id) {
                            setInstrumentIds(clientData.instruments.map((instrument) => instrument._id));
                            break;
                        }
                    }
                }
            }
        }

        /*if (client && client._id === emptyObjectId && Object.keys(queryArgs).length) {
            navigate("/reconcile/cashbalance/" + client.dashName + location.search);
        }*/
    }, [dataClients, client, instrumentIds, previousClient, queryArgs, navigate]);

    if (loadingClients) return <p>Loading clients</p>;
    if (errorClients) return <p>errorClients: {JSON.stringify(errorClients, null, 2)}</p>;
    if (errorUpdate) return <p>error: {JSON.stringify(errorUpdate, null, 2)}</p>;
    if (errorCashBalance) return <p>errorCashBalance: {JSON.stringify(errorCashBalance, null, 2)}</p>;

    if (!dataCashBalances) {
        return (
            <Page className="loader">
                <div>
                    <div>Loading cash balance</div>
                    <Svgs.Loader />
                </div>
            </Page>
        );
    }

    const cashBalancesClone = cloneDeep(dataCashBalances.cashBalances);

    const groupedByInstrumentId = keyBy(cashBalancesClone, "instrumentId");
    const tabs = {};
    Object.keys(groupedByInstrumentId).forEach((instrumentId) => {
        tabs[instrumentId] = groupedByInstrumentId[instrumentId].instrument.name;
    });

    const tabNames = [];
    for (const key in tabs) {
        tabNames.push(tabs[key]);
    }
    const tabNamesSorted = tabNames.sort();
    const tabsSortedByName = {};
    tabNamesSorted.forEach((instrumentName) => {
        for (const tabKey in tabs) {
            if (tabs[tabKey] === instrumentName) {
                tabsSortedByName[tabKey] = instrumentName;
            }
        }
    });

    let activeTab = null;
    if (tab && tabsSortedByName[tab]) {
        activeTab = tab;
    } else if (Object.keys(tabsSortedByName).length > 0) {
        activeTab = Object.keys(tabsSortedByName)[0];
    }

    let groups = {};
    if (Object.keys(groupedByInstrumentId).length > 0 && groupedByInstrumentId[activeTab]) {
        groups = groupByStatus(groupedByInstrumentId[activeTab].balanceItems, transactionGroups);
    }

    const handleStatusUpdate = async (input: UpdateTransactionInput[]) => {
        await updateTransactionsStatus({ input: input })
            .then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toTransactionItems = (items: any, instrumentId, instrumentName, currency): ITransactionDataRow[] => {
        if (items) {
            return items.map((item: any) => {
                return {
                    _id: item.transactionId,
                    amount: item.amount,
                    currency: currency,
                    instrumentId: instrumentId,
                    instrumentName: instrumentName,
                    tradeDate: item.tradeDate,
                    valueDate: item.valueDate,
                    type: item.transaction.type,
                    //types: item.types,
                    balance: item.balance,
                    description: item.description,
                    status: item.transaction.status,
                    updatedByUserName: item.transaction.updateUserInfo ? item.transaction.updateUserInfo.name : ""
                };
            });
        } else {
            return [];
        }
    };

    return (
        <div>
            <div className="row">
                <div className="col-xs-6 col-sm-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} />
                </div>
            </div>

            {client && client._id !== emptyObjectId ? (
                <div>
                    <div className="row mt-4">
                        <div className="col-xs-6 col-sm-4">
                            <DateForm defaultDateString={viewFromDate} dateName={"viewFromDate"} className="width-fixed"></DateForm>
                        </div>
                        <div className="col-xs-6 col-sm-8 text-end">
                            <NewMiniTransactionButton page={`reconcile/cashbalance/${client.dashName}`} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className={"col-12"}>
                            {activeTab && client && client._id ? (
                                <Tabs onSelect={setTab} activeKey={activeTab} transition={false}>
                                    {Object.keys(tabsSortedByName).map((instrumentId) => {
                                        const instrumentName = groupedByInstrumentId[instrumentId].instrument.name;
                                        const instrumentCurrency = groupedByInstrumentId[instrumentId].instrument.currency;

                                        return (
                                            <Tab key={instrumentId} eventKey={instrumentId} title={tabsSortedByName[instrumentId]}>
                                                <Link to={"/parties/" + client._id + "/instruments/" + instrumentId}>Instrument</Link>
                                                <br />
                                                {transactionGroups.map((statusGroup) => (
                                                    <div key={statusGroup} className="mt-4 mb-4">
                                                        <h2>{statusGroup}</h2>
                                                        {groups[statusGroup] ? (
                                                            <UpdateTransactionsStatusGrid
                                                                key={"sg" + groups[statusGroup].length}
                                                                items={toTransactionItems(
                                                                    groups[statusGroup],
                                                                    instrumentId,
                                                                    instrumentName,
                                                                    instrumentCurrency
                                                                )}
                                                                onUpdateStatus={handleStatusUpdate}
                                                                visibleColumns={[
                                                                    "id",
                                                                    "instrument",
                                                                    "currency",
                                                                    "status",
                                                                    "tradeDate",
                                                                    "valueDate",
                                                                    "type",
                                                                    // "types",
                                                                    "amount",
                                                                    "balance",
                                                                    "description",
                                                                    "updatedBy"
                                                                ]}
                                                            />
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </Tab>
                                        );
                                    })}
                                </Tabs>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/cashbalance/${client.dashName}${location.search}`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id ? <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} /> : null}
            </Panel>
        </div>
    );
};
