import React, { Fragment, useState, useEffect, useCallback } from "react";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { cloneDeep, groupBy, keyBy } from "lodash";

import { YesNoModal } from "../components/YesNoModal";
import { TextField, SelectField, SearchListField, SubmitButton, NumberField, DateField, CheckBoxField } from "../../../components/src/form";
import { addWeekDays, generateRandomString, useAlertTimeOut } from "../../../components/src/common/Utils";
import { usePrevious } from "../../../components/src/common/Utils";

import { serializeSwedenDate } from "../components/dateFormater";
import {
    PartyFundInfoClass,
    TransferAgentOrder,
    TransferAgentOrderInput,
    CurrencyEnum,
    TransferAgentOrderEnum,
    TransactionStatus,
    TransferAgentOrderSubTypeEnum
} from "../types.generated";
import { MarkDownField } from "../components/form/MarkDownField";
import { AttachmentForm } from "./AttachmentForm";
import { recursivelyRemoveKey } from "../../../common/src";
import { REACT_APP_API_URI } from "../env";

type TransferAgentOrderForm = Partial<
    Omit<TransferAgentOrderInput, "amount" | "units"> & {
        amount: number;
        units: number;
        instrumentId?: string;
        toClientNumber?: string;
        toClientAccount?: string;
        toClientName?: string;
    }
>;

const GET_DATA = gql`
    query getData($orderId: GraphQLObjectId!) {
        funds: parties(filter: { typeIn: [Fund] }) {
            _id
            name
            fundInfo {
                classes {
                    enabled
                    dividendPaying
                    shareClass
                    instrument {
                        _id
                        isin
                        name
                        currency
                    }
                }
            }
        }

        transferAgent: party(_id: "57dbfea69caa3c3efcfff63c") {
            _id
            name
        }

        order: transferAgentOrder(_id: $orderId) {
            _id
            carryOwnTransactionCost
            currency
            amount
            units
            isin
            tradeDate
            valueDate
            transactionType
            externalId
            sourceId
            clientId
            clientName
            clientNumber
            clientAccount
            status
            transactionIds
            description
            subType
            referenceExternalId
            attachments {
                fileId
                fileName
                mimeType
                updateTimestamp
            }
        }

        transferAgentOrders {
            _id
            externalId
            sourceId
        }

        navs: navs(lastOnly: true) {
            date
            clientId
        }
    }
`;

const UPSERT_ORDERS = gql`
    mutation upsertTransferAgentOrders($input: [TransferAgentOrderInput!]!) {
        upsert: upsertTransferAgentOrders(input: $input) {
            _id
            carryOwnTransactionCost
            currency
            amount
            units
            isin
            tradeDate
            valueDate
            transactionType
            externalId
            sourceId
            clientName
            clientNumber
            clientAccount
            status
            transactionIds
            description
            subType
            referenceExternalId
        }
    }
`;

const UPDATE_ORDER_ATTACHMENTS = gql`
    mutation updateTransferAgentOrderAttachments($input: TransferAgentOrderAttachmentsInput!) {
        upsert: updateTransferAgentOrderAttachments(input: $input) {
            _id
        }
    }
`;

type TransferAgentOrderFormProps = {
    refetch?: () => void;
};

export function TransferAgentOrderForm(props: TransferAgentOrderFormProps): React.ReactElement {
    const { id }: any = useParams();
    const previousId = usePrevious(id);

    let orderId = "000000000000000000000000";
    if (id && id !== "new") {
        orderId = id;
    }

    const [{ fetching: loading, error, data }, refetch] = useQuery({ query: GET_DATA, variables: { orderId } });

    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [formData, setFormData] = useState<TransferAgentOrderForm>(null);

    const [orderAttachments, setOrderAttachments] = useState([]);
    const [orderClientId, setOrderClientId] = useState(null);

    const [__stateUpsert, upsertMutation] = useMutation(UPSERT_ORDERS);
    const [__stateUpsertAttachments, upsertAttachmentsMutation] = useMutation(UPDATE_ORDER_ATTACHMENTS);
    const navigate = useNavigate();
    const location = useLocation();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const isins = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const instruments = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fundClassByInstrumentId: Record<string, PartyFundInfoClass> = {};
    if (data && data.funds) {
        data.funds.forEach((fund) => {
            fund.fundInfo.classes.forEach((c: PartyFundInfoClass) => {
                //if (c.enabled) { Cannot only have enabled classes, cause then you cannot see old orders
                isins.push(c.instrument.isin);
                instruments.push(c.instrument);
                fundClassByInstrumentId[c.instrument._id] = c;
            });
        });
    }

    const instrumentsByIsin = keyBy(instruments, "isin");
    const instrumentsById = keyBy(instruments, "_id");

    useEffect(() => {
        if (previousId && previousId !== id) {
            setFormData(null);
        } else if (!loading && !error) {
            if (formData === null) {
                if (id && id !== "new" && data.order) {
                    const order: TransferAgentOrder = data.order;
                    const instrument = instrumentsByIsin[order.isin];
                    const initFormData: TransferAgentOrderForm = {
                        _id: order._id,
                        carryOwnTransactionCost: order.carryOwnTransactionCost,
                        currency: order.currency,
                        amount: parseFloat(order.amount),
                        units: parseFloat(order.units),
                        instrumentId: instrument._id,
                        tradeDate: order.tradeDate,
                        valueDate: order.valueDate,
                        transactionType: order.transactionType,
                        externalId: order.externalId,
                        sourceId: order.sourceId,
                        clientName: order.clientName,
                        clientNumber: order.clientNumber,
                        clientAccount: order.clientAccount,
                        status: order.status,
                        transactionIds: order.transactionIds,
                        description: order.description || "",
                        subType: order.subType,
                        referenceExternalId: order.referenceExternalId
                    };
                    setFormData(initFormData);
                    setOrderClientId(data.order.clientId);
                    setOrderAttachments(cloneDeep(data.order.attachments));
                } else {
                    // default
                    const today = serializeSwedenDate(new Date());
                    const instrumentId = instruments[0]._id;
                    const initFormData: TransferAgentOrderForm = {
                        _id: "new",
                        carryOwnTransactionCost: fundClassByInstrumentId[instrumentId].shareClass === "C" ? true : false,
                        currency: CurrencyEnum.SEK,
                        amount: 0.0,
                        units: 0.0,
                        instrumentId: instrumentId,
                        tradeDate: today,
                        valueDate: addWeekDays(today, 2),
                        transactionType: TransferAgentOrderEnum.BUY,
                        externalId: "",
                        sourceId: data.transferAgent._id,
                        clientName: "",
                        clientNumber: "",
                        clientAccount: "",
                        status: TransactionStatus.Pending,
                        transactionIds: [],
                        description: "",
                        subType: TransferAgentOrderSubTypeEnum.NORMAL,
                        referenceExternalId: ""
                    };

                    setFormData(initFormData);
                }
            }
        }
    }, [previousId, id, loading, error, formData, data, instrumentsByIsin, instruments, fundClassByInstrumentId]);

    const onChangeAttachment = useCallback(
        (attachments) => {
            const cleanAttachments = recursivelyRemoveKey(attachments, "__typename");

            const input = {
                _id: orderId,
                attachments: cleanAttachments
            };

            upsertAttachmentsMutation({ input }).then(() => {
                setOrderAttachments(cleanAttachments);
            });
        },
        [orderId, upsertAttachmentsMutation, setOrderAttachments]
    );

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );
    if (error)
        return (
            <div className="loader">
                <h3>Failed loading data</h3>
            </div>
        );

    if (orderId !== "000000000000000000000000" && !data.order)
        return (
            <div className="loader">
                <h3>No order with that id</h3>
            </div>
        );

    if (!formData) return <div></div>;

    let isCreateMode = false;
    if (id === "new") {
        isCreateMode = true;
    }

    const pageTitle = isCreateMode ? "New order" : "Edit order";
    const submitButtonLabel = isCreateMode ? "Create" : "Update";

    const externalIdByTransferAgent = groupBy(data.transferAgentOrders, "sourceId");

    const fund = data.funds.find((f) => f.fundInfo.classes.filter((c) => c.instrumentId === formData.instrumentId));
    const nav = data.navs.find((x) => x.clientId === fund._id);
    const minDate = nav ? new Date(nav.date) : new Date("2001-01-01");
    minDate.setDate(minDate.getDate() + 1);

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete order with id " + modal.payload.input._id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let mutationData: any;
                        upsertMutation({ input: [modal.payload.input] })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    mutationData = result.data.upsert[0];
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The order '${modal.payload.input._id}' updated and deleted successfully!`
                                    });
                                }
                            })
                            .catch((error) => {
                                console.error(error.toString());
                            })
                            .finally(() => {
                                refetch();
                                if (mutationData && mutationData._id) {
                                    mutationData.instrumentId =
                                        instrumentsByIsin && instrumentsByIsin[mutationData.isin]
                                            ? instrumentsByIsin[mutationData.isin]._id
                                            : null;
                                    setFormData(mutationData);
                                }
                                if (props.refetch) {
                                    props.refetch();
                                }
                            });
                    }}
                />
            ) : null}

            <h3>{pageTitle}</h3>

            {formData._id !== "new" && formData.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? (
                <div>
                    <div>WARNING: if you edit this order, you must also edit the corresponding order with externalId: </div>
                    <Link to={"/transferagentorders/?externalId=" + formData.referenceExternalId + "&tradeDate=" + formData.tradeDate}>
                        {formData.referenceExternalId}
                    </Link>
                </div>
            ) : null}

            <div className="">
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={formData}
                    validationSchema={Yup.object({
                        externalId: Yup.string()
                            .min(5, "Must be at least 5 characters")
                            .max(30, "Must be 30 characters or less")
                            .required("Required"),
                        amount: Yup.number().min(0),
                        units: Yup.number().min(0)
                    })}
                    validate={(validateFormData: TransferAgentOrderForm) => {
                        const errors: any = {};
                        if (
                            validateFormData.subType === TransferAgentOrderSubTypeEnum.NORMAL &&
                            validateFormData.transactionType === TransferAgentOrderEnum.BUY &&
                            validateFormData.units > 0
                        ) {
                            errors.type = "BUY order of units is not supported";
                            errors.units = "BUY order of units is not supported";
                        }
                        if (validateFormData.amount === 0.0 && validateFormData.units === 0.0) {
                            errors.amount = "Amount or units must be 0, not both";
                            errors.units = "Amount or units must be 0, not both";
                        }
                        if (validateFormData.amount !== 0.0 && validateFormData.units !== 0.0) {
                            errors.amount = "Amount or units must be !==0, not both";
                            errors.units = "Amount or units must be !==0, not both";
                        }

                        if (validateFormData.externalId) {
                            const externalIds = keyBy(externalIdByTransferAgent[validateFormData.externalId], "externalId");
                            const existingOrder = externalIds[validateFormData.externalId];
                            if (existingOrder && existingOrder._id !== validateFormData._id) {
                                errors.externalId = "externalId already exist on order: " + existingOrder._id;
                            }
                        }
                        if (validateFormData.externalId === "") {
                            validateFormData.externalId = generateRandomString(15);
                        }

                        const fundClass = fundClassByInstrumentId[validateFormData.instrumentId];

                        if (validateFormData.instrumentId !== formData.instrumentId) {
                            if (fundClass && fundClass.shareClass === "C") {
                                validateFormData.carryOwnTransactionCost = true;
                            } else {
                                validateFormData.carryOwnTransactionCost = false;
                            }
                        }

                        if (fundClass && fundClass.instrument && fundClass.instrument.currency !== validateFormData.currency) {
                            errors.currency = `Currency must be '${fundClass.instrument.currency}'`;
                        }

                        setFormData(validateFormData);

                        console.log({ errors });

                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const submitValues: TransferAgentOrderForm = values;
                        let input: TransferAgentOrderInput[] = [];

                        if (submitValues._id === "new" && submitValues.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH) {
                            const externalIdSell = "SWITCH_A_" + generateRandomString(12);
                            const externalIdBuy = "SWITCH_A_" + generateRandomString(12);
                            input = [
                                {
                                    carryOwnTransactionCost: false,
                                    currency: instrumentsById[submitValues.instrumentId].currency,
                                    amount: "0.0",
                                    units: submitValues.units.toFixed(4), // TODO: decimals depending on fund
                                    isin: instrumentsById[submitValues.instrumentId].isin,
                                    tradeDate: submitValues.tradeDate,
                                    valueDate: submitValues.tradeDate,
                                    transactionType: TransferAgentOrderEnum.SELL,
                                    externalId: externalIdSell,
                                    sourceId: submitValues.sourceId,
                                    clientName: "",
                                    clientNumber: submitValues.clientNumber,
                                    clientAccount: submitValues.clientAccount,
                                    status: submitValues.status,
                                    transactionIds: submitValues.transactionIds,
                                    subType: submitValues.subType,
                                    referenceExternalId: externalIdBuy
                                },
                                {
                                    carryOwnTransactionCost: false,
                                    currency: instrumentsById[submitValues.instrumentId].currency,
                                    amount: "0.0",
                                    units: submitValues.units.toFixed(4), // TODO: decimals depending on fund
                                    isin: instrumentsById[submitValues.instrumentId].isin,
                                    tradeDate: submitValues.tradeDate,
                                    valueDate: submitValues.tradeDate,
                                    transactionType: TransferAgentOrderEnum.BUY,
                                    externalId: externalIdBuy,
                                    sourceId: submitValues.sourceId,
                                    clientName: "",
                                    clientNumber: submitValues.toClientNumber,
                                    clientAccount: submitValues.toClientAccount,
                                    status: submitValues.status,
                                    transactionIds: submitValues.transactionIds,
                                    subType: submitValues.subType,
                                    referenceExternalId: externalIdSell
                                }
                            ];
                        } else {
                            input = [
                                {
                                    _id: submitValues._id,
                                    carryOwnTransactionCost: submitValues.carryOwnTransactionCost,
                                    currency: submitValues.currency,
                                    amount: submitValues.amount.toFixed(2),
                                    units: submitValues.units.toFixed(4), // TODO: decimals depending on fund
                                    isin: instrumentsById[submitValues.instrumentId].isin,
                                    tradeDate: submitValues.tradeDate,
                                    valueDate: submitValues.valueDate,
                                    transactionType: submitValues.transactionType,
                                    externalId: submitValues.externalId,
                                    sourceId: submitValues.sourceId,
                                    clientName: "",
                                    clientNumber: submitValues.clientNumber,
                                    clientAccount: submitValues.clientAccount,
                                    status: submitValues.status,
                                    transactionIds: submitValues.transactionIds,
                                    subType: submitValues.subType,
                                    referenceExternalId: submitValues.referenceExternalId
                                }
                            ];
                            if (isCreateMode) {
                                delete input[0]._id;
                            }
                        }

                        let mutationData: TransferAgentOrderForm = null;

                        if (!isCreateMode && input[0].status && input[0].status === TransactionStatus.Deleted) {
                            const inputDelete: any = cloneDeep(input[0]);
                            setModal({ showModal: true, payload: { input: inputDelete } });
                        } else if (!input[0].status || input[0].status !== TransactionStatus.Deleted) {
                            await upsertMutation({ input: input })
                                .then((result) => {
                                    if (result.error) {
                                        setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                    } else {
                                        mutationData = result.data.upsert[0];
                                        mutationData.instrumentId =
                                            instrumentsByIsin && instrumentsByIsin[mutationData.isin]
                                                ? instrumentsByIsin[mutationData.isin]._id
                                                : null;
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.toString());
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    // redirect to edit Order page on create success
                                    if (mutationData && mutationData._id) {
                                        if (isCreateMode) {
                                            const path = location.pathname.split("/");
                                            path.pop();
                                            path.push(mutationData._id);
                                            navigate(path.join("/"), { replace: true });
                                        }
                                        refetch();
                                        setFormData(mutationData);
                                        /*if (props.refetch) {
                                            props.refetch();
                                        }*/

                                        if (input.length === 2) {
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `The orders '${input[0].externalId}' and '${input[1].externalId}' created successfully!`
                                            });
                                        } else {
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `The order '${input[0].externalId}' created/updated successfully!`
                                            });
                                        }
                                    }
                                });
                        }
                    }}
                >
                    {({ isSubmitting, values: valuesUntyped }) => {
                        const values: TransferAgentOrderForm = valuesUntyped;
                        return (
                            <Fragment>
                                <Form autoComplete="off">
                                    <div className="form">
                                        <div className="form-row">
                                            <div className="col">
                                                <TextField className="" name="_id" label="Id" disabled={true} />
                                            </div>
                                            {values._id === "new" &&
                                            values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? null : (
                                                <div className="col">
                                                    <SelectField
                                                        className=""
                                                        name="transactionType"
                                                        label="Type*"
                                                        options={Object.values(TransferAgentOrderEnum)}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            )}
                                            <div className="col">
                                                <SelectField
                                                    className=""
                                                    name="subType"
                                                    label="SubType"
                                                    options={Object.values(TransferAgentOrderSubTypeEnum)}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <NumberField className="" name="units" label="Units*" disabled={isSubmitting} />
                                            </div>
                                            {values._id === "new" &&
                                            values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? null : (
                                                <Fragment>
                                                    <div className="col">
                                                        <NumberField className="" name="amount" label="Amount*" disabled={isSubmitting} />
                                                    </div>
                                                    <div className="col">
                                                        <SelectField
                                                            className=""
                                                            name="currency"
                                                            label="Currency*"
                                                            options={["SEK", "NOK", "DKK", "EUR", "USD"]}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <SearchListField
                                                    className=""
                                                    name="instrumentId"
                                                    label="Instrument"
                                                    options={instruments}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="col">
                                                <DateField
                                                    className=""
                                                    name="tradeDate"
                                                    label="Trade date"
                                                    minDate={minDate.toDateString()}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            {values._id === "new" &&
                                            values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? null : (
                                                <div className="col">
                                                    <DateField
                                                        className=""
                                                        name="valueDate"
                                                        label="Value date"
                                                        minDate={minDate.toDateString()}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {values._id === "new" && values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? (
                                            <Fragment>
                                                <div className="form-row">
                                                    <div className="col">
                                                        <TextField
                                                            className=""
                                                            name="clientNumber"
                                                            label="From client number"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <TextField
                                                            className=""
                                                            name="clientAccount"
                                                            label="From client account"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col">
                                                        <TextField
                                                            className=""
                                                            name="toClientNumber"
                                                            label="To client number"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <TextField
                                                            className=""
                                                            name="toClientAccount"
                                                            label="To client account"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div className="form-row">
                                                <div className="col">
                                                    <TextField
                                                        className=""
                                                        name="clientNumber"
                                                        label="Client number"
                                                        type="text"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <TextField
                                                        className=""
                                                        name="clientAccount"
                                                        label="Client account"
                                                        type="text"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className="form-row">
                                            <div className="col">
                                                <SelectField
                                                    className=""
                                                    name="status"
                                                    label="Status"
                                                    options={Object.values(TransactionStatus)}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            {values._id === "new" &&
                                            values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? null : (
                                                <Fragment>
                                                    <div className="col">
                                                        <CheckBoxField
                                                            className=""
                                                            name="carryOwnTransactionCost"
                                                            label="Carry own transaction cost"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <TextField
                                                            className=""
                                                            name="externalId"
                                                            label="External id"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <MarkDownField
                                                    name="description"
                                                    label="Description"
                                                    className=""
                                                    graphqlApiBaseUrl={REACT_APP_API_URI}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <SearchListField
                                                    className=""
                                                    name="sourceId"
                                                    label="Transfer agent"
                                                    options={[data.transferAgent]}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            {values._id === "new" &&
                                            values.subType === TransferAgentOrderSubTypeEnum.ACCOUNT_SWITCH ? null : (
                                                <div className="col">
                                                    <TextField
                                                        className=""
                                                        name="referenceExternalId"
                                                        label="Reference External Id"
                                                        type="text"
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <SubmitButton disabled={isSubmitting} label={submitButtonLabel} className="btn btn-primary mb-4" />
                                    </div>
                                    {alert.visible ? (
                                        <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                            {alert.message}
                                        </Alert>
                                    ) : null}
                                </Form>
                            </Fragment>
                        );
                    }}
                </Formik>

                {!isCreateMode ? (
                    <AttachmentForm
                        key={orderAttachments.length}
                        clientId={orderClientId}
                        attachments={orderAttachments}
                        onChange={onChangeAttachment}
                    />
                ) : null}
            </div>
        </div>
    );
}
