import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import React from "react";

import { cloneDeep } from "lodash";

export const dateToYYYYMMDDString = (d) => {
    return d.toISOString().split("T")[0];
};

export function removeEmpty(obj) {
    const result = cloneDeep(obj);
    Object.keys(result).forEach(
        (key) =>
            (result[key] && typeof result[key] === "object" && removeEmpty(result[key])) ||
            ((typeof result[key] === "undefined" || result[key] === "") && delete result[key])
    );
    return result;
}

export function removeNull(inObj: any) {
    function removeNulls(obj) {
        const isArray = obj instanceof Array;
        let k: any;
        for (k in obj) {
            if (obj[k] === null) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                isArray ? obj.splice(k, 1) : delete obj[k];
            } else if (typeof obj[k] === "object") {
                removeNulls(obj[k]);
            }
            if (isArray && obj.length === k) {
                removeNulls(obj);
            }
        }
        return obj;
    }
    const obj = cloneDeep(inObj);
    return removeNulls(obj);
}

export function isValidMongoDBObjectID(i: string): boolean {
    /// https://stackoverflow.com/questions/11985228/mongodb-node-check-if-objectid-is-valid
    const id = i + "";
    const len = id.length;
    let valid = false;
    if (len === 12 || len === 24) {
        valid = /^[0-9a-fA-F]+$/.test(id);
    }
    return valid;
}

export function isValidYYYYMMDD(date) {
    const expression = RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$");
    return expression.test(date);
}

export function isValidYYYYMMDDHHMMSS(date) {
    //eslint-disable-next-line
    let expression = RegExp(
        "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
    );
    return expression.test(date);
}

export const isArrayOfObjects = (data: any): boolean => {
    if (!Array.isArray(data)) return false;
    data.forEach((element) => {
        if (typeof element !== "object") return false;
    });
    return true;
};

export const getKeysFromArrayOfObjects = (data) => {
    const fields = {};
    for (let i = 0; i !== data.length; ++i) {
        const row = data[i];
        if (row) {
            Object.keys(row).forEach((key) => {
                if ((typeof key === "string" || typeof "number") && !fields[key]) {
                    fields[key] = true;
                }
            });
        }
    }
    return Object.keys(fields);
};

export function useQueryArgs() {
    const location = useLocation();
    const navigate = useNavigate();
    return {
        queryArgs: queryString.parse(location.search),
        pushQueryArgs: (queryArgs) => {
            navigate(location.pathname + "?" + queryString.stringify(queryArgs));
        },
        replaceQueryArgs: (queryArgs) => {
            navigate(location.pathname + "?" + queryString.stringify(queryArgs), { replace: true });
        }
    };
}

export function usePrevious(value) {
    // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function addWeekDays(date: string, n: number): string {
    let newDate = new Date(new Date(date).getTime() + 86400000 * n);
    if (newDate.getDay() === 6) {
        //Saturday
        newDate = new Date(newDate.getTime() + 86400000 * 2);
    } else if (newDate.getDay() === 0) {
        //Sunday
        newDate = new Date(newDate.getTime() + 86400000 * 1);
    }
    return newDate.toJSON().slice(0, 10);
}

export function generateRandomString(length: number): string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

//https://usehooks-typescript.com/react-hook/use-interval
export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (delay === null) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [delay]);
}

export function useAlertTimeOut(
    alert: Record<string, string | boolean>,
    //setAlert: React.Dispatch<React.SetStateAction<Record<string, string | boolean>>>,
    setAlert: React.Dispatch<React.SetStateAction<any>>,
    secondsToShow: number
) {
    const [secondsToShowAlert, setSecondsToShowAlert] = useState(secondsToShow);

    const dismissAlert = cloneDeep(alert);
    dismissAlert.color = "info";
    dismissAlert.visible = false;
    dismissAlert.message = "";
    useEffect(() => {
        let interval = null;
        const isActive = alert.visible && alert.color === "success";

        if (isActive) {
            interval = setInterval(() => {
                setSecondsToShowAlert((secondsToShowAlert) => secondsToShowAlert - 1);
            }, 1000);
            if (secondsToShowAlert < 0) {
                clearInterval(interval);
                setAlert(dismissAlert);
            }
        } else if (!isActive) {
            if (secondsToShowAlert !== secondsToShow) {
                setSecondsToShowAlert(secondsToShow);
            }
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [alert, dismissAlert, secondsToShow, secondsToShowAlert, setAlert, setSecondsToShowAlert]);
}

export const parseSwedishPersonalNumber = (s: string, requireMatch: boolean): string => {
    if (!s) return null;
    const reg = /^(19|20)?([0-9]{2})([01][0-9](?:[0-2][0-9]|3[01]))-?([0-9]{4})$/gm;
    const m = reg.exec(s);
    if (!m) return requireMatch ? null : s;
    const h = m[1];
    const y = parseInt(m[2], 10);
    let year: string;
    if (typeof h === "undefined") {
        return null;
    } else {
        year = h + y;
    }
    const monthDay = m[3];
    const code = m[4];
    return year + monthDay + code;
};
