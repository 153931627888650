import React, { useEffect, useState } from "react";
import { round, sumBy, cloneDeep, keyBy } from "lodash";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { Grid, Column } from "../../../../components/src";
import { Nav, NavData } from "../../types.generated";
import { numberFormatFun } from "../../../../common/src";
import { Excel } from "../../../../components/src/Svgs";

import { exportToXlsx, sheetType } from "../../../../components/src/common/exportToXlsx";

type NavComponentProps = { nav: Nav; initShowPositions?: boolean };
export function NavComponent({ nav, initShowPositions = false }: NavComponentProps): React.ReactElement {
    const data: NavData = nav.data;

    useEffect(() => {
        if (nav && nav.client) {
            document.title = `NAV - ${nav.client.longName}`;
        }
    }, [nav]);

    const sumClasses = {
        name: "Sum",
        navBeforeAdjustmentsBeforeOrders: sumBy(
            data.classes,
            (fundClass) => Number(fundClass.navBeforeAdjustmentsBeforeOrders) * Number(fundClass.fxRate)
        ),
        navAfterAdjustmentsBeforeOrders: sumBy(
            data.classes,
            (fundClass) => Number(fundClass.navAfterAdjustmentsBeforeOrders) * Number(fundClass.fxRate)
        ),
        navAfterAdjustmentsAfterOrders: sumBy(
            data.classes,
            (fundClass) => Number(fundClass.navAfterAdjustmentsAfterOrders) * Number(fundClass.fxRate)
        ),
        managementFee: sumBy(data.classes, (fundClass) => Number(fundClass.managementFee) * Number(fundClass.fxRate))
    };
    const withSum = cloneDeep(data.classes) as Array<any>;
    withSum.push(sumClasses);

    const orders = cloneDeep(data.orders) as Array<any>;
    const transactions = cloneDeep(data.transactions) as Array<any>;
    const positions = cloneDeep(data.positions) as Array<any>;
    const adjustmentAmountPositions = cloneDeep(data.adjustmentAmountPositions) as Array<any>;
    const [showPositions, setShowPositions] = useState(initShowPositions);

    const adjustmentAmountPositionsSum = {
        name: "Sum",
        value: sumBy(adjustmentAmountPositions, (adjustmentAmountPosition) => Number(adjustmentAmountPosition.value))
    };
    adjustmentAmountPositions.push(adjustmentAmountPositionsSum);

    let formattedFrontOfficeNav = "";
    if (nav.frontOfficeNav) {
        formattedFrontOfficeNav = numberFormatFun("# ##0.##")(parseFloat(nav.frontOfficeNav));
    }

    const format3decimals = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
    });

    const navReconciliations = [
        {
            riskLevel: nav.fundRiskLevel || "-",
            absoluteSoftLimit: nav.absLowTolerancePercent ? `${parseFloat(nav.absLowTolerancePercent) * 100}%` : "-",
            absoluteHardLimit: nav.absHighTolerancePercent ? `${parseFloat(nav.absHighTolerancePercent) * 100}%` : "-",
            foBoDifference: `${format3decimals.format(
                (parseFloat(nav.frontOfficeNav) / sumClasses.navAfterAdjustmentsBeforeOrders - 1) * 100
            )}%`,
            frontOfficeNavEstimate: formattedFrontOfficeNav || "-",
            navAfterAdjustmentsBeforeOrders: numberFormatFun("# ##0.##")(round(sumClasses.navAfterAdjustmentsBeforeOrders, 2)),
            comment: nav.comment || "-"
        }
    ];

    return (
        <div className="print-zoom-65">
            <div className="row">
                <div className="col">
                    <h4>Date: {nav.date}</h4>
                </div>
                <div className="col">
                    <button
                        id="export-excel"
                        className="export-link"
                        type="button"
                        onClick={() => {
                            const sheetNames = [
                                "navReconciliations",
                                "fundClasses",
                                "orders",
                                "transactions",
                                "adjustmentAmountPositions",
                                "positions"
                            ];
                            const data: sheetType[] = [
                                navReconciliations,
                                withSum,
                                orders,
                                transactions,
                                adjustmentAmountPositions,
                                positions
                            ];
                            let fileName = "";
                            if (nav.client) {
                                const fundDashName = nav.client.longName.replace(/ /g, "-");
                                fileName = fundDashName + "_" + nav.date + ".xlsx";
                            } else {
                                fileName = nav.date + ".xlsx";
                            }
                            exportToXlsx(data, fileName, sheetNames);
                        }}
                    >
                        <Excel />
                    </button>
                </div>
            </div>

            <React.Fragment>
                <h6>Comment: </h6>
                <ReactMarkdown>{nav.comment || ""}</ReactMarkdown>
            </React.Fragment>

            <div className="">
                <h6>
                    Published to: {nav.publishedTo && nav.publishedTo.length ? Object.keys(keyBy(nav.publishedTo, "key")).join(", ") : ""}
                </h6>
            </div>

            <Grid header="NAV reconciliation" data={navReconciliations} tableClassName="table-xs" hideDownload={true}>
                <Column className="grid-column-sticky nowrap center" field="riskLevel" title="Risk level"></Column>
                <Column className="nowrap center" field="absoluteSoftLimit" title="Absolute soft limit" />
                <Column className="nowrap center" field="absoluteHardLimit" title="Absolute hard limit" />
                <Column className="nowrap center" field="foBoDifference" title="FO BO difference" />
                <Column className="nowrap center" field="frontOfficeNavEstimate" title="Front office NAV estimate (FO)"></Column>
                <Column
                    className="nowrap center"
                    field="navAfterAdjustmentsBeforeOrders"
                    title="NAV after adjustments before orders (BO)"
                ></Column>
            </Grid>

            <Grid header="Fund classes" data={withSum} sortable tableClassName="table-xs" hideDownload={true}>
                <Column
                    className="grid-column-sticky nowrap"
                    field="name"
                    format={(value, item) => {
                        return item && item.instrumentId ? <Link to={"/instruments/" + item.instrumentId}>{value}</Link> : value;
                    }}
                ></Column>
                <Column className="nowrap right" field="navPerUnit" title="NAV per unit" />
                <Column
                    className="nowrap right"
                    field="navPerUnitReturn1D"
                    title="NAV per unit return1D"
                    format={(s) => numberFormatFun("# ##0.0000%")(parseFloat(s))}
                />
                <Column
                    className="nowrap right"
                    field="managementFee"
                    title="Management fee"
                    format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                ></Column>
                <Column
                    className="nowrap right"
                    field="navBeforeAdjustmentsBeforeOrders"
                    title="NAV before adjustments before orders"
                    format={(value) => {
                        if (value) {
                            return numberFormatFun("# ##0.##")(round(value, 2));
                        }
                        return null;
                    }}
                ></Column>
                <Column
                    className="nowrap right"
                    field="navAfterAdjustmentsBeforeOrders"
                    title="NAV after adjustments before orders"
                    format={(value) => {
                        if (value) {
                            return numberFormatFun("# ##0.##")(round(value, 2));
                        }
                        return null;
                    }}
                ></Column>
                <Column
                    className="nowrap right "
                    field="navAfterAdjustmentsAfterOrders"
                    title="NAV after adjustments after orders"
                    format={(value) => {
                        if (value) {
                            return numberFormatFun("# ##0.##")(round(value, 2));
                        }
                        return null;
                    }}
                ></Column>
                <Column className="nowrap" field="navCurrency" title="Currency"></Column>
                <Column
                    className="nowrap"
                    field="weight"
                    format={(value) => {
                        if (value) {
                            return numberFormatFun("# ##0.##")(round(value, 2));
                        }
                        return null;
                    }}
                ></Column>
                <Column className="nowrap right" field="unitsAfterOrders" title="Units after orders" />
                <Column className="nowrap right" field="unitsBeforeOrders" title="Units before orders" />
                <Column className="nowrap right" field="dividendPaid" title="Dividend paid"></Column>
                <Column className="nowrap right" field="fxHedge" title="FX hedge"></Column>
                <Column className="nowrap right" field="adjustmentAmount" title="Adjustment amount"></Column>
                <Column className="nowrap right" field="fxRate" title="FX rate"></Column>
            </Grid>
            <Grid header="Orders" data={orders} sortable tableClassName="table-xs" hideDownload={true}>
                <Column
                    className="grid-column-sticky nowrap right"
                    field="orderId"
                    title="Order id"
                    format={(value, item) => {
                        return item && item.orderId ? <Link to={"/transferagentorders/" + item.orderId}>{value}</Link> : value;
                    }}
                />
                <Column
                    className="nowrap right"
                    field="carryOwnTransactionCost"
                    title="Carry own transaction cost"
                    format={(value) => {
                        return (
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" checked={value} onChange={null} readOnly></input>
                            </div>
                        );
                    }}
                />
                <Column className="nowrap right" field="currency" />
                <Column className="nowrap right" field="isin" />
                <Column className="nowrap right" field="name" />
                <Column className="nowrap right" field="transactionType" title="Transaction type" />
                <Column className="nowrap right" field="units" />
                <Column className="nowrap right" field="amount" />
                <Column className="nowrap right" field="valueDate" title="Value date" />
            </Grid>
            <Grid header="Transactions" data={transactions} sortable tableClassName="table-xs" hideDownload={true}>
                <Column className="grid-column-sticky nowrap right" field="adjustmentAmount" title="Adjustment amount" />
                <Column className="nowrap right" field="adjustmentShares" title="Adjustment shares" />
                <Column className="nowrap right" field="amount" title="Amount" format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))} />
                <Column
                    className="nowrap right"
                    field="accountingAmount"
                    title="Accounting amount"
                    format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))}
                />
                <Column className="nowrap right" field="currency" />
                <Column className="nowrap right" field="accountingCurrency" title="Accounting currency" />
                <Column className="nowrap right" field="externalId" title="External id" />
                <Column className="nowrap right" field="isin" title="ISIN" />
                <Column className="nowrap right" field="name" />
                <Column className="nowrap right" field="fxRate" title="FX rate" />
                <Column className="nowrap right" field="price" />
                <Column
                    className="nowrap right"
                    field="accountingPrice"
                    title="Accounting price"
                    format={(s) => numberFormatFun("# ##0.####")(parseFloat(s))}
                />
                <Column className="nowrap right" field="transactionType" title="Transaction type" />
                <Column
                    className="nowrap right"
                    field="accountSwitchOrder"
                    title="Account switch order"
                    format={(s) => {
                        const str = s.toString();
                        return str.charAt(0).toUpperCase() + str.slice(1);
                    }}
                />
                <Column className="nowrap right" field="units" />
                <Column className="nowrap right" field="valueDate" title="Value date" />
            </Grid>
            <Grid
                header="Adjustment amount positions"
                data={adjustmentAmountPositions}
                sortable
                tableClassName="table-xs"
                hideDownload={true}
            >
                <Column className="grid-column-sticky nowrap" field="name" title="name" />
                <Column className="nowrap right" field="quantity" format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))} />
                <Column className="nowrap right" field="value" format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))} />
                <Column className="nowrap right" field="currency" />
                <Column className="nowrap right" field="valuationDate" title="Valuation date" />
                <Column className="nowrap right" field="fxValuationDate" title="FX valuation date" />
                <Column className="nowrap right" field="fxRate" title="FX rate" />
            </Grid>
            {showPositions ? (
                <div className="mb-5">
                    <span className="glyphicon glyphicon-minus mb-5" onClick={() => setShowPositions(false)} />
                    <Grid
                        header="Positions"
                        data={positions.map((p) => {
                            // we do this so sort by value show works
                            p.value = parseFloat(p.value);
                            return p;
                        })}
                        sortable
                        className="mb-5"
                        tableClassName="table-xs"
                        hideDownload={true}
                    >
                        <Column
                            className="grid-column-sticky nowrap"
                            field="name"
                            format={(value, item) => {
                                return item && item.instrumentId ? <Link to={"/instruments/" + item.instrumentId}>{value}</Link> : value;
                            }}
                        />
                        <Column className="nowrap right" field="quantity" format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))} />
                        <Column className="nowrap right" field="value" format={(s) => numberFormatFun("# ##0.##")(parseFloat(s))} />
                        <Column className="nowrap right" field="currency" />
                        <Column className="nowrap right" field="valuationDate" title="Valuation date" />
                        <Column className="nowrap right" field="fxValuationDate" title="FX valuation date" />
                        <Column className="nowrap right" field="fxRate" title="FX rate" />
                    </Grid>
                </div>
            ) : (
                <span className="glyphicon glyphicon-plus mb-5" onClick={() => setShowPositions(true)} />
            )}
        </div>
    );
}
