import React from "react";

import { useQueryState } from "../../../components/src/use-query-state";
import { DateHelper } from "../../../common/src";

interface PeriodSelectorProps {
    firstAvailableStartDate: string;
    endDate?: string;
}

export const PeriodSelector = ({ firstAvailableStartDate, endDate }: PeriodSelectorProps): React.ReactElement => {
    const yesterday: string = DateHelper.dateToString(DateHelper.dateAddDays(new Date(), -1));

    const [_startDate, _setStartDate] = useQueryState("startDate", "2020-01-01");
    const [_endDate] = useQueryState("endDate", endDate ? endDate : yesterday);

    return (
        <div>
            <div className="row">
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        _setStartDate(DateHelper.addDays(_endDate, -7));
                    }}
                >
                    Week
                </div>
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        _setStartDate(DateHelper.addMonths(_endDate, -1));
                    }}
                >
                    Month
                </div>
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        const previousYear = DateHelper.getYear(_endDate) - 1;
                        _setStartDate(previousYear + "-12-31");
                    }}
                >
                    Ytd
                </div>
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        _setStartDate(DateHelper.addYears(_endDate, -1));
                    }}
                >
                    Year
                </div>
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        _setStartDate(DateHelper.addYears(_endDate, -5));
                    }}
                >
                    {"5years"}
                </div>
                <div
                    className="m-2 hover__pointer"
                    style={{ width: "auto", height: "auto" }}
                    onClick={() => {
                        _setStartDate(firstAvailableStartDate);
                    }}
                >
                    All
                </div>
            </div>
        </div>
    );
};
