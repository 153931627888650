import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery, gql } from "urql";
import { useQueryState } from "../../../components/src/use-query-state";

import { DateForm } from "../../../components/src/dateForm";

import { DateHelper, twoDecPriceFormat } from "../../../common/src";
import { ReactTable } from "../../../components/src/react-table/ReactTable";
import { GET_INSTRUMENT } from "./InstrumentPage";
import { serializeSwedenDate } from "../components/dateFormater";

const PORTFOLIOSWAP_CONSTITUENTS = gql`
    query portfolioSwapConstituents($portfolioInstrumentId: GraphQLObjectId!, $filterZeroPositions: Boolean, $date: GraphQLDateString) {
        portfolioSwapConstituents(portfolioInstrumentId: $portfolioInstrumentId, filterZeroPositions: $filterZeroPositions, date: $date) {
            instrumentId
            instrument {
                _id
                name
                bloombergTicker
                isin
            }
            quantity
            valuation {
                date
                value {
                    price
                    currency
                }
            }
        }
    }
`;

export function SwapConstituentsPage(): React.ReactElement {
    const { instrumentId }: any = useParams();

    const yesterday: string = DateHelper.dateToString(DateHelper.dateAddDays(new Date(), -1));
    const [date, _setDate] = useQueryState("date", yesterday);

    const [{ fetching: fetchingInstrument, data: dataInstrument, error: errorInstrument }] = useQuery({
        query: GET_INSTRUMENT,
        variables: { id: instrumentId },
        requestPolicy: "cache-and-network"
    });

    const [{ fetching, error, data }] = useQuery({
        query: PORTFOLIOSWAP_CONSTITUENTS,
        requestPolicy: "cache-and-network",
        variables: { portfolioInstrumentId: instrumentId, date: date, filterZeroPositions: false }
    });

    const columns = useMemo(() => {
        const columns = [
            {
                header: "Name",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Isin",
                accessorKey: "isin",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Bloomberg ticker",
                accessorKey: "bloombergTicker",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Date",
                accessorKey: "date"
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Price",
                accessorKey: "price",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Value (price * quantity)",
                accessorKey: "value",
                cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>
            },
            {
                header: "Currency",
                accessorKey: "currency",
                size: 25
            }
        ];
        return columns;
    }, []);

    const tableData = useMemo(() => {
        const tableData = [];
        if (data) {
            data.portfolioSwapConstituents.forEach((portfolioSwapConstituent) => {
                const row = {
                    ...portfolioSwapConstituent,
                    ...portfolioSwapConstituent.instrument,
                    date: portfolioSwapConstituent.valuation.date,
                    ...portfolioSwapConstituent.valuation.value
                };
                try {
                    row["value"] = row["price"] * row["quantity"];
                } catch (e) {
                    row["value"] = null;
                }
                tableData.push(row);
            });
        }
        return tableData;
    }, [data]);

    const presentValue = useMemo(() => {
        let presentValue = 0;
        tableData.forEach((row) => {
            if (row.value) {
                presentValue = presentValue + row.value;
            }
        });
        return presentValue;
    }, [tableData]);

    if (fetching || fetchingInstrument) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorInstrument) return <p>error: {JSON.stringify(errorInstrument, null, 2)}</p>;

    const startDate = serializeSwedenDate(new Date(new Date().setDate(new Date().getDate() - 90)));
    return (
        <div className="container">
            <div>
                <div className="row">
                    <Link to={"/instruments/" + instrumentId}>Instrument</Link>
                </div>
                <div className="row">
                    <div className="col col-sm-3 col-lg-2">
                        <DateForm defaultDateString={date} dateName={"date"}></DateForm>
                    </div>
                </div>
                <div className="row">
                    <div className="d-inline-flex flex-wrap mt-2">
                        <p>Present value: {twoDecPriceFormat(presentValue)}</p>
                        <ReactTable columns={columns} data={tableData} />
                    </div>
                </div>
            </div>

            <div className="row">
                {/* 
                <div className="col">
                    <Link
                        to={
                            "/transactionitems?includePortfolioSwapConstituents=true&performanceType=Portfolio&portfolioInstrument=" +
                            dataInstrument.instrument.name +
                            "&startDate=" +
                            startDate
                            //"&clientIds=" +
                            //dataInstrument.instrument.clientIds[0]
                        }
                    >
                        Swap TransactionItems
                    </Link>
                </div>
                */}
                <div className="col">
                    <Link
                        to={
                            "/transactionitems?includePortfolioSwapConstituents=true&performanceType=PortfolioItem&portfolioInstrument=" +
                            dataInstrument.instrument.name +
                            "&startDate=" +
                            startDate
                            //"&clientIds=" +
                            //dataInstrument.instrument.clientIds[0]
                        }
                    >
                        TransactionItems within swap
                    </Link>
                </div>
            </div>
        </div>
    );
}
