import React, { useMemo, ReactElement, Fragment } from "react";

import { AgreementFilterInput, AgreementStatusEnum, AgreementTypeEnum } from "../../../types.generated";
import { useGetAgreementsQuery } from "../../AgreementsPage.generated";
import { Link } from "react-router-dom";
import { ReactTable } from "../../../../../components/src/react-table/ReactTable";
import {
    SelectColumnFilter,
    SelectColumnFilterArrayOfIds,
    SelectColumnFilterType
} from "../../../../../components/src/react-table/ReactTableFilters";

interface PartyDocsTabPropsType {
    partyId: string;
}

export const PartyDocsTab = ({ partyId }: PartyDocsTabPropsType): ReactElement => {
    const filterParty: AgreementFilterInput = { partyIdIn: [partyId] };
    const filterCounterparty: AgreementFilterInput = { counterPartyIdIn: [partyId] };
    const [{ fetching: loading, error, data }] = useGetAgreementsQuery({ variables: { filter: filterParty } });
    const [{ fetching: loadingCounterparty, error: errorCounterParty, data: dataCounterparty }] = useGetAgreementsQuery({
        variables: { filter: filterCounterparty }
    });

    const columns = useMemo(() => {
        const partyById: Record<string, { _id: string; name: string }> = {};
        const counterPartyById: Record<string, { _id: string; name: string }> = {};
        const clientById: Record<string, { _id: string; name: string }> = {};

        let agreements = data?.agreements ? data.agreements : [];

        agreements = dataCounterparty ? agreements.concat(dataCounterparty.agreements ?? []) : agreements;

        if (agreements) {
            agreements.forEach((agreement) => {
                agreement.counterParties.forEach((party) => {
                    if (!(party._id in counterPartyById)) {
                        counterPartyById[party._id] = party;
                    }
                });
                agreement.parties.forEach((party) => {
                    if (!(party._id in partyById)) {
                        partyById[party._id] = party;
                    }
                });

                if (!(agreement.client._id in clientById)) {
                    clientById[agreement.client._id] = agreement.client;
                }
            });
        }

        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/agreements/${row.original._id}`}>#</Link>;
                },
                size: 25
            },
            {
                header: "Client (owner)",
                id: "client.name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.client) {
                        return <Link to={`/parties/${row.original.client._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilter,
                size: 150
            },
            {
                header: "Type",
                accessorKey: "type",
                filter: SelectColumnFilterType(AgreementTypeEnum)
            },
            {
                header: "Parties",
                accessorKey: "partyIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    return (
                                        <Link className="row" key={id} to={`/parties/${id}`}>
                                            {partyById[id].name}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(partyById)),
                size: 200
            },
            {
                header: "Counterparties",
                accessorKey: "counterPartyIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    return (
                                        <Link className="row" key={id} to={`/parties/${id}`}>
                                            {counterPartyById[id].name}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(counterPartyById)),
                size: 200
            },
            {
                header: "Description",
                accessorKey: "description",
                size: 200
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(AgreementStatusEnum),
                size: 75
            },
            {
                header: "Attachments",
                accessorKey: "attachments.length",
                cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
                filter: SelectColumnFilter
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                filter: SelectColumnFilter
            }
        ];
    }, [data, dataCounterparty]);

    if (loading || loadingCounterparty) return <div>Loading</div>;
    if (error) return <div>{"Error: " + error.message}</div>;
    if (errorCounterParty) return <div>{"Error counterparty: " + errorCounterParty.message}</div>;

    let allAgreements = data ? data.agreements : [];

    allAgreements = dataCounterparty ? allAgreements.concat(dataCounterparty.agreements) : allAgreements;

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto">
                    <Link to={"/agreements/new"}>New Agreement</Link>
                </div>
            </div>

            <Fragment>
                <p>Number of Agreements: {allAgreements.length}</p>
                <ReactTable columns={columns} data={allAgreements} defaultHiddenColumns={[]} />
            </Fragment>
        </div>
    );
};
