import React, { Fragment } from "react";
import { gql, useQuery } from "urql";
import { useQueryState } from "../../../components/src/use-query-state";
import { sortBy } from "lodash";

import { numberFormatFun } from "../../../common/src";
import { Grid, Column, Svgs } from "../../../components/src";
import { DateForm } from "../../../components/src/dateForm";
import { Page } from "../components/Page";
import { serializeSwedenDate } from "../components/dateFormater";
import { Link } from "react-router-dom";

const getFundUnits = gql`
    query getFundUnits($date: GraphQLDateString) {
        fundUnits(date: $date) {
            instrumentId
            instrument {
                name
                isin
            }
            totalUnits
        }
    }
`;

const DataGrid = ({ date }) => {
    const [{ fetching, error, data }] = useQuery({ query: getFundUnits, variables: { date } });
    if (fetching)
        return (
            <Page className="loader">
                <div>
                    <div>Fetching fund units</div>
                    <Svgs.Loader />
                </div>
            </Page>
        );
    if (error) return <div>`Error! ${error.message}`</div>;

    const fundUnits = sortBy(data.fundUnits, "instrument.name");

    return (
        <Fragment>
            <p>Number of fund classes: {fundUnits.length}</p>
            <Grid header="Fund Units" data={fundUnits} sortable tableClassName="table-xs">
                <Column
                    className="left"
                    field="instrumentId"
                    title="Fund class"
                    format={(_, item) => {
                        if (item.instrument && item.instrument.name) {
                            return (
                                <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                    {item.instrument.name}
                                </Link>
                            );
                        } else {
                            return "";
                        }
                    }}
                />
                <Column
                    className="left"
                    field="instrumentId"
                    title="Isin"
                    format={(_, item) => {
                        if (item.instrument && item.instrument.isin) {
                            return (
                                <Link to={"/instruments/" + item.instrumentId} target="_blank">
                                    {item.instrument.isin}
                                </Link>
                            );
                        } else {
                            return "";
                        }
                    }}
                />
                <Column className="right" field="totalUnits" title="Total units" format={numberFormatFun("# ##0.#######")} />
            </Grid>
        </Fragment>
    );
};

export const PositionFundUnitsPage = (): React.ReactElement => {
    const [date] = useQueryState("date", "");
    const defaultDateString = date ? serializeSwedenDate(new Date(date)) : serializeSwedenDate(new Date());
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 d-flex">
                    <DateForm defaultDateString={defaultDateString} dateName={"date"}></DateForm>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <DataGrid date={defaultDateString} />
                </div>
            </div>
        </Fragment>
    );
};
