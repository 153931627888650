import React, { useState, Fragment, ReactElement } from "react";
import { gql, useMutation } from "urql";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { TextField, SubmitButton } from "../../../components/src/form";
import { useAlertTimeOut } from "../../../components/src/common/Utils";
import { formikUrqlErrorFormater } from "../../../components/src/common/formik-urql-error-helper";

const CHANGE_PASSWORD = gql`
    mutation changePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            personalNumber
            name
        }
    }
`;

export const ChangePasswordPage = (): ReactElement => {
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const [_, changePassword] = useMutation(CHANGE_PASSWORD);

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    return (
        <div className="change-password-page">
            <div className="row">
                <Formik
                    initialValues={{ oldPassword: "", newPassword: "" }}
                    validationSchema={Yup.object({
                        oldPassword: Yup.string().required("Required"),
                        newPassword: Yup.string()
                            .min(6, "Must be at least 6 characters")
                            .max(32, "Must be 32 characters or less")
                            .required("Required"),
                        confirmPassword: Yup.string().test("passwords-match", "Passwords must match", function (v) {
                            return this.parent.newPassword === v;
                        })
                    })}
                    onSubmit={async (submitValues, { resetForm, setErrors }) => {
                        const input = {
                            oldPassword: submitValues.oldPassword,
                            newPassword: submitValues.newPassword
                        };
                        await changePassword({ input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    resetForm({});
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The password for user '${result.data.name}' has been changed!`
                                    });
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Fragment>
                            <div className="col-lg-4 col-md-3 col-sm-2 col-xs-0"></div>
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12">
                                <h4>Change password</h4>
                                <Form autoComplete="off">
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <TextField
                                                name="oldPassword"
                                                label="Old password*"
                                                type="password"
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <TextField
                                                name="newPassword"
                                                label="New password*"
                                                type="password"
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <TextField
                                                name="confirmPassword"
                                                label="Confirm your new password*"
                                                type="password"
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    {alert.visible ? (
                                        <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                            {alert.message}
                                        </Alert>
                                    ) : null}
                                    <SubmitButton disabled={isSubmitting} label="Change password" />
                                </Form>
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-2 col-xs-0"></div>
                        </Fragment>
                    )}
                </Formik>
            </div>
        </div>
    );
};
