import React, { Fragment, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useParams, Link, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { Button } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { Panel, IColumn, ICommandBarItemProps } from "@fluentui/react";
import { useQueryState } from "../../../components/src/use-query-state";

import { twoDecPriceFormat } from "../../../common/src";

import { YesNoModal } from "../components/YesNoModal";
import { dateFormater, serializeSwedenDate } from "../components/dateFormater";
import { MiniTransactionForm, miniTransactionTypes } from "./MiniTransactionForm";
import { CorporateActionForm } from "./CorporateActionForm";
import { MultipleSelectDetailsGrid } from "./MultipleSelectDetailsGrid";
import {
    CorporateAction,
    CorporateActionEvent,
    CorporateActionInput,
    CorporateActionStatusEnum,
    InstrumentModelTypeEnum,
    MatchCoacsTransactionsInput,
    MatchingCoacsStatus,
    MiniTransaction,
    PermissionAssetEnum,
    TransactionStatus,
    TransactionType,
    UpdateTransactionInput
} from "../types.generated";
import { TooltipLabel } from "../components/TooltipLabel";
import { GET_ME } from "../common/queries";

const GET_CORPORATE_ACTIONS = gql`
    query corporateActions($filter: CorporateActionFilterInput) {
        corporateActions(filter: $filter) {
            _id
            clientId
            client {
                _id
                name
            }
            recordDate
            exDate
            paymentDate
            type
            instrument {
                _id
                name
                isin
            }
            currency
            amount
            foreignTax
            messageType
            corporateActionEvent
            status
            text
            externalId
            error
            correspondingTransactionId
            correspondingTransaction {
                _id
                status
            }
            updateTimestamp
            reportIds
            swiftId
            name
        }
    }
`;
const GET_MINI_TRANSACTIONS = gql`
    query miniTransactions($status: [TransactionStatus], $type: [TransactionType]) {
        miniTransactions(status: $status, type: $type) {
            _id
            type
            accountId
            tradeDate
            effectiveDate
            description
            status
            error
            bestExecutionType
            brokerId
            broker {
                _id
                name
            }
            uniqueTradeId
            brokerTradeId
            source
            instrumentId
            instrument {
                _id
                name
                modelType
            }
            currency
            valueDate
            settlementAmount
            clientId
            client {
                _id
                name
            }
            externalId
            quantity
            price
            amount
            commission
            stampDuty
            foreignTax
            accruedInterest
            agreementType
            clearingHouseId
            secondInstrumentId
            secondAmount
            secondCurrency
            bonusShares
            fee
            itemType
            updateTimestamp
        }
    }
`;

const MATCH_COACS_TRANSACTIONS = gql`
    mutation MatchCoacsTransactions($input: [MatchCoacsTransactionsInput!]!) {
        matchCoacsTransactions(input: $input) {
            corporateAction {
                _id
                status
                error
                correspondingTransactionId
            }
            transaction {
                _id
                status
                error
                brokerTradeId
            }
        }
    }
`;

const UPSERT_CORPORATE_ACTIONS = gql`
    mutation upsertCorporateActions($input: [CorporateActionInput!]!) {
        upsertCorporateActions(input: $input) {
            _id
            clientId
            status
        }
    }
`;

const UPDATE_TRANSACTIONS = gql`
    mutation updateTransactions($input: [UpdateTransactionInput!]!) {
        updateTransactions(input: $input) {
            _id
        }
    }
`;

type TransactionCorporateAction = CorporateActionExtended | MiniTransactionExtended;

type CorporateActionExtended = CorporateAction & {
    clientName: string;
    instrumentName: string;
    valueDate: string;
    tradeDate: string;
};

type MiniTransactionExtended = MiniTransaction & {
    clientName: string;
    instrumentName: string;
};

const transactionCoacsGroups: string[] = ["Pending - NOT MATCHED", "Pending - MATCHED", "Preliminary", "Confirmed"];

const groupByStatus = (transactionsCoacs: (MiniTransaction | CorporateAction)[], transactionCoacsGroups: string[]) => {
    const result: Record<string, (MiniTransaction | CorporateAction)[]> = {};
    transactionCoacsGroups.forEach((status) => {
        result[status] = [];
    });

    transactionsCoacs = sortBy(transactionsCoacs, "valueDate");
    transactionsCoacs.forEach((transactionCoacs) => {
        let status = transactionCoacs.status as string;

        if (status === "Pending") {
            if ((transactionCoacs as CorporateAction).correspondingTransactionId || (transactionCoacs as MiniTransaction).brokerTradeId) {
                status = "Pending - MATCHED";
            } else {
                status = "Pending - NOT MATCHED";
            }
        }

        if (transactionCoacsGroups.includes(status)) {
            result[status].push(transactionCoacs);
        }
    });

    return result;
};

export const CorporateActionsUpdateGrid = ({ items, onUpdateStatusClick, onMatchTransactionClick }): React.ReactElement => {
    const [selected, setSelected] = useState(null);
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const columns: IColumn[] = [
        {
            key: "clientName",
            name: "Client",
            fieldName: "clientName",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 170,
            onRender: (item: TransactionCorporateAction) => {
                if (item.client && item.client._id) {
                    return <Link to={"/parties/" + item.client._id}>{item.clientName}</Link>;
                } else {
                    return null;
                }
            }
        },
        {
            key: "externalId",
            name: "Event ref/external id",
            fieldName: "externalId",

            isResizable: true,
            minWidth: 110,
            onRender: (item: TransactionCorporateAction) => {
                if (item.__typename === "MiniTransaction") {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + item._id}>{item.externalId}</Link>
                    ) : null;
                } else {
                    return <Link to={"/reconcile/corporateactions/corporateaction/" + item._id}>{item.externalId}</Link>;
                }
            }
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 70
        },

        {
            key: "type",
            name: "Type",
            fieldName: "type",
            isResizable: true,
            minWidth: 60,
            onRender: (item: TransactionCorporateAction) => {
                if (item.__typename === "MiniTransaction") {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + item._id}>{item.type}</Link>
                    ) : (
                        <span>{item.type}</span>
                    );
                } else {
                    return <Link to={"/reconcile/corporateactions/corporateaction/" + item._id}>{item.corporateActionEvent}</Link>;
                }
            }
        },
        {
            key: "instrumentName",
            name: "Instrument",
            fieldName: "instrumentName",
            isResizable: true,
            minWidth: 80,
            onRender: (item: TransactionCorporateAction) => {
                return item.instrument && item.instrument._id ? (
                    <Link to={"/instruments/" + item.instrument._id}>{item.instrumentName}</Link>
                ) : (
                    <span>{item.instrumentName}</span>
                );
            }
        },
        {
            key: "foreignTax",
            name: "Foreign tax",
            fieldName: "foreignTax",
            isResizable: true,
            minWidth: 80,
            onRender: (item: TransactionCorporateAction) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.foreignTax)}
                    </span>
                );
            }
        },
        {
            key: "amount",
            name: "Amount",
            fieldName: "amount",
            isResizable: true,
            minWidth: 80,
            onRender: (item: TransactionCorporateAction) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.amount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80
        },
        {
            key: "valueDate",
            name: "Value date",
            fieldName: "valueDate",
            minWidth: 80,
            onRender: (item: TransactionCorporateAction) => {
                if (item.valueDate < new Date().toISOString().slice(0, 10) && item.status !== "Settled") {
                    return (
                        <span
                            style={{
                                color: "white",
                                backgroundColor: "crimson"
                            }}
                        >
                            {item.valueDate}
                        </span>
                    );
                } else {
                    return <span>{item.valueDate}</span>;
                }
            }
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            isResizable: true,
            minWidth: 90
        },
        {
            key: "correspondingTransactionId",
            name: "Matched with",
            fieldName: "correspondingTransactionId",
            isResizable: true,
            minWidth: 90,
            onRender: (item: TransactionCorporateAction) => {
                if ((item as CorporateAction).correspondingTransactionId) {
                    return (
                        <Link to={"/reconcile/corporateactions/minitransaction/" + (item as CorporateAction).correspondingTransactionId}>
                            {(item as CorporateAction).correspondingTransactionId}
                        </Link>
                    );
                } else {
                    return (item as MiniTransaction).brokerTradeId ? <span>{(item as MiniTransaction).brokerTradeId}</span> : null;
                }
            }
        },
        {
            key: "updateTimestamp",
            name: "Update timestamp",
            fieldName: "updateTimestamp",
            isResizable: true,
            minWidth: 120,
            onRender: (item: TransactionCorporateAction) => {
                return <span>{dateFormater(item.updateTimestamp)}</span>;
            }
        }
    ];

    const commands: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Change selection status",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            subMenuProps: {
                items: [
                    {
                        key: "pending",
                        text: "Change to Pending",
                        iconProps: { iconName: "StatusCircleSync" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Pending");
                            }
                        }
                    },
                    {
                        key: "preliminary",
                        text: "Change to Preliminary",
                        iconProps: { iconName: "StatusCircleExclamation" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Preliminary");
                            }
                        }
                    },
                    {
                        key: "confirmed",
                        text: "Change to Confirmed",
                        iconProps: { iconName: "StatusCircleCheckmark" },
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Confirmed");
                            }
                        }
                    },
                    {
                        key: "settled",
                        text: "Change to Settled",
                        onClick: () => {
                            if (onUpdateStatusClick) {
                                onUpdateStatusClick(selected, "Settled");
                            }
                        }
                    },
                    {
                        key: "deleted",
                        text: "Change to Deleted",
                        iconProps: { iconName: "StatusCircleBlock" },
                        onClick: () => {
                            setModal({
                                showModal: true,
                                payload: { newStatus: "Deleted", ids: selected }
                            });
                        }
                    }
                ]
            }
        }
    ];

    // Match Transaction commands
    if (selected && selected.length === 1) {
        if (selected[0].correspondingTransactionId) {
            const removeMatchMenuItem = {
                key: "removeMatchMenuItem",
                text: "Remove Match",
                cacheKey: selected[0]._id,
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(selected[0], "remove");
                    }
                }
            };
            commands.push(removeMatchMenuItem);
        } else if (
            selected[0].__typename === "CorporateAction" &&
            selected[0].status === CorporateActionStatusEnum.Pending &&
            !selected[0].correspondingTransactionId
        ) {
            const noMatchManuallyOkMenuItem = {
                key: "noMatchManuallyOkMenuItem",
                text: "No match, manually checked",
                cacheKey: selected[0]._id,
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(selected[0], "No match, manually checked");
                    }
                }
            };
            commands.push(noMatchManuallyOkMenuItem);
        }
    } else if (selected && selected.length === 2) {
        if (
            (selected[0].__typename === "CorporateAction" && selected[1].__typename === "MiniTransaction") ||
            (selected[0].__typename === "MiniTransaction" && selected[1].__typename === "CorporateAction")
        ) {
            const corporateAction = selected[0].__typename === "CorporateAction" ? selected[0] : selected[1];
            const transaction = selected[0].__typename === "CorporateAction" ? selected[1] : selected[0];

            const matchTransactionCorporateAction = {
                key: "matchTransactionMenuItem",
                text: "Match",
                onClick: () => {
                    if (onMatchTransactionClick) {
                        onMatchTransactionClick(corporateAction, transaction._id);
                    }
                }
            };

            commands.push(matchTransactionCorporateAction);
        }
    }

    const onSelectedChange = (e) => {
        setSelected(e);
    };

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={
                        modal.payload.__typename === "MiniTransaction"
                            ? " Are you sure you want to delete selected transactions?"
                            : " Are you sure you want to delete selected corporate actions?"
                    }
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        if (onUpdateStatusClick) {
                            onUpdateStatusClick(modal.payload.ids, "Deleted");
                        }
                    }}
                />
            ) : null}
            <MultipleSelectDetailsGrid
                key={items}
                items={items}
                columns={columns}
                commands={selected && selected.length > 0 ? commands : []}
                onSelectedChange={onSelectedChange}
            />
        </div>
    );
};

export const CorporateActionsPage = (): React.ReactElement => {
    let relevantCorporateActions: CorporateActionExtended[] = [];
    const inputListDelete: CorporateActionInput[] = [];
    let unknownCorporateActions: CorporateActionExtended[] = [];
    let corporateActionsResulting: CorporateActionExtended[] = [];
    let transactionsCorporateActions: TransactionCorporateAction[] = [];

    const [{ fetching: loadingMe, error: errorMe, data }] = useQuery({
        query: GET_ME,
        requestPolicy: "network-only"
    });

    // All coacs in pending preliminary confirmed
    const filterAll = {
        statusIn: [
            CorporateActionStatusEnum.Pending,
            CorporateActionStatusEnum.Preliminary,
            CorporateActionStatusEnum.Confirmed,
            CorporateActionStatusEnum.Waiting
        ]
    };
    const [{ fetching: loading, error, data: allCorporateActions }, refetch] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterAll },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // messageType 564

    // corporateActionEvent: DRIP/DVOP, needs manual attention + 4:98A: <= todays date -> possibly preliminary confirmed
    const tomorrow = serializeSwedenDate(new Date().setDate(new Date().getDate() + 1));
    const today = serializeSwedenDate(new Date());
    const filterPreliminary = {
        statusIn: [
            CorporateActionStatusEnum.Pending,
            CorporateActionStatusEnum.Preliminary,
            CorporateActionStatusEnum.Confirmed,
            CorporateActionStatusEnum.Waiting
        ],
        corporateActionEventIn: [CorporateActionEvent.DRIP, CorporateActionEvent.DVOP],
        recordDateEnd: today,
        messageTypeIn: ["564"]
    };
    const [{ error: errorCorporateActionsPreliminary, data: dataCorporateActionsPreliminary }, refetchCorporateActionsPreliminary] =
        useQuery({
            query: GET_CORPORATE_ACTIONS,
            variables: { filter: filterPreliminary },
            requestPolicy: "network-only"
            //pollInterval: 15000
        });
    // corporateActionEvent: DRIP/DVOP, needs manual attention + 4:98A: > todays date -> waiting until included in recordDateEnd: today above
    const filterWait = {
        statusIn: [CorporateActionStatusEnum.Pending, CorporateActionStatusEnum.Preliminary, CorporateActionStatusEnum.Confirmed],
        corporateActionEventIn: [CorporateActionEvent.DRIP, CorporateActionEvent.DVOP],
        recordDateStart: tomorrow,
        messageTypeIn: ["564"]
    };
    const [{ error: errorCorporateActionsWait, data: dataCorporateActionsWait }, refetchCorporateActionsWait] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterWait },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });
    // corporateActionEvent: DVCA/INTR/SHPR/CAPG/CAPD + 4:98A: <= todays date -> possibly confirm
    const filterConfirm = {
        statusIn: [CorporateActionStatusEnum.Pending, CorporateActionStatusEnum.Preliminary, CorporateActionStatusEnum.Confirmed],
        corporateActionEventIn: [
            CorporateActionEvent.DVCA,
            CorporateActionEvent.INTR,
            CorporateActionEvent.SHPR,
            CorporateActionEvent.CAPG,
            CorporateActionEvent.CAPD
        ],
        recordDateEnd: today,
        messageTypeIn: ["564"]
    };
    const [{ error: errorCorporateActionsConfirm, data: dataCorporateActionsConfirm }, refetchCorporateActionsConf] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterConfirm },
        requestPolicy: "network-only"
        //pollInterval: 5000
    });
    // corporateActionEvent: DVCA/INTR/SHPR/CAPG/CAPD/DRIP/DVOP + 4:98A: >todays date
    const filterDrop = {
        corporateActionEventIn: [
            CorporateActionEvent.DVCA,
            CorporateActionEvent.INTR,
            CorporateActionEvent.SHPR,
            CorporateActionEvent.CAPG,
            CorporateActionEvent.CAPD,
            CorporateActionEvent.DRIP,
            CorporateActionEvent.DVOP
        ],
        recordDateStart: tomorrow,
        messageTypeIn: ["564"]
    };
    const [{ error: errorCorporateActionsDrop, data: dataCorporateActionsDrop }, refetchCorporateActionsDrop] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterDrop },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // messageType 566, all updates
    const filterUpdate = {
        statusIn: [
            CorporateActionStatusEnum.Pending,
            CorporateActionStatusEnum.Preliminary,
            CorporateActionStatusEnum.Confirmed,
            CorporateActionStatusEnum.Waiting
        ],
        messageTypeIn: ["566"]
    };
    const [{ error: errorGetCorporateActionsUpdate, data: dataGetCorporateActionsUpdate }, refetchCorporateActionsUpdate] = useQuery({
        query: GET_CORPORATE_ACTIONS,
        variables: { filter: filterUpdate },
        requestPolicy: "network-only"
        //pollInterval: 15000
    });

    // For messageType 566, see if we have previous corporate action of type 564 and update that
    const [stateUpdate, updateCorporateActions] = useMutation(UPSERT_CORPORATE_ACTIONS);

    if (
        dataCorporateActionsConfirm &&
        dataGetCorporateActionsUpdate &&
        dataCorporateActionsPreliminary &&
        allCorporateActions &&
        dataCorporateActionsDrop
    ) {
        relevantCorporateActions = cloneDeep(
            dataCorporateActionsPreliminary.corporateActions.concat(
                dataCorporateActionsConfirm.corporateActions,
                dataGetCorporateActionsUpdate.corporateActions
            )
        );
        const relevantCorporateActionIds: string[] = [];
        relevantCorporateActions.forEach(function (obj) {
            obj.tradeDate = obj.exDate ? obj.exDate : null;
            obj.valueDate = obj.paymentDate ? obj.paymentDate : null;
            obj.clientName = obj.client ? obj.client.name : null;
            obj.instrumentName = obj.instrument ? obj.instrument.name : null;
            relevantCorporateActionIds.push(obj._id);
        });
        const nonRelevantCorporateActionIds = [];

        dataCorporateActionsDrop.corporateActions.forEach(function (corporateAction: CorporateActionExtended) {
            //obj.tradeDate = obj.exDate ? obj.exDate : null;
            //obj.valueDate = obj.paymentDate ? obj.paymentDate : null;
            //obj.clientName = obj.client ? obj.client.name : null;
            //obj.instrumentName = obj.instrument ? obj.instrument.name : null;
            nonRelevantCorporateActionIds.push(corporateAction._id);
            const input = {
                _id: corporateAction._id,
                clientId: corporateAction.clientId,
                status: CorporateActionStatusEnum.Deleted
            };
            inputListDelete.push(input);
        });

        const knowHowToHandleCorporateActionIds = relevantCorporateActionIds.concat(nonRelevantCorporateActionIds);

        // Get the unknown pile of corporateActions, removing the ones we know we want to look at and the ones we know we do not want to look at
        const allCorporateActionsClone = cloneDeep(allCorporateActions.corporateActions);
        unknownCorporateActions = allCorporateActionsClone.filter(function (corporateAction: CorporateActionExtended) {
            corporateAction.tradeDate = corporateAction.exDate ? corporateAction.exDate : null;
            corporateAction.valueDate = corporateAction.paymentDate ? corporateAction.paymentDate : null;
            corporateAction.clientName = corporateAction.client ? corporateAction.client.name : null;
            corporateAction.instrumentName = corporateAction.instrument ? corporateAction.instrument.name : null;
            return !knowHowToHandleCorporateActionIds.includes(corporateAction._id);
        });

        corporateActionsResulting = relevantCorporateActions.concat(unknownCorporateActions);
    }

    const [{ fetching: loadingMiniTransactions, error: errorMiniTransactions, data: dataMiniTransactions }, refetchMiniTransactions] =
        useQuery({
            query: GET_MINI_TRANSACTIONS,
            variables: {
                status: [TransactionStatus.Pending, TransactionStatus.Preliminary, TransactionStatus.Confirmed],
                type: [TransactionType.Dividend, TransactionType.Interest]
            },
            requestPolicy: "network-only"
            //pollInterval: 15000
        });

    const [stateMatch, matchTransactions] = useMutation(MATCH_COACS_TRANSACTIONS);
    const [stateUpdateCorporateActions, updateCorporateActionsStatus] = useMutation(UPSERT_CORPORATE_ACTIONS);
    const [stateUpdateTransaction, updateTransactionStatus] = useMutation(UPDATE_TRANSACTIONS);

    if (loading) return <p>Loading corporate actions</p>;
    if (loadingMiniTransactions) return <p>Loading transactions</p>;
    if (stateUpdate.fetching || stateMatch.fetching || loadingMe) return <p>Loading...</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorMiniTransactions) return <p>error: {JSON.stringify(errorMiniTransactions, null, 2)}</p>;
    if (errorCorporateActionsPreliminary) return <p>error: {JSON.stringify(errorCorporateActionsPreliminary, null, 2)}</p>;
    if (errorCorporateActionsWait) return <p>error: {JSON.stringify(errorCorporateActionsWait, null, 2)}</p>;
    if (errorCorporateActionsConfirm) return <p>error: {JSON.stringify(errorCorporateActionsConfirm, null, 2)}</p>;
    if (errorCorporateActionsDrop) return <p>error: {JSON.stringify(errorCorporateActionsDrop, null, 2)}</p>;
    if (errorGetCorporateActionsUpdate) return <p>error: {JSON.stringify(errorGetCorporateActionsUpdate, null, 2)}</p>;
    if (stateUpdate.error) return <p>error: {JSON.stringify(stateUpdate.error, null, 2)}</p>;
    if (stateUpdateCorporateActions.error) return <p>error: {JSON.stringify(stateUpdateCorporateActions.error, null, 2)}</p>;
    if (stateUpdateTransaction.error) return <p>error: {JSON.stringify(stateUpdateTransaction.error, null, 2)}</p>;
    if (stateMatch.error) return <p>error: {JSON.stringify(errorMiniTransactions, null, 2)}</p>;
    if (errorMe)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorMe, null, 2)}</pre>
            </div>
        );

    // Filter transactions to show in pending list, keep relevant dividends and interests
    let extendedMiniTransactions: MiniTransactionExtended[];
    if (dataMiniTransactions.miniTransactions && corporateActionsResulting) {
        const miniTransactionsClone = cloneDeep(dataMiniTransactions.miniTransactions) as MiniTransactionExtended[];
        extendedMiniTransactions = miniTransactionsClone.filter(function (transaction) {
            //return (!t.error || t.error === "No matching corporate action transaction found.") && t.instrument.type === "Bond";
            transaction.clientName = transaction.client ? transaction.client.name : null;
            transaction.instrumentName = transaction.instrument ? transaction.instrument.name : null;
            return (
                transaction.instrument.modelType === InstrumentModelTypeEnum.Bond ||
                transaction.instrument.modelType === InstrumentModelTypeEnum.Stock
            );
        });

        // Filter transactions to show (not show both corporate action and transaction if matched)
        const filteredMiniTransactions = extendedMiniTransactions.filter(function (transaction) {
            return (
                (!transaction.error && transaction.status === TransactionStatus.Pending) ||
                (transaction.error === "No matching corporate action transaction found." &&
                    transaction.status === TransactionStatus.Pending)
            );
        });
        transactionsCorporateActions = [...corporateActionsResulting, ...filteredMiniTransactions];
    }

    // Update status for DRIP/DVOP with ex-date further ahead to waiting
    if (dataCorporateActionsWait && dataCorporateActionsWait.corporateActions && dataCorporateActionsWait.corporateActions.length) {
        const corporateActionsWaitingInput: CorporateActionInput[] = [];
        for (let s = 0; s < dataCorporateActionsWait.length; s++) {
            const corporateAction = cloneDeep(dataCorporateActionsWait[s]);
            corporateActionsWaitingInput.push({
                _id: corporateAction._id,
                status: CorporateActionStatusEnum.Waiting,
                clientId: corporateAction.clientId
            });
        }
        if (corporateActionsWaitingInput.length) {
            updateCorporateActionsStatus({
                input: corporateActionsWaitingInput
            })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchCorporateActionsPreliminary();
                        refetchCorporateActionsWait();
                        refetchCorporateActionsConf();
                        refetchCorporateActionsDrop();
                        refetchCorporateActionsUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const groups = groupByStatus(transactionsCorporateActions, transactionCoacsGroups);

    const updateStatus = async (items: TransactionCorporateAction[], changeTo: string) => {
        const updateTransactionsInput: UpdateTransactionInput[] = [];
        const updateCorporateActionsInput: CorporateActionInput[] = [];
        for (const item of items) {
            // Different mutations for corporateActions/transactions
            if (item.__typename === "CorporateAction") {
                updateCorporateActionsInput.push({ _id: item._id, status: changeTo as CorporateActionStatusEnum, clientId: item.clientId });
            } else {
                updateTransactionsInput.push({ _id: item._id, status: changeTo as TransactionStatus });
            }
        }
        if (updateTransactionsInput.length) {
            updateTransactionStatus({
                input: updateTransactionsInput
            }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetchMiniTransactions();
                }
            });
        }
        if (updateCorporateActionsInput.length) {
            updateCorporateActionsStatus({
                input: updateCorporateActionsInput
            })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchCorporateActionsPreliminary();
                        refetchCorporateActionsWait();
                        refetchCorporateActionsConf();
                        refetchCorporateActionsDrop();
                        refetchCorporateActionsUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const doMatchTransactions = (item: CorporateActionExtended, changeTo: string) => {
        if (changeTo === "remove") {
            const matchTransaction: MatchCoacsTransactionsInput[] = [
                {
                    corporateActionId: item._id,
                    corporateActionError: null,
                    transactionId: item.correspondingTransactionId,
                    transactionError: null,
                    matchingStatus: MatchingCoacsStatus.Mismatch
                }
            ];
            matchTransactions({ input: matchTransaction })
                .then((result) => {
                    if (result.error) {
                        console.error(result.error.toString());
                    } else {
                        refetch();
                        refetchMiniTransactions();
                        refetchCorporateActionsPreliminary();
                        refetchCorporateActionsWait();
                        refetchCorporateActionsConf();
                        refetchCorporateActionsDrop();
                        refetchCorporateActionsUpdate();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            console.log("matching coacs and transactions", matchTransaction);
        } else if (changeTo !== "No match, manually checked") {
            const matchTransaction: MatchCoacsTransactionsInput[] = [
                {
                    corporateActionId: item._id,
                    corporateActionError: "Manually matched",
                    transactionId: changeTo,
                    transactionError: "Manually matched",
                    matchingStatus: MatchingCoacsStatus.Confirmed
                }
            ];

            console.log(matchTransaction);
            matchTransactions({ input: matchTransaction }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                    refetchMiniTransactions();
                    refetchCorporateActionsPreliminary();
                    refetchCorporateActionsWait();
                    refetchCorporateActionsConf();
                    refetchCorporateActionsDrop();
                    refetchCorporateActionsUpdate();
                }
            });
            console.log("matching coacs and transactions", matchTransaction);
        } else {
            const updateCorporateActionInput: CorporateActionInput = {
                _id: item._id,
                clientId: item.clientId,
                status: CorporateActionStatusEnum.Settled,
                correspondingTransactionId: null,
                error: changeTo
            };

            console.log(updateCorporateActionInput);
            updateCorporateActions({ input: [updateCorporateActionInput] }).then((result) => {
                if (result.error) {
                    console.error(result.error.toString());
                } else {
                    refetch();
                    refetchCorporateActionsPreliminary();
                    refetchCorporateActionsWait();
                    refetchCorporateActionsConf();
                    refetchCorporateActionsDrop();
                    refetchCorporateActionsUpdate();
                }
            });
            console.log("updating corporate action", updateCorporateActionInput);
        }
    };

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <Button
                        variant="info"
                        type="submit"
                        style={{ marginLeft: "2px" }}
                        onClick={() => {
                            const win = window.open("/corporateactions/", "_blank");
                            win.focus();
                        }}
                    >
                        <TooltipLabel
                            text={"All corporate actions"}
                            content={"Page showing all coacs from a certain start date. All statuses are shown."}
                        />
                    </Button>
                </div>
                <div className="col-8 right">
                    {data &&
                    data.me &&
                    data.me.frontendRole &&
                    data.me.frontendRole.assets &&
                    (data.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) || data.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual/coac_management.md"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                    ) : null}
                </div>
            </div>
            <br />
            <Fragment>
                {transactionCoacsGroups.map((status) => (
                    <div key={status} className="mt-4">
                        <h2>{status}</h2>
                        <CorporateActionsUpdateGrid
                            items={groups[status]}
                            onMatchTransactionClick={doMatchTransactions}
                            onUpdateStatusClick={updateStatus}
                        />
                    </div>
                ))}
            </Fragment>
        </div>
    );
};

export function CorporateActionsReconciliationPage(): React.ReactElement {
    const params: any = useParams();
    const { id, type } = params;

    // Needed for corporate action form, possible transactions to match with
    const [exDateStart] = useQueryState("exDateStart", serializeSwedenDate(new Date(new Date().getFullYear(), 0, 1)));
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="row">
                <div className={"col-12"}>
                    <CorporateActionsPage />
                </div>
            </div>
            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => navigate(`/reconcile/corporateactions`)}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id && type === "minitransaction" ? <MiniTransactionForm id={id} type={null} /> : null}
                {id && type === "corporateaction" ? <CorporateActionForm id={id} tradeDate={exDateStart} /> : null}
            </Panel>
        </Fragment>
    );
}
