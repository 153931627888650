import React, { useEffect, useState, useMemo } from "react";
import { useQuery, gql, useMutation } from "urql";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Formik, Form } from "formik";
import { cloneDeep } from "lodash";

import { SearchListField, SelectField, SubmitButton, TextField } from "../../../components/src/form";
import { formikUrqlErrorFormater } from "../../../components/src/common/formik-urql-error-helper";

import { MarkDownField } from "../components/form/MarkDownField";
import { useAlertTimeOut, useQueryArgs } from "../../../components/src/common/Utils";
import { ExtensionInput, DocumentStatusEnum, CollectionNameEnum, Extension } from "../types.generated";
import { SYSTEM_PARTY_ID } from "../Constants";
import { REACT_APP_API_URI } from "../env";

const QUERY_EXTENSIONS = gql`
    query extensions($filter: ExtensionsFilterInput) {
        extensions(filter: $filter) {
            _id
            clientId
            collection
            documentId
            status
            key
            value
            comment
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            versions
        }
    }
`;
export const QUERY_DATA = gql`
    query getData {
        instruments {
            _id
            name
        }

        parties {
            _id
            name
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const UPSERT_EXTENSIONS = gql`
    mutation upsertExtensions($input: [ExtensionInput!]!) {
        upsertExtensions(input: $input) {
            _id
            clientId
            collection
            documentId
            status
            key
            value
            comment
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
            versions
        }
    }
`;

enum SupportedCollectionNameEnum {
    Instrument = "Instrument",
    None = "None",
    Party = "Party"
}

const defaultExtensionDefinition: Partial<Extension> = {
    _id: "new",
    clientId: SYSTEM_PARTY_ID,
    collection: SupportedCollectionNameEnum.Instrument as unknown as CollectionNameEnum,
    documentId: "000000000000000000000000",
    status: DocumentStatusEnum.Active,
    key: "",
    value: "",
    comment: ""
};

export const ExtensionPage = (): React.ReactElement => {
    const [extension, setExtension] = useState(defaultExtensionDefinition);
    const { id } = useParams<"id">();
    const extensionDefinitionId = id && id !== "new" ? id : "000000000000000000000000";
    const isEditMode = !!(extensionDefinitionId !== "000000000000000000000000");
    const { queryArgs } = useQueryArgs();

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    const [{ fetching: fetchingData, error: errorData, data: dataData }] = useQuery({ query: QUERY_DATA });
    const [{ fetching, error, data }] = useQuery({
        query: QUERY_EXTENSIONS,
        variables: { filter: { idIn: [extensionDefinitionId] } },
        pause: !isEditMode
    });

    const [_, upsertExtension] = useMutation(UPSERT_EXTENSIONS);

    const navigate = useNavigate();

    useAlertTimeOut(alert, setAlert, 5);
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useEffect(() => {
        if (id === "new" && queryArgs.instrumentId && extension.documentId === "000000000000000000000000") {
            const editExtension = cloneDeep(extension);
            editExtension.documentId = queryArgs.instrumentId as string;
            editExtension.collection = CollectionNameEnum.Instrument;
            setExtension(editExtension);
        }

        if (id === "new" && queryArgs.instrumentId && extension.documentId === queryArgs.instrumentId) {
            //remove queryArgs.instrumentId from the url
            navigate(location.pathname, { replace: true });
        }
    }, [extension, id, navigate, queryArgs.instrumentId]);

    useEffect(() => {
        if (isEditMode && data) {
            const extension = {
                _id: data.extensions[0]._id,
                clientId: data.extensions[0].clientId,
                collection: data.extensions[0].collection,
                documentId: data.extensions[0].documentId,
                status: data.extensions[0].status,
                key: data.extensions[0].key,
                value: data.extensions[0].value,
                comment: data.extensions[0].comment,
                createTimestamp: data.extensions[0].createTimestamp,
                updateTimestamp: data.extensions[0].updateTimestamp,
                updateUserInfo: data.extensions[0].updateUserInfo
            };
            setExtension(extension);
        }
    }, [data, isEditMode]);

    const documentOptions = useMemo(() => {
        let options = [{ _id: "000000000000000000000000", name: "None " }];
        if (dataData) {
            if (extension.collection === CollectionNameEnum.Instrument) {
                options = [{ _id: "000000000000000000000000", name: "None " }, ...dataData.instruments];
            }

            if (extension.collection === CollectionNameEnum.Party) {
                options = [{ _id: "000000000000000000000000", name: "None " }, ...dataData.parties];
            }
        }
        return options;
    }, [dataData, extension]);

    if (fetching || fetchingData) return <p>Loading</p>;
    if (errorData) return <p>error: {JSON.stringify(errorData, null, 2)}</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div className="container page">
            <Link to={"/extensions"}>Extensions</Link>

            <h1>Extension</h1>

            <Formik
                initialValues={extension}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    const input: ExtensionInput = {
                        clientId: submitValues.clientId,
                        collection: submitValues.collection as any,
                        documentId: submitValues.documentId,
                        status: submitValues.status,
                        key: submitValues.key,
                        value: submitValues.value,
                        comment: submitValues.comment
                    };

                    if (isEditMode) {
                        input._id = submitValues._id;
                    }

                    await upsertExtension({ input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: "The extension upserted successfully!"
                                });

                                if (result.data && result.data.upsertExtensions) {
                                    navigate("/extensions/" + result.data.upsertExtensions[0]._id, { replace: true });
                                }
                            }
                            return true;
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField name="_id" label={"_id"} type="text" className="" spellCheck="false" disabled={true} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <SelectField
                                    name="clientId"
                                    label={<Link to={"/parties/" + values.clientId}>Client (owner)</Link>}
                                    options={dataData.clients.map((d) => ({ key: d._id, value: d.name }))}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <SelectField
                                    name="collection"
                                    label="collection"
                                    options={Object.keys(SupportedCollectionNameEnum).sort()}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <SearchListField
                                    name="documentId"
                                    label={
                                        values.collection === CollectionNameEnum.Instrument ? (
                                            <div className="pb-2">
                                                <Link to={"/instruments/" + values.documentId}>{CollectionNameEnum.Instrument}</Link>
                                            </div>
                                        ) : values.collection === CollectionNameEnum.Party ? (
                                            <div className="pb-2">
                                                <Link to={"/parties/" + values.documentId}>{CollectionNameEnum.Party}</Link>
                                            </div>
                                        ) : (
                                            <div>{CollectionNameEnum.Instrument}</div>
                                        )
                                    }
                                    options={documentOptions}
                                    className=""
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="col-12 col-xl-12 mt-3">
                                <h5>comment</h5>
                                <MarkDownField name="comment" graphqlApiBaseUrl={REACT_APP_API_URI} label="Click to edit" height={200} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField name="key" label={"key"} type="text" className="" spellCheck="false" disabled={isSubmitting} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField
                                    name="value"
                                    label={"value"}
                                    type="text"
                                    className=""
                                    spellCheck="false"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField name="createTimestamp" label="Create timestamp" type="text" className="" disabled={true} />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField name="updateTimestamp" label="Update timestamp" type="text" className="" disabled={true} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <TextField name="updateUserInfo.name" label="Update user info" type="text" className="" disabled={true} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col">
                                {alert.visible ? (
                                    <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                        {alert.message}
                                    </Alert>
                                ) : null}

                                <SubmitButton disabled={isSubmitting} label={isEditMode ? "Update" : "Create"} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
