import React, { useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender,
    SortingState,
    ColumnFiltersState,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel
} from "@tanstack/react-table";
import { Dropdown, IDropdownStyles } from "@fluentui/react";
import { cloneDeep } from "lodash";

import { Svgs } from "../../src";
import { exportToXlsx, sheetType } from "../../src/common/exportToXlsx";
import { useQueryArgs } from "../../src/common/Utils";
import { DefaultColumnFilter, Filter, includesValueFormat, notEquals, numberEqualOrGreaterThan, startsWith } from "./ReactTableFilters";

// https://tanstack.com/table/v8

/**
 * react-table wrapper
 *
 *             <ReactTable
 *              columns={columns}
 *              data={transactions}
 *              defaultHiddenColumns={["_id", "secondCurrency", "secondSettlementAmount", "secondInstrumentName"]}
 *             />
 *
 *  - 'defaultHiddenColumns' is optional. default value is 'null' - hidden columns select is not visible
 *  - use 'defaultHiddenColumns={[]}' when hidden columns select is needed and no default hidden columns
 */

export interface Props {
    columns: any[];
    data: any;
    defaultHiddenColumns?: any;
    initialPageSize?: number;
    exportToXlsxFile?: string;
}

const defaultColumn: any = { filter: DefaultColumnFilter, size: 50, minSize: 1, maxSize: 500 };

export const ReactTable = ({
    columns,
    data: dataIn,
    defaultHiddenColumns = null,
    initialPageSize = 60,
    exportToXlsxFile = null
}: Props): React.ReactElement => {
    // Issue with filter if first row has any value that is null, see https://github.com/TanStack/table/issues/4919
    const data = React.useMemo(() => {
        const dataNoNullFirstRow = cloneDeep(dataIn);
        if (dataNoNullFirstRow && dataNoNullFirstRow.length) {
            Object.keys(dataNoNullFirstRow[0]).forEach((key) => {
                if (dataNoNullFirstRow[0][key] === null) {
                    dataNoNullFirstRow[0][key] = "";
                }
            });
        }

        return dataNoNullFirstRow;
    }, [dataIn]);

    const { queryArgs } = useQueryArgs();
    const initialHiddenColumns = defaultHiddenColumns ? defaultHiddenColumns.filter((c) => !queryArgs[c]) : []; // columns in queryArgs should be visible
    const [hiddenColumns, setHiddenColumnsState] = useState(initialHiddenColumns);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const columnVisibility = Object.fromEntries(columns.map((x) => [x.accessorKey, !hiddenColumns.includes(x.accessorKey)]));

    const table = useReactTable({
        data,
        columns,
        defaultColumn,
        state: {
            sorting,
            columnFilters,
            columnVisibility
        },
        initialState: { pagination: { pageIndex: 0, pageSize: initialPageSize } },
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        filterFns: {
            notEquals,
            includesValueFormat,
            numberEqualOrGreaterThan,
            startsWith
        }
        //debugAll: true
        //debugTable: true,
        //debugHeaders: true,
        //debugColumns: true
    });

    const handleChange = (e, option) => {
        if (option) {
            const hideColumns = [...hiddenColumns];
            if (option.selected) {
                if (!hideColumns.includes(option.key)) {
                    hideColumns.push(option.key);
                }
            } else {
                const index = hideColumns.indexOf(option.key);
                if (index > -1) {
                    hideColumns.splice(index, 1);
                }
            }
            setHiddenColumnsState(hideColumns);
        }
    };

    const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

    const hiddenColumnsOptions = columns.map((c) => {
        // at least one column must be visible
        return {
            key: c.accessorKey,
            text: c.header,
            disabled: hiddenColumns && !hiddenColumns.includes(c.accessorKey) && hiddenColumns.length > columns.length - 2
        };
    });

    return (
        <div className="captor-table">
            {exportToXlsxFile ? (
                <div
                    title="Export to XLSX"
                    style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                    onClick={() => {
                        const currentRecords: sheetType = table.getFilteredRowModel().rows.map((row) => {
                            const item: any = row.original;
                            delete item.__typename;
                            return item;
                        });
                        exportToXlsx([currentRecords], exportToXlsxFile);
                    }}
                >
                    <Svgs.Excel />
                </div>
            ) : null}

            {defaultHiddenColumns !== null ? (
                <Dropdown
                    key="hiddenColumnsDropdown"
                    className="captor-table-select"
                    placeholder="hidden columns"
                    defaultSelectedKeys={hiddenColumns.map((cid) => {
                        const column = columns.find((x) => x.accessorKey === cid);
                        return column.accessorKey;
                    })}
                    multiSelect
                    options={hiddenColumnsOptions}
                    calloutProps={{ doNotLayer: false, calloutMaxHeight: 300 }}
                    onChange={handleChange}
                    styles={dropdownStyles}
                />
            ) : null}

            <table className="table table-striped table-xs" role="table">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="tr" role="row">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={header.getSize() > 0 ? { width: header.getSize() } : undefined}
                                        className="th"
                                        role="columnheader"
                                    >
                                        {header.column.getCanResize() && (
                                            <div
                                                onMouseDown={header.getResizeHandler()}
                                                className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                                            ></div>
                                        )}

                                        {header.isPlaceholder ? null : (
                                            <div
                                                className="header-content"
                                                title="Toggle SortBy"
                                                {...{
                                                    className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                    onClick: header.column.getToggleSortingHandler()
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: " ▼",
                                                    desc: " ▲"
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}

                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={`f${headerGroup.id}`} className="tr" role="row">
                            {headerGroup.headers.map((header: any) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    style={{ width: header.getSize() }}
                                    className="th"
                                    role="columnheader"
                                >
                                    {!header.isPlaceholder && header.column.getCanFilter() ? (
                                        <div>
                                            <Filter column={header.column} table={table} />
                                        </div>
                                    ) : null}

                                    {header.column.getCanResize() && (
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                                        ></div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody role="rowgroup">
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="tr" role="row">
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="td" role="cell" style={{ width: cell.column.getSize() }}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <div className="col-xs-4">
                    <button className="button" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} title="First">
                        &laquo;
                    </button>{" "}
                    <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} title="Previous">
                        &lt;
                    </button>{" "}
                </div>

                <div className="col-xs-4 ms-2">
                    <span>
                        Page:{" "}
                        <input
                            type="number"
                            min={1}
                            max={table.getPageCount()}
                            value={table.getState().pagination.pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                table.setPageIndex(page);
                            }}
                            style={{ maxWidth: "70px" }}
                            className="h-100"
                        />{" "}
                        of {table.getPageCount()}
                    </span>
                </div>

                <div className="col-xs-4 ms-2">
                    <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} title="Next">
                        &gt;
                    </button>{" "}
                    <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} title="Last">
                        &raquo;
                    </button>
                </div>

                <div className="col-xs-0 d-none d-sm-block ms-5">
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                        className="h-100"
                    >
                        {[20, 40, 60, 80, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};
