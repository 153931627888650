import React, { useContext } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";

import { loginContext } from "./Login";
import { useQueryState } from "../../../components/src/use-query-state";

export const TokenPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const [redirectUri] = useQueryState("redirect_uri", "none");
    const { login } = useContext(loginContext);

    if (redirectUri && redirectUri !== "none") {
        const url = redirectUri;
        const hostname = new URL(url).hostname;
        const queryArg = queryString.stringify({ api_key: login.token });
        if (hostname === "localhost") {
            if (typeof window !== "undefined") {
                return (
                    <div>
                        <div> Do you want to authorize localhost to act as you </div>
                        <button
                            className="btn btn-warning"
                            type="button"
                            onClick={() => {
                                window.open(redirectUri + "?" + queryArg);
                                navigate("/", { replace: true });
                            }}
                        >
                            <div>Yes</div>
                        </button>
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={() => {
                                navigate("/", { replace: true });
                            }}
                        >
                            <div>No</div>
                        </button>
                    </div>
                );
            }
        }
    }
    return <div></div>;
};
