import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { Alert } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import stableStringify from "json-stable-stringify";

import { SubmitButton, SelectField, NumberField, SearchListField } from "../../../../components/src/form";
import { AccountingBatchType, AccountingTransactionType, UpdateJournalEntryInput } from "../../types.generated";
import { emptyObjectId } from "../../../../common/src";
import { useAlertTimeOut, usePrevious } from "../../../../components/src/common/Utils";

const GET_DATA = gql`
    query getData($_id: GraphQLObjectId!) {
        client: party(_id: $_id) {
            name
            _id
            instruments {
                _id
                name
                longName
                externalAccountId
                currency
                productType
            }
        }
        instruments(filter: { typeIn: [Instrument] }) {
            _id
            isin
            name
            longName
            bloombergTicker
            currency
        }
    }
`;

const GET_JOURNAL_ENTRY = gql`
    query getJournalEntry($_id: GraphQLObjectId!) {
        journalEntry(_id: $_id) {
            _id
            clientId
            accountingRunId
            batch
            effectiveDate
            number
            transactions {
                amount
                instrumentId
                tAccountNumber
                type
            }
        }
    }
`;

const CREATE_JOURNAL_ENTRY = gql`
    mutation CreateJournalEntry($input: CreateJournalEntryInput!) {
        createJournalEntry(input: $input) {
            _id
            clientId
            accountingRunId
            batch
            description
            effectiveDate
            number
            transactions {
                amount
                instrumentId
                tAccountNumber
                type
            }
        }
    }
`;

const UPDATE_JOURNAL_ENTRY = gql`
    mutation UpdateJournalEntry($input: UpdateJournalEntryInput!) {
        updateJournalEntry(input: $input) {
            _id
            clientId
            accountingRunId
            batch
            description
            effectiveDate
            number
            transactions {
                amount
                instrumentId
                tAccountNumber
                type
            }
        }
    }
`;

interface InputManualJournalEntryType {
    journalEntryId: string;
    clientId: string;
    accountingRunId: string;
    effectiveDate: string;
    manualJournalEntryNumber: number;
    tAccountNumbers: { key: string; value: string }[];
    onUpdated: () => void;
}

export function ManualJournalEntryForm(props: InputManualJournalEntryType): React.ReactElement {
    const previousProps: InputManualJournalEntryType = usePrevious(props);

    const id = props.journalEntryId ? props.journalEntryId : emptyObjectId;

    const navigate = useNavigate();
    const location = useLocation();

    const [{ fetching: loading, error: getDataError, data }] = useQuery({
        query: GET_DATA,
        variables: { _id: props.clientId },
        requestPolicy: "network-only"
    });

    const [{ fetching: loadingJournalEntry, error: getJournalEntryError, data: getJournalEntryData }] = useQuery({
        query: GET_JOURNAL_ENTRY,
        variables: { _id: id },
        requestPolicy: "network-only",
        pause: !id || id === emptyObjectId
    });

    const [values, setValues] = useState(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const [_, executeCreateJournalEntry] = useMutation(CREATE_JOURNAL_ENTRY);
    const [__, executeUpdateJournalEntry] = useMutation(UPDATE_JOURNAL_ENTRY);

    useEffect(() => {
        if (previousProps && stableStringify(props.journalEntryId) !== stableStringify(previousProps.journalEntryId)) {
            setValues(null);
        } else if (
            (values === null || (values && values._id !== id)) &&
            id !== emptyObjectId &&
            !getJournalEntryError &&
            !loadingJournalEntry &&
            getJournalEntryData
        ) {
            const manualJournalEntry = {
                _id: getJournalEntryData.journalEntry._id,
                amount: getJournalEntryData.journalEntry.transactions[0].amount,
                instrumentId: getJournalEntryData.journalEntry.transactions[0].instrumentId,
                type: getJournalEntryData.journalEntry.transactions[0].type,
                tAccountNumber: getJournalEntryData.journalEntry.transactions[0].tAccountNumber,
                secondInstrumentId: getJournalEntryData.journalEntry.transactions[1].instrumentId,
                secondTAccountNumber: getJournalEntryData.journalEntry.transactions[1].tAccountNumber,
                secondType: getJournalEntryData.journalEntry.transactions[1].type
            };

            setValues(manualJournalEntry);
        } else if (values === null) {
            const resetValues = {
                tAccountNumber: props.tAccountNumbers ? props.tAccountNumbers[0].key : "",
                type: Object.values(AccountingTransactionType)[0],
                instrumentId: "",
                amount: 0,
                secondTAccountNumber: props.tAccountNumbers ? props.tAccountNumbers[0].key : "",
                secondType: Object.values(AccountingTransactionType)[0],
                secondInstrumentId: ""
            };
            setValues(resetValues);
        }
    }, [
        getJournalEntryError,
        id,
        loading,
        loadingJournalEntry,
        previousProps,
        props.journalEntryId,
        props.tAccountNumbers,
        values,
        getJournalEntryData
    ]);

    if (loading || loadingJournalEntry) return <div>Loading</div>;

    if (getDataError) return <div>{"Error data: " + JSON.stringify(getDataError, null, 2)}</div>;

    if (getJournalEntryError) return <div>{"Error journal entry: " + JSON.stringify(getJournalEntryError, null, 2)}</div>;

    if (values === null) return <div></div>;

    const { client, instruments } = cloneDeep(data);
    const allInstruments = cloneDeep(instruments).concat(cloneDeep(client.instruments));

    return (
        <div id="manualjournalentryform" className="manualjournalentryform form">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={values}
                validate={(validateValues) => {
                    const errors = {};

                    const requiredFields = [
                        "amount",
                        "instrumentId",
                        "tAccountNumber",
                        "type",
                        "secondInstrumentId",
                        "secondTAccountNumber",
                        "secondType"
                    ];

                    requiredFields.forEach((d) => {
                        if (!validateValues[d]) {
                            errors[d] = "Required";
                        }
                    });
                    //console.log("validateValues", validateValues);
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setSubmitting }) => {
                    //console.log("submitValues: ", submitValues);
                    const input = { ...submitValues };

                    const je: UpdateJournalEntryInput = {
                        _id: id,
                        portfolioTransactionId: emptyObjectId,
                        clientId: props.clientId,
                        accountingRunId: props.accountingRunId,
                        batch: AccountingBatchType.M,
                        number: props.manualJournalEntryNumber,
                        effectiveDate: props.effectiveDate,
                        transactions: [
                            {
                                amount: input.amount,
                                type: input.type,
                                instrumentId: input.instrumentId,
                                tAccountNumber: input.tAccountNumber
                            },
                            {
                                amount: -input.amount,
                                type: input.secondType,
                                instrumentId: input.secondInstrumentId,
                                tAccountNumber: input.secondTAccountNumber
                            }
                        ]
                    };

                    if (id === emptyObjectId) {
                        let mutationData: any;
                        delete je._id;
                        executeCreateJournalEntry({ input: je })
                            .then((result) => {
                                mutationData = result.data.createJournalEntry;
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: `The journal entry '${mutationData._id}' was created successfully!`
                                });
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                if (mutationData && mutationData._id) {
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(mutationData._id);
                                    navigate(path.join("/"), { replace: true });
                                    const formData = {
                                        _id: mutationData._id,
                                        amount: mutationData.transactions[0].amount,
                                        instrumentId: mutationData.transactions[0].instrumentId,
                                        type: mutationData.transactions[0].type,
                                        tAccountNumber: mutationData.transactions[0].tAccountNumber,
                                        secondInstrumentId: mutationData.transactions[1].instrumentId,
                                        secondTAccountNumber: mutationData.transactions[1].tAccountNumber,
                                        secondType: mutationData.transactions[1].type
                                    };
                                    setValues(formData);
                                    setSubmitting(false);
                                }

                                props.onUpdated();
                            });
                    } else {
                        let mutationData: any;
                        executeUpdateJournalEntry({ input: je })
                            .then((result) => {
                                mutationData = result.data.updateJournalEntry;
                                setAlert({
                                    color: "success",
                                    visible: true,
                                    message: `The journal entry '${mutationData._id}' was updated successfully!`
                                });
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                if (mutationData && mutationData._id) {
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(mutationData._id);
                                    navigate(path.join("/"), { replace: true });
                                    const formData = {
                                        _id: mutationData._id,
                                        amount: mutationData.transactions[0].amount,
                                        instrumentId: mutationData.transactions[0].instrumentId,
                                        type: mutationData.transactions[0].type,
                                        tAccountNumber: mutationData.transactions[0].tAccountNumber,
                                        secondInstrumentId: mutationData.transactions[1].instrumentId,
                                        secondTAccountNumber: mutationData.transactions[1].tAccountNumber,
                                        secondType: mutationData.transactions[1].type
                                    };
                                    setSubmitting(false);
                                    setValues(formData);
                                }

                                props.onUpdated();
                            });
                    }
                }}
            >
                {({ isSubmitting, values, errors }) => {
                    if (Object.keys(errors).length > 0) {
                        console.log(errors);
                    }
                    return (
                        <Fragment>
                            <div className="">
                                <header>
                                    <h4>{"Manual journal entry"}</h4>
                                </header>
                                <div>
                                    <div>
                                        <Form autoComplete="off">
                                            <div className="container form">
                                                <div className="row">
                                                    <div className="col-lg">
                                                        <SelectField
                                                            name="tAccountNumber"
                                                            label={"T account"}
                                                            options={props.tAccountNumbers}
                                                            className=""
                                                            disabled={false}
                                                        />
                                                        <SelectField
                                                            name="type"
                                                            label={"Type"}
                                                            options={Object.values(AccountingTransactionType)}
                                                            className=""
                                                            disabled={false}
                                                        />
                                                        <SearchListField
                                                            name="instrumentId"
                                                            label={"Instrument name"}
                                                            className=""
                                                            options={allInstruments}
                                                            disabled={false}
                                                        />
                                                        <NumberField name="amount" label="Amount" className="" disabled={false} />
                                                    </div>
                                                    <div className="col-lg">
                                                        <SelectField
                                                            name="secondTAccountNumber"
                                                            label={"T account"}
                                                            options={props.tAccountNumbers}
                                                            className=""
                                                            disabled={false}
                                                        />
                                                        <SelectField
                                                            name="secondType"
                                                            label={"Type"}
                                                            options={Object.values(AccountingTransactionType)}
                                                            className=""
                                                            disabled={false}
                                                        />
                                                        <SearchListField
                                                            name="secondInstrumentId"
                                                            label={"Instrument name"}
                                                            className=""
                                                            options={allInstruments}
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {alert.visible ? (
                                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                    {alert.message}
                                                </Alert>
                                            ) : null}

                                            <SubmitButton disabled={isSubmitting} label={values._id ? "Update" : "Create"} />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}
