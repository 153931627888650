import React, { ReactElement } from "react";
import { gql, useMutation } from "urql";
import { AccountingRun, AccountingRunStatusEnum } from "../../types.generated";
import { TooltipLabel } from "../../components/TooltipLabel";

const UPDATE_ACCOUNTINGRUNSTATUS = gql`
    mutation updateAccountingRunStatus($id: GraphQLObjectId!, $status: AccountingRunStatusEnum!) {
        updateAccountingRunStatus(_id: $id, status: $status)
    }
`;

interface IConfirmAccountingRunButton {
    accountingRun: AccountingRun;
    user: any;
    updateToStatus: AccountingRunStatusEnum;
    onConfirmed: () => void;
    onError: (msg: string) => void;
}

export const ConfirmAccountingRunButton = ({
    accountingRun,
    user,
    updateToStatus,
    onConfirmed,
    onError
}: IConfirmAccountingRunButton): ReactElement => {
    const [__stateUpdate, updateAccountingRunStatus] = useMutation(UPDATE_ACCOUNTINGRUNSTATUS);
    //const disableConfirmMode = accountingRun && accountingRun._id && !(endDate > accountingRun.endDate);

    // Same user who updated to preliminary cannot set to confirm -> disable..
    const sameUserConfirm =
        updateToStatus === AccountingRunStatusEnum.Confirmed && user && user._id && user._id.toString() === accountingRun.updateUserId;

    return (
        <div className="">
            {sameUserConfirm ? (
                <TooltipLabel
                    text={
                        <button
                            type="button"
                            onClick={async () => {
                                if (window.confirm("Do you want to change status to " + updateToStatus + "?")) {
                                    await updateAccountingRunStatus({
                                        id: accountingRun._id,
                                        status: updateToStatus
                                    })
                                        .then((result) => {
                                            if (result.error) {
                                                onError(result.error.toString());
                                            } else {
                                                onConfirmed();
                                            }
                                        })
                                        .catch((error) => {
                                            onError(error.toString());
                                        });
                                }
                            }}
                            className="save btn btn-primary btn-sm"
                            disabled={sameUserConfirm ? true : false}
                        >
                            {"Confirm the preliminary accounting run"}
                        </button>
                    }
                    className={""}
                    content={"Only a different user than user with id " + accountingRun.updateUserId + " can update to Confirmed."}
                ></TooltipLabel>
            ) : (
                <button
                    type="button"
                    onClick={async () => {
                        if (window.confirm("Do you want to change status to " + updateToStatus + "?")) {
                            await updateAccountingRunStatus({
                                id: accountingRun._id,
                                status: updateToStatus
                            })
                                .then((result) => {
                                    if (result.error) {
                                        onError(result.error.toString());
                                    } else {
                                        onConfirmed();
                                    }
                                })
                                .catch((error) => {
                                    onError(error.toString());
                                });
                        }
                    }}
                    className="save btn btn-primary btn-sm"
                    disabled={false}
                >
                    {updateToStatus === AccountingRunStatusEnum.Confirmed
                        ? "Confirm the preliminary accounting run"
                        : "Preliminary confirm the pending accounting run"}
                </button>
            )}
        </div>
    );
};
