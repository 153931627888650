import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import { gql, useMutation, useQuery } from "urql";
import { sortBy } from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { AttachmentForm } from "../AttachmentForm";
import { usePrevious, useAlertTimeOut } from "../../../../components/src/common/Utils";
import { SubmitButton, TextField, SelectField, SearchMultipleSelectField, DateField, NumberField } from "../../../../components/src/form";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";

import { SYSTEM_PARTY_ID } from "../../Constants";
import { MarkDownField } from "../../components/form/MarkDownField";
import { CostFilterInput, CostInput, CostStatusEnum, CurrencyEnum } from "../../types.generated";
import { REACT_APP_API_URI } from "../../env";

export const getCosts = gql`
    query getCosts($filter: CostFilterInput) {
        costs(filter: $filter) {
            _id
            clientId
            client {
                _id
                name
            }
            debtorIds
            debtors {
                _id
                name
            }
            description
            amount
            currency
            date
            startDate
            endDate
            createTimestamp
            updateTimestamp
            creatorId
            updateUserId
            updateUserInfo {
                name
            }
            status
            attachments {
                fileId
                fileName
                mimeType
            }
        }
    }
`;

export const UpsertCosts = gql`
    mutation UpsertCosts($input: [CostInput!]!, $includeVersions: Boolean = false) {
        upsertCosts(input: $input) {
            _id
            clientId
            client {
                _id
                name
            }
            debtorIds
            debtors {
                _id
                name
            }
            description
            amount
            currency
            startDate
            endDate
            createTimestamp
            updateTimestamp
            creatorId
            updateUserId
            updateUserInfo {
                name
            }
            status
            attachments {
                fileId
                clientId
                fileName
                mimeType
                mD5
                updateTimestamp
            }
            versions @include(if: $includeVersions)
        }
    }
`;

export const GetParties = gql`
    query GetParties {
        parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const getDefaultDocument = (): CostInput => {
    const today = new Date().toISOString().slice(0, 10);
    const cost: CostInput = {
        _id: "new",
        clientId: SYSTEM_PARTY_ID,
        debtorIds: [],
        attachments: [],
        currency: CurrencyEnum.SEK,
        amount: 100,
        description: "",
        date: today,
        startDate: today,
        endDate: today,
        status: CostStatusEnum.Active
    };

    return cost;
};

export function CostPage(): React.ReactElement {
    const navigate = useNavigate();
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);
    const isEditMode = id === "new" ? false : true;
    const isCreateMode = !isEditMode;

    const filter: CostFilterInput = { idIn: [id], statusIn: Object.values(CostStatusEnum) };

    const [{ fetching: loading, error, data }, refetch] = useQuery({
        query: getCosts,
        variables: { filter },
        pause: isCreateMode,
        requestPolicy: "cache-and-network"
    });

    const [_, upsertMutation] = useMutation(UpsertCosts);

    const [{ fetching: loadingParties, error: errorParties, data: dataParties }] = useQuery({
        query: GetParties,
        requestPolicy: "cache-and-network"
    });
    const [cost, setCost] = useState<CostInput>(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (id === "new" && !cost) {
            setCost(getDefaultDocument());
        } else if (previousId && id !== previousId) {
            setCost(null);
        } else if (data && data.costs) {
            setCost(data.costs[0]);
        } else if (!cost) {
            setCost(null);
        }
    }, [cost, data, id, previousId]);

    const callBackOnChangeAttachment = useCallback(
        (attachments) => {
            attachments = recursivelyRemoveKey(attachments, "__typename");
            const input: CostInput = {
                _id: cost._id,
                clientId: cost.clientId,
                debtorIds: cost.debtorIds,
                description: cost.description,
                status: cost.status,
                date: cost.date,
                startDate: cost.startDate,
                endDate: cost.endDate,
                amount: cost.amount,
                currency: cost.currency,
                attachments: attachments
            };
            upsertMutation({ input: input }).then(() => {
                if (isEditMode) {
                    refetch();
                }
            });
        },
        [cost, isEditMode, refetch, upsertMutation]
    );

    if (loading || loadingParties) return <div>Loading</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (errorParties) return <p>error: {JSON.stringify(errorParties, null, 2)}</p>;
    if (!cost) return <div>No cost found</div>;

    const partyOptions = sortBy(
        dataParties.parties.map((d) => ({ key: d._id, value: d.name, text: d.name })),
        "value"
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={cost}
            onSubmit={async (submitValues, { setSubmitting }) => {
                const input: CostInput = {
                    _id: submitValues._id,
                    clientId: submitValues.clientId,
                    debtorIds: submitValues.debtorIds,
                    description: submitValues.description,
                    status: submitValues.status,
                    date: submitValues.date,
                    startDate: submitValues.startDate,
                    endDate: submitValues.endDate,
                    amount: submitValues.amount,
                    currency: submitValues.currency
                };
                if (isCreateMode) {
                    delete input._id;
                }
                await upsertMutation({ input: [input] })
                    .then((result) => {
                        const cost = result.data.upsertCosts[0];
                        if (isCreateMode) {
                            navigate("/backoffice/costs/" + cost._id, { replace: true });
                        } else {
                            setCost(cost);
                            setAlert({
                                color: "success",
                                visible: true,
                                message: `Cost '${cost._id}' updated successfully!`
                            });
                        }
                    })
                    .catch((error) => {
                        setAlert({ color: "danger", visible: true, message: error.toString() });
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting, errors }) => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors);
                }
                return (
                    <Fragment>
                        <div className="row col">
                            <h2 className="p-2">Cost</h2>
                        </div>
                        <Form autoComplete="off">
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="d-sm-flex">
                                            <TextField name="_id" label="Id" type="text" className="col-3" disabled={true} />
                                            <DateField name="date" label="Date" className="ps-sm-3 col-3" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-sm-flex">
                                            <DateField name="startDate" label="Start date" className="col-3" disabled={isSubmitting} />
                                            <DateField name="endDate" label="End date" className="ps-sm-3 col-3" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-sm-flex">
                                            <NumberField name="amount" label="Amount" className="col-3" disabled={isSubmitting} />
                                            <SelectField
                                                name="currency"
                                                label="Currency"
                                                options={Object.values(CurrencyEnum).sort()}
                                                className="ps-sm-3 select-currency col-3"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-sm-flex">
                                            <SearchMultipleSelectField
                                                name="debtorIds"
                                                label="Debtors"
                                                className="col-10"
                                                disabled={isSubmitting}
                                                options={partyOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-sm-flex">
                                            <MarkDownField
                                                name="description"
                                                graphqlApiBaseUrl={REACT_APP_API_URI}
                                                label="Description"
                                                className="cursor-pointer col-10"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-sm-flex col">
                                        <SubmitButton
                                            className="btn btn-primary mb-2"
                                            disabled={isSubmitting || Object.keys(errors).length > 0}
                                            label={"Update"}
                                        />
                                    </div>
                                    <div className="d-sm-flex">
                                        {isEditMode ? (
                                            <AttachmentForm
                                                clientId={cost.clientId}
                                                attachments={cost.attachments}
                                                onChange={callBackOnChangeAttachment}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <SelectField
                                        name="status"
                                        label="Status"
                                        options={Object.keys(CostStatusEnum)}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                    <SelectField
                                        name="clientId"
                                        label="Client (owner)"
                                        options={partyOptions}
                                        className=""
                                        disabled={isSubmitting}
                                    />
                                    <TextField name="updateUserInfo.name" label="Updated by" type="text" className="" disabled={true} />
                                </div>
                            </div>

                            {alert.visible ? (
                                <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                    {alert.message}
                                </Alert>
                            ) : null}
                        </Form>
                    </Fragment>
                );
            }}
        </Formik>
    );
}
