import React, { ReactElement, Fragment, useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import { Form } from "react-bootstrap";

import { Formik } from "formik";
import { DateHelper } from "../../../common/src";
import { SearchListField } from "../../../components/src/form";
import { useQueryState } from "../../../components/src/use-query-state";
import { PeriodSelector } from "../components/PeriodSelector";
import { Grid, Column, Svgs } from "../../../components/src";
import { DateForm } from "../../../components/src/dateForm";
import { TimeSeriesOperationPlot } from "./timeseries/TimeSeriesOperationPlot";
import { cloneDeep } from "lodash";

interface DataProp {
    _id: string;
    instrumentId: string;
    status: string;
    instrument: {
        name: string;
        longName: string;
        currency: string;
        bloombergTicker: string;
        isin: string;
    };
}

const GET_DATA = gql`
    query {
        timeseries(filter: { statusIn: Active, typeIn: "Price(Close)" }) {
            _id
            instrumentId
            instrument {
                name
                longName
                currency
                bloombergTicker
                isin
            }
        }
    }
`;

const defaultCurrency = "SEK";

const Checkbox = (props) => <input type="checkbox" {...props} />;

export function ComparePage(): ReactElement {
    const [timeserieIds, setTimeserieIds] = useQueryState("ts", []);
    const [rebase, setRebase] = useQueryState("rebase", true);
    const [alignDateRange, setAlignDateRange] = useQueryState("align", true);
    const [startDate] = useQueryState("startDate", DateHelper.addYears(new Date(), -1).toISOString().slice(0, 10));
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));
    const [currency, setCurrency] = useQueryState("currency", "");
    const [exchange, setExchange] = useQueryState("ex", []);
    const [fxHedged, setFxHedged] = useQueryState("fx", []);

    const [instruments, setInstuments] = useState([]);
    const [globalCurrency, setGlobalCurrency] = useState(defaultCurrency);

    const addTypeInName = false;
    const useRangeSelector = false;
    const margin = { t: 0, b: 0, l: 0, r: 200, pad: 0 };

    const [{ data, fetching: loading, error }] = useQuery({
        query: GET_DATA,
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        const ids = instruments && instruments.length > 0 ? instruments.map((i) => i._id) : [];
        if (data && data.timeseries && ids.toString() != timeserieIds.toString()) {
            const updatedInstruments = timeserieIds.map((tid: any, index: number) => {
                const existingInstrument = instruments.find((x) => x._id == tid);
                if (existingInstrument) {
                    const instr = cloneDeep(existingInstrument);
                    instr.index = index;
                    return instr;
                } else {
                    const ts = data.timeseries.find((t: DataProp) => t._id == tid);
                    return {
                        _id: ts._id,
                        name: ts.instrument.name,
                        longName: ts.instrument.longName,
                        currency: ts.instrument.currency,
                        exchangedCurrency: currency ? globalCurrency : exchange.length > index ? exchange[index] : ts.instrument.currency,
                        fxHedged: fxHedged.length > index ? fxHedged[index] == "true" : false,
                        index
                    };
                }
            });
            setInstuments(updatedInstruments);
            const exchangedCurrencies = updatedInstruments.map((x) => x.exchangedCurrency);
            setExchange(exchangedCurrencies);
            const fx = updatedInstruments.map((x) => x.fxHedged);
            setFxHedged(fx);
        }
    }, [instruments, timeserieIds, data, currency, globalCurrency, exchange, fxHedged, setExchange, setFxHedged]);

    if (loading)
        return (
            <div className="loader">
                <p>Loading...</p>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const handleChange = (e) => {
        if (e) {
            const newIds = timeserieIds.slice();
            newIds.push(e);
            setTimeserieIds(newIds);
        }
    };

    const handleCurrencyCheckChange = (e) => {
        if (e.target.checked) {
            setCurrency(globalCurrency); // set query param
            setExchange([]);
            setFxHedged([]);
            if (instruments && instruments.length > 0) {
                const updatedInstruments = instruments.map((i) => {
                    const instr = cloneDeep(i);
                    instr.exchangedCurrency = globalCurrency;
                    return instr;
                });
                setInstuments(updatedInstruments);
            }
        } else {
            setCurrency("");
            if (instruments && instruments.length > 0) {
                const updatedInstruments = instruments.map((i) => {
                    const instr = cloneDeep(i);
                    instr.exchangedCurrency = instr.currency;
                    return instr;
                });
                setInstuments(updatedInstruments);
                const exchangedCurrencies = updatedInstruments.map((x) => x.exchangedCurrency);
                setExchange(exchangedCurrencies);
                const fx = updatedInstruments.map((x) => x.fxHedged);
                setFxHedged(fx);
            }
        }
    };

    const handleGlobalCurrencyChange = (e) => {
        setCurrency(e.target.value);
        setGlobalCurrency(e.target.value);
        if (instruments && instruments.length > 0) {
            const updatedInstruments = instruments.map((i) => {
                const instr = cloneDeep(i);
                instr.exchangedCurrency = e.target.value;
                return instr;
            });
            setInstuments(updatedInstruments);
        }
    };

    const handleRebase = (e) => {
        setRebase(e.target.checked);
    };

    const handleAlignDateRange = (e) => {
        setAlignDateRange(e.target.checked);
    };

    const options = data.timeseries.map((t: DataProp) => {
        return {
            _id: t._id,
            name: t.instrument.name,
            longName: t.instrument.longName,
            isin: t.instrument.isin,
            currency: t.instrument.currency,
            bloombergTicker: t.instrument.bloombergTicker
        };
    });

    const uniqueCurrencies: string[] = [...new Set<string>(options.map((item: any) => item.currency))];

    //console.log("instruments", instruments);

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-xs-12">
                    <Formik key={timeserieIds.join(",")} enableReinitialize={true} initialValues={{ timeserieId: null }} onSubmit={null}>
                        {() => (
                            <Fragment>
                                <Form autoComplete="off" className="form">
                                    <div className="form-row">
                                        <SearchListField
                                            name="instrumentId"
                                            label="Add timeseries"
                                            className="form-group w-100"
                                            options={options}
                                            disabled={false}
                                            onChange={handleChange}
                                            initialInputFocus={true}
                                        />
                                    </div>
                                </Form>
                            </Fragment>
                        )}
                    </Formik>
                    <div>
                        <Checkbox checked={rebase} onChange={handleRebase} disabled={timeserieIds.length === 0} />{" "}
                        <span>Rebase series</span>
                    </div>
                    <div>
                        <Checkbox checked={alignDateRange} onChange={handleAlignDateRange} disabled={timeserieIds.length === 0} />{" "}
                        <span>Align date range</span>
                    </div>
                    <div>
                        <Checkbox checked={currency} onChange={handleCurrencyCheckChange} disabled={timeserieIds.length === 0} />{" "}
                        <span>Same currency</span>
                        <select
                            id="currency"
                            className="ms-1"
                            defaultValue={defaultCurrency}
                            disabled={!currency}
                            onChange={handleGlobalCurrencyChange}
                        >
                            {uniqueCurrencies.map((d: string, i) => (
                                <option key={i} value={d}>
                                    {d}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-1 col-md-1 col-xs-12"></div>
                <div className="col-lg-4 col-md-5 col-xs-12">
                    <PeriodSelector firstAvailableStartDate={""} />
                    <DateForm className="width-fixed" defaultDateString={startDate} dateName={"startDate"}></DateForm>
                    <DateForm className="width-fixed" defaultDateString={endDate} dateName={"endDate"}></DateForm>
                </div>
                <div className="col-lg-3 col-md-0 col-xs-12"></div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Grid data={instruments} className="table-timeseries" tableClassName="table-xs">
                        <Column field="name" className="nowrap left" />
                        <Column field="longName" className="nowrap left" />
                        <Column field="currency" title="Instr. ccy" />
                        <Column
                            field="exchangedCurrency"
                            title="Calc. ccy"
                            format={(value, item) => (
                                <select
                                    id="currency"
                                    data-ndx={item.index}
                                    className="ms-1"
                                    disabled={!!currency}
                                    value={value}
                                    onChange={(e) => {
                                        const updatedInstruments = cloneDeep(instruments);
                                        const instrIndex = e.target.dataset["ndx"];
                                        const instr = updatedInstruments[instrIndex];
                                        instr.exchangedCurrency = e.target.value;
                                        setInstuments(updatedInstruments);
                                        const exchangedCurrencies = updatedInstruments.map((x) => x.exchangedCurrency);
                                        setExchange(exchangedCurrencies);
                                    }}
                                >
                                    {uniqueCurrencies.map((d: string, i) => (
                                        <option key={i} value={d}>
                                            {d}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                        <Column
                            className="center"
                            field="Remove"
                            format={() => (
                                <div style={{ width: "16px", height: "16px" }}>
                                    <Svgs.Cross />
                                </div>
                            )}
                            onClick={(e, i) => {
                                const updatedInstruments = instruments.filter((instr) => instr.index != i);
                                const tsIds = updatedInstruments.map((instr) => instr._id);
                                setTimeserieIds(tsIds);
                            }}
                        />
                        <Column
                            field="fxHedged"
                            format={(value, item) => (
                                <input
                                    type="checkbox"
                                    data-ndx={item.index}
                                    checked={value}
                                    disabled={!instruments}
                                    onChange={(e) => {
                                        const updatedInstruments = cloneDeep(instruments);
                                        const instrIndex = e.target.dataset["ndx"];
                                        const instr = updatedInstruments[instrIndex];
                                        instr.fxHedged = e.target.checked;
                                        setInstuments(updatedInstruments);
                                        const fx = updatedInstruments.map((x) => x.fxHedged);
                                        setFxHedged(fx);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </div>
            </div>

            <div className="row">
                {instruments && instruments.length > 0 ? (
                    <div className="col-12">
                        <TimeSeriesOperationPlot
                            params={instruments}
                            startDate={startDate}
                            endDate={endDate}
                            alignDateRange={alignDateRange}
                            rebase={rebase}
                            addTypeInName={addTypeInName}
                            useRangeSelector={useRangeSelector}
                            customMargin={margin}
                        />
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
}
