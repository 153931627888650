import stableStringify from "json-stable-stringify";
import { cloneDeep } from "lodash";

export const smartRound = (d, minDecimals = 2, maxDecimals = 6, returnNumberOfdecimals = false) => {
    let i = !isNaN(Number(minDecimals)) ? Number(minDecimals) : 2;
    const n = !isNaN(Number(maxDecimals)) ? Number(maxDecimals) : 6;
    let v = Math.pow(10, i) * d;
    const forever = true;
    while (forever) {
        const r = Math.round(v);
        if (i === n || Math.abs(Math.round(v) - v) < 1e-5) {
            return returnNumberOfdecimals === true ? i : r * Math.pow(10, -i);
        }
        i++;
        v *= 10;
    }
};

export function recursivelyRemoveKey(inObj: any, key: string) {
    function removeKeys(obj, key: string) {
        const isArray = obj instanceof Array;
        let k: any;
        for (k in obj) {
            if (k === key) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                isArray ? obj.splice(k, 1) : delete obj[k];
            } else if (typeof obj[k] === "object") {
                removeKeys(obj[k], key);
            }
            if (isArray && obj.length === k) {
                removeKeys(obj, key);
            }
        }
        return obj;
    }
    const obj = cloneDeep(inObj);
    return removeKeys(obj, key);
}

export function numberFormat(
    v: number,
    formatStringOrNumberOfDecimals: string,
    decimalDelimiter = ".",
    thousandDelimiter = "",
    factor = 1,
    suffix = ""
) {
    if (isNaN(v)) return "";
    let c = 0;
    let copt = 0;
    let d = ".";
    let t = "";
    let f = 1;
    let x = "";
    if (typeof formatStringOrNumberOfDecimals === "string") {
        const m = /((?:#)([\u0020\u00a0\xa0,.])(?:##))?0(?:([.,])*(0*)(#*))?([%$£€a-zA-Z]+)?/.exec(formatStringOrNumberOfDecimals);
        if (!m) return v.toFixed(2);
        if (m[5]) copt = m[5].length;
        if (m[4]) c = m[4].length;
        if (m[3]) d = m[3];
        if (m[2]) t = m[2];
        if (m[6]) {
            x = m[6];
            if (x === "%") f = 100;
        }
    } else {
        if (typeof decimalDelimiter !== "undefined") d = decimalDelimiter;
        if (typeof thousandDelimiter !== "undefined") t = thousandDelimiter;
        if (typeof factor !== "undefined") f = factor;
        if (typeof suffix !== "undefined") x = suffix;
    }
    if (copt !== 0) {
        // copt: optional decimals
        // console.log(`smartRound: ${c} ${c + copt} ${smartRound(v, c, c + copt, true)}`);
        c = smartRound(v, c, c + copt, true);
    }
    const n = f * v;
    const s = n < 0 ? "-" : "";
    const ns = Math.abs(Number(n) || 0).toFixed(c);
    const i = String(parseInt(ns, 10));
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(Number(ns) - Number(i))
                  .toFixed(c)
                  .slice(2)
            : "") +
        x
    );
}

export const valueFormat = (d) => (typeof d === "number" ? numberFormat(Number(d), "#\xa0##0") : "");
export const priceFormat = (d) => {
    if (typeof d !== "number") {
        return "";
    }
    const n = Number(d);
    return numberFormat(n, Math.abs(n) < 2 ? "#\xa0##0.0000" : "#\xa0##0.00");
};
export const numberFormatFun = (f) => (d) =>
    typeof d === "number" ? numberFormat(Number(d), f) : typeof d === "string" && parseFloat(d) ? numberFormat(parseFloat(d), f) : null;
export const twoDecPriceFormat = (d) => (typeof d === "number" ? numberFormat(Number(d), "#\xa0##0.00") : "");
export const fourDecPriceFormat = (d) => (typeof d === "number" ? numberFormat(Number(d), "#\xa0##0.0000") : "");
export const pctfmtFun = (d) => (typeof d === "number" ? numberFormat(Number(d), Math.abs(d) > 0.995 ? "0%" : "0,0%") : "");
export const jsonfmtFun = (d) => (typeof d === "object" ? stableStringify(d, { space: "  " }) : "");
