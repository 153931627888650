import React, { Fragment, useState, useEffect } from "react";
import { Grid, Column } from "../../../../components/src";
import { gql, useQuery } from "urql";

import {
    InstrumentPosition,
    Instrument,
    Party,
    CashAccountLadder,
    InstrumentModelTypeEnum,
    IssuerProgramCategory
} from "../../types.generated";
import { DateHelper, numberFormatFun } from "../../../../common/src";
import { Link } from "react-router-dom";
import { groupBy, round } from "lodash";
import { TooltipLabel } from "../TooltipLabel";

const getParties = gql`
    query getParties {
        parties(filter: { typeIn: [Fund, Mandate], statusIn: [Confirmed] }) {
            _id
            name
        }
    }
`;

const getData = gql`
    query getData($clientIds: [GraphQLObjectId!]!, $endDate: GraphQLDateString) {
        allInstrumentsInPosition {
            clients {
                name
            }
            instrument {
                _id
                name
                modelType
                maturityDate
            }
        }
        cashLadder(endDate: $endDate, clientIds: $clientIds) {
            client {
                _id
                name
            }
            instrumentId
            instrument {
                name
                productType
            }
            dates
            balances
        }
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: true) {
            clientId
            exposure
            interestRateYieldDelta(date: $endDate)
            creditYieldDelta(date: $endDate)
            modelType
            instrument {
                issuerProgram {
                    category
                }
            }
        }
    }
`;

const moneyFormat = numberFormatFun("# ##0,00");

interface InstrumentExtended extends Instrument {
    positionClients?: string;
}

const sortInstruments = (allInstruments: InstrumentPosition[], showAll: boolean, endDate: string) => {
    const instrumentsFiltered: Instrument[] = [];
    // Only choose up to max_days days into the future from end date
    const max_days = 90;
    const future_date = DateHelper.dateAddDays(new Date(endDate), max_days);

    allInstruments.forEach((item) => {
        if (item.instrument.maturityDate !== null) {
            const instr_new: InstrumentExtended = item.instrument;
            instr_new.positionClients = item.clients
                .map(function (e) {
                    return e.name;
                })
                .join(", ");
            if (showAll === true) {
                instrumentsFiltered.push(instr_new);
            } else if (showAll === false && DateHelper.toDate(item.instrument.maturityDate) < future_date) {
                instrumentsFiltered.push(instr_new);
            }
        }
    });
    instrumentsFiltered.sort((a, b) => a.maturityDate.localeCompare(b.maturityDate));

    return instrumentsFiltered;
};

interface CashOverview {
    client: Party;
    name: string;
    date: string;
    today: number;
    tomorrow: number;
    twoDays: number;
    threeDays: number;
}

const getTodaysBalances = (cashLadder: CashAccountLadder[], endDate: string) => {
    const result: CashOverview[] = [];
    const currentDate: Date = new Date(endDate);
    let futureDate1: Date;
    let futureDate2: Date;
    let futureDate3: Date;

    futureDate1 = DateHelper.dateAddDays(currentDate, 1);
    if (futureDate1.getDay() == 6) {
        futureDate1 = DateHelper.dateAddDays(new Date(futureDate1), 2);
    } else if (futureDate1.getDay() == 0) {
        futureDate1 = DateHelper.dateAddDays(new Date(futureDate1), 1);
    }

    if (futureDate1.getDay() == 5) {
        futureDate2 = DateHelper.dateAddDays(new Date(futureDate1), 3);
    } else {
        futureDate2 = DateHelper.dateAddDays(new Date(futureDate1), 1);
    }

    if (futureDate2.getDay() == 5) {
        futureDate3 = DateHelper.dateAddDays(new Date(futureDate2), 3);
    } else {
        futureDate3 = DateHelper.dateAddDays(new Date(futureDate2), 1);
    }

    cashLadder.forEach((item) => {
        if (item.instrument.productType === "CashAccount") {
            const idx_tdy = item.dates.indexOf(currentDate.toISOString().slice(0, 10));
            const idx_add1 = item.dates.indexOf(futureDate1.toISOString().slice(0, 10));
            const idx_add2 = item.dates.indexOf(futureDate2.toISOString().slice(0, 10));
            const idx_add3 = item.dates.indexOf(futureDate3.toISOString().slice(0, 10));

            if (!(idx_tdy === -1 && idx_add1 === -1 && idx_add2 === -1 && idx_add3 === -1)) {
                result.push({
                    client: item.client,
                    name: item.instrument.name,
                    date: item.dates[idx_tdy],
                    today: item.balances[idx_tdy],
                    tomorrow: item.balances[idx_add1],
                    twoDays: item.balances[idx_add2],
                    threeDays: item.balances[idx_add3]
                });
            }
        }
    });
    return result;
};

const getRiskNumbers = (clients, trades) => {
    const positionsByClientId = groupBy(trades, (i) => i.clientId);

    const result = clients.map((fund) => {
        let duration;
        let creditDuration;
        let creditDurationBonds;
        let esgBondsPercent;
        const cdsExposure = "-";

        const positions = positionsByClientId[fund._id];
        if (positions) {
            const portfolioNav = positions.reduce((acc, o) => acc + o.exposure, 0);
            const sumInterestRateYieldDelta = positions.reduce((acc, o) => acc + o.interestRateYieldDelta, 0);
            duration = (sumInterestRateYieldDelta / portfolioNav) * 10000;

            const sumCreditYieldDelta = positions.reduce((acc, o) => acc + o.creditYieldDelta, 0);
            creditDuration = (sumCreditYieldDelta / portfolioNav) * 10000;

            const sumBondsCreditYieldDelta: number = positions
                .filter((x) => x.modelType == InstrumentModelTypeEnum.Bond)
                .reduce((acc, o) => acc + o.creditYieldDelta, 0);

            const sumBondsExposure: number = positions
                .filter((x) => x.modelType == InstrumentModelTypeEnum.Bond)
                .reduce((acc, o) => acc + o.exposure, 0);

            const sumBondsWithCategoryExposure: number = positions
                .filter(
                    (x) =>
                        x.modelType == InstrumentModelTypeEnum.Bond &&
                        x.instrument.issuerProgram &&
                        x.instrument.issuerProgram.category !== IssuerProgramCategory.None
                )
                .reduce((acc, o) => acc + o.exposure, 0);

            esgBondsPercent = round((sumBondsWithCategoryExposure / sumBondsExposure) * 100, 1);
            creditDurationBonds = (sumBondsCreditYieldDelta / sumBondsExposure) * 10000;
        }

        return {
            client: {
                _id: fund._id,
                name: fund.name
            },
            duration,
            creditDuration,
            creditDurationBonds,
            cdsExposure,
            esgBondsPercent
        };
    });

    return result;
};

const Checkbox = (props) => <input type="checkbox" {...props} />;

export function Dashboard({ endDate }: { endDate: string }): React.ReactElement {
    const [checkedShowAll, setCheckedShowAll] = useState(false);
    const [clientList, setClientList] = useState(null);
    const [trades, setTrades] = useState([]);

    const [{ fetching: loadingParties, data: dataParties }] = useQuery({ query: getParties, requestPolicy: "network-only" });

    // TODO: Query for allInstrumentsInPosition does not support date in argument
    const [{ fetching: loading, error: error, data: data }] = useQuery({
        query: getData,
        pause: clientList === null,
        variables: { clientIds: clientList ? clientList.map((p: Party) => p._id) : [], endDate },
        requestPolicy: "network-only"
    });

    const [instrumentData, setinstrumentData] = useState(null);
    const [cashLadder, setCashLadder] = useState(null);

    useEffect(() => {
        if (dataParties && dataParties.parties) {
            const clients = dataParties.parties.map((p: Party) => {
                return { _id: p._id, name: p.name };
            });
            setClientList(clients);
        } else {
            setClientList(null);
        }
    }, [dataParties]);

    useEffect(() => {
        if (data) {
            setinstrumentData(data.allInstrumentsInPosition);
            setCashLadder(data.cashLadder);
            setTrades(data.tradingmanager);
        }
    }, [dataParties, data]);

    if (loadingParties) return <p>Loading parties...</p>;
    if (loading) return <div>Loading...</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (!instrumentData) return <div>"No data found"</div>;

    const handleCheckboxChangeShowAll = (e) => {
        setCheckedShowAll(e.target.checked);
    };

    const instruments: Instrument[] = sortInstruments(instrumentData, checkedShowAll, endDate);
    const cashBalances = getTodaysBalances(cashLadder, endDate);
    const riskNumbers = getRiskNumbers(clientList, trades);

    return (
        <Fragment>
            <div className="row mb-2">
                <div className="col">
                    <label>
                        <Checkbox checked={checkedShowAll} onChange={handleCheckboxChangeShowAll} />
                        <span> Show all expiring instruments </span>
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-xxl-5">
                    <Grid header="Cash balances" data={cashBalances} sortable tableClassName="table-xs" negativeClassName="warning">
                        <Column
                            field="client.name"
                            className="nowrap left"
                            title="Client"
                            format={(_, item) => {
                                return (
                                    <Link to={"/pm/" + item.client._id + "/cashLadder"}>{item && item.client ? item.client.name : ""}</Link>
                                );
                            }}
                        />
                        <Column field="name" />
                        <Column field="today" format={numberFormatFun("# ##0")} />
                        <Column field="tomorrow" format={numberFormatFun("# ##0")} />
                        <Column field="twoDays" title="2 days" format={numberFormatFun("# ##0")} />
                        <Column field="threeDays" title="3 days" format={numberFormatFun("# ##0")} />
                    </Grid>

                    <Grid header="Risk numbers" data={riskNumbers} sortable tableClassName="table-xs" negativeClassName="warning">
                        <Column
                            field="client.name"
                            className="nowrap left"
                            title="Client"
                            format={(_, item) => {
                                return (
                                    <Link to={"/pm/" + item.client._id + "/cashLadder"}>{item && item.client ? item.client.name : ""}</Link>
                                );
                            }}
                        />
                        <Column
                            field="duration"
                            title="Duration"
                            format={(value) => {
                                return value !== undefined ? moneyFormat(value) : "n/a";
                            }}
                            className="nowrap right"
                        />
                        <Column
                            field="creditDuration"
                            title="Credit duration"
                            format={(value) => {
                                return value !== undefined ? moneyFormat(value) : "n/a";
                            }}
                            className="nowrap right"
                        />
                        <Column
                            field="creditDurationBonds"
                            title="Credit duration bonds"
                            format={(value) => {
                                return value !== undefined ? moneyFormat(value) : "n/a";
                            }}
                            className="nowrap right"
                        />
                        <Column
                            field="cdsExposure"
                            title="CDS exposure"
                            format={() => {
                                return "-";
                            }}
                            className="nowrap center"
                        />
                        <Column
                            field="esgBondsPercent"
                            title={<TooltipLabel text={"ESG Bonds %"} content={"Value of ESG bonds / total bond value"} />}
                            className="nowrap center"
                            format={numberFormatFun("0.0")}
                        />
                    </Grid>
                </div>
                <div className="col-12 col-xxl-7">
                    <Grid header="Upcoming expiries" data={instruments} sortable tableClassName="table-xs" negativeClassName="warning">
                        <Column field="maturityDate" title="Maturity date" />
                        <Column field="modelType" title="Model type" />
                        <Column
                            field="_id"
                            className="nowrap left"
                            title="Instrument"
                            format={(_, item) => {
                                return <Link to={"/instruments/" + item._id}>{item ? item.name : ""}</Link>;
                            }}
                        />
                        <Column field="positionClients" title="Clients" />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}
