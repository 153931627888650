import React from "react";
import { DetailsList, Selection, IColumn, DetailsListLayoutMode, CheckboxVisibility, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { useLocation } from "react-router-dom";
import { isValidMongoDBObjectID } from "../../../components/src/common/Utils";
import { twoDecPriceFormat } from "../../../common/src";
import { orderBy, snakeCase } from "lodash";
import { Link } from "react-router-dom";
import { miniTransactionTypes } from "../containers/MiniTransactionForm";
import { CommandBar, ICommandBarItemProps, MarqueeSelection } from "@fluentui/react";
import { Excel } from "../../../components/src/Svgs";
import { exportToXlsx2 } from "../../../components/src/common/exportToXlsx2";
import { TransactionStatus } from "../types.generated";

export interface ITransactionDataRow {
    _id: string;
    clientId: string;
    clientName: string;
    brokerId: string;
    brokerName: string;
    amount: number;
    balance: number;
    currency: string;
    instrumentId: string;
    instrumentName: string;
    tradeDate: string;
    valueDate: string;
    type: string;
    description: string;
    status: string;
    error: string;
    reference: string; //TODO: for ba transactions, maybe create separate grid eventually..
    updatedByUserName: string;
}

interface ITransactionsGrid {
    items: any[];
    commands?: ICommandBarItemProps[];
    visibleColumns?: string[];
    canSelect?: boolean;
    onSelectedChange?(items: any[]): any;
    showExcelButton?: boolean;
}

// control visibility and the order of columns
const defaultVisibleColumns = [
    "client",
    "broker",
    "amount",
    "currency",
    "instrument",
    "tradeDate",
    "valueDate",
    "type",
    "description",
    "status",
    "id",
    "updatedBy"
];

export const TransactionsGrid = ({
    items,
    commands,
    visibleColumns,
    canSelect,
    onSelectedChange,
    showExcelButton = true
}: ITransactionsGrid): React.ReactElement => {
    const location = useLocation();
    const [sortedItems, setSortedItems] = React.useState(items);
    const [selectionDetails, setSelectionDetails] = React.useState("No items selected");
    const selection = new Selection({
        onSelectionChanged: () => setSelectionDetails(getSelectionDetails())
    });

    const getSelectionDetails = (): string => {
        if (onSelectedChange) {
            onSelectedChange(selection.getSelection());
        }
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return "No items selected";
            case 1:
                return "1 item selected";
            default:
                return `${selectionCount} items selected`;
        }
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = sortedColumns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = orderBy(items, currColumn.fieldName, currColumn.isSortedDescending ? "desc" : "asc");
        setSortedColumns(newColumns);
        setSortedItems(newItems);
    };

    const relativeLocation = (id: string): string => {
        const parts: string[] = location.pathname.split("/");
        const last = parts[parts.length - 1];
        if (isValidMongoDBObjectID(last)) {
            const path = location.pathname.replace(last, "");
            return `${path}${id}${location.search}`;
        } else {
            const path = location.pathname.endsWith("/") ? location.pathname : `${location.pathname}/`;
            return `${path}${id}${location.search}`;
        }
    };

    const allColumns: IColumn[] = [
        {
            key: "client",
            name: "Client",
            fieldName: "clientName",
            className: "ms-DetailsList-stickyColumn",
            headerClassName: "ms-DetailsList-stickyColumn",
            isResizable: true,
            minWidth: 200,
            maxWidth: 200,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <Link to={"/parties/" + item.clientId} target="_blank">
                        {item.clientName}
                    </Link>
                );
            }
        },
        {
            key: "broker",
            name: "Broker",
            fieldName: "brokerName",
            minWidth: 80,
            maxWidth: 120,
            isResizable: true,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <Link to={"/parties/" + item.brokerId} target="_blank">
                        {item.brokerName}
                    </Link>
                );
            }
        },
        {
            key: "amount",
            name: "Amount",
            fieldName: "amount",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.amount)}
                    </span>
                );
            }
        },
        {
            key: "currency",
            name: "Currency",
            fieldName: "currency",
            minWidth: 60,
            maxWidth: 80,
            onColumnClick: onColumnClick
        },
        {
            key: "instrument",
            name: "Instrument",
            fieldName: "instrumentName",
            minWidth: 60,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <Link to={"/instruments/" + item.instrumentId} target="_blank">
                        {item.instrumentName}
                    </Link>
                );
            }
        },
        {
            key: "tradeDate",
            name: "Trade date",
            fieldName: "tradeDate",
            minWidth: 80,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return miniTransactionTypes.find((d) => d === item.type) ? (
                    <Link to={relativeLocation(item._id)}>{item.tradeDate}</Link>
                ) : (
                    <span>{item.tradeDate}</span>
                );
            }
        },
        {
            key: "valueDate",
            name: "Value date",
            fieldName: "valueDate",
            minWidth: 80,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                if (item.valueDate < new Date().toISOString().slice(0, 10) && item.status !== TransactionStatus.Settled) {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={relativeLocation(item._id)}>
                            {
                                <span
                                    style={{
                                        color: "white",
                                        backgroundColor: "crimson"
                                    }}
                                >
                                    {item.valueDate}
                                </span>
                            }
                        </Link>
                    ) : (
                        <span
                            style={{
                                color: "white",
                                backgroundColor: "crimson"
                            }}
                        >
                            {item.valueDate}
                        </span>
                    );
                } else {
                    return miniTransactionTypes.find((d) => d === item.type) ? (
                        <Link to={relativeLocation(item._id)}>{item.valueDate}</Link>
                    ) : (
                        <span>{item.valueDate}</span>
                    );
                }
            }
        },
        {
            key: "type",
            name: "Type",
            fieldName: "type",
            minWidth: 60,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return miniTransactionTypes.find((d) => d === item.type) ? (
                    <Link to={relativeLocation(item._id)}>{item.type}</Link>
                ) : (
                    <span>{item.type}</span>
                );
            }
        },
        {
            key: "description",
            name: "Description",
            fieldName: "description",
            minWidth: 85,
            onColumnClick: onColumnClick
        },
        {
            key: "reference",
            name: "Reference",
            fieldName: "reference",
            minWidth: 140,
            onColumnClick: onColumnClick
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: 80,
            maxWidth: 100,
            onColumnClick: onColumnClick
        },
        {
            key: "id",
            name: "Id",
            fieldName: "_id",
            minWidth: 80,
            maxWidth: 100,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <Link to={"/transaction/" + item._id} target="_blank">
                        {item._id}
                    </Link>
                );
            }
        },
        {
            key: "error",
            name: "Error",
            fieldName: "error",
            minWidth: 100,
            maxWidth: 120,
            onColumnClick: onColumnClick
        },

        {
            key: "updatedBy",
            name: "Updated by",
            fieldName: "updatedByUserName",
            minWidth: 100,
            maxWidth: 120,
            onColumnClick: onColumnClick
        },
        {
            key: "balance",
            name: "Balance",
            fieldName: "balance",
            minWidth: 60,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {twoDecPriceFormat(item.balance)}
                    </span>
                );
            }
        },

        {
            key: "transactionItemId",
            name: "Transaction item id",
            fieldName: "transactionItemId",
            minWidth: 80,
            maxWidth: 200,
            onColumnClick: onColumnClick
        }
        /*{
            key: "types",
            name: "Types",
            fieldName: "types",
            minWidth: 60,
            maxWidth: 80,
            isResizable: true,
            onColumnClick: onColumnClick,
            onRender: (item: any) => {
                return <span>{item.types.join()}</span>;
            }
        }*/
    ];

    const visibleColumnKeys = visibleColumns ? visibleColumns : defaultVisibleColumns;

    const allColumnsDict = allColumns.reduce((dict, v) => ((dict[v.key] = v), dict), {});

    const columns = visibleColumnKeys.map((c) => {
        return allColumnsDict[c];
    });

    const [sortedColumns, setSortedColumns] = React.useState(columns);

    const farItems: ICommandBarItemProps[] = [
        {
            key: "download",
            text: "Download",
            ariaLabel: "Download",
            iconOnly: true,
            //iconProps: { iconName: "ExcelDocument" },
            onRenderIcon: () => <Excel style={{ maxWidth: 24, maxHeight: 24 }} />,
            onClick: () => {
                const randomString = Math.random().toString(36).slice(2);
                const filename = snakeCase(randomString);
                exportToXlsx2(sortedItems, `${filename}.xlsx`);
            }
        }
    ];

    // update selected count
    if (canSelect) {
        farItems.unshift({
            key: "selected",
            text: selectionDetails
        });
    }

    return sortedItems.length > 0 ? (
        <div>
            <CommandBar items={commands || []} farItems={showExcelButton ? farItems : null} />
            {canSelect ? (
                <MarqueeSelection selection={selection}>
                    <DetailsList
                        columns={sortedColumns}
                        items={sortedItems}
                        setKey="set"
                        selectionMode={SelectionMode.multiple}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                        compact={true}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        checkboxVisibility={CheckboxVisibility.onHover}
                    />
                </MarqueeSelection>
            ) : (
                <DetailsList
                    columns={sortedColumns}
                    items={sortedItems}
                    setKey="set"
                    compact={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                />
            )}
        </div>
    ) : (
        <div>No data to display.</div>
    );
};
