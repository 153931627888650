import React, { Fragment, useState, useContext, useEffect, useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import Plotly from "plotly.js-finance-dist"; // this is the plotly we use
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import stableStringify from "json-stable-stringify";

import { emptyObjectId, numberFormat } from "../../../common/src";
import { Grid, Column, PlotlyDefaults } from "../../../components/src";

import { Page } from "../components/Page";
import { useQueryState } from "../../../components/src/use-query-state";
import { ClientContextSelector } from "../contexts/ClientContextSelector";
import { ClientContext } from "../contexts/ClientContext";
import { PartyType } from "../types.generated";
import Plot from "react-plotly.js";

const GET_LIMIT_REPORT = gql`
    query report($clientId: GraphQLObjectId!, $date: GraphQLDateString) {
        party(_id: $clientId) {
            name
        }

        LimitReportV3: reports(clientId: $clientId, type: "LimitReportV3", name: "LimitReportV3", date: $date, lastOnly: true) {
            _id
            date
            type
            createTimestamp
            data
        }

        VaRLimitReport: reports(clientId: $clientId, type: "LimitReportData", name: "VaRLimitReport", date: $date, lastOnly: true) {
            _id
            date
            type
            createTimestamp
            data
        }
    }
`;

const linkFormater = (link, item) => {
    return (
        <div>
            {link ? (
                <a href={link} rel="noopener noreferrer">
                    {item}
                </a>
            ) : null}
        </div>
    );
};

const statusFormatter = (status) => {
    let color;
    if (status === "Ok") {
        color = "success";
    } else if (status === "Warning") {
        color = "warning";
    } else {
        color = "danger";
    }
    return (
        <div>
            <Badge bg={color}>{status}</Badge>
        </div>
    );
};

export const LimitBarPlot = (
    data: Partial<Plotly.PlotData>[],
    layout: Partial<Plotly.Layout>,
    config: Partial<Plotly.Config>
): React.ReactElement => {
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    // tickformat: "%"
    const localLayout: Partial<Plotly.Layout> = {
        showlegend: false,
        title: "Cumulative Risk Class Contribution",
        yaxis: { title: "Percent of Total", tickformat: "%" }
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.getDefaultConfig();

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return <Plot data={data as Plotly.PlotData[]} config={thisConfig as Plotly.Config} layout={thisLayout} useResizeHandler={true} />;
};

const formatFunction = (d: any) =>
    typeof d === "number" ? numberFormat(Number(d), Math.abs(d) > 0.995 ? "0%" : "0,0%") : typeof d === "string" ? d : "";

const parseData = (
    limitRows: any[],
    reportDate: string,
    varReport: { date: any; data: string | any[] },
    party: { name: string }
): { date: string; name: string; rows: ReportRow[] } => {
    const rows = [];

    limitRows.forEach((row) => {
        rows.push({ ...row.limit, ...row.result });
    });

    if (varReport) {
        if (reportDate === varReport.date) {
            if (Array.isArray(varReport.data) && varReport.data.length) {
                const row = {
                    name: "Value at Risk",
                    type: "VaR",
                    value: varReport.data[0].vaR,
                    url: "https://en.wikipedia.org/wiki/Value_at_risk",
                    comment: "VaR",
                    maxValue: 0.03
                };
                // Special case for Iris
                if (varReport.data.length === 2 && party.name === "Captor Iris Bond") {
                    row.name = "Value at Risk vs comparison index";
                    row.type = "Relative VaR";
                    row.value = varReport.data[0].vaR / varReport.data[1].vaR;
                    row.maxValue = 1.3;
                    row.url = "https://captor.se/wp-content/uploads/2019/01/Captor-Iris-Bond-Fondbest%C3%A4mmelser-20190201.pdf";
                    row.comment = "Fund Specific";
                }
                // Special case for Asters
                if (
                    varReport.data.length === 1 &&
                    (party.name === "Captor Aster Global Credit" ||
                        party.name === "Captor Aster Global Credit Short Term" ||
                        party.name === "Captor Aster Global High Yield" ||
                        party.name === "Captor Global Fixed Income" ||
                        party.name === "Captor Perenne Short Term Bond")
                ) {
                    row.value = Math.abs(varReport.data[0].vaR / varReport.data[0].thVal);
                }
                // Max var different for Bliwa
                if (party.name === "Bliwa Liv") row.maxValue = 0.09;
                if (party.name === "Bliwa Skade") row.maxValue = 0.07;
                rows.push(row);
            }
        }
    }

    return {
        date: reportDate,
        name: party.name,
        rows: rows
    };
};

type ReportRow = {
    name: string;
    type?: string;
    status?: string;
    value: number | string;
    minValue?: string;
    maxValue?: string;
    minValueWarning?: string | number;
    maxValueWarning?: string | number;
    url?: string;
    comment?: string;
    valueField?: string;
};

type ReportType = {
    name: string;
    header: string;
    minValueWarningExist: boolean;
    maxValueWarningExist: boolean;
    rows: ReportRow[];
    plot?: any;
};

export const Limits = ({ clientId }: { clientId: string }): React.ReactElement => {
    const [endDate, setDateQueryArg] = useQueryState("endDate", "2000-01-01");
    const date = endDate === "2000-01-01" ? null : endDate;

    const { tabId } = useParams<"tabId">();
    const { pathname } = useLocation();

    const [variables, setVariables] = useState<{ clientId: string; date: string }>(null);

    const [{ fetching, error, data }] = useQuery({
        query: GET_LIMIT_REPORT,
        requestPolicy: "cache-and-network",
        variables: variables,
        pause: variables ? false : true
    });

    useEffect(() => {
        if (stableStringify({ clientId, date }) !== stableStringify(variables)) {
            setVariables({ clientId, date });
        }
    }, [clientId, date, variables]);

    useEffect(() => {
        if (!date && data && "LimitReportV3" in data && data.LimitReportV3.length > 0) {
            //Only set query args in relevant tab or if on limit page
            if (
                date !== data.LimitReportV3[0].date &&
                ((tabId && tabId === "limits" && pathname.includes("portfoliorisk")) || !pathname.includes("portfoliorisk"))
            ) {
                setDateQueryArg(data.LimitReportV3[0].date);
            }
        }
    }, [data, date, pathname, setDateQueryArg, tabId]);

    const limitReportInfo = useMemo(() => {
        const defaultReportData: ReportType = {
            name: "",
            header: "",
            minValueWarningExist: false,
            maxValueWarningExist: false,
            rows: [],
            plot: null
        };
        const limitReportInfo = cloneDeep(defaultReportData);

        if (!data || !data.LimitReportV3 || data.LimitReportV3.length === 0) return limitReportInfo;

        const report = data.LimitReportV3[0];

        let limitRows = [];
        if (Array.isArray(report.data)) {
            limitRows = report.data;
        } else {
            if ("limit" in report.data) {
                limitRows = report.data["limit"];
            }
            if ("plot" in report.data) {
                limitReportInfo.plot = report.data["plot"];
            }
        }

        let varLimitReport = null;
        if (data.VaRLimitReport && data.VaRLimitReport.length > 0) {
            varLimitReport = data.VaRLimitReport[0];
        }

        const { name, rows } = parseData(limitRows, report.date, varLimitReport, data.party);
        limitReportInfo.name = name;
        limitReportInfo.rows = rows;
        limitReportInfo.header = "Limit report for " + data.party.name;

        // loop limits to set format
        for (const row of rows) {
            if (row.minValueWarning) limitReportInfo.minValueWarningExist = true;
            if (row.maxValueWarning) limitReportInfo.maxValueWarningExist = true;
            // not bulletproof but for now
            if (row.valueField === "interestRateYieldDelta") {
                if (row.value) row.value = numberFormat(Number(row.value), "#\xa0##0.0");
                if (row.minValueWarning) row.minValueWarning = numberFormat(Number(row.minValueWarning), "#\xa0##0.0");
                if (row.maxValueWarning) row.maxValueWarning = numberFormat(Number(row.maxValueWarning), "#\xa0##0.0");
                if (row.minValue) row.minValue = numberFormat(Number(row.minValue), "#\xa0##0.0");
                if (row.maxValue) row.maxValue = numberFormat(Number(row.maxValue), "#\xa0##0.0");
            }
        }

        return limitReportInfo;
    }, [data]);

    if (fetching) return <div>Loading...</div>;
    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (!data) {
        return <div></div>;
    }

    if (data.LimitReportV3.length === 0) {
        if (variables && variables.clientId && variables.clientId !== emptyObjectId) {
            if (data.LimitReportV3.length === 0) {
                return <Page header="Could not find a report" />;
            }
        } else {
            return <Page header="" />;
        }
    }

    let thisLayout = PlotlyDefaults.getDefaultLayout();
    let thisConfig = PlotlyDefaults.getDefaultConfig();

    if (limitReportInfo.plot) {
        if (limitReportInfo.plot.layout) {
            thisLayout = PlotlyDefaults.mergeLayout(thisLayout, limitReportInfo.plot.layout);
        }
        if (limitReportInfo.plot.config) {
            thisConfig = PlotlyDefaults.mergeConfig(thisConfig, limitReportInfo.plot.config);
        }
    }

    return (
        <Page header={limitReportInfo.name}>
            <Fragment>
                <Link to={"/report/" + data.LimitReportV3[0]._id}>
                    <div>Date: {data.LimitReportV3[0].date}</div>
                </Link>
                <div className="card card-body bg-light mt-5">
                    <div className="bg-white px-3 py-2">
                        {limitReportInfo.rows && (
                            <Grid header={limitReportInfo.header} data={limitReportInfo.rows}>
                                <Column field="name" className="grid-column-sticky" />
                                <Column
                                    field="status"
                                    format={(status) => {
                                        return statusFormatter(status);
                                    }}
                                />
                                {limitReportInfo.minValueWarningExist ? (
                                    <Column field="minValueWarning" format={formatFunction} className="nowrap center" />
                                ) : null}
                                <Column field="minValue" format={formatFunction} className="nowrap center" />
                                <Column field="value" format={formatFunction} className="nowrap center" />
                                <Column field="maxValue" format={formatFunction} className="nowrap center" />
                                {limitReportInfo.maxValueWarningExist ? (
                                    <Column field="maxValueWarning" format={formatFunction} className="nowrap center" />
                                ) : null}
                                <Column
                                    className="nowrap"
                                    field="comment"
                                    format={(comment, item) => {
                                        return linkFormater(item.url, comment);
                                    }}
                                />
                            </Grid>
                        )}
                    </div>
                </div>
                {limitReportInfo.plot ? (
                    <div className="card card-body bg-light mt-5">
                        <Plot
                            data={limitReportInfo.plot.data as Plotly.PlotData[]}
                            config={thisConfig as Plotly.Config}
                            layout={thisLayout}
                            useResizeHandler={true}
                        />
                    </div>
                ) : null}
            </Fragment>
        </Page>
    );
};

export const LimitV3Page = (): React.ReactElement => {
    const { client } = useContext(ClientContext);

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <ClientContextSelector typeIn={[PartyType.Client]} />
                </div>
            </div>
            {client ? <Limits clientId={client._id} /> : null}
            <div className="row mt-5">
                <div className="col-4"></div>
            </div>
        </div>
    );
};
