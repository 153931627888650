import React, { useMemo } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";

import { SelectColumnFilter } from "../../../../components/src/react-table/ReactTableFilters";
import { ReactTable } from "../../../../components/src/react-table/ReactTable";
import { sortBy } from "lodash";

const GET_REPORTS = gql`
    query {
        reports(type: "AnnualReport") {
            _id
            name
            status
            clientId
            clientName
            date
            data
            type
            updateUserId
            updateUserInfo {
                name
            }
            createTimestamp
            updateTimestamp
        }
    }
`;

export const AnnualReportsPage = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({
        query: GET_REPORTS,
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/reporting/annualreports/${row.original._id}`}>{"#"}</Link>;
                },
                size: 15
            },
            {
                header: "Name",
                accessorKey: "name",
                size: 150
            },
            {
                header: "Date",
                accessorKey: "date",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "Client name",
                accessorKey: "clientName",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.clientId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 120
            },
            {
                header: "Type",
                accessorKey: "data.AnnualReport.semiAnnual",
                cell: (cellProps) => {
                    if (cellProps.getValue()) {
                        return "Semi annual";
                    } else return "Annual";
                },
                filter: SelectColumnFilter
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilter
            },
            {
                header: "Create timestamp",
                accessorKey: "createTimestamp",
                size: 90
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp",
                size: 90
            },
            {
                header: "Update user",
                accessorKey: "updateUserInfo.name",
                filter: SelectColumnFilter
            }
        ],
        []
    );

    const list: any[] = React.useMemo(() => {
        let list: any[] = [];
        if (data) {
            list = sortBy(data.reports, ["date"]).reverse();
        }
        return list;
    }, [data]);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <p>Number of documents: {list.length}</p>
            <ReactTable columns={columns} data={list} defaultHiddenColumns={null} />
        </div>
    );
};
