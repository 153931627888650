import React, { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import stableStringify from "json-stable-stringify";
import { sortBy } from "lodash";
import { useQueryState } from "../../../../../components/src/use-query-state";

import { DateHelper, emptyObjectId, SwedenCalendar } from "../../../../../common/src";

import { FinancialStatementsTab } from "./tabs/FinancialStatementsTab";
import { usePrevious, useQueryArgs } from "../../../../../components/src/common/Utils";
import {
    AccountingCompanyTypeEnum,
    CurrencyEnum,
    Instrument,
    PartyType,
    PermissionAssetEnum,
    TransactionStatus,
    PositionSelectorType,
    TradingManagerColumn
} from "../../../types.generated";

import { ReconciliationTab } from "./tabs/Reconciliation";
import { ISelectDropdownOption, SelectDropdown } from "../../../components/SelectDropdown";
import { SYSTEM_PARTY_ID } from "../../../Constants";
import {
    useGetClientsQuery,
    useGetAccountingRunsWithJournalEntriesQuery,
    GetAccountingRunsWithJournalEntriesQuery,
    useGetTAccountChartsQuery,
    useGetPositionsQuery,
    GetClientsQuery,
    useGetAccountingRunsQuery
} from "../queries.generated";
import { CustomAutosuggest } from "../../../../../components/src/form";

export type ClientOption = {
    value: string;
    label: string;
    _id: string;
    instruments: Instrument[];
    accountingCurrency: CurrencyEnum;
    accountingCompanyType: AccountingCompanyTypeEnum;
    types: PartyType[];
};

const allTabs = {
    financialstatements: "Financial statements",
    reconciliation: "Reconciliation"
};

const defaultClient = {
    value: emptyObjectId,
    label: "None",
    _id: emptyObjectId,
    instruments: [],
    accountingCurrency: CurrencyEnum.SEK,
    accountingCompanyType: null,
    types: []
};

export function StatementsReportingPage(): React.ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const { tabId } = useParams<"tabId">();
    const { queryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);
    const today = new Date().toISOString().slice(0, 10);
    const [date, setDate] = useQueryState("date", today);
    const [clientId, setClientId] = useQueryState("clientId", emptyObjectId);
    const [activeTabId, setActiveTabId] = useState(tabId);
    const [tabs, setTabs] = useState<Record<string, string>>(allTabs);
    const [selectedClient, setSelectedClient] = useState(defaultClient);
    const [dates, setDates] = useState([
        {
            key: today,
            text: today
        }
    ]);
    const [extendDates, setExtendDates] = useState(false);

    const [selectedAccountingRun, setSelectedAccountingRun] = useState(null);
    const [yearAccountingRuns, setYearAccountingRuns] = useState([]);
    const [latestTAccountChart, setLatestTAccountChart] = useState(null);
    const [previousAccountingRuns, setPreviousAccountingRuns] = useState([]);

    const dateOptionsLimit = 20;

    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useGetClientsQuery({
        requestPolicy: "cache-and-network"
    });
    const [{ data: dataTAccountCharts, fetching: loadingTAccountCharts, error: errorTAccountCharts }] = useGetTAccountChartsQuery({
        variables: { clientId },
        pause: !clientId || clientId === emptyObjectId
    });

    const [{ fetching: loadingAccountingRuns, error: errorAccountingRuns, data: getAccountingRunsData }] =
        useGetAccountingRunsWithJournalEntriesQuery({
            pause: !clientId || clientId === emptyObjectId,
            variables: {
                clientId,
                accountingPeriod: date.substring(0, 4),
                previousAccountingPeriod: (parseFloat(date.substring(0, 4)) - 1).toString()
            },
            requestPolicy: "cache-and-network"
        });

    const [{ fetching: loadingAllAccountingRuns, error: errorAllAccountingRuns, data: getAllAccountingRunsData }] =
        useGetAccountingRunsQuery({
            pause: !clientId || clientId === emptyObjectId,
            variables: { clientId },
            requestPolicy: "cache-and-network"
        });

    //Positions at end of previous year
    const [{ fetching: loadingPreviousPositions, error: errorPreviousPositions, data: dataPreviousPositions }] = useGetPositionsQuery({
        variables: {
            filter: {
                endDate: previousAccountingRuns.length > 0 ? previousAccountingRuns[previousAccountingRuns.length - 1].endDate : null,
                clientIds: [clientId],
                accountIds: null,
                statusIn: [
                    TransactionStatus.Confirmed,
                    TransactionStatus.Instructed,
                    TransactionStatus.Pending,
                    TransactionStatus.Preliminary,
                    TransactionStatus.Settled
                ]
            },
            groupPositionsBy: PositionSelectorType.ExternalAccountId
        },
        pause: !clientId || clientId === emptyObjectId
    });

    const [{ fetching: loadingPositions, error: errorPositions, data: dataPositions }] = useGetPositionsQuery({
        variables: {
            filter: {
                endDate: date,
                clientIds: [clientId],
                accountIds: null,
                statusIn: [
                    TransactionStatus.Confirmed,
                    TransactionStatus.Instructed,
                    TransactionStatus.Pending,
                    TransactionStatus.Preliminary,
                    TransactionStatus.Settled
                ]
            },
            groupPositionsBy: PositionSelectorType.ExternalAccountId
        },
        pause: !clientId || clientId === emptyObjectId || !date
    });

    const previousAccountingRunsData = usePrevious(getAccountingRunsData);

    let previousQuarter = new Date(new Date(date).getFullYear(), new Date(date).getMonth() - 2, 1);
    // Adjusting to prev business date if needed
    const swedenCalendar = new SwedenCalendar();
    if (!swedenCalendar.isBusinessDay(previousQuarter)) {
        while (!swedenCalendar.isBusinessDay(previousQuarter)) {
            previousQuarter = DateHelper.dateAddDays(previousQuarter, -1);
        }
    }

    useEffect(() => {
        if (dataClients) {
            const availableClientIds = dataClients.clients.map((client) => client._id);
            if (clientId !== emptyObjectId) {
                if (!availableClientIds.includes(clientId)) {
                    setClientId(null);
                } else if (!selectedClient || selectedClient._id !== clientId) {
                    for (let i = 0; i < dataClients.clients.length; i++) {
                        const client = dataClients.clients[i];
                        if (clientId === client._id && clientId !== emptyObjectId) {
                            setSelectedClient({
                                value: client._id,
                                label: client.name,
                                _id: client._id,
                                instruments: client.instruments,
                                accountingCurrency: client.accountingCurrency as CurrencyEnum,
                                accountingCompanyType: client.accountingCompanyType,
                                types: client.types
                            });

                            break;
                        }
                    }
                }

                if (
                    selectedClient &&
                    selectedClient._id !== emptyObjectId &&
                    getAccountingRunsData &&
                    (getAccountingRunsData.accountingRuns.length || getAccountingRunsData.previousAccountingRuns.length) &&
                    getAllAccountingRunsData &&
                    !loadingAccountingRuns &&
                    !loadingAllAccountingRuns &&
                    (getAccountingRunsData.accountingRuns.length || getAccountingRunsData.previousAccountingRuns.length) &&
                    (!selectedAccountingRun ||
                        (selectedAccountingRun &&
                            (selectedAccountingRun.client._id !== selectedClient._id || selectedAccountingRun.endDate !== date)) ||
                        stableStringify(previousQueryArgs) !== stableStringify(queryArgs))
                ) {
                    const availableDatesByDate: Record<string, { key: string; text: string }> = {};
                    const previousRuns: Array<GetAccountingRunsWithJournalEntriesQuery["accountingRuns"][0]> = [];
                    const currentRuns: Array<GetAccountingRunsWithJournalEntriesQuery["accountingRuns"][0]> = [];
                    const accountingRunsByDate: Record<string, GetAccountingRunsWithJournalEntriesQuery["accountingRuns"][0]> = {};

                    const accountingRunsWithJournalEntries =
                        getAccountingRunsData.previousAccountingRuns && getAccountingRunsData.previousAccountingRuns.length
                            ? getAccountingRunsData.accountingRuns.concat(getAccountingRunsData.previousAccountingRuns)
                            : getAccountingRunsData.accountingRuns;

                    for (const accountingRun of getAllAccountingRunsData.accountingRuns) {
                        if (accountingRun.endDate) {
                            availableDatesByDate[accountingRun.endDate] = { key: accountingRun.endDate, text: accountingRun.endDate };
                        }
                    }

                    for (const accountingRun of accountingRunsWithJournalEntries) {
                        if (accountingRun.endDate) {
                            accountingRunsByDate[accountingRun.endDate] = accountingRun;

                            if (
                                accountingRun.endDate &&
                                accountingRun.endDate.substring(0, 4) === parseFloat(date.substring(0, 4)).toString() &&
                                accountingRun.endDate <= date
                            ) {
                                currentRuns.push(accountingRun);
                            }
                            if (
                                accountingRun.endDate &&
                                accountingRun.endDate.substring(0, 4) === (parseFloat(date.substring(0, 4)) - 1).toString()
                            ) {
                                previousRuns.push(accountingRun);
                            }
                        }
                    }

                    const availableDatesSorted = Object.values(availableDatesByDate).sort(function (a, b) {
                        return new Date(b.key).getTime() - new Date(a.key).getTime();
                    });

                    setDates(Object.values(availableDatesSorted));

                    if (!date || !availableDatesByDate[date]) {
                        const endDate =
                            queryArgs && queryArgs.date && availableDatesByDate[queryArgs.date as string]
                                ? (queryArgs.date as unknown as string)
                                : availableDatesSorted[0].key;

                        setDate(endDate);
                    }

                    //When reloading page after switching to extended mode with search field for dates
                    if (
                        !extendDates &&
                        availableDatesSorted.length > dateOptionsLimit &&
                        !availableDatesSorted.slice(0, dateOptionsLimit).some((dateOption) => dateOption.key === date)
                    ) {
                        setDate(availableDatesSorted[0].key);
                    }

                    if (accountingRunsByDate[date]) {
                        setSelectedAccountingRun(accountingRunsByDate[date]);
                    }
                    setPreviousAccountingRuns(previousRuns);
                    setYearAccountingRuns(currentRuns);
                }
            }
        }
        if (
            selectedClient &&
            dataTAccountCharts &&
            dataTAccountCharts.tAccountCharts &&
            (!latestTAccountChart || latestTAccountChart.client._id !== selectedClient._id)
        ) {
            const sortedTAccountCharts = sortBy(dataTAccountCharts.tAccountCharts, "version");
            setLatestTAccountChart(sortedTAccountCharts[sortedTAccountCharts.length - 1]);
        }
    }, [
        clientId,
        dataClients,
        dataTAccountCharts,
        date,
        extendDates,
        getAccountingRunsData,
        getAllAccountingRunsData,
        latestTAccountChart,
        previousAccountingRunsData,
        previousQueryArgs,
        queryArgs,
        selectedAccountingRun,
        selectedClient,
        loadingAccountingRuns,
        loadingAllAccountingRuns,
        setClientId,
        setDate
    ]);

    useEffect(() => {
        if (!(activeTabId in tabs)) {
            setActiveTabId("financialstatements");
            navigate("/financialstatements/financialstatements" + location.search, { replace: true });
        }
    }, [setActiveTabId, clientId, activeTabId, navigate, location.search, tabs, tabId]);

    const onTabChange = useCallback(
        (tabId) => {
            if (!(tabId in tabs)) {
                setActiveTabId("financialstatements");
                navigate("/financialstatements/financialstatements" + location.search, { replace: true });
            } else {
                if (tabId !== activeTabId) {
                    setActiveTabId(tabId);
                    navigate("/financialstatements/" + tabId + location.search, { replace: true });
                }
            }
        },
        [tabs, navigate, location.search, activeTabId]
    );

    if (loadingClients) return <p>Loading clients</p>;
    if (errorClients) return <p>errorClients: {JSON.stringify(errorClients, null, 2)}</p>;
    if (loadingAccountingRuns || loadingAllAccountingRuns) return <p>Loading accounting runs</p>;
    if (errorAccountingRuns) return <p>errorAccountingRuns: {JSON.stringify(errorAccountingRuns, null, 2)}</p>;
    if (errorAllAccountingRuns) return <p>errorAllAccountingRuns: {JSON.stringify(errorAllAccountingRuns, null, 2)}</p>;
    if (loadingPositions || loadingPreviousPositions)
        return (
            <div className="loader">
                <p>Loading positions</p>
            </div>
        );
    if (loadingTAccountCharts) return <p>Loading tAccountCharts</p>;
    if (errorPositions)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(errorPositions, null, 2)}</pre>
            </div>
        );
    if (errorPreviousPositions)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(errorPreviousPositions, null, 2)}</pre>
            </div>
        );

    if (errorTAccountCharts) return <p>error tAccountCharts: {JSON.stringify(errorTAccountCharts, null, 2)}</p>;

    let options: ClientOption[] = [];

    //Check access client combined with asset accounting + transaction
    const accessClients: Array<GetClientsQuery["clients"][0]> = [];
    if (dataClients && dataClients.clients) {
        if (dataClients.me && dataClients.me.roles) {
            let access = false;
            for (const client of dataClients.clients) {
                if (dataClients.me.frontendRole.assets.length === 0) {
                    access = true;
                } else {
                    for (const role of dataClients.me.roles) {
                        if (
                            role.assets.includes(PermissionAssetEnum.Accounting) &&
                            role.assets.includes(PermissionAssetEnum.Transaction) &&
                            role.clientIds.includes(client._id)
                        ) {
                            access = true;
                        }
                    }
                }
                // Exclude system party from client selection menu
                if (access && client._id !== SYSTEM_PARTY_ID) {
                    accessClients.push(client);
                }
            }
        }
    }

    accessClients.forEach((client) => {
        if (client.instruments && client.instruments.length > 0) {
            options.push({
                value: client._id,
                label: client.name,
                _id: client._id,
                instruments: client.instruments as Instrument[], // Danger
                accountingCurrency: client.accountingCurrency as unknown as CurrencyEnum,
                accountingCompanyType: client.accountingCompanyType,
                types: client.types
            });
        }
    });
    options = sortBy(options, "label");

    const clients: ISelectDropdownOption[] = [
        ...[{ key: emptyObjectId, text: "None", data: defaultClient }],
        ...options.map((client) => {
            return { key: client._id, text: client.label, data: client };
        })
    ];

    const datesOptions: ISelectDropdownOption[] =
        dates.length > dateOptionsLimit ? [...dates.slice(0, dateOptionsLimit), ...[{ key: "...more", text: "...more" }]] : [...dates];

    return (
        <div>
            <div className="row">
                <div className="card card-body bg-light ">
                    <h5>Client</h5>
                    <div className="row">
                        <div className="col-12 col-sm-3">
                            <SelectDropdown
                                placeholder="None"
                                className="form-control form-select"
                                defaultValue={selectedClient ? selectedClient._id : null}
                                options={clients}
                                onChange={(e: ISelectDropdownOption) => {
                                    setSelectedClient(e.data);
                                    setActiveTabId(null);
                                    setClientId(e.data._id);
                                    setTabs(allTabs);
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <h5>Date</h5>
                    <div className="row">
                        <div className="col-12 col-sm-3">
                            {!extendDates ? (
                                <SelectDropdown
                                    placeholder="None"
                                    className="width-auto form-control form-select"
                                    defaultValue={date}
                                    options={datesOptions}
                                    onChange={(e: ISelectDropdownOption) => {
                                        if (e) {
                                            if (e.text === "...more") {
                                                setExtendDates(true);
                                            } else {
                                                setDate(e.key);
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <CustomAutosuggest
                                    className={""}
                                    selected={{ _id: date, name: date }}
                                    values={dates.map((date) => {
                                        return { _id: date.key, name: date.key };
                                    })}
                                    toDisplayText={(d) => d.name}
                                    toSuggestionText={(d) => d.name}
                                    onChange={(option) => {
                                        setDate(option === null ? today : option._id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <br />
                    <Tabs onSelect={onTabChange} activeKey={activeTabId} transition={false}>
                        {"financialstatements" in tabs ? (
                            <Tab eventKey="financialstatements" title={tabs["financialstatements"]}>
                                <FinancialStatementsTab
                                    client={selectedClient}
                                    date={date}
                                    selectedAccountingRun={selectedAccountingRun}
                                    accountingRuns={yearAccountingRuns}
                                    positions={
                                        dataPositions && dataPositions.tradingmanager
                                            ? (dataPositions.tradingmanager as TradingManagerColumn[])
                                            : []
                                    }
                                    previousPositions={
                                        dataPreviousPositions && dataPreviousPositions.tradingmanager && previousAccountingRuns.length
                                            ? (dataPreviousPositions.tradingmanager as TradingManagerColumn[])
                                            : []
                                    }
                                    latestTAccountChart={latestTAccountChart}
                                />
                            </Tab>
                        ) : null}

                        {"reconciliation" in tabs ? (
                            <Tab eventKey={"reconciliation"} title={tabs["reconciliation"]}>
                                <ReconciliationTab
                                    selectedAccountingRun={selectedAccountingRun}
                                    accountingRuns={yearAccountingRuns}
                                    partyTypes={selectedClient ? selectedClient.types : []}
                                    positions={
                                        dataPositions && dataPositions.tradingmanager
                                            ? (dataPositions.tradingmanager as TradingManagerColumn[])
                                            : []
                                    }
                                    latestTAccountChart={latestTAccountChart}
                                    client={selectedClient}
                                />
                            </Tab>
                        ) : null}
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
