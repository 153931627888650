import React, { Fragment } from "react";

import { fourDecPriceFormat, numberFormat, numberFormatFun } from "../../../../common/src";
import { Grid, Column } from "../../../../components/src";
import { round } from "lodash";
import { Link } from "react-router-dom";
import { TooltipLabel } from "../../components/TooltipLabel";
import { ReconciledExposureCollateralType } from "../../types.generated";

interface SecuritiesCollateralGridInput {
    header: string;
    data: ReconciledExposureCollateralType[];
    endDate: string;
}
export const SecuritiesCollateralGrid = ({ header, data, endDate }: SecuritiesCollateralGridInput): React.ReactElement => {
    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <Grid header={header} data={data} sortable tableClassName="table-xs">
                        <Column
                            field="name"
                            className="grid-column-sticky nowrap maxwidth150px left"
                            format={(__, item) => {
                                return item && item.instrumentId && item.instrument.name ? (
                                    <Link to={"/instruments/" + item.instrumentId}>{item.instrument.name}</Link>
                                ) : (
                                    item.name
                                );
                            }}
                        />
                        <Column
                            field="isin"
                            format={(_, item) => {
                                return item && item.instrument && item.instrument.isin ? (
                                    <Link to={"/instruments/" + item.instrumentId}>{item.instrument.isin}</Link>
                                ) : (
                                    ""
                                );
                            }}
                        />
                        <Column field="currency" title="Ccy" />
                        <Column field="quantity" title="Our qty" format={numberFormatFun("# ##0")} className={"right"} />
                        <Column
                            field="counterpartyQuantity"
                            title="Cpty qty"
                            format={(value, item) => {
                                if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                    return "Missing report";
                                } else {
                                    return numberFormat(Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field="quantityDifference"
                            title={<TooltipLabel text={"Difference"} content="Our quantity - Cpty quantity" />}
                            format={(value, item) => {
                                if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                    return "-";
                                } else if (
                                    item &&
                                    item.name !== "Sum" &&
                                    item.name !== "Sum base ccy" &&
                                    round(item.quantityDifference) !== 0
                                ) {
                                    return (
                                        <TooltipLabel
                                            text={numberFormat(Number(value), "# ##0")}
                                            content="Difference tolerance is 0, please check."
                                        />
                                    );
                                } else {
                                    return numberFormat(Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (
                                    d &&
                                    d.name !== "Sum" &&
                                    d.name !== "Sum base ccy" &&
                                    round(d.quantityDifference) !== 0 &&
                                    !d.missingReport
                                ) {
                                    return "right nowrap btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field={"haircut"}
                            title="Our haircut"
                            format={(value, item) => {
                                if (item && (item.name === "Sum" || item.name === "Sum base ccy")) {
                                    return "";
                                } else {
                                    return fourDecPriceFormat(Number(value));
                                }
                            }}
                            className={"right"}
                        />
                        <Column
                            field={"counterpartyHaircut"}
                            title="Cpty haircut"
                            format={(value, item) => {
                                if (item && (item.name === "Sum" || item.name === "Sum base ccy")) {
                                    return "";
                                } else if (item && item.missingReport) {
                                    return "Missing report";
                                } else {
                                    return fourDecPriceFormat(Number(value));
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                    return "right nowrap btn-sm btn-info";
                                } else {
                                    return "right";
                                }
                            }}
                        />

                        <Column
                            field={"haircutDifference"}
                            title="Difference"
                            format={(value, item) => {
                                if (item && (item.name === "Sum" || item.name === "Sum base ccy")) {
                                    return "";
                                } else if (item && item.missingReport) {
                                    return "-";
                                } else {
                                    return fourDecPriceFormat(Number(value));
                                }
                            }}
                            className={(d) => {
                                if (
                                    d &&
                                    d.haircutDifference &&
                                    Number(d.haircutDifference) !== 0 &&
                                    d.name !== "Sum" &&
                                    d.name !== "Sum base ccy"
                                ) {
                                    return "right btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />

                        <Column
                            field="exposure"
                            title={<TooltipLabel text={"Our valuation"} content="Our valuation. Includes haircut." />}
                            format={numberFormatFun("# ##0")}
                            className={"right"}
                        />
                        <Column
                            field="counterpartyExposure"
                            title={<TooltipLabel text={"Cpty valuation"} content="Counterparty valuation. Includes haircut." />}
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "Missing report";
                                } else if (item && !item.counterpartyExposure) {
                                    return (
                                        <TooltipLabel
                                            text={numberFormat(-Number(value), "# ##0")}
                                            content="Counterparty value is missing, please check."
                                        />
                                    );
                                } else {
                                    return numberFormat(-Number(value), "# ##0");
                                }
                            }}
                            className={(d) => {
                                if (d && d.missingReport) {
                                    return "right nowrap btn-sm btn-info";
                                } else if (d && !d.counterpartyExposure) {
                                    return "right btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field="exposureDifference"
                            title={<TooltipLabel text={"Difference"} content="Our valuation - Cpty valuation" />}
                            format={(value, item) => {
                                if (item && item.missingReport) {
                                    return "-";
                                } else {
                                    return numberFormat(Number(value), "# ##0");
                                }
                            }}
                            className={
                                "right" /*(d) => {
                                if ((d && Math.abs(d.exposureDifference) > 1000000) || (d && !d.exposureDifference && d.exposureDifference !== 0)) {
                                   return "right btn-sm btn-danger text-white";
                                } else {
                                return "right";
                                }
                            }*/
                            }
                        />
                        <Column
                            field="valuationDate"
                            title="Our valuation date"
                            format={(value, item) => {
                                if (item && item.valuationDate !== endDate && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                    return (
                                        <TooltipLabel
                                            text={value}
                                            content="Valuation date does not match with chosen end date, please check."
                                        />
                                    );
                                } else {
                                    return value;
                                }
                            }}
                            className={(d) => {
                                if (d && d.valuationDate !== endDate && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                    return "right btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                        <Column
                            field="counterpartyValuationDate"
                            title="Cpty valuation date"
                            format={(value, item) => {
                                if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                    return "Missing report";
                                } else if (
                                    item &&
                                    item.counterpartyValuationDate !== endDate &&
                                    item.name !== "Sum" &&
                                    item.name !== "Sum base ccy"
                                ) {
                                    return (
                                        <TooltipLabel
                                            text={value}
                                            content="Cpty valuation date does not match with chosen end date, please check."
                                        />
                                    );
                                } else {
                                    return value;
                                }
                            }}
                            className={(d) => {
                                if (d && d.name !== "Sum" && d.name !== "Sum base ccy" && d.missingReport) {
                                    return "right nowrap btn-sm btn-info";
                                } else if (d && d.counterpartyValuationDate !== endDate && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                    return "right btn-sm btn-danger text-white";
                                } else {
                                    return "right";
                                }
                            }}
                        />
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};
