import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Column } from "../../../../components/src";

import { usePrevious } from "../../../../components/src/common/Utils";
import { useGetAgreementsQuery } from "../../containers/AgreementsPage.generated";
import { Agreement, AgreementStatusEnum, AgreementFilterInput, ApprovedStatusEnum } from "../../types.generated";
import { Link } from "react-router-dom";

// Arrange data from raw agreements data into format to display
const arrangeAgreementsData = (data: [Agreement]) => {
    const agreementTypesList: string[] = [];
    data.forEach((item) => {
        // Only keep types which has some counterparty (for example ssi has no counterparty)
        if (item.counterPartyIds.length !== 0) {
            agreementTypesList.push(item.type);
        }
    });
    const uniqueAgreementTypesSorted = [...new Set(agreementTypesList)].sort();

    const newData: any[] = []; // unclear how to type the items and be able to keep the agreement types as properties
    data.forEach((item) => {
        item.counterParties.forEach((counterparty) => {
            // Check if party already exist in list, otherwise add it
            const currentCounterpartyItem = newData.find((x) => x.counterparty._id === counterparty._id);
            if (typeof currentCounterpartyItem === "undefined") {
                const newCounterpartyItem: any = {};
                newCounterpartyItem.counterparty = counterparty;

                uniqueAgreementTypesSorted.forEach((agreementType) => {
                    newCounterpartyItem[agreementType] = {};
                    newCounterpartyItem[agreementType] = "";
                    newCounterpartyItem[agreementType + "Link"] = {};
                    newCounterpartyItem[agreementType + "Link"] = "";
                });
                if (item.approvedInfo && item.approvedInfo.length) {
                    if (item.approvedInfo[0].status === ApprovedStatusEnum.Accepted) {
                        newCounterpartyItem[item.type] = "✅";
                    } else if (item.approvedInfo[0].status === ApprovedStatusEnum.Rejected) {
                        newCounterpartyItem[item.type] = "⚠️";
                    } else {
                        newCounterpartyItem[item.type] = "x";
                    }
                } else {
                    newCounterpartyItem[item.type] = "x";
                }

                newCounterpartyItem[item.type + "Link"] = item._id;
                newData.push(newCounterpartyItem);
            } else {
                if (item.approvedInfo && item.approvedInfo.length) {
                    if (item.approvedInfo[0].status === ApprovedStatusEnum.Accepted) {
                        currentCounterpartyItem[item.type] = "✅";
                    } else if (item.approvedInfo[0].status === ApprovedStatusEnum.Rejected) {
                        currentCounterpartyItem[item.type] = "⚠️";
                    } else {
                        currentCounterpartyItem[item.type] = "x";
                    }
                } else {
                    currentCounterpartyItem[item.type] = "x";
                }
                currentCounterpartyItem[item.type + "Link"] = item._id;
            }
        });
    });
    return [newData, uniqueAgreementTypesSorted];
};

export function Counterparty({ clientId }): React.ReactElement {
    const { id } = useParams<"id">();
    const previousId: string = usePrevious(id);

    const filter: AgreementFilterInput = { partyIdIn: [clientId], statusIn: [AgreementStatusEnum.Active] };
    const [{ fetching: loading, error, data }] = useGetAgreementsQuery({ variables: { filter: filter } });

    const [agreement, setAgreements] = useState(null);

    useEffect(() => {
        if (data) {
            setAgreements(data.agreements);
        } else if (id !== previousId) {
            setAgreements(null);
        }
    }, [agreement, data, id, previousId]);

    if (loading) return <div>Loading</div>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;
    if (!agreement) return <div>No agreement found</div>;

    const arrangedData = arrangeAgreementsData(agreement);
    return (
        <div className="row">
            <div className="col">
                <Grid header="Counterparties" data={arrangedData[0]} sortable tableClassName="table-xs">
                    <Column
                        field="counterparty.name"
                        title="Counterparty"
                        className="grid-column-sticky"
                        format={(value, item) => {
                            return item && item.counterparty ? (
                                <Link to={"/parties/" + item.counterparty._id} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][0]}
                        title={arrangedData[1][0]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][0]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][0] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][1]}
                        title={arrangedData[1][1]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][1]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][1] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][2]}
                        title={arrangedData[1][2]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][2]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][2] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][3]}
                        title={arrangedData[1][3]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][3]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][3] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][4]}
                        title={arrangedData[1][4]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][4]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][4] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][5]}
                        title={arrangedData[1][5]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][5]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][5] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][6]}
                        title={arrangedData[1][6]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][6]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][6] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][7]}
                        title={arrangedData[1][7]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][7]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][7] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][8]}
                        title={arrangedData[1][8]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][8]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][8] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                    <Column
                        field={arrangedData[1][9]}
                        title={arrangedData[1][9]}
                        format={(value, item) => {
                            return item && typeof item[arrangedData[1][9]] !== "undefined" ? (
                                <Link to={"/agreements/" + item[arrangedData[1][9] + "Link"]} target="_blank">
                                    {value}
                                </Link>
                            ) : (
                                value
                            );
                        }}
                    />
                </Grid>
            </div>
        </div>
    );
}
