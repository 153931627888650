import React, { Fragment, useState, useEffect, ReactElement } from "react";
import { gql, useQuery } from "urql";
import MarkdownIt from "markdown-it";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { keyBy, cloneDeep, round } from "lodash";
import ReactMarkdown from "react-markdown";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import queryString from "query-string";
import stableStringify from "json-stable-stringify";
import { Alert } from "react-bootstrap";
import { useQueryState } from "../../../../components/src/use-query-state";

import { numberFormatFun, recursivelyRemoveKey } from "../../../../common/src";

import { useQueryArgs, usePrevious } from "../../../../components/src/common/Utils";
import {
    useGetFundsQuery,
    useCalculateNavQuery,
    useGetLastNavQuery,
    useCreateNavMutation,
    useGetPerformanceQuery
} from "./CalculateNavPage.generated";
import { SelectField, DateField } from "../../../../components/src/form";
import { NavComponent } from "./NavComponent";
import { NavData, NavInput, ReportStatusEnum, Nav, NavDataInput, PermissionAssetEnum } from "../../types.generated";
import { calculateWithinRange, NavRange } from "./utils";
import { serializeSwedenDate } from "../../components/dateFormater";
import { formikUrqlErrorFormater } from "../../../../components/src/common/formik-urql-error-helper";
import { GET_ME } from "../../common/queries";

const mdParser = new MarkdownIt();

export const getFunds = gql`
    query getFunds {
        funds: parties(filter: { typeIn: [Fund] }) {
            _id
            name
            accountingCurrency
            fundInfo {
                riskLevel
            }
        }
    }
`;

export const getFxValuations = gql`
    query getFxValuations($date: GraphQLSwedenDate!) {
        fxValuations(date: $date) {
            name
            date
            price
            currency
        }
    }
`;

export const getLastNav = gql`
    query getLastNav($fundId: GraphQLObjectId) {
        lastNav: navs(clientId: $fundId, lastOnly: true) {
            _id
            clientId
            date
            comment
            frontOfficeNav
            createTimestamp
            status
            updateUserId
            fundRiskLevel
            absLowTolerancePercent
            absHighTolerancePercent
            publishedTo {
                key
                timestamp
                userId
            }
            data {
                classes {
                    instrumentId
                    name
                    navBeforeAdjustmentsBeforeOrders
                    managementFee
                    dividendPaid
                    fxHedge
                    fxRate
                    adjustmentAmount
                    navAfterAdjustmentsBeforeOrders
                    navAfterAdjustmentsAfterOrders
                    navCurrency
                    navPerUnit
                    navPerUnitReturn1D
                    unitsAfterOrders
                    unitsBeforeOrders
                    weight
                }
                adjustmentAmountPositions {
                    instrumentId
                    name
                    accountId
                    value
                    currency
                    fxValuationDate
                    valuationDate
                    fxRate
                    quantity
                }
                positions {
                    instrumentId
                    name
                    accountId
                    value
                    currency
                    fxValuationDate
                    valuationDate
                    fxRate
                    quantity
                }
                orders {
                    amount
                    carryOwnTransactionCost
                    currency
                    instrumentId
                    isin
                    name
                    orderId
                    transactionType
                    units
                    valueDate
                }
                transactions {
                    accountId
                    adjustmentAmount
                    adjustmentShares
                    amount
                    currency
                    externalId
                    instrumentId
                    isin
                    name
                    price
                    accountingCurrency
                    accountingAmount
                    accountingPrice
                    fxRate
                    transactionType
                    units
                    valueDate
                    accountSwitchOrder
                }
            }
        }
    }
`;

export const calculateNav = gql`
    query calculateNav($fundId: GraphQLObjectId!, $dateString: GraphQLDateString!) {
        calculateNav(fundId: $fundId, date: $dateString) {
            classes {
                instrumentId
                name
                navBeforeAdjustmentsBeforeOrders
                managementFee
                dividendPaid
                fxHedge
                fxRate
                adjustmentAmount
                navAfterAdjustmentsBeforeOrders
                navAfterAdjustmentsAfterOrders
                navCurrency
                navPerUnit
                navPerUnitReturn1D
                unitsAfterOrders
                unitsBeforeOrders
                weight
                instrument {
                    currency
                }
            }
            adjustmentAmountPositions {
                instrumentId
                name
                accountId
                value
                currency
                fxValuationDate
                valuationDate
                fxRate
                quantity
            }
            positions {
                instrumentId
                name
                accountId
                value
                currency
                fxValuationDate
                valuationDate
                fxRate
                quantity
            }
            orders {
                amount
                carryOwnTransactionCost
                currency
                instrumentId
                isin
                name
                orderId
                transactionType
                units
                valueDate
            }
            transactions {
                accountId
                adjustmentAmount
                adjustmentShares
                amount
                currency
                externalId
                instrumentId
                isin
                name
                accountingCurrency
                accountingAmount
                accountingPrice
                fxRate
                price
                transactionType
                units
                valueDate
                accountSwitchOrder
            }
        }
    }
`;

export const getPerformance = gql`
    query getPerformance($fundId: GraphQLObjectId!, $dateString: GraphQLDateString!) {
        performance(clientId: $fundId, filter: { endDate: $dateString }) {
            series
        }
    }
`;

export const createNav = gql`
    mutation createNav($input: NavInput!) {
        createNav(input: $input) {
            _id
        }
    }
`;

interface FormData {
    fundName: string;
    date: string;
}

function isNumeric(value: string) {
    if (typeof value != "string") return false; // we only process strings!
    return (
        !isNaN(value as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(value))
    ); // ...and ensure strings of whitespace fail
}

export function CalculateNavPage(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();

    const { fundDashName }: any = useParams();
    const { queryArgs } = useQueryArgs();

    const previousFundDashName = usePrevious(fundDashName);
    const [date, setDate] = useQueryState("date", serializeSwedenDate(new Date()));
    const [fund, setFund] = useState(null);
    const [fundId, setFundId] = useState(null);
    const [formData, setFormData] = useState(null);
    const previousFormData = usePrevious(formData);

    const [comment, setComment] = useState("");
    const [editComment, setEditComment] = useState(false);
    const [frontOfficeNav, setFrontOfficeNav] = useState("");
    const [calculatedNavVariables, setCalculatedNavVariables] = useState<{
        fundId: string;
        dateString: string;
    }>(null);

    const [{ fetching: loading, error, data }] = useGetFundsQuery();
    const [{ fetching: fetchingCalculatedNav, error: errorCalculatedNav, data: calculatedNavData }] = useCalculateNavQuery({
        variables: calculatedNavVariables,
        pause: calculatedNavVariables ? false : true,
        requestPolicy: "network-only"
    });

    const [{ fetching: fetchingPerformance, error: performanceError, data: performanceData }] = useGetPerformanceQuery({
        variables: calculatedNavVariables,
        pause: calculatedNavVariables ? false : true,
        requestPolicy: "network-only"
    });

    const [{ data: lastNav }] = useGetLastNavQuery({ variables: { fundId }, pause: fundId ? false : true, requestPolicy: "network-only" });
    const [{ fetching: loadingFxValuations, data: dataFxValuations }] = useQuery({ query: getFxValuations, variables: { date: date } });

    const [{ fetching: loadingMe, error: errorMe, data: dataMe }] = useQuery({
        query: GET_ME,
        requestPolicy: "network-only"
    });

    const [createNavAlert, setCreateNavAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissCreateNavAlert = () => setCreateNavAlert({ color: "info", visible: false, message: "" });

    const [_, createNavFn] = useCreateNavMutation();

    useEffect(() => {
        if (!queryArgs || (queryArgs && !("date" in queryArgs))) {
            navigate(location.pathname + "?" + queryString.stringify({ date }), { replace: true });
        }
    }, [date, queryArgs, navigate, location]);

    useEffect(() => {
        if (fundDashName !== previousFundDashName) {
            setFormData(null);
            setFrontOfficeNav("");
        } else if (data) {
            const fundNames = data.funds.map((fund) => fund.name);
            const fundsByName = keyBy(data.funds, "name");
            if (formData === null) {
                if (fundDashName && fundNames.includes(fundDashName.replace(/-/g, " "))) {
                    const fundName = fundDashName.replace(/-/g, " ");
                    const initFormData: FormData = {
                        fundName: fundName,
                        date: date
                    };
                    setFormData(initFormData);
                    setComment(comment);
                    setFrontOfficeNav(frontOfficeNav);
                    const fund = fundsByName[fundDashName.replace(/-/g, " ")];
                    setFund(fund);
                    setFundId(fund._id);
                } else {
                    // default
                    const initFormData: FormData = {
                        fundName: "Select fund",
                        date: date
                    };
                    setFormData(initFormData);
                    setFrontOfficeNav(frontOfficeNav);
                }
            }
        }
    }, [comment, data, date, formData, frontOfficeNav, fundDashName, previousFundDashName]);

    useEffect(() => {
        if (data) {
            if (stableStringify(formData) !== stableStringify(previousFormData)) {
                if (formData) {
                    const fundsByName = keyBy(data.funds, "name");
                    if (formData.fundName in fundsByName) {
                        setCalculatedNavVariables({
                            fundId: fundsByName[formData.fundName]._id,
                            dateString: date
                        });
                    }
                }
            }
        }
    }, [data, date, formData, previousFormData]);

    if (loading || loadingFxValuations || fetchingCalculatedNav || fetchingPerformance || loadingMe)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (errorCalculatedNav)
        return (
            <div>
                <p style={{ color: "red" }}>{errorCalculatedNav.graphQLErrors[0].message}</p>
            </div>
        );

    if (performanceError)
        return (
            <div>
                <p style={{ color: "red" }}>{performanceError.graphQLErrors[0].message}</p>
            </div>
        );

    if (errorMe)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorMe, null, 2)}</pre>
            </div>
        );

    if (!calculatedNavData) {
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );
    }

    let fundNames = data.funds.map((fund) => fund.name);
    fundNames = ["Select fund", ...fundNames];

    let valuationsExistForDate = false;
    let fxValuationsExistForDate = false;

    let maxClassDiff = 0;
    let maxAbsClassReturn1D = 0;

    if (calculatedNavData) {
        const data = calculatedNavData.calculateNav;
        valuationsExistForDate = true;
        fxValuationsExistForDate = true;
        for (let i = 0; i < data.positions.length; i++) {
            if (data.positions[i].valuationDate !== date) {
                valuationsExistForDate = false;
            }
            if (data.positions[i].fxValuationDate !== date) {
                fxValuationsExistForDate = false;
            }
        }

        for (let i = 0; i < data.classes.length - 1; i++) {
            for (let j = i + 1; j < data.classes.length; j++) {
                const diff = Math.abs(parseFloat(data.classes[j].navPerUnitReturn1D) - parseFloat(data.classes[i].navPerUnitReturn1D));
                if (diff > maxClassDiff) maxClassDiff = diff;
            }
        }
        for (let i = 0; i < data.classes.length; i++) {
            const absClassReturn1D = Math.abs(parseFloat(data.classes[i].navPerUnitReturn1D));
            if (absClassReturn1D > maxAbsClassReturn1D) maxAbsClassReturn1D = absClassReturn1D;
        }
    }

    const calcNav: Nav = {
        __typename: "Nav",
        _id: null,
        clientId: null,
        date: date,
        frontOfficeNav: null,
        status: ReportStatusEnum.Active,
        comment: null,
        createTimestamp: null,
        updateUserId: null,
        data: calculatedNavData.calculateNav as NavData
    };

    // frontOfficeNav check
    const frontOfficeNavProvided = frontOfficeNav && isNumeric(frontOfficeNav);

    // Checks between FO and BO NAV
    const fxDict = keyBy(dataFxValuations.fxValuations, "name");

    // sum of navAfterAdjustmentsBeforeOrders
    let totalNavAfterAdjustmentsBeforeOrders = 0;

    let navRange: NavRange = null;

    const validRiskLevel = Boolean(
        fund &&
            fund.fundInfo &&
            fund.fundInfo.riskLevel &&
            // risk level integer between 1 and 7
            Number.isInteger(fund.fundInfo.riskLevel) &&
            fund.fundInfo.riskLevel > 0 &&
            fund.fundInfo.riskLevel < 8
    );

    if (frontOfficeNavProvided && validRiskLevel && calculateNav) {
        totalNavAfterAdjustmentsBeforeOrders =
            calculatedNavData && calculatedNavData.calculateNav.classes
                ? calculatedNavData.calculateNav.classes.reduce((acc, c) => {
                      let exchangeRate = 1;
                      if (c.instrument.currency !== fund.accountingCurrency) {
                          exchangeRate = fxDict[c.instrument.currency + fund.accountingCurrency].price;
                      }
                      return acc + parseFloat(c.navAfterAdjustmentsBeforeOrders) * exchangeRate;
                  }, 0)
                : 0;

        navRange = calculateWithinRange(parseFloat(frontOfficeNav), totalNavAfterAdjustmentsBeforeOrders, fund.fundInfo.riskLevel);
    }

    // skip check for first fund day
    let volCheck = true;
    if (navRange && navRange.narrowRange[0] === 0 && navRange.narrowRange[1] === 0) {
        navRange = null;
        volCheck = false;
    }

    // we need at least 10 returns to get decent vol estimate
    const validPerformanceData = Boolean(
        performanceData &&
            performanceData.performance &&
            Array.isArray(performanceData.performance.series) &&
            performanceData.performance.series.length > 10
    );

    // if we should check against vol we need valid risk level or performance data
    const volCheckInputValid = Boolean(volCheck && (validPerformanceData || validRiskLevel));

    let dailyVolatility = 0;

    // start with vol estimate depending on risk level
    if (validRiskLevel) {
        const level = fund.fundInfo.riskLevel;
        // https://github.com/CaptorAB/Python/blob/master/FrontOffice/frontOfficeNav.md
        // use half for each level except first and last
        if (level === 1) dailyVolatility = 0.005 / Math.sqrt(252);
        else if (level === 2) dailyVolatility = 0.0275 / Math.sqrt(252);
        else if (level === 3) dailyVolatility = 0.085 / Math.sqrt(252);
        else dailyVolatility = 0.12 / Math.sqrt(252);
    }

    // estimate from series if valid
    if (validPerformanceData) {
        const returnSeries: number[] = [];
        // we use last 30 days as proxy
        const seriesLength = performanceData.performance.series.length;
        const nReturns = seriesLength > 30 ? 30 : seriesLength - 1;
        for (let i = seriesLength - 1; i > seriesLength - 1 - nReturns; i--) {
            returnSeries.unshift(performanceData.performance.series[i] / performanceData.performance.series[i - 1]);
        }
        // assume zero mean for daily returns
        const mean = 0.0;
        dailyVolatility = Math.sqrt(returnSeries.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / nReturns);
    }

    // check against z-score assuming 0 mean
    // low tol 90% probability
    const lowToleranceVolatilityPassed = Boolean(maxAbsClassReturn1D < 1.644854 * dailyVolatility);
    // high tol 99% probability
    // TODO - investigate if high tolerance should be used!
    // const highToleranceVolatilityPassed = Boolean(maxAbsClassReturn1D < 2.575829 * dailyVolatility);

    const lowTolerancePassed = navRange ? navRange.withinNarrowRange : true; // narrow tolerance
    const highTolerancePassed = navRange ? navRange.withinErrorRange : true; // broader tolerance

    const fundRiskLevel: string = fund && fund.fundInfo ? fund.fundInfo.riskLevel.toString() : null;
    const absHighTolerancePercent: string = navRange ? navRange.absHighTolerancePercent.toString() : null;
    const absLowTolerancePercent: string = navRange ? navRange.absLowTolerancePercent.toString() : null;

    calcNav.fundRiskLevel = fundRiskLevel;
    calcNav.absHighTolerancePercent = absHighTolerancePercent;
    calcNav.absLowTolerancePercent = absLowTolerancePercent;
    calcNav.frontOfficeNav = frontOfficeNav;

    // lowest limit for substantial error 0.1% (for kid category 1), lets start with half of that...
    // TODO - investigate if high tolerance should be used!
    //const highNavClassConsistencyPassed = Boolean(maxClassDiff < 0.0005);
    const navClassConsistencyPassed = Boolean(maxClassDiff < 0.00025);

    // If difference is outside narrow tolerance this should be highlighted and a commentary mandatory in order to save report
    let commentRequired = false;
    let lowToleranceFailHighTolerancePassed = false;

    if (highTolerancePassed && !lowTolerancePassed && !comment) {
        lowToleranceFailHighTolerancePassed = true;
        commentRequired = true;
    }

    if (!commentRequired) {
        if (!navClassConsistencyPassed && !comment) {
            commentRequired = true;
        }
        if (!lowToleranceVolatilityPassed && !comment) {
            commentRequired = true;
        }
    }

    // Save button disabled
    const disableSaveButton = (!frontOfficeNavProvided || !highTolerancePassed || !volCheckInputValid || commentRequired) && !comment;

    return (
        <div className="container">
            <div className="row">
                <div className="col-10">
                    <Formik
                        enableReinitialize={true}
                        initialValues={formData}
                        validate={(validateFormData) => {
                            const errors: any = {};
                            if (validateFormData.date !== formData.date) {
                                const change: FormData = {
                                    fundName: formData.fundName,
                                    date: validateFormData.date
                                };
                                setFormData(change);
                                setDate(validateFormData.date);
                            }

                            if (validateFormData.fundName !== formData.fundName) {
                                const path = location.pathname.split("/");
                                if (fundDashName) {
                                    path.pop();
                                }
                                path.push(validateFormData.fundName.replace(/ /g, "-"));
                                navigate(path.join("/") + "?" + queryString.stringify({ ...queryArgs }), { replace: true });
                            }
                            return Object.keys(errors).length > 0 ? errors : {};
                        }}
                        onSubmit={null}
                    >
                        {({ isSubmitting }) => (
                            <Fragment>
                                <Form autoComplete="off">
                                    <div className="form row mb-3">
                                        <div className="col-4">
                                            <SelectField
                                                className=""
                                                name="fundName"
                                                label="Fund"
                                                options={fundNames}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="col-2">
                                            <DateField className="" name="date" label="Date" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                </Form>
                            </Fragment>
                        )}
                    </Formik>
                </div>
                <div className="col-2 right">
                    {dataMe &&
                    dataMe.me &&
                    dataMe.me.frontendRole &&
                    dataMe.me.frontendRole.assets &&
                    (dataMe.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) ||
                        dataMe.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manuals
                        </a>
                    ) : null}
                </div>
            </div>

            {fetchingCalculatedNav ? <div>Loading calculated NAV </div> : null}
            {calculatedNavData ? (
                <div>
                    {valuationsExistForDate ? null : (
                        <div style={{ color: "red" }}>Warning: Valuations for {date} for all instruments does not exist</div>
                    )}
                    {fxValuationsExistForDate ? null : (
                        <div style={{ color: "red" }}>Warning: Fx valuations for {date} for all instruments does not exist</div>
                    )}

                    <br />
                    <h4>New calculated NAV</h4>
                    <NavComponent nav={calcNav}></NavComponent>
                    <div className="row-col">
                        <label>Investment manager estimate for total NAV after adjustments before orders</label>
                    </div>
                    <div className="row-col">
                        <input
                            type="text"
                            name="frontOfficeNav"
                            value={frontOfficeNav}
                            className="form-control w-auto"
                            onChange={(e) => {
                                const value = e.target.value.replace(" ", "");
                                setFrontOfficeNav(value);
                            }}
                        />
                    </div>
                    <div className="row-col">
                        {frontOfficeNavProvided && navRange ? (
                            <div className="mt-2">
                                {"Total NAV after adjustments before orders: " +
                                    numberFormatFun("# ##0.##")(round(totalNavAfterAdjustmentsBeforeOrders)) +
                                    " needs to be greater than " +
                                    numberFormatFun("# ##0.##")(round(navRange.narrowRange[0])) +
                                    " and less than " +
                                    numberFormatFun("# ##0.##")(round(navRange.narrowRange[1]))}
                            </div>
                        ) : null}
                    </div>
                    <div className="row-col mt-3">
                        {!frontOfficeNavProvided ? (
                            <Alert style={{ marginTop: "10px" }} variant={"danger"}>
                                Need to set a front office NAV and it needs to be a number!
                            </Alert>
                        ) : null}
                        {lowToleranceFailHighTolerancePassed ? (
                            <Alert style={{ marginTop: "10px" }} variant={"warning"}>
                                Difference between investment manager NAV and official NAV is outside narrow tolerance! Please comment.
                            </Alert>
                        ) : null}
                        {!navClassConsistencyPassed ? (
                            <Alert style={{ marginTop: "10px" }} variant={"warning"}>
                                The maximum absolute difference between daily class returns {round(maxClassDiff, 6)} is larger than
                                tolerance 0.00025. Please comment.
                            </Alert>
                        ) : null}
                        {!lowToleranceVolatilityPassed ? (
                            <Alert style={{ marginTop: "10px" }} variant={"warning"}>
                                The maximum absolute daily class return is outside 90% confidence interval assuming normal distribution with
                                zero mean, z-score: {round(maxAbsClassReturn1D / dailyVolatility, 6)} maxAbsClassReturn1D/dailyVolatility.
                            </Alert>
                        ) : null}
                        {!highTolerancePassed ? (
                            <Alert style={{ marginTop: "10px" }} variant={"danger"}>
                                Difference between investment manager NAV and official NAV is outside broader tolerance!
                            </Alert>
                        ) : null}
                        {!volCheckInputValid ? (
                            <Alert style={{ marginTop: "10px" }} variant={"danger"}>
                                Must have valid fund risk level (1-7) or fund performance series to check volatility!
                            </Alert>
                        ) : null}
                    </div>
                    <div className="row-col mt-3">
                        <DropdownButton id="button" title={"Comment"} align="end" size="lg">
                            <Dropdown.Item
                                key={"edit"}
                                as="button"
                                onClick={() => {
                                    setEditComment(true);
                                }}
                            >
                                <div>Edit</div>
                            </Dropdown.Item>

                            <Dropdown.Item
                                key={"cancel"}
                                as="button"
                                onClick={() => {
                                    setComment(comment);
                                    setEditComment(false);
                                }}
                            >
                                <div>Cancel</div>
                            </Dropdown.Item>
                            <Dropdown.Item
                                key={"Save"}
                                as="button"
                                onClick={() => {
                                    setComment(comment);
                                    setEditComment(false);
                                }}
                            >
                                <div>Save</div>
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                    {editComment ? (
                        <div className="row mt-1">
                            <div className="col">
                                <MdEditor
                                    style={{ height: "500px" }}
                                    value={comment}
                                    renderHTML={(text) => mdParser.render(text)}
                                    onChange={({ text }) => setComment(text)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-1">
                            <div className="col">
                                <ReactMarkdown>{comment || ""}</ReactMarkdown>
                            </div>
                        </div>
                    )}

                    <div className="row-col mb-4">
                        <button
                            type="submit"
                            disabled={disableSaveButton}
                            className="btn btn-primary btn-lg"
                            onClick={async () => {
                                let nav: NavInput = {
                                    clientId: fund._id,
                                    date: date,
                                    comment: comment,
                                    frontOfficeNav: frontOfficeNav,
                                    status: ReportStatusEnum.Active,
                                    fundRiskLevel,
                                    absHighTolerancePercent,
                                    absLowTolerancePercent,
                                    data: cloneDeep(calculatedNavData.calculateNav) as NavDataInput
                                };
                                nav = recursivelyRemoveKey(nav, "__typename");
                                nav = recursivelyRemoveKey(nav, "instrument");
                                await createNavFn({ input: nav })
                                    .then((result) => {
                                        if ("error" in result && result.error) {
                                            const message = formikUrqlErrorFormater(result.error);
                                            setCreateNavAlert({ color: "danger", visible: true, message });
                                        } else {
                                            navigate("/nav2/" + result.data.createNav._id, { replace: true });
                                            setCreateNavAlert({
                                                color: "success",
                                                visible: true,
                                                message: "New NAV created successfully!"
                                            });
                                        }
                                        return true;
                                    })
                                    .catch((error) => {
                                        setCreateNavAlert({ color: "danger", visible: true, message: error.toString() });
                                    });
                            }}
                        >
                            Save NAV
                        </button>
                    </div>

                    <div className="row-col mt-3">
                        {createNavAlert.visible ? (
                            <Alert style={{ marginTop: "10px" }} variant={createNavAlert.color} onClose={onDismissCreateNavAlert}>
                                {createNavAlert.message}
                            </Alert>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {lastNav && lastNav.lastNav && lastNav.lastNav.length > 0 ? (
                <div>
                    <h4>Last NAV</h4>
                    <NavComponent nav={lastNav.lastNav[0]}></NavComponent>
                </div>
            ) : null}
        </div>
    );
}
