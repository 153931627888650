import React, { Fragment, useMemo } from "react";
import { useQuery, gql } from "urql";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";
import { Button } from "react-bootstrap";

import { ReactTable } from "../../../components/src/react-table/ReactTable";

export const getCurveDefinitions = gql`
    query curveDefinitions {
        curveDefinitions {
            _id
            clientId
            name
            updateTimestamp
            type
            definition {
                currency
                dayCount
                forwardPeriod
                type
                issuerProgramId
                issuerProgram {
                    _id
                    name
                }
                instruments {
                    instrumentId
                    instrument {
                        _id
                        name
                    }
                }
            }
        }
    }
`;

export const CurveDefinitionsPage = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getCurveDefinitions });

    const curveDefinitions = useMemo(() => {
        let curveDefinitions = [];
        if (data) {
            curveDefinitions = sortBy(data.curveDefinitions, "updateTimestamp").reverse();
        }
        return curveDefinitions;
    }, [data]);

    const columns = useMemo(() => {
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/curvedefinitions/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 75
            },
            {
                header: "Name",
                accessorKey: "name"
            },
            {
                header: "definition.instruments",
                accessorKey: "definition.instruments",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((item) => {
                                    const { instrument, instrumentId } = item;
                                    return (
                                        <Link className="row" key={instrumentId} to={`/instruments/${instrumentId}`}>
                                            {instrument.name}
                                        </Link>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                }
            },
            {
                header: "Issuer program",
                id: "issuerProgram.name",
                accessorKey: "issuerProgram.name",
                accessorFn: (row) => (row.issuerProgram ? row.issuerProgram.name : null),
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const issuerProgramId = row.original.issuerProgramId;
                    if (issuerProgramId && issuerProgramId !== "000000000000000000000000") {
                        return <Link to={`/issuerprograms/${row.original.issuerProgramId}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return null;
                    }
                },
                size: 110
            },
            {
                header: "Update timestamp",
                accessorKey: "updateTimestamp"
            }
        ];
    }, []);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <Fragment>
                <div className="row">
                    <div className="col">
                        <Button
                            type="button"
                            className="float-end btn btn-sm"
                            onClick={() => {
                                const win = window.open("/curvedefinitions/new", "_self");
                                win.focus();
                            }}
                        >
                            New curve definition
                        </Button>
                    </div>
                </div>
                <p>Number of curve definitions: {curveDefinitions.length}</p>
                <ReactTable columns={columns} data={curveDefinitions} />
            </Fragment>
        </div>
    );
};
