import React, { useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "urql";

import { Svgs } from "../../../../components/src";
import { DateHelper } from "../../../../common/src";

import { useQueryArgs } from "../../../../components/src/common/Utils";
import { exportToXlsx, sheetType } from "../../../../components/src/common/exportToXlsx";
import { InstrumentModelTypeEnum } from "../../types.generated";
import { DateForm } from "../../../../components/src/dateForm";

import { ReactTable } from "../../../../components/src/react-table/ReactTable";
import { SelectColumnFilter } from "../../../../components/src/react-table/ReactTableFilters";

const GET_DATA = gql`
    query allInstrumentsInPosition($endDate: GraphQLDateString) {
        allInstrumentsInPosition {
            instrument {
                _id
                name
                modelType
                presentValue(date: $endDate)
                accruedInterest(date: $endDate)
                cleanPrice(date: $endDate)
                valuations(date: $endDate) {
                    date
                    cleanPrice
                    accruedInterest
                    price
                }
            }
        }
    }
`;

// Since we still have some swaps where we not have position 1...
const SWAP_SCALE_BY_ID = {
    "592c09c5710517250c2199a1": 100000000.0,
    "592c1edd710518250c7a2a72": 100000000.0,
    "5a33ce6271051585d8a616be": 50000000.0,
    "59ae67ae7105170944560fd1": 40000000.0,
    "592c2261710518250c7a520e": 30000000.0
};
// TODO make end date responsive!
export const ValuationComparePage = (): React.ReactElement => {
    let date = DateHelper.dateAddDays(new Date(), -1).toISOString().slice(0, 10);
    const { queryArgs } = useQueryArgs();

    if (queryArgs.date) {
        date = queryArgs.date as string;
    }

    const [{ data, error, fetching }] = useQuery({
        query: GET_DATA,
        variables: { endDate: date },
        requestPolicy: "cache-and-network"
    });

    const columns = useMemo(
        () => [
            {
                header: "Instrument",
                accessorKey: "name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 100
            },
            {
                header: "Model type",
                accessorKey: "modelType",
                filter: SelectColumnFilter,
                size: 50
            },
            {
                header: "Date",
                accessorKey: "date",
                size: 50
            },
            {
                header: "Present value",
                accessorKey: "presentValue",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Py present value",
                accessorKey: "pyPresentValue",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Present value diff",
                accessorKey: "pvDiff",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Accrued interest",
                accessorKey: "accruedInterest",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Py accrued interest",
                accessorKey: "pyAccruedInterest",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Accrued interest diff",
                accessorKey: "accruedDiff",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Clean price",
                accessorKey: "cleanPrice",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Py clean price",
                accessorKey: "pyCleanPrice",
                filterFn: "startsWith",
                size: 50
            },
            {
                header: "Clean price diff",
                accessorKey: "cleanDiff",
                filterFn: "startsWith",
                size: 50
            }
        ],
        []
    );

    if (fetching) return <p>fetching...</p>;
    if (error) <pre>{JSON.stringify(error, null, 2)}</pre>;

    const tableData: any[] = [];

    for (const position of data.allInstrumentsInPosition) {
        // DerivativeValuation in py values "FxSwap", "Swap", "Swaption"
        const modelType = position.instrument.modelType as InstrumentModelTypeEnum;
        if (
            modelType === InstrumentModelTypeEnum.Swap ||
            modelType === InstrumentModelTypeEnum.Swaption ||
            modelType === InstrumentModelTypeEnum.FxSwap
        ) {
            const scale = SWAP_SCALE_BY_ID[position.instrument._id] ? SWAP_SCALE_BY_ID[position.instrument._id] : 1;
            const pyPresentValue = position.instrument.valuations ? scale * position.instrument.valuations.price : null;
            const pyAccruedInterest = position.instrument.valuations ? scale * position.instrument.valuations.accruedInterest : null;
            const pyCleanPrice = position.instrument.valuations ? scale * position.instrument.valuations.cleanPrice : null;
            const pvDiff = position.instrument.presentValue - pyPresentValue;
            const accruedDiff = position.instrument.accruedInterest - pyAccruedInterest;
            const cleanDiff = position.instrument.cleanPrice - pyCleanPrice;
            tableData.push({
                instrumentId: position.instrument._id,
                name: position.instrument.name,
                modelType: position.instrument.modelType,
                presentValue: position.instrument.presentValue,
                accruedInterest: position.instrument.accruedInterest,
                cleanPrice: position.instrument.cleanPrice,
                date: position.instrument.valuations ? position.instrument.valuations.date : null,
                pyPresentValue: position.instrument.valuations ? position.instrument.valuations.price : null,
                pyAccruedInterest: position.instrument.valuations ? position.instrument.valuations.accruedInterest : null,
                pyCleanPrice: position.instrument.valuations ? position.instrument.valuations.cleanPrice : null,
                pvDiff,
                accruedDiff,
                cleanDiff
            });
        }
    }

    return (
        <Fragment>
            <div className="d-flex mb-2">
                <DateForm defaultDateString={date} dateName={"date"}></DateForm>
            </div>
            <div
                style={{ width: "20px", height: "20px", marginBottom: "0.5rem" }}
                onClick={() => {
                    const currentRecords: sheetType = tableData;
                    exportToXlsx([currentRecords], "valuations.xlsx");
                }}
            >
                <Svgs.Excel />
            </div>
            <p>Number of positions: {tableData.length}</p>
            <ReactTable columns={columns} data={tableData} defaultHiddenColumns={[]} />
        </Fragment>
    );
};
