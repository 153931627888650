import { gql } from "urql";

export const GET_ME = gql`
    query getMe {
        me {
            _id
            frontendRole {
                assets
            }
        }
    }
`;
