import React, { Fragment, PureComponent } from "react";
import { reduce, isArray, isNil, snakeCase, startCase, map, get } from "lodash";
import classnames from "classnames";

import { Column } from "./Column";
import { Excel } from "../Svgs";
import { exportToXlsx2 } from "../common/exportToXlsx2";
import { Item } from "./Item";
import { PrintContext } from "../PrintContext";

import { isNullOrUndefined, processClassName as pc, sortData } from "./helpers";

import { CopyToClipboardButton } from "../CopyToClipboardButton";

interface IGridProps {
    data: Array<any>;
    header?: any; //PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func, PropTypes.string]),
    children?: Array<any>; //PropTypes.array,
    className?: string;
    trClassName?: any; // function
    trStyle?: any; // function
    tableClassName?: string;
    sortable?: boolean;
    showHeader?: boolean;
    hideDownload?: boolean;
    copyButton?: boolean;
    /** Determines a data row for headers, excluded from table body */
    headersRowIndex?: number;
    negativeClassName?: string;
}

export class Grid extends PureComponent<IGridProps> {
    state = {
        sortCol: "",
        sortOrder: "desc",
        clipboard: null
    };

    renderSortHandle = (col) => {
        const { sortCol, sortOrder } = this.state;

        if (sortCol !== col) return <Fragment />;

        return (
            <span
                className={classnames({
                    dropup: sortOrder === "asc",
                    dropdown: sortOrder === "desc"
                })}
            >
                <small className="dropdown-toggle"> </small>
            </span>
        );
    };

    renderHeaders = (headers, topHeaders, itemProps) => {
        const { sortable } = this.props;
        return (
            <thead>
                {topHeaders && topHeaders.length > 0 && (
                    <tr>
                        {topHeaders.map((topHeader, i) => (
                            <th key={i} className={pc(topHeader.className)} colSpan={topHeader.colspan}>
                                {topHeader.title}
                            </th>
                        ))}
                    </tr>
                )}
                <tr>
                    {headers.map((header, i) => (
                        <th
                            key={i}
                            className={pc(itemProps[i].className, null, i)}
                            onClick={() => sortable && this.handleSort(itemProps[i])}
                        >
                            {header}
                            {sortable && this.renderSortHandle(itemProps[i].key)}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };

    renderBody = (data, itemProps, trClassName, trStyle, headersRowIndex, negativeClassName) => {
        return (
            <tbody>
                {data.map((item, i) => {
                    return isNullOrUndefined(headersRowIndex) || headersRowIndex !== i ? (
                        <Item
                            key={i}
                            idx={i}
                            item={item}
                            items={data}
                            itemProps={itemProps}
                            trClassName={trClassName}
                            trStyle={trStyle}
                            negativeClassName={negativeClassName}
                        />
                    ) : null;
                })}
            </tbody>
        );
    };

    parseChildren = (cols, data, headersRowIndex) => {
        if (!isArray(cols)) {
            cols = [cols];
        }

        return reduce(
            cols,
            (acc, col) => {
                if (!col) return acc;

                let { title } = col.props;
                const { field, topTitle, topTitleColspan, ...rest } = col.props;

                if (!isNil(topTitle)) {
                    acc.topHeaders.push({
                        title: topTitle,
                        colspan: topTitleColspan,
                        className: rest.className
                    });
                }

                if (isNil(title)) {
                    title = isNullOrUndefined(headersRowIndex) ? startCase(field) : data[headersRowIndex][parseInt(field)];
                }
                acc.headers.push(title);
                acc.itemProps.push({
                    key: field,
                    ...rest
                });

                return acc;
            },
            { headers: [], topHeaders: [], itemProps: [] }
        );
    };

    renderExportToExcel = () => {
        return (
            <PrintContext.Consumer>
                {(ctx) =>
                    !ctx.print && (
                        <button id="export-excel" className="export-link" type="button" onClick={this.handleExport}>
                            {<Excel />}
                        </button>
                    )
                }
            </PrintContext.Consumer>
        );
    };

    exportToCsv = (data: any[], headers: string[], headersRowIndex: number, itemProps: any[], delimiter: string = "\t"): string => {
        const rows: string[] = [];

        rows.push(headers.join(delimiter));

        data.forEach((item, i) => {
            if (isNullOrUndefined(headersRowIndex) || headersRowIndex !== i) {
                const row: string[] = [];
                map(itemProps, (itemProp, i) => {
                    const value = get(item, itemProp.key);
                    row.push(value);
                });
                rows.push(row.join(delimiter));
            }
        });

        return rows.join("\n");
    };

    renderCopyToClipboard = (data: any[], headers: string[], headersRowIndex: number, itemProps: any[]) => {
        const randomId = `ccb${Math.floor(Math.random() * 10000)}`;

        return (
            <CopyToClipboardButton
                id={randomId}
                clipboard={this.state.clipboard}
                className="grid-icon-button float-end"
                onClick={() => {
                    const csv = this.exportToCsv(data, headers, headersRowIndex, itemProps);

                    const { sortCol, sortOrder } = this.state;
                    this.setState({
                        sortCol,
                        sortOrder,
                        clipboard: csv
                    });
                }}
            />
        );
    };

    handleExport = () => {
        const { data, header } = this.props;
        const randomString = Math.random().toString(36).slice(2);
        const filename = snakeCase(header || randomString);
        exportToXlsx2(data, `${filename}.xlsx`);
    };

    handleSort = (itemProps) => {
        const { sortCol, sortOrder } = this.state;
        let newSortOrder = "asc";

        if (sortCol === itemProps.key) {
            newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        }

        this.setState({
            sortCol: itemProps.key,
            sortOrder: newSortOrder
        });
    };

    render() {
        const {
            header,
            className,
            tableClassName,
            showHeader,
            trClassName,
            trStyle,
            hideDownload,
            headersRowIndex,
            copyButton,
            negativeClassName
        } = this.props;
        let { children, data } = this.props;
        if (isNil(children)) {
            const columns = {};
            data.forEach((d) => Object.keys(d).forEach((e) => (columns[e] = true)));
            children = Object.keys(columns).map((d, i) => (
                <Column key={i} field={d} title={isNullOrUndefined(headersRowIndex) ? i : data[headersRowIndex][i]} />
            ));
        }
        const { headers, itemProps, topHeaders } = this.parseChildren(children, data, headersRowIndex);
        const tableClasses = classnames("table", "table-striped", tableClassName, {
            "table-sm": !tableClassName
        });
        const { sortCol, sortOrder } = this.state;
        data = sortData(data, sortCol, sortOrder);

        return (
            <div className={`${className} table-responsive`}>
                {showHeader !== false ? (
                    <Fragment>
                        <h4>{header}</h4>
                        {hideDownload ? null : this.renderExportToExcel()}
                        {copyButton && this.renderCopyToClipboard(data, headers, headersRowIndex, itemProps)}
                    </Fragment>
                ) : null}
                <table className={tableClasses}>
                    {this.renderHeaders(headers, topHeaders, itemProps)}
                    {this.renderBody(data, itemProps, trClassName, trStyle, headersRowIndex, negativeClassName)}
                </table>
            </div>
        );
    }
}
