import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "urql";
import { Button, Alert } from "react-bootstrap";
import { cloneDeep } from "lodash";

import { getMe } from "./SideNav";
import { useAlertTimeOut } from "../../../components/src/common/Utils";
import { loginContext } from "./Login";
import { Markdown } from "../../../components/src/Markdown";

const acceptTerms = gql`
    mutation acceptTerms($input: AcceptTermsInput!) {
        acceptTerms(input: $input) {
            _id
            noteIds
        }
    }
`;

export const TermsOfServicePage = (): React.ReactElement => {
    const [{ fetching: userLoading, error: userError, data: userData }] = useQuery({ query: getMe });
    const [__acceptTermsData, executeAcceptTerms] = useMutation(acceptTerms);

    const { login, setLogin } = useContext(loginContext);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        // Only for first time logging in when no terms have been created so there is nothing to accept
        if (userData && userData.me && !userData.me.termsOfService && login && !login.acceptedTerms) {
            setLogin({ ...login, acceptedTerms: true });
        }
    }, [login, setLogin, userData]);

    if (userLoading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (userError)
        return (
            <div>
                <p>user error:</p>
                <pre> {JSON.stringify(userError, null, 2)}</pre>
            </div>
        );

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col">
                    <header className="mb-4">
                        <h4>{"End user license agreement and terms of use agreement"}</h4>
                    </header>
                </div>
            </div>
            {!login.acceptedTerms ? (
                <div className="row mb-4">
                    <div className="col">
                        <i>{"Please read and accept below"}</i>
                    </div>
                </div>
            ) : null}
            {userData && userData.me && userData.me.termsOfService ? (
                <div>
                    <div className="row">
                        <div className="col-sm-9">
                            <Markdown
                                key={(Math.random() + 1).toString(36).substring(7)}
                                markdown={userData.me.termsOfService.data || ""}
                            />
                        </div>
                    </div>
                    {!login.acceptedTerms ? (
                        <div className="d-flex">
                            <Button
                                id="button"
                                size="sm"
                                variant="primary"
                                className="w-auto"
                                onClick={async () => {
                                    const newNoteIds = cloneDeep(userData.me.noteIds).concat([userData.me.termsOfService._id]);
                                    const input = {
                                        _id: userData.me._id,
                                        noteIds: newNoteIds
                                    };

                                    executeAcceptTerms({ input })
                                        .catch((error) => {
                                            setAlert({ color: "danger", visible: true, message: error.toString() });
                                            console.error(error);
                                        })
                                        .finally(() => {
                                            setLogin({ ...login, acceptedTerms: true });
                                        });
                                }}
                            >
                                {"I have read and agree to the terms"}
                            </Button>
                        </div>
                    ) : null}
                    <div className="row mt-4">
                        <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                            {alert.visible ? (
                                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                    {alert.message}
                                </Alert>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
