//import { PartialDeep } from "type-fest";
import { Position } from "../../types.generated";
import {
    InstrumentResult,
    YieldDeltaBucket,
    CurrencyEnum,
    InstrumentModelLegTypesEnum,
    InstrumentModelTypeEnum,
    MappingParameterEnum,
    MappingTypeEnum,
    WireCurveDefinition,
    WireInstrument,
    WireValuationMapping,
    getDataToQuantLib,
    getInstrumentRisk,
    InstrumentQuery,
    NULL_DATE_STRING
} from "swaplab2";

export const getNullResult = (instrumentId: string, date: string, currency: CurrencyEnum): InstrumentResult => {
    return {
        instrumentId,
        date,
        currency,
        presentValue: null,
        accruedInterest: null,
        cleanPrice: null,
        interestRateYieldDelta: null,
        creditYieldDelta: null,
        zeroSpread: null,
        bsDelta: null,
        bsVega: null,
        bpShiftDelta: null,
        bpShiftVega: null,
        pctDelta: null,
        interestRateYieldDeltaBuckets: []
    };
};
export const getTypeScriptResults = (instruments: WireInstrument[], date: string, buckets: YieldDeltaBucket[]): InstrumentResult[] => {
    const results: InstrumentResult[] = [];
    for (const instrument of instruments) {
        const result = getNullResult(instrument._id, date, instrument.model.quoteCurrency);
        result.interestRateYieldDeltaBuckets = buckets;
        // Get instrument depending on model type
        const modelType = instrument.model._t;
        // Add models we have positions in here.
        if (
            modelType === InstrumentModelTypeEnum.Stock ||
            modelType === InstrumentModelTypeEnum.PortfolioSwap ||
            modelType === InstrumentModelTypeEnum.FundExternal ||
            modelType === InstrumentModelTypeEnum.FundInternal ||
            modelType === InstrumentModelTypeEnum.PortfolioSwapX ||
            modelType === InstrumentModelTypeEnum.RealEstate
        ) {
            // Just mirror valuation price! Here we should use price collection.
            result.presentValue = instrument.valuations.price;
        } else {
            throw Error("Model of type: " + modelType + " not implemented in typescript");
        }
        results.push(result);
    }
    return results;
};
export const isExpired = (instrument: WireInstrument, evaluationDate: string): boolean => {
    const model = instrument.model;
    if (model._t === InstrumentModelTypeEnum.PortfolioSwap) {
        return model.expiryDate < evaluationDate;
    } else if (
        model._t === InstrumentModelTypeEnum.Bond ||
        model._t === InstrumentModelTypeEnum.Swap ||
        model._t === InstrumentModelTypeEnum.FxSwap ||
        model._t === InstrumentModelTypeEnum.Swaption ||
        model._t === InstrumentModelTypeEnum.CdsIndex
    ) {
        let maturityDate = "1901-01-01";
        if (Array.isArray(model.legs) && model.legs.length) {
            for (const leg of model.legs) {
                if (Array.isArray(leg.cashFlows) && leg.cashFlows.length) {
                    for (const cf of leg.cashFlows) {
                        if (cf.payDate > maturityDate) maturityDate = cf.payDate;
                    }
                }
            }
        }
        return maturityDate < evaluationDate;
    } else {
        return false;
    }
};

export const notStarted = (instrument: WireInstrument, evaluationDate: string): boolean => {
    const model = instrument.model;
    if (model._t === InstrumentModelTypeEnum.PortfolioSwap) {
        return evaluationDate < model.startDate;
    } else if (
        model._t === InstrumentModelTypeEnum.Bond ||
        model._t === InstrumentModelTypeEnum.Swap ||
        model._t === InstrumentModelTypeEnum.FxSwap ||
        model._t === InstrumentModelTypeEnum.Swaption ||
        model._t === InstrumentModelTypeEnum.CdsIndex
    ) {
        // Since we sometimes trade a prior to start date we must be able to value
        if (instrument.firstTradeDate) return evaluationDate < instrument.firstTradeDate;
        let startDate = "2999-01-01";
        if (Array.isArray(model.legs) && model.legs.length) {
            for (const leg of model.legs) {
                if (Array.isArray(leg.cashFlows) && leg.cashFlows.length) {
                    for (const cf of leg.cashFlows) {
                        if (cf.startDate < startDate) startDate = cf.startDate;
                    }
                }
            }
        }
        return evaluationDate < startDate;
    } else {
        return false;
    }
};

export const cannotBeValued = (
    instrument: WireInstrument,
    mappedCreditCurvesByIssuerProgramId: Record<string, WireCurveDefinition>
): boolean => {
    const model = instrument.model;
    // We cannot value bonds if we do not have clean price...
    if (model._t === InstrumentModelTypeEnum.Bond) {
        if (!instrument.valuations || typeof instrument.valuations.cleanPrice === "undefined") {
            return true;
        }
        return false;
    } else if (model._t === InstrumentModelTypeEnum.CdsIndex) {
        if (!mappedCreditCurvesByIssuerProgramId[instrument.issuerProgramId]) {
            return true;
        }
        return false;
    } else {
        return false;
    }
};
export const getExpiredResults = (instruments: WireInstrument[], date: string): InstrumentResult[] => {
    const results: InstrumentResult[] = [];
    for (const instrument of instruments) {
        const result = getNullResult(instrument._id, date, instrument.model.quoteCurrency);
        results.push(result);
    }
    return results;
};

export const isSpecialCaseCdsIndex = (wireInstrument: WireInstrument): boolean => {
    // We must have price on instrument
    if (!wireInstrument.pxLast || typeof wireInstrument.pxLast.value === "undefined") {
        throw Error("No price on instrument: " + wireInstrument.name);
    }
    const fixedRateLeg = wireInstrument.model.legs.find((leg) => leg._t === InstrumentModelLegTypesEnum.FixedRateLeg);
    if (!fixedRateLeg) throw Error("No fixed rate leg found for cds instrument " + wireInstrument.name);
    // If there are no coupons and price close to zero numerical limits in QuantLib cds model
    if (fixedRateLeg.rate === 0 && Math.abs(wireInstrument.pxLast.value) < 1e-4) return true;
    else return false;
};

export const getResultsByInstrumentId = (
    positions: Position[],
    valuationMappings: WireValuationMapping[],
    buckets: YieldDeltaBucket[] = [],
    evaluationDate = NULL_DATE_STRING
): Record<string, InstrumentResult> => {
    // Use todays date as evaluation date if null
    if (evaluationDate === NULL_DATE_STRING) {
        // See https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
        const today = new Date();
        evaluationDate = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000).toISOString().substring(0, 10);
    }
    // To filter out cds index that have no curve...
    const mappedCreditCurvesByIssuerProgramId: Record<string, WireCurveDefinition> = {};
    for (let i = 0; i < valuationMappings.length; i++) {
        const valuationMapping = valuationMappings[i];
        if (valuationMapping.parameterType === MappingParameterEnum.CreditCurve) {
            // Credit curves mapped on issuerProgramId thus mappingType IssuerProgram
            if (valuationMapping.mappingType === MappingTypeEnum.IssuerProgram) {
                mappedCreditCurvesByIssuerProgramId[valuationMapping.issuerProgramId] = valuationMapping.curveDefinition;
            } else {
                throw Error(
                    "Unknown valuation mapping type for CreditCurve. Should be IssuerProgram - got: : " + valuationMapping.mappingType
                );
            }
        }
    }
    // Filter out instruments to be priced in QuantLib and TypeScript
    const qlInstruments: WireInstrument[] = [];
    const tsInstruments: WireInstrument[] = [];
    const expiredInstruments: WireInstrument[] = [];
    // Special case instruments due to numerical limitations
    const specialCaseCdsIndexes: WireInstrument[] = [];
    for (let i = 0; i < positions.length; i++) {
        const wireInstrument = positions[i].instrument as unknown as WireInstrument;
        // Special case for party instruments - always value 1 in its currency
        if (positions[i].isCashAccount) {
            const result = getNullResult(wireInstrument._id, evaluationDate, wireInstrument.model.quoteCurrency);
            // always  1 in its currency
            result.presentValue = 1;
            continue;
        }
        // We need model to proceed!
        if (!wireInstrument.model) {
            throw Error("No model on instrument: " + wireInstrument.name);
        }
        // Get instrument depending on model type
        const modelType = wireInstrument.model._t;
        if (
            isExpired(wireInstrument, evaluationDate) ||
            cannotBeValued(wireInstrument, mappedCreditCurvesByIssuerProgramId) ||
            notStarted(wireInstrument, evaluationDate)
        ) {
            expiredInstruments.push(wireInstrument);
        } else {
            if (
                [
                    InstrumentModelTypeEnum.GenericDeposit,
                    InstrumentModelTypeEnum.GenericSwap,
                    InstrumentModelTypeEnum.Bond,
                    InstrumentModelTypeEnum.Swap,
                    InstrumentModelTypeEnum.Swaption,
                    InstrumentModelTypeEnum.FxSwap,
                    InstrumentModelTypeEnum.CdsIndex,
                    InstrumentModelTypeEnum.CdsBasket
                ].includes(modelType)
            ) {
                // Special case for cds index hedges due to numerical limitations in QuantLib cds model
                if (
                    (modelType === InstrumentModelTypeEnum.CdsIndex || modelType === InstrumentModelTypeEnum.CdsBasket) &&
                    isSpecialCaseCdsIndex(wireInstrument)
                )
                    specialCaseCdsIndexes.push(wireInstrument);
                else qlInstruments.push(wireInstrument);
            } else {
                tsInstruments.push(wireInstrument);
            }
        }
    }
    // Problems with enum node_modules vs types!
    const dataToQuantLib = getDataToQuantLib(qlInstruments as any, valuationMappings as any, evaluationDate);
    const instrumentQuery: InstrumentQuery = {
        presentValue: true,
        cleanPrice: true,
        accruedInterest: true,
        creditYieldDelta: true,
        interestRateYieldDelta: true,
        zeroSpread: true,
        bsDelta: true,
        bsVega: true,
        bpShiftDelta: true,
        bpShiftVega: true,
        pctDelta: true,
        interestRateYieldDeltaBuckets: { queriedFor: true, buckets }
    };

    //console.log({ instrumentQuery });
    //console.log({ dataToQuantLib });
    //console.log({ evaluationDate });

    const quantLibResults = getInstrumentRisk(dataToQuantLib, instrumentQuery, evaluationDate);

    const resultsByInstrumentId: Record<string, any> = {};
    for (const result of quantLibResults) {
        resultsByInstrumentId[result.instrumentId] = result;
    }

    for (const instrument of expiredInstruments) {
        const result = getNullResult(instrument._id, evaluationDate, instrument.model.quoteCurrency);
        resultsByInstrumentId[instrument._id] = result;
    }

    const typeScriptResults = getTypeScriptResults(tsInstruments, evaluationDate, buckets);
    for (const result of typeScriptResults) {
        resultsByInstrumentId[result.instrumentId] = result;
    }

    for (const instrument of specialCaseCdsIndexes) {
        const result = getNullResult(instrument._id, evaluationDate, instrument.model.quoteCurrency);
        // Just mirror price as present value and 0 deltas
        // We know there is a price. Otherwise not special case...
        result.presentValue = instrument.pxLast.value;
        result.creditYieldDelta = 0.0;
        result.interestRateYieldDelta = 0.0;
        resultsByInstrumentId[instrument._id] = result;
    }

    return resultsByInstrumentId;
};
