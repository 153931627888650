import React, { Fragment } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";
import { useQueryState } from "../../../components/src/use-query-state";

import { DateForm } from "../../../components/src/dateForm";
import { useQueryArgs } from "../../../components/src/common/Utils";

import { serializeSwedenDate } from "../components/dateFormater";
import { SelectColumnFilter } from "../../../components/src/react-table/ReactTableFilters";
import { ReactTable } from "../../../components/src/react-table/ReactTable";

const getValuations = gql`
    query getValuations($startDate: GraphQLSwedenDate, $endDate: GraphQLSwedenDate, $instrumentIds: [GraphQLObjectId!]) {
        valuations(startDate: $startDate, endDate: $endDate, instrumentIds: $instrumentIds) {
            _id
            date
            records {
                bloombergTicker
                source
                prices {
                    type
                    value
                    currency
                }
            }
            instrument {
                _id
                name
                productType
            }
        }
    }
`;

export const ValuationsPage = (): React.ReactElement => {
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(), "YYYY-MM-DD", -5));
    const [endDate] = useQueryState("endDate", serializeSwedenDate(new Date()));
    const { queryArgs } = useQueryArgs();

    const [{ fetching, error, data }] = useQuery({
        query: getValuations,
        variables: {
            startDate: startDate,
            endDate: endDate,
            instrumentIds: queryArgs && queryArgs.instrumentIds ? queryArgs.instrumentIds : null
        },
        requestPolicy: "cache-and-network"
    });

    const columns = React.useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/valuation/${row.original._id}`}>{"#"}</Link>;
                },
                size: 15
            },
            {
                header: "Date",
                accessorKey: "date",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                size: 40
            },
            {
                header: "Source",
                accessorKey: "source",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter,
                size: 175
            },
            {
                header: "Bloomberg ticker",
                accessorKey: "bloombergTicker",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
                },
                size: 80
            },
            {
                header: "Product type",
                accessorKey: "productType",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>,
                filter: SelectColumnFilter
            },
            {
                header: "Currency",
                accessorKey: "priceCurrency",
                filter: SelectColumnFilter
            },

            {
                header: "Price",
                accessorKey: "price",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>
            },
            {
                header: "Clean price",
                accessorKey: "cleanPrice",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>
            },
            {
                header: "Accrued interest",
                accessorKey: "accruedInterest",
                cell: (cellProps) => <div style={{ textAlign: "center" }}>{cellProps.getValue()}</div>
            }
        ],
        []
    );

    const list: any[] = React.useMemo(() => {
        const list: any[] = [];
        if (data) {
            for (let index = 0, l = data.valuations.length; index < l; index++) {
                // if the instrument is not set, the user does not have access to the instrument
                if (data.valuations[index].instrument) {
                    const item: any = {};
                    item._id = data.valuations[index]._id;
                    item.date = data.valuations[index].date;
                    item.instrumentId = data.valuations[index].instrument._id;
                    item.name = data.valuations[index].instrument.name;
                    item.productType = data.valuations[index].instrument.productType;
                    item.hash = "#";

                    // Last "record" contains relevant valuation
                    const lastRecord = data.valuations[index].records[data.valuations[index].records.length - 1];
                    item.source = lastRecord.source;
                    item.bloombergTicker = lastRecord.bloombergTicker;
                    // Loop prices in last record
                    for (let p = 0; p < lastRecord.prices.length; p++) {
                        // Most relevant valuations are of type: Price, CleanPrice and AccruedInterest
                        if (lastRecord.prices[p].type === "Price") {
                            item.price = lastRecord.prices[p].value;
                            item.priceCurrency = lastRecord.prices[p].currency;
                        } else if (lastRecord.prices[p].type === "CleanPrice") {
                            item.cleanPrice = lastRecord.prices[p].value;
                            item.priceCurrency = lastRecord.prices[p].currency;
                        } else if (lastRecord.prices[p].type === "AccruedInterest") {
                            item.accruedInterest = lastRecord.prices[p].value;
                            item.priceCurrency = lastRecord.prices[p].currency;
                        }
                    }
                    list.push(item);
                }
            }
        }
        return list;
    }, [data]);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    //const sixDecPriceFormat = (d) => (typeof d === "number" ? numberFormat(d, "#\xa0##0.000000") : "");

    return (
        <Fragment>
            <div className="d-flex">
                <div className="ms-auto">
                    <Link to={"/valuation/add"}>Add</Link>
                </div>
            </div>
            <div className="d-flex mb-2">
                <DateForm defaultDateString={startDate} dateName="startDate"></DateForm>
                <DateForm className="ms-3" defaultDateString={endDate} dateName="endDate"></DateForm>
            </div>
            <p>Number of valuations: {list.length}</p>
            <ReactTable columns={columns} data={list} defaultHiddenColumns={null} />
        </Fragment>
    );
};
