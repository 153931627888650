import React, { useMemo, Fragment } from "react";
import { cloneDeep } from "lodash";
import { render } from "squirrelly/dist/browser/squirrelly.min.js"; // else we get the node build

import { Grid, Column } from "../../../../components/src";

import { IAnnualReportProps } from "./AnnualReportProps";
import { GeneratedSchemaForRoot } from "./AnnualReportInterface";
import { CountryCodeEnum } from "../../types.generated";
import { exportToXlsx, sheetType } from "../../../../components/src/common/exportToXlsx";
import { Excel } from "../../../../components/src/Svgs";

// Accountant wanted to hide row with only zeros
const filterOutZeroRows = (table: string[][]): string[][] => {
    const result: string[][] = [];
    for (const row of table) {
        // this is dirty
        let allYears = row[1];
        if (row[2]) {
            allYears = allYears + row[2];
        }
        if (row[3]) {
            allYears = allYears + row[3];
        }
        if (row[4]) {
            allYears = allYears + row[4];
        }
        if (row[5]) {
            allYears = allYears + row[5];
        }

        if (!allYears.split("").every((char) => char === "0")) {
            result.push(row);
        }
    }
    return result;
};

export const AnnualReportComponent = ({ report }: IAnnualReportProps): React.ReactElement => {
    const annualReport: GeneratedSchemaForRoot["AnnualReport"] = useMemo(() => {
        // we shall take this from the report collection in the future
        const data: GeneratedSchemaForRoot = report.data as any;
        const annualReport = cloneDeep(data.AnnualReport);
        let globalKey = "";
        try {
            const keys = Object.keys(annualReport);

            for (const key of keys) {
                globalKey = key;
                if (typeof annualReport[key] === "string") {
                    annualReport[key] = render(annualReport[key], annualReport);
                }
            }
        } catch (error) {
            console.error("KEY: ", globalKey);
            console.error(error);
        }
        return annualReport;
    }, [report]);

    const resultSheet = filterOutZeroRows(annualReport.resultSheet);
    const balanceSheet = filterOutZeroRows(annualReport.balanceSheet);

    return (
        <div id="annual-report-component">
            <h5 style={{ textAlign: "center" }}>{annualReport.title}</h5>
            <p style={{ textAlign: "center" }}>{annualReport.fundOrgNumber}</p>
            <p style={{ textAlign: "center" }}>{annualReport.date}</p>
            <h5>{annualReport.managementReportName}</h5>
            <p>{annualReport.managementReportText}</p>
            <h5>{annualReport.focusName}</h5>
            <p>{annualReport.focusText}</p>
            <h5>{annualReport.performanceName}</h5>
            <p>{annualReport.performanceText}</p>
            <h5>{annualReport.riskMethodName}</h5>
            <p>{annualReport.riskMethodText}</p>
            <h5>{annualReport.riskName}</h5>
            <p>{annualReport.riskText}</p>
            <h5>{annualReport.derivativeTradingName}</h5>
            <p>{annualReport.derivativeTradingText}</p>
            <h5>{annualReport.valuationMethodName}</h5>
            <p>{annualReport.valuationMethodText}</p>
            {annualReport.shareholderEngagementPolicyName ? (
                <Fragment>
                    <h5>{annualReport.shareholderEngagementPolicyName}</h5>
                    <p>{annualReport.shareholderEngagementPolicyText}</p>
                </Fragment>
            ) : null}

            <h5>{annualReport.benchmarkName}</h5>
            <p>{annualReport.benchmarkText}</p>
            <div className="pagebreak"> </div>

            <div className="row-col">
                <button
                    id="export-excel"
                    className="export-link"
                    type="button"
                    onClick={() => {
                        const sheetNames = [
                            "Resultaträkning",
                            "Balansräkning",
                            "Förändring av fondförmögenhet",
                            "Fondens innehav",
                            "Derivat och värdepappersfinansierin",
                            "Största emittenter",
                            "Kreditbetyg",
                            "Nyckeltal",
                            "Fondens utveckling"
                        ];
                        const data: sheetType[] = [
                            resultSheet,
                            balanceSheet,
                            annualReport.equityChange,
                            annualReport.positions,
                            annualReport.derivativesSecurities,
                            annualReport.issuers,
                            annualReport.ratings,
                            annualReport.keyFigures,
                            annualReport.fundFigures
                        ];
                        const fileName = annualReport.fundName + "_" + annualReport.date + ".xlsx";

                        exportToXlsx(data, fileName, sheetNames);
                    }}
                >
                    <Excel />
                </button>
            </div>
            {resultSheet && resultSheet.length > 1 && !annualReport.semiAnnual ? (
                <div className="mt-5">
                    <h5>{"Resultaträkning"}</h5>
                    <Grid data={resultSheet} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        {annualReport.firstTradeDate && annualReport.firstTradeDate <= resultSheet[0][2].toString() ? (
                            <Column field="2" className="right" />
                        ) : null}
                    </Grid>
                </div>
            ) : null}
            {balanceSheet && balanceSheet.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Balansräkning"}</h5>
                    <Grid data={balanceSheet} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        {annualReport.firstTradeDate && annualReport.firstTradeDate <= balanceSheet[0][2].toString() ? (
                            <Column field="2" className="right" />
                        ) : null}
                    </Grid>
                </div>
            ) : null}
            {annualReport.equityChange && annualReport.equityChange.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Förändring av fondförmögenhet"}</h5>
                    <Grid data={annualReport.equityChange} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.equityChange[0][2].toString() ? (
                            <Column field="2" className="right" />
                        ) : null}
                    </Grid>
                </div>
            ) : null}
            {annualReport.positions && annualReport.positions.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Fondens innehav"}</h5>
                    <Grid data={annualReport.positions} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field={"0"} />
                        <Column
                            field={"1"}
                            format={(value) => {
                                if (value === CountryCodeEnum.Unknown) {
                                    return "";
                                } else {
                                    return value;
                                }
                            }}
                        />
                        <Column field="2" className="right" />
                        <Column field="3" className="right" />
                        <Column field="4" className="right" />
                    </Grid>
                </div>
            ) : null}

            {annualReport.derivativesSecurities && annualReport.derivativesSecurities.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Derivat och värdepappersfinansiering"}</h5>
                    <Grid data={annualReport.derivativesSecurities} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" />
                        <Column field="2" className="right" />
                        <Column field="3" className="right" />
                    </Grid>
                </div>
            ) : null}

            {annualReport.issuers && annualReport.issuers.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Största emittenter"}</h5>
                    <Grid data={annualReport.issuers} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                    </Grid>
                </div>
            ) : null}

            {annualReport.ratings && annualReport.ratings.length > 1 && !annualReport.semiAnnual ? (
                <div className="mt-5">
                    <h5>{"Kreditbetyg"}</h5>
                    <Grid data={annualReport.ratings} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        <Column field="2" className="right" />
                    </Grid>
                </div>
            ) : null}
            <div className="pagebreak"> </div>
            {annualReport.keyFigures && annualReport.keyFigures.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Nyckeltal"}</h5>
                    <Grid data={annualReport.keyFigures} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.keyFigures[0][2].substring(0, 4) ? (
                            <Column field="2" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.keyFigures[0][3].substring(0, 4) ? (
                            <Column field="3" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.keyFigures[0][4].substring(0, 4) ? (
                            <Column field="4" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.keyFigures[0][5].substring(0, 4) ? (
                            <Column field="5" className="right" />
                        ) : null}
                    </Grid>
                </div>
            ) : null}
            {annualReport.keyFiguresFootnote ? <p>{annualReport.keyFiguresFootnote}</p> : null}
            <div className="pagebreak"> </div>
            {annualReport.fundFigures && annualReport.fundFigures.length > 1 ? (
                <div className="mt-5">
                    <h5>{"Fondens Utveckling"}</h5>
                    <Grid data={annualReport.fundFigures} headersRowIndex={0} hideDownload={true} className="no-scroll">
                        <Column field="0" />
                        <Column field="1" className="right" />
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][2].substring(0, 4) ? (
                            <Column field="2" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][3].substring(0, 4) ? (
                            <Column field="3" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][4].substring(0, 4) ? (
                            <Column field="4" className="right" />
                        ) : null}
                        {annualReport.firstTradeDate &&
                        annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][5].substring(0, 4) ? (
                            <Column field="5" className="right" />
                        ) : null}
                    </Grid>
                    {annualReport.fundFigures && annualReport.fundFigures[0].length > 6 ? (
                        <Grid data={annualReport.fundFigures} headersRowIndex={0} hideDownload={true} className="no-scroll">
                            <Column field="0" />
                            {annualReport.firstTradeDate &&
                            annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][6].substring(0, 4) ? (
                                <Column field="6" className="right" />
                            ) : null}
                            {annualReport.firstTradeDate &&
                            annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][7].substring(0, 4) ? (
                                <Column field="7" className="right" />
                            ) : null}
                            {annualReport.firstTradeDate &&
                            annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][8].substring(0, 4) ? (
                                <Column field="8" className="right" />
                            ) : null}
                            {annualReport.firstTradeDate &&
                            annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][9].substring(0, 4) ? (
                                <Column field="9" className="right" />
                            ) : null}
                            {annualReport.firstTradeDate &&
                            annualReport.firstTradeDate.substring(0, 4) <= annualReport.fundFigures[0][10].substring(0, 4) ? (
                                <Column field="10" className="right" />
                            ) : null}
                        </Grid>
                    ) : null}
                </div>
            ) : null}
            {annualReport.fundFiguresFootnote ? <p>{annualReport.fundFiguresFootnote}</p> : null}
            <h5>{annualReport.accountingPrinciplesName}</h5>
            <p>{annualReport.accountingPrinciplesText}</p>
            <h5>{annualReport.compensationPrinciplesName}</h5>
            <p>{annualReport.compensationPrinciplesText}</p>
            <p>{annualReport.employeesText}</p>
        </div>
    );
};
