import React from "react";
import { map, get } from "lodash";
import { formatValue, processClassName as pc } from "./helpers";

interface ItemI {
    idx: number;
    item: any;
    items: any[];
    itemProps?: any[];
    trClassName?: string | any;
    trStyle?: any;
    negativeClassName?: string;
}

/*

Item.propTypes = {
    idx: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    itemProps: PropTypes.array.isRequired,
    trClassName: PropTypes.string,
    trStyle: PropTypes.object
};
*/

export const Item = ({ idx, item, items, itemProps, trClassName, trStyle, negativeClassName }: ItemI): React.ReactElement => (
    <tr className={trClassName ? trClassName(item, idx, items) : null} style={trStyle ? trStyle(item, idx, items) : null}>
        {map(itemProps, (itemProp, i) => {
            const value = get(item, itemProp.key);
            const isComponent = React.isValidElement(value);

            const tdClasses = [
                pc(itemProp.className, item, idx, items),
                negativeClassName && typeof value === "number" && value < 0 ? negativeClassName : null
            ].filter((c) => c);

            const classNames = tdClasses.length > 0 ? tdClasses.join(" ") : null;

            return (
                <td key={i} className={classNames} onClick={itemProp.onClick ? (e) => itemProp.onClick(e, idx) : null}>
                    {!isComponent && formatValue(value, itemProp.format, item, idx, items)}
                    {isComponent && value}
                </td>
            );
        })}
    </tr>
);
