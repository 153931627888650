/* eslint-disable */
import type * as Types from '../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetDataQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TicketFilterInput>;
}>;


export type GetDataQuery = { __typename?: 'Query', me?: { __typename?: 'Me', _id: string, name: string, frontendRole: { __typename?: 'Role', assets: Array<Types.PermissionAssetEnum> } } | null, clients?: Array<{ __typename?: 'Party', _id: string, name: string } | null> | null, ticketUsers?: Array<{ __typename?: 'TicketUser', _id: string, name: string, clientIds: Array<string> }> | null, tickets?: Array<{ __typename?: 'Ticket', _id: string, title: string, clientId: string, type: Types.TicketTypeEnum, creatorId: string, createTimestamp: any, updateTimestamp?: any | null, client: { __typename?: 'PartyInfo', _id: string, name: string }, creator: { __typename?: 'UserInfo', name: string }, references?: Array<{ __typename?: 'TicketReference', documentId: string, collection: Types.CollectionNameEnum }> | null, state: { __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }, previousStates: Array<{ __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }> } | null> | null };

export type UpsertTicketsMutationVariables = Types.Exact<{
  input: Array<Types.TicketInput> | Types.TicketInput;
}>;


export type UpsertTicketsMutation = { __typename?: 'Mutation', upsertTickets?: Array<{ __typename?: 'Ticket', _id: string, title: string, clientId: string, type: Types.TicketTypeEnum, creatorId: string, createTimestamp: any, updateTimestamp?: any | null, client: { __typename?: 'PartyInfo', _id: string, name: string }, creator: { __typename?: 'UserInfo', name: string }, references?: Array<{ __typename?: 'TicketReference', documentId: string, collection: Types.CollectionNameEnum }> | null, state: { __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }, previousStates: Array<{ __typename?: 'TicketState', status: Types.TicketStatusEnum, assigneeIds: Array<string>, comment: string, updateUserId?: string | null, timestamp?: any | null, assignees: Array<{ __typename?: 'UserInfo', name: string }>, updateUserInfo?: { __typename?: 'UserInfo', name: string } | null }> } | null> | null };


export const GetDataDocument = gql`
    query getData($filter: TicketFilterInput) {
  me {
    _id
    name
    frontendRole {
      assets
    }
  }
  clients: parties(filter: {typeIn: [Client]}) {
    _id
    name
  }
  ticketUsers {
    _id
    name
    clientIds
  }
  tickets(filter: $filter) {
    _id
    title
    clientId
    client {
      _id
      name
    }
    type
    creatorId
    creator {
      name
    }
    createTimestamp
    updateTimestamp
    references {
      documentId
      collection
    }
    state {
      status
      assigneeIds
      assignees {
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
    previousStates {
      status
      assigneeIds
      assignees {
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
  }
}
    `;

export function useGetDataQuery(options?: Omit<Urql.UseQueryArgs<GetDataQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDataQuery, GetDataQueryVariables>({ query: GetDataDocument, ...options });
};
export const UpsertTicketsDocument = gql`
    mutation upsertTickets($input: [TicketInput!]!) {
  upsertTickets(input: $input) {
    _id
    title
    clientId
    client {
      _id
      name
    }
    type
    creatorId
    creator {
      name
    }
    createTimestamp
    updateTimestamp
    references {
      documentId
      collection
    }
    state {
      status
      assigneeIds
      assignees {
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
    previousStates {
      status
      assigneeIds
      assignees {
        name
      }
      comment
      updateUserId
      updateUserInfo {
        name
      }
      timestamp
    }
  }
}
    `;

export function useUpsertTicketsMutation() {
  return Urql.useMutation<UpsertTicketsMutation, UpsertTicketsMutationVariables>(UpsertTicketsDocument);
};