import React from "react";
import { Markdown } from "../../../../components/src/Markdown";

const text = `
# Interest Rate Sensitivity

Interest rate sensitivity is a measure of how much the price of a fixed-income asset will fluctuate 
as a result of changes in the interest rate environment. Securities that are more sensitive have 
greater price fluctuations than those with less sensitivity.

Fixed-income securities and interest rates are inversely correlated. Therefore, as interest rates 
rise, prices of fixed-income securities tend to fall. When applied to calculate fixed income 
securities, interest rate sensitivity is known as the asset's duration. This is one way to determine how 
interest rates affect a fixed-income security portfolio. The higher a bond or bond fund's duration, 
the more sensitive the bond or bond fund to changes in interest rates.

The duration of fixed-income securities gives investors an idea of the sensitivity to potential 
interest rate changes. Duration is a good measure of interest rate sensitivity because the calculation 
includes multiple bond characteristics, such as coupon payments and maturity.

## Credit Delta

For bonds, credit delta measures changes in currency value for the position with respect to a 1 basis 
point shift in discount curves.

For credit default swap (CDS), it measures the currency present value changes for each basis point 
shift in the credit curve.

## Credit Duration

Credit delta converted to years.

## Interest Rate Delta

Measures changes in currency value for the position with respect to a 1 basis point shift in in all 
used yield curves.

## Modified Duration

Interest yield delta converted to years.

## Interest Rate Delta Buckets

Measures changes in currency value for the position with respect to a 1 basis point triangular shift 
in in all used yield curves centered at the buckets time period. For the first period the curves first 
point is shifted together with the centered of the first bucket. For the last period the curves end point 
is shifted together with the point centered of the last bucket. This is done to cover the whole range of 
all buckets and therefore the sum of all buckets equals the interest yield delta.
`;

export const DurationRiskDocPage = (): React.ReactElement => {
    return (
        <div className="container">
            <Markdown key={(Math.random() + 1).toString(36).substring(7)} markdown={text} />
        </div>
    );
};
