import React, { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
import mk from "markdown-it-katex";
import "katex/dist/katex.min.css";
import mermaid from "mermaid";

const mdParser = new MarkdownIt();
mdParser.use(mk, { throwOnError: false, errorColor: " #cc0000" });

mermaid.initialize({ startOnLoad: false });

export const Markdown = ({ markdown }: { markdown: string }): React.ReactElement => {
    const [html, setHtml] = useState(null);

    useEffect(() => {
        const htmlString = mdParser.render(markdown);
        setHtml(htmlString);
    }, [markdown]);

    useEffect(() => {
        const renderMermaid = async () => {
            await mermaid.run({
                querySelector: ".language-mermaid"
            });
        };

        renderMermaid().catch(console.error);
    }, [html]);

    return <span className="markdown" dangerouslySetInnerHTML={{ __html: html }} />;
};
