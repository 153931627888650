import {
    AccountingCompanyTypeEnum,
    AccountingLanguageEnum,
    TAccountGroup1Enum,
    TAccountGroup2Enum,
    TAccountGroup3Enum,
    TAccountGroup4Enum,
    TAccountGroup5Enum
} from "./types.generated";

export type TranslationMapping = {
    [key in AccountingLanguageEnum]?: CompanyMapping;
};

export type CompanyMapping = {
    [key in AccountingCompanyTypeEnum]?: string;
};

export type GroupMapping = {
    sorting: number;
    translation: TranslationMapping;
};

export type Group1Mapping = {
    [key in TAccountGroup2Enum]?: Group2Mapping;
} & GroupMapping;

export type Group2Mapping = {
    [key in TAccountGroup3Enum]?: Group3Mapping;
} & GroupMapping;

export type Group3Mapping = {
    [key in TAccountGroup4Enum]?: Group4Mapping;
} & GroupMapping;

export type Group4Mapping = {
    [key in TAccountGroup5Enum]?: GroupMapping;
} & GroupMapping;

export type TAccountGroupMappingType = {
    [key in TAccountGroup1Enum]?: Group1Mapping;
};

export const mapping: TAccountGroupMappingType = {
    Assets: {
        translation: {
            Sv: {
                SwedishMutualFund: "Tillgångar",
                GeneralPortfolio: "Tillgångar",
                SwedishPensionTrust: "Tillgångar"
            }
        },
        sorting: 1,
        A: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Finansiella Instrument med positivt marknadsvärde",
                    GeneralPortfolio: "Finansiella Instrument med positivt marknadsvärde",
                    SwedishPensionTrust: "Finansiella Tillgångar"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Överlåtbara värdepapper",
                        GeneralPortfolio: "Överlåtbara värdepapper",
                        SwedishPensionTrust: "Värdepappersinnehav"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Penningmarknadsinstrument",
                        GeneralPortfolio: "Penningmarknadsinstrument",
                        SwedishPensionTrust: "Andelar i koncernföretag"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "OTC-derivatinstrument med positivt marknadsvärde",
                        GeneralPortfolio: "OTC-derivatinstrument med positivt marknadsvärde",
                        SwedishPensionTrust: "Fordringar hos koncernföretag"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            D: {
                sorting: 4,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga derivatinstrument med positivt marknadsvärde",
                        GeneralPortfolio: "Övriga derivatinstrument med positivt marknadsvärde",
                        SwedishPensionTrust: "Derivatinstrument"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            E: {
                sorting: 5,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Fondandelar",
                        GeneralPortfolio: "Fondandelar",
                        SwedishPensionTrust: "Upplupna Räntor"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        B: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "Placeringar med positivt marknadsvärde",
                    GeneralPortfolio: "Placeringar med positivt marknadsvärde",
                    SwedishPensionTrust: "Kortfristiga fordringar"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut",
                        SwedishPensionTrust: "Förutbetalda kostnader och upplupna intäkter"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut",
                        SwedishPensionTrust: "Upplupna Räntor"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut",
                        SwedishPensionTrust: "Derivatinstrument med positivt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            D: {
                sorting: 4,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut",
                        SwedishPensionTrust: "Övriga fordringar"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            E: {
                sorting: 5,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Placering på konto hos kreditinstitut",
                        GeneralPortfolio: "Placering på konto hos kreditinstitut",
                        SwedishPensionTrust: "Fondlikvidfordringar"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        C: {
            sorting: 3,
            translation: {
                Sv: {
                    SwedishMutualFund: "C",
                    GeneralPortfolio: "C",
                    SwedishPensionTrust: "Kassa och Bank"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Bankmedel och övriga likvida medel",
                        GeneralPortfolio: "Bankmedel och övriga likvida medel",
                        SwedishPensionTrust: "Bankkonto"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Förutbetalda kostnader och upplupna intäkter",
                        GeneralPortfolio: "Förutbetalda kostnader och upplupna intäkter",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga tillgångar",
                        GeneralPortfolio: "Övriga tillgångar",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        }
    },
    Liabilities: {
        sorting: 1,
        translation: {
            Sv: {
                SwedishMutualFund: "Skulder",
                GeneralPortfolio: "Skulder",
                SwedishPensionTrust: "Skulder"
            }
        },
        A: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Finansiella instrument med negativt marknadsvärde",
                    GeneralPortfolio: "Finansiella instrument med negativt marknadsvärde",
                    SwedishPensionTrust: "Kortfristiga fordringar"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "OTC-derivat med negativt marknadsvärde",
                        GeneralPortfolio: "OTC-derivat med negativt marknadsvärde",
                        SwedishPensionTrust: "Upplupna kostnader"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga derivatinstrument med negativt marknadsvärde",
                        GeneralPortfolio: "Övriga derivatinstrument med negativt marknadsvärde",
                        SwedishPensionTrust: "Upplupna räntor"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga finansiella instrument med negativt marknadsvärde",
                        GeneralPortfolio: "Övriga finansiella instrument med negativt marknadsvärde",
                        SwedishPensionTrust: "Derivatinstrument med negativt marknadsvärde"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            D: {
                sorting: 4,
                translation: {
                    Sv: {
                        SwedishMutualFund: "",
                        GeneralPortfolio: "",
                        SwedishPensionTrust: "Övriga kortfristiga skulder"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            E: {
                sorting: 5,
                translation: {
                    Sv: {
                        SwedishMutualFund: "",
                        GeneralPortfolio: "",
                        SwedishPensionTrust: "Fondlikvidskulder"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        B: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "B",
                    GeneralPortfolio: "B",
                    SwedishPensionTrust: "B"
                }
            },
            A: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Skatteskulder",
                        GeneralPortfolio: "Skatteskulder",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            B: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Upplupna kostnader och förutbetalda intäkter",
                        GeneralPortfolio: "Upplupna kostnader och förutbetalda intäkter",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            C: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Övriga skulder",
                        GeneralPortfolio: "Övriga skulder",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        }
    },
    Equity: {
        sorting: 2,
        translation: {
            Sv: {
                SwedishMutualFund: "Fondförmögenhet",
                GeneralPortfolio: "Eget kapital",
                SwedishPensionTrust: "Eget kapital"
            }
        },
        ShareCapital: {
            sorting: 1,
            translation: {
                Sv: {
                    SwedishMutualFund: "Tecknade andelar",
                    GeneralPortfolio: "Aktiekapital",
                    SwedishPensionTrust: "Eget kapital"
                }
            },
            ShareCapital: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Tecknade andelar",
                        GeneralPortfolio: "Aktiekapital",
                        SwedishPensionTrust: "Ingående kapital"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            Contribution: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Tecknade andelar",
                        GeneralPortfolio: "Aktiekapital",
                        SwedishPensionTrust: "Tillfört kapital"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            Compensation: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Utdelning",
                        GeneralPortfolio: "Utdelning",
                        SwedishPensionTrust: "Lämnad gottgörelse"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        RetainedEarnings: {
            sorting: 2,
            translation: {
                Sv: {
                    SwedishMutualFund: "Balanserat resultat",
                    GeneralPortfolio: "Balanserat resultat",
                    SwedishPensionTrust: "Balanserat resultat"
                }
            },
            RetainedEarnings: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Balanserat resultat",
                        GeneralPortfolio: "Balanserat resultat",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        DividendPaid: {
            sorting: 3,
            translation: {
                Sv: {
                    SwedishMutualFund: "Lämnad utdelning",
                    GeneralPortfolio: "Lämnad utdelning",
                    SwedishPensionTrust: "Lämnad gottgörelse"
                }
            },
            DividendPaid: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Lämnad utdelning",
                        GeneralPortfolio: "Lämnad utdelning",
                        SwedishPensionTrust: "Lämnad gottgörelse"
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        },
        ProfitLossCurrentYear: {
            sorting: 4,
            translation: {
                Sv: {
                    SwedishMutualFund: "Årets resultat",
                    GeneralPortfolio: "Årets resultat",
                    SwedishPensionTrust: "Årets resultat"
                }
            },
            Revenue: {
                sorting: 1,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Intäkter och värdeförändring",
                        GeneralPortfolio: "Intäkter och värdeförändring",
                        SwedishPensionTrust: "Stiftelsens intäkter"
                    }
                },
                A: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på överlåtbara värdepapper",
                            GeneralPortfolio: "Värdeförändring på överlåtbara värdepapper",
                            SwedishPensionTrust: "Utdelningar"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                B: {
                    sorting: 2,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på penningmarknadsinstrument",
                            GeneralPortfolio: "Värdeförändring på penningmarknadsinstrument",
                            SwedishPensionTrust: "Ränteintäkter"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                C: {
                    sorting: 3,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på OTC-derivat",
                            GeneralPortfolio: "Värdeförändring på OTC-derivat",
                            SwedishPensionTrust: "Nettoresultat av finansiella placeringar"
                        }
                    },
                    A: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: "Realisationsvinster"
                            }
                        }
                    },
                    B: {
                        sorting: 2,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: "Realisationsförluster"
                            }
                        }
                    },
                    C: {
                        sorting: 3,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: "Årets orealiserade värdeförändring"
                            }
                        }
                    },
                    D: {
                        sorting: 4,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: "Valutaresultat"
                            }
                        }
                    }
                },
                D: {
                    sorting: 4,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på övriga derivatinstrument",
                            GeneralPortfolio: "Värdeförändring på övriga derivatinstrument",
                            SwedishPensionTrust: "Övriga intäkter"
                        }
                    },
                    A: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: "Fondrabatt"
                            }
                        }
                    }
                },
                E: {
                    sorting: 5,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Värdeförändring på fondandelar",
                            GeneralPortfolio: "Värdeförändring på fondandelar",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                F: {
                    sorting: 6,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Ränteintäkter",
                            GeneralPortfolio: "Ränteintäkter",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                G: {
                    sorting: 7,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Utdelningar",
                            GeneralPortfolio: "Utdelningar",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                H: {
                    sorting: 8,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Valutakursvinster och -förluster netto",
                            GeneralPortfolio: "Valutakursvinster och -förluster netto",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                I: {
                    sorting: 9,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga finansiella intäkter",
                            GeneralPortfolio: "Övriga finansiella intäkter",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                J: {
                    sorting: 10,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga intäkter",
                            GeneralPortfolio: "Övriga intäkter",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            Expenses: {
                sorting: 2,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Kostnader",
                        GeneralPortfolio: "Kostnader",
                        SwedishPensionTrust: "Stiftelsens kostnader"
                    }
                },
                ManagementFees: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Förvaltningskostnader",
                            GeneralPortfolio: "Förvaltningskostnader",
                            SwedishPensionTrust: "Förvaltningskostnader"
                        }
                    },
                    A: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till fondbolaget",
                                GeneralPortfolio: "Ersättning till fondbolaget",
                                SwedishPensionTrust: ""
                            }
                        }
                    },
                    B: {
                        sorting: 2,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till förvaringsinstitutet",
                                GeneralPortfolio: "Ersättning till förvaringsinstitutet",
                                SwedishPensionTrust: ""
                            }
                        }
                    },
                    C: {
                        sorting: 2,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "Ersättning till tillsynsmyndighet",
                                GeneralPortfolio: "Ersättning till tillsynsmyndighet",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                A: {
                    sorting: 2,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Ersättning till revisorer",
                            GeneralPortfolio: "Ersättning till revisorer",
                            SwedishPensionTrust: "Externa kostnader"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                B: {
                    sorting: 3,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Räntekostnader",
                            GeneralPortfolio: "Räntekostnader",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                C: {
                    sorting: 4,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga finansiella kostnader",
                            GeneralPortfolio: "Övriga finansiella kostnader"
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                },
                D: {
                    sorting: 5,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "Övriga kostnader",
                            GeneralPortfolio: "Övriga kostnader",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            },
            Tax: {
                sorting: 3,
                translation: {
                    Sv: {
                        SwedishMutualFund: "Skatt",
                        GeneralPortfolio: "Skatt",
                        SwedishPensionTrust: ""
                    }
                },
                None: {
                    sorting: 1,
                    translation: {
                        Sv: {
                            SwedishMutualFund: "",
                            GeneralPortfolio: "",
                            SwedishPensionTrust: ""
                        }
                    },
                    None: {
                        sorting: 1,
                        translation: {
                            Sv: {
                                SwedishMutualFund: "",
                                GeneralPortfolio: "",
                                SwedishPensionTrust: ""
                            }
                        }
                    }
                }
            }
        }
    }
};
