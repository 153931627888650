import React from "react";
import { orderBy, snakeCase } from "lodash";

import { DetailsList, Selection, IColumn, DetailsListLayoutMode, CheckboxVisibility, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";

import { Excel } from "../../../components/src/Svgs";
import { exportToXlsx2 } from "../../../components/src/common/exportToXlsx2";

interface IMultipleSelectDetailsGrid {
    items: any[];
    columns?: IColumn[];
    commands?: ICommandBarItemProps[];
    onSelectedChange?(ids: any[]): any;
}

export const MultipleSelectDetailsGrid = ({
    items,
    columns,
    commands,
    onSelectedChange
}: IMultipleSelectDetailsGrid): React.ReactElement => {
    const [sortedItems, setSortedItems] = React.useState(items);
    const [selectionDetails, setSelectionDetails] = React.useState("No items selected");
    const selection = new Selection({
        onSelectionChanged: () => setSelectionDetails(getSelectionDetails())
    });

    const getSelectionDetails = (): string => {
        if (onSelectedChange) {
            onSelectedChange(selection.getSelection());
        }
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return "No items selected";
            case 1:
                return "1 item selected";
            default:
                return `${selectionCount} items selected`;
        }
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = orderBy(items, currColumn.fieldName, currColumn.isSortedDescending ? "desc" : "asc");

        setSortedItems(newItems);
    };

    // assign column click
    columns.forEach((c) => (c.onColumnClick = onColumnClick));

    const farItems: ICommandBarItemProps[] = [
        {
            key: "selected",
            text: selectionDetails
        },
        {
            key: "download",
            text: "Download",
            ariaLabel: "Download",
            iconOnly: true,
            //iconProps: { iconName: "ExcelDocument" },
            onRenderIcon: () => <Excel style={{ maxWidth: 24, maxHeight: 24 }} />,
            onClick: () => {
                const randomString = Math.random().toString(36).slice(2);
                const filename = snakeCase(randomString);
                exportToXlsx2(sortedItems, `${filename}.xlsx`);
            }
        }
    ];

    return (
        <div>
            <CommandBar items={commands || []} farItems={farItems} />
            <DetailsList
                columns={columns}
                items={sortedItems}
                setKey="set"
                selectionMode={SelectionMode.multiple}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                compact={true}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.onHover}
            />
        </div>
    );
};
