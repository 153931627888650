import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { twoDecPriceFormat } from "../../../../common/src";

import { PartyAccountType, InstrumentModelTypeEnum, Party } from "../../types.generated";
import { usePrevious, useQueryArgs } from "../../../../components/src/common/Utils";
import { PerformanceGraphsTables } from "./PerformanceGraphsTables";
import { AttributionGraph, AttributionSelectorEnum, PlotTypeEnum } from "./Attribution";
import { useQueryState } from "../../../../components/src/use-query-state";
import { PeriodSelector } from "../../components/PeriodSelector";
import { TableGrouper } from "../../../../components/src/react-table/TableGrouper";

import {
    excludeInAttribution,
    getRowData,
    showModelIndex,
    aggregate2DArrayOfNumbers,
    getTopBottomInstrumentsFromSelectedRow,
    getTopBottomInstruments
} from "./functions";
import { SelectedRowType, PerformanceTreeProps, ProcessedData } from "./interfaces";
import { isValidRow } from "./functions";
import { accountReturn, benchmarkReturn, diffReturn } from "./components";
import { useProcessedData } from "./hooks";

export const PerformanceTree = ({ client, data, endDate }: PerformanceTreeProps): React.ReactElement => {
    const [startDate] = useQueryState("startDate", client.firstTradeDate);

    const { pushQueryArgs: setFormData } = useQueryArgs();

    const [errors] = useState<string[]>([]);

    // this should set the data needed for the graph. TimeSeries for performance and array of TimeSeries for Benchmark
    const [selectedRow, setSelectedRow] = useState<SelectedRowType>({
        name: "",
        performance: null,
        values: [],
        instrumentPerformances: [],
        benchmarks: [],
        riskFree: null,
        startDate: startDate,
        endDate: endDate,
        dateSeries: [],
        positionFlag: []
    });

    const [hideZerosChecked, setHideZerosChecked] = useState(false);

    const previousSelectedRow: SelectedRowType = usePrevious(selectedRow);
    const previousSelectedClient: Partial<Party> = usePrevious(client);

    const sortBy = React.useMemo(
        () => [
            {
                id: "level1",
                desc: false
            },
            {
                id: "level2",
                desc: false
            },
            {
                id: "level3",
                desc: false
            },
            {
                id: "level4",
                desc: false
            },
            {
                id: "instrumentName",
                desc: false
            }
        ],
        []
    );

    const processedData: ProcessedData = useProcessedData({ endDate, startDate, data });

    const columns = React.useMemo(() => {
        if (!processedData) return [];
        const { dataWithLevels, dateSeries, benchmarksById, accountSeriesById, accountPositionsById, modelSeriesById, firstSelectedRow } =
            processedData;
        return [
            {
                Header: "",
                id: "metaData",
                columns: [
                    {
                        id: "rootId",
                        header: () => {
                            if (dataWithLevels && dataWithLevels.length > 0) {
                                return <Link to={`/parties/${client._id}/accounts/${dataWithLevels[0].accountId}`}>Client</Link>;
                            } else {
                                return <Link to={`/parties/${client._id}/accounts`}>Client</Link>;
                            }
                        },
                        accessorKey: "rootId",
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            return (
                                <span
                                    className="hover__highlight__tree"
                                    onClick={() => {
                                        setSelectedRow(firstSelectedRow);
                                    }}
                                >
                                    {row.original.root}
                                </span>
                            );
                        },
                        getIsGrouped: () => true
                    },
                    {
                        header: "Client Description",
                        accessorKey: "root",
                        aggregationFn: (_leafRows: any, childRows: any) => childRows[0]
                    },
                    {
                        id: "level1Id",
                        header: () => {
                            if (dataWithLevels && dataWithLevels.length > 1) {
                                return <Link to={`/parties/${client._id}/accounts/${dataWithLevels[1].accountId}`}>Account L1</Link>;
                            } else {
                                return <Link to={`/parties/${client._id}/accounts`}>Account L1</Link>;
                            }
                        },
                        accessorKey: "level1Id",
                        cell: (cellProps) => {
                            const { row, cell } = cellProps;
                            return (
                                <span
                                    className="hover__highlight__tree"
                                    onClick={() => {
                                        const value = cell.getValue();
                                        const account = processedData.accountsById[value];

                                        const selectedRow = getRowData(
                                            row,
                                            processedData,
                                            row.original.level1,
                                            startDate,
                                            endDate,
                                            showModelIndex(account, processedData.maxAccountLevels, 1)
                                        );
                                        setSelectedRow(selectedRow);
                                    }}
                                >
                                    {row.original.level1}
                                </span>
                            );
                        }
                    },
                    {
                        id: "level2Id",
                        header: () => {
                            if (dataWithLevels && dataWithLevels.length > 2) {
                                return <Link to={`/parties/${client._id}/accounts/${dataWithLevels[2].accountId}`}>Account L2</Link>;
                            } else {
                                return <Link to={`/parties/${client._id}/accounts`}>Account L2</Link>;
                            }
                        },
                        accessorKey: "level2Id",
                        cell: (cellProps) => {
                            const { row, cell } = cellProps;
                            return (
                                <span
                                    className="hover__highlight__tree"
                                    onClick={() => {
                                        const value = cell.getValue();
                                        const account = processedData.accountsById[value];

                                        const selectedRow = getRowData(
                                            row,
                                            processedData,
                                            row.original.level2,
                                            startDate,
                                            endDate,
                                            showModelIndex(account, processedData.maxAccountLevels, 2)
                                        );
                                        setSelectedRow(selectedRow);
                                    }}
                                >
                                    {row.original.level2}
                                </span>
                            );
                        }
                    },
                    {
                        id: "level3Id",
                        header: () => {
                            if (dataWithLevels && dataWithLevels.length > 3) {
                                return <Link to={`/parties/${client._id}/accounts/${dataWithLevels[3].accountId}`}>Account L3</Link>;
                            } else {
                                return <Link to={`/parties/${client._id}/accounts`}>Account L3</Link>;
                            }
                        },
                        accessorKey: "level3Id",
                        cell: (cellProps) => {
                            const { row, cell } = cellProps;
                            return (
                                <span
                                    className="hover__highlight__tree"
                                    onClick={() => {
                                        const value = cell.getValue();
                                        const account = processedData.accountsById[value];

                                        const selectedRow = getRowData(
                                            row,
                                            processedData,
                                            row.original.level3,
                                            startDate,
                                            endDate,
                                            showModelIndex(account, processedData.maxAccountLevels, 3)
                                        );
                                        setSelectedRow(selectedRow);
                                    }}
                                >
                                    {row.original.level3}
                                </span>
                            );
                        }
                    },
                    {
                        id: "level4Id",
                        header: () => {
                            if (dataWithLevels && dataWithLevels.length > 4) {
                                return <Link to={`/parties/${client._id}/accounts/${dataWithLevels[4].accountId}`}>Account L4</Link>;
                            } else {
                                return <Link to={`/parties/${client._id}/accounts`}>Account L4</Link>;
                            }
                        },
                        accessorKey: "level4Id",
                        cell: (cellProps) => {
                            const { row, cell } = cellProps;
                            return (
                                <span
                                    className="hover__highlight__tree"
                                    onClick={() => {
                                        const value = cell.getValue();
                                        const account = processedData.accountsById[value];

                                        const selectedRow = getRowData(
                                            row,
                                            processedData,
                                            row.original.level4,
                                            startDate,
                                            endDate,
                                            showModelIndex(account, processedData.maxAccountLevels, 4)
                                        );
                                        setSelectedRow(selectedRow);
                                    }}
                                >
                                    {row.original.level4}
                                </span>
                            );
                        }
                    },
                    {
                        header: "Account L1 Description",
                        accessorKey: "level1",
                        aggregationFn: (_leafRows: any, childRows: any) => childRows[0]
                    },
                    {
                        header: "Account L2 Description",
                        accessorKey: "level2",
                        aggregationFn: (_leafRows: any, childRows: any) => childRows[0]
                    },
                    {
                        header: "Account L3 Description",
                        accessorKey: "level3",
                        aggregationFn: (_leafRows: any, childRows: any) => childRows[0]
                    },
                    {
                        header: "Account L4 Description",
                        accessorKey: "level4",
                        aggregationFn: (_leafRows: any, childRows: any) => childRows[0]
                    },
                    {
                        id: "instrumentName",
                        accessorFn: (row) => (row.instrument.longName ? row.instrument.longName : row.instrument.name),
                        header: "Instrument",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const benchmark = row.getIsGrouped()
                                ? benchmarksById[row.groupingValue]
                                : benchmarksById[row.original.instrumentId]
                                  ? benchmarksById[row.original.instrumentId]
                                  : benchmarksById[row.original.accountId];
                            return (
                                <span>
                                    {cellProps.getValue() ? (
                                        <Link className="me-1" to={`/instruments/${row.original.instrumentId}`}>
                                            #
                                        </Link>
                                    ) : null}
                                    <span
                                        className="hover__highlight__tree"
                                        onClick={() => {
                                            const selectedRow = getRowData(
                                                row,
                                                processedData,
                                                cellProps.getValue(),
                                                startDate,
                                                endDate,
                                                false,
                                                benchmark
                                            );
                                            setSelectedRow(selectedRow);
                                        }}
                                    >
                                        {cellProps.getValue()}
                                    </span>
                                </span>
                            );
                        }
                    },

                    {
                        header: "First trade date",
                        accessorKey: "firstTradeDate",
                        aggregationFn: (_leafRows: any, childRows: any) => {
                            let minDateString = "2999-12-30";
                            for (let i = 0; i < childRows.length; i++) {
                                if (childRows[i].original.firstTradeDate < minDateString) {
                                    minDateString = childRows[i].original.firstTradeDate;
                                }
                            }
                            return minDateString;
                        },
                        getCanGroup: () => false
                    },
                    {
                        header: "Value (SEK)",
                        id: "valueSEK",
                        accessorFn: (row) => {
                            return row.values[row.values.length - 1];
                        },
                        aggregationFn: "sum",
                        cell: (cellProps) => <div style={{ textAlign: "right" }}>{twoDecPriceFormat(cellProps.getValue())}</div>,
                        aggregatedCell: null, //use 'cell'
                        getCanGroup: () => false
                    },
                    {
                        header: "Values",
                        accessorKey: "values",
                        aggregationFn: (leafRows) => aggregate2DArrayOfNumbers(leafRows),
                        cell: (_cellProps) => {
                            return null;
                        }
                    },
                    {
                        header: "Series",
                        accessorKey: "series",
                        cell: (_cellProps) => {
                            return null;
                        },
                        getCanGroup: () => false
                    }
                ]
            },
            {
                header: <div style={{ textAlign: "center" }}>{"Return MTD"}</div>,
                id: "returnMtd",
                columns: [
                    {
                        header: "Account",
                        id: "returnMtdAccount",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const startDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), 0);
                            return accountReturn(row, dateSeries, startDate, lastDate, accountSeriesById);
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Benchmark",
                        id: "returnMtdBenchmark",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const startDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), 0);
                            return benchmarkReturn(
                                row,
                                dateSeries,
                                startDate,
                                lastDate,
                                benchmarksById,
                                accountPositionsById,
                                modelSeriesById
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Difference",
                        id: "returnMtdDifference",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const startDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), 0);
                            return diffReturn(
                                row,
                                dateSeries,
                                startDate,
                                lastDate,
                                benchmarksById,
                                accountSeriesById,
                                accountPositionsById,
                                modelSeriesById
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    }
                ]
            },
            {
                header: <div style={{ textAlign: "center" }}>{"Return YTD"}</div>,
                id: "returnYtd",
                columns: [
                    {
                        header: "Account",
                        id: "returnYtdAccount",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            // December month 11 in JavaScript!!!!
                            const startDate = new Date(lastDate.getFullYear() - 1, 11, 31);
                            return accountReturn(row, dateSeries, startDate, lastDate, accountSeriesById);
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Benchmark",
                        id: "returnYtdBenchmark",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            // December month 11 in JavaScript!!!!
                            const startDate = new Date(lastDate.getFullYear() - 1, 11, 31);
                            return benchmarkReturn(
                                row,
                                dateSeries,
                                startDate,
                                lastDate,
                                benchmarksById,
                                accountPositionsById,
                                modelSeriesById
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Difference",
                        id: "returnYtdDifference",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            // December month 11 in JavaScript!!!!
                            const startDate = new Date(lastDate.getFullYear() - 1, 11, 31);
                            return diffReturn(
                                row,
                                dateSeries,
                                startDate,
                                lastDate,
                                benchmarksById,
                                accountSeriesById,
                                accountPositionsById,
                                modelSeriesById
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    }
                ]
            },
            {
                header: (
                    <div
                        data-tooltip-id="ct-tooltip"
                        data-tooltip-content={
                            "\u2071: Compound Annual Growth Rate (CAGR) used for periods over one year. Linear return for one year and under."
                        }
                    >
                        {"Return chosen period (CAGR\u2071)"}
                    </div>
                ),
                id: "returnPer",

                columns: [
                    {
                        header: "Account",
                        id: "returnPerAccount",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const firstDate = new Date(startDate);
                            return accountReturn(row, dateSeries, firstDate, lastDate, accountSeriesById, true);
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Benchmark",
                        id: "returnPerBenchmark",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const firstDate = new Date(startDate);
                            return benchmarkReturn(
                                row,
                                dateSeries,
                                firstDate,
                                lastDate,
                                benchmarksById,
                                accountPositionsById,
                                modelSeriesById,
                                true,
                                true
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    },
                    {
                        header: "Difference",
                        id: "return1YDifference",
                        getCanGroup: () => false,
                        cell: (cellProps) => {
                            const { row } = cellProps;
                            const lastDate = new Date(endDate);
                            const firstDate = new Date(startDate);
                            return diffReturn(
                                row,
                                dateSeries,
                                firstDate,
                                lastDate,
                                benchmarksById,
                                accountSeriesById,
                                accountPositionsById,
                                modelSeriesById,
                                true,
                                true
                            );
                        },
                        aggregatedCell: null // the 'cell' is used instead default aggregatedCell
                    }
                ]
            }
        ];
    }, [client._id, startDate, endDate, processedData]);

    useEffect(() => {
        if (processedData) {
            const { firstSelectedRow, dateSeries } = processedData;

            const startDateAttribution =
                startDate > dateSeries[0] || startDate < dateSeries[dateSeries.length - 1] ? startDate : dateSeries[0];
            if (selectedRow.name === "") {
                setSelectedRow(firstSelectedRow);
                setFormData({ startDate: startDateAttribution, endDate });
            } else if (
                firstSelectedRow.name !== previousSelectedRow.name &&
                previousSelectedClient._id.toString() !== client._id.toString()
            ) {
                setSelectedRow(firstSelectedRow);
                setFormData({ startDate: startDateAttribution, endDate });
            } else if (selectedRow.name !== previousSelectedRow.name) {
                setFormData({ startDate: startDateAttribution, endDate });
            }
        }
    }, [data, endDate, previousSelectedRow, processedData, selectedRow, setFormData, startDate, client, previousSelectedClient]);

    if (errors && errors.length > 0) return <p>{errors}</p>;
    if (!processedData) return <div></div>;

    if (!client.firstTradeDate) return <p>Missing firstTradeDate</p>;

    if (startDate && endDate && endDate < startDate)
        return (
            <p>
                EndDate {endDate} cannot be earlier than startDate {startDate}
            </p>
        );

    let treeRootExist = false;
    if (data && "client" in data && "accounts" in data.client && data.client.topAccount) {
        treeRootExist = true;
    }

    // Extract data
    if (!treeRootExist) return <p>No account tree found</p>;
    const { maxAccountLevels, dataWithLevels, dateSeries } = processedData;

    const expandedPositions: Record<string, boolean> = {};

    const hiddenColumns = ["series", "values", "root", "level1", "level2", "level3", "level4", "positionFlag"];

    const groupBy = ["rootId", "level1Id", "level2Id", "level3Id", "level4Id"];
    if (maxAccountLevels && maxAccountLevels.length < 5) {
        delete groupBy[4];
        hiddenColumns.push("level4Id");
        if (maxAccountLevels && maxAccountLevels.length < 4) {
            delete groupBy[3];
            hiddenColumns.push("level3Id");
            if (maxAccountLevels && maxAccountLevels.length < 3) {
                delete groupBy[2];
                hiddenColumns.push("level2Id");
                if (maxAccountLevels.length < 2) {
                    delete groupBy[1];
                    hiddenColumns.push("level1Id");
                }
            }
        }
    }

    let topBottomInstruments: string[] = [];
    if (selectedRow.name !== "" && selectedRow.instrumentPerformances.length) {
        topBottomInstruments = getTopBottomInstrumentsFromSelectedRow(selectedRow);
    } else {
        const defaultInstrumentsValues: Record<string, number> = {};
        for (const level of dataWithLevels) {
            if (
                level.instrument &&
                level.instrument.modelType &&
                !excludeInAttribution(level.instrument.modelType as unknown as InstrumentModelTypeEnum)
            ) {
                defaultInstrumentsValues[level.instrument.name] = level.series[level.series.length - 1];
            }
        }
        topBottomInstruments = getTopBottomInstruments(defaultInstrumentsValues);
    }

    const accountDescriptions: Record<string, number> = {};
    for (const row of dataWithLevels) {
        const account = processedData.accountsById[row.accountId];
        if (account && account.type === PartyAccountType.Physical && account.description) accountDescriptions[account.description] = 1;
    }

    const rowInPosition = (startDate: string, dateSeries: string[], positionFlag: number): boolean => {
        for (let i = 0; i < dateSeries.length; i++) {
            if (dateSeries[i] < startDate) continue;
            if (positionFlag[i] === 1) return true;
        }
        // only zeros so should be filtered out
        return false;
    };

    const filteredDataWithLevels = hideZerosChecked
        ? dataWithLevels.filter((row) => rowInPosition(startDate, dateSeries, row.positionFlag))
        : dataWithLevels;

    const Checkbox = (props: any) => <input type="checkbox" {...props} />;

    const handleCheckboxChange = (e) => {
        //alert("Hide rows when checked!");
        setHideZerosChecked(e.target.checked);
    };

    return (
        <>
            <div className="row">
                <div className="col print-none d-flex">
                    <PeriodSelector firstAvailableStartDate={client.firstTradeDate} endDate={endDate}></PeriodSelector>
                    <div className="ms-4 m-auto">
                        <span>Hide zeros </span>
                        <Checkbox checked={hideZerosChecked} onChange={handleCheckboxChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TableGrouper
                        key={`${client._id}-${endDate}-${filteredDataWithLevels.length}-${maxAccountLevels}-${groupBy.join()}`}
                        columns={columns}
                        data={filteredDataWithLevels}
                        expanded={expandedPositions}
                        groupBy={groupBy}
                        hiddenColumns={hiddenColumns}
                        compactMode={true}
                        captionColumn="instrumentName"
                        sortBy={sortBy}
                    />
                </div>
            </div>
            <div>
                <div>
                    {isValidRow(selectedRow) ? <PerformanceGraphsTables selectedRow={{ ...selectedRow, endDate, startDate }} /> : null}
                </div>
            </div>
            <div className="row">
                {selectedRow.performance ? (
                    <div className="col-sm-12 col-md-6 mt-4">
                        <AttributionGraph
                            data={{
                                client,
                                performance: {
                                    dates: selectedRow.dateSeries,
                                    values: selectedRow.values,
                                    series: selectedRow.performance.__values,
                                    instrumentPerformances: selectedRow.instrumentPerformances as any
                                },
                                topBottomInstruments: topBottomInstruments,
                                accountDescriptions: Object.keys(accountDescriptions).sort(),
                                accountsById: processedData.accountsById
                            }}
                            selector={processedData ? processedData.attributionSelector : AttributionSelectorEnum.Instruments}
                            plotType={PlotTypeEnum.Series}
                            style={{ height: "50vh" }}
                            client={client}
                            showForm={false}
                        />
                    </div>
                ) : null}
                {selectedRow.performance ? (
                    <div className="col-12 col-md-6 mt-4">
                        <AttributionGraph
                            data={{
                                client,
                                performance: {
                                    dates: selectedRow.dateSeries,
                                    values: selectedRow.values,
                                    series: selectedRow.performance.__values,
                                    instrumentPerformances: selectedRow.instrumentPerformances as any
                                },
                                topBottomInstruments: topBottomInstruments,
                                accountDescriptions: Object.keys(accountDescriptions).sort(),
                                accountsById: processedData.accountsById
                            }}
                            selector={processedData ? processedData.attributionSelector : AttributionSelectorEnum.Instruments}
                            plotType={PlotTypeEnum.Waterfall}
                            style={{ height: "50vh" }}
                            client={client}
                            showForm={false}
                            showXlsxButton={false}
                            layout={{ showlegend: false }}
                        />
                    </div>
                ) : null}
            </div>
            <Tooltip id="ct-tooltip" />
        </>
    );
};
