import React from "react";
import { Portfolio } from "../../../common/src";
import { RelativePerformanceTimeSeriesChart, ValuePerformanceTimeSeriesChart, Widget } from "../index";

interface IPerformanceTimeSeriesChartProps {
    loadPromise: any;
    showHeader: boolean;
}

export class PerformanceTimeSeriesChart extends React.Component<IPerformanceTimeSeriesChartProps> {
    render() {
        const { loadPromise, showHeader } = this.props;
        return (
            <React.Fragment>
                {showHeader !== false ? <h3>Värdeutveckling</h3> : null}
                <Widget>
                    {{
                        load: loadPromise,
                        render: ({ data }) => {
                            const portf = Portfolio.fromFundInfo(data);
                            return (
                                <React.Fragment>
                                    <ValuePerformanceTimeSeriesChart value={portf} />
                                    <RelativePerformanceTimeSeriesChart value={portf} />
                                </React.Fragment>
                            );
                        }
                    }}
                </Widget>
            </React.Fragment>
        );
    }
}
