import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { serializeDate } from "../../components/dateFormater";
import { CollectionNameEnum, NoteReference, NoteStatusEnum } from "../../types.generated";
import { Markdown } from "../../../../components/src/Markdown";

interface ILinkLookup {
    collection: CollectionNameEnum;
    documentId: string;
    text?: string;
}

export interface NotePageProps {
    routeToNotes: string;
    routeToNotesText: string;
}

export const LinkLookup = ({ collection, documentId, text = null }: ILinkLookup): React.ReactElement => {
    if (documentId === "000000000000000000000000") {
        return <div>{text ? text : "None"}</div>;
    }

    if (collection === CollectionNameEnum.None) {
        return <div>{text ? text : "None"}</div>;
    }

    if (collection === CollectionNameEnum.Instrument) {
        return <Link to={"/instruments/" + documentId}>{text ? text : "Instrument"}</Link>;
    }

    if (collection === CollectionNameEnum.IssuerProgram) {
        return <Link to={"/issuerprograms/" + documentId}>{text ? text : "Issuer Program"}</Link>;
    }

    if (collection === CollectionNameEnum.Party) {
        return <Link to={"/parties/" + documentId}>{text ? text : "Party"}</Link>;
    }

    if (collection === CollectionNameEnum.Screen) {
        return <Link to={"/screens/" + documentId}>{text ? text : "Screen"}</Link>;
    }

    if (collection === CollectionNameEnum.Transaction) {
        return <Link to={"/transactions/minitransaction/" + documentId}>{text ? text : "Transaction"}</Link>;
    }
    if (collection === CollectionNameEnum.BrokerTransaction) {
        return <Link to={"/brokerconfirmations/brokertransaction/" + documentId}>{text ? text : "Broker transaction"}</Link>;
    }

    if (collection === CollectionNameEnum.Swift) {
        return <Link to={"/reconcile/corporateactions/corporateaction/" + documentId}>{text ? text : "Corporate action"}</Link>;
    }

    return <div>{text ? text : "None"}</div>;
};

export const ButtonsView = (
    onEditClick = null,
    onCancelEditClick = null,
    routeToNew = "/notes/new",
    routeToNewText = "New note"
): React.ReactElement => {
    return (
        <div>
            {onEditClick ? (
                <Button
                    type="button"
                    className="btn-sm"
                    onClick={() => {
                        onEditClick(true);
                    }}
                >
                    Edit
                </Button>
            ) : null}
            {onCancelEditClick ? (
                <Button
                    type="button"
                    className="btn-sm"
                    onClick={() => {
                        onCancelEditClick(false);
                    }}
                >
                    Cancel Edit
                </Button>
            ) : null}
            <Button
                type="button"
                className="btn-sm ms-2"
                onClick={() => {
                    const win = window.open(routeToNew, "_self");
                    win.focus();
                }}
            >
                {routeToNewText}
            </Button>
        </div>
    );
};

export const NoteView = (
    note: any,
    buttons: React.ReactElement,
    docNameById: Record<string, { _id: string; name: string }>
): React.ReactElement => {
    return (
        <div>
            <div className="row">
                <div className="col-sm-9">
                    <h2>{note.title}</h2>
                    {note && note.status === NoteStatusEnum.Deleted ? (
                        <div style={{ color: "red", fontWeight: "bold", marginBottom: "8px" }}>
                            This note has been {NoteStatusEnum.Deleted}
                        </div>
                    ) : null}
                    <div>
                        <i>
                            {note.creator.name} created this page on {serializeDate(note.createTimestamp, "MMMM DD, YYYY")}
                        </i>
                    </div>
                </div>
                <div className="col-sm-3 mt-2">{buttons}</div>
            </div>
            <div className="row mt-4">
                <div className="col-sm-9">
                    <Markdown key={(Math.random() + 1).toString(36).substring(7)} markdown={note.data || ""} />
                </div>
                <div className="col-sm-3">
                    <div>
                        <div>
                            <strong>Clients (owners)</strong>
                        </div>
                        {note.clients.map((client, index) => {
                            return (
                                <Link key={"client" + index.toString()} to={"/parties/" + client._id}>
                                    {client.name}
                                </Link>
                            );
                        })}
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong>Asset</strong>
                        </div>
                        <div>{note.asset}</div>
                    </div>

                    <div className="mt-4">
                        <div>
                            <strong>Refs</strong>
                        </div>
                        <div id="refs" className="col">
                            {note.refs.map((ref: NoteReference, index) => {
                                const d = docNameById[ref.documentId];
                                let text: string = null;
                                if (d) {
                                    text = d.name;
                                }
                                return (
                                    <LinkLookup
                                        key={"L" + index.toString()}
                                        collection={ref.collection}
                                        documentId={ref.documentId}
                                        text={text}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong>Tags</strong>
                        </div>
                        <div id="tags" className="col">
                            {note.tags.map((tag, index) => {
                                return <div key={"tag" + index.toString()}>{tag.type + ":" + tag.value}</div>;
                            })}
                        </div>
                    </div>
                    {note.updateUserInfo ? (
                        <div className="mt-4">
                            <div>
                                <strong>Last update</strong>
                            </div>
                            <div>{note.updateUserInfo.name}</div>
                            <div>{serializeDate(note.updateTimestamp, "MMMM DD, YYYY HH:mm")}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
