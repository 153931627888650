import React, { useEffect, useState } from "react";
import { cloneDeep, keyBy, sortBy } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import { gql } from "urql";
import { PartialDeep } from "type-fest";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Alert, Card, Badge, Button } from "react-bootstrap";
import Avatar from "react-avatar";

import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import { usePrevious } from "../../../components/src/common/Utils";
import { SubmitButton, TextField, SelectField, MultipleSelectField } from "../../../components/src/form";
import { ReactMarkdownCustom } from "../../../components/src/form/ReactMarkdownCustom";
import { formikUrqlErrorFormater } from "../../../components/src/common/formik-urql-error-helper";

import { LinkLookup } from "./note/NoteComponents";
import { MarkDownField } from "../components/form/MarkDownField";
import { useGetDataQuery, useUpsertTicketsMutation } from "./TicketPage.generated";
import {
    TicketFilterInput,
    Ticket,
    TicketInput,
    TicketTypeEnum,
    TicketStatusEnum,
    TicketState,
    TicketReference,
    CollectionNameEnum,
    PermissionAssetEnum
} from "../types.generated";
import { SYSTEM_PARTY_ID } from "../Constants";
import { REACT_APP_API_URI } from "../env";

export const getData = gql`
    query getData($filter: TicketFilterInput) {
        me {
            _id
            name
            frontendRole {
                assets
            }
        }

        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }

        ticketUsers {
            _id
            name
            clientIds
        }

        tickets(filter: $filter) {
            _id
            title
            clientId
            client {
                _id
                name
            }
            type
            creatorId
            creator {
                name
            }
            createTimestamp
            updateTimestamp
            references {
                documentId
                collection
            }
            state {
                status
                assigneeIds
                assignees {
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
            previousStates {
                status
                assigneeIds
                assignees {
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
        }
    }
`;

export const upsertTickets = gql`
    mutation upsertTickets($input: [TicketInput!]!) {
        upsertTickets(input: $input) {
            _id
            title
            clientId
            client {
                _id
                name
            }
            type
            creatorId
            creator {
                name
            }
            createTimestamp
            updateTimestamp
            references {
                documentId
                collection
            }
            state {
                status
                assigneeIds
                assignees {
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
            previousStates {
                status
                assigneeIds
                assignees {
                    name
                }
                comment
                updateUserId
                updateUserInfo {
                    name
                }
                timestamp
            }
        }
    }
`;

const defaultFormData: PartialDeep<Ticket> = {
    __typename: "Ticket",
    _id: null,
    title: "",
    clientId: SYSTEM_PARTY_ID,
    type: TicketTypeEnum.Error,
    creatorId: "",
    createTimestamp: "",
    updateTimestamp: "",
    references: [],
    state: {
        status: TicketStatusEnum.Open,
        assigneeIds: [],
        comment: "",
        updateUserId: "",
        timestamp: ""
    },
    previousStates: []
};

export const defaultReference: Partial<TicketReference> = {
    collection: CollectionNameEnum.None,
    documentId: ""
};

export const Reference = ({ reference }: { reference: Partial<TicketReference> }): React.ReactElement => {
    return;
    <LinkLookup collection={reference.collection} documentId={reference.documentId} />;
};

const toLocaleString = (date: Date) => {
    return date.toLocaleString("en-EN", {
        weekday: "short", // long, short, narrow
        day: "numeric", // numeric, 2-digit
        year: "numeric", // numeric, 2-digit
        month: "long", // numeric, 2-digit, long, short, narrow
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        second: "numeric" // numeric, 2-digit
    });
};

export const TicketPage = (): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id }: any = useParams();
    const [editReferences, setEditReferences] = useState<boolean>(false);
    const previousId: string = usePrevious(id);
    const [formData, setFormData] = useState(null);
    const filter: TicketFilterInput = {
        idIn: [id === "new" ? "000000000000000000000000" : id],
        statusIn: [TicketStatusEnum.Open, TicketStatusEnum.Closed]
    };
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });
    const [{ fetching: loading, error, data }, refetch] = useGetDataQuery({ variables: { filter: filter } });

    const [_, upsertTickets] = useUpsertTicketsMutation();

    useEffect(() => {
        if (previousId && previousId !== id) {
            setFormData(null);
            refetch();
        } else if (data && formData === null && id === "new") {
            const initFormData = cloneDeep(defaultFormData);
            setFormData(initFormData);
        } else if (formData === null && data && data.tickets.length === 1) {
            const init = cloneDeep(data.tickets[0]);
            init.state.comment = "";
            setFormData(init);
        }
    }, [previousId, id, data, formData, refetch]);

    if (loading) return <p>Loading...</p>;
    if (error) {
        return (
            <div>
                <h3>error</h3>
                <p>{error.toString()}</p>;
            </div>
        );
    }

    if (id !== "new" && data.tickets.length === 0) {
        return <div>No ticket with id {id}</div>;
    }

    if (!formData) {
        return <div></div>;
    }

    const usersByClientId: Record<string, { key: string; value: string }[]> = {};

    data.ticketUsers.forEach((user) => {
        user.clientIds.forEach((clientId: string) => {
            if (clientId in usersByClientId) {
                usersByClientId[clientId].push({ key: user._id, value: user.name });
            } else {
                usersByClientId[clientId] = [{ key: user._id, value: user.name }];
            }
        });
    });

    // TODO: need to add a query to get the available users
    let ticketUsers = [{ key: data.me._id, value: data.me.name }];

    if (formData.clientId in usersByClientId) {
        ticketUsers = usersByClientId[formData.clientId];
    }

    //console.log("formData: ", formData);

    const openTicket = formData.state.status === TicketStatusEnum.Open;

    return (
        <div className="container page">
            <div className="row">
                <div className="col-2">
                    <Button
                        type="button"
                        className="btn btn-sm mb-3"
                        onClick={() => {
                            const win = window.open("/tickets", "_self");
                            win.focus();
                        }}
                    >
                        All tickets
                    </Button>
                </div>
                <div className="col-10 right">
                    {data &&
                    data.me &&
                    data.me.frontendRole &&
                    data.me.frontendRole.assets &&
                    (data.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) || data.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manuals
                        </a>
                    ) : null}
                </div>
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                validate={() => {
                    const errors: any = {};

                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    let input: TicketInput[] = [
                        {
                            _id: submitValues._id === "new" ? null : submitValues._id,
                            title: submitValues.title,
                            clientId: submitValues.clientId,
                            type: submitValues.type,
                            references: submitValues.references,
                            state: {
                                status: submitValues.setStatus ? submitValues.setStatus : submitValues.state.status,
                                assigneeIds: submitValues.state.assigneeIds,
                                comment: submitValues.state.comment
                            }
                        }
                    ];

                    //Mentioning what has been changed in the comment
                    if (input[0]._id) {
                        const clientsById = keyBy(data.clients, "_id");
                        if (input[0].clientId !== formData.clientId) {
                            input[0].state.comment =
                                input[0].state.comment + " Client updated to " + clientsById[input[0].clientId].name + ".";
                        }

                        if (input[0].type !== formData.type) {
                            input[0].state.comment = input[0].state.comment + " Type updated to " + input[0].type + ".";
                        }

                        if (JSON.stringify(input[0].state.assigneeIds.sort()) !== JSON.stringify(formData.state.assigneeIds.sort())) {
                            const userNamesByIds: Record<string, string> = {};

                            for (const user of usersByClientId[input[0].clientId]) {
                                userNamesByIds[user.key] = user.value;
                            }
                            const assigneeNames: string[] = [];
                            for (const assigneeId of input[0].state.assigneeIds) {
                                assigneeNames.push(userNamesByIds[assigneeId]);
                            }
                            input[0].state.comment = input[0].state.comment + " AssigneeIds updated to " + assigneeNames.toString() + ".";
                        }
                        if (
                            JSON.stringify(sortBy(formData.references, "documentId")) !==
                            JSON.stringify(sortBy(input[0].references, "documentId"))
                        ) {
                            const referenceCollectionNames: CollectionNameEnum[] = [];

                            for (const reference of input[0].references) {
                                if (reference.collection) {
                                    referenceCollectionNames.push(reference.collection);
                                }
                            }
                            input[0].state.comment =
                                input[0].state.comment + " References updated to " + referenceCollectionNames.toString() + ".";
                        }
                    }

                    input = recursivelyRemoveKey(input, "__typename");

                    await upsertTickets({ input })
                        .then((result) => {
                            if ("error" in result && result.error) {
                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                setAlert({ color: "danger", visible: true, message });
                            } else {
                                if (id === "new") {
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(result.data.upsertTickets[0]._id);
                                    navigate(path.join("/"), { replace: true });
                                } else {
                                    //setAlert({ color: "success", visible: true, message: `The ticket has been updated successfully!` });
                                    const init = cloneDeep(result.data.upsertTickets[0]);
                                    init.state.comment = "";
                                    setFormData(init);
                                    setSubmitting(false);
                                }
                            }
                        })
                        .catch((error) => {
                            setAlert({ color: "danger", visible: true, message: error.toString() });
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, values: temp, setFieldValue, handleSubmit }) => {
                    const values: PartialDeep<Ticket> = temp;

                    return (
                        <Form autoComplete="off">
                            <div className="form-group form-row">
                                {id === "new" ? (
                                    <div>
                                        <div className="col-10">
                                            <TextField
                                                name="title"
                                                label={<h3>Title</h3>}
                                                type="text"
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="col-sm-10">
                                            <h1>{values.title}</h1>
                                            <div className="d-flex align-items-baseline">
                                                <h5>
                                                    <Badge pill={true} bg={openTicket ? "success" : "danger"}>
                                                        <div className="p-2">{values.state.status}</div>
                                                    </Badge>
                                                </h5>
                                                <div className="ms-2">
                                                    <strong>{values.creator.name}</strong>{" "}
                                                    {`opened this issue on ${toLocaleString(new Date(values.createTimestamp))}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row">
                                <div className="col-sm-8 mb-4">
                                    {values.previousStates.map((state: Partial<TicketState>, index) => {
                                        const date = new Date(state.timestamp);
                                        return (
                                            <div key={`n${index}`} className="row">
                                                <div className="col-sm-1 pt-4">
                                                    <div className="avatar-timeline"></div>

                                                    <Avatar name={state.updateUserInfo.name} round={true} size="40" />
                                                </div>
                                                <div className="col-sm-11 mt-4">
                                                    <Card key={index}>
                                                        <Card.Header>
                                                            <strong>{state.updateUserInfo.name}</strong>
                                                            {" commented on " + toLocaleString(date)}
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <ReactMarkdownCustom>{state.comment || ""}</ReactMarkdownCustom>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <ul className="nav nav-tabs mt-4" id="comment-tab" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="comment-tab-a"
                                                data-toggle="tab"
                                                role="tab"
                                                aria-selected="true"
                                            >
                                                Write a comment
                                            </a>
                                        </li>
                                    </ul>

                                    <MarkDownField
                                        name="state.comment"
                                        graphqlApiBaseUrl={REACT_APP_API_URI}
                                        label="."
                                        type="text"
                                        className=""
                                        height={300}
                                        initialEditMode={true}
                                        disabled={isSubmitting}
                                        useAttachment={true}
                                    />

                                    <div className="row">
                                        <div className="col-sm-12">
                                            {alert.visible ? (
                                                <Alert
                                                    style={{ marginTop: "10px" }}
                                                    variant={alert.color}
                                                    onClose={onDismissAlert}
                                                    dismissible
                                                >
                                                    {alert.message}
                                                </Alert>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row-reverse">
                                        <SubmitButton
                                            className="btn btn-success ms-2"
                                            disabled={false}
                                            label={id === "new" ? "Open" : "Update"}
                                        />
                                        {id !== "new" ? (
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className={openTicket ? "btn btn-outline-danger" : "btn btn-outline-success"}
                                                onClick={() => {
                                                    setFieldValue(
                                                        "setStatus",
                                                        openTicket ? TicketStatusEnum.Closed : TicketStatusEnum.Open,
                                                        false
                                                    );
                                                    handleSubmit();
                                                }}
                                            >
                                                {openTicket ? "Close issue" : "Re-open issue"}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <SelectField
                                                name="clientId"
                                                label={<Link to={"/parties/" + values.clientId}>Client</Link>}
                                                options={data.clients.map((d) => ({ key: d._id, value: d.name }))}
                                                className=""
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <SelectField
                                                className=""
                                                name="type"
                                                label="Type"
                                                options={Object.values(TicketTypeEnum)}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-4">
                                        <h6
                                            style={{ color: "#2186c5" }}
                                            className="col-12 font-weight-bold"
                                            onClick={() => {
                                                setEditReferences(!editReferences);
                                            }}
                                        >
                                            References
                                        </h6>
                                    </div>
                                    {editReferences ? (
                                        <div className="col-12">
                                            <FieldArray
                                                name="references"
                                                render={(arrayHelpers) => (
                                                    <div className="form row">
                                                        {values.references && values.references.length > 0 ? (
                                                            values.references.map((tag, index) => (
                                                                <div key={index} className="col-xs-6 form-group">
                                                                    <SelectField
                                                                        className=""
                                                                        name={`references[${index}].collection`}
                                                                        label="Collection"
                                                                        options={Object.values(CollectionNameEnum).sort()}
                                                                        disabled={isSubmitting}
                                                                    />
                                                                    <TextField
                                                                        name={`references[${index}].documentId`}
                                                                        label={
                                                                            <LinkLookup
                                                                                collection={tag.collection}
                                                                                documentId={tag.documentId}
                                                                            />
                                                                        }
                                                                        className=""
                                                                        disabled={false}
                                                                    />
                                                                    <Button
                                                                        className="me-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Button
                                                                        className=""
                                                                        type="button"
                                                                        onClick={() =>
                                                                            arrayHelpers.insert(index, cloneDeep(defaultReference))
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className=""
                                                                type="button"
                                                                onClick={() => arrayHelpers.push(cloneDeep(defaultReference))}
                                                            >
                                                                {/* show this when user has removed all items */}
                                                                Add a reference
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            {values.references.map((reference, index) => {
                                                return <Reference key={"reference" + index.toString()} reference={reference} />;
                                            })}
                                        </div>
                                    )}

                                    <div className="row">
                                        <div className="col-sm-12 mt-4">
                                            <MultipleSelectField
                                                name="state.assigneeIds"
                                                label="Assignees"
                                                options={ticketUsers}
                                                className=""
                                                disabled={isSubmitting}
                                                size={ticketUsers.length}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
