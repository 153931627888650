import React, { Fragment, ReactElement } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Panel } from "@fluentui/react";

import { useQueryState } from "../../../components/src/use-query-state";
import { DateForm } from "../../../components/src/dateForm";
import { MiniTransactionForm, miniTransactionTypes, NewMiniTransactionButton } from "./MiniTransactionForm";
import { DealBlotterGrid } from "./DealBlotterGrid";
import { serializeSwedenDate } from "../components/dateFormater";
import { gql, useQuery } from "urql";
import { PermissionAssetEnum } from "../types.generated";
import { GET_ME } from "../common/queries";

export function DealBlotterPage(): ReactElement {
    const [startDate] = useQueryState("startDate", serializeSwedenDate(new Date(new Date().getFullYear() - 1, 0, 1)));

    const [{ fetching: loadingMe, error: errorMe, data }] = useQuery({
        query: GET_ME,
        requestPolicy: "network-only"
    });

    const params: any = useParams();
    const navigate = useNavigate();
    const location: any = useLocation();

    if (loadingMe) return <div>Loading...</div>;

    if (errorMe)
        return (
            <div>
                <p>Error:</p>
                <pre> {JSON.stringify(errorMe, null, 2)}</pre>
            </div>
        );

    let id = params.id;

    let type = miniTransactionTypes[0];

    if (id) {
        if (id.startsWith("new")) {
            type = miniTransactionTypes.find((d) => d.toLowerCase() === id.substring(3, id.length));
            id = "new";
        }
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-6 d-flex">
                    <DateForm defaultDateString={startDate} dateName={"startDate"}></DateForm>
                </div>
                <div className="col-6 right mb-3">
                    {data &&
                    data.me &&
                    data.me.frontendRole &&
                    data.me.frontendRole.assets &&
                    (data.me.frontendRole.assets.includes(PermissionAssetEnum.BackOffice) || data.me.frontendRole.assets.length === 0) ? (
                        <a
                            href="https://github.com/CaptorAB/documents/blob/main/BackOffice/manual/transacation_and_trade_reconciliation.md"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                    ) : null}
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex">
                    <div className="ms-auto">
                        <NewMiniTransactionButton page="dealblotter" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={"col-12"}>
                    <DealBlotterGrid startDate={startDate} />
                </div>
            </div>

            <Panel
                isOpen={id != null}
                isBlocking={false}
                onDismiss={() => {
                    navigate({
                        pathname: "/dealblotter",
                        search: location.search
                    });
                }}
                layerProps={{ eventBubblingEnabled: true }}
            >
                {id ? <MiniTransactionForm id={id === "new" ? null : id} type={id === "new" ? type : null} /> : null}
            </Panel>
        </Fragment>
    );
}
