import React, { Fragment, useContext, useMemo, useState } from "react";
import { gql, useQuery } from "urql";
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";

import { ClientContextSelector } from "../../contexts/ClientContextSelector";
import { ClientContext } from "../../contexts/ClientContext";
import { PartyType } from "../../types.generated";
import { FiReport } from "../../common/accounting/FiReport";
import { emptyObjectId } from "../../../../common/src";

const GET_DATA = gql`
    query getData(
        $date: GraphQLDateString
        $swedenDate: GraphQLSwedenDate
        $fromDate: GraphQLDateString!
        $toDate: GraphQLDateString!
        $clientId: GraphQLObjectId
        $clientIdForced: GraphQLObjectId!
        $accountingPeriod: String
    ) {
        shareRegister(filter: { tradeDateEnd: $date }) {
            amount
            units
            fundId
            isin
            customer {
                swedishFinancialAuthorityCategory
            }
        }
        shareRegisterDelta(fromDate: $fromDate, toDate: $toDate) {
            amount
            units
            fundId
            isin
            customer {
                swedishFinancialAuthorityCategory
            }
        }
        party(_id: $clientId) {
            _id
            name
            fundInfo {
                classes {
                    enabled
                    shareClass
                    instrumentId
                    instrument {
                        isin
                        currency
                        valuations(date: $date) {
                            price
                        }
                    }
                }
            }
            externalAccounts {
                _id
                type
                custodianId
            }
            aliases {
                key
                value
            }
        }
        parties {
            _id
            country
            issuerType
            industryCode
        }
        issuerprograms {
            _id
            covered
        }
        instruments {
            _id
            name
            modelType
            issuerProgramId
            issuerId
            productType
            firstTradeDate(clientId: $clientId)
            firstTradeExternalAccountId(clientId: $clientId)
        }
        journalEntries(clientId: $clientId, accountingPeriod: $accountingPeriod) {
            batch
            effectiveDate
            portfolioTransactionId
            transactions {
                quantity
                amount
                instrumentId
                type
            }
        }
        fxValuations(date: $swedenDate) {
            name
            price
        }
        performance(clientId: $clientIdForced, filter: { endDate: $date }) {
            dates
            instrumentPerformances {
                instrumentId
                values
            }
        }
    }
`;

const handleDownload = (filename: string, text: string) => {
    const blob = new Blob([text], { type: "text/json;charset=utf-8" });
    saveAs(blob, filename);
};

export const calculateDefaultDates = () => {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3);
    const startQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);
    const toDateString = (date: Date) => {
        const d = new Date(date);
        const result: string = [d.getFullYear(), ("0" + (d.getMonth() + 1)).slice(-2), ("0" + d.getDate()).slice(-2)].join("-");
        return result;
    };
    const lastReportDate = new Date(startQuarter);
    lastReportDate.setDate(lastReportDate.getDate() - 1);
    const result = {
        lastReportDate: toDateString(lastReportDate),
        fromDate: toDateString(startQuarter),
        toDate: toDateString(endQuarter),
        accountingPeriod: startQuarter.getFullYear().toString()
    };

    return result;
};

export const SwedishFinancialAuthoritiesPage = (): React.ReactElement => {
    const { client } = useContext(ClientContext);
    const [functionCallError, setFunctionCallError] = useState(null);

    const [dates] = useState(calculateDefaultDates());

    // TODO more dynamic variables...
    //const lastReportDate = "2023-06-30";
    //const fromDate = "2023-07-01";
    //const date = "2023-09-30";
    //const accountingPeriod = "2023";

    const [{ fetching, error, data }] = useQuery({
        query: GET_DATA,
        requestPolicy: "cache-and-network",
        variables: {
            clientId: client._id,
            clientIdForced: client._id,
            date: dates.toDate,
            fromDate: dates.fromDate,
            toDate: dates.toDate,
            accountingPeriod: dates.accountingPeriod,
            swedenDate: dates.toDate
        },
        pause: client && client._id !== emptyObjectId ? false : true
    });

    const report = useMemo(() => {
        if (!client || (client && client._id === emptyObjectId)) return null;
        if (fetching) return null;
        if (error) return null;
        if (!data) return null;
        setFunctionCallError(null);
        try {
            // TODO logic for this and fromDate!
            const data2 = cloneDeep(data);
            // set up report
            const fiReport = new FiReport(dates.toDate);

            fiReport.processData(
                data2.party,
                data2.journalEntries,
                data2.instruments,
                data2.parties,
                data2.issuerprograms,
                data2.shareRegister,
                data2.shareRegisterDelta,
                data2.fxValuations,
                data2.performance,
                dates.lastReportDate
            );
            // round and calculate sums
            fiReport.postProcess();
            const fiReportJson = fiReport.toJson();
            return fiReportJson;
        } catch (e) {
            setFunctionCallError(e.toString());
            console.error(e);
            return null;
        }
    }, [fetching, error, data, client, dates]);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    return (
        <div>
            <div className="row">
                <div className="col-4">
                    <ClientContextSelector typeIn={[PartyType.Fund]} />
                </div>
            </div>
            {client && functionCallError ? (
                <div>
                    <p>Error creating report:</p>
                    <pre> {JSON.stringify(functionCallError, null, 2)}</pre>
                </div>
            ) : report ? (
                <Fragment>
                    <div className="row-col ms-1">
                        <button
                            type="submit"
                            onClick={() => {
                                handleDownload(client.dashName + ".json", JSON.stringify(report, null, 2));
                            }}
                            className="btn btn-primary btn-sm float-end"
                        >
                            Download json file
                        </button>
                    </div>

                    <div className="row-col mt-2 ms-2">
                        <pre>{JSON.stringify(dates, null, 2)}</pre>
                    </div>

                    <div className="row-col ms-2">
                        <pre>{JSON.stringify(report, null, 2)}</pre>
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
};
