import React, { useEffect } from "react";
import { gql, useQuery } from "urql";
import { useParams } from "react-router-dom";

import { usePrevious } from "../../../../components/src/common/Utils";
import { NavComponent } from "./NavComponent";

const getNav = gql`
    query getNav($_id: GraphQLObjectId!) {
        nav(_id: $_id) {
            clientId
            client {
                longName
            }
            date
            comment
            frontOfficeNav
            fundRiskLevel
            absHighTolerancePercent
            absLowTolerancePercent
            publishedTo {
                key
                timestamp
            }
            data {
                classes {
                    instrumentId
                    name
                    navBeforeAdjustmentsBeforeOrders
                    managementFee
                    dividendPaid
                    fxHedge
                    fxRate
                    adjustmentAmount
                    navAfterAdjustmentsBeforeOrders
                    navAfterAdjustmentsAfterOrders
                    navCurrency
                    navPerUnit
                    navPerUnitReturn1D
                    unitsAfterOrders
                    unitsBeforeOrders
                    weight
                }
                adjustmentAmountPositions {
                    instrumentId
                    name
                    accountId
                    value
                    currency
                    fxValuationDate
                    valuationDate
                    fxRate
                }
                positions {
                    instrumentId
                    name
                    accountId
                    value
                    currency
                    fxValuationDate
                    valuationDate
                    fxRate
                    quantity
                }
                orders {
                    amount
                    carryOwnTransactionCost
                    currency
                    instrumentId
                    isin
                    name
                    orderId
                    transactionType
                    units
                    valueDate
                }
                transactions {
                    accountId
                    adjustmentAmount
                    adjustmentShares
                    amount
                    currency
                    externalId
                    instrumentId
                    isin
                    name
                    price
                    accountingCurrency
                    accountingAmount
                    accountingPrice
                    fxRate
                    transactionType
                    units
                    valueDate
                    accountSwitchOrder
                }
            }
        }
    }
`;

export function NavPage(): React.ReactElement {
    const params: any = useParams();
    const previousId: string = usePrevious(params.id);

    const [{ fetching, error, data }, refetch] = useQuery({ query: getNav, variables: { _id: params.id }, requestPolicy: "network-only" });

    useEffect(() => {
        if (params.id !== previousId) {
            refetch();
        }
    }, [previousId, params, refetch]);

    if (fetching) return <div>Loading</div>;
    if (error)
        return (
            <div>
                <p>Error:</p>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    return (
        <div className="container">
            <h3>{data.nav.client.longName}</h3>
            <NavComponent nav={data.nav} initShowPositions={true}></NavComponent>
        </div>
    );
}
