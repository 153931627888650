import React, { useState, useEffect, Fragment } from "react";
import { gql, useQuery } from "urql";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { cloneDeep, keyBy } from "lodash";
import { Link } from "react-router-dom";

import { numberFormatFun } from "../../../../common/src";
import { Grid, Column } from "../../../../components/src";

const getTradingmanager = gql`
    query getTradingmanager($clientIds: [GraphQLObjectId!], $endDate: GraphQLDateString, $lookThrough: Boolean) {
        tradingmanager(filterZeroPositions: true, filter: { endDate: $endDate, clientIds: $clientIds }, lookThrough: $lookThrough) {
            name
            instrumentId
            longName
            quantity
            currency
            exposure
            localExposure
            issuerName
            issuerId
            valuationPrice
            valuationDate
            fxRate
            bloombergTicker
            sustainabilityClass
            modelType
            maturityDate
            issuerIndustryCode
            accountName
            valuationCleanPrice
            valuationAccruedInterest
            issuerLei
            issuerType
            fxRateDate
            instrument {
                startDate
                creditRating
                issuerProgram {
                    _id
                    name
                }
                issuer {
                    sustainalyticsId
                    nace
                }
            }
        }
    }
`;

const getMiniTransactions = gql`
    query getMiniTransactions($type: [TransactionType], $filter: TransactionFilterInput) {
        miniTransactions(type: $type, filter: $filter) {
            _id
            parentId
            instrumentId
            type
        }
    }
`;

const calculate_portfolio_value = (tradingmanager) => {
    let portfolio_value = 0;
    for (let i = 0; i < tradingmanager.length; i++) {
        portfolio_value += tradingmanager[i].exposure;
    }
    return portfolio_value;
};

const check_multiple_valuation_dates = (tradingmanager) => {
    const valuation_dates = [];
    let return_text;
    for (let i = 0; i < tradingmanager.length; i++) {
        if (tradingmanager[i].quantity === null || tradingmanager[i].quantity === 0) {
            continue;
        }
        if (!valuation_dates.includes(tradingmanager[i].valuationDate)) {
            valuation_dates.push(tradingmanager[i].valuationDate);
        }
    }
    if (valuation_dates.length !== 1) {
        return_text = "Mixed valuation dates";
    } else {
        return_text = "";
    }
    return return_text;
};

const check_multiple_account = (tradingmanager) => {
    const accounts = [];
    let return_text;
    for (let i = 0; i < tradingmanager.length; i++) {
        if (!accounts.includes(tradingmanager[i].accountName)) {
            accounts.push(tradingmanager[i].accountName);
        }
    }
    if (accounts.length !== 1) {
        return_text = "Multiple accounts";
    } else {
        return_text = "";
    }
    return return_text;
};

export interface PortfolioManagerCommentProps {
    clientIds: string[];
    endDate: string;
    lookThrough: boolean;
}

export const Reports = ({ clientIds, endDate, lookThrough }: PortfolioManagerCommentProps): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({
        query: getTradingmanager,
        requestPolicy: "network-only",
        variables: { clientIds, endDate, lookThrough }
    });
    const [instrumentIds, setInstrumentIds] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const filter = { instrumentIdsIn: instrumentIds, statusNotIn: ["Deleted"], clientIds: clientIds };
    const [{ fetching: getMiniTransactionsLoading, error: getMiniTransactionsError, data: getMiniTransactionsData }] = useQuery({
        query: getMiniTransactions,
        variables: { type: ["Insert", "DerivativeTrade"], filter: filter },
        requestPolicy: "network-only",
        pause: !instrumentIds
    });

    useEffect(() => {
        if (data) {
            const tradingManagerIncludingRating = [];
            const derivativePositionsInstrumentIds = [];
            for (let i = 0; i < data.tradingmanager.length; i++) {
                const tradingManagerColumn = cloneDeep(data.tradingmanager[i]);
                tradingManagerColumn["creditRating"] = data.tradingmanager[i].instrument.creditRating;
                delete tradingManagerColumn["instrument"];
                tradingManagerIncludingRating.push(tradingManagerColumn);
                if (
                    tradingManagerColumn.modelType === "Swap" ||
                    tradingManagerColumn.modelType === "Swaption" ||
                    tradingManagerColumn.modelType === "FxSwap" ||
                    tradingManagerColumn.modelType === "EquityBasketOption" ||
                    tradingManagerColumn.modelType === "EquityUnitOption" ||
                    tradingManagerColumn.modelType === "GenericSwap" ||
                    tradingManagerColumn.modelType === "GenericFxSwap" ||
                    tradingManagerColumn.modelType === "PortfolioSwap"
                )
                    derivativePositionsInstrumentIds.push(tradingManagerColumn.instrumentId);
            }
            setInstrumentIds(derivativePositionsInstrumentIds);
        }
    }, [data, setInstrumentIds]);

    if (fetching) return <div> {"Loading..."} </div>;
    if (error) return <div>{"Error!" + error.message}</div>;
    if (getMiniTransactionsLoading) return <div> {"Loading transactions..."} </div>;
    if (getMiniTransactionsError) return <div>{"Error!" + error.message}</div>;

    const tradingManagerIncludingRating = [];
    for (let i = 0; i < data.tradingmanager.length; i++) {
        const tradingManagerColumn = cloneDeep(data.tradingmanager[i]);
        tradingManagerColumn["creditRating"] = data.tradingmanager[i].instrument.creditRating;
        tradingManagerColumn["issuerProgramId"] = "";
        tradingManagerColumn["issuerProgramName"] = "";
        if (data.tradingmanager[i].instrument.issuerProgram) {
            tradingManagerColumn["issuerProgramId"] = data.tradingmanager[i].instrument.issuerProgram._id;
            tradingManagerColumn["issuerProgramName"] = data.tradingmanager[i].instrument.issuerProgram.name;
        }
        tradingManagerColumn["issueDate"] = data.tradingmanager[i].instrument.startDate;
        tradingManagerColumn["sustainalyticsId"] = data.tradingmanager[i].instrument.issuer
            ? data.tradingmanager[i].instrument.issuer.sustainalyticsId
            : "";
        tradingManagerColumn["nace"] = data.tradingmanager[i].instrument.issuer ? data.tradingmanager[i].instrument.issuer.nace : "";

        delete tradingManagerColumn["instrument"];
        tradingManagerIncludingRating.push(tradingManagerColumn);
    }

    let transactionsPossibleClose;
    let transactionsByInstrumentId;
    if (getMiniTransactionsData) {
        transactionsByInstrumentId = keyBy(getMiniTransactionsData.miniTransactions, "instrumentId");
        const instrumentIds = Object.keys(transactionsByInstrumentId);
        transactionsPossibleClose = [...new Set(instrumentIds)];
    }

    return (
        <Fragment>
            <p>Portfolio value: {numberFormatFun("# ##0")(calculate_portfolio_value(tradingManagerIncludingRating))} </p>
            <p>{check_multiple_valuation_dates(tradingManagerIncludingRating)} </p>
            <p>{check_multiple_account(tradingManagerIncludingRating)} </p>
            <Grid header="POSITIONS" data={tradingManagerIncludingRating} sortable tableClassName="table-xs">
                <Column field="accountName" title="Account" className="grid-column-sticky" />
                <Column
                    field="name"
                    className="nowrap left"
                    format={(d, pos) => {
                        const menu = ["Instrument"];
                        if (transactionsPossibleClose && transactionsPossibleClose.includes(pos.instrumentId)) {
                            menu.push("Close Position");
                        }
                        return (
                            <Dropdown>
                                <Dropdown.Toggle as="a" bsPrefix="m-dropdown__toggle" id={pos.instrumentId} data-boundary="window">
                                    {pos.name.slice(0, 20)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {menu.map((menuItem, i) => {
                                        return (
                                            <Dropdown.Item
                                                key={i}
                                                onClick={() => {
                                                    if (menuItem === "Instrument") {
                                                        const win = window.open("/instruments/" + pos.instrumentId, "_blank");
                                                        win.focus();
                                                    } else {
                                                        const transaction = transactionsByInstrumentId[pos.instrumentId];
                                                        navigate(
                                                            `/pm/${clientIds}/positions/newclosederivativetrade/${transaction._id}${location.search}`
                                                        );
                                                    }
                                                }}
                                            >
                                                {menuItem}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    }}
                />
                <Column field="bloombergTicker" title="Bloomberg ticker" />
                <Column field="currency" />
                <Column field="quantity" format={numberFormatFun("# ##0")} />
                <Column field="valuationPrice" format={numberFormatFun("# ##0.00")} title="Valuation price" />
                <Column field="valuationCleanPrice" format={numberFormatFun("# ##0.00000")} title="Valuation clean price" />
                <Column field="valuationAccruedInterest" format={numberFormatFun("# ##0.00000")} title="Valuation accrued interest" />
                <Column field="exposure" format={numberFormatFun("# ##0")} />
                <Column field="localExposure" format={numberFormatFun("# ##0")} title="Local exposure" />
                <Column className="nowrap" field="valuationDate" title="Valuation date" />
                <Column field="fxRate" title="FX rate" />
                <Column field="fxRateDate" title="FX rate date" />
                <Column
                    field="issuerName"
                    title="Issuer name"
                    className="nowrap maxwidth150px left"
                    format={(value, item) => {
                        return item && item.issuerId ? (
                            <Link to={"/parties/" + item.issuerId} target="_blank">
                                {value}
                            </Link>
                        ) : (
                            value
                        );
                    }}
                />
                <Column
                    field="issuerProgramName"
                    title="Issuer program name"
                    className="nowrap maxwidth150px left"
                    format={(value, item) => {
                        return item && item.issuerProgramId ? (
                            <Link to={"/issuerprograms/" + item.issuerProgramId} target="_blank">
                                {value}
                            </Link>
                        ) : (
                            value
                        );
                    }}
                />
                <Column className="nowrap" field="issueDate" title="Issue date" />
                <Column field="issuerIndustryCode" title="Issuer insdustry code" />
                <Column field="issuerLei" title="Issuer LEI" />
                <Column field="issuerType" title="Issuer type" />
                <Column field="nace" title="NACE" />
                <Column
                    field="creditRating"
                    title="Credit rating"
                    format={(item) => {
                        if (item === "Not Applicable") {
                            return "N/A";
                        }
                        return item;
                    }}
                />
                <Column field="sustainabilityClass" title="Sustainability clas" />
                <Column field="sustainalyticsId" title="Sustainalytics ID" />

                <Column field="modelType" title="Model type" />
                <Column field="maturityDate" title="Maturity date" />
            </Grid>
        </Fragment>
    );
};
