import React from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQueryState } from "../../../../components/src/use-query-state";

import { DateForm } from "../../../../components/src/dateForm";
import { Collateral } from ".";

const check_today_date = (endDate: string) => {
    const today = new Date().toISOString().slice(0, 10);
    if (endDate === today) {
        return true;
    } else {
        return false;
    }
};

export const CollateralView = (): React.ReactElement => {
    const [endDate] = useQueryState("endDate", new Date().toISOString().slice(0, 10));
    const { id } = useParams<"id">();

    if (typeof id === "undefined" || id === "all") {
        return <div>"Select a client"</div>;
    }

    return (
        <div>
            <div className="d-flex">
                <DateForm defaultDateString={endDate} dateName={"endDate"}></DateForm>
                {!check_today_date(endDate) ? (
                    <Alert variant={"danger"} className="ms-2">
                        Historical or future date selected
                    </Alert>
                ) : null}
            </div>
            <br />
            <Collateral clientIds={[id]} endDate={endDate} />
        </div>
    );
};
