import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { gql, useQuery, useMutation } from "urql";
import stableStringify from "json-stable-stringify";

import save from "save-file";
import { Alert } from "react-bootstrap";
import { Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Buffer } from "buffer";

import { FileIcon } from "../components/icons/FileIcon";
import { Attachment } from "../types.generated";
import { usePrevious } from "../../../components/src/common/Utils";

const GET_ATTACHMENT = gql`
    query getAttachment($_id: GraphQLObjectId!) {
        attachment(_id: $_id) {
            fileId
            fileName
            base64
            mimeType
        }
    }
`;

const CREATE_ATTACHMENT = gql`
    mutation createAttachment($clientId: GraphQLObjectId!, $input: CreateAttachmentInput!) {
        createAttachment(clientId: $clientId, input: $input) {
            fileId
            fileName
            mimeType
            updateTimestamp
        }
    }
`;
interface AttachmentFormProps {
    clientId: string;
    attachments: Partial<Attachment>[];
    onChange: (arg: Partial<Attachment>[]) => void;
}

export const AttachmentForm = ({ clientId, attachments, onChange }: AttachmentFormProps): React.ReactElement => {
    const [_, createAttachment] = useMutation(CREATE_ATTACHMENT);
    const [variables, setVariables] = useState<{ _id: string }>(null);

    const [{ data }] = useQuery({ query: GET_ATTACHMENT, variables, requestPolicy: "network-only", pause: variables ? false : true });
    const previousData = usePrevious(data);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useEffect(() => {
        if (data && stableStringify(data) !== stableStringify(previousData)) {
            const dataURI = "data:" + data.attachment.mimeType + ";base64," + data.attachment.base64;
            save(dataURI, data.attachment.fileName).catch((error) => {
                setAlert({ color: "danger", visible: true, message: error.toString() });
            });
        }
    }, [data, previousData]);

    const readFile = (inputFile) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                console.log("file reading was aborted");
                reject();
            };

            reader.onload = async (event) => {
                const data = event.target.result as string;
                const result = {
                    fileName: inputFile.name,
                    base64: Buffer.from(data, "binary").toString("base64")
                };
                resolve(result);
            };
            reader.readAsBinaryString(inputFile);
        });
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const readFilesPromises = acceptedFiles.map(async (f) => await readFile(f));

            const newAttachments = await Promise.all(readFilesPromises)
                .then(async (files) => {
                    const attachmentsResult = [];
                    for (let i = 0; i < files.length; i++) {
                        await createAttachment({ clientId: clientId, input: files[i] })
                            .then(({ data }) => {
                                attachmentsResult.push({
                                    fileId: data.createAttachment.fileId,
                                    fileName: data.createAttachment.fileName,
                                    mimeType: data.createAttachment.mimeType,
                                    updateTimestamp: data.createAttachment.updateTimestamp
                                });
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }
                    return attachmentsResult;
                })
                .catch((error) => {
                    setAlert({ color: "danger", visible: true, message: error.toString() });
                });

            if (onChange) {
                const attachmentsUpdated: Partial<Attachment>[] = [...cloneDeep(attachments), ...(newAttachments as any[])];
                onChange(attachmentsUpdated);
            }
        },
        [onChange, attachments, clientId, createAttachment]
    );
    const maxSize = 10 * 1048576; // 10 MB
    const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, maxSize });

    if (fileRejections && fileRejections.length > 0) return <p style={{ color: "red" }}>{fileRejections[0].errors[0].message}</p>;

    return (
        <div className="transaction-attachment-form">
            <div className="d-flex flex-wrap">
                {attachments.map((attachment, index) => (
                    <Card
                        style={{
                            maxWidth: "20rem"
                        }}
                        key={attachment.fileName + index}
                        className="m-2"
                    >
                        <Card.Header>
                            <Dropdown>
                                <Dropdown.Toggle type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <FileIcon mimeType={attachment.mimeType} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            if (onChange) {
                                                // returns all attachments except deleted
                                                onChange(attachments.filter((a) => a.fileId !== attachment.fileId));
                                            }
                                        }}
                                    >
                                        Delete attachment
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={async () => {
                                            setVariables({ _id: attachment.fileId });
                                        }}
                                    >
                                        Download attachment
                                    </Dropdown.Item>
                                    {attachment.mimeType === "application/pdf" ? (
                                        <Dropdown.Item
                                            onClick={async () => {
                                                window.open(`/attachment/pdf/${attachment.fileId}`, "_blank");
                                            }}
                                        >
                                            View attachment
                                        </Dropdown.Item>
                                    ) : null}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Header>
                        <Card.Body>
                            {attachment.mimeType !== "application/pdf" ? (
                                <Card.Text>{attachment.fileName}</Card.Text>
                            ) : (
                                <Card.Text>
                                    <Link to={`/attachment/pdf/${attachment.fileId}`} target="_blank">
                                        {attachment.fileName}
                                    </Link>
                                </Card.Text>
                            )}
                        </Card.Body>
                    </Card>
                ))}
            </div>

            <div className="m-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="dropzone">Drag 'n' drop file here to upload as attachment </p>
            </div>

            {alert.visible ? (
                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
        </div>
    );
};
