import React from "react";
import { cloneDeep, round } from "lodash";
//import chroma from "chroma-js";
import Plotly from "plotly.js-finance-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { Language } from "../Language";

const Plot: any = createPlotlyComponent(Plotly);
interface PerformanceData {
    // First day special case
    totalReturnDay0: number;
    totalReturn: number;
    startDateStackedValue: number;
    startDateSeriesValue: number;
    series: number[];
    plotSeries: number[];
    waterfall: number;
}

const MS_PER_DAY = 86400000;

const daysBetween = (fromDate: string, toDate: string): number => {
    return (new Date(toDate).getTime() - new Date(fromDate).getTime()) / MS_PER_DAY;
};

const numberToDecimalString = (num: number, decimals: number) => {
    return round(num, decimals).toFixed(decimals);
};

const dataToTraces = (data: any, groupBy: string, groupValues: string[], plotType: "waterfall" | "series", startDate?: string): any[] => {
    // This will be input from page
    if (!startDate) {
        startDate = data.performance.dates[0];
    }
    const endDate = data.performance.dates[data.performance.dates.length - 1];

    //const groupBy = "currency";
    //const groupValues = ["EUR", "SEK"];

    const localData = cloneDeep(data);
    const otherGroup = "Other";

    const groupPerformances: Record<string, PerformanceData> = {};
    // Start with other group
    groupPerformances[otherGroup] = {
        totalReturnDay0: 0,
        totalReturn: 0,
        plotSeries: [],
        series: [],
        startDateStackedValue: 0,
        waterfall: 0,
        startDateSeriesValue: 0
    };

    for (let i = 0; i < groupValues.length; i++) {
        groupPerformances[groupValues[i]] = {
            totalReturnDay0: 0,
            totalReturn: 0,
            plotSeries: [],
            series: [],
            startDateStackedValue: 0,
            waterfall: 0,
            startDateSeriesValue: 0
        };
    }

    // Get start index - since we know we have all days it can be calculated
    const seriesFactor = 1 / localData.performance.series[daysBetween(localData.performance.dates[0], startDate)];

    const groupKeys = Object.keys(groupPerformances);

    if (
        localData.performance &&
        localData.performance.dates &&
        localData.performance.values &&
        localData.performance.instrumentPerformances
    ) {
        // Calculate return series for each group using total as base to get additive return series
        const dates: string[] = [];
        const series: number[] = [];
        const plotSeries: number[] = [];
        for (let i = 0; i < localData.performance.dates.length; i++) {
            const date = localData.performance.dates[i];
            if (date >= startDate && date <= endDate) {
                dates.push(localData.performance.dates[i]);
                plotSeries.push(100 * (seriesFactor * localData.performance.series[i] - 1));
            }

            series.push(seriesFactor * localData.performance.series[i]);
            for (let j = 0; j < localData.performance.instrumentPerformances.length; j++) {
                const instrumentPerformance = localData.performance.instrumentPerformances[j];
                const instrument = instrumentPerformance.instrument;
                const group = groupPerformances[instrument[groupBy]]
                    ? groupPerformances[instrument[groupBy]]
                    : groupPerformances[otherGroup];
                if (i === 0) {
                    group.totalReturn += instrumentPerformance.values[i] - instrumentPerformance.cashFlows[i];
                } else {
                    group.totalReturn +=
                        instrumentPerformance.values[i] - instrumentPerformance.values[i - 1] - instrumentPerformance.cashFlows[i];
                }
            }
            // Calculate series and reset group returns
            let stackedValue = 0;
            for (let j = 0; j < groupKeys.length; j++) {
                let seriesValue = 0;
                if (i === 0) {
                    groupPerformances[groupKeys[j]].totalReturnDay0 = groupPerformances[groupKeys[j]].totalReturn;
                    seriesValue = 0;
                } else {
                    let totalReturn = groupPerformances[groupKeys[j]].totalReturn;
                    // If day two - add first day total return since first day forced to start with no return
                    if (i === 1) totalReturn += groupPerformances[groupKeys[j]].totalReturnDay0;

                    // Assert no division by zero?
                    if (localData.performance.values[i - 1] === 0)
                        throw new Error("Performance value at date " + dates[i] + " zero. Division by zero!");

                    // Scale with cumulative return
                    seriesValue =
                        groupPerformances[groupKeys[j]].series[i - 1] +
                        (localData.performance.series[i - 1] * totalReturn) / localData.performance.values[i - 1];
                }
                stackedValue += seriesValue;
                groupPerformances[groupKeys[j]].series.push(seriesValue);
                if (date === startDate) {
                    groupPerformances[groupKeys[j]].startDateStackedValue = stackedValue;
                    groupPerformances[groupKeys[j]].startDateSeriesValue = seriesValue;
                    groupPerformances[groupKeys[j]].plotSeries.push(0);
                } else if (date > startDate && date <= endDate) {
                    // In percent
                    groupPerformances[groupKeys[j]].plotSeries.push(
                        100 * seriesFactor * (stackedValue - groupPerformances[groupKeys[j]].startDateStackedValue)
                    );
                    // Update waterfall to last series value in percent for output
                    groupPerformances[groupKeys[j]].waterfall =
                        100 * seriesFactor * (seriesValue - groupPerformances[groupKeys[j]].startDateSeriesValue);
                }

                groupPerformances[groupKeys[j]].totalReturn = 0;
            }
        }

        // Set up traces Plotly
        const traces: any[] = [];

        if (plotType === "series") {
            traces.push({ name: otherGroup, x: dates, y: groupPerformances[otherGroup].plotSeries, fill: "tozeroy", type: "scatter" });
            for (let i = 0; i < groupValues.length; i++) {
                traces.push({
                    name: groupValues[i],
                    x: dates,
                    y: groupPerformances[groupValues[i]].plotSeries,
                    fill: "tonexty",
                    type: "scatter"
                });
            }

            traces.push({ name: "Total", x: dates, y: plotSeries, type: "scatter" });
        } else {
            const x = [otherGroup];
            const y = [groupPerformances[otherGroup].waterfall];
            const measure = ["relative"];
            const text = [numberToDecimalString(groupPerformances[otherGroup].waterfall, 1) + " %"];

            for (let i = 0; i < groupValues.length; i++) {
                x.push(groupValues[i]);
                y.push(groupPerformances[groupValues[i]].waterfall);
                measure.push("relative");
                text.push(numberToDecimalString(groupPerformances[groupValues[i]].waterfall, 1) + " %");
            }

            x.push("Total");
            y.push(plotSeries.slice(-1)[0]);
            measure.push("total");
            text.push(numberToDecimalString(plotSeries.slice(-1)[0], 1) + " %");

            traces.push({
                type: "waterfall",
                orientation: "v",
                measure,
                x,
                textposition: "inside",
                text,
                y,
                hoverinfo: "x+text",
                connector: {
                    visible: false
                },
                decreasing: { marker: { color: "#bdbdbd" } },
                increasing: { marker: { color: "#43a04c" } },
                totals: { marker: { color: "#2186C5" } }
            });
        }

        return traces;
    }

    return [];
};
interface IAttributionProps {
    startDate?: string;
    data: any;
    locale: Language;
    selector: string;
    selectorOptions: string[];
    plotType: "waterfall" | "series";
    width?: number;
    height?: number;
}

export const Attribution = ({
    data,
    locale,
    startDate,
    selector,
    selectorOptions,
    plotType,
    width,
    height
}: IAttributionProps): React.ReactElement => {
    let titletext: string = null;
    let header: string = null;
    if (locale === "En") {
        titletext = "Cumulative return";
        if (startDate) {
            header = "Attribution This Month";
        } else {
            header = "Cumulative Attribution";
        }
    } else {
        if (locale === "Sv") {
            titletext = "Kumulativ avkastning";
            if (startDate) {
                header = "Attribution innevarande månad";
            } else {
                header = "Kumulativ resultatattribution";
            }
        }
    }

    const performancedata = data.data;
    const traces = dataToTraces(performancedata, selector, selectorOptions, plotType, startDate);

    //endDate always set to date in script i.e the date when the datafile is generated for
    //const color = chroma("#2186C5");

    const layout: Partial<Plotly.Layout> = {
        xaxis:
            plotType === "series"
                ? {
                      title: {
                          text: "Date"
                      }
                  }
                : {
                      type: "category"
                  },
        yaxis:
            plotType === "series"
                ? {
                      title: {
                          text: titletext
                      }
                  }
                : {
                      title: {
                          text: titletext
                      },
                      type: "linear"
                  },
        margin: { b: 40, t: 10, l: 40, r: 10 },
        autosize: false
    };

    if (width) {
        layout.width = width;
    }
    if (height) {
        layout.height = height;
    }

    return (
        <React.Fragment>
            <h3> {header} </h3>
            <div className="plotly-print">
                <Plot
                    data={traces}
                    layout={layout}
                    useResizeHandler={true}
                    //style={{ width: "100%", height: "calc(100vh - 100px)", position: "sticky", top: "0" }}
                    config={{
                        responsive: true,
                        displayModeBar: false,
                        showSendToCloud: false,
                        toImageButtonOptions: { format: "svg" },
                        displaylogo: false,
                        modeBarButtonsToRemove: [
                            "zoom2d",
                            "pan2d",
                            "select2d",
                            "resetViews",
                            "toggleSpikelines",
                            "hoverClosestCartesian",
                            "hoverCompareCartesian",
                            "lasso2d",
                            "zoomIn2d",
                            "zoomOut2d",
                            "autoScale2d",
                            "resetScale2d",
                            "hoverClosestPie",
                            "sendDataToCloud"
                        ]
                    }}
                />
            </div>
        </React.Fragment>
    );
};
