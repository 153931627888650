import React, { Fragment, useState, useEffect, useMemo } from "react";
import { gql, useQuery, useMutation } from "urql";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";

import { Svgs } from "../../../../components/src";
import { Checkbox } from "../../../../components/src/form";
import { ReactTable } from "../../../../components/src/react-table/ReactTable";
import { Party, PermissionAssetEnum, PermissionTypeEnum } from "../../types.generated";
import { SYSTEM_PARTY_ID } from "../../Constants";
import { getAllTAccountCharts, getClients } from "./queries";
import { userHaveAccessTo } from "../../common/Permissions";
import { ClientSelector } from "./ClientDateSelector";

const CREATE_TACCOUNTCHART = gql`
    mutation createTAccountChart($input: CreateTAccountChartInput) {
        createTAccountChart(input: $input) {
            _id
            name
            version
            client {
                _id
                name
            }
            locked
        }
    }
`;

const CLONE_TACCOUNTCHART = gql`
    mutation cloneTAccountChart($name: String, $clientId: GraphQLObjectId!) {
        cloneTAccountChart(name: $name, clientId: $clientId) {
            _id
            name
            version
            client {
                _id
                name
            }
            locked
        }
    }
`;

export function TAccountChartsPage(): React.ReactElement {
    const [tAccountCharts, setTAccountCharts] = useState([]);
    const [recentTAccountChartsByName, setRecentTAccountChartsByName] = useState({});

    const [{ fetching: loadingTAccountCharts, error: errorTAccountCharts, data: dataTAccountCharts }] = useQuery({
        query: getAllTAccountCharts
    });

    // Fetch clients, dropdown for creating new tAChart
    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useQuery({
        query: getClients
    });

    const [____, createTAccountChart] = useMutation(CREATE_TACCOUNTCHART);
    const [_____, cloneTAccountChart] = useMutation(CLONE_TACCOUNTCHART);

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Link to={`/taccountcharts/${row.original._id}`}>#</Link>
                        </div>
                    );
                },
                size: 0 // 'auto'
            },
            {
                header: "Type",
                accessorKey: "type",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const v = !row.original.client ? "Master" : "Client";
                    return <Link to={`/taccountcharts/${row.original._id}`}>{v}</Link>;
                },
                size: 0
            },
            {
                header: "Name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.client._id}`}>{row.original.client.name}</Link>;
                }
            },
            {
                header: "Version",
                accessorKey: "version",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            {" "}
                            <Link to={`/taccountcharts/${row.original._id}`}>{row.original.version}</Link>
                        </div>
                    );
                },
                size: 0
            },
            {
                header: "Locked",
                accessorKey: "locked",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Checkbox checked={row.original.locked} readOnly={true} />
                        </div>
                    );
                },
                size: 0
            }
        ],
        []
    );

    // tAccountCharts
    useEffect(() => {
        const data = dataTAccountCharts && dataTAccountCharts.tAccountCharts ? cloneDeep(dataTAccountCharts.tAccountCharts) : [];
        data.sort((d1, d2) => {
            let c = d1.client && !d2.client ? 1 : !d1.client && d2.client ? -1 : 0;
            if (c !== 0) {
                return c;
            }
            const n1 = d1.client ? d1.client.name : d1.name;
            const n2 = d2.client ? d2.client.name : d2.name;
            c = n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
            if (c !== 0) {
                return c;
            }
            return d2.version - d1.version;
        });
        setTAccountCharts(data);
    }, [dataTAccountCharts]);

    // recentTAccountChartsByName
    useEffect(() => {
        const recent = {};
        for (const t in tAccountCharts) {
            const tAChart = tAccountCharts[t];
            if (tAChart.client.name && !recent[tAChart.client.name]) {
                recent[tAChart.client.name] = tAChart;
            } else if (tAChart.client.name && recent[tAChart.client.name] && recent[tAChart.client.name].version < tAChart.version) {
                recent[tAChart.client.name] = tAChart;
            }
        }
        setRecentTAccountChartsByName(recent);
    }, [tAccountCharts]);

    // loading
    if (loadingTAccountCharts || loadingClients) {
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );
    }

    // error
    if (errorTAccountCharts || errorClients) {
        return (
            <div className="loader">
                <h3>Failed loading data: {errorTAccountCharts.message ? errorClients.message : errorClients.message}</h3>
            </div>
        );
    }

    const accessClients: Party[] = [];
    if (dataClients) {
        //Check access client combined with asset accounting
        if (dataClients.me && dataClients.me.roles) {
            let access = false;
            for (const client of dataClients.clients) {
                if (dataClients.me.frontendRole && dataClients.me.frontendRole.assets.length === 0) {
                    access = true;
                } else {
                    for (const role of dataClients.me.roles) {
                        if (role.assets.includes(PermissionAssetEnum.Accounting) && role.clientIds.includes(client._id)) {
                            access = true;
                        }
                    }
                }
                // Exclude system party from client selection menu
                if (access && client._id !== SYSTEM_PARTY_ID) {
                    accessClients.push(client);
                }
            }
        }
    }

    const accountingReadWriteAccess =
        dataClients &&
        dataClients.me &&
        dataClients.me.roles &&
        userHaveAccessTo("Any", PermissionAssetEnum.Accounting, dataClients.me.roles, PermissionTypeEnum.ReadWrite)
            ? true
            : false;

    return (
        <Fragment>
            <div className="row">
                <div className="col-6">
                    <h4>T-account charts</h4>
                </div>
                {accountingReadWriteAccess ? (
                    <div className="col-6">
                        <h4>Create new t-account chart</h4>
                    </div>
                ) : null}
            </div>
            <div className="row">
                <div className="col-6">
                    <ReactTable key={tAccountCharts.length} columns={columns} data={tAccountCharts} />
                </div>
                {accountingReadWriteAccess ? (
                    <div className="col-6">
                        <ClientSelector
                            id={null}
                            endDate={null}
                            clients={accessClients}
                            buttonText={"Client"}
                            page={"taccountcharts"}
                            createTAccountChart={createTAccountChart}
                            cloneTAccountChart={cloneTAccountChart}
                            recentTAccountCharts={recentTAccountChartsByName}
                        />
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
}
