import React, { useState, useEffect, ReactElement } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { cloneDeep, sortBy } from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import cronValidate from "cron-validate";
import { TextField, SelectField, CheckboxItem, SubmitButton, JsonField, SearchMultipleSelectField } from "../../../../components/src/form";

import { MarkDownField } from "../../components/form/MarkDownField";
import { Page } from "../../components/Page";
import { isValidMongoDBObjectID, useAlertTimeOut } from "../../../../components/src/common/Utils";
import { TimezoneEnum } from "../../types.generated";

import { JobTypeEnum } from "../../../../scheduler/src/wire.types";

import { formikUrqlErrorFormater } from "../../../../components/src/common/formik-urql-error-helper";
import { GET_JOBS, UPSERT_JOB_DEFINITIONS } from "./JobsPage";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";
import { REACT_APP_API_URI } from "../../env";

const cronOptions = {
    preset: "default", // You can choose a preset or customize it
    override: {
        useSeconds: false // Set to true if you want to validate seconds
    }
};

const validateCronExpression = (cronExpression: string) => {
    const { isValid } = cronValidate(cronExpression, cronOptions);
    return isValid();
};

const defaultFormData = {
    _id: "",
    name: "",
    clientIds: ["582ae3939da3da2ebcea9f6c"], // Captor Fund Management AB
    type: JobTypeEnum.Manual,
    description: "",
    jobUserId: "59f82fd4cba1d51ef0b38d05", // JOBBOT_ID
    args: {},
    enabled: true,
    schedule: {
        day: "*",
        dayOfTheWeek: "*",
        hour: "*",
        minute: "*/20",
        month: "*"
    },
    timezone: TimezoneEnum.EuropeStockholm,
    url: "https://pyjobapi.captor.se/jobs/Template/run",
    createTimestamp: "",
    updateTimestamp: "",
    updateUserInfo: {
        name: ""
    }
};

const GET_ClIENTS = gql`
    query getClients($typeIn: [PartyType!], $statusIn: [PartyStatusEnum!]) {
        clients: parties(filter: { typeIn: $typeIn, statusIn: $statusIn }) {
            _id
            name
            dashName
            status
        }
    }
`;

export function JobPage(): ReactElement {
    const { id } = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(cloneDeep(defaultFormData));

    const isCreateMode = !!(id && id.toString() === "new");

    const [{ fetching: fetching_clients, error: error_clients, data: data_clients }] = useQuery({
        query: GET_ClIENTS,
        variables: { typeIn: "Client", statusIn: "Confirmed" },
        requestPolicy: "cache-and-network"
    });

    const [{ fetching, data, error }, _refetch] = useQuery({
        query: GET_JOBS,
        variables: {
            filter: {
                idIn: id
            }
        },
        requestPolicy: "network-only",
        pause: isCreateMode
    });

    const [_, upsertJobs] = useMutation(UPSERT_JOB_DEFINITIONS);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (id && id !== "new" && data && data.schedulerJobDefinitions && data.schedulerJobDefinitions.length > 0) {
            const job = {
                _id: data.schedulerJobDefinitions[0]._id,
                name: data.schedulerJobDefinitions[0].name,
                clientIds: data.schedulerJobDefinitions[0].clientIds,
                type: data.schedulerJobDefinitions[0].type,
                description: data.schedulerJobDefinitions[0].description,
                jobUserId: data.schedulerJobDefinitions[0].jobUserId,
                args: data.schedulerJobDefinitions[0].args,
                enabled: data.schedulerJobDefinitions[0].enabled,
                schedule: data.schedulerJobDefinitions[0].schedule,
                timezone: data.schedulerJobDefinitions[0].timezone,
                url: data.schedulerJobDefinitions[0].url,
                createTimestamp: data.schedulerJobDefinitions[0].createTimestamp,
                updateTimestamp: data.schedulerJobDefinitions[0].updateTimestamp,
                updateUserInfo: data.schedulerJobDefinitions[0].updateUserInfo
            };
            setFormData(job);
        }
    }, [id, data]);

    if (fetching || fetching_clients)
        return (
            <div className="loader">
                <p>Loading...</p>
            </div>
        );

    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

    if (error_clients) return <pre>{JSON.stringify(error_clients, null, 2)}</pre>;

    if (id !== "new" && !data.schedulerJobDefinitions) {
        return <div>Job not found</div>;
    }

    if (!isCreateMode && data && data.instrument) {
        if (!isValidMongoDBObjectID(id)) {
            return <Page header={"id: ''" + id + "'' is not a valid id format"} />;
        }
    }

    const clients = data_clients.clients.map((d) => ({ key: d._id, value: d.name, text: d.name }));
    const sortedClients = sortBy(clients, "value");

    return (
        <div>
            <Link to={"/jobs"}>Jobs</Link>
            <div className="container page mt-2">
                <div className="">
                    {formData ? (
                        <Formik
                            enableReinitialize={true}
                            initialValues={formData}
                            validationSchema={Yup.object({
                                name: Yup.string().trim().required("Name is required").min(1, "Name cannot be empty"),
                                clientIds: Yup.array().of(Yup.string()).required("Client ids are required"),
                                type: Yup.mixed().oneOf(Object.values(JobTypeEnum)).required("Job type is required"),
                                jobUserId: Yup.string().required("Job user ID is required"),
                                args: Yup.object().required("Args are required"),
                                enabled: Yup.boolean().required("Enabled is required"),
                                schedule: Yup.object({
                                    day: Yup.string()
                                        .required("Day is required")
                                        .test("is-valid-cron", "Invalid cron expression for day", (value) =>
                                            validateCronExpression(`0 0 ${value} * *`)
                                        ),
                                    dayOfTheWeek: Yup.string()
                                        .required("Day of the week is required")
                                        .test("is-valid-cron", "Invalid cron expression for dayOfTheWeek", (value) =>
                                            validateCronExpression(`0 0 * * ${value}`)
                                        ),
                                    hour: Yup.string()
                                        .required("Hour is required")
                                        .test("is-valid-cron", "Invalid cron expression for hour", (value) =>
                                            validateCronExpression(`0 ${value} * * *`)
                                        ),
                                    minute: Yup.string()
                                        .required("Minute is required")
                                        .test("is-valid-cron", "Invalid cron expression for minute", (value) =>
                                            validateCronExpression(`${value} * * * *`)
                                        ),
                                    month: Yup.string()
                                        .required("Month is required")
                                        .test("is-valid-cron", "Invalid cron expression for month", (value) =>
                                            validateCronExpression(`0 0 * ${value} *`)
                                        )
                                }),
                                timezone: Yup.mixed().oneOf(Object.values(TimezoneEnum)).required("Timezone is required"),
                                url: Yup.string().url("Invalid URL format").required("URL is required")
                            })}
                            onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                                let input = cloneDeep(submitValues);

                                if (isCreateMode) {
                                    delete input["_id"];
                                }

                                delete input["createTimestamp"];
                                delete input["updateTimestamp"];
                                delete input["updateUserInfo"];

                                input = recursivelyRemoveKey(input, "__typename");
                                await upsertJobs({ input })
                                    .then((result) => {
                                        if ("error" in result && result.error) {
                                            const message = formikUrqlErrorFormater(result.error, setErrors);
                                            setAlert({ color: "danger", visible: true, message });
                                        } else {
                                            const docId = result.data.schedulerUpsertJobDefinitions[0]._id;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `job '${input.name}' upserted successfully!`
                                            });
                                            if (docId) {
                                                const path = location.pathname.split("/");
                                                path.pop();
                                                path.push(docId);
                                                navigate(path.join("/"), { replace: true });
                                            }
                                        }
                                        return true;
                                    })
                                    .catch((error) => {
                                        setAlert({ color: "danger", visible: true, message: error.toString() });
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ isSubmitting }) => (
                                <div className="row col-12">
                                    <Form autoComplete="off" className="form">
                                        <div className="form-row">
                                            <div className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                                                <div className="form-row">
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <TextField
                                                            name="name"
                                                            label="Name"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <SelectField
                                                            name="type"
                                                            label="Type"
                                                            options={Object.keys(JobTypeEnum).sort()}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <SearchMultipleSelectField
                                                        name="clientIds"
                                                        label="Clients (owner)"
                                                        className="col-sm-6"
                                                        disabled={isSubmitting}
                                                        options={sortedClients}
                                                    />
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                                                        <CheckboxItem name="enabled" label="Enabled" />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                                        <JsonField name="args" label="Args" className="" rows={7} disabled={isSubmitting} />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                                        <SelectField
                                                            name="timezone"
                                                            label="Timezone"
                                                            options={Object.keys(TimezoneEnum).sort()}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                                        <TextField
                                                            name="schedule.day"
                                                            label="Day"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                                        <TextField
                                                            name="schedule.month"
                                                            label="Month"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                                        <TextField
                                                            name="schedule.hour"
                                                            label="Hour"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                                        <TextField
                                                            name="schedule.minute"
                                                            label="Minute"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                                        <TextField
                                                            name="schedule.dayOfTheWeek"
                                                            label="Day of the week (0-6, Sunday=0)"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <TextField
                                                            name="createTimestamp"
                                                            label="Create timestamp"
                                                            type="text"
                                                            className=""
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <TextField
                                                            name="updateTimestamp"
                                                            label="Update timestamp"
                                                            type="text"
                                                            className=""
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <TextField
                                                            name="updateUserInfo.name"
                                                            label="Update user info"
                                                            type="text"
                                                            className=""
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <TextField
                                                            name="jobUserId"
                                                            label="Job user id"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <TextField name="_id" label="Id" type="text" className="" disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-12 col-xl-12 mt-3">
                                                        <h5>Description</h5>
                                                        <MarkDownField
                                                            name="description"
                                                            graphqlApiBaseUrl={REACT_APP_API_URI}
                                                            label="Click to edit"
                                                            height={200}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <TextField
                                                            name="url"
                                                            label="Url"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {alert.visible ? (
                                            <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}

                                        <SubmitButton
                                            disabled={isSubmitting}
                                            label={isCreateMode ? "Create" : "Update"}
                                            className="btn btn-primary btn-lg"
                                        />
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
