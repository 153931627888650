import React, { useMemo, useState, useEffect } from "react";
import { gql, useQuery } from "urql";
import { Link } from "react-router-dom";
import stableStringify from "json-stable-stringify";
import { useQueryState } from "../../../components/src/use-query-state";

import { useQueryArgs, usePrevious } from "../../../components/src/common/Utils";
import { DateForm } from "../../../components/src/dateForm";

import { PriceFilterInput } from "../types.generated";
import { ReactTable } from "../../../components/src/react-table/ReactTable";
import { SelectColumnFilter } from "../../../components/src/react-table/ReactTableFilters";

const PRICES = gql`
    query prices($filter: PriceFilterInput) {
        prices(filter: $filter) {
            _id
            instrumentId
            instrument {
                name
            }
            date
            price
            accruedInterest
            cleanPrice
            currency
            type
        }
    }
`;

export const PricesPage = (): React.ReactElement => {
    const { queryArgs } = useQueryArgs();
    const [date] = useQueryState("date", new Date().toISOString().slice(0, 10));
    const previousQueryArgs = usePrevious(queryArgs);
    const [variables, setVariables] = useState<{ filter: PriceFilterInput }>({ filter: { dateIn: [date] } });

    const [{ fetching, data, error }] = useQuery({
        query: PRICES,
        variables: variables,
        requestPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            let filter: PriceFilterInput = {};
            filter = { ...variables.filter };

            if (queryArgs.date) {
                filter = { ...variables.filter, dateIn: [queryArgs.date as string] };
            }

            setVariables({ filter });
        }
    }, [previousQueryArgs, queryArgs, variables.filter]);

    const columns = useMemo(() => {
        let columns = [];
        columns.push({
            header: "Id",
            accessorKey: "_id",
            cell: (cellProps) => {
                const { row } = cellProps;
                return <Link to={`/prices/${row.original._id}`}>#</Link>;
            },
            size: 8
        });
        columns.push({
            header: "Instrument",
            id: "instrument.name",
            accessorKey: "instrument.name",
            cell: (cellProps) => {
                const { row } = cellProps;
                return <Link to={`/instruments/${row.original.instrumentId}`}>{cellProps.getValue()}</Link>;
            },
            size: 150
        });
        columns.push({
            header: "Currency",
            accessorKey: "currency",
            filter: SelectColumnFilter,
            size: 75
        });
        columns = [
            ...columns,
            ...["date", "price", "accruedInterest", "cleanPrice", "currency", "type"].map((item) => {
                return {
                    header: item
                        .replace(/([a-z])([A-Z])/g, "$1 $2")
                        .replace(/^./, (c) => c.toUpperCase())
                        .replace(/ (\w)/g, (c) => c.toLowerCase()),
                    accessorKey: item,
                    filter: SelectColumnFilter
                };
            })
        ];

        return columns;
    }, []);

    if (fetching) return <div>Loading...</div>;

    if (error)
        return (
            <div>
                <p>error:</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    if (data.prices === null) return <div>No documents</div>;
    return (
        <div>
            <div className="d-flex mb-2">
                <DateForm defaultDateString={date} dateName={"date"}></DateForm>
            </div>

            <p>Number of documents: {data.prices.length}</p>
            <ReactTable columns={columns} data={data.prices} defaultHiddenColumns={[]} />
        </div>
    );
};
