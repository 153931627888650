/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateStringOrTimestamp: { input: any; output: any; }
  DecimalString: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  GraphQLCreditRating: { input: any; output: any; }
  GraphQLDateString: { input: string; output: string; }
  GraphQLJSON: { input: any; output: any; }
  GraphQLJSONObject: { input: any; output: any; }
  GraphQLLei: { input: any; output: any; }
  GraphQLObjectId: { input: string; output: string; }
  GraphQLPatchInstrumentDate: { input: string; output: string; }
  GraphQLSwedenDate: { input: string; output: string; }
  ISIN: { input: string; output: string; }
  Timestamp: { input: any; output: any; }
  TwoDecimalString: { input: string; output: string; }
  UriPartString: { input: any; output: any; }
};

export type AcceptTermsInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  noteIds: Array<Scalars['GraphQLObjectId']['input']>;
};

export enum AccountingBatchType {
  /** Automated, similar to BVU but automatically added adjustment */
  A = 'A',
  /** Automated reverse, similar to BVI but closing previous A */
  AR = 'AR',
  /** Balance valuation ingoing, closing previous BVU */
  BVI = 'BVI',
  /** Balance valuation outgoing */
  BVU = 'BVU',
  /** Ingoing balance */
  IB = 'IB',
  /** Manual, similar to BVU but manually added */
  M = 'M',
  /** Manual reverse, similar to BVI but closing previous M */
  MR = 'MR',
  /** Transaction */
  T = 'T'
}

export enum AccountingCompanyTypeEnum {
  GeneralPortfolio = 'GeneralPortfolio',
  None = 'None',
  SwedishMutualFund = 'SwedishMutualFund',
  SwedishPensionTrust = 'SwedishPensionTrust'
}

export enum AccountingFrequencyEnum {
  Annually = 'Annually',
  Monthly = 'Monthly',
  None = 'None',
  Quarterly = 'Quarterly',
  SemiAnnually = 'SemiAnnually'
}

export enum AccountingLanguageEnum {
  Sv = 'Sv'
}

export type AccountingRun = {
  __typename?: 'AccountingRun';
  _id: Scalars['GraphQLObjectId']['output'];
  accountingPeriod: Scalars['String']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientTAccountChart?: Maybe<TAccountChart>;
  clientTAccountChartId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  createUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  endDate: Scalars['GraphQLDateString']['output'];
  journalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  masterTAccountChart?: Maybe<TAccountChart>;
  masterTAccountChartId: Scalars['GraphQLObjectId']['output'];
  number: Scalars['Int']['output'];
  roundingDecimals?: Maybe<Scalars['Int']['output']>;
  status: AccountingRunStatusEnum;
  type: AccountingRunTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum AccountingRunStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Preliminary = 'Preliminary'
}

export enum AccountingRunTypeEnum {
  Manual = 'Manual',
  Transaction = 'Transaction'
}

export type AccountingTransaction = {
  __typename?: 'AccountingTransaction';
  amount?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  tAccountNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AccountingTransactionType>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type AccountingTransactionInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  tAccountNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountingTransactionType>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum AccountingTransactionType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  CustodyFee = 'CustodyFee',
  Dividend = 'Dividend',
  DividendPaid = 'DividendPaid',
  Equity = 'Equity',
  Fee = 'Fee',
  ForeignTax = 'ForeignTax',
  ForwardCash = 'ForwardCash',
  InitialCost = 'InitialCost',
  Interest = 'Interest',
  ManagementCost = 'ManagementCost',
  ManagementFee = 'ManagementFee',
  OpeningBalance = 'OpeningBalance',
  Realized = 'Realized',
  RealizedFx = 'RealizedFx',
  Rebate = 'Rebate',
  Rounding = 'Rounding',
  ShareCapital = 'ShareCapital',
  StampDuty = 'StampDuty',
  Tax = 'Tax',
  TaxRestitution = 'TaxRestitution',
  Unrealized = 'Unrealized',
  UnrealizedFx = 'UnrealizedFx',
  ValueChange = 'ValueChange'
}

export type Agreement = {
  __typename?: 'Agreement';
  _id: Scalars['GraphQLObjectId']['output'];
  approvedInfo?: Maybe<Array<ApprovedInfo>>;
  attachments: Array<Maybe<Attachment>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  counterParties: Array<PartyInfo>;
  counterPartyIds: Array<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  parties?: Maybe<Array<Maybe<PartyInfo>>>;
  partyIds: Array<Scalars['GraphQLObjectId']['output']>;
  status: AgreementStatusEnum;
  type: AgreementTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type AgreementFilterInput = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  counterPartyIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  partyIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<AgreementStatusEnum>>;
  typeIn?: InputMaybe<Array<AgreementTypeEnum>>;
};

export type AgreementInput = {
  /** if _id is omitted, an agreement will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  approvedInfo?: InputMaybe<Array<ApprovedInfoInput>>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  clientId: Scalars['GraphQLObjectId']['input'];
  counterPartyIds: Array<Scalars['GraphQLObjectId']['input']>;
  description: Scalars['String']['input'];
  partyIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: AgreementStatusEnum;
  type: AgreementTypeEnum;
};

export enum AgreementStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Superseded = 'Superseded'
}

export enum AgreementType {
  ClearingAccount = 'ClearingAccount',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Isda = 'Isda',
  OmnibusClearingAccount = 'OmnibusClearingAccount'
}

export enum AgreementTypeEnum {
  CashTrading = 'CashTrading',
  Cdea = 'Cdea',
  ClearingBroker = 'ClearingBroker',
  CustodyAgreement = 'CustodyAgreement',
  Emir = 'Emir',
  EquityDma = 'EquityDma',
  FxTrading = 'FxTrading',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Gua = 'Gua',
  InvestmentManagement = 'InvestmentManagement',
  InvestmentPolicy = 'InvestmentPolicy',
  Isda = 'Isda',
  Mt101 = 'Mt101',
  ProcessAgent = 'ProcessAgent',
  Sftr = 'Sftr',
  Ssi = 'Ssi',
  SubscriptionRedemptionForm = 'SubscriptionRedemptionForm',
  TriParty = 'TriParty',
  UserAgreement = 'UserAgreement'
}

export type Alias = {
  __typename?: 'Alias';
  collection: CollectionNameEnum;
  /** Markdown comment */
  comment: Scalars['String']['output'];
  documentId: Scalars['GraphQLObjectId']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AliasInput = {
  collection: CollectionNameEnum;
  comment: Scalars['String']['input'];
  documentId: Scalars['GraphQLObjectId']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AnnualReportType = {
  __typename?: 'AnnualReportType';
  /** Name, year, lastYear */
  balanceSheet: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  clientId: Scalars['GraphQLObjectId']['output'];
  /** Name, agreementType, netExposure(tkr), share(%) */
  derivativesSecurities: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear */
  equityChange: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear, secondLastYear, thirdLastYear, fourthLastYear... ninthLastYear */
  fundFigures?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  /** Name, weight(%) */
  issuers: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear, secondLastYear, thirdLastYear, fourthLastYear */
  keyFigures: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** InstrumentName, country/category, quantity, exposure(tkr), share(%) */
  positions: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, creditRating, share(%) */
  ratings: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
  /** Name, year, lastYear */
  resultSheet: Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>;
};

export type ApprovedInfo = {
  __typename?: 'ApprovedInfo';
  comment?: Maybe<Scalars['String']['output']>;
  status: ApprovedStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
};

export type ApprovedInfoInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status: ApprovedStatusEnum;
  /** if updateTimestamp is omitted, it is seen as new info and updateTimestamp is added */
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  /** if updateUserId is omitted, it is seen as new info and updateUserId is added */
  updateUserId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export enum ApprovedStatusEnum {
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export type Attachment = {
  __typename?: 'Attachment';
  /**
   * base64 is a available on attachment but
   * not on attachments
   */
  base64?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fileId: Scalars['GraphQLObjectId']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  mD5?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type AttachmentInput = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fileId: Scalars['GraphQLObjectId']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  mD5?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type BankAccountBalance = {
  __typename?: 'BankAccountBalance';
  balance: Scalars['TwoDecimalString']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type BankAccountBalanceFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<BankAccountTransactionStatusEnum>>;
};

export type BankAccountTransaction = {
  __typename?: 'BankAccountTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  amount: Scalars['TwoDecimalString']['output'];
  /**
   * Balance for instrument on "date". Calculated by adding all transactions from last two days to
   * balance position received from custodian two days prior to "date"
   */
  balance: Scalars['TwoDecimalString']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  date: Scalars['GraphQLDateString']['output'];
  description: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  other?: Maybe<Scalars['GraphQLJSON']['output']>;
  reference: Scalars['String']['output'];
  status: BankAccountTransactionStatusEnum;
  /**
   * Ids of transactionItems that the bankAccountTransaction amount has matched with, used for reconciliation
   * of bank accounts
   */
  transactionItemIds: Array<Scalars['GraphQLObjectId']['output']>;
  /** Array of matched transactionItems that the bankAccountTransaction amount has matched with */
  transactionItems: Array<TransactionItem>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type BankAccountTransactionFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  ibanIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<BankAccountTransactionStatusEnum>>;
};

export type BankAccountTransactionInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount: Scalars['TwoDecimalString']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  description: Scalars['String']['input'];
  error?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  other?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  reference: Scalars['String']['input'];
  status: BankAccountTransactionStatusEnum;
  transactionItemIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export enum BankAccountTransactionStatusEnum {
  Deleted = 'Deleted',
  Pending = 'Pending',
  Settled = 'Settled'
}

export type BaseCalendar = {
  __typename?: 'BaseCalendar';
  _id: Scalars['GraphQLObjectId']['output'];
  baseEvents: Array<BaseCalendarEvent>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<PartyInfo>;
  createTimestamp: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isBusinessDay?: Maybe<Scalars['Boolean']['output']>;
  isEarlyClose?: Maybe<Scalars['Boolean']['output']>;
  name: CalendarNameEnum;
  source?: Maybe<Scalars['String']['output']>;
  status: CalendarStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
  year: Scalars['String']['output'];
};


export type BaseCalendarIsBusinessDayArgs = {
  date: Scalars['GraphQLDateString']['input'];
};


export type BaseCalendarIsEarlyCloseArgs = {
  date: Scalars['GraphQLDateString']['input'];
};

export type BaseCalendarEvent = {
  __typename?: 'BaseCalendarEvent';
  date: Scalars['GraphQLDateString']['output'];
  event: Scalars['String']['output'];
  state: CalendarStateEnum;
};

export type BaseCalendarEventInput = {
  date: Scalars['GraphQLDateString']['input'];
  event: Scalars['String']['input'];
  state: CalendarStateEnum;
};

export type BaseCalendarFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  names?: InputMaybe<Array<CalendarNameEnum>>;
  statusIn?: InputMaybe<Array<CalendarStatusEnum>>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BaseCalendarInput = {
  /** If _id is omitted, a Calendar will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  baseEvents: Array<BaseCalendarEventInput>;
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: CalendarNameEnum;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CalendarStatusEnum>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  currency?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  offset?: Maybe<Scalars['Float']['output']>;
};

export type BenchmarkInput = {
  currency?: InputMaybe<CurrencyEnum>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type BeneficialOwner = {
  __typename?: 'BeneficialOwner';
  _id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BeneficialOwnerInput = {
  /** _id is a pointer to a document in a not yet implemented collection BeneficialOwner */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum BestExecutionType {
  /**
   * Client denotes transactions entered by a client it self as
   * a part of an asset management agreement.
   */
  Client = 'Client',
  /**
   * Execution denotes a trade executed on behalf a client
   * outside an asset management agreement.
   */
  Execution = 'Execution',
  /**
   * Maintenance denotes transactions such as dividends, coupons etc.
   * that follows from positions held.
   */
  Maintenance = 'Maintenance',
  /**
   * Portfolio denotes a trade conducted as under an asset
   * management agreement.
   */
  Portfolio = 'Portfolio',
  /**
   * Transmission denotes a trade passed on to another broker on behalf
   * of a client outside an asset management agreement.
   */
  Transmission = 'Transmission'
}

export type BrokerTransaction = {
  __typename?: 'BrokerTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  broker?: Maybe<Party>;
  brokerId: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  commission: Scalars['Float']['output'];
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  isin: Scalars['ISIN']['output'];
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  settlementAmount?: Maybe<Scalars['Float']['output']>;
  stampDuty: Scalars['Float']['output'];
  status?: Maybe<BrokerTransactionStatus>;
  tradeCurrency?: Maybe<CurrencyEnum>;
  tradeDate: Scalars['GraphQLDateString']['output'];
  type: BrokerTransactionType;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type BrokerTransactionFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<BrokerTransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum BrokerTransactionStatus {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum BrokerTransactionType {
  Buy = 'Buy',
  Sell = 'Sell'
}

export type Calendar = {
  __typename?: 'Calendar';
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  isBusinessDay?: Maybe<Scalars['Boolean']['output']>;
  isEarlyClose?: Maybe<Scalars['Boolean']['output']>;
  names?: Maybe<Array<CalendarNameEnum>>;
};


export type CalendarIsBusinessDayArgs = {
  date: Scalars['GraphQLDateString']['input'];
};


export type CalendarIsEarlyCloseArgs = {
  date: Scalars['GraphQLDateString']['input'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  date: Scalars['GraphQLDateString']['output'];
  events: Array<EventInfo>;
  state: CalendarStateEnum;
};

export enum CalendarNameEnum {
  SwedenFixedIncome = 'SwedenFixedIncome',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStatesEquity = 'UnitedStatesEquity',
  UnitedStatesFixedIncome = 'UnitedStatesFixedIncome'
}

export enum CalendarStateEnum {
  Closed = 'Closed',
  EarlyClose = 'EarlyClose'
}

export enum CalendarStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type CashAccountBalance = {
  __typename?: 'CashAccountBalance';
  balanceItems: Array<CashBalance>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CashAccountLadder = {
  __typename?: 'CashAccountLadder';
  amounts: Array<Scalars['Float']['output']>;
  balances: Array<Scalars['Float']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  dates: Array<Scalars['GraphQLDateString']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CashBalance = {
  __typename?: 'CashBalance';
  amount: Scalars['Float']['output'];
  balance: Scalars['Float']['output'];
  tradeDate: Scalars['GraphQLDateString']['output'];
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['GraphQLObjectId']['output'];
  transactionItem?: Maybe<TransactionItem>;
  transactionItemId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type CashFlowItem = {
  __typename?: 'CashFlowItem';
  amount?: Maybe<Scalars['Float']['output']>;
  baseCurrency?: Maybe<CurrencyEnum>;
  currency?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
};

export enum CdsType {
  Basket = 'Basket',
  Index = 'Index'
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export enum CicAreaEnum {
  /** UnitedArabEmirates */
  AE = 'AE',
  /** Argentina */
  AR = 'AR',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Belgium */
  BE = 'BE',
  /** Bulgaria */
  BG = 'BG',
  /** Bermuda */
  BM = 'BM',
  /** Canada */
  CA = 'CA',
  /** Switzerland */
  CH = 'CH',
  /** Chile */
  CL = 'CL',
  /** China */
  CN = 'CN',
  /** Cyprus */
  CY = 'CY',
  /** CzechRepublic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Denmark */
  DK = 'DK',
  /** Estonia */
  EE = 'EE',
  /** Spain */
  ES = 'ES',
  /** Finland */
  FI = 'FI',
  /** FaroeIslands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** UnitedKingdom */
  GB = 'GB',
  /** Guernsey */
  GG = 'GG',
  /** Greece */
  GR = 'GR',
  /** HongKong */
  HK = 'HK',
  /** Croatia */
  HR = 'HR',
  /** Hungary */
  HU = 'HU',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** IsleOfMan */
  IM = 'IM',
  /** Iran */
  IR = 'IR',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** CaymanIslands */
  KY = 'KY',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Macao */
  MO = 'MO',
  /** Malta */
  MT = 'MT',
  /** Mexico */
  MX = 'MX',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** NewZealand */
  NZ = 'NZ',
  /** None */
  None = 'None',
  /** Poland */
  PL = 'PL',
  /** Portugal */
  PT = 'PT',
  /** Romania */
  RO = 'RO',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** Slovenia */
  SI = 'SI',
  /** Slovakia */
  SK = 'SK',
  /** UnitedStates */
  US = 'US',
  /** Assets that are not listed in a stock exchange */
  XL = 'XL',
  /** Assets that are not exchange tradable */
  XT = 'XT',
  /** SouthAfrica */
  ZA = 'ZA'
}

export enum CicTypeEnum {
  /** None */
  None = 'None',
  /** 00 Other investments */
  XX00 = 'XX00',
  /** 11 Central Government bonds */
  XX11 = 'XX11',
  /** 12 Supra-national bonds */
  XX12 = 'XX12',
  /** 13 Regional government bonds */
  XX13 = 'XX13',
  /** 14 Municipal government bonds */
  XX14 = 'XX14',
  /** 15 Treasury bonds */
  XX15 = 'XX15',
  /** 16 Covered bonds */
  XX16 = 'XX16',
  /** 17 National Central bank bonds */
  XX17 = 'XX17',
  /** 19 Government bonds Other */
  XX19 = 'XX19',
  /** 21 Corporate bonds */
  XX21 = 'XX21',
  /** 22 Convertible bonds */
  XX22 = 'XX22',
  /** 23 Commercial paper */
  XX23 = 'XX23',
  /** 24 Money market instruments */
  XX24 = 'XX24',
  /** 25 Hybrid bonds */
  XX25 = 'XX25',
  /** 26 Common covered bonds */
  XX26 = 'XX26',
  /** 27 Covered bonds subject to specific law */
  XX27 = 'XX27',
  /** 28 Subordinated bonds */
  XX28 = 'XX28',
  /** 29 Corporate bonds Other */
  XX29 = 'XX29',
  /** 31 Common equity */
  XX31 = 'XX31',
  /** 32 Equity of real estate related corporation */
  XX32 = 'XX32',
  /** 33 Equity rights */
  XX33 = 'XX33',
  /** 34 Preferred equity */
  XX34 = 'XX34',
  /** 39 Equity Other */
  XX39 = 'XX39',
  /** 41 Equity funds */
  XX41 = 'XX41',
  /** 42 Debt funds */
  XX42 = 'XX42',
  /** 43 Money market funds */
  XX43 = 'XX43',
  /** 44 Asset allocation funds */
  XX44 = 'XX44',
  /** 45 Real estate funds */
  XX45 = 'XX45',
  /** 46 Alternative funds */
  XX46 = 'XX46',
  /** 47 Private equity funds */
  XX47 = 'XX47',
  /** 48 Infrastructure funds */
  XX48 = 'XX48',
  /** 49 Collective Investment Undertakings (funds) other */
  XX49 = 'XX49',
  /** 51 Structured notes Equity risk */
  XX51 = 'XX51',
  /** 52 Structured notes Interest rate risk */
  XX52 = 'XX52',
  /** 53 Structured notes Currency risk */
  XX53 = 'XX53',
  /** 54 Structured notes Credit risk */
  XX54 = 'XX54',
  /** 55 Structured notes Real estate risk */
  XX55 = 'XX55',
  /** 56 Structured notes Commodity risk */
  XX56 = 'XX56',
  /** 57 Structured notes Catastrophe and Weather risk */
  XX57 = 'XX57',
  /** 58 Structured notes Mortality risk */
  XX58 = 'XX58',
  /** 59 Structured notes Structured notes Other */
  XX59 = 'XX59',
  /** 61 Collateralised securities Equity risk */
  XX61 = 'XX61',
  /** 62 Collateralised securities Interest rate risk */
  XX62 = 'XX62',
  /** 63 Collateralised securities Currency risk */
  XX63 = 'XX63',
  /** 64 Collateralised securities Credit risk */
  XX64 = 'XX64',
  /** 65 Collateralised securities Real estate risk */
  XX65 = 'XX65',
  /** 66 Collateralised securities Commodity risk */
  XX66 = 'XX66',
  /** 67 Collateralised securities Catastrophe and Weather risk */
  XX67 = 'XX67',
  /** 68 Collateralised securities Mortality risk */
  XX68 = 'XX68',
  /** 69 Collateralised securities Other */
  XX69 = 'XX69',
  /** 71 Cash */
  XX71 = 'XX71',
  /** 72 Cash Transferable deposits ,cash equivalents */
  XX72 = 'XX72',
  /** 73 Cash Other deposits short term ,less than or equal to one year */
  XX73 = 'XX73',
  /** 74 Cash Other deposits with term longer than one year */
  XX74 = 'XX74',
  /** 75 Cash Deposits to cedants */
  XX75 = 'XX75',
  /** 79 Cash and deposits Other */
  XX79 = 'XX79',
  /** 81 Uncollateralized loans made */
  XX81 = 'XX81',
  /** 82 Loans made collateralized with securities */
  XX82 = 'XX82',
  /** 84 Mortgages */
  XX84 = 'XX84',
  /** 85 Other collateralized loans made */
  XX85 = 'XX85',
  /** 86 Loans on policies */
  XX86 = 'XX86',
  /** 89 Mortgages and loans Other */
  XX89 = 'XX89',
  /** 91 Property (office and commercial) */
  XX91 = 'XX91',
  /** 92 Property (residential) */
  XX92 = 'XX92',
  /** 93 Property (for own use) */
  XX93 = 'XX93',
  /** 94 Property (under construction) */
  XX94 = 'XX94',
  /** 95 Plant and equipment (for own use) */
  XX95 = 'XX95',
  /** 99 Property Other */
  XX99 = 'XX99',
  /** A1 Equity and index futures */
  XXA1 = 'XXA1',
  /** A2 Interest rate futures */
  XXA2 = 'XXA2',
  /** A3 Currency futures */
  XXA3 = 'XXA3',
  /** A5 Commodity futures */
  XXA5 = 'XXA5',
  /** A7 Catastrophe and Weather risk futures */
  XXA7 = 'XXA7',
  /** A8 Mortality risk futures */
  XXA8 = 'XXA8',
  /** A9 Futures Other */
  XXA9 = 'XXA9',
  /** B1 Equity and index call options */
  XXB1 = 'XXB1',
  /** B2 Bond call options */
  XXB2 = 'XXB2',
  /** B3 Currency call options */
  XXB3 = 'XXB3',
  /** B4 Call Warrants */
  XXB4 = 'XXB4',
  /** B5 Commodity call options */
  XXB5 = 'XXB5',
  /** B6 Swaptions call / receiver */
  XXB6 = 'XXB6',
  /** B7 Catastrophe and Weather risk call options */
  XXB7 = 'XXB7',
  /** B8 Mortality risk call options */
  XXB8 = 'XXB8',
  /** B9 Call Options Other */
  XXB9 = 'XXB9',
  /** C1 Equity and index put options */
  XXC1 = 'XXC1',
  /** C2 Bond put options */
  XXC2 = 'XXC2',
  /** C3 Currency put options */
  XXC3 = 'XXC3',
  /** C4 Put Warrants */
  XXC4 = 'XXC4',
  /** C5 Commodity put options */
  XXC5 = 'XXC5',
  /** C6 Swaptions put / payer */
  XXC6 = 'XXC6',
  /** C7 Catastrophe and Weather risk put options */
  XXC7 = 'XXC7',
  /** C8 Mortality risk put options */
  XXC8 = 'XXC8',
  /** C9 Put Options Other */
  XXC9 = 'XXC9',
  /** D1 Interest rate swaps */
  XXD1 = 'XXD1',
  /** D2 Currency swaps */
  XXD2 = 'XXD2',
  /** D3 Interest rate and currency swaps */
  XXD3 = 'XXD3',
  /** D5 Security swaps */
  XXD5 = 'XXD5',
  /** D7 Catastrophe and Weather risk swaps */
  XXD7 = 'XXD7',
  /** D8 Mortality risk swaps */
  XXD8 = 'XXD8',
  /** D9 Swaps Other */
  XXD9 = 'XXD9',
  /** E1 Forward interest rate agreement */
  XXE1 = 'XXE1',
  /** E2 Forward exchange rate agreement */
  XXE2 = 'XXE2',
  /** E7 Catastrophe and Weather risk forwards */
  XXE7 = 'XXE7',
  /** E8 Mortality risk forwards */
  XXE8 = 'XXE8',
  /** E9 Forwards Other */
  XXE9 = 'XXE9',
  /** F1 Credit default swap */
  XXF1 = 'XXF1',
  /** F2 Credit spread option */
  XXF2 = 'XXF2',
  /** F3 Credit spread swap */
  XXF3 = 'XXF3',
  /** F4 Total return swap */
  XXF4 = 'XXF4',
  /** F9 Credit derivatives other */
  XXF9 = 'XXF9'
}

export type ClientForAsset = {
  __typename?: 'ClientForAsset';
  asset: PermissionAssetEnum;
  clients: Array<ClientInfo>;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
};

export enum CollectionNameEnum {
  BrokerTransaction = 'BrokerTransaction',
  CorporateAction = 'CorporateAction',
  Instrument = 'Instrument',
  IssuerProgram = 'IssuerProgram',
  None = 'None',
  Party = 'Party',
  Screen = 'Screen',
  Swift = 'Swift',
  Transaction = 'Transaction'
}

export type CorporateAction = {
  __typename?: 'CorporateAction';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Gross amount */
  amount?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  corporateActionEvent: CorporateActionEvent;
  correspondingTransaction?: Maybe<Transaction>;
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency?: Maybe<CurrencyEnum>;
  error?: Maybe<Scalars['String']['output']>;
  exDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  externalId: Scalars['String']['output'];
  /** Tax amount */
  foreignTax?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  messageType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  recordDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  reportIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  status: CorporateActionStatusEnum;
  swift?: Maybe<Swift>;
  swiftId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export enum CorporateActionEvent {
  /** Accumulation */
  ACCU = 'ACCU',
  /** Trading Status: Active */
  ACTV = 'ACTV',
  /** Attachment */
  ATTI = 'ATTI',
  /** Repurchase Offer */
  BIDS = 'BIDS',
  /** Bondholder Meeting */
  BMET = 'BMET',
  /** Bonus Issue */
  BONU = 'BONU',
  /** Put Redemption */
  BPUT = 'BPUT',
  /** Bankruptcy */
  BRUP = 'BRUP',
  /** Capital Distribution */
  CAPD = 'CAPD',
  /** Capital Gains Distribution */
  CAPG = 'CAPG',
  /** Capitalisation */
  CAPI = 'CAPI',
  /** Non-US TEFRA D Certification */
  CERT = 'CERT',
  /** Change */
  CHAN = 'CHAN',
  /** Class Action */
  CLSA = 'CLSA',
  /** Court Meeting */
  CMET = 'CMET',
  /** Consent */
  CONS = 'CONS',
  /** Conversion */
  CONV = 'CONV',
  /** Company Option */
  COOP = 'COOP',
  /** Credit Event */
  CREV = 'CREV',
  /** Decrease in Value */
  DECR = 'DECR',
  /** Detachment */
  DETI = 'DETI',
  /** Bond Default */
  DFLT = 'DFLT',
  /** Trading Status: Delisted */
  DLST = 'DLST',
  /** Drawing */
  DRAW = 'DRAW',
  /** Cash Distribution from Non-Eligible Securities Sales */
  DRCA = 'DRCA',
  /** Dividend Reinvestment */
  DRIP = 'DRIP',
  /** Disclosure */
  DSCL = 'DSCL',
  /** Dutch Auction */
  DTCH = 'DTCH',
  /** Cash Dividend */
  DVCA = 'DVCA',
  /** Dividend Option */
  DVOP = 'DVOP',
  /** Scrip Dividend */
  DVSC = 'DVSC',
  /** Stock Dividend */
  DVSE = 'DVSE',
  /** Exchange */
  EXOF = 'EXOF',
  /** Call on Intermediate Securities */
  EXRI = 'EXRI',
  /** Maturity Extension */
  EXTM = 'EXTM',
  /** Warrant Exercise */
  EXWA = 'EXWA',
  /** Increase in Value */
  INCR = 'INCR',
  /** Information */
  INFO = 'INFO',
  /** Interest Payment */
  INTR = 'INTR',
  /** Liquidation Dividend */
  LIQU = 'LIQU',
  /** Full Call */
  MCAL = 'MCAL',
  /** Annual General Meeting */
  MEET = 'MEET',
  /** Merger */
  MRGR = 'MRGR',
  /** New Offer */
  NOOF = 'NOOF',
  /** Odd Lot Offer */
  ODLT = 'ODLT',
  /** Other Event */
  OTHR = 'OTHR',
  /** Parity Adjustment */
  PARI = 'PARI',
  /** Partial Defeasance */
  PDEF = 'PDEF',
  /** Pink Sheet Trading */
  PINK = 'PINK',
  /** Placement */
  PLAC = 'PLAC',
  /** Partial Redemption with Pool Factor Reduction */
  PPUB = 'PPUB',
  /** Prime Rate Interest Payment */
  PRII = 'PRII',
  /** Priority Issue */
  PRIO = 'PRIO',
  /** Redemption */
  REDM = 'REDM',
  /** Remarketing */
  REMK = 'REMK',
  /** Rights Distribution */
  RHDI = 'RHDI',
  /** Rights Issue */
  RHTS = 'RHTS',
  /** Spin-Off */
  SDRV = 'SDRV',
  /** Shareholders' Meeting */
  SHPR = 'SHPR',
  /** Smallest Negotiable Unit */
  SMAL = 'SMAL',
  /** Stock Offering */
  SOFF = 'SOFF',
  /** Stock Split */
  SPLF = 'SPLF',
  /** Reverse Stock Split */
  SPLR = 'SPLR',
  /** Tender Offer */
  TEND = 'TEND',
  /** Worthless Security */
  WRTH = 'WRTH'
}

export type CorporateActionFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  corporateActionEventIn?: InputMaybe<Array<CorporateActionEvent>>;
  /** Corresponding transaction */
  correspondingTransactionIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Corporate action event reference */
  externalIdIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  messageTypeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record date, format: YYYY-MM-DD */
  recordDateEnd?: InputMaybe<Scalars['String']['input']>;
  /** Record date, format: YYYY-MM-DD */
  recordDateStart?: InputMaybe<Scalars['String']['input']>;
  reportIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<CorporateActionStatusEnum>>;
  swiftIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type CorporateActionInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Gross amount */
  amount?: InputMaybe<Scalars['Float']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  /** Required for create */
  corporateActionEvent?: InputMaybe<CorporateActionEvent>;
  correspondingTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  exDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** Required for create */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Tax amount */
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Required for create */
  messageType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  recordDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** reportIds or swiftId required for create */
  reportIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  status?: InputMaybe<CorporateActionStatusEnum>;
  /** reportIds or swiftId required for create */
  swiftId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Required for create */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Required for create */
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum CorporateActionStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Preliminary = 'Preliminary',
  /** Messages of type 566 gets status settled when matched */
  Settled = 'Settled',
  /** Used for messageType 566 when original coacs(swift) of messageType 564 has been updated and coacs(swift) of messageType is insignificant */
  UpdatedPrevious = 'UpdatedPrevious',
  /**
   * Used for messages with corporateActionEvent: DRIP/DVOP, theese are received earlier (not received two days after ex-date
   * like other corporateActionEvents)
   */
  Waiting = 'Waiting'
}

export type Cost = {
  __typename?: 'Cost';
  _id: Scalars['GraphQLObjectId']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  currency?: Maybe<CurrencyEnum>;
  date: Scalars['GraphQLDateString']['output'];
  debtorIds: Array<Scalars['GraphQLObjectId']['output']>;
  debtors: Array<PartyInfo>;
  description: Scalars['String']['output'];
  endDate: Scalars['GraphQLDateString']['output'];
  startDate: Scalars['GraphQLDateString']['output'];
  status: CostStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CostFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<CostStatusEnum>>;
};

export type CostInput = {
  /** if _id is omitted, a Cost will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  clientId: Scalars['GraphQLObjectId']['input'];
  currency?: InputMaybe<CurrencyEnum>;
  date: Scalars['GraphQLDateString']['input'];
  debtorIds: Array<Scalars['GraphQLObjectId']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['GraphQLDateString']['input'];
  startDate: Scalars['GraphQLDateString']['input'];
  status: CostStatusEnum;
  /** A list of versions of the document */
  versions?: InputMaybe<Scalars['GraphQLJSON']['input']>;
};

export enum CostStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum CountryCodeEnum {
  /** UnitedArabEmirates */
  AE = 'AE',
  /** Argentina */
  AR = 'AR',
  /** Austria */
  AT = 'AT',
  /** Australia */
  AU = 'AU',
  /** Belgium */
  BE = 'BE',
  /** Bulgaria */
  BG = 'BG',
  /** Bermuda */
  BM = 'BM',
  /** Canada */
  CA = 'CA',
  /** Switzerland */
  CH = 'CH',
  /** Chile */
  CL = 'CL',
  /** China */
  CN = 'CN',
  /** Cyprus */
  CY = 'CY',
  /** CzechRepublic */
  CZ = 'CZ',
  /** Germany */
  DE = 'DE',
  /** Denmark */
  DK = 'DK',
  /** Estonia */
  EE = 'EE',
  /** Spain */
  ES = 'ES',
  /** Finland */
  FI = 'FI',
  /** FaroeIslands */
  FO = 'FO',
  /** France */
  FR = 'FR',
  /** UnitedKingdom */
  GB = 'GB',
  /** Guernsey */
  GG = 'GG',
  /** Greece */
  GR = 'GR',
  /** HongKong */
  HK = 'HK',
  /** Croatia */
  HR = 'HR',
  /** Hungary */
  HU = 'HU',
  /** Ireland */
  IE = 'IE',
  /** Israel */
  IL = 'IL',
  /** IsleOfMan */
  IM = 'IM',
  /** Iran */
  IR = 'IR',
  /** Italy */
  IT = 'IT',
  /** Jersey */
  JE = 'JE',
  /** Jordan */
  JO = 'JO',
  /** Japan */
  JP = 'JP',
  /** CaymanIslands */
  KY = 'KY',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Latvia */
  LV = 'LV',
  /** Macao */
  MO = 'MO',
  /** Malta */
  MT = 'MT',
  /** Mexico */
  MX = 'MX',
  /** Netherlands */
  NL = 'NL',
  /** Norway */
  NO = 'NO',
  /** NewZealand */
  NZ = 'NZ',
  /** Poland */
  PL = 'PL',
  /** Portugal */
  PT = 'PT',
  /** Romania */
  RO = 'RO',
  /** Sweden */
  SE = 'SE',
  /** Singapore */
  SG = 'SG',
  /** Slovenia */
  SI = 'SI',
  /** Slovakia */
  SK = 'SK',
  /** UnitedStates */
  US = 'US',
  /** Unknown */
  Unknown = 'Unknown',
  /** SouthAfrica */
  ZA = 'ZA'
}

export type CreateAccountingRunInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  clientTAccountChartId: Scalars['GraphQLObjectId']['input'];
  endDate: Scalars['GraphQLDateString']['input'];
  masterTAccountChartId: Scalars['GraphQLObjectId']['input'];
  type: AccountingRunTypeEnum;
};

export type CreateAttachmentInput = {
  base64: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type CreateCurveDefinitionInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  definition?: InputMaybe<CurveDefinitionDefinitionInput>;
  name: Scalars['String']['input'];
  type: CurveDefinitionType;
};

export type CreateInstrumentInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  benchmarkId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergQuoteMultiplier?: InputMaybe<Scalars['Float']['input']>;
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<InstrumentCategory>;
  cicArea?: InputMaybe<CicAreaEnum>;
  cicType?: InputMaybe<CicTypeEnum>;
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchange?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<InstrumentModelInput>;
  name: Scalars['String']['input'];
  placeOfSettlementId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  productType?: InputMaybe<InstrumentProductTypeEnum>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InstrumentStatusEnum>;
  /** type defaults to Instrument */
  type?: InputMaybe<InstrumentTypeEnum>;
};

export type CreateJournalEntryInput = {
  accountingRunId: Scalars['GraphQLObjectId']['input'];
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type CreateMiniTransactionInput = {
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType?: InputMaybe<AgreementType>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  bonusShares?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['Boolean']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  itemType?: InputMaybe<FeeInterestItemType>;
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reverts + open a new transaction from input, available for dividend/interest in gui only */
  revertTransaction?: InputMaybe<Scalars['Boolean']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  secondAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  secondAmount?: InputMaybe<Scalars['Float']['input']>;
  secondCurrency?: InputMaybe<CurrencyEnum>;
  secondInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  source?: InputMaybe<SourceType>;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  type: TransactionType;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type CreatePartyInput = {
  accountant?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  approval?: InputMaybe<Scalars['String']['input']>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<NaceClass>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types: Array<PartyType>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportInput = {
  base64?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  name: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ReportStatusEnum>;
  type: Scalars['String']['input'];
};

export type CreateRoleInput = {
  assets: Array<InputMaybe<PermissionAssetEnum>>;
  clientIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissionType: PermissionTypeEnum;
  roleType: RoleTypeEnum;
  status: DocumentStatusEnum;
};

export type CreateSwiftInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  parsed: Scalars['GraphQLJSON']['input'];
  reportIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: SwiftStatusEnum;
  text: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateTAccountChartInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tAccountMappings?: InputMaybe<Array<TAccountMappingInput>>;
  tAccounts?: InputMaybe<Array<TAccountInput>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTransactionInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType: BestExecutionType;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  items: Array<CreateTransactionItemInput>;
  number?: InputMaybe<Scalars['Int']['input']>;
  status: TransactionStatus;
  tradeTimestamp: Scalars['Timestamp']['input'];
  type: TransactionType;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTransactionItemInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  type: TransactionItemType;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type CreateUpdateCashInstrumentInput = {
  /** Create: Not included Update: Required */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Create: Optional Update: Optional */
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  create: Scalars['Boolean']['input'];
  /** Create: Required Update: Optional */
  currency?: InputMaybe<CurrencyEnum>;
  /** Create: Required Update: Optional */
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Account number, Create: Optional Update: Optional */
  isin?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUpdateExternalAccountInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  baseCurrency?: InputMaybe<CurrencyEnum>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  create: Scalars['Boolean']['input'];
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  custodianId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  emirReporting?: InputMaybe<Scalars['Boolean']['input']>;
  initialMarginCurrencies?: InputMaybe<Array<CurrencyEnum>>;
  minimumTransferAmount?: InputMaybe<Scalars['Float']['input']>;
  noCollateralRequirement?: InputMaybe<Scalars['Boolean']['input']>;
  principalCurrencies?: InputMaybe<Array<CurrencyEnum>>;
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  settlementOnVMInstrument?: InputMaybe<Scalars['Boolean']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type: PartyExternalAccountType;
  variationMarginCurrencies?: InputMaybe<Array<CurrencyEnum>>;
};

export type CreateUpdatePartyCashOrBalanceInstrumentInput = {
  /** Create: Not included Update: Required */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Create: Optional Update: Optional */
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  bic?: InputMaybe<Scalars['String']['input']>;
  cicArea?: InputMaybe<CicAreaEnum>;
  cicType?: InputMaybe<CicTypeEnum>;
  /** Create: Required Update: Not included */
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  create: Scalars['Boolean']['input'];
  /** Create: Required Update: Optional */
  currency?: InputMaybe<CurrencyEnum>;
  /** Create: Required Update: Optional */
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  /** Account number, Create: Optional Update: Optional */
  isin?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Only acceptable options are CashAccount or BalanceAccount */
  productType: InstrumentProductTypeEnum;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserInput = {
  bidExpiration: Scalars['Int']['input'];
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  company: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  frontendRoleId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  noteIds: Array<Scalars['GraphQLObjectId']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  personalNumber: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['GraphQLObjectId']['input']>;
  status: UserStatusEnum;
};

export type CreateValuationInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLSwedenDate']['input'];
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  instrumentName?: InputMaybe<Scalars['String']['input']>;
  prices: Array<ValuationPriceInput>;
  source?: InputMaybe<Scalars['String']['input']>;
  writeUser?: InputMaybe<Scalars['String']['input']>;
};

export type CreditRatingInput = {
  creditRatingAgencyId: Scalars['GraphQLObjectId']['input'];
  grade: Scalars['GraphQLCreditRating']['input'];
};

export type CreditStatus = {
  pariPassu: TrueFalseNone;
  secured: TrueFalseNone;
  senior: TrueFalseNone;
};

export type CreditStatusType = {
  __typename?: 'CreditStatusType';
  pariPassu: TrueFalseNone;
  secured: TrueFalseNone;
  senior: TrueFalseNone;
};

export enum CurrencyEnum {
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  ILS = 'ILS',
  INR = 'INR',
  ISK = 'ISK',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  USD = 'USD',
  ZAR = 'ZAR'
}

export type CurveDefinition = {
  __typename?: 'CurveDefinition';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  definition?: Maybe<CurveDefinitionDefinition>;
  name: Scalars['String']['output'];
  type: CurveDefinitionType;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CurveDefinitionDefinition = {
  __typename?: 'CurveDefinitionDefinition';
  currency: CurrencyEnum;
  dayCount: Scalars['String']['output'];
  forwardPeriod: Scalars['String']['output'];
  instruments: Array<CurveDefinitionInstrument>;
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId: Scalars['GraphQLObjectId']['output'];
  type: CurveDefinitionType2;
};

export type CurveDefinitionDefinitionInput = {
  currency: CurrencyEnum;
  dayCount: Scalars['String']['input'];
  forwardPeriod: Scalars['String']['input'];
  instruments: Array<CurveDefinitionInstrumentInput>;
  issuerProgramId: Scalars['GraphQLObjectId']['input'];
  type: CurveDefinitionType2;
};

export type CurveDefinitionInstrument = {
  __typename?: 'CurveDefinitionInstrument';
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type CurveDefinitionInstrumentInput = {
  instrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum CurveDefinitionType {
  BasisCurve = 'BasisCurve',
  CreditCurve = 'CreditCurve',
  FixingCurve = 'FixingCurve',
  FxCurve = 'FxCurve',
  YieldCurve = 'YieldCurve'
}

export enum CurveDefinitionType2 {
  YieldCurveDefinition = 'YieldCurveDefinition'
}

export type CustodianApiPosition = {
  __typename?: 'CustodianApiPosition';
  _id: Scalars['GraphQLObjectId']['output'];
  accountNumber: Scalars['String']['output'];
  attachments: Array<Attachment>;
  cashAccountNumber: Scalars['String']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  custodian: PartyInfo;
  custodianId: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  status: StatusEnum;
  type: CustodianPositionType;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type CustodianApiPositionInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountNumber: Scalars['String']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  cashAccountNumber: Scalars['String']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  custodianId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  externalAccountId: Scalars['GraphQLObjectId']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status: StatusEnum;
  type: CustodianPositionType;
};

export type CustodianPosition = {
  __typename?: 'CustodianPosition';
  amount?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<CurrencyEnum>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export enum CustodianPositionType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type Customer = {
  __typename?: 'Customer';
  _id: Scalars['GraphQLObjectId']['output'];
  clientAccount: Scalars['String']['output'];
  clientAccountLabel: Scalars['String']['output'];
  /** clientIds are used for access control */
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clientNumber: Scalars['String']['output'];
  clients: Array<PartyInfo>;
  organizationName: Scalars['String']['output'];
  swedishFinancialAuthorityCategory: SwedishFinancialAuthorityCategoryEnum;
  swedishFinancialAuthorityCategoryString: Scalars['String']['output'];
};

export type CustomerFilterInput = {
  categoryIn?: InputMaybe<Array<SwedishFinancialAuthorityCategoryEnum>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type CustomerInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientAccount: Scalars['String']['input'];
  clientAccountLabel: Scalars['String']['input'];
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  clientNumber: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  swedishFinancialAuthorityCategory: SwedishFinancialAuthorityCategoryEnum;
};

export type DartBasic = {
  __typename?: 'DartBasic';
  analysisFeeDaily?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fund?: Maybe<Scalars['String']['output']>;
  fundFigureAttribute?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  managementFeeDaily?: Maybe<Scalars['Float']['output']>;
  navAfterManagementFee?: Maybe<Scalars['Float']['output']>;
  navBeforeManagementFee?: Maybe<Scalars['Float']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // NAV basic data description
   */
  navDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  navPerUnit?: Maybe<Scalars['Float']['output']>;
  numberOfFundUnits?: Maybe<Scalars['Float']['output']>;
  organisationNo?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  taxRestitutions?: Maybe<Scalars['Float']['output']>;
};

export type DartHolding = {
  __typename?: 'DartHolding';
  accruedInterestLocalCurrency?: Maybe<Scalars['Float']['output']>;
  accruedInterestSek?: Maybe<Scalars['Float']['output']>;
  balanceNominal?: Maybe<Scalars['Float']['output']>;
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  cfi?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fundName?: Maybe<Scalars['String']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // Holdings, cash and other assets & liabilities
   */
  fundOrganisationNumber?: Maybe<Scalars['String']['output']>;
  fundShort?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  instrumentType?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  marketValueLocalCurrency?: Maybe<Scalars['Float']['output']>;
  marketValueSek?: Maybe<Scalars['Float']['output']>;
  navDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  priceFuture?: Maybe<Scalars['Float']['output']>;
  pricePerUnit?: Maybe<Scalars['Float']['output']>;
  priceType?: Maybe<Scalars['String']['output']>;
  quotation?: Maybe<Scalars['String']['output']>;
  securityGroup?: Maybe<Scalars['String']['output']>;
  securityName?: Maybe<Scalars['String']['output']>;
  securityShort?: Maybe<Scalars['String']['output']>;
  securityType?: Maybe<Scalars['String']['output']>;
  tradingCurrency?: Maybe<Scalars['String']['output']>;
  unsettledAmountIncomingLocalCurrency?: Maybe<Scalars['Float']['output']>;
  unsettledAmountIncomingSek?: Maybe<Scalars['Float']['output']>;
  unsettledAmountOutgoingLocalCurrency?: Maybe<Scalars['Float']['output']>;
  unsettledAmountOutgoingSek?: Maybe<Scalars['Float']['output']>;
  unsettledQuantity?: Maybe<Scalars['Float']['output']>;
};

export type DartReport = {
  __typename?: 'DartReport';
  /** // NAV basic data description */
  basics?: Maybe<Array<Maybe<DartBasic>>>;
  holdings?: Maybe<Array<Maybe<DartHolding>>>;
  transactions?: Maybe<Array<Maybe<DartTransaction>>>;
};

export type DartTransaction = {
  __typename?: 'DartTransaction';
  cashAccount?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  grossAmount?: Maybe<Scalars['Float']['output']>;
  investor?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  shareClassInternalCode?: Maybe<Scalars['String']['output']>;
  shareClassName?: Maybe<Scalars['String']['output']>;
  /**
   * // DART data reporting requirements SLA 20 Appendix 2
   * // Fund unit transactions
   */
  transactionId?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['Float']['output']>;
  valuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type DateQuote = {
  __typename?: 'DateQuote';
  date: Scalars['GraphQLDateString']['output'];
  type: QuoteTypeEnum;
  value: Scalars['Float']['output'];
};

export type DateValue = {
  __typename?: 'DateValue';
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum DayAdjustmentEnum {
  FixedPeriods = 'FixedPeriods',
  Following = 'Following',
  ModifiedFollowing = 'ModifiedFollowing'
}

export type DefaultCashInstrument = {
  __typename?: 'DefaultCashInstrument';
  currency: CurrencyEnum;
  partyInstrument?: Maybe<Instrument>;
  partyInstrumentId: Scalars['GraphQLObjectId']['output'];
};

export type DefaultCashInstrumentInput = {
  currency?: InputMaybe<CurrencyEnum>;
  partyInstrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum DocumentStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum EngagementMethod {
  Dialog = 'Dialog',
  Voting = 'Voting'
}

export enum EsgActivityEnum {
  ApprovedScreen = 'ApprovedScreen',
  Dialogue = 'Dialogue',
  Divestment = 'Divestment',
  Investment = 'Investment',
  Other = 'Other',
  OtherActivity = 'OtherActivity',
  ProxyVoting = 'ProxyVoting',
  RatingEvent = 'RatingEvent',
  ShareholderMeeting = 'ShareholderMeeting',
  Voting = 'Voting'
}

export enum EsgAreaEnum {
  BusinessEthics = 'BusinessEthics',
  Climate = 'Climate',
  Environment = 'Environment',
  HumanRights = 'HumanRights',
  LabourRights = 'LabourRights',
  Other = 'Other',
  Ratings = 'Ratings',
  Social = 'Social'
}

export type EsgComment = {
  __typename?: 'EsgComment';
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
};

export type EsgCommentInput = {
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
};

export enum EsgComponentEnum {
  CdsIndexEsgScreenings = 'CdsIndexEsgScreenings',
  EsgCategory = 'EsgCategory',
  GlobalStandardScreen = 'GlobalStandardScreen',
  KeySector = 'KeySector',
  ProductInvolvement = 'ProductInvolvement',
  ProductInvolvementRanges = 'ProductInvolvementRanges',
  ScreeningInformation = 'ScreeningInformation',
  SpoProviders = 'SpoProviders',
  SustainableDevelopmentGoals = 'SustainableDevelopmentGoals',
  TransitionCompanies = 'TransitionCompanies',
  UseOfProceeds = 'UseOfProceeds'
}

export enum EuTaxonomyEnvironmentalObjective {
  /** Climate change adaptation */
  ClimateChangeAdaption = 'ClimateChangeAdaption',
  /** Climate change mitigation */
  ClimateChangeMitigation = 'ClimateChangeMitigation',
  /** Pollution prevention and control */
  PollutionPreventionAndControl = 'PollutionPreventionAndControl',
  /** Protection and restoration of biodiversity and ecosystems */
  ProtectionAndRestorationBioDiversityAndEcosystems = 'ProtectionAndRestorationBioDiversityAndEcosystems',
  /** Sustainable use and protection of water and marine resources */
  SustainableUseAndProtectionWaterAndMarineResources = 'SustainableUseAndProtectionWaterAndMarineResources',
  /** Transition to a circular economy */
  TransitionToCircularEconomy = 'TransitionToCircularEconomy'
}

export type EventInfo = {
  __typename?: 'EventInfo';
  event: Scalars['String']['output'];
  name: CalendarNameEnum;
  state: CalendarStateEnum;
};

export type ExposureCollateral = {
  __typename?: 'ExposureCollateral';
  _id: Scalars['GraphQLObjectId']['output'];
  agreementType: AgreementType;
  /** Exposure/Collateral (agreement currency), from sources perspective. */
  amount: Scalars['Float']['output'];
  client: Party;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  /** Exposure/Collateral agreement currency */
  currency: CurrencyEnum;
  /** Can be used to find client, we store this number on externalAccount */
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  /** Valuation date. */
  date: Scalars['GraphQLDateString']['output'];
  /** The reference used by the source. */
  externalId: Scalars['String']['output'];
  haircut?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  /** Can be either RED code or instrumentId */
  instrumentAlias?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['ISIN']['output']>;
  /** Required for type CollateralCash/CollateralSecurity */
  marginType?: Maybe<MarginType>;
  /** Exposure/Collateral quantity, from sources perspective. */
  quantity: Scalars['Float']['output'];
  /** database id of original document. Only used internally */
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  type: ExposureCollateralType;
  uniqueTransactionIdentifier?: Maybe<Scalars['String']['output']>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type ExposureCollateralInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  agreementType: AgreementType;
  /** Exposure (local), from sources perspective. */
  amount: Scalars['Float']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  /** Exposure/Collateral agreement currency */
  currency: CurrencyEnum;
  /** Can be used to find client, we store this number on externalAccount */
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** Valuation date. */
  date: Scalars['GraphQLDateString']['input'];
  /** The reference used by the source. */
  externalId: Scalars['String']['input'];
  /** Only for initial margin collateral securities */
  haircut?: InputMaybe<Scalars['Float']['input']>;
  /** Can be either RED code or instrumentId */
  instrumentAlias?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  /** Required for type CollateralCash/CollateralSecurity */
  marginType?: InputMaybe<MarginType>;
  /** Exposure/Collateral quantity, from sources perspective. */
  quantity: Scalars['Float']['input'];
  /** database id of original document. Only used internally */
  reportId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  sourceId: Scalars['GraphQLObjectId']['input'];
  type: ExposureCollateralType;
  uniqueTransactionIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export enum ExposureCollateralType {
  CollateralCash = 'CollateralCash',
  CollateralSecurity = 'CollateralSecurity',
  Exposure = 'Exposure'
}

export type Extension = {
  __typename?: 'Extension';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  collection: CollectionNameEnum;
  /** Markdown comment */
  comment: Scalars['String']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  documentId: Scalars['GraphQLObjectId']['output'];
  key: Scalars['String']['output'];
  status: DocumentStatusEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
  value: Scalars['String']['output'];
  versions: Scalars['GraphQLJSON']['output'];
};

export type ExtensionInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  collection: CollectionNameEnum;
  /** Markdown comment */
  comment: Scalars['String']['input'];
  documentId: Scalars['GraphQLObjectId']['input'];
  key: Scalars['String']['input'];
  status: DocumentStatusEnum;
  value: Scalars['String']['input'];
};

export type ExtensionsFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  documentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export enum FeeInterestItemType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  CustodyFee = 'CustodyFee',
  Fee = 'Fee',
  ForeignTax = 'ForeignTax',
  Interest = 'Interest',
  /** ManagementCost is an type used for costs due to management of funds, e.g. Bloomberg license. */
  ManagementCost = 'ManagementCost',
  ManagementFee = 'ManagementFee',
  Rebate = 'Rebate',
  TaxRestitution = 'TaxRestitution'
}

export type FieldDataItem = {
  __typename?: 'FieldDataItem';
  field?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type FilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};

export type FredObservation = {
  __typename?: 'FredObservation';
  date?: Maybe<Scalars['Date']['output']>;
  realtime_end?: Maybe<Scalars['Date']['output']>;
  realtime_start?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FredSeries = {
  __typename?: 'FredSeries';
  frequency?: Maybe<Scalars['String']['output']>;
  frequency_short?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_updated?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  observation_end?: Maybe<Scalars['Date']['output']>;
  observation_start?: Maybe<Scalars['Date']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
  realtime_end?: Maybe<Scalars['Date']['output']>;
  realtime_start?: Maybe<Scalars['Date']['output']>;
  seasonal_adjustment?: Maybe<Scalars['String']['output']>;
  seasonal_adjustment_short?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  units_short?: Maybe<Scalars['String']['output']>;
};

export enum FundReportComponentEnum {
  AttributionMonth = 'AttributionMonth',
  AtttributionTotal = 'AtttributionTotal',
  BarReturnsMonth = 'BarReturnsMonth',
  BarReturnsYearly = 'BarReturnsYearly',
  CdsIndexComponents = 'CdsIndexComponents',
  CdsIndexEsgScreenings = 'CdsIndexEsgScreenings',
  CdsIndexHYComponents = 'CdsIndexHYComponents',
  CdsIndexIGComponents = 'CdsIndexIGComponents',
  CountryPieChart = 'CountryPieChart',
  CreditRating = 'CreditRating',
  CreditSpreadRisk = 'CreditSpreadRisk',
  CurrencyBarChart = 'CurrencyBarChart',
  CurrencyPieChart = 'CurrencyPieChart',
  EsgCategory = 'EsgCategory',
  InterestRateBuckets = 'InterestRateBuckets',
  KeyNumbers = 'KeyNumbers',
  ReturnVsVolatility = 'ReturnVsVolatility',
  Sectors = 'Sectors',
  SustainableDevelopmentGoals = 'SustainableDevelopmentGoals',
  TopHoldings = 'TopHoldings',
  TopIssuers = 'TopIssuers',
  TransitionCompanies = 'TransitionCompanies',
  UseOfProceeds = 'UseOfProceeds'
}

export enum FundReportEnum {
  Benchmark = 'Benchmark',
  Bonds = 'Bonds',
  Derivatives = 'Derivatives',
  Equities = 'Equities',
  Leverage = 'Leverage'
}

export enum FundTypeEnum {
  AIF = 'AIF',
  UCITS = 'UCITS'
}

export type FundUnit = {
  __typename?: 'FundUnit';
  instrument: Instrument;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  totalUnits?: Maybe<Scalars['Float']['output']>;
};

export type FundUnitReconciliationType = {
  __typename?: 'FundUnitReconciliationType';
  calculatedNbr?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  date: Scalars['GraphQLDateString']['output'];
  diff?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  party?: Maybe<PartyInfo>;
  partyId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  reportedNbr?: Maybe<Scalars['Float']['output']>;
  reporter?: Maybe<PartyInfo>;
  reporterId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  shareRegisterDiff?: Maybe<Scalars['Float']['output']>;
  shareRegisterNbr?: Maybe<Scalars['Float']['output']>;
  unitsAfterOrders?: Maybe<Scalars['String']['output']>;
};

export type FxValuation = {
  __typename?: 'FxValuation';
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type GlobalStandardScreen = {
  __typename?: 'GlobalStandardScreen';
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
  status: StandardScreenStatus;
};

export type GlobalStandardScreenInput = {
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
  status: StandardScreenStatus;
};

export type HistoricalData = {
  __typename?: 'HistoricalData';
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  eidDataArray?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  field?: Maybe<Scalars['String']['output']>;
  fieldExceptionsArray?: Maybe<Array<RefDataFieldExceptionsArray>>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  security?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  securityError?: Maybe<Array<RefDataError>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export enum HistoricalFieldsEnum {
  PX_LAST = 'PX_LAST',
  PX_VOLUME = 'PX_VOLUME'
}

export type ImportBondTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCloseDerivativeTradeInput = {
  amount: Scalars['Float']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalId: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCollateralInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType: AgreementType;
  /** Counterparty - Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** To determine if variation margin/initial margin collateral */
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** To determine if loan -> itemType SecurityLoan or collateral -> itemType CollateralPledge */
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Collateral quantity */
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCouponInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType?: InputMaybe<AgreementType>;
  amount: Scalars['Float']['input'];
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportCreateRedeemInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accountSwitchOrder?: InputMaybe<Scalars['Boolean']['input']>;
  accountingAmount: Scalars['Float']['input'];
  adjustmentAmount?: InputMaybe<Scalars['Float']['input']>;
  adjustmentShares?: InputMaybe<Scalars['Float']['input']>;
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['GraphQLDateString']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportDerivativeTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType: AgreementType;
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** Name/LEI/_id(only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingBrokerAlias?: InputMaybe<Scalars['String']['input']>;
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingHouseAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Float']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportDividendInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  /** Default: False */
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  externalId: Scalars['String']['input'];
  /** ForeignTax amount */
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** Default: False */
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFeeInterestInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType?: InputMaybe<AgreementType>;
  amount: Scalars['Float']['input'];
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  itemType: FeeInterestItemType;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFundTradeInput = {
  /** Name or _id, will use default if not stated */
  accountAlias?: InputMaybe<Scalars['String']['input']>;
  /** Need to state amount + quantity or quantity + price */
  amount?: InputMaybe<Scalars['Float']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  /** Name or _id, will use default security external account if not stated */
  externalAccountAlias?: InputMaybe<Scalars['String']['input']>;
  /** If set, settle in accounting currency */
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** Need to state amount + quantity or quantity + price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Need to state amount + quantity or quantity + price */
  quantity: Scalars['Float']['input'];
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportFxSpotInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** If related to fxSwap, should be set to the fxSwap derivativeTrade */
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** Name or _id, if not stated, will be set to accountAlias or default on party instrument */
  secondAccountAlias?: InputMaybe<Scalars['String']['input']>;
  secondAmount: Scalars['Float']['input'];
  secondCurrency: CurrencyEnum;
  source: SourceType;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertCashInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertDerivativeTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  agreementType: AgreementType;
  /** Name/LEI/_id */
  brokerAlias: Scalars['String']['input'];
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingBrokerAlias?: InputMaybe<Scalars['String']['input']>;
  /** Name/LEI/_id (only necessary for agreementType: ClearingAccount and OmnibusClearingAccount) */
  clearingHouseAlias?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  uniqueTradeId: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportInsertSecurityInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  /** Only needed when security and quantity = 0, price = 0 and amount != 0 */
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportStockTradeInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  source: SourceType;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type ImportTransferInput = {
  /** Name or _id */
  accountAlias: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bestExecutionType: BestExecutionType;
  /** Name/LEI/_id */
  brokerAlias?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Name/ISIN/BloombergTicker/_id */
  instrumentAlias: Scalars['String']['input'];
  /** Name or _id, if not stated, will be set to accountAlias or default on party instrument */
  secondAccountAlias?: InputMaybe<Scalars['String']['input']>;
  secondAmount: Scalars['Float']['input'];
  secondCurrency: CurrencyEnum;
  /** Name/ISIN/BloombergTicker/_id */
  secondInstrumentAlias: Scalars['String']['input'];
  status?: InputMaybe<TransactionStatus>;
  /** String on format YYYY-MM-DD or timeStamp */
  tradeTimestamp: Scalars['DateStringOrTimestamp']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type Instrument = {
  __typename?: 'Instrument';
  _id: Scalars['GraphQLObjectId']['output'];
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  /**
   * Accrued interest using only model cash-flows and thus much quicker than
   * bootstrapping all curves needed for risk parameters above
   */
  accruedInterestOnly?: Maybe<Scalars['Float']['output']>;
  aliases: Array<Alias>;
  benchmark?: Maybe<Instrument>;
  benchmarkId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  bic: Scalars['String']['output'];
  bloombergDailyDownload: Scalars['Boolean']['output'];
  bloombergQuoteMultiplier: Scalars['Float']['output'];
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  category?: Maybe<InstrumentCategory>;
  /** cic is computed from cicArea and cicType, or guessed */
  cic?: Maybe<Scalars['String']['output']>;
  cicArea?: Maybe<CicAreaEnum>;
  cicType?: Maybe<CicTypeEnum>;
  cleanPrice?: Maybe<Scalars['Float']['output']>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<Party>;
  /** Calculated country of listing */
  countryOfListing?: Maybe<Scalars['String']['output']>;
  couponFrequency?: Maybe<Scalars['Int']['output']>;
  couponRate?: Maybe<Scalars['Float']['output']>;
  creditQualityStep?: Maybe<Scalars['Int']['output']>;
  /** Calculated credit ratings */
  creditRating?: Maybe<Scalars['String']['output']>;
  creditRatings?: Maybe<Array<PartyCreditRating>>;
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  exchange?: Maybe<Scalars['String']['output']>;
  extensions: Array<Extension>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  /** First trade date if traded */
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** First external account id if traded */
  firstTradeExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** First transaction id if traded */
  firstTradeTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['GraphQLObjectId']['output'];
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<Party>;
  issuerId: Scalars['GraphQLObjectId']['output'];
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId: Scalars['GraphQLObjectId']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  /** Always true after first margin call transaction has been created */
  marginCallsMade?: Maybe<Scalars['Boolean']['output']>;
  maturityDate?: Maybe<Scalars['String']['output']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: Maybe<Scalars['String']['output']>;
  model?: Maybe<InstrumentModel>;
  /** For derivative models where we trade one unit and notional is on model, modelNotionalScaling can be used to match counterparty notional */
  modelNotionalScaling?: Maybe<Scalars['Float']['output']>;
  /** Calculated fields from model */
  modelType?: Maybe<InstrumentModelTypeEnum>;
  name?: Maybe<Scalars['String']['output']>;
  placeOfSettlementId: Scalars['GraphQLObjectId']['output'];
  /**
   * Swaplab2 calculated properties. If no date given default to yesterday.
   * Not optimal but for now. Should be last business day but hard without
   * calendars
   */
  presentValue?: Maybe<Scalars['Float']['output']>;
  productType?: Maybe<InstrumentProductTypeEnum>;
  /** Last available time series value */
  pxLast?: Maybe<DateValue>;
  quantityDecimals?: Maybe<Scalars['Int']['output']>;
  /** Quote at date - null if no quote found for that date */
  quote?: Maybe<DateQuote>;
  /** Used as issueDate */
  startDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** If exchanged is asked for, only exchanged is returned */
  timeSeries?: Maybe<Array<TimeSeries>>;
  type: InstrumentTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valuations?: Maybe<InstrumentValuation>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
  zeroSpread?: Maybe<Scalars['Float']['output']>;
};


export type InstrumentAccruedInterestArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentAccruedInterestOnlyArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentCleanPriceArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentCreditYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentFirstTradeDateArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentFirstTradeExternalAccountIdArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentFirstTradeTransactionIdArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type InstrumentInterestRateYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentPresentValueArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentPxLastArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentQuoteArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentTimeSeriesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  exchanged?: InputMaybe<CurrencyEnum>;
  fxHedged?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type InstrumentValuationsArgs = {
  currency?: InputMaybe<CurrencyEnum>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type InstrumentZeroSpreadArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type InstrumentAccountPosition = {
  __typename?: 'InstrumentAccountPosition';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  currency: CurrencyEnum;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  quantity: Scalars['Float']['output'];
};

export enum InstrumentCategory {
  Alternative = 'Alternative',
  CashAccount = 'CashAccount',
  Equity = 'Equity',
  Fx = 'Fx',
  HedgeFund = 'HedgeFund',
  Interest = 'Interest',
  InterestRate = 'InterestRate',
  Liability = 'Liability',
  Margin = 'Margin',
  None = 'None',
  RealEstate = 'RealEstate',
  System = 'System'
}

export type InstrumentFilterInput = {
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergTickerIn?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryIn?: InputMaybe<Array<InstrumentCategory>>;
  categoryNotIn?: InputMaybe<Array<InstrumentCategory>>;
  currencyIn?: InputMaybe<Array<CurrencyEnum>>;
  currencyNotIn?: InputMaybe<Array<CurrencyEnum>>;
  exchangeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  exchangeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  isinNotIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  issuerIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerProgramIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  modelTypeIn?: InputMaybe<Array<InstrumentModelTypeEnum>>;
  modelTypeNotIn?: InputMaybe<Array<InstrumentModelTypeEnum>>;
  productTypeIn?: InputMaybe<Array<InstrumentProductTypeEnum>>;
  productTypeNotIn?: InputMaybe<Array<InstrumentProductTypeEnum>>;
  statusIn?: InputMaybe<Array<InstrumentStatusEnum>>;
  typeIn?: InputMaybe<Array<InstrumentTypeEnum>>;
  updatedAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBefore?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type InstrumentModel = {
  __typename?: 'InstrumentModel';
  _t: InstrumentModelTypeEnum;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  expiryDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  legs?: Maybe<Array<InstrumentModelLegsItem>>;
  notionalScaling?: Maybe<Scalars['Float']['output']>;
  objectId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  objectType?: Maybe<InstrumentModelObjectTypeEnum>;
  quoteCurrency: CurrencyEnum;
  quoteType: Scalars['String']['output'];
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  type: InstrumentModelTypeEnum;
};

export type InstrumentModelInput = {
  _t: InstrumentModelTypeEnum;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  expiryDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  legs?: InputMaybe<Array<InstrumentModelLegsItemInput>>;
  notionalScaling?: InputMaybe<Scalars['Float']['input']>;
  objectId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  objectType?: InputMaybe<InstrumentModelObjectTypeEnum>;
  quoteCurrency: CurrencyEnum;
  quoteType: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
};

export enum InstrumentModelLegFixingTypesEnum {
  Compound = 'Compound',
  Single = 'Single'
}

export enum InstrumentModelLegTypesEnum {
  CashFlowLeg = 'CashFlowLeg',
  EquityBasketOptionLeg = 'EquityBasketOptionLeg',
  EquityUnitOptionLeg = 'EquityUnitOptionLeg',
  FixedAmountLeg = 'FixedAmountLeg',
  FixedRateLeg = 'FixedRateLeg',
  FloatRateLeg = 'FloatRateLeg',
  FtaLeg = 'FtaLeg',
  GenericFixedAmountLeg = 'GenericFixedAmountLeg',
  GenericFixedRateLeg = 'GenericFixedRateLeg',
  GenericFloatRateLeg = 'GenericFloatRateLeg',
  SwaptionLeg = 'SwaptionLeg'
}

export type InstrumentModelLegsItem = {
  __typename?: 'InstrumentModelLegsItem';
  _t: InstrumentModelLegTypesEnum;
  calendars: Array<Scalars['String']['output']>;
  call?: Maybe<Scalars['Boolean']['output']>;
  cashFlows?: Maybe<Array<InstrumentModelLegsItemCashFlowsItem>>;
  creditSpread?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  dayAdjustment: DayAdjustmentEnum;
  dayCount?: Maybe<Scalars['String']['output']>;
  exercise?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  fixingType?: Maybe<Scalars['String']['output']>;
  flatAdjustment?: Maybe<Scalars['Float']['output']>;
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  longStub?: Maybe<Scalars['Boolean']['output']>;
  maturityDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  maturityPeriod?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Int']['output']>;
  payLeg: Scalars['Boolean']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  rateIndexId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  recoveryRate?: Maybe<Scalars['Float']['output']>;
  relativeAdjustment?: Maybe<Scalars['Float']['output']>;
  rollingDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  rollingPeriod?: Maybe<Scalars['String']['output']>;
  settlementType?: Maybe<Scalars['String']['output']>;
  spread?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  startDateOffset?: Maybe<Scalars['Int']['output']>;
  startPeriod?: Maybe<Scalars['String']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  type: InstrumentModelLegTypesEnum;
  ultimateForwardRate?: Maybe<Scalars['Float']['output']>;
  underlyingId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  underlyingInstrument?: Maybe<Instrument>;
  underlyings?: Maybe<Array<InstrumentModelLegsItemUnderlyingsItem>>;
  volatility?: Maybe<Scalars['Float']['output']>;
  zeroFloor?: Maybe<Scalars['Boolean']['output']>;
};

export type InstrumentModelLegsItemCashFlowsItem = {
  __typename?: 'InstrumentModelLegsItemCashFlowsItem';
  endDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
  exDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  fixings?: Maybe<Array<InstrumentModelLegsItemCashFlowsItemFixingsItem>>;
  notional: Scalars['Float']['output'];
  payDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  startDate?: Maybe<Scalars['GraphQLPatchInstrumentDate']['output']>;
};

export type InstrumentModelLegsItemCashFlowsItemFixingsItem = {
  __typename?: 'InstrumentModelLegsItemCashFlowsItemFixingsItem';
  date: Scalars['GraphQLPatchInstrumentDate']['output'];
  endDate: Scalars['GraphQLPatchInstrumentDate']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  startDate: Scalars['GraphQLPatchInstrumentDate']['output'];
};

export type InstrumentModelLegsItemCashFlowsItemFixingsItemInput = {
  date: Scalars['GraphQLPatchInstrumentDate']['input'];
  endDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['GraphQLPatchInstrumentDate']['input'];
};

export type InstrumentModelLegsItemCashFlowsItemInput = {
  endDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  exDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  fixings?: InputMaybe<Array<InstrumentModelLegsItemCashFlowsItemFixingsItemInput>>;
  notional: Scalars['Float']['input'];
  payDate: Scalars['GraphQLPatchInstrumentDate']['input'];
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
};

export type InstrumentModelLegsItemInput = {
  _t: InstrumentModelLegTypesEnum;
  calendars: Array<Scalars['String']['input']>;
  call?: InputMaybe<Scalars['Boolean']['input']>;
  cashFlows?: InputMaybe<Array<InstrumentModelLegsItemCashFlowsItemInput>>;
  creditSpread?: InputMaybe<Scalars['Float']['input']>;
  currency: CurrencyEnum;
  dayAdjustment: DayAdjustmentEnum;
  dayCount?: InputMaybe<Scalars['String']['input']>;
  exercise?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  fixingType?: InputMaybe<Scalars['String']['input']>;
  flatAdjustment?: InputMaybe<Scalars['Float']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longStub?: InputMaybe<Scalars['Boolean']['input']>;
  maturityDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  maturityPeriod?: InputMaybe<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  notional?: InputMaybe<Scalars['Int']['input']>;
  payLeg: Scalars['Boolean']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateIndexId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  recoveryRate?: InputMaybe<Scalars['Float']['input']>;
  relativeAdjustment?: InputMaybe<Scalars['Float']['input']>;
  rollingDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  rollingPeriod?: InputMaybe<Scalars['String']['input']>;
  settlementType?: InputMaybe<Scalars['String']['input']>;
  spread?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLPatchInstrumentDate']['input']>;
  startDateOffset?: InputMaybe<Scalars['Int']['input']>;
  startPeriod?: InputMaybe<Scalars['String']['input']>;
  strike?: InputMaybe<Scalars['Float']['input']>;
  ultimateForwardRate?: InputMaybe<Scalars['Float']['input']>;
  underlyingId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  underlyings?: InputMaybe<Array<InstrumentModelLegsItemUnderlyingsItemInput>>;
  volatility?: InputMaybe<Scalars['Float']['input']>;
  zeroFloor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InstrumentModelLegsItemUnderlyingsItem = {
  __typename?: 'InstrumentModelLegsItemUnderlyingsItem';
  instrumentId: Scalars['String']['output'];
  quanto: Scalars['Boolean']['output'];
  startPrice: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

export type InstrumentModelLegsItemUnderlyingsItemInput = {
  instrumentId: Scalars['String']['input'];
  quanto: Scalars['Boolean']['input'];
  startPrice: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum InstrumentModelObjectTypeEnum {
  Account = 'Account',
  Client = 'Client',
  Instrument = 'Instrument',
  Portfolio = 'Portfolio'
}

export enum InstrumentModelTypeEnum {
  /** Balance model is used for bank accounts and such, a placeholder for a currency position. */
  Balance = 'Balance',
  /**
   * Bond model is used for fixed coupon bonds, floating rate bonds and zero coupon bonds.
   * The model needs one fixed amount leg for principal and one coupon leg for coupons.
   * For zero coupon bonds, use fixed rate leg type and 0 coupon.
   */
  Bond = 'Bond',
  /**
   * DO NOT USE! CashFlowEngineering is not implemented.
   * @deprecated DO NOT USE! CashFlowEngineering is not implemented.
   */
  CashFlowEngineering = 'CashFlowEngineering',
  /**
   * CdsBasket model is used for valuation of a custom CDS Basket traded as a warrant, i.e.
   * as a transferable security.
   */
  CdsBasket = 'CdsBasket',
  /** CdsIndex model is used for standard CDS index instruments cleared at a CCP. */
  CdsIndex = 'CdsIndex',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityBasketOption model is used for valuations of
   * equity basket options. Volatility surfaces are stored in system.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  EquityBasketOption = 'EquityBasketOption',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityUnitOption model is used for valuations of
   * single underlying equity options. Volatility surfaces are stored in system.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  EquityUnitOption = 'EquityUnitOption',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Fta model is used for liability valuation.
   * @deprecated PortfolioSwap is deprecated, use PortfolioSwapX instead
   */
  Fta = 'Fta',
  /**
   * FundExternal model is used for fund instruments not connected to their own portfolio within the system.
   * The valuation has to be provided from external source.
   */
  FundExternal = 'FundExternal',
  /**
   * FundInternal model is used for fund instruments linked to their own portfolio within the system. The issuerId field
   * serves as a link to the portfolio and can be used for more advanced calculations.
   */
  FundInternal = 'FundInternal',
  /**
   * FXSwap model is used for the outright leg of an FX Swap position. Model uses a set of FixedAmountLegs
   * in different currencies.
   */
  FxSwap = 'FxSwap',
  /**
   * GenericDeposit model is used for generic deposit instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericDeposit = 'GenericDeposit',
  /**
   * GenericFxSpot model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericFxSpot = 'GenericFxSpot',
  /**
   * GenericFxSwap model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericFxSwap = 'GenericFxSwap',
  /**
   * GenericSwap model is used for generic swap instruments used in curve building.
   * This instrument type should never be connected to a traded position.
   */
  GenericSwap = 'GenericSwap',
  /**
   * SHOULD NEVER BE SET MANUALLY! Pending model is set for trades uploaded where no instrument exists in system. A fund can not be valued
   * while an instrument with model type Pending exists in the underlying portfolio.
   */
  Pending = 'Pending',
  /**
   * DEPRECATED! PortfolioSwap model is the OLD Portfolio Swap model based on keeping positions
   * using swap counterparty as owner of portfolio in system.
   * @deprecated PortfolioSwap is deprecated, use PortfolioSwapX instead
   */
  PortfolioSwap = 'PortfolioSwap',
  /** PortfolioSwapX model is the new Portfolio Swap model. */
  PortfolioSwapX = 'PortfolioSwapX',
  /**
   * RealEstate model is a spot valuation model which differentiates Real Estate
   * risk factor instruments from other spot valuation models.
   */
  RealEstate = 'RealEstate',
  /**
   * DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Spot model is a generic spot model, please use Stock or RealEstate models instead.
   * @deprecated DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2!
   */
  Spot = 'Spot',
  /**
   * Stock model is a spot valuation model used for all normal equity like instruments, including stock, dividend rights and such.
   * All valuations are done T+0 i.e. no T+2 discounting.
   */
  Stock = 'Stock',
  /** Swap model is used for interest rate swaps. Currently the model does not support OIS-swaps. */
  Swap = 'Swap',
  /**
   * Swaption model is used for valuation of swaptions. Using the call/put and pay/recieve properties
   * of the SwaptionLeg gives call: false = Payer Swaption, payleg: false = sold (short) position.
   */
  Swaption = 'Swaption',
  /**
   * ZeroCouponLinear model is used as an amortizing model for not marked valued corporate certificates
   * and other not market valued zero coupon structures. Valuation is a linear amortizing model towards par.
   */
  ZeroCouponLinear = 'ZeroCouponLinear'
}

export type InstrumentPerformance = {
  __typename?: 'InstrumentPerformance';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  localCurrency?: Maybe<CurrencyEnum>;
  positions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type InstrumentPerformance2 = {
  __typename?: 'InstrumentPerformance2';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  fx?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  positions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  prices?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  totalReturns?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type InstrumentPosition = {
  __typename?: 'InstrumentPosition';
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients?: Maybe<Array<Maybe<Party>>>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export enum InstrumentPositionType {
  All = 'All',
  CashAccounts = 'CashAccounts',
  Securities = 'Securities'
}

export enum InstrumentProductTypeEnum {
  Account = 'Account',
  /** For PayableReceivable transactions just a balance since no cash involved */
  BalanceAccount = 'BalanceAccount',
  Bond = 'Bond',
  Cash = 'Cash',
  CashAccount = 'CashAccount',
  Fund = 'Fund',
  FutureForward = 'FutureForward',
  FxPair = 'FxPair',
  Index = 'Index',
  InitialMarginAccount = 'InitialMarginAccount',
  Liability = 'Liability',
  None = 'None',
  Option = 'Option',
  PrincipalAccount = 'PrincipalAccount',
  RealEstate = 'RealEstate',
  Shares = 'Shares',
  Swap = 'Swap',
  System = 'System',
  VariationMarginAccount = 'VariationMarginAccount'
}

export type InstrumentSelector = {
  __typename?: 'InstrumentSelector';
  alias?: Maybe<Scalars['String']['output']>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
};

export type InstrumentSelectorInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
};

export enum InstrumentStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending'
}

export enum InstrumentTypeEnum {
  Instrument = 'Instrument',
  PartyInstrument = 'PartyInstrument'
}

export type InstrumentValuation = {
  __typename?: 'InstrumentValuation';
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  cleanPrice?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export enum IorpEnum {
  None = 'None',
  RiskFree = 'RiskFree',
  SOBL = 'SOBL'
}

export type IssuerProgram = {
  __typename?: 'IssuerProgram';
  _id: Scalars['GraphQLObjectId']['output'];
  attachments: Array<Attachment>;
  category?: Maybe<IssuerProgramCategory>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  covered: TrueFalseNone;
  createTimestamp: Scalars['Timestamp']['output'];
  creditStatus: CreditStatusType;
  crossDefault: TrueFalseNone;
  euTaxonomyEnvironmentalObjectives: Array<EuTaxonomyEnvironmentalObjective>;
  guarantor?: Maybe<Party>;
  guarantorId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instruments: Array<Instrument>;
  issuer?: Maybe<Party>;
  issuerId: Scalars['GraphQLObjectId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  negativePledge: TrueFalseNone;
  /** A list of screens for program */
  screens?: Maybe<Array<Maybe<Screen>>>;
  spoProvider?: Maybe<Party>;
  spoProviderId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status?: Maybe<DocumentStatusEnum>;
  sustainableDevelopmentGoals?: Maybe<Array<SustainableDevelopmentGoal>>;
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  useOfProceeds?: Maybe<Array<UseOfProceed>>;
  /** A list of versions of the document */
  versions?: Maybe<Array<Maybe<Scalars['GraphQLJSON']['output']>>>;
};

export enum IssuerProgramCategory {
  Green = 'Green',
  None = 'None',
  Social = 'Social',
  SustainabilityLinked = 'SustainabilityLinked',
  Sustainable = 'Sustainable',
  Transition = 'Transition'
}

export type IssuerProgramInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  category?: InputMaybe<IssuerProgramCategory>;
  clientId: Scalars['GraphQLObjectId']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  covered?: InputMaybe<TrueFalseNone>;
  creditRatings?: InputMaybe<Array<CreditRatingInput>>;
  creditStatus?: InputMaybe<CreditStatus>;
  crossDefault?: InputMaybe<TrueFalseNone>;
  euTaxonomyEnvironmentalObjectives?: InputMaybe<Array<EuTaxonomyEnvironmentalObjective>>;
  guarantorId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  negativePledge?: InputMaybe<TrueFalseNone>;
  spoProviderId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<DocumentStatusEnum>;
  sustainableDevelopmentGoals?: InputMaybe<Array<SustainableDevelopmentGoal>>;
  useOfProceeds?: InputMaybe<Array<UseOfProceed>>;
};

export type IssuerProgramsFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};

export enum IssuerTypeEnum {
  CreditInstitution = 'CreditInstitution',
  EuCovered = 'EuCovered',
  Government = 'Government',
  None = 'None'
}

export type Job = {
  __typename?: 'Job';
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<JobStateEnum>;
  lastExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  lastStatusCode?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nextExecutionTime?: Maybe<Scalars['Timestamp']['output']>;
  resultUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum JobResultEnum {
  Enqueued = 'Enqueued',
  Failure = 'Failure',
  Processing = 'Processing',
  Running = 'Running',
  Succeeded = 'Succeeded'
}

export enum JobStateEnum {
  disabled = 'disabled',
  enabled = 'enabled'
}

export type JournalEntry = {
  __typename?: 'JournalEntry';
  _id: Scalars['GraphQLObjectId']['output'];
  accountingRunId: Scalars['GraphQLObjectId']['output'];
  batch?: Maybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['output'];
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** External verification number */
  externalNumber?: Maybe<Scalars['Int']['output']>;
  /** Batch specific verification number */
  number?: Maybe<Scalars['Int']['output']>;
  portfolioTransaction?: Maybe<Transaction>;
  portfolioTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  transactions?: Maybe<Array<AccountingTransaction>>;
};

export type JournalEntryInput = {
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  createTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  createUserId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type LimitData = {
  __typename?: 'LimitData';
  /** // Risk data */
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  custodian?: Maybe<Scalars['String']['output']>;
  /** // Exposure */
  exposure?: Maybe<Scalars['Float']['output']>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<PartyExternalAccountType>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrumentLongName?: Maybe<Scalars['String']['output']>;
  instrumentName?: Maybe<Scalars['String']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  /** // Eligible asset categories under UCITS */
  isAncillaryLiquidAsset?: Maybe<Scalars['Boolean']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  /** // Position type */
  isCollateral?: Maybe<Scalars['Boolean']['output']>;
  isCollateralInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  isCreditInstitution?: Maybe<Scalars['Boolean']['output']>;
  isDeposit?: Maybe<Scalars['Boolean']['output']>;
  isDerivative?: Maybe<Scalars['Boolean']['output']>;
  isEquity?: Maybe<Scalars['Boolean']['output']>;
  isEuCovered?: Maybe<Scalars['Boolean']['output']>;
  isFinancialIndex?: Maybe<Scalars['Boolean']['output']>;
  isFund?: Maybe<Scalars['Boolean']['output']>;
  isGovernment?: Maybe<Scalars['Boolean']['output']>;
  isGreenBond?: Maybe<Scalars['Boolean']['output']>;
  isInterestBearing?: Maybe<Scalars['Boolean']['output']>;
  isLookThrough?: Maybe<Scalars['Boolean']['output']>;
  isMoneyMarket?: Maybe<Scalars['Boolean']['output']>;
  isNonGuaranteed?: Maybe<Scalars['Boolean']['output']>;
  isNonVoting?: Maybe<Scalars['Boolean']['output']>;
  isOtc?: Maybe<Scalars['Boolean']['output']>;
  isSecurity?: Maybe<Scalars['Boolean']['output']>;
  isTransferableSecurity?: Maybe<Scalars['Boolean']['output']>;
  isTsmmEmbeddedDerivative?: Maybe<Scalars['Boolean']['output']>;
  isUcitsFund?: Maybe<Scalars['Boolean']['output']>;
  /** // Instrument properties */
  isUnlisted?: Maybe<Scalars['Boolean']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  /** // Issuer data */
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  localExposure?: Maybe<Scalars['Float']['output']>;
  localValue?: Maybe<Scalars['Float']['output']>;
  parentIssuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  portfolioCurrency?: Maybe<Scalars['String']['output']>;
  /** // Meta data - For visibility */
  portfolioName?: Maybe<Scalars['String']['output']>;
  positionType?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  separatedInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type LimitedUserFilterInput = {
  roleAsset?: InputMaybe<PermissionAssetEnum>;
  status?: InputMaybe<UserStatusEnum>;
};

export enum MappingCollectionNameEnum {
  CurveDefinition = 'CurveDefinition'
}

export enum MappingParameterEnum {
  CreditCurve = 'CreditCurve',
  DiscountCurve = 'DiscountCurve',
  FixingCurve = 'FixingCurve',
  ForwardCurve = 'ForwardCurve',
  FxCurve = 'FxCurve'
}

export enum MappingTypeEnum {
  Currency = 'Currency',
  Instrument = 'Instrument',
  IssuerProgram = 'IssuerProgram',
  Model = 'Model'
}

export type MarginRequirement = {
  __typename?: 'MarginRequirement';
  _id: Scalars['GraphQLObjectId']['output'];
  agreementType: AgreementType;
  amount: Scalars['Float']['output'];
  client: Party;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['output'];
  externalId: Scalars['String']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  type: MarginRequirementType;
  updateTimestamp: Scalars['Timestamp']['output'];
};

export enum MarginRequirementType {
  InitialMarginRequirement = 'InitialMarginRequirement',
  VariationMarginRequirement = 'VariationMarginRequirement'
}

export enum MarginType {
  InitialMargin = 'InitialMargin',
  VariationMargin = 'VariationMargin'
}

export type MatchBankAccountTransactionsInput = {
  bankAccountTransactionError?: InputMaybe<Scalars['String']['input']>;
  bankAccountTransactionIds: Array<Scalars['GraphQLObjectId']['input']>;
  matchingStatus: MatchingStatus;
  transactionIdentifierIds: Array<TransactionIdentifier>;
  transactionItemError?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCoacsTransactionsInput = {
  corporateActionError?: InputMaybe<Scalars['String']['input']>;
  corporateActionExternalId?: InputMaybe<Scalars['String']['input']>;
  corporateActionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  matchingStatus?: InputMaybe<MatchingCoacsStatus>;
  transactionError?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export type MatchTransactionsInput = {
  brokerExternalId?: InputMaybe<Scalars['String']['input']>;
  brokerTransactionError?: InputMaybe<Scalars['String']['input']>;
  brokerTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  matchingStatus?: InputMaybe<MatchingStatus>;
  transactionError?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};

export type MatchedBankAccountTransaction = {
  __typename?: 'MatchedBankAccountTransaction';
  bankAccountTransactionIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  bankAccountTransactions?: Maybe<Array<BankAccountTransaction>>;
  transactionItemIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  transactionItems?: Maybe<Array<TransactionItem>>;
};

export type MatchedCoacsTransaction = {
  __typename?: 'MatchedCoacsTransaction';
  corporateAction?: Maybe<CorporateAction>;
  corporateActionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  transaction?: Maybe<Transaction>;
  transactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
};

export type MatchedTransaction = {
  __typename?: 'MatchedTransaction';
  brokerTransaction?: Maybe<BrokerTransaction>;
  transaction?: Maybe<Transaction>;
};

export enum MatchingCoacsStatus {
  Confirmed = 'Confirmed',
  /** Matched with a transaction but found at least on error */
  Matched = 'Matched',
  Mismatch = 'Mismatch',
  Preliminary = 'Preliminary',
  /** Messages of type 566 gets status settled when matched */
  Settled = 'Settled'
}

export enum MatchingStatus {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  /** Matched with a transaction but found at least on error */
  Matched = 'Matched',
  Mismatch = 'Mismatch'
}

export type Me = {
  __typename?: 'Me';
  _id: Scalars['GraphQLObjectId']['output'];
  bidExpiration?: Maybe<Scalars['Int']['output']>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<PartyInfo>;
  company: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  frontendRole: Role;
  frontendRoleId: Scalars['GraphQLObjectId']['output'];
  lastBankIdLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastPasswordUpdate?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  noteIds: Array<Scalars['GraphQLObjectId']['output']>;
  notes: Array<Note>;
  personalNumber: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds: Array<Scalars['GraphQLObjectId']['output']>;
  roles?: Maybe<Array<Role>>;
  status: UserStatusEnum;
  /** Latest terms of service from notes collection */
  termsOfService?: Maybe<Note>;
  unreadTicketsCount?: Maybe<Scalars['Int']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type MiniTransaction = {
  __typename?: 'MiniTransaction';
  _id: Scalars['GraphQLObjectId']['output'];
  account?: Maybe<PartyAccount>;
  /** For partyInstruments with accountId set on the instrument, that accountId is used otherwise the accountId in input is used */
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For BondTrade */
  accruedInterest?: Maybe<Scalars['Float']['output']>;
  /** For DerivativeTrade/ Collateral */
  agreementType?: Maybe<AgreementType>;
  /**
   * Amount is used for all types except:
   * collateral, stockTrade, bondTrade, derivativeTrade
   */
  amount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  bestExecutionType?: Maybe<BestExecutionType>;
  /** For CreateRedeem/FundTrade */
  bonusShares?: Maybe<Scalars['Float']['output']>;
  broker?: Maybe<Party>;
  brokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  brokerTradeId?: Maybe<Scalars['String']['output']>;
  brokerTransaction?: Maybe<BrokerTransaction>;
  carryOwnCostDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  clearingBroker?: Maybe<Party>;
  /** For agreementTypes OmnibusClearingAccount and ClearingAccount */
  clearingBrokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clearingHouse?: Maybe<Party>;
  clearingHouseId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For StockTrade / BondTrade / FundTrade */
  commission?: Maybe<Scalars['Float']['output']>;
  corporateAction?: Maybe<CorporateAction>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  dividendPaid?: Maybe<Scalars['Boolean']['output']>;
  effectiveDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  /** For Dividend */
  foreignTax?: Maybe<Scalars['Float']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  /** For Collateral */
  initialMargin?: Maybe<Scalars['Boolean']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For Fee/Interest */
  itemType?: Maybe<FeeInterestItemType>;
  loan?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** Read only for now, related to portfolio swap (stockTrade) */
  payableReceivable?: Maybe<Scalars['Float']['output']>;
  /** For StockTrade / BondTrade / DerivativeTrade */
  price?: Maybe<Scalars['Float']['output']>;
  /** For StockTrade / BondTrade / DerivativeTrade/ Collateral */
  quantity?: Maybe<Scalars['Float']['output']>;
  reinvestedDividend?: Maybe<Scalars['Boolean']['output']>;
  reinvestedRebate?: Maybe<Scalars['Boolean']['output']>;
  /** Used for when counterparties round settlementAmount */
  rounding?: Maybe<Scalars['Float']['output']>;
  /** If not stated, will be set to accountId or default on party instrument */
  secondAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  secondAmount?: Maybe<Scalars['Float']['output']>;
  secondCurrency?: Maybe<Scalars['String']['output']>;
  secondExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** For Transfer */
  secondInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  /** SettlementAmount is used for all types except collateral and transfer */
  settlementAmount?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SourceType>;
  /** For StockTrade / BondTrade */
  stampDuty?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TransactionStatus>;
  taxRestitution?: Maybe<Scalars['Boolean']['output']>;
  tradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  tradeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  type?: Maybe<TransactionType>;
  uniqueTradeId?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

/**
 * ModelIndexBenchmark calculated within the Party.accounts computed property.
 * Uses introspection to decide calculation and parameter. Thus if type name
 * or parameters to timeSeries are altered this must be mirrored under
 * Party.accounts
 */
export type ModelIndexBenchmark = {
  __typename?: 'ModelIndexBenchmark';
  currency?: Maybe<CurrencyEnum>;
  name: Scalars['String']['output'];
  timeSeries?: Maybe<TimeSeries>;
};


/**
 * ModelIndexBenchmark calculated within the Party.accounts computed property.
 * Uses introspection to decide calculation and parameter. Thus if type name
 * or parameters to timeSeries are altered this must be mirrored under
 * Party.accounts
 */
export type ModelIndexBenchmarkTimeSeriesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type ModelIndexBenchmarkInput = {
  name: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTerms?: Maybe<User>;
  addTimeSerieItems?: Maybe<TimeSeries>;
  changePassword?: Maybe<UserInfo>;
  cleanPerformanceCache?: Maybe<Scalars['Boolean']['output']>;
  cloneTAccountChart?: Maybe<TAccountChart>;
  createAccountingRun?: Maybe<AccountingRun>;
  createAttachment?: Maybe<Attachment>;
  createCdsValuations?: Maybe<Array<Maybe<Valuation>>>;
  createCurveDefinition?: Maybe<CurveDefinition>;
  createExposureCollateral: ExposureCollateral;
  createInstrument?: Maybe<Instrument>;
  createIssuerProgram?: Maybe<IssuerProgram>;
  createJournalEntry?: Maybe<JournalEntry>;
  createManagementCostTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  createMiniTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  createNav?: Maybe<Nav>;
  createPartyAccount?: Maybe<Party>;
  createPartyBase?: Maybe<Party>;
  createReport?: Maybe<Report>;
  createRole?: Maybe<Role>;
  createScreen?: Maybe<Screen>;
  /** Creates swifts and corresponding corporateActions */
  createSwifts?: Maybe<Array<Swift>>;
  createTAccountChart?: Maybe<TAccountChart>;
  createTransaction?: Maybe<Transaction>;
  /** createUpdateExternalAccount also creates partyInstruments */
  createUpdateExternalAccount?: Maybe<PartyExternalAccount>;
  createUpdatePartyCashOrBalanceInstrument?: Maybe<Instrument>;
  createUser?: Maybe<User>;
  createValuation?: Maybe<Valuation>;
  databaseMigration?: Maybe<Scalars['Boolean']['output']>;
  defaultMutation?: Maybe<Scalars['String']['output']>;
  deleteAttachment?: Maybe<Scalars['Boolean']['output']>;
  deleteCurveDefinition?: Maybe<Scalars['Boolean']['output']>;
  deleteInstrument?: Maybe<Scalars['Boolean']['output']>;
  deleteIssuerProgram?: Maybe<Scalars['Boolean']['output']>;
  deleteJournalEntry?: Maybe<Scalars['Boolean']['output']>;
  deleteManagementCostTransactions?: Maybe<Scalars['Boolean']['output']>;
  deleteNav?: Maybe<Scalars['Boolean']['output']>;
  deleteParty?: Maybe<Scalars['Boolean']['output']>;
  deletePartyAccount?: Maybe<Scalars['Boolean']['output']>;
  deletePartyExternalAccount?: Maybe<Scalars['Boolean']['output']>;
  deleteReport?: Maybe<Scalars['Boolean']['output']>;
  deleteReports?: Maybe<Scalars['Boolean']['output']>;
  deleteScreen?: Maybe<Scalars['Boolean']['output']>;
  deleteTAccountChart?: Maybe<Scalars['Boolean']['output']>;
  deleteTimeSerie?: Maybe<Scalars['Boolean']['output']>;
  deleteTransaction?: Maybe<Scalars['Boolean']['output']>;
  deleteTransferAgentOrder?: Maybe<Scalars['Boolean']['output']>;
  deleteValuation?: Maybe<Scalars['Boolean']['output']>;
  derivativesToPrices?: Maybe<Scalars['Boolean']['output']>;
  derivativesToValuations?: Maybe<Scalars['Boolean']['output']>;
  fxPricesToValuations?: Maybe<Scalars['Boolean']['output']>;
  /** fxQuotesToPrices mutation converts fx-quotes to prices and calculates relevant fx-crosses */
  fxQuotesToPrices?: Maybe<Scalars['Boolean']['output']>;
  /** If aggregated amount the created transaction amount will be from current position to input amount */
  importAccruedInterestPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importBondTrade?: Maybe<MiniTransaction>;
  importCloseDerivativeTrade?: Maybe<MiniTransaction>;
  importCloseResetPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  /** NOT creating default valuations if none exists for Insert transactions + Collateral transactions */
  importCollateral?: Maybe<MiniTransaction>;
  importCoupon?: Maybe<MiniTransaction>;
  importCreateRedeems?: Maybe<Array<Maybe<MiniTransaction>>>;
  importDerivativeTrade?: Maybe<MiniTransaction>;
  importDividend?: Maybe<MiniTransaction>;
  importDividendPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFeeInterests?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFundTrades?: Maybe<Array<Maybe<MiniTransaction>>>;
  importFxSpot?: Maybe<MiniTransaction>;
  importInsertCash?: Maybe<MiniTransaction>;
  importInsertCloseDerivativeTrade?: Maybe<MiniTransaction>;
  importInsertDerivativeTrade?: Maybe<MiniTransaction>;
  importInsertSecurity?: Maybe<MiniTransaction>;
  importInterestPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  /** Portfolio swap transactions */
  importOpenPortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importStockTradePortfolioSwapX?: Maybe<Array<Maybe<MiniTransaction>>>;
  importStockTrades?: Maybe<Array<Maybe<MiniTransaction>>>;
  importTAccountChartAccounts?: Maybe<TAccountChart>;
  importTAccountChartMappings?: Maybe<TAccountChart>;
  importTransfers?: Maybe<Array<Maybe<MiniTransaction>>>;
  manuallyMatchBankAccountTransactions: Array<MatchedBankAccountTransaction>;
  matchBankAccountTransactions: Array<MatchedBankAccountTransaction>;
  matchCoacsTransactions: Array<MatchedCoacsTransaction>;
  matchTransactions: Array<MatchedTransaction>;
  pricesToValuations?: Maybe<Scalars['Boolean']['output']>;
  /** quotesToPrices mutation converts quotes to prices using specified logic for each QuoteTypeEnum */
  quotesToPrices?: Maybe<Scalars['Boolean']['output']>;
  roundTransaction?: Maybe<MiniTransaction>;
  setRedisKey?: Maybe<Scalars['GraphQLJSON']['output']>;
  updateAccountingRun?: Maybe<AccountingRun>;
  updateAccountingRunStatus?: Maybe<Scalars['Boolean']['output']>;
  updateBrokerTransaction: BrokerTransaction;
  updateCurveDefinition?: Maybe<CurveDefinition>;
  updateExposureCollateral: ExposureCollateral;
  updateInstrument?: Maybe<Instrument>;
  updateIssuerProgram?: Maybe<IssuerProgram>;
  updateJournalEntries?: Maybe<Array<Maybe<JournalEntry>>>;
  updateJournalEntry?: Maybe<JournalEntry>;
  updateMiniTransaction?: Maybe<MiniTransaction>;
  updateNavsPublishedTo?: Maybe<Array<Maybe<Nav>>>;
  updateParty?: Maybe<Party>;
  updatePartyAccount?: Maybe<Party>;
  updatePartyBase?: Maybe<Party>;
  updatePartyExternalAccount?: Maybe<Party>;
  updatePartyFundInfo?: Maybe<Party>;
  updatePartyIssuer?: Maybe<Party>;
  updateReport?: Maybe<Report>;
  updateRole?: Maybe<Role>;
  updateScreen?: Maybe<Screen>;
  updateTAccountChart?: Maybe<TAccountChart>;
  updateTimeSerie?: Maybe<TimeSeries>;
  updateTransactions?: Maybe<Array<Maybe<Transaction>>>;
  updateTransferAgentOrderAttachments?: Maybe<TransferAgentOrder>;
  updateUser?: Maybe<User>;
  upsertAgreements?: Maybe<Array<Maybe<Agreement>>>;
  upsertArrayOfTimeSerieItems: Array<Scalars['Boolean']['output']>;
  upsertBankAccountTransactions: Array<BankAccountTransaction>;
  upsertBaseCalendars?: Maybe<Array<Maybe<BaseCalendar>>>;
  upsertCorporateActions?: Maybe<Array<CorporateAction>>;
  upsertCosts?: Maybe<Array<Maybe<Cost>>>;
  upsertCustodianPositions: Array<CustodianApiPosition>;
  upsertCustomers?: Maybe<Array<Maybe<Customer>>>;
  upsertExtensions?: Maybe<Array<Maybe<Extension>>>;
  upsertNotes?: Maybe<Array<Maybe<Note>>>;
  upsertPrices?: Maybe<Array<Maybe<Price>>>;
  upsertQuotes?: Maybe<Array<Maybe<Quote>>>;
  upsertRegisterExtracts?: Maybe<Array<RegisterExtract>>;
  upsertTickets?: Maybe<Array<Maybe<Ticket>>>;
  upsertTimeSerieItems?: Maybe<TimeSeries>;
  upsertTransferAgentOrders?: Maybe<Array<Maybe<TransferAgentOrder>>>;
  upsertValuationAttachments?: Maybe<Valuation>;
  upsertValuationMappings?: Maybe<Array<Maybe<ValuationMapping>>>;
  upsertValuations?: Maybe<Array<Maybe<Price>>>;
  valuationsToPrices?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAcceptTermsArgs = {
  input: AcceptTermsInput;
};


export type MutationAddTimeSerieItemsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCleanPerformanceCacheArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationCloneTAccountChartArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAccountingRunArgs = {
  input: CreateAccountingRunInput;
};


export type MutationCreateAttachmentArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  input: CreateAttachmentInput;
};


export type MutationCreateCdsValuationsArgs = {
  cdsTypes?: InputMaybe<Array<CdsType>>;
};


export type MutationCreateCurveDefinitionArgs = {
  input: CreateCurveDefinitionInput;
};


export type MutationCreateExposureCollateralArgs = {
  input: ExposureCollateralInput;
};


export type MutationCreateInstrumentArgs = {
  input: CreateInstrumentInput;
};


export type MutationCreateIssuerProgramArgs = {
  input: IssuerProgramInput;
};


export type MutationCreateJournalEntryArgs = {
  input: CreateJournalEntryInput;
};


export type MutationCreateManagementCostTransactionsArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type MutationCreateMiniTransactionsArgs = {
  input: Array<CreateMiniTransactionInput>;
};


export type MutationCreateNavArgs = {
  input: NavInput;
};


export type MutationCreatePartyAccountArgs = {
  input: PartyAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreatePartyBaseArgs = {
  input: PartyBaseInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateScreenArgs = {
  input: ScreenInput;
};


export type MutationCreateSwiftsArgs = {
  input: Array<CreateSwiftInput>;
};


export type MutationCreateTAccountChartArgs = {
  input?: InputMaybe<CreateTAccountChartInput>;
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationCreateUpdateExternalAccountArgs = {
  input: CreateUpdateExternalAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationCreateUpdatePartyCashOrBalanceInstrumentArgs = {
  input: CreateUpdatePartyCashOrBalanceInstrumentInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateValuationArgs = {
  input: CreateValuationInput;
};


export type MutationDeleteAttachmentArgs = {
  fileId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteCurveDefinitionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteInstrumentArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteIssuerProgramArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteJournalEntryArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteManagementCostTransactionsArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
};


export type MutationDeleteNavArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyAccountArgs = {
  accountId: Scalars['GraphQLObjectId']['input'];
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeletePartyExternalAccountArgs = {
  externalAccountId: Scalars['GraphQLObjectId']['input'];
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteReportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteReportsArgs = {
  hard?: InputMaybe<Scalars['Boolean']['input']>;
  idIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationDeleteScreenArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTAccountChartArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTimeSerieArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTransactionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteTransferAgentOrderArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDeleteValuationArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type MutationDerivativesToPricesArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationDerivativesToValuationsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationFxPricesToValuationsArgs = {
  baseCurrency: CurrencyEnum;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationFxQuotesToPricesArgs = {
  baseCurrency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationImportAccruedInterestPortfolioSwapXArgs = {
  aggregatedAmount: Scalars['Boolean']['input'];
  portfolioInterestInput: ImportFeeInterestInput;
};


export type MutationImportBondTradeArgs = {
  input: ImportBondTradeInput;
};


export type MutationImportCloseDerivativeTradeArgs = {
  input: ImportCloseDerivativeTradeInput;
};


export type MutationImportCloseResetPortfolioSwapXArgs = {
  accountAlias: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  portfolioSwapAlias: Scalars['String']['input'];
  reset: Scalars['Boolean']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};


export type MutationImportCollateralArgs = {
  input: ImportCollateralInput;
};


export type MutationImportCouponArgs = {
  input: ImportCouponInput;
};


export type MutationImportCreateRedeemsArgs = {
  input?: InputMaybe<Array<ImportCreateRedeemInput>>;
};


export type MutationImportDerivativeTradeArgs = {
  input: ImportDerivativeTradeInput;
};


export type MutationImportDividendArgs = {
  input: ImportDividendInput;
};


export type MutationImportDividendPortfolioSwapXArgs = {
  portfolioSwapAlias: Scalars['String']['input'];
  stockDividendInput: ImportDividendInput;
};


export type MutationImportFeeInterestsArgs = {
  input: Array<ImportFeeInterestInput>;
};


export type MutationImportFundTradesArgs = {
  input: Array<ImportFundTradeInput>;
};


export type MutationImportFxSpotArgs = {
  input: ImportFxSpotInput;
};


export type MutationImportInsertCashArgs = {
  input: ImportInsertCashInput;
};


export type MutationImportInsertCloseDerivativeTradeArgs = {
  input: ImportCloseDerivativeTradeInput;
};


export type MutationImportInsertDerivativeTradeArgs = {
  input: ImportInsertDerivativeTradeInput;
};


export type MutationImportInsertSecurityArgs = {
  input: ImportInsertSecurityInput;
};


export type MutationImportInterestPortfolioSwapXArgs = {
  portfolioInterestInput: ImportFeeInterestInput;
};


export type MutationImportOpenPortfolioSwapXArgs = {
  stockInput: Array<ImportStockTradeInput>;
  swapInput: ImportDerivativeTradeInput;
};


export type MutationImportStockTradePortfolioSwapXArgs = {
  portfolioSwapAlias: Scalars['String']['input'];
  settlementAmount?: InputMaybe<Scalars['Float']['input']>;
  stockInput: ImportStockTradeInput;
};


export type MutationImportStockTradesArgs = {
  input: Array<ImportStockTradeInput>;
};


export type MutationImportTAccountChartAccountsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  csv: Scalars['String']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImportTAccountChartMappingsArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  csv: Scalars['String']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImportTransfersArgs = {
  input: Array<ImportTransferInput>;
};


export type MutationManuallyMatchBankAccountTransactionsArgs = {
  input: Array<MatchBankAccountTransactionsInput>;
};


export type MutationMatchBankAccountTransactionsArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationMatchCoacsTransactionsArgs = {
  input: Array<MatchCoacsTransactionsInput>;
};


export type MutationMatchTransactionsArgs = {
  input: Array<MatchTransactionsInput>;
};


export type MutationPricesToValuationsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn: Array<Scalars['GraphQLObjectId']['input']>;
};


export type MutationQuotesToPricesArgs = {
  date: Scalars['GraphQLDateString']['input'];
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type MutationRoundTransactionArgs = {
  input: RoundTransactionInput;
};


export type MutationSetRedisKeyArgs = {
  data: Scalars['GraphQLJSON']['input'];
  key: Scalars['String']['input'];
};


export type MutationUpdateAccountingRunArgs = {
  input: UpdateAccountingRunInput;
};


export type MutationUpdateAccountingRunStatusArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  status: AccountingRunStatusEnum;
};


export type MutationUpdateBrokerTransactionArgs = {
  input: UpdateBrokerTransactionInput;
};


export type MutationUpdateCurveDefinitionArgs = {
  input: UpdateCurveDefinitionInput;
};


export type MutationUpdateExposureCollateralArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: ExposureCollateralInput;
};


export type MutationUpdateInstrumentArgs = {
  input: UpdateInstrumentInput;
};


export type MutationUpdateIssuerProgramArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: IssuerProgramInput;
};


export type MutationUpdateJournalEntriesArgs = {
  input: Array<UpdateJournalEntryInput>;
};


export type MutationUpdateJournalEntryArgs = {
  input: UpdateJournalEntryInput;
};


export type MutationUpdateMiniTransactionArgs = {
  input: UpdateMiniTransactionInput;
};


export type MutationUpdateNavsPublishedToArgs = {
  publishedToInput: Array<InputMaybe<PublishedToInput>>;
};


export type MutationUpdatePartyArgs = {
  input: UpdatePartyInput;
};


export type MutationUpdatePartyAccountArgs = {
  input: PartyAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyBaseArgs = {
  input: PartyBaseInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyExternalAccountArgs = {
  input: PartyExternalAccountInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyFundInfoArgs = {
  input: PartyFundInfoInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdatePartyIssuerArgs = {
  input: PartyIssuerInput;
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateScreenArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  input: ScreenInput;
};


export type MutationUpdateTAccountChartArgs = {
  input?: InputMaybe<UpdateTAccountChartInput>;
};


export type MutationUpdateTimeSerieArgs = {
  input: TimeSeriesInput;
};


export type MutationUpdateTransactionsArgs = {
  input: Array<UpdateTransactionInput>;
};


export type MutationUpdateTransferAgentOrderAttachmentsArgs = {
  input: TransferAgentOrderAttachmentsInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpsertAgreementsArgs = {
  input: Array<AgreementInput>;
};


export type MutationUpsertArrayOfTimeSerieItemsArgs = {
  data: Array<TimeSeriesUpsertInput>;
};


export type MutationUpsertBankAccountTransactionsArgs = {
  input: Array<BankAccountTransactionInput>;
};


export type MutationUpsertBaseCalendarsArgs = {
  input: Array<BaseCalendarInput>;
};


export type MutationUpsertCorporateActionsArgs = {
  input: Array<CorporateActionInput>;
};


export type MutationUpsertCostsArgs = {
  input: Array<CostInput>;
};


export type MutationUpsertCustodianPositionsArgs = {
  input: Array<CustodianApiPositionInput>;
};


export type MutationUpsertCustomersArgs = {
  input: Array<CustomerInput>;
};


export type MutationUpsertExtensionsArgs = {
  input: Array<ExtensionInput>;
};


export type MutationUpsertNotesArgs = {
  input: Array<NoteInput>;
};


export type MutationUpsertPricesArgs = {
  input: Array<PriceInput>;
};


export type MutationUpsertQuotesArgs = {
  input: Array<QuoteInput>;
};


export type MutationUpsertRegisterExtractsArgs = {
  input: Array<RegisterExtractInput>;
};


export type MutationUpsertTicketsArgs = {
  input: Array<TicketInput>;
};


export type MutationUpsertTimeSerieItemsArgs = {
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  type: Scalars['String']['input'];
};


export type MutationUpsertTransferAgentOrdersArgs = {
  input: Array<TransferAgentOrderInput>;
};


export type MutationUpsertValuationAttachmentsArgs = {
  attachments: Array<AttachmentInput>;
  valuationId: Scalars['GraphQLObjectId']['input'];
};


export type MutationUpsertValuationMappingsArgs = {
  input: Array<ValuationMappingInput>;
};


export type MutationUpsertValuationsArgs = {
  input: Array<PriceInput>;
};

export enum NaceClass {
  /** (A) Agriculture, Forestry And Fishing */
  A = 'A',
  /** (01) Crop And Animal Production, Hunting And Related Service Activities */
  A01 = 'A01',
  /** (02) Forestry And Logging */
  A02 = 'A02',
  /** (03) Fishing And Aquaculture */
  A03 = 'A03',
  /** (01.1) Growing Of Non-Perennial Crops */
  A011 = 'A011',
  /** (01.2) Growing Of Perennial Crops */
  A012 = 'A012',
  /** (01.3) Plant Propagation */
  A013 = 'A013',
  /** (01.4) Animal Production */
  A014 = 'A014',
  /** (01.5) Mixed Farming */
  A015 = 'A015',
  /** (01.6) Support Activities To Agriculture And Post-Harvest Crop Activities */
  A016 = 'A016',
  /** (01.7) Hunting, Trapping And Related Service Activities */
  A017 = 'A017',
  /** (02.1) Silviculture And Other Forestry Activities */
  A021 = 'A021',
  /** (02.2) Logging */
  A022 = 'A022',
  /** (02.3) Gathering Of Wild Growing Non-Wood Products */
  A023 = 'A023',
  /** (02.4) Support Services To Forestry */
  A024 = 'A024',
  /** (03.1) Fishing */
  A031 = 'A031',
  /** (03.2) Aquaculture */
  A032 = 'A032',
  /** (01.11) Growing Of Cereals (Except Rice), Leguminous Crops And Oil Seeds */
  A0111 = 'A0111',
  /** (01.12) Growing Of Rice */
  A0112 = 'A0112',
  /** (01.13) Growing Of Vegetables And Melons, Roots And Tubers */
  A0113 = 'A0113',
  /** (01.14) Growing Of Sugar Cane */
  A0114 = 'A0114',
  /** (01.15) Growing Of Tobacco */
  A0115 = 'A0115',
  /** (01.16) Growing Of Fibre Crops */
  A0116 = 'A0116',
  /** (01.19) Growing Of Other Non-Perennial Crops */
  A0119 = 'A0119',
  /** (01.21) Growing Of Grapes */
  A0121 = 'A0121',
  /** (01.22) Growing Of Tropical And Subtropical Fruits */
  A0122 = 'A0122',
  /** (01.23) Growing Of Citrus Fruits */
  A0123 = 'A0123',
  /** (01.24) Growing Of Pome Fruits And Stone Fruits */
  A0124 = 'A0124',
  /** (01.25) Growing Of Other Tree And Bush Fruits And Nuts */
  A0125 = 'A0125',
  /** (01.26) Growing Of Oleaginous Fruits */
  A0126 = 'A0126',
  /** (01.27) Growing Of Beverage Crops */
  A0127 = 'A0127',
  /** (01.28) Growing Of Spices, Aromatic, Drug And Pharmaceutical Crops */
  A0128 = 'A0128',
  /** (01.29) Growing Of Other Perennial Crops */
  A0129 = 'A0129',
  /** (01.30) Plant Propagation */
  A0130 = 'A0130',
  /** (01.41) Raising Of Dairy Cattle */
  A0141 = 'A0141',
  /** (01.42) Raising Of Other Cattle And Buffaloes */
  A0142 = 'A0142',
  /** (01.43) Raising Of Horses And Other Equines */
  A0143 = 'A0143',
  /** (01.44) Raising Of Camels And Camelids */
  A0144 = 'A0144',
  /** (01.45) Raising Of Sheep And Goats */
  A0145 = 'A0145',
  /** (01.46) Raising Of Swine/Pigs */
  A0146 = 'A0146',
  /** (01.47) Raising Of Poultry */
  A0147 = 'A0147',
  /** (01.49) Raising Of Other Animals */
  A0149 = 'A0149',
  /** (01.50) Mixed Farming */
  A0150 = 'A0150',
  /** (01.61) Support Activities For Crop Production */
  A0161 = 'A0161',
  /** (01.62) Support Activities For Animal Production */
  A0162 = 'A0162',
  /** (01.63) Post-Harvest Crop Activities */
  A0163 = 'A0163',
  /** (01.64) Seed Processing For Propagation */
  A0164 = 'A0164',
  /** (01.70) Hunting, Trapping And Related Service Activities */
  A0170 = 'A0170',
  /** (02.10) Silviculture And Other Forestry Activities */
  A0210 = 'A0210',
  /** (02.20) Logging */
  A0220 = 'A0220',
  /** (02.30) Gathering Of Wild Growing Non-Wood Products */
  A0230 = 'A0230',
  /** (02.40) Support Services To Forestry */
  A0240 = 'A0240',
  /** (03.11) Marine Fishing */
  A0311 = 'A0311',
  /** (03.12) Freshwater Fishing */
  A0312 = 'A0312',
  /** (03.21) Marine Aquaculture */
  A0321 = 'A0321',
  /** (03.22) Freshwater Aquaculture */
  A0322 = 'A0322',
  /** (B) Mining And Quarrying */
  B = 'B',
  /** (05) Mining Of Coal And Lignite */
  B05 = 'B05',
  /** (06) Extraction Of Crude Petroleum And Natural Gas */
  B06 = 'B06',
  /** (07) Mining Of Metal Ores */
  B07 = 'B07',
  /** (08) Other Mining And Quarrying */
  B08 = 'B08',
  /** (09) Mining Support Service Activities */
  B09 = 'B09',
  /** (05.1) Mining Of Hard Coal */
  B051 = 'B051',
  /** (05.2) Mining Of Lignite */
  B052 = 'B052',
  /** (06.1) Extraction Of Crude Petroleum */
  B061 = 'B061',
  /** (06.2) Extraction Of Natural Gas */
  B062 = 'B062',
  /** (07.1) Mining Of Iron Ores */
  B071 = 'B071',
  /** (07.2) Mining Of Non-Ferrous Metal Ores */
  B072 = 'B072',
  /** (08.1) Quarrying Of Stone, Sand And Clay */
  B081 = 'B081',
  /** (08.9) Mining And Quarrying N.E.C. */
  B089 = 'B089',
  /** (09.1) Support Activities For Petroleum And Natural Gas Extraction */
  B091 = 'B091',
  /** (09.9) Support Activities For Other Mining And Quarrying */
  B099 = 'B099',
  /** (05.10) Mining Of Hard Coal */
  B0510 = 'B0510',
  /** (05.20) Mining Of Lignite */
  B0520 = 'B0520',
  /** (06.10) Extraction Of Crude Petroleum */
  B0610 = 'B0610',
  /** (06.20) Extraction Of Natural Gas */
  B0620 = 'B0620',
  /** (07.10) Mining Of Iron Ores */
  B0710 = 'B0710',
  /** (07.21) Mining Of Uranium And Thorium Ores */
  B0721 = 'B0721',
  /** (07.29) Mining Of Other Non-Ferrous Metal Ores */
  B0729 = 'B0729',
  /** (08.11) Quarrying Of Ornamental And Building Stone, Limestone, Gypsum, Chalk And Slate */
  B0811 = 'B0811',
  /** (08.12) Operation Of Gravel And Sand Pits; Mining Of Clays And Kaolin */
  B0812 = 'B0812',
  /** (08.91) Mining Of Chemical And Fertiliser Minerals */
  B0891 = 'B0891',
  /** (08.92) Extraction Of Peat */
  B0892 = 'B0892',
  /** (08.93) Extraction Of Salt */
  B0893 = 'B0893',
  /** (08.99) Other Mining And Quarrying N.E.C. */
  B0899 = 'B0899',
  /** (09.10) Support Activities For Petroleum And Natural Gas Extraction */
  B0910 = 'B0910',
  /** (09.90) Support Activities For Other Mining And Quarrying */
  B0990 = 'B0990',
  /** (C) Manufacturing */
  C = 'C',
  /** (10) Manufacture Of Food Products */
  C10 = 'C10',
  /** (11) Manufacture Of Beverages */
  C11 = 'C11',
  /** (12) Manufacture Of Tobacco Products */
  C12 = 'C12',
  /** (13) Manufacture Of Textiles */
  C13 = 'C13',
  /** (14) Manufacture Of Wearing Apparel */
  C14 = 'C14',
  /** (15) Manufacture Of Leather And Related Products */
  C15 = 'C15',
  /** (16) Manufacture Of Wood And Of Products Of Wood And Cork, Except Furniture; Manufacture Of Articles Of Straw And Plaiting Materials */
  C16 = 'C16',
  /** (17) Manufacture Of Paper And Paper Products */
  C17 = 'C17',
  /** (18) Printing And Reproduction Of Recorded Media */
  C18 = 'C18',
  /** (19) Manufacture Of Coke And Refined Petroleum Products */
  C19 = 'C19',
  /** (20) Manufacture Of Chemicals And Chemical Products */
  C20 = 'C20',
  /** (21) Manufacture Of Basic Pharmaceutical Products And Pharmaceutical Preparations */
  C21 = 'C21',
  /** (22) Manufacture Of Rubber And Plastic Products */
  C22 = 'C22',
  /** (23) Manufacture Of Other Non-Metallic Mineral Products */
  C23 = 'C23',
  /** (24) Manufacture Of Basic Metals */
  C24 = 'C24',
  /** (25) Manufacture Of Fabricated Metal Products, Except Machinery And Equipment */
  C25 = 'C25',
  /** (26) Manufacture Of Computer, Electronic And Optical Products */
  C26 = 'C26',
  /** (27) Manufacture Of Electrical Equipment */
  C27 = 'C27',
  /** (28) Manufacture Of Machinery And Equipment N.E.C. */
  C28 = 'C28',
  /** (29) Manufacture Of Motor Vehicles, Trailers And Semi-Trailers */
  C29 = 'C29',
  /** (30) Manufacture Of Other Transport Equipment */
  C30 = 'C30',
  /** (31) Manufacture Of Furniture */
  C31 = 'C31',
  /** (32) Other Manufacturing */
  C32 = 'C32',
  /** (33) Repair And Installation Of Machinery And Equipment */
  C33 = 'C33',
  /** (10.1) Processing And Preserving Of Meat And Production Of Meat Products */
  C101 = 'C101',
  /** (10.2) Processing And Preserving Of Fish, Crustaceans And Molluscs */
  C102 = 'C102',
  /** (10.3) Processing And Preserving Of Fruit And Vegetables */
  C103 = 'C103',
  /** (10.4) Manufacture Of Vegetable And Animal Oils And Fats */
  C104 = 'C104',
  /** (10.5) Manufacture Of Dairy Products */
  C105 = 'C105',
  /** (10.6) Manufacture Of Grain Mill Products, Starches And Starch Products */
  C106 = 'C106',
  /** (10.7) Manufacture Of Bakery And Farinaceous Products */
  C107 = 'C107',
  /** (10.8) Manufacture Of Other Food Products */
  C108 = 'C108',
  /** (10.9) Manufacture Of Prepared Animal Feeds */
  C109 = 'C109',
  /** (11.0) Manufacture Of Beverages */
  C110 = 'C110',
  /** (12.0) Manufacture Of Tobacco Products */
  C120 = 'C120',
  /** (13.1) Preparation And Spinning Of Textile Fibres */
  C131 = 'C131',
  /** (13.2) Weaving Of Textiles */
  C132 = 'C132',
  /** (13.3) Finishing Of Textiles */
  C133 = 'C133',
  /** (13.9) Manufacture Of Other Textiles */
  C139 = 'C139',
  /** (14.1) Manufacture Of Wearing Apparel, Except Fur Apparel */
  C141 = 'C141',
  /** (14.2) Manufacture Of Articles Of Fur */
  C142 = 'C142',
  /** (14.3) Manufacture Of Knitted And Crocheted Apparel */
  C143 = 'C143',
  /** (15.1) Tanning And Dressing Of Leather; Manufacture Of Luggage, Handbags, Saddlery And Harness; Dressing And Dyeing Of Fur */
  C151 = 'C151',
  /** (15.2) Manufacture Of Footwear */
  C152 = 'C152',
  /** (16.1) Sawmilling And Planing Of Wood */
  C161 = 'C161',
  /** (16.2) Manufacture Of Products Of Wood, Cork, Straw And Plaiting Materials */
  C162 = 'C162',
  /** (17.1) Manufacture Of Pulp, Paper And Paperboard */
  C171 = 'C171',
  /** (17.2) Manufacture Of Articles Of Paper And Paperboard */
  C172 = 'C172',
  /** (18.1) Printing And Service Activities Related To Printing */
  C181 = 'C181',
  /** (18.2) Reproduction Of Recorded Media */
  C182 = 'C182',
  /** (19.1) Manufacture Of Coke Oven Products */
  C191 = 'C191',
  /** (19.2) Manufacture Of Refined Petroleum Products */
  C192 = 'C192',
  /** (20.1) Manufacture Of Basic Chemicals, Fertilisers And Nitrogen Compounds, Plastics And Synthetic Rubber In Primary Forms */
  C201 = 'C201',
  /** (20.2) Manufacture Of Pesticides And Other Agrochemical Products */
  C202 = 'C202',
  /** (20.3) Manufacture Of Paints, Varnishes And Similar Coatings, Printing Ink And Mastics */
  C203 = 'C203',
  /** (20.4) Manufacture Of Soap And Detergents, Cleaning And Polishing Preparations, Perfumes And Toilet Preparations */
  C204 = 'C204',
  /** (20.5) Manufacture Of Other Chemical Products */
  C205 = 'C205',
  /** (20.6) Manufacture Of Man-Made Fibres */
  C206 = 'C206',
  /** (21.1) Manufacture Of Basic Pharmaceutical Products */
  C211 = 'C211',
  /** (21.2) Manufacture Of Pharmaceutical Preparations */
  C212 = 'C212',
  /** (22.1) Manufacture Of Rubber Products */
  C221 = 'C221',
  /** (22.2) Manufacture Of Plastics Products */
  C222 = 'C222',
  /** (23.1) Manufacture Of Glass And Glass Products */
  C231 = 'C231',
  /** (23.2) Manufacture Of Refractory Products */
  C232 = 'C232',
  /** (23.3) Manufacture Of Clay Building Materials */
  C233 = 'C233',
  /** (23.4) Manufacture Of Other Porcelain And Ceramic Products */
  C234 = 'C234',
  /** (23.5) Manufacture Of Cement, Lime And Plaster */
  C235 = 'C235',
  /** (23.6) Manufacture Of Articles Of Concrete, Cement And Plaster */
  C236 = 'C236',
  /** (23.7) Cutting, Shaping And Finishing Of Stone */
  C237 = 'C237',
  /** (23.9) Manufacture Of Abrasive Products And Non-Metallic Mineral Products N.E.C. */
  C239 = 'C239',
  /** (24.1) Manufacture Of Basic Iron And Steel And Of Ferro-Alloys */
  C241 = 'C241',
  /** (24.2) Manufacture Of Tubes, Pipes, Hollow Profiles And Related Fittings, Of Steel */
  C242 = 'C242',
  /** (24.3) Manufacture Of Other Products Of First Processing Of Steel */
  C243 = 'C243',
  /** (24.4) Manufacture Of Basic Precious And Other Non-Ferrous Metals */
  C244 = 'C244',
  /** (24.5) Casting Of Metals */
  C245 = 'C245',
  /** (25.1) Manufacture Of Structural Metal Products */
  C251 = 'C251',
  /** (25.2) Manufacture Of Tanks, Reservoirs And Containers Of Metal */
  C252 = 'C252',
  /** (25.3) Manufacture Of Steam Generators, Except Central Heating Hot Water Boilers */
  C253 = 'C253',
  /** (25.4) Manufacture Of Weapons And Ammunition */
  C254 = 'C254',
  /** (25.5) Forging, Pressing, Stamping And Roll-Forming Of Metal; Powder Metallurgy */
  C255 = 'C255',
  /** (25.6) Treatment And Coating Of Metals; Machining */
  C256 = 'C256',
  /** (25.7) Manufacture Of Cutlery, Tools And General Hardware */
  C257 = 'C257',
  /** (25.9) Manufacture Of Other Fabricated Metal Products */
  C259 = 'C259',
  /** (26.1) Manufacture Of Electronic Components And Boards */
  C261 = 'C261',
  /** (26.2) Manufacture Of Computers And Peripheral Equipment */
  C262 = 'C262',
  /** (26.3) Manufacture Of Communication Equipment */
  C263 = 'C263',
  /** (26.4) Manufacture Of Consumer Electronics */
  C264 = 'C264',
  /** (26.5) Manufacture Of Instruments And Appliances For Measuring, Testing And Navigation; Watches And Clocks */
  C265 = 'C265',
  /** (26.6) Manufacture Of Irradiation, Electromedical And Electrotherapeutic Equipment */
  C266 = 'C266',
  /** (26.7) Manufacture Of Optical Instruments And Photographic Equipment */
  C267 = 'C267',
  /** (26.8) Manufacture Of Magnetic And Optical Media */
  C268 = 'C268',
  /** (27.1) Manufacture Of Electric Motors, Generators, Transformers And Electricity Distribution And Control Apparatus */
  C271 = 'C271',
  /** (27.2) Manufacture Of Batteries And Accumulators */
  C272 = 'C272',
  /** (27.3) Manufacture Of Wiring And Wiring Devices */
  C273 = 'C273',
  /** (27.4) Manufacture Of Electric Lighting Equipment */
  C274 = 'C274',
  /** (27.5) Manufacture Of Domestic Appliances */
  C275 = 'C275',
  /** (27.9) Manufacture Of Other Electrical Equipment */
  C279 = 'C279',
  /** (28.1) Manufacture Of General-Purpose Machinery */
  C281 = 'C281',
  /** (28.2) Manufacture Of Other General-Purpose Machinery */
  C282 = 'C282',
  /** (28.3) Manufacture Of Agricultural And Forestry Machinery */
  C283 = 'C283',
  /** (28.4) Manufacture Of Metal Forming Machinery And Machine Tools */
  C284 = 'C284',
  /** (28.9) Manufacture Of Other Special-Purpose Machinery */
  C289 = 'C289',
  /** (29.1) Manufacture Of Motor Vehicles */
  C291 = 'C291',
  /** (29.2) Manufacture Of Bodies (Coachwork) For Motor Vehicles; Manufacture Of Trailers And Semi-Trailers */
  C292 = 'C292',
  /** (29.3) Manufacture Of Parts And Accessories For Motor Vehicles */
  C293 = 'C293',
  /** (30.1) Building Of Ships And Boats */
  C301 = 'C301',
  /** (30.2) Manufacture Of Railway Locomotives And Rolling Stock */
  C302 = 'C302',
  /** (30.3) Manufacture Of Air And Spacecraft And Related Machinery */
  C303 = 'C303',
  /** (30.4) Manufacture Of Military Fighting Vehicles */
  C304 = 'C304',
  /** (30.9) Manufacture Of Transport Equipment N.E.C. */
  C309 = 'C309',
  /** (31.0) Manufacture Of Furniture */
  C310 = 'C310',
  /** (32.1) Manufacture Of Jewellery, Bijouterie And Related Articles */
  C321 = 'C321',
  /** (32.2) Manufacture Of Musical Instruments */
  C322 = 'C322',
  /** (32.3) Manufacture Of Sports Goods */
  C323 = 'C323',
  /** (32.4) Manufacture Of Games And Toys */
  C324 = 'C324',
  /** (32.5) Manufacture Of Medical And Dental Instruments And Supplies */
  C325 = 'C325',
  /** (32.9) Manufacturing N.E.C. */
  C329 = 'C329',
  /** (33.1) Repair Of Fabricated Metal Products, Machinery And Equipment */
  C331 = 'C331',
  /** (33.2) Installation Of Industrial Machinery And Equipment */
  C332 = 'C332',
  /** (10.11) Processing And Preserving Of Meat */
  C1011 = 'C1011',
  /** (10.12) Processing And Preserving Of Poultry Meat */
  C1012 = 'C1012',
  /** (10.13) Production Of Meat And Poultry Meat Products */
  C1013 = 'C1013',
  /** (10.20) Processing And Preserving Of Fish, Crustaceans And Molluscs */
  C1020 = 'C1020',
  /** (10.31) Processing And Preserving Of Potatoes */
  C1031 = 'C1031',
  /** (10.32) Manufacture Of Fruit And Vegetable Juice */
  C1032 = 'C1032',
  /** (10.39) Other Processing And Preserving Of Fruit And Vegetables */
  C1039 = 'C1039',
  /** (10.41) Manufacture Of Oils And Fats */
  C1041 = 'C1041',
  /** (10.42) Manufacture Of Margarine And Similar Edible Fats */
  C1042 = 'C1042',
  /** (10.51) Operation Of Dairies And Cheese Making */
  C1051 = 'C1051',
  /** (10.52) Manufacture Of Ice Cream */
  C1052 = 'C1052',
  /** (10.61) Manufacture Of Grain Mill Products */
  C1061 = 'C1061',
  /** (10.62) Manufacture Of Starches And Starch Products */
  C1062 = 'C1062',
  /** (10.71) Manufacture Of Bread; Manufacture Of Fresh Pastry Goods And Cakes */
  C1071 = 'C1071',
  /** (10.72) Manufacture Of Rusks And Biscuits; Manufacture Of Preserved Pastry Goods And Cakes */
  C1072 = 'C1072',
  /** (10.73) Manufacture Of Macaroni, Noodles, Couscous And Similar Farinaceous Products */
  C1073 = 'C1073',
  /** (10.81) Manufacture Of Sugar */
  C1081 = 'C1081',
  /** (10.82) Manufacture Of Cocoa, Chocolate And Sugar Confectionery */
  C1082 = 'C1082',
  /** (10.83) Processing Of Tea And Coffee */
  C1083 = 'C1083',
  /** (10.84) Manufacture Of Condiments And Seasonings */
  C1084 = 'C1084',
  /** (10.85) Manufacture Of Prepared Meals And Dishes */
  C1085 = 'C1085',
  /** (10.86) Manufacture Of Homogenised Food Preparations And Dietetic Food */
  C1086 = 'C1086',
  /** (10.89) Manufacture Of Other Food Products N.E.C. */
  C1089 = 'C1089',
  /** (10.91) Manufacture Of Prepared Feeds For Farm Animals */
  C1091 = 'C1091',
  /** (10.92) Manufacture Of Prepared Pet Foods */
  C1092 = 'C1092',
  /** (11.01) Distilling, Rectifying And Blending Of Spirits */
  C1101 = 'C1101',
  /** (11.02) Manufacture Of Wine From Grape */
  C1102 = 'C1102',
  /** (11.03) Manufacture Of Cider And Other Fruit Wines */
  C1103 = 'C1103',
  /** (11.04) Manufacture Of Other Non-Distilled Fermented Beverages */
  C1104 = 'C1104',
  /** (11.05) Manufacture Of Beer */
  C1105 = 'C1105',
  /** (11.06) Manufacture Of Malt */
  C1106 = 'C1106',
  /** (11.07) Manufacture Of Soft Drinks; Production Of Mineral Waters And Other Bottled Waters */
  C1107 = 'C1107',
  /** (12.00) Manufacture Of Tobacco Products */
  C1200 = 'C1200',
  /** (13.10) Preparation And Spinning Of Textile Fibres */
  C1310 = 'C1310',
  /** (13.20) Weaving Of Textiles */
  C1320 = 'C1320',
  /** (13.30) Finishing Of Textiles */
  C1330 = 'C1330',
  /** (13.91) Manufacture Of Knitted And Crocheted Fabrics */
  C1391 = 'C1391',
  /** (13.92) Manufacture Of Made-Up Textile Articles, Except Apparel */
  C1392 = 'C1392',
  /** (13.93) Manufacture Of Carpets And Rugs */
  C1393 = 'C1393',
  /** (13.94) Manufacture Of Cordage, Rope, Twine And Netting */
  C1394 = 'C1394',
  /** (13.95) Manufacture Of Non-Wovens And Articles Made From Non-Wovens, Except Apparel */
  C1395 = 'C1395',
  /** (13.96) Manufacture Of Other Technical And Industrial Textiles */
  C1396 = 'C1396',
  /** (13.99) Manufacture Of Other Textiles N.E.C. */
  C1399 = 'C1399',
  /** (14.11) Manufacture Of Leather Clothes */
  C1411 = 'C1411',
  /** (14.12) Manufacture Of Workwear */
  C1412 = 'C1412',
  /** (14.13) Manufacture Of Other Outerwear */
  C1413 = 'C1413',
  /** (14.14) Manufacture Of Underwear */
  C1414 = 'C1414',
  /** (14.19) Manufacture Of Other Wearing Apparel And Accessories */
  C1419 = 'C1419',
  /** (14.20) Manufacture Of Articles Of Fur */
  C1420 = 'C1420',
  /** (14.31) Manufacture Of Knitted And Crocheted Hosiery */
  C1431 = 'C1431',
  /** (14.39) Manufacture Of Other Knitted And Crocheted Apparel */
  C1439 = 'C1439',
  /** (15.11) Tanning And Dressing Of Leather; Dressing And Dyeing Of Fur */
  C1511 = 'C1511',
  /** (15.12) Manufacture Of Luggage, Handbags And The Like, Saddlery And Harness */
  C1512 = 'C1512',
  /** (15.20) Manufacture Of Footwear */
  C1520 = 'C1520',
  /** (16.10) Sawmilling And Planing Of Wood */
  C1610 = 'C1610',
  /** (16.21) Manufacture Of Veneer Sheets And Wood-Based Panels */
  C1621 = 'C1621',
  /** (16.22) Manufacture Of Assembled Parquet Floors */
  C1622 = 'C1622',
  /** (16.23) Manufacture Of Other Builders' Carpentry And Joinery */
  C1623 = 'C1623',
  /** (16.24) Manufacture Of Wooden Containers */
  C1624 = 'C1624',
  /** (16.29) Manufacture Of Other Products Of Wood; Manufacture Of Articles Of Cork, Straw And Plaiting Materials */
  C1629 = 'C1629',
  /** (17.11) Manufacture Of Pulp */
  C1711 = 'C1711',
  /** (17.12) Manufacture Of Paper And Paperboard */
  C1712 = 'C1712',
  /** (17.21) Manufacture Of Corrugated Paper And Paperboard And Of Containers Of Paper And Paperboard */
  C1721 = 'C1721',
  /** (17.22) Manufacture Of Household And Sanitary Goods And Of Toilet Requisites */
  C1722 = 'C1722',
  /** (17.23) Manufacture Of Paper Stationery */
  C1723 = 'C1723',
  /** (17.24) Manufacture Of Wallpaper */
  C1724 = 'C1724',
  /** (17.29) Manufacture Of Other Articles Of Paper And Paperboard */
  C1729 = 'C1729',
  /** (18.11) Printing Of Newspapers */
  C1811 = 'C1811',
  /** (18.12) Other Printing */
  C1812 = 'C1812',
  /** (18.13) Pre-Press And Pre-Media Services */
  C1813 = 'C1813',
  /** (18.14) Binding And Related Services */
  C1814 = 'C1814',
  /** (18.20) Reproduction Of Recorded Media */
  C1820 = 'C1820',
  /** (19.10) Manufacture Of Coke Oven Products */
  C1910 = 'C1910',
  /** (19.20) Manufacture Of Refined Petroleum Products */
  C1920 = 'C1920',
  /** (20.11) Manufacture Of Industrial Gases */
  C2011 = 'C2011',
  /** (20.12) Manufacture Of Dyes And Pigments */
  C2012 = 'C2012',
  /** (20.13) Manufacture Of Other Inorganic Basic Chemicals */
  C2013 = 'C2013',
  /** (20.14) Manufacture Of Other Organic Basic Chemicals */
  C2014 = 'C2014',
  /** (20.15) Manufacture Of Fertilisers And Nitrogen Compounds */
  C2015 = 'C2015',
  /** (20.16) Manufacture Of Plastics In Primary Forms */
  C2016 = 'C2016',
  /** (20.17) Manufacture Of Synthetic Rubber In Primary Forms */
  C2017 = 'C2017',
  /** (20.20) Manufacture Of Pesticides And Other Agrochemical Products */
  C2020 = 'C2020',
  /** (20.30) Manufacture Of Paints, Varnishes And Similar Coatings, Printing Ink And Mastics */
  C2030 = 'C2030',
  /** (20.41) Manufacture Of Soap And Detergents, Cleaning And Polishing Preparations */
  C2041 = 'C2041',
  /** (20.42) Manufacture Of Perfumes And Toilet Preparations */
  C2042 = 'C2042',
  /** (20.51) Manufacture Of Explosives */
  C2051 = 'C2051',
  /** (20.52) Manufacture Of Glues */
  C2052 = 'C2052',
  /** (20.53) Manufacture Of Essential Oils */
  C2053 = 'C2053',
  /** (20.59) Manufacture Of Other Chemical Products N.E.C. */
  C2059 = 'C2059',
  /** (20.60) Manufacture Of Man-Made Fibres */
  C2060 = 'C2060',
  /** (21.10) Manufacture Of Basic Pharmaceutical Products */
  C2110 = 'C2110',
  /** (21.20) Manufacture Of Pharmaceutical Preparations */
  C2120 = 'C2120',
  /** (22.11) Manufacture Of Rubber Tyres And Tubes; Retreading And Rebuilding Of Rubber Tyres */
  C2211 = 'C2211',
  /** (22.19) Manufacture Of Other Rubber Products */
  C2219 = 'C2219',
  /** (22.21) Manufacture Of Plastic Plates, Sheets, Tubes And Profiles */
  C2221 = 'C2221',
  /** (22.22) Manufacture Of Plastic Packing Goods */
  C2222 = 'C2222',
  /** (22.23) Manufacture Of Builders’ Ware Of Plastic */
  C2223 = 'C2223',
  /** (22.29) Manufacture Of Other Plastic Products */
  C2229 = 'C2229',
  /** (23.11) Manufacture Of Flat Glass */
  C2311 = 'C2311',
  /** (23.12) Shaping And Processing Of Flat Glass */
  C2312 = 'C2312',
  /** (23.13) Manufacture Of Hollow Glass */
  C2313 = 'C2313',
  /** (23.14) Manufacture Of Glass Fibres */
  C2314 = 'C2314',
  /** (23.19) Manufacture And Processing Of Other Glass, Including Technical Glassware */
  C2319 = 'C2319',
  /** (23.20) Manufacture Of Refractory Products */
  C2320 = 'C2320',
  /** (23.31) Manufacture Of Ceramic Tiles And Flags */
  C2331 = 'C2331',
  /** (23.32) Manufacture Of Bricks, Tiles And Construction Products, In Baked Clay */
  C2332 = 'C2332',
  /** (23.41) Manufacture Of Ceramic Household And Ornamental Articles */
  C2341 = 'C2341',
  /** (23.42) Manufacture Of Ceramic Sanitary Fixtures */
  C2342 = 'C2342',
  /** (23.43) Manufacture Of Ceramic Insulators And Insulating Fittings */
  C2343 = 'C2343',
  /** (23.44) Manufacture Of Other Technical Ceramic Products */
  C2344 = 'C2344',
  /** (23.49) Manufacture Of Other Ceramic Products */
  C2349 = 'C2349',
  /** (23.51) Manufacture Of Cement */
  C2351 = 'C2351',
  /** (23.52) Manufacture Of Lime And Plaster */
  C2352 = 'C2352',
  /** (23.61) Manufacture Of Concrete Products For Construction Purposes */
  C2361 = 'C2361',
  /** (23.62) Manufacture Of Plaster Products For Construction Purposes */
  C2362 = 'C2362',
  /** (23.63) Manufacture Of Ready-Mixed Concrete */
  C2363 = 'C2363',
  /** (23.64) Manufacture Of Mortars */
  C2364 = 'C2364',
  /** (23.65) Manufacture Of Fibre Cement */
  C2365 = 'C2365',
  /** (23.69) Manufacture Of Other Articles Of Concrete, Plaster And Cement */
  C2369 = 'C2369',
  /** (23.70) Cutting, Shaping And Finishing Of Stone */
  C2370 = 'C2370',
  /** (23.91) Production Of Abrasive Products */
  C2391 = 'C2391',
  /** (23.99) Manufacture Of Other Non-Metallic Mineral Products N.E.C. */
  C2399 = 'C2399',
  /** (24.10) Manufacture Of Basic Iron And Steel And Of Ferro-Alloys */
  C2410 = 'C2410',
  /** (24.20) Manufacture Of Tubes, Pipes, Hollow Profiles And Related Fittings, Of Steel */
  C2420 = 'C2420',
  /** (24.31) Cold Drawing Of Bars */
  C2431 = 'C2431',
  /** (24.32) Cold Rolling Of Narrow Strip */
  C2432 = 'C2432',
  /** (24.33) Cold Forming Or Folding */
  C2433 = 'C2433',
  /** (24.34) Cold Drawing Of Wire */
  C2434 = 'C2434',
  /** (24.41) Precious Metals Production */
  C2441 = 'C2441',
  /** (24.42) Aluminium Production */
  C2442 = 'C2442',
  /** (24.43) Lead, Zinc And Tin Production */
  C2443 = 'C2443',
  /** (24.44) Copper Production */
  C2444 = 'C2444',
  /** (24.45) Other Non-Ferrous Metal Production */
  C2445 = 'C2445',
  /** (24.46) Processing Of Nuclear Fuel */
  C2446 = 'C2446',
  /** (24.51) Casting Of Iron */
  C2451 = 'C2451',
  /** (24.52) Casting Of Steel */
  C2452 = 'C2452',
  /** (24.53) Casting Of Light Metals */
  C2453 = 'C2453',
  /** (24.54) Casting Of Other Non-Ferrous Metals */
  C2454 = 'C2454',
  /** (25.11) Manufacture Of Metal Structures And Parts Of Structures */
  C2511 = 'C2511',
  /** (25.12) Manufacture Of Doors And Windows Of Metal */
  C2512 = 'C2512',
  /** (25.21) Manufacture Of Central Heating Radiators And Boilers */
  C2521 = 'C2521',
  /** (25.29) Manufacture Of Other Tanks, Reservoirs And Containers Of Metal */
  C2529 = 'C2529',
  /** (25.30) Manufacture Of Steam Generators, Except Central Heating Hot Water Boilers */
  C2530 = 'C2530',
  /** (25.40) Manufacture Of Weapons And Ammunition */
  C2540 = 'C2540',
  /** (25.50) Forging, Pressing, Stamping And Roll-Forming Of Metal; Powder Metallurgy */
  C2550 = 'C2550',
  /** (25.61) Treatment And Coating Of Metals */
  C2561 = 'C2561',
  /** (25.62) Machining */
  C2562 = 'C2562',
  /** (25.71) Manufacture Of Cutlery */
  C2571 = 'C2571',
  /** (25.72) Manufacture Of Locks And Hinges */
  C2572 = 'C2572',
  /** (25.73) Manufacture Of Tools */
  C2573 = 'C2573',
  /** (25.91) Manufacture Of Steel Drums And Similar Containers */
  C2591 = 'C2591',
  /** (25.92) Manufacture Of Light Metal Packaging */
  C2592 = 'C2592',
  /** (25.93) Manufacture Of Wire Products, Chain And Springs */
  C2593 = 'C2593',
  /** (25.94) Manufacture Of Fasteners And Screw Machine Products */
  C2594 = 'C2594',
  /** (25.99) Manufacture Of Other Fabricated Metal Products N.E.C. */
  C2599 = 'C2599',
  /** (26.11) Manufacture Of Electronic Components */
  C2611 = 'C2611',
  /** (26.12) Manufacture Of Loaded Electronic Boards */
  C2612 = 'C2612',
  /** (26.20) Manufacture Of Computers And Peripheral Equipment */
  C2620 = 'C2620',
  /** (26.30) Manufacture Of Communication Equipment */
  C2630 = 'C2630',
  /** (26.40) Manufacture Of Consumer Electronics */
  C2640 = 'C2640',
  /** (26.51) Manufacture Of Instruments And Appliances For Measuring, Testing And Navigation */
  C2651 = 'C2651',
  /** (26.52) Manufacture Of Watches And Clocks */
  C2652 = 'C2652',
  /** (26.60) Manufacture Of Irradiation, Electromedical And Electrotherapeutic Equipment */
  C2660 = 'C2660',
  /** (26.70) Manufacture Of Optical Instruments And Photographic Equipment */
  C2670 = 'C2670',
  /** (26.80) Manufacture Of Magnetic And Optical Media */
  C2680 = 'C2680',
  /** (27.11) Manufacture Of Electric Motors, Generators And Transformers */
  C2711 = 'C2711',
  /** (27.12) Manufacture Of Electricity Distribution And Control Apparatus */
  C2712 = 'C2712',
  /** (27.20) Manufacture Of Batteries And Accumulators */
  C2720 = 'C2720',
  /** (27.31) Manufacture Of Fibre Optic Cables */
  C2731 = 'C2731',
  /** (27.32) Manufacture Of Other Electronic And Electric Wires And Cables */
  C2732 = 'C2732',
  /** (27.33) Manufacture Of Wiring Devices */
  C2733 = 'C2733',
  /** (27.40) Manufacture Of Electric Lighting Equipment */
  C2740 = 'C2740',
  /** (27.51) Manufacture Of Electric Domestic Appliances */
  C2751 = 'C2751',
  /** (27.52) Manufacture Of Non-Electric Domestic Appliances */
  C2752 = 'C2752',
  /** (27.90) Manufacture Of Other Electrical Equipment */
  C2790 = 'C2790',
  /** (28.11) Manufacture Of Engines And Turbines, Except Aircraft, Vehicle And Cycle Engines */
  C2811 = 'C2811',
  /** (28.12) Manufacture Of Fluid Power Equipment */
  C2812 = 'C2812',
  /** (28.13) Manufacture Of Other Pumps And Compressors */
  C2813 = 'C2813',
  /** (28.14) Manufacture Of Other Taps And Valves */
  C2814 = 'C2814',
  /** (28.15) Manufacture Of Bearings, Gears, Gearing And Driving Elements */
  C2815 = 'C2815',
  /** (28.21) Manufacture Of Ovens, Furnaces And Furnace Burners */
  C2821 = 'C2821',
  /** (28.22) Manufacture Of Lifting And Handling Equipment */
  C2822 = 'C2822',
  /** (28.23) Manufacture Of Office Machinery And Equipment (Except Computers And Peripheral Equipment) */
  C2823 = 'C2823',
  /** (28.24) Manufacture Of Power-Driven Hand Tools */
  C2824 = 'C2824',
  /** (28.25) Manufacture Of Non-Domestic Cooling And Ventilation Equipment */
  C2825 = 'C2825',
  /** (28.29) Manufacture Of Other General-Purpose Machinery N.E.C. */
  C2829 = 'C2829',
  /** (28.30) Manufacture Of Agricultural And Forestry Machinery */
  C2830 = 'C2830',
  /** (28.41) Manufacture Of Metal Forming Machinery */
  C2841 = 'C2841',
  /** (28.49) Manufacture Of Other Machine Tools */
  C2849 = 'C2849',
  /** (28.91) Manufacture Of Machinery For Metallurgy */
  C2891 = 'C2891',
  /** (28.92) Manufacture Of Machinery For Mining, Quarrying And Construction */
  C2892 = 'C2892',
  /** (28.93) Manufacture Of Machinery For Food, Beverage And Tobacco Processing */
  C2893 = 'C2893',
  /** (28.94) Manufacture Of Machinery For Textile, Apparel And Leather Production */
  C2894 = 'C2894',
  /** (28.95) Manufacture Of Machinery For Paper And Paperboard Production */
  C2895 = 'C2895',
  /** (28.96) Manufacture Of Plastics And Rubber Machinery */
  C2896 = 'C2896',
  /** (28.99) Manufacture Of Other Special-Purpose Machinery N.E.C. */
  C2899 = 'C2899',
  /** (29.10) Manufacture Of Motor Vehicles */
  C2910 = 'C2910',
  /** (29.20) Manufacture Of Bodies (Coachwork) For Motor Vehicles; Manufacture Of Trailers And Semi-Trailers */
  C2920 = 'C2920',
  /** (29.31) Manufacture Of Electrical And Electronic Equipment For Motor Vehicles */
  C2931 = 'C2931',
  /** (29.32) Manufacture Of Other Parts And Accessories For Motor Vehicles */
  C2932 = 'C2932',
  /** (30.11) Building Of Ships And Floating Structures */
  C3011 = 'C3011',
  /** (30.12) Building Of Pleasure And Sporting Boats */
  C3012 = 'C3012',
  /** (30.20) Manufacture Of Railway Locomotives And Rolling Stock */
  C3020 = 'C3020',
  /** (30.30) Manufacture Of Air And Spacecraft And Related Machinery */
  C3030 = 'C3030',
  /** (30.40) Manufacture Of Military Fighting Vehicles */
  C3040 = 'C3040',
  /** (30.91) Manufacture Of Motorcycles */
  C3091 = 'C3091',
  /** (30.92) Manufacture Of Bicycles And Invalid Carriages */
  C3092 = 'C3092',
  /** (30.99) Manufacture Of Other Transport Equipment N.E.C. */
  C3099 = 'C3099',
  /** (31.01) Manufacture Of Office And Shop Furniture */
  C3101 = 'C3101',
  /** (31.02) Manufacture Of Kitchen Furniture */
  C3102 = 'C3102',
  /** (31.03) Manufacture Of Mattresses */
  C3103 = 'C3103',
  /** (31.09) Manufacture Of Other Furniture */
  C3109 = 'C3109',
  /** (32.11) Striking Of Coins */
  C3211 = 'C3211',
  /** (32.12) Manufacture Of Jewellery And Related Articles */
  C3212 = 'C3212',
  /** (32.13) Manufacture Of Imitation Jewellery And Related Articles */
  C3213 = 'C3213',
  /** (32.20) Manufacture Of Musical Instruments */
  C3220 = 'C3220',
  /** (32.30) Manufacture Of Sports Goods */
  C3230 = 'C3230',
  /** (32.40) Manufacture Of Games And Toys */
  C3240 = 'C3240',
  /** (32.50) Manufacture Of Medical And Dental Instruments And Supplies */
  C3250 = 'C3250',
  /** (32.91) Manufacture Of Brooms And Brushes */
  C3291 = 'C3291',
  /** (32.99) Other Manufacturing N.E.C. */
  C3299 = 'C3299',
  /** (33.11) Repair Of Fabricated Metal Products */
  C3311 = 'C3311',
  /** (33.12) Repair Of Machinery */
  C3312 = 'C3312',
  /** (33.13) Repair Of Electronic And Optical Equipment */
  C3313 = 'C3313',
  /** (33.14) Repair Of Electrical Equipment */
  C3314 = 'C3314',
  /** (33.15) Repair And Maintenance Of Ships And Boats */
  C3315 = 'C3315',
  /** (33.16) Repair And Maintenance Of Aircraft And Spacecraft */
  C3316 = 'C3316',
  /** (33.17) Repair And Maintenance Of Other Transport Equipment */
  C3317 = 'C3317',
  /** (33.19) Repair Of Other Equipment */
  C3319 = 'C3319',
  /** (33.20) Installation Of Industrial Machinery And Equipment */
  C3320 = 'C3320',
  /** (D) Electricity, Gas, Steam And Air Conditioning Supply */
  D = 'D',
  /** (35) Electricity, Gas, Steam And Air Conditioning Supply */
  D35 = 'D35',
  /** (35.1) Electric Power Generation, Transmission And Distribution */
  D351 = 'D351',
  /** (35.2) Manufacture Of Gas; Distribution Of Gaseous Fuels Through Mains */
  D352 = 'D352',
  /** (35.3) Steam And Air Conditioning Supply */
  D353 = 'D353',
  /** (35.11) Production Of Electricity */
  D3511 = 'D3511',
  /** (35.12) Transmission Of Electricity */
  D3512 = 'D3512',
  /** (35.13) Distribution Of Electricity */
  D3513 = 'D3513',
  /** (35.14) Trade Of Electricity */
  D3514 = 'D3514',
  /** (35.21) Manufacture Of Gas */
  D3521 = 'D3521',
  /** (35.22) Distribution Of Gaseous Fuels Through Mains */
  D3522 = 'D3522',
  /** (35.23) Trade Of Gas Through Mains */
  D3523 = 'D3523',
  /** (35.30) Steam And Air Conditioning Supply */
  D3530 = 'D3530',
  /** (E) Water Supply; Sewerage; Waste Managment And Remediation Activities */
  E = 'E',
  /** (36) Water Collection, Treatment And Supply */
  E36 = 'E36',
  /** (37) Sewerage */
  E37 = 'E37',
  /** (38) Waste Collection, Treatment And Disposal Activities; Materials Recovery */
  E38 = 'E38',
  /** (39) Remediation Activities And Other Waste Management Services */
  E39 = 'E39',
  /** (36.0) Water Collection, Treatment And Supply */
  E360 = 'E360',
  /** (37.0) Sewerage */
  E370 = 'E370',
  /** (38.1) Waste Collection */
  E381 = 'E381',
  /** (38.2) Waste Treatment And Disposal */
  E382 = 'E382',
  /** (38.3) Materials Recovery */
  E383 = 'E383',
  /** (39.0) Remediation Activities And Other Waste Management Services */
  E390 = 'E390',
  /** (36.00) Water Collection, Treatment And Supply */
  E3600 = 'E3600',
  /** (37.00) Sewerage */
  E3700 = 'E3700',
  /** (38.11) Collection Of Non-Hazardous Waste */
  E3811 = 'E3811',
  /** (38.12) Collection Of Hazardous Waste */
  E3812 = 'E3812',
  /** (38.21) Treatment And Disposal Of Non-Hazardous Waste */
  E3821 = 'E3821',
  /** (38.22) Treatment And Disposal Of Hazardous Waste */
  E3822 = 'E3822',
  /** (38.31) Dismantling Of Wrecks */
  E3831 = 'E3831',
  /** (38.32) Recovery Of Sorted Materials */
  E3832 = 'E3832',
  /** (39.00) Remediation Activities And Other Waste Management Services */
  E3900 = 'E3900',
  /** (F) Construction */
  F = 'F',
  /** (41) Construction Of Buildings */
  F41 = 'F41',
  /** (42) Civil Engineering */
  F42 = 'F42',
  /** (43) Specialised Construction Activities */
  F43 = 'F43',
  /** (41.1) Development Of Building Projects */
  F411 = 'F411',
  /** (41.2) Construction Of Residential And Non-Residential Buildings */
  F412 = 'F412',
  /** (42.1) Construction Of Roads And Railways */
  F421 = 'F421',
  /** (42.2) Construction Of Utility Projects */
  F422 = 'F422',
  /** (42.9) Construction Of Other Civil Engineering Projects */
  F429 = 'F429',
  /** (43.1) Demolition And Site Preparation */
  F431 = 'F431',
  /** (43.2) Electrical, Plumbing And Other Construction Installation Activities */
  F432 = 'F432',
  /** (43.3) Building Completion And Finishing */
  F433 = 'F433',
  /** (43.9) Other Specialised Construction Activities */
  F439 = 'F439',
  /** (41.10) Development Of Building Projects */
  F4110 = 'F4110',
  /** (41.20) Construction Of Residential And Non-Residential Buildings */
  F4120 = 'F4120',
  /** (42.11) Construction Of Roads And Motorways */
  F4211 = 'F4211',
  /** (42.12) Construction Of Railways And Underground Railways */
  F4212 = 'F4212',
  /** (42.13) Construction Of Bridges And Tunnels */
  F4213 = 'F4213',
  /** (42.21) Construction Of Utility Projects For Fluids */
  F4221 = 'F4221',
  /** (42.22) Construction Of Utility Projects For Electricity And Telecommunications */
  F4222 = 'F4222',
  /** (42.91) Construction Of Water Projects */
  F4291 = 'F4291',
  /** (42.99) Construction Of Other Civil Engineering Projects N.E.C. */
  F4299 = 'F4299',
  /** (43.11) Demolition */
  F4311 = 'F4311',
  /** (43.12) Site Preparation */
  F4312 = 'F4312',
  /** (43.13) Test Drilling And Boring */
  F4313 = 'F4313',
  /** (43.21) Electrical Installation */
  F4321 = 'F4321',
  /** (43.22) Plumbing, Heat And Air-Conditioning Installation */
  F4322 = 'F4322',
  /** (43.29) Other Construction Installation */
  F4329 = 'F4329',
  /** (43.31) Plastering */
  F4331 = 'F4331',
  /** (43.32) Joinery Installation */
  F4332 = 'F4332',
  /** (43.33) Floor And Wall Covering */
  F4333 = 'F4333',
  /** (43.34) Painting And Glazing */
  F4334 = 'F4334',
  /** (43.39) Other Building Completion And Finishing */
  F4339 = 'F4339',
  /** (43.91) Roofing Activities */
  F4391 = 'F4391',
  /** (43.99) Other Specialised Construction Activities N.E.C. */
  F4399 = 'F4399',
  /** (G) Wholesale And Retail Trade; Repair Of Motor Vehicles And Motorcycles */
  G = 'G',
  /** (45) Wholesale And Retail Trade And Repair Of Motor Vehicles And Motorcycles */
  G45 = 'G45',
  /** (46) Wholesale Trade, Except Of Motor Vehicles And Motorcycles */
  G46 = 'G46',
  /** (47) Retail Trade, Except Of Motor Vehicles And Motorcycles */
  G47 = 'G47',
  /** (45.1) Sale Of Motor Vehicles */
  G451 = 'G451',
  /** (45.2) Maintenance And Repair Of Motor Vehicles */
  G452 = 'G452',
  /** (45.3) Sale Of Motor Vehicle Parts And Accessories */
  G453 = 'G453',
  /** (45.4) Sale, Maintenance And Repair Of Motorcycles And Related Parts And Accessories */
  G454 = 'G454',
  /** (46.1) Wholesale On A Fee Or Contract Basis */
  G461 = 'G461',
  /** (46.2) Wholesale Of Agricultural Raw Materials And Live Animals */
  G462 = 'G462',
  /** (46.3) Wholesale Of Food, Beverages And Tobacco */
  G463 = 'G463',
  /** (46.4) Wholesale Of Household Goods */
  G464 = 'G464',
  /** (46.5) Wholesale Of Information And Communication Equipment */
  G465 = 'G465',
  /** (46.6) Wholesale Of Other Machinery, Equipment And Supplies */
  G466 = 'G466',
  /** (46.7) Other Specialised Wholesale */
  G467 = 'G467',
  /** (46.9) Non-Specialised Wholesale Trade */
  G469 = 'G469',
  /** (47.1) Retail Sale In Non-Specialised Stores */
  G471 = 'G471',
  /** (47.2) Retail Sale Of Food, Beverages And Tobacco In Specialised Stores */
  G472 = 'G472',
  /** (47.3) Retail Sale Of Automotive Fuel In Specialised Stores */
  G473 = 'G473',
  /** (47.4) Retail Sale Of Information And Communication Equipment In Specialised Stores */
  G474 = 'G474',
  /** (47.5) Retail Sale Of Other Household Equipment In Specialised Stores */
  G475 = 'G475',
  /** (47.6) Retail Sale Of Cultural And Recreation Goods In Specialised Stores */
  G476 = 'G476',
  /** (47.7) Retail Sale Of Other Goods In Specialised Stores */
  G477 = 'G477',
  /** (47.8) Retail Sale Via Stalls And Markets */
  G478 = 'G478',
  /** (47.9) Retail Trade Not In Stores, Stalls Or Markets */
  G479 = 'G479',
  /** (45.11) Sale Of Cars And Light Motor Vehicles */
  G4511 = 'G4511',
  /** (45.19) Sale Of Other Motor Vehicles */
  G4519 = 'G4519',
  /** (45.20) Maintenance And Repair Of Motor Vehicles */
  G4520 = 'G4520',
  /** (45.31) Wholesale Trade Of Motor Vehicle Parts And Accessories */
  G4531 = 'G4531',
  /** (45.32) Retail Trade Of Motor Vehicle Parts And Accessories */
  G4532 = 'G4532',
  /** (45.40) Sale, Maintenance And Repair Of Motorcycles And Related Parts And Accessories */
  G4540 = 'G4540',
  /** (46.11) Agents Involved In The Sale Of Agricultural Raw Materials, Live Animals, Textile Raw Materials And Semi-Finished Goods */
  G4611 = 'G4611',
  /** (46.12) Agents Involved In The Sale Of Fuels, Ores, Metals And Industrial Chemicals */
  G4612 = 'G4612',
  /** (46.13) Agents Involved In The Sale Of Timber And Building Materials */
  G4613 = 'G4613',
  /** (46.14) Agents Involved In The Sale Of Machinery, Industrial Equipment, Ships And Aircraft */
  G4614 = 'G4614',
  /** (46.15) Agents Involved In The Sale Of Furniture, Household Goods, Hardware And Ironmongery */
  G4615 = 'G4615',
  /** (46.16) Agents Involved In The Sale Of Textiles, Clothing, Fur, Footwear And Leather Goods */
  G4616 = 'G4616',
  /** (46.17) Agents Involved In The Sale Of Food, Beverages And Tobacco */
  G4617 = 'G4617',
  /** (46.18) Agents Specialised In The Sale Of Other Particular Products */
  G4618 = 'G4618',
  /** (46.19) Agents Involved In The Sale Of A Variety Of Goods */
  G4619 = 'G4619',
  /** (46.21) Wholesale Of Grain, Unmanufactured Tobacco, Seeds And Animal Feeds */
  G4621 = 'G4621',
  /** (46.22) Wholesale Of Flowers And Plants */
  G4622 = 'G4622',
  /** (46.23) Wholesale Of Live Animals */
  G4623 = 'G4623',
  /** (46.24) Wholesale Of Hides, Skins And Leather */
  G4624 = 'G4624',
  /** (46.31) Wholesale Of Fruit And Vegetables */
  G4631 = 'G4631',
  /** (46.32) Wholesale Of Meat And Meat Products */
  G4632 = 'G4632',
  /** (46.33) Wholesale Of Dairy Products, Eggs And Edible Oils And Fats */
  G4633 = 'G4633',
  /** (46.34) Wholesale Of Beverages */
  G4634 = 'G4634',
  /** (46.35) Wholesale Of Tobacco Products */
  G4635 = 'G4635',
  /** (46.36) Wholesale Of Sugar And Chocolate And Sugar Confectionery */
  G4636 = 'G4636',
  /** (46.37) Wholesale Of Coffee, Tea, Cocoa And Spices */
  G4637 = 'G4637',
  /** (46.38) Wholesale Of Other Food, Including Fish, Crustaceans And Molluscs */
  G4638 = 'G4638',
  /** (46.39) Non-Specialised Wholesale Of Food, Beverages And Tobacco */
  G4639 = 'G4639',
  /** (46.41) Wholesale Of Textiles */
  G4641 = 'G4641',
  /** (46.42) Wholesale Of Clothing And Footwear */
  G4642 = 'G4642',
  /** (46.43) Wholesale Of Electrical Household Appliances */
  G4643 = 'G4643',
  /** (46.44) Wholesale Of China And Glassware And Cleaning Materials */
  G4644 = 'G4644',
  /** (46.45) Wholesale Of Perfume And Cosmetics */
  G4645 = 'G4645',
  /** (46.46) Wholesale Of Pharmaceutical Goods */
  G4646 = 'G4646',
  /** (46.47) Wholesale Of Furniture, Carpets And Lighting Equipment */
  G4647 = 'G4647',
  /** (46.48) Wholesale Of Watches And Jewellery */
  G4648 = 'G4648',
  /** (46.49) Wholesale Of Other Household Goods */
  G4649 = 'G4649',
  /** (46.51) Wholesale Of Computers, Computer Peripheral Equipment And Software */
  G4651 = 'G4651',
  /** (46.52) Wholesale Of Electronic And Telecommunications Equipment And Parts */
  G4652 = 'G4652',
  /** (46.61) Wholesale Of Agricultural Machinery, Equipment And Supplies */
  G4661 = 'G4661',
  /** (46.62) Wholesale Of Machine Tools */
  G4662 = 'G4662',
  /** (46.63) Wholesale Of Mining, Construction And Civil Engineering Machinery */
  G4663 = 'G4663',
  /** (46.64) Wholesale Of Machinery For The Textile Industry And Of Sewing And Knitting Machines */
  G4664 = 'G4664',
  /** (46.65) Wholesale Of Office Furniture */
  G4665 = 'G4665',
  /** (46.66) Wholesale Of Other Office Machinery And Equipment */
  G4666 = 'G4666',
  /** (46.69) Wholesale Of Other Machinery And Equipment */
  G4669 = 'G4669',
  /** (46.71) Wholesale Of Solid, Liquid And Gaseous Fuels And Related Products */
  G4671 = 'G4671',
  /** (46.72) Wholesale Of Metals And Metal Ores */
  G4672 = 'G4672',
  /** (46.73) Wholesale Of Wood, Construction Materials And Sanitary Equipment */
  G4673 = 'G4673',
  /** (46.74) Wholesale Of Hardware, Plumbing And Heating Equipment And Supplies */
  G4674 = 'G4674',
  /** (46.75) Wholesale Of Chemical Products */
  G4675 = 'G4675',
  /** (46.76) Wholesale Of Other Intermediate Products */
  G4676 = 'G4676',
  /** (46.77) Wholesale Of Waste And Scrap */
  G4677 = 'G4677',
  /** (46.90) Non-Specialised Wholesale Trade */
  G4690 = 'G4690',
  /** (47.11) Retail Sale In Non-Specialised Stores With Food, Beverages Or Tobacco Predominating */
  G4711 = 'G4711',
  /** (47.19) Other Retail Sale In Non-Specialised Stores */
  G4719 = 'G4719',
  /** (47.21) Retail Sale Of Fruit And Vegetables In Specialised Stores */
  G4721 = 'G4721',
  /** (47.22) Retail Sale Of Meat And Meat Products In Specialised Stores */
  G4722 = 'G4722',
  /** (47.23) Retail Sale Of Fish, Crustaceans And Molluscs In Specialised Stores */
  G4723 = 'G4723',
  /** (47.24) Retail Sale Of Bread, Cakes, Flour Confectionery And Sugar Confectionery In Specialised Stores */
  G4724 = 'G4724',
  /** (47.25) Retail Sale Of Beverages In Specialised Stores */
  G4725 = 'G4725',
  /** (47.26) Retail Sale Of Tobacco Products In Specialised Stores */
  G4726 = 'G4726',
  /** (47.29) Other Retail Sale Of Food In Specialised Stores */
  G4729 = 'G4729',
  /** (47.30) Retail Sale Of Automotive Fuel In Specialised Stores */
  G4730 = 'G4730',
  /** (47.41) Retail Sale Of Computers, Peripheral Units And Software In Specialised Stores */
  G4741 = 'G4741',
  /** (47.42) Retail Sale Of Telecommunications Equipment In Specialised Stores */
  G4742 = 'G4742',
  /** (47.43) Retail Sale Of Audio And Video Equipment In Specialised Stores */
  G4743 = 'G4743',
  /** (47.51) Retail Sale Of Textiles In Specialised Stores */
  G4751 = 'G4751',
  /** (47.52) Retail Sale Of Hardware, Paints And Glass In Specialised Stores */
  G4752 = 'G4752',
  /** (47.53) Retail Sale Of Carpets, Rugs, Wall And Floor Coverings In Specialised Stores */
  G4753 = 'G4753',
  /** (47.54) Retail Sale Of Electrical Household Appliances In Specialised Stores */
  G4754 = 'G4754',
  /** (47.59) Retail Sale Of Furniture, Lighting Equipment And Other Household Articles In Specialised Stores */
  G4759 = 'G4759',
  /** (47.61) Retail Sale Of Books In Specialised Stores */
  G4761 = 'G4761',
  /** (47.62) Retail Sale Of Newspapers And Stationery In Specialised Stores */
  G4762 = 'G4762',
  /** (47.63) Retail Sale Of Music And Video Recordings In Specialised Stores */
  G4763 = 'G4763',
  /** (47.64) Retail Sale Of Sporting Equipment In Specialised Stores */
  G4764 = 'G4764',
  /** (47.65) Retail Sale Of Games And Toys In Specialised Stores */
  G4765 = 'G4765',
  /** (47.71) Retail Sale Of Clothing In Specialised Stores */
  G4771 = 'G4771',
  /** (47.72) Retail Sale Of Footwear And Leather Goods In Specialised Stores */
  G4772 = 'G4772',
  /** (47.73) Dispensing Chemist In Specialised Stores */
  G4773 = 'G4773',
  /** (47.74) Retail Sale Of Medical And Orthopaedic Goods In Specialised Stores */
  G4774 = 'G4774',
  /** (47.75) Retail Sale Of Cosmetic And Toilet Articles In Specialised Stores */
  G4775 = 'G4775',
  /** (47.76) Retail Sale Of Flowers, Plants, Seeds, Fertilisers, Pet Animals And Pet Food In Specialised Stores */
  G4776 = 'G4776',
  /** (47.77) Retail Sale Of Watches And Jewellery In Specialised Stores */
  G4777 = 'G4777',
  /** (47.78) Other Retail Sale Of New Goods In Specialised Stores */
  G4778 = 'G4778',
  /** (47.79) Retail Sale Of Second-Hand Goods In Stores */
  G4779 = 'G4779',
  /** (47.81) Retail Sale Via Stalls And Markets Of Food, Beverages And Tobacco Products */
  G4781 = 'G4781',
  /** (47.82) Retail Sale Via Stalls And Markets Of Textiles, Clothing And Footwear */
  G4782 = 'G4782',
  /** (47.89) Retail Sale Via Stalls And Markets Of Other Goods */
  G4789 = 'G4789',
  /** (47.91) Retail Sale Via Mail Order Houses Or Via Internet */
  G4791 = 'G4791',
  /** (47.99) Other Retail Sale Not In Stores, Stalls Or Markets */
  G4799 = 'G4799',
  /** (H) Transporting And Storage */
  H = 'H',
  /** (49) Land Transport And Transport Via Pipelines */
  H49 = 'H49',
  /** (50) Water Transport */
  H50 = 'H50',
  /** (51) Air Transport */
  H51 = 'H51',
  /** (52) Warehousing And Support Activities For Transportation */
  H52 = 'H52',
  /** (53) Postal And Courier Activities */
  H53 = 'H53',
  /** (49.1) Passenger Rail Transport, Interurban */
  H491 = 'H491',
  /** (49.2) Freight Rail Transport */
  H492 = 'H492',
  /** (49.3) Other Passenger Land Transport */
  H493 = 'H493',
  /** (49.4) Freight Transport By Road And Removal Services */
  H494 = 'H494',
  /** (49.5) Transport Via Pipeline */
  H495 = 'H495',
  /** (50.1) Sea And Coastal Passenger Water Transport */
  H501 = 'H501',
  /** (50.2) Sea And Coastal Freight Water Transport */
  H502 = 'H502',
  /** (50.3) Inland Passenger Water Transport */
  H503 = 'H503',
  /** (50.4) Inland Freight Water Transport */
  H504 = 'H504',
  /** (51.1) Passenger Air Transport */
  H511 = 'H511',
  /** (51.2) Freight Air Transport And Space Transport */
  H512 = 'H512',
  /** (52.1) Warehousing And Storage */
  H521 = 'H521',
  /** (52.2) Support Activities For Transportation */
  H522 = 'H522',
  /** (53.1) Postal Activities Under Universal Service Obligation */
  H531 = 'H531',
  /** (53.2) Other Postal And Courier Activities */
  H532 = 'H532',
  /** (49.10) Passenger Rail Transport, Interurban */
  H4910 = 'H4910',
  /** (49.20) Freight Rail Transport */
  H4920 = 'H4920',
  /** (49.31) Urban And Suburban Passenger Land Transport */
  H4931 = 'H4931',
  /** (49.32) Taxi Operation */
  H4932 = 'H4932',
  /** (49.39) Other Passenger Land Transport N.E.C. */
  H4939 = 'H4939',
  /** (49.41) Freight Transport By Road */
  H4941 = 'H4941',
  /** (49.42) Removal Services */
  H4942 = 'H4942',
  /** (49.50) Transport Via Pipeline */
  H4950 = 'H4950',
  /** (50.10) Sea And Coastal Passenger Water Transport */
  H5010 = 'H5010',
  /** (50.20) Sea And Coastal Freight Water Transport */
  H5020 = 'H5020',
  /** (50.30) Inland Passenger Water Transport */
  H5030 = 'H5030',
  /** (50.40) Inland Freight Water Transport */
  H5040 = 'H5040',
  /** (51.10) Passenger Air Transport */
  H5110 = 'H5110',
  /** (51.21) Freight Air Transport */
  H5121 = 'H5121',
  /** (51.22) Space Transport */
  H5122 = 'H5122',
  /** (52.10) Warehousing And Storage */
  H5210 = 'H5210',
  /** (52.21) Service Activities Incidental To Land Transportation */
  H5221 = 'H5221',
  /** (52.22) Service Activities Incidental To Water Transportation */
  H5222 = 'H5222',
  /** (52.23) Service Activities Incidental To Air Transportation */
  H5223 = 'H5223',
  /** (52.24) Cargo Handling */
  H5224 = 'H5224',
  /** (52.29) Other Transportation Support Activities */
  H5229 = 'H5229',
  /** (53.10) Postal Activities Under Universal Service Obligation */
  H5310 = 'H5310',
  /** (53.20) Other Postal And Courier Activities */
  H5320 = 'H5320',
  /** (I) Accommodation And Food Service Activities */
  I = 'I',
  /** (55) Accommodation */
  I55 = 'I55',
  /** (56) Food And Beverage Service Activities */
  I56 = 'I56',
  /** (55.1) Hotels And Similar Accommodation */
  I551 = 'I551',
  /** (55.2) Holiday And Other Short-Stay Accommodation */
  I552 = 'I552',
  /** (55.3) Camping Grounds, Recreational Vehicle Parks And Trailer Parks */
  I553 = 'I553',
  /** (55.9) Other Accommodation */
  I559 = 'I559',
  /** (56.1) Restaurants And Mobile Food Service Activities */
  I561 = 'I561',
  /** (56.2) Event Catering And Other Food Service Activities */
  I562 = 'I562',
  /** (56.3) Beverage Serving Activities */
  I563 = 'I563',
  /** (55.10) Hotels And Similar Accommodation */
  I5510 = 'I5510',
  /** (55.20) Holiday And Other Short-Stay Accommodation */
  I5520 = 'I5520',
  /** (55.30) Camping Grounds, Recreational Vehicle Parks And Trailer Parks */
  I5530 = 'I5530',
  /** (55.90) Other Accommodation */
  I5590 = 'I5590',
  /** (56.10) Restaurants And Mobile Food Service Activities */
  I5610 = 'I5610',
  /** (56.21) Event Catering Activities */
  I5621 = 'I5621',
  /** (56.29) Other Food Service Activities */
  I5629 = 'I5629',
  /** (56.30) Beverage Serving Activities */
  I5630 = 'I5630',
  /** (J) Information And Communication */
  J = 'J',
  /** (58) Publishing Activities */
  J58 = 'J58',
  /** (59) Motion Picture, Video And Television Programme Production, Sound Recording And Music Publishing Activities */
  J59 = 'J59',
  /** (60) Programming And Broadcasting Activities */
  J60 = 'J60',
  /** (61) Telecommunications */
  J61 = 'J61',
  /** (62) Computer Programming, Consultancy And Related Activities */
  J62 = 'J62',
  /** (63) Information Service Activities */
  J63 = 'J63',
  /** (58.1) Publishing Of Books, Periodicals And Other Publishing Activities */
  J581 = 'J581',
  /** (58.2) Software Publishing */
  J582 = 'J582',
  /** (59.1) Motion Picture, Video And Television Programme Activities */
  J591 = 'J591',
  /** (59.2) Sound Recording And Music Publishing Activities */
  J592 = 'J592',
  /** (60.1) Radio Broadcasting */
  J601 = 'J601',
  /** (60.2) Television Programming And Broadcasting Activities */
  J602 = 'J602',
  /** (61.1) Wired Telecommunications Activities */
  J611 = 'J611',
  /** (61.2) Wireless Telecommunications Activities */
  J612 = 'J612',
  /** (61.3) Satellite Telecommunications Activities */
  J613 = 'J613',
  /** (61.9) Other Telecommunications Activities */
  J619 = 'J619',
  /** (62.0) Computer Programming, Consultancy And Related Activities */
  J620 = 'J620',
  /** (63.1) Data Processing, Hosting And Related Activities; Web Portals */
  J631 = 'J631',
  /** (63.9) Other Information Service Activities */
  J639 = 'J639',
  /** (58.11) Book Publishing */
  J5811 = 'J5811',
  /** (58.12) Publishing Of Directories And Mailing Lists */
  J5812 = 'J5812',
  /** (58.13) Publishing Of Newspapers */
  J5813 = 'J5813',
  /** (58.14) Publishing Of Journals And Periodicals */
  J5814 = 'J5814',
  /** (58.19) Other Publishing Activities */
  J5819 = 'J5819',
  /** (58.21) Publishing Of Computer Games */
  J5821 = 'J5821',
  /** (58.29) Other Software Publishing */
  J5829 = 'J5829',
  /** (59.11) Motion Picture, Video And Television Programme Production Activities */
  J5911 = 'J5911',
  /** (59.12) Motion Picture, Video And Television Programme Post-Production Activities */
  J5912 = 'J5912',
  /** (59.13) Motion Picture, Video And Television Programme Distribution Activities */
  J5913 = 'J5913',
  /** (59.14) Motion Picture Projection Activities */
  J5914 = 'J5914',
  /** (59.20) Sound Recording And Music Publishing Activities */
  J5920 = 'J5920',
  /** (60.10) Radio Broadcasting */
  J6010 = 'J6010',
  /** (60.20) Television Programming And Broadcasting Activities */
  J6020 = 'J6020',
  /** (61.10) Wired Telecommunications Activities */
  J6110 = 'J6110',
  /** (61.20) Wireless Telecommunications Activities */
  J6120 = 'J6120',
  /** (61.30) Satellite Telecommunications Activities */
  J6130 = 'J6130',
  /** (61.90) Other Telecommunications Activities */
  J6190 = 'J6190',
  /** (62.01) Computer Programming Activities */
  J6201 = 'J6201',
  /** (62.02) Computer Consultancy Activities */
  J6202 = 'J6202',
  /** (62.03) Computer Facilities Management Activities */
  J6203 = 'J6203',
  /** (62.09) Other Information Technology And Computer Service Activities */
  J6209 = 'J6209',
  /** (63.11) Data Processing, Hosting And Related Activities */
  J6311 = 'J6311',
  /** (63.12) Web Portals */
  J6312 = 'J6312',
  /** (63.91) News Agency Activities */
  J6391 = 'J6391',
  /** (63.99) Other Information Service Activities N.E.C. */
  J6399 = 'J6399',
  /** (K) Financial And Insurance Activities */
  K = 'K',
  /** (64) Financial Service Activities, Except Insurance And Pension Funding */
  K64 = 'K64',
  /** (65) Insurance, Reinsurance And Pension Funding, Except Compulsory Social Security */
  K65 = 'K65',
  /** (66) Activities Auxiliary To Financial Services And Insurance Activities */
  K66 = 'K66',
  /** (64.1) Monetary Intermediation */
  K641 = 'K641',
  /** (64.2) Activities Of Holding Companies */
  K642 = 'K642',
  /** (64.3) Trusts, Funds And Similar Financial Entities */
  K643 = 'K643',
  /** (64.9) Other Financial Service Activities, Except Insurance And Pension Funding */
  K649 = 'K649',
  /** (65.1) Insurance */
  K651 = 'K651',
  /** (65.2) Reinsurance */
  K652 = 'K652',
  /** (65.3) Pension Funding */
  K653 = 'K653',
  /** (66.1) Activities Auxiliary To Financial Services, Except Insurance And Pension Funding */
  K661 = 'K661',
  /** (66.2) Activities Auxiliary To Insurance And Pension Funding */
  K662 = 'K662',
  /** (66.3) Fund Management Activities */
  K663 = 'K663',
  /** (64.11) Central Banking */
  K6411 = 'K6411',
  /** (64.19) Other Monetary Intermediation */
  K6419 = 'K6419',
  /** (64.20) Activities Of Holding Companies */
  K6420 = 'K6420',
  /** (64.30) Trusts, Funds And Similar Financial Entities */
  K6430 = 'K6430',
  /** (64.91) Financial Leasing */
  K6491 = 'K6491',
  /** (64.92) Other Credit Granting */
  K6492 = 'K6492',
  /** (64.99) Other Financial Service Activities, Except Insurance And Pension Funding N.E.C. */
  K6499 = 'K6499',
  /** (65.11) Life Insurance */
  K6511 = 'K6511',
  /** (65.12) Non-Life Insurance */
  K6512 = 'K6512',
  /** (65.20) Reinsurance */
  K6520 = 'K6520',
  /** (65.30) Pension Funding */
  K6530 = 'K6530',
  /** (66.11) Administration Of Financial Markets */
  K6611 = 'K6611',
  /** (66.12) Security And Commodity Contracts Brokerage */
  K6612 = 'K6612',
  /** (66.19) Other Activities Auxiliary To Financial Services, Except Insurance And Pension Funding */
  K6619 = 'K6619',
  /** (66.21) Risk And Damage Evaluation */
  K6621 = 'K6621',
  /** (66.22) Activities Of Insurance Agents And Brokers */
  K6622 = 'K6622',
  /** (66.29) Other Activities Auxiliary To Insurance And Pension Funding */
  K6629 = 'K6629',
  /** (66.30) Fund Management Activities */
  K6630 = 'K6630',
  /** (L) Real Estate Activities */
  L = 'L',
  /** (68) Real Estate Activities */
  L68 = 'L68',
  /** (68.1) Buying And Selling Of Own Real Estate */
  L681 = 'L681',
  /** (68.2) Renting And Operating Of Own Or Leased Real Estate */
  L682 = 'L682',
  /** (68.3) Real Estate Activities On A Fee Or Contract Basis */
  L683 = 'L683',
  /** (68.10) Buying And Selling Of Own Real Estate */
  L6810 = 'L6810',
  /** (68.20) Renting And Operating Of Own Or Leased Real Estate */
  L6820 = 'L6820',
  /** (68.31) Real Estate Agencies */
  L6831 = 'L6831',
  /** (68.32) Management Of Real Estate On A Fee Or Contract Basis */
  L6832 = 'L6832',
  /** (M) Professional, Scientific And Technical Activities */
  M = 'M',
  /** (69) Legal And Accounting Activities */
  M69 = 'M69',
  /** (70) Activities Of Head Offices; Management Consultancy Activities */
  M70 = 'M70',
  /** (71) Architectural And Engineering Activities; Technical Testing And Analysis */
  M71 = 'M71',
  /** (72) Scientific Research And Development */
  M72 = 'M72',
  /** (73) Advertising And Market Research */
  M73 = 'M73',
  /** (74) Other Professional, Scientific And Technical Activities */
  M74 = 'M74',
  /** (75) Veterinary Activities */
  M75 = 'M75',
  /** (69.1) Legal Activities */
  M691 = 'M691',
  /** (69.2) Accounting, Bookkeeping And Auditing Activities; Tax Consultancy */
  M692 = 'M692',
  /** (70.1) Activities Of Head Offices */
  M701 = 'M701',
  /** (70.2) Management Consultancy Activities */
  M702 = 'M702',
  /** (71.1) Architectural And Engineering Activities And Related Technical Consultancy */
  M711 = 'M711',
  /** (71.2) Technical Testing And Analysis */
  M712 = 'M712',
  /** (72.1) Research And Experimental Development On Natural Sciences And Engineering */
  M721 = 'M721',
  /** (72.2) Research And Experimental Development On Social Sciences And Humanities */
  M722 = 'M722',
  /** (73.1) Advertising */
  M731 = 'M731',
  /** (73.2) Market Research And Public Opinion Polling */
  M732 = 'M732',
  /** (74.1) Specialised Design Activities */
  M741 = 'M741',
  /** (74.2) Photographic Activities */
  M742 = 'M742',
  /** (74.3) Translation And Interpretation Activities */
  M743 = 'M743',
  /** (74.9) Other Professional, Scientific And Technical Activities N.E.C. */
  M749 = 'M749',
  /** (75.0) Veterinary Activities */
  M750 = 'M750',
  /** (69.10) Legal Activities */
  M6910 = 'M6910',
  /** (69.20) Accounting, Bookkeeping And Auditing Activities; Tax Consultancy */
  M6920 = 'M6920',
  /** (70.10) Activities Of Head Offices */
  M7010 = 'M7010',
  /** (70.21) Public Relations And Communication Activities */
  M7021 = 'M7021',
  /** (70.22) Business And Other Management Consultancy Activities */
  M7022 = 'M7022',
  /** (71.11) Architectural Activities */
  M7111 = 'M7111',
  /** (71.12) Engineering Activities And Related Technical Consultancy */
  M7112 = 'M7112',
  /** (71.20) Technical Testing And Analysis */
  M7120 = 'M7120',
  /** (72.11) Research And Experimental Development On Biotechnology */
  M7211 = 'M7211',
  /** (72.19) Other Research And Experimental Development On Natural Sciences And Engineering */
  M7219 = 'M7219',
  /** (72.20) Research And Experimental Development On Social Sciences And Humanities */
  M7220 = 'M7220',
  /** (73.11) Advertising Agencies */
  M7311 = 'M7311',
  /** (73.12) Media Representation */
  M7312 = 'M7312',
  /** (73.20) Market Research And Public Opinion Polling */
  M7320 = 'M7320',
  /** (74.10) Specialised Design Activities */
  M7410 = 'M7410',
  /** (74.20) Photographic Activities */
  M7420 = 'M7420',
  /** (74.30) Translation And Interpretation Activities */
  M7430 = 'M7430',
  /** (74.90) Other Professional, Scientific And Technical Activities N.E.C. */
  M7490 = 'M7490',
  /** (75.00) Veterinary Activities */
  M7500 = 'M7500',
  /** (N) Administrative And Support Service Activities */
  N = 'N',
  /** (77) Rental And Leasing Activities */
  N77 = 'N77',
  /** (78) Employment Activities */
  N78 = 'N78',
  /** (79) Travel Agency, Tour Operator And Other Reservation Service And Related Activities */
  N79 = 'N79',
  /** (80) Security And Investigation Activities */
  N80 = 'N80',
  /** (81) Services To Buildings And Landscape Activities */
  N81 = 'N81',
  /** (82) Office Administrative, Office Support And Other Business Support Activities */
  N82 = 'N82',
  /** (77.1) Renting And Leasing Of Motor Vehicles */
  N771 = 'N771',
  /** (77.2) Renting And Leasing Of Personal And Household Goods */
  N772 = 'N772',
  /** (77.3) Renting And Leasing Of Other Machinery, Equipment And Tangible Goods */
  N773 = 'N773',
  /** (77.4) Leasing Of Intellectual Property And Similar Products, Except Copyrighted Works */
  N774 = 'N774',
  /** (78.1) Activities Of Employment Placement Agencies */
  N781 = 'N781',
  /** (78.2) Temporary Employment Agency Activities */
  N782 = 'N782',
  /** (78.3) Other Human Resources Provision */
  N783 = 'N783',
  /** (79.1) Travel Agency And Tour Operator Activities */
  N791 = 'N791',
  /** (79.9) Other Reservation Service And Related Activities */
  N799 = 'N799',
  /** (80.1) Private Security Activities */
  N801 = 'N801',
  /** (80.2) Security Systems Service Activities */
  N802 = 'N802',
  /** (80.3) Investigation Activities */
  N803 = 'N803',
  /** (81.1) Combined Facilities Support Activities */
  N811 = 'N811',
  /** (81.2) Cleaning Activities */
  N812 = 'N812',
  /** (81.3) Landscape Service Activities */
  N813 = 'N813',
  /** (82.1) Office Administrative And Support Activities */
  N821 = 'N821',
  /** (82.2) Activities Of Call Centres */
  N822 = 'N822',
  /** (82.3) Organisation Of Conventions And Trade Shows */
  N823 = 'N823',
  /** (82.9) Business Support Service Activities N.E.C. */
  N829 = 'N829',
  /** (77.11) Renting And Leasing Of Cars And Light Motor Vehicles */
  N7711 = 'N7711',
  /** (77.12) Renting And Leasing Of Trucks */
  N7712 = 'N7712',
  /** (77.21) Renting And Leasing Of Recreational And Sports Goods */
  N7721 = 'N7721',
  /** (77.22) Renting Of Video Tapes And Disks */
  N7722 = 'N7722',
  /** (77.29) Renting And Leasing Of Other Personal And Household Goods */
  N7729 = 'N7729',
  /** (77.31) Renting And Leasing Of Agricultural Machinery And Equipment */
  N7731 = 'N7731',
  /** (77.32) Renting And Leasing Of Construction And Civil Engineering Machinery And Equipment */
  N7732 = 'N7732',
  /** (77.33) Renting And Leasing Of Office Machinery And Equipment (Including Computers) */
  N7733 = 'N7733',
  /** (77.34) Renting And Leasing Of Water Transport Equipment */
  N7734 = 'N7734',
  /** (77.35) Renting And Leasing Of Air Transport Equipment */
  N7735 = 'N7735',
  /** (77.39) Renting And Leasing Of Other Machinery, Equipment And Tangible Goods N.E.C. */
  N7739 = 'N7739',
  /** (77.40) Leasing Of Intellectual Property And Similar Products, Except Copyrighted Works */
  N7740 = 'N7740',
  /** (78.10) Activities Of Employment Placement Agencies */
  N7810 = 'N7810',
  /** (78.20) Temporary Employment Agency Activities */
  N7820 = 'N7820',
  /** (78.30) Other Human Resources Provision */
  N7830 = 'N7830',
  /** (79.11) Travel Agency Activities */
  N7911 = 'N7911',
  /** (79.12) Tour Operator Activities */
  N7912 = 'N7912',
  /** (79.90) Other Reservation Service And Related Activities */
  N7990 = 'N7990',
  /** (80.10) Private Security Activities */
  N8010 = 'N8010',
  /** (80.20) Security Systems Service Activities */
  N8020 = 'N8020',
  /** (80.30) Investigation Activities */
  N8030 = 'N8030',
  /** (81.10) Combined Facilities Support Activities */
  N8110 = 'N8110',
  /** (81.21) General Cleaning Of Buildings */
  N8121 = 'N8121',
  /** (81.22) Other Building And Industrial Cleaning Activities */
  N8122 = 'N8122',
  /** (81.29) Other Cleaning Activities */
  N8129 = 'N8129',
  /** (81.30) Landscape Service Activities */
  N8130 = 'N8130',
  /** (82.11) Combined Office Administrative Service Activities */
  N8211 = 'N8211',
  /** (82.19) Photocopying, Document Preparation And Other Specialised Office Support Activities */
  N8219 = 'N8219',
  /** (82.20) Activities Of Call Centres */
  N8220 = 'N8220',
  /** (82.30) Organisation Of Conventions And Trade Shows */
  N8230 = 'N8230',
  /** (82.91) Activities Of Collection Agencies And Credit Bureaus */
  N8291 = 'N8291',
  /** (82.92) Packaging Activities */
  N8292 = 'N8292',
  /** (82.99) Other Business Support Service Activities N.E.C. */
  N8299 = 'N8299',
  /** None */
  None = 'None',
  /** (O) Public Administration And Defence; Compulsory Social Security */
  O = 'O',
  /** (84) Public Administration And Defence; Compulsory Social Security */
  O84 = 'O84',
  /** (84.1) Administration Of The State And The Economic And Social Policy Of The Community */
  O841 = 'O841',
  /** (84.2) Provision Of Services To The Community As A Whole */
  O842 = 'O842',
  /** (84.3) Compulsory Social Security Activities */
  O843 = 'O843',
  /** (84.11) General Public Administration Activities */
  O8411 = 'O8411',
  /** (84.12) Regulation Of The Activities Of Providing Health Care, Education, Cultural Services And Other Social Services, Excluding Social Security */
  O8412 = 'O8412',
  /** (84.13) Regulation Of And Contribution To More Efficient Operation Of Businesses */
  O8413 = 'O8413',
  /** (84.21) Foreign Affairs */
  O8421 = 'O8421',
  /** (84.22) Defence Activities */
  O8422 = 'O8422',
  /** (84.23) Justice And Judicial Activities */
  O8423 = 'O8423',
  /** (84.24) Public Order And Safety Activities */
  O8424 = 'O8424',
  /** (84.25) Fire Service Activities */
  O8425 = 'O8425',
  /** (84.30) Compulsory Social Security Activities */
  O8430 = 'O8430',
  /** (P) Education */
  P = 'P',
  /** (85) Education */
  P85 = 'P85',
  /** (85.1) Pre-Primary Education */
  P851 = 'P851',
  /** (85.2) Primary Education */
  P852 = 'P852',
  /** (85.3) Secondary Education */
  P853 = 'P853',
  /** (85.4) Higher Education */
  P854 = 'P854',
  /** (85.5) Other Education */
  P855 = 'P855',
  /** (85.6) Educational Support Activities */
  P856 = 'P856',
  /** (85.10) Pre-Primary Education */
  P8510 = 'P8510',
  /** (85.20) Primary Education */
  P8520 = 'P8520',
  /** (85.31) General Secondary Education */
  P8531 = 'P8531',
  /** (85.32) Technical And Vocational Secondary Education */
  P8532 = 'P8532',
  /** (85.41) Post-Secondary Non-Tertiary Education */
  P8541 = 'P8541',
  /** (85.42) Tertiary Education */
  P8542 = 'P8542',
  /** (85.51) Sports And Recreation Education */
  P8551 = 'P8551',
  /** (85.52) Cultural Education */
  P8552 = 'P8552',
  /** (85.53) Driving School Activities */
  P8553 = 'P8553',
  /** (85.59) Other Education N.E.C. */
  P8559 = 'P8559',
  /** (85.60) Educational Support Activities */
  P8560 = 'P8560',
  /** (Q) Human Health And Social Work Activities */
  Q = 'Q',
  /** (86) Human Health Activities */
  Q86 = 'Q86',
  /** (87) Residential Care Activities */
  Q87 = 'Q87',
  /** (88) Social Work Activities Without Accommodation */
  Q88 = 'Q88',
  /** (86.1) Hospital Activities */
  Q861 = 'Q861',
  /** (86.2) Medical And Dental Practice Activities */
  Q862 = 'Q862',
  /** (86.9) Other Human Health Activities */
  Q869 = 'Q869',
  /** (87.1) Residential Nursing Care Activities */
  Q871 = 'Q871',
  /** (87.2) Residential Care Activities For Mental Retardation, Mental Health And Substance Abuse */
  Q872 = 'Q872',
  /** (87.3) Residential Care Activities For The Elderly And Disabled */
  Q873 = 'Q873',
  /** (87.9) Other Residential Care Activities */
  Q879 = 'Q879',
  /** (88.1) Social Work Activities Without Accommodation For The Elderly And Disabled */
  Q881 = 'Q881',
  /** (88.9) Other Social Work Activities Without Accommodation */
  Q889 = 'Q889',
  /** (86.10) Hospital Activities */
  Q8610 = 'Q8610',
  /** (86.21) General Medical Practice Activities */
  Q8621 = 'Q8621',
  /** (86.22) Specialist Medical Practice Activities */
  Q8622 = 'Q8622',
  /** (86.23) Dental Practice Activities */
  Q8623 = 'Q8623',
  /** (86.90) Other Human Health Activities */
  Q8690 = 'Q8690',
  /** (87.10) Residential Nursing Care Activities */
  Q8710 = 'Q8710',
  /** (87.20) Residential Care Activities For Mental Retardation, Mental Health And Substance Abuse */
  Q8720 = 'Q8720',
  /** (87.30) Residential Care Activities For The Elderly And Disabled */
  Q8730 = 'Q8730',
  /** (87.90) Other Residential Care Activities */
  Q8790 = 'Q8790',
  /** (88.10) Social Work Activities Without Accommodation For The Elderly And Disabled */
  Q8810 = 'Q8810',
  /** (88.91) Child Day-Care Activities */
  Q8891 = 'Q8891',
  /** (88.99) Other Social Work Activities Without Accommodation N.E.C. */
  Q8899 = 'Q8899',
  /** (R) Arts, Entertainment And Recreation */
  R = 'R',
  /** (90) Creative, Arts And Entertainment Activities */
  R90 = 'R90',
  /** (91) Libraries, Archives, Museums And Other Cultural Activities */
  R91 = 'R91',
  /** (92) Gambling And Betting Activities */
  R92 = 'R92',
  /** (93) Sports Activities And Amusement And Recreation Activities */
  R93 = 'R93',
  /** (90.0) Creative, Arts And Entertainment Activities */
  R900 = 'R900',
  /** (91.0) Libraries, Archives, Museums And Other Cultural Activities */
  R910 = 'R910',
  /** (92.0) Gambling And Betting Activities */
  R920 = 'R920',
  /** (93.1) Sports Activities */
  R931 = 'R931',
  /** (93.2) Amusement And Recreation Activities */
  R932 = 'R932',
  /** (90.01) Performing Arts */
  R9001 = 'R9001',
  /** (90.02) Support Activities To Performing Arts */
  R9002 = 'R9002',
  /** (90.03) Artistic Creation */
  R9003 = 'R9003',
  /** (90.04) Operation Of Arts Facilities */
  R9004 = 'R9004',
  /** (91.01) Library And Archives Activities */
  R9101 = 'R9101',
  /** (91.02) Museums Activities */
  R9102 = 'R9102',
  /** (91.03) Operation Of Historical Sites And Buildings And Similar Visitor Attractions */
  R9103 = 'R9103',
  /** (91.04) Botanical And Zoological Gardens And Nature Reserves Activities */
  R9104 = 'R9104',
  /** (92.00) Gambling And Betting Activities */
  R9200 = 'R9200',
  /** (93.11) Operation Of Sports Facilities */
  R9311 = 'R9311',
  /** (93.12) Activities Of Sport Clubs */
  R9312 = 'R9312',
  /** (93.13) Fitness Facilities */
  R9313 = 'R9313',
  /** (93.19) Other Sports Activities */
  R9319 = 'R9319',
  /** (93.21) Activities Of Amusement Parks And Theme Parks */
  R9321 = 'R9321',
  /** (93.29) Other Amusement And Recreation Activities */
  R9329 = 'R9329',
  /** (S) Other Services Activities */
  S = 'S',
  /** (94) Activities Of Membership Organisations */
  S94 = 'S94',
  /** (95) Repair Of Computers And Personal And Household Goods */
  S95 = 'S95',
  /** (96) Other Personal Service Activities */
  S96 = 'S96',
  /** (94.1) Activities Of Business, Employers And Professional Membership Organisations */
  S941 = 'S941',
  /** (94.2) Activities Of Trade Unions */
  S942 = 'S942',
  /** (94.9) Activities Of Other Membership Organisations */
  S949 = 'S949',
  /** (95.1) Repair Of Computers And Communication Equipment */
  S951 = 'S951',
  /** (95.2) Repair Of Personal And Household Goods */
  S952 = 'S952',
  /** (96.0) Other Personal Service Activities */
  S960 = 'S960',
  /** (94.11) Activities Of Business And Employers Membership Organisations */
  S9411 = 'S9411',
  /** (94.12) Activities Of Professional Membership Organisations */
  S9412 = 'S9412',
  /** (94.20) Activities Of Trade Unions */
  S9420 = 'S9420',
  /** (94.91) Activities Of Religious Organisations */
  S9491 = 'S9491',
  /** (94.92) Activities Of Political Organisations */
  S9492 = 'S9492',
  /** (94.99) Activities Of Other Membership Organisations N.E.C. */
  S9499 = 'S9499',
  /** (95.11) Repair Of Computers And Peripheral Equipment */
  S9511 = 'S9511',
  /** (95.12) Repair Of Communication Equipment */
  S9512 = 'S9512',
  /** (95.21) Repair Of Consumer Electronics */
  S9521 = 'S9521',
  /** (95.22) Repair Of Household Appliances And Home And Garden Equipment */
  S9522 = 'S9522',
  /** (95.23) Repair Of Footwear And Leather Goods */
  S9523 = 'S9523',
  /** (95.24) Repair Of Furniture And Home Furnishings */
  S9524 = 'S9524',
  /** (95.25) Repair Of Watches, Clocks And Jewellery */
  S9525 = 'S9525',
  /** (95.29) Repair Of Other Personal And Household Goods */
  S9529 = 'S9529',
  /** (96.01) Washing And (Dry-)Cleaning Of Textile And Fur Products */
  S9601 = 'S9601',
  /** (96.02) Hairdressing And Other Beauty Treatment */
  S9602 = 'S9602',
  /** (96.03) Funeral And Related Activities */
  S9603 = 'S9603',
  /** (96.04) Physical Well-Being Activities */
  S9604 = 'S9604',
  /** (96.09) Other Personal Service Activities N.E.C. */
  S9609 = 'S9609',
  /** (T) Activities Of Households As Employers; Undifferentiated Goods - And Services - Producing Activities Of Households For Own Use */
  T = 'T',
  /** (97) Activities Of Households As Employers Of Domestic Personnel */
  T97 = 'T97',
  /** (98) Undifferentiated Goods- And Services-Producing Activities Of Private Households For Own Use */
  T98 = 'T98',
  /** (97.0) Activities Of Households As Employers Of Domestic Personnel */
  T970 = 'T970',
  /** (98.1) Undifferentiated Goods-Producing Activities Of Private Households For Own Use */
  T981 = 'T981',
  /** (98.2) Undifferentiated Service-Producing Activities Of Private Households For Own Use */
  T982 = 'T982',
  /** (97.00) Activities Of Households As Employers Of Domestic Personnel */
  T9700 = 'T9700',
  /** (98.10) Undifferentiated Goods-Producing Activities Of Private Households For Own Use */
  T9810 = 'T9810',
  /** (98.20) Undifferentiated Service-Producing Activities Of Private Households For Own Use */
  T9820 = 'T9820',
  /** (U) Activities Of Extraterritorial Organisations And Bodies */
  U = 'U',
  /** (99) Activities Of Extraterritorial Organisations And Bodies */
  U99 = 'U99',
  /** (99.0) Activities Of Extraterritorial Organisations And Bodies */
  U990 = 'U990',
  /** (99.00) Activities Of Extraterritorial Organisations And Bodies */
  U9900 = 'U9900'
}

export type Nav = {
  __typename?: 'Nav';
  _id: Scalars['GraphQLObjectId']['output'];
  /** totalNavBeforeAdjustmentsBeforeOrders must differ less from frontOfficeNav than absHighTolerancePercent */
  absHighTolerancePercent?: Maybe<Scalars['String']['output']>;
  /** if totalNavBeforeAdjustmentsBeforeOrders differs more from frontOfficeNav than absLowTolerancePercent, a comment is required */
  absLowTolerancePercent?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment: Scalars['String']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  data: NavData;
  date: Scalars['GraphQLDateString']['output'];
  frontOfficeNav: Scalars['String']['output'];
  /** Copied from Party.fundInfo.riskLevel when calculating NAV */
  fundRiskLevel?: Maybe<Scalars['String']['output']>;
  /** Added when jobs send out navs to external parties */
  publishedTo?: Maybe<Array<PublishedToType>>;
  status: ReportStatusEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo?: Maybe<UserInfo>;
};

export type NavData = {
  __typename?: 'NavData';
  adjustmentAmountPositions: Array<NavPosition>;
  classes: Array<NavFundClass>;
  orders: Array<NavOrder>;
  positions: Array<NavPosition>;
  transactions: Array<NavTransaction>;
};

export type NavDataInput = {
  adjustmentAmountPositions: Array<NavPositionInput>;
  classes: Array<NavFundClassInput>;
  orders: Array<NavOrderInput>;
  positions: Array<NavPositionInput>;
  transactions: Array<NavTransactionInput>;
};

export type NavFundClass = {
  __typename?: 'NavFundClass';
  adjustmentAmount: Scalars['String']['output'];
  dividendPaid: Scalars['String']['output'];
  fxHedge: Scalars['String']['output'];
  fxRate: Scalars['String']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  managementFee: Scalars['String']['output'];
  name: Scalars['String']['output'];
  navAfterAdjustmentsAfterOrders: Scalars['String']['output'];
  navAfterAdjustmentsBeforeOrders: Scalars['String']['output'];
  navBeforeAdjustmentsBeforeOrders: Scalars['String']['output'];
  navCurrency: CurrencyEnum;
  navPerUnit: Scalars['String']['output'];
  navPerUnitReturn1D: Scalars['String']['output'];
  unitsAfterOrders: Scalars['String']['output'];
  unitsBeforeOrders: Scalars['String']['output'];
  weight: Scalars['String']['output'];
};

export type NavFundClassInput = {
  adjustmentAmount: Scalars['String']['input'];
  dividendPaid: Scalars['String']['input'];
  fxHedge: Scalars['String']['input'];
  fxRate: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  managementFee: Scalars['String']['input'];
  name: Scalars['String']['input'];
  navAfterAdjustmentsAfterOrders: Scalars['String']['input'];
  navAfterAdjustmentsBeforeOrders: Scalars['String']['input'];
  navBeforeAdjustmentsBeforeOrders: Scalars['String']['input'];
  navCurrency: CurrencyEnum;
  navPerUnit: Scalars['String']['input'];
  navPerUnitReturn1D: Scalars['String']['input'];
  unitsAfterOrders: Scalars['String']['input'];
  unitsBeforeOrders: Scalars['String']['input'];
  weight: Scalars['String']['input'];
};

export type NavInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** totalNavBeforeAdjustmentsBeforeOrders must differ less from frontOfficeNav than absHighTolerancePercent */
  absHighTolerancePercent?: InputMaybe<Scalars['String']['input']>;
  /** if totalNavBeforeAdjustmentsBeforeOrders differs more from frontOfficeNav than absLowTolerancePercent, a comment is required */
  absLowTolerancePercent?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  comment: Scalars['String']['input'];
  data: NavDataInput;
  date: Scalars['GraphQLDateString']['input'];
  frontOfficeNav: Scalars['String']['input'];
  /** Copied from Party.fundInfo.riskLevel when calculating NAV */
  fundRiskLevel?: InputMaybe<Scalars['String']['input']>;
  status: ReportStatusEnum;
};

export type NavOrder = {
  __typename?: 'NavOrder';
  amount: Scalars['String']['output'];
  carryOwnTransactionCost: Scalars['Boolean']['output'];
  currency: CurrencyEnum;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<TransferAgentOrder>;
  orderId: Scalars['GraphQLObjectId']['output'];
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['output'];
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type NavOrderInput = {
  amount: Scalars['String']['input'];
  carryOwnTransactionCost: Scalars['Boolean']['input'];
  currency: CurrencyEnum;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  name: Scalars['String']['input'];
  orderId: Scalars['GraphQLObjectId']['input'];
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export type NavPosition = {
  __typename?: 'NavPosition';
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  currency: CurrencyEnum;
  fxRate: Scalars['String']['output'];
  fxValuationDate: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  valuationDate: Scalars['GraphQLDateString']['output'];
  value: Scalars['String']['output'];
};

export type NavPositionInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  fxRate: Scalars['String']['input'];
  fxValuationDate: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  name: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  valuationDate: Scalars['GraphQLDateString']['input'];
  value: Scalars['String']['input'];
};

export type NavTransaction = {
  __typename?: 'NavTransaction';
  account?: Maybe<PartyAccount>;
  accountId: Scalars['GraphQLObjectId']['output'];
  accountSwitchOrder: Scalars['Boolean']['output'];
  accountingAmount?: Maybe<Scalars['String']['output']>;
  accountingCurrency?: Maybe<CurrencyEnum>;
  accountingPrice?: Maybe<Scalars['String']['output']>;
  adjustmentAmount: Scalars['String']['output'];
  adjustmentShares: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  currency: CurrencyEnum;
  externalId: Scalars['String']['output'];
  fxRate?: Maybe<Scalars['String']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['ISIN']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  transactionType: NavTransactionType;
  units: Scalars['String']['output'];
  valueDate: Scalars['GraphQLDateString']['output'];
};

export type NavTransactionInput = {
  accountId: Scalars['GraphQLObjectId']['input'];
  accountSwitchOrder: Scalars['Boolean']['input'];
  accountingAmount: Scalars['String']['input'];
  accountingCurrency: CurrencyEnum;
  accountingPrice: Scalars['String']['input'];
  adjustmentAmount: Scalars['String']['input'];
  adjustmentShares: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  currency: CurrencyEnum;
  externalId: Scalars['String']['input'];
  fxRate: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['ISIN']['input'];
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  transactionType: NavTransactionType;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export enum NavTransactionType {
  CreateRedeemAmount = 'CreateRedeemAmount',
  CreateRedeemUnits = 'CreateRedeemUnits',
  FxSpot = 'FxSpot',
  ManagementFee = 'ManagementFee',
  Transfer = 'Transfer'
}

export enum NonTradingDayFillMethodEnum {
  NIL_VALUE = 'NIL_VALUE',
  PREVIOUS_VALUE = 'PREVIOUS_VALUE'
}

export enum NonTradingDayFillOptionEnum {
  ACTIVE_DAYS_ONLY = 'ACTIVE_DAYS_ONLY',
  ALL_CALENDAR_DAYS = 'ALL_CALENDAR_DAYS',
  NON_TRADING_WEEKDAYS = 'NON_TRADING_WEEKDAYS'
}

export type Note = {
  __typename?: 'Note';
  _id: Scalars['GraphQLObjectId']['output'];
  asset: NoteAssetEnum;
  clientIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  clients: Array<PartyInfo>;
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  /** Markdown or JSON */
  data: Scalars['String']['output'];
  refs: Array<NoteReference>;
  status: NoteStatusEnum;
  tags: Array<NoteTag>;
  title: Scalars['UriPartString']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum NoteAssetEnum {
  Admin = 'Admin',
  Base = 'Base',
  Esg = 'Esg'
}

export type NoteFilterInput = {
  assetIn?: InputMaybe<Array<NoteAssetEnum>>;
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  collectionIn?: InputMaybe<Array<CollectionNameEnum>>;
  creatorIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  documentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<NoteStatusEnum>>;
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  valueIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NoteInput = {
  /** if _id is omitted, a Note will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  asset: NoteAssetEnum;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  data: Scalars['String']['input'];
  refs: Array<NoteReferenceInput>;
  status: NoteStatusEnum;
  tags: Array<NoteTagInput>;
  title: Scalars['String']['input'];
};

export type NoteReference = {
  __typename?: 'NoteReference';
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['output'];
};

export type NoteReferenceInput = {
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['input'];
};

export enum NoteStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type NoteTag = {
  __typename?: 'NoteTag';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type NoteTagFilter = {
  collection?: InputMaybe<CollectionNameEnum>;
  documentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type NoteTagInput = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OrderFilterInput = {
  carryOwnTransactionCost?: InputMaybe<Scalars['Boolean']['input']>;
  clientAccountIn?: InputMaybe<Array<Scalars['String']['input']>>;
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  sourceIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  tradeDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  tradeDateStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum OverrideOptionEnum {
  OVERRIDE_OPTION_CLOSE = 'OVERRIDE_OPTION_CLOSE',
  OVERRIDE_OPTION_GPA = 'OVERRIDE_OPTION_GPA'
}

export type Party = {
  __typename?: 'Party';
  _id: Scalars['GraphQLObjectId']['output'];
  accountant?: Maybe<Scalars['String']['output']>;
  accountingCompanyType?: Maybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: Maybe<CurrencyEnum>;
  accountingFrequency?: Maybe<AccountingFrequencyEnum>;
  accounts: Array<PartyAccount>;
  address?: Maybe<Scalars['String']['output']>;
  aliases: Array<Alias>;
  approval?: Maybe<Scalars['String']['output']>;
  beneficialOwner?: Maybe<BeneficialOwner>;
  bloombergEquityTicker?: Maybe<Scalars['String']['output']>;
  businessIdentifierCode?: Maybe<Scalars['String']['output']>;
  ceo?: Maybe<Scalars['String']['output']>;
  complaintsManager?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryCodeEnum>;
  creditRatingScale: Array<Scalars['String']['output']>;
  creditRatings: Array<PartyCreditRating>;
  dashName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalAccounts: Array<PartyExternalAccount>;
  firstTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  fundInfo?: Maybe<PartyFundInfo>;
  inLedgerNumber?: Maybe<Scalars['Int']['output']>;
  inLedgerTradeDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  industryCode?: Maybe<Scalars['String']['output']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['output']>;
  instruments: Array<Instrument>;
  issuer?: Maybe<PartyIssuerType>;
  issuerType?: Maybe<IssuerTypeEnum>;
  legalEntityIdentifier?: Maybe<Scalars['String']['output']>;
  legalNumber?: Maybe<Scalars['String']['output']>;
  limitSlackChannel?: Maybe<Scalars['String']['output']>;
  limitStartDate?: Maybe<Scalars['Date']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  nace?: Maybe<NaceClass>;
  naceDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  regulator?: Maybe<Scalars['String']['output']>;
  standardSettlementInstructions: Array<PartyStandardSettlementInstruction>;
  status?: Maybe<PartyStatusEnum>;
  sustainalyticsData?: Maybe<SustainalyticsDataModelWithStatus>;
  sustainalyticsId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<TimezoneEnum>;
  topAccount?: Maybe<PartyAccount>;
  types: Array<Maybe<PartyType>>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type PartyAccount = {
  __typename?: 'PartyAccount';
  _id: Scalars['GraphQLObjectId']['output'];
  benchmarks: Array<PartyBenchmark>;
  childrenAccounts: Array<PartyAccount>;
  childrenAccountsIds: Array<Scalars['GraphQLObjectId']['output']>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  defaultAccount: Scalars['Boolean']['output'];
  defaultAccruedInstruments?: Maybe<Array<DefaultCashInstrument>>;
  defaultCashInstruments: Array<DefaultCashInstrument>;
  defaultDebtInstruments: Array<DefaultCashInstrument>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * ModelIndexBenchmark calculated within the Party.accounts computed property.
   * Uses introspection to decide calculation and parameter. Thus if type name
   * or parameters to timeSeries are altered this must be mirrored under
   * Party.accounts
   */
  modelIndexBenchmark?: Maybe<ModelIndexBenchmark>;
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  parentAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  securityExternalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  transientCashInstruments: Array<DefaultCashInstrument>;
  type: PartyAccountType;
  weights: Array<PartyAccountWeight>;
};

export type PartyAccountInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  benchmarks?: InputMaybe<Array<PartyBenchmarkInput>>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  defaultAccount?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAccruedInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  defaultCashInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  defaultDebtInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  modelIndexBenchmark?: InputMaybe<ModelIndexBenchmarkInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  securityExternalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transientCashInstruments?: InputMaybe<Array<DefaultCashInstrumentInput>>;
  type: PartyAccountType;
  weights?: InputMaybe<Array<PartyAccountWeightInput>>;
};

export enum PartyAccountType {
  Physical = 'Physical',
  PortfolioInstrument = 'PortfolioInstrument',
  Sum = 'Sum'
}

export type PartyAccountWeight = {
  __typename?: 'PartyAccountWeight';
  date: Scalars['GraphQLDateString']['output'];
  expectedReturn: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  model: Scalars['Float']['output'];
};

export type PartyAccountWeightInput = {
  date: Scalars['GraphQLDateString']['input'];
  expectedReturn: Scalars['Float']['input'];
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
  model: Scalars['Float']['input'];
};

export type PartyBaseInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountant?: InputMaybe<Scalars['String']['input']>;
  accountingCompanyType?: InputMaybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: InputMaybe<Scalars['String']['input']>;
  accountingFrequency?: InputMaybe<AccountingFrequencyEnum>;
  address?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  approval?: InputMaybe<Scalars['String']['input']>;
  beneficialOwner?: InputMaybe<BeneficialOwnerInput>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  creditRatingScale?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  inLedgerNumber?: InputMaybe<Scalars['Int']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  issuerType?: InputMaybe<IssuerTypeEnum>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<NaceClass>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  standardSettlementInstructions?: InputMaybe<Array<PartyStandardSettlementInstructionInput>>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types: Array<PartyType>;
  updateTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type PartyBenchmark = {
  __typename?: 'PartyBenchmark';
  comment: Scalars['String']['output'];
  currency: CurrencyEnum;
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  mainBenchmark: Scalars['Boolean']['output'];
  /** Offset real number, i.e. 0.01 = 1% */
  offset: Scalars['Float']['output'];
  showBenchmark: Scalars['Boolean']['output'];
  showExternal: Scalars['Boolean']['output'];
};

export type PartyBenchmarkInput = {
  comment: Scalars['String']['input'];
  currency: CurrencyEnum;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  mainBenchmark?: InputMaybe<Scalars['Boolean']['input']>;
  offset: Scalars['Float']['input'];
  showBenchmark: Scalars['Boolean']['input'];
  showExternal: Scalars['Boolean']['input'];
};

export type PartyCreditRating = {
  __typename?: 'PartyCreditRating';
  creditRatingAgencyId: Scalars['GraphQLObjectId']['output'];
  grade: Scalars['GraphQLCreditRating']['output'];
};

export type PartyEsg = {
  __typename?: 'PartyEsg';
  attachments?: Maybe<Array<Attachment>>;
  comments: Array<EsgComment>;
  globalStandardScreen: Array<GlobalStandardScreen>;
  productInvolvement: Array<ProductInvolvement>;
  shareholderEngagement: Array<ShareholderEngagement>;
};

export type PartyEsgInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  comments: Array<EsgCommentInput>;
  globalStandardScreen: Array<GlobalStandardScreenInput>;
  productInvolvement: Array<ProductInvolvementInput>;
  shareholderEngagement: Array<ShareholderEngagementInput>;
};

export type PartyExternalAccount = {
  __typename?: 'PartyExternalAccount';
  _id: Scalars['GraphQLObjectId']['output'];
  agreement?: Maybe<Agreement>;
  attachments: Array<Attachment>;
  baseCurrency?: Maybe<CurrencyEnum>;
  clearingBroker?: Maybe<Party>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  clearingHouses?: Maybe<Array<Party>>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  custodian?: Maybe<Party>;
  custodianAccountId?: Maybe<Scalars['String']['output']>;
  custodianAccountNumber?: Maybe<Scalars['String']['output']>;
  custodianId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emirReporting: Scalars['Boolean']['output'];
  initialMarginCurrencies?: Maybe<Array<CurrencyEnum>>;
  minimumTransferAmount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  noCollateralRequirement?: Maybe<Scalars['Boolean']['output']>;
  number: Scalars['Int']['output'];
  partyInstrumentIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  partyInstruments?: Maybe<Array<Instrument>>;
  principalCurrencies?: Maybe<Array<CurrencyEnum>>;
  roundingAmount?: Maybe<Scalars['Float']['output']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: Maybe<Scalars['Boolean']['output']>;
  settlementOnVMInstrument?: Maybe<Scalars['Boolean']['output']>;
  threshold?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<PartyExternalAccountType>;
  variationMarginCurrencies?: Maybe<Array<CurrencyEnum>>;
};

export type PartyExternalAccountInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  baseCurrency?: InputMaybe<CurrencyEnum>;
  /** only for omnibusClearingAccount + clearingAccount */
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** only for omnibusClearingAccounts */
  clearingHousesIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  custodianAccountId?: InputMaybe<Scalars['String']['input']>;
  custodianAccountNumber?: InputMaybe<Scalars['String']['input']>;
  custodianId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  emirReporting?: InputMaybe<Scalars['Boolean']['input']>;
  minimumTransferAmount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  noCollateralRequirement?: InputMaybe<Scalars['Boolean']['input']>;
  number: Scalars['Int']['input'];
  roundingAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Need to be able to separate collateral securities posted as IM from what's posted as VM */
  separatedInitialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  settlementOnVMInstrument?: InputMaybe<Scalars['Boolean']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<PartyExternalAccountType>;
};

export enum PartyExternalAccountType {
  CashAccount = 'CashAccount',
  ClearingAccount = 'ClearingAccount',
  Equity = 'Equity',
  FundAccount = 'FundAccount',
  Gmra = 'Gmra',
  Gmsla = 'Gmsla',
  Isda = 'Isda',
  OmnibusClearingAccount = 'OmnibusClearingAccount',
  SecuritiesAccount = 'SecuritiesAccount',
  ShareRegistry = 'ShareRegistry'
}

export type PartyFilterInput = {
  custodianAccountNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fundInfoClassInstrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  legalEntityIdentifierIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  statusIn?: InputMaybe<Array<PartyStatusEnum>>;
  typeIn?: InputMaybe<Array<PartyType>>;
  withEnabledFundClasses?: InputMaybe<Scalars['Boolean']['input']>;
  withTransactions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartyFundInfo = {
  __typename?: 'PartyFundInfo';
  calendar?: Maybe<Calendar>;
  calendarNames?: Maybe<Array<CalendarNameEnum>>;
  classes: Array<Maybe<PartyFundInfoClass>>;
  createRedeemInstrument?: Maybe<Instrument>;
  createRedeemInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodian?: Maybe<PartyInfo>;
  custodianId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  depositary?: Maybe<PartyInfo>;
  /** A depositary verifies that a fund's assets exist on behalf of shareholders */
  depositaryId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  endDate: Scalars['GraphQLDateString']['output'];
  esgComponents?: Maybe<Array<EsgComponentEnum>>;
  fundCompany?: Maybe<Party>;
  fundCompanyId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fundDescription?: Maybe<Scalars['String']['output']>;
  fundManager?: Maybe<Scalars['String']['output']>;
  fundReportComponents?: Maybe<Array<FundReportComponentEnum>>;
  fundReportTypes: Array<FundReportEnum>;
  fundType?: Maybe<FundTypeEnum>;
  portfolio?: Maybe<Scalars['String']['output']>;
  riskLevel?: Maybe<Scalars['Int']['output']>;
  ta?: Maybe<PartyInfo>;
  taId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  tradeFrequency?: Maybe<Scalars['String']['output']>;
};


export type PartyFundInfoCalendarArgs = {
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PartyFundInfoClass = {
  __typename?: 'PartyFundInfoClass';
  carryOwnCostPossible?: Maybe<Scalars['Boolean']['output']>;
  cleanClass?: Maybe<Scalars['Boolean']['output']>;
  dividendPaying?: Maybe<Scalars['Boolean']['output']>;
  earlyCloseTradeCutOff?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fxHedgeAccount?: Maybe<PartyAccount>;
  fxHedgeAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  fxHedged?: Maybe<Scalars['Boolean']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mainClass?: Maybe<Scalars['Boolean']['output']>;
  managementFee?: Maybe<Scalars['String']['output']>;
  managementFeeStruct?: Maybe<Scalars['String']['output']>;
  minimumInitialSubscription?: Maybe<Scalars['String']['output']>;
  minimumSubscriptionAdded?: Maybe<Scalars['String']['output']>;
  numberOfDecimalsNAVPerUnit?: Maybe<Scalars['Int']['output']>;
  numberOfDecimalsUnits?: Maybe<Scalars['Int']['output']>;
  redemptionFee?: Maybe<Scalars['String']['output']>;
  sIFA_ID?: Maybe<Scalars['String']['output']>;
  sIFA_UNIT?: Maybe<Scalars['String']['output']>;
  sfdr?: Maybe<Scalars['String']['output']>;
  shareClass?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  startNAVPerUnit?: Maybe<Scalars['String']['output']>;
  subscriptionFee?: Maybe<Scalars['String']['output']>;
  tradeCutOff?: Maybe<Scalars['String']['output']>;
};

export type PartyFundInfoClassInput = {
  carryOwnCostPossible?: InputMaybe<Scalars['Boolean']['input']>;
  cleanClass?: InputMaybe<Scalars['Boolean']['input']>;
  dividendPaying?: InputMaybe<Scalars['Boolean']['input']>;
  earlyCloseTradeCutOff?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fxHedgeAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxHedged?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  mainClass?: InputMaybe<Scalars['Boolean']['input']>;
  managementFee?: InputMaybe<Scalars['String']['input']>;
  managementFeeStruct?: InputMaybe<Scalars['String']['input']>;
  minimumInitialSubscription?: InputMaybe<Scalars['String']['input']>;
  minimumSubscriptionAdded?: InputMaybe<Scalars['String']['input']>;
  numberOfDecimalsNAVPerUnit?: InputMaybe<Scalars['Int']['input']>;
  numberOfDecimalsUnits?: InputMaybe<Scalars['Int']['input']>;
  redemptionFee?: InputMaybe<Scalars['String']['input']>;
  sIFA_ID?: InputMaybe<Scalars['String']['input']>;
  sIFA_UNIT?: InputMaybe<Scalars['String']['input']>;
  sfdr?: InputMaybe<Scalars['String']['input']>;
  shareClass?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startNAVPerUnit?: InputMaybe<Scalars['String']['input']>;
  subscriptionFee?: InputMaybe<Scalars['String']['input']>;
  tradeCutOff?: InputMaybe<Scalars['String']['input']>;
};

export type PartyFundInfoInput = {
  calendarNames: Array<CalendarNameEnum>;
  classes: Array<InputMaybe<PartyFundInfoClassInput>>;
  createRedeemInstrumentId: Scalars['GraphQLObjectId']['input'];
  custodianId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  depositaryId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  esgComponents?: InputMaybe<Array<EsgComponentEnum>>;
  fundCompanyId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fundDescription?: InputMaybe<Scalars['String']['input']>;
  fundManager?: InputMaybe<Scalars['String']['input']>;
  fundReportComponents?: InputMaybe<Array<FundReportComponentEnum>>;
  fundReportTypes?: InputMaybe<Array<FundReportEnum>>;
  fundType?: InputMaybe<FundTypeEnum>;
  portfolio?: InputMaybe<Scalars['String']['input']>;
  riskLevel?: InputMaybe<Scalars['Int']['input']>;
  taId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  tradeFrequency?: InputMaybe<Scalars['String']['input']>;
};

export type PartyInfo = {
  __typename?: 'PartyInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  name: Scalars['String']['output'];
};

export type PartyIssuerInput = {
  activitiesAffectingBiodiversityAreas?: InputMaybe<Scalars['Boolean']['input']>;
  captorDnshCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  carbonFootprint?: InputMaybe<Scalars['Boolean']['input']>;
  deforestation?: InputMaybe<Scalars['Boolean']['input']>;
  energyIntensityHighImpact?: InputMaybe<Scalars['Boolean']['input']>;
  esg: PartyEsgInput;
  fossilFuelCompaniesExposure?: InputMaybe<Scalars['Boolean']['input']>;
  ghgEmissions?: InputMaybe<Scalars['Boolean']['input']>;
  ghgIntensityOfInvesteeCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  hazardousWasteRatio?: InputMaybe<Scalars['Boolean']['input']>;
  iorp: IorpEnum;
  keySector?: InputMaybe<Scalars['Boolean']['input']>;
  lackOfProcessesAndCompliance?: InputMaybe<Scalars['Boolean']['input']>;
  managementSupervisoryGenderDiversity?: InputMaybe<Scalars['Boolean']['input']>;
  nearTermTargetClassification?: InputMaybe<Scalars['String']['input']>;
  netZeroCommitted?: Scalars['Boolean']['input'];
  nonRenewableEnergyShare?: InputMaybe<Scalars['Boolean']['input']>;
  pollutionOfWater?: InputMaybe<Scalars['Boolean']['input']>;
  recordableWorkAccidents?: InputMaybe<Scalars['Boolean']['input']>;
  scienceBasedTargetInitiative?: ScienceBasedTargetInitiativeEnum;
  transitionCompany?: InputMaybe<Scalars['Boolean']['input']>;
  unadjustedGenderPayGap?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartyIssuerType = {
  __typename?: 'PartyIssuerType';
  /** Activities negatively affecting biodiversity sensitive areas */
  activitiesAffectingBiodiversityAreas?: Maybe<Scalars['Boolean']['output']>;
  captorDnshCompliant?: Maybe<Scalars['Boolean']['output']>;
  carbonFootprint?: Maybe<Scalars['Boolean']['output']>;
  deforestation?: Maybe<Scalars['Boolean']['output']>;
  /** Energy consumption intensity per high impact climate sector */
  energyIntensityHighImpact?: Maybe<Scalars['Boolean']['output']>;
  esg?: Maybe<PartyEsg>;
  fossilFuelCompaniesExposure?: Maybe<Scalars['Boolean']['output']>;
  ghgEmissions?: Maybe<Scalars['Boolean']['output']>;
  ghgIntensityOfInvesteeCompanies?: Maybe<Scalars['Boolean']['output']>;
  hazardousWasteRatio?: Maybe<Scalars['Boolean']['output']>;
  iorp?: Maybe<IorpEnum>;
  keySector?: Maybe<Scalars['Boolean']['output']>;
  lackOfProcessesAndCompliance?: Maybe<Scalars['Boolean']['output']>;
  /** Management and supervisory board gender diversity */
  managementSupervisoryGenderDiversity?: Maybe<Scalars['Boolean']['output']>;
  nearTermTargetClassification?: Maybe<Scalars['String']['output']>;
  netZeroCommitted?: Maybe<Scalars['Boolean']['output']>;
  /** Share of non-renewable energy consumption and production */
  nonRenewableEnergyShare?: Maybe<Scalars['Boolean']['output']>;
  pollutionOfWater?: Maybe<Scalars['Boolean']['output']>;
  /** Rate of recordable work-related accidents */
  recordableWorkAccidents?: Maybe<Scalars['Boolean']['output']>;
  scienceBasedTargetInitiative: ScienceBasedTargetInitiativeEnum;
  transitionCompany?: Maybe<Scalars['Boolean']['output']>;
  /** Unadjusted gender pay gap between female and employees */
  unadjustedGenderPayGap?: Maybe<Scalars['Boolean']['output']>;
};

export type PartyStandardSettlementInstruction = {
  __typename?: 'PartyStandardSettlementInstruction';
  agentBusinessIdentifierCode: Scalars['String']['output'];
  beneficiaryBusinessIdentifierCode: Scalars['String']['output'];
  buyerSellerSafe: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  placeOfSettlementId: Scalars['GraphQLObjectId']['output'];
  securityAccount: Scalars['String']['output'];
  tradeType?: Maybe<SsiTradeType>;
  type: Scalars['String']['output'];
};

export type PartyStandardSettlementInstructionInput = {
  agentBusinessIdentifierCode: Scalars['String']['input'];
  beneficiaryBusinessIdentifierCode: Scalars['String']['input'];
  buyerSellerSafe: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  placeOfSettlementId: Scalars['GraphQLObjectId']['input'];
  securityAccount: Scalars['String']['input'];
  tradeType: SsiTradeType;
  type: PartyStandardSettlementInstructionType;
};

export enum PartyStandardSettlementInstructionType {
  Equity = 'Equity',
  FixedIncome = 'FixedIncome'
}

export enum PartyStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Inactive = 'Inactive'
}

export enum PartyType {
  Broker = 'Broker',
  ClearingBroker = 'ClearingBroker',
  ClearingHouse = 'ClearingHouse',
  Client = 'Client',
  CreditRatingAgency = 'CreditRatingAgency',
  Custodian = 'Custodian',
  EsgDataProvider = 'EsgDataProvider',
  Fund = 'Fund',
  Issuer = 'Issuer',
  /** Mandate denotes Discretionary Mandate */
  Mandate = 'Mandate',
  MarketDataProvider = 'MarketDataProvider',
  PlaceOfSettlement = 'PlaceOfSettlement',
  PrivateClient = 'PrivateClient'
}

export type PerformanceCache = {
  __typename?: 'PerformanceCache';
  _id: Scalars['GraphQLObjectId']['output'];
  cashFlows: Array<Maybe<Scalars['Float']['output']>>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  currency: CurrencyEnum;
  dates: Array<Scalars['GraphQLDateString']['output']>;
  lookThrough: Scalars['Boolean']['output'];
  performanceItemsIds: Array<Scalars['GraphQLObjectId']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type PerformanceFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum PeriodicityAdjustmentEnum {
  ACTUAL = 'ACTUAL',
  CALENDAR = 'CALENDAR',
  FISCAL = 'FISCAL'
}

export enum PeriodicitySelectionEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

export enum PermissionAssetEnum {
  Accounting = 'Accounting',
  Admin = 'Admin',
  Agreement = 'Agreement',
  Attachment = 'Attachment',
  BackOffice = 'BackOffice',
  BankAccountTransaction = 'BankAccountTransaction',
  Base = 'Base',
  Bloomberg = 'Bloomberg',
  Collateral = 'Collateral',
  ConfirmAccounting = 'ConfirmAccounting',
  Customer = 'Customer',
  Esg = 'Esg',
  Instrument = 'Instrument',
  Job = 'Job',
  Limit = 'Limit',
  Nav = 'Nav',
  Note = 'Note',
  /** Team asset */
  Operations = 'Operations',
  Party = 'Party',
  Performance = 'Performance',
  Playground = 'Playground',
  /** PortfolioManager used in frontend */
  PortfolioManager = 'PortfolioManager',
  /** Team asset */
  PortfolioManagers = 'PortfolioManagers',
  Position = 'Position',
  Price = 'Price',
  Property = 'Property',
  Quote = 'Quote',
  Reconciliation = 'Reconciliation',
  Report = 'Report',
  Sustainalytics = 'Sustainalytics',
  Ticket = 'Ticket',
  TimeSeries = 'TimeSeries',
  Transaction = 'Transaction',
  User = 'User',
  Valuation = 'Valuation'
}

export type PortfolioPerformance = {
  __typename?: 'PortfolioPerformance';
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  id: Scalars['GraphQLObjectId']['output'];
  instrumentPerformances?: Maybe<Array<Maybe<InstrumentPerformance>>>;
  performanceItems?: Maybe<Array<Maybe<PortfolioPerformanceItem>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

/** types with more properties for debugging purpose */
export type PortfolioPerformance2 = {
  __typename?: 'PortfolioPerformance2';
  cashFlows?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  currency?: Maybe<CurrencyEnum>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  id: Scalars['GraphQLObjectId']['output'];
  instrumentPerformances?: Maybe<Array<Maybe<InstrumentPerformance2>>>;
  performanceItems?: Maybe<Array<Maybe<PortfolioPerformanceItem>>>;
  rawCashFlows?: Maybe<Array<Maybe<CashFlowItem>>>;
  series?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  totalReturns?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type PortfolioPerformanceItem = {
  __typename?: 'PortfolioPerformanceItem';
  cashFlow?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  serie?: Maybe<Scalars['Float']['output']>;
  totalReturn?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type PortfolioSwapItemValuation = {
  __typename?: 'PortfolioSwapItemValuation';
  date: Scalars['GraphQLDateString']['output'];
  value: PortfolioSwapItemValue;
};

export type PortfolioSwapItemValue = {
  __typename?: 'PortfolioSwapItemValue';
  currency: CurrencyEnum;
  price: Scalars['Float']['output'];
};

export type PortfolioSwapItemsPosition = {
  __typename?: 'PortfolioSwapItemsPosition';
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isDebtInstrument: Scalars['Boolean']['output'];
  quantity: Scalars['Float']['output'];
  valuation: PortfolioSwapItemValuation;
};

export type Position = {
  __typename?: 'Position';
  account?: Maybe<PartyAccount>;
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  portfolioInstrument?: Maybe<Instrument>;
  portfolioInstrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type PositionFilter = {
  accountIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
};

export enum PositionSelectorType {
  AccountId = 'AccountId',
  ClientId = 'ClientId',
  ExternalAccountId = 'ExternalAccountId'
}

export enum PositionTypeEnum {
  CreateRedeem = 'CreateRedeem',
  OffBalance = 'OffBalance',
  OnBalance = 'OnBalance'
}

export type Price = {
  __typename?: 'Price';
  _id: Scalars['GraphQLObjectId']['output'];
  accruedInterest?: Maybe<Scalars['String']['output']>;
  cleanPrice?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  price: Scalars['String']['output'];
  sourceId: Scalars['GraphQLObjectId']['output'];
  spread?: Maybe<Scalars['String']['output']>;
  type: PriceTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type PriceFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  typeIn?: InputMaybe<Array<PriceTypeEnum>>;
};

export type PriceInput = {
  accruedInterest?: InputMaybe<Scalars['String']['input']>;
  cleanPrice?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  currency: CurrencyEnum;
  date: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  price: Scalars['String']['input'];
  spread?: InputMaybe<Scalars['String']['input']>;
};

export enum PriceType {
  AccruedInterest = 'AccruedInterest',
  CleanPrice = 'CleanPrice',
  Price = 'Price',
  PriceEstimate = 'PriceEstimate'
}

export enum PriceTypeEnum {
  Price = 'Price',
  PriceMapping = 'PriceMapping',
  Valuation = 'Valuation',
  ValuationMapping = 'ValuationMapping'
}

export enum PricingOptionEnum {
  PRICING_OPTION_PRICE = 'PRICING_OPTION_PRICE',
  PRICING_OPTION_YIELD = 'PRICING_OPTION_YIELD'
}

export type ProductInvolvement = {
  __typename?: 'ProductInvolvement';
  involvement: ProductInvolvementIndicator;
  range: RangeEnum;
};

export enum ProductInvolvementIndicator {
  AdultEntertainment = 'AdultEntertainment',
  AlcoholicBeverages = 'AlcoholicBeverages',
  ControversialWeapons = 'ControversialWeapons',
  Gambling = 'Gambling',
  MilitaryContracting = 'MilitaryContracting',
  OilGas = 'OilGas',
  OilSands = 'OilSands',
  SmallArms = 'SmallArms',
  ThermalCoal = 'ThermalCoal',
  TobaccoProducts = 'TobaccoProducts'
}

export type ProductInvolvementInput = {
  involvement: ProductInvolvementIndicator;
  range: RangeEnum;
};

export type PublishedToInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  /** Name of external recipient */
  keys: Array<Scalars['String']['input']>;
};

export type PublishedToType = {
  __typename?: 'PublishedToType';
  /** Name of external recipient */
  key: Scalars['String']['output'];
  timestamp: Scalars['Timestamp']['output'];
  userId: Scalars['GraphQLObjectId']['output'];
};

export type QuantLibPeriod = {
  __typename?: 'QuantLibPeriod';
  unit?: Maybe<QuantLibTimeUnitEnum>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum QuantLibTimeUnitEnum {
  Days = 'Days',
  Hours = 'Hours',
  Microseconds = 'Microseconds',
  Milliseconds = 'Milliseconds',
  Minutes = 'Minutes',
  Months = 'Months',
  Seconds = 'Seconds',
  Weeks = 'Weeks',
  Years = 'Years'
}

export type Query = {
  __typename?: 'Query';
  accountPerformance?: Maybe<PortfolioPerformance2>;
  accountingRun?: Maybe<AccountingRun>;
  accountingRunId: Scalars['GraphQLObjectId']['output'];
  accountingRuns?: Maybe<Array<Maybe<AccountingRun>>>;
  adjustmentAmount?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  agreements?: Maybe<Array<Maybe<Agreement>>>;
  allInstrumentsInPosition?: Maybe<Array<Maybe<InstrumentPosition>>>;
  annualReports: Array<AnnualReportType>;
  attachment?: Maybe<Attachment>;
  attachments: Array<Attachment>;
  bankAccountBalances: Array<BankAccountBalance>;
  bankAccountTransactions: Array<BankAccountTransaction>;
  /** Base calendars are unique by year combined with name */
  baseCalendars?: Maybe<Array<Maybe<BaseCalendar>>>;
  brokerTransactions: Array<BrokerTransaction>;
  calculateNav?: Maybe<NavData>;
  /**
   * Combining all dates from different years for calendar with the stated names or for
   * calendars in party.fundInfo for a specific fund
   */
  calendar?: Maybe<Calendar>;
  cashBalances?: Maybe<Array<Maybe<CashAccountBalance>>>;
  cashLadder?: Maybe<Array<Maybe<CashAccountLadder>>>;
  clientAccounts?: Maybe<Array<Maybe<PartyAccount>>>;
  clientsByAssets: Array<ClientForAsset>;
  corporateActions?: Maybe<Array<CorporateAction>>;
  costs?: Maybe<Array<Maybe<Cost>>>;
  curveDefinition?: Maybe<CurveDefinition>;
  curveDefinitionInstruments: Array<Instrument>;
  curveDefinitions?: Maybe<Array<Maybe<CurveDefinition>>>;
  custodianPositions: Array<CustodianApiPosition>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  dart?: Maybe<DartReport>;
  defaultQuery?: Maybe<Scalars['String']['output']>;
  exposuresCollaterals: Array<ExposureCollateral>;
  extensions?: Maybe<Array<Maybe<Extension>>>;
  externalAccountsPositions?: Maybe<Array<Maybe<Position>>>;
  firstTransaction?: Maybe<Transaction>;
  firstTransactions?: Maybe<Array<Transaction>>;
  fredSeriesObservations?: Maybe<Array<Maybe<FredObservation>>>;
  fredSeriess?: Maybe<Array<Maybe<FredSeries>>>;
  fundUnits?: Maybe<Array<Maybe<FundUnit>>>;
  fxValuations?: Maybe<Array<Maybe<FxValuation>>>;
  getRedisKey?: Maybe<Scalars['GraphQLJSON']['output']>;
  historicalData?: Maybe<Array<Maybe<HistoricalData>>>;
  instrument?: Maybe<Instrument>;
  instrumentAccountPositions?: Maybe<Array<Maybe<InstrumentAccountPosition>>>;
  instruments?: Maybe<Array<Maybe<Instrument>>>;
  instrumentsUsedBy?: Maybe<Array<Maybe<Instrument>>>;
  issuerprograms?: Maybe<Array<Maybe<IssuerProgram>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  journalEntries?: Maybe<Array<JournalEntry>>;
  journalEntry?: Maybe<JournalEntry>;
  lastValuations?: Maybe<Array<Maybe<Valuation>>>;
  limit?: Maybe<Array<Maybe<LimitData>>>;
  marginRequirements: Array<MarginRequirement>;
  me?: Maybe<Me>;
  miniTransaction?: Maybe<MiniTransaction>;
  miniTransactions?: Maybe<Array<Maybe<MiniTransaction>>>;
  nav?: Maybe<Nav>;
  navs?: Maybe<Array<Maybe<Nav>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  parties?: Maybe<Array<Maybe<Party>>>;
  party?: Maybe<Party>;
  partyExternalAccount?: Maybe<PartyExternalAccount>;
  partyUsage?: Maybe<Scalars['Boolean']['output']>;
  performance?: Maybe<PortfolioPerformance>;
  performance2?: Maybe<PortfolioPerformance2>;
  performance3?: Maybe<PortfolioPerformance2>;
  /**
   * performanceCA groups performance on external account. Not fully working since accounts by id will not find relevant account.
   * Temporary query to be deleted after analysis
   */
  performanceCA?: Maybe<PortfolioPerformance2>;
  performanceCache: Array<PerformanceCache>;
  portfolioSwapConstituents?: Maybe<Array<Maybe<PortfolioSwapItemsPosition>>>;
  positions?: Maybe<Array<Maybe<Position>>>;
  prices?: Maybe<Array<Maybe<Price>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  reconcileExposureCollateral: Array<ReconciledExposureCollateralType>;
  reconcileFundUnits?: Maybe<Array<FundUnitReconciliationType>>;
  reconcilePositions?: Maybe<Array<ReconciliationType>>;
  referenceData?: Maybe<Array<Maybe<ReferenceData>>>;
  registerExtracts?: Maybe<Array<RegisterExtract>>;
  report?: Maybe<Report>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  reports?: Maybe<Array<Maybe<Report>>>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  screens?: Maybe<Array<Maybe<Screen>>>;
  /** returns an array of clientAccount positions on selected day and per instruments */
  shareRegister?: Maybe<Array<ShareRegisterItem>>;
  shareRegisterDelta?: Maybe<Array<ShareRegisterItem>>;
  shareRegisterUnits?: Maybe<Array<ShareRegisterUnits>>;
  /** returns an array of clientAccount positions per day and per instruments */
  shareRegisters?: Maybe<Array<ShareRegisterItem>>;
  sustainalyticsDataModel: Array<SustainalyticsDataModel>;
  sustainalyticsDataService?: Maybe<Array<Maybe<SustainalyticsDataModelWithStatus>>>;
  sustainalyticsFieldDefinitions?: Maybe<Array<Maybe<SustainalyticsFieldDefinitionModel>>>;
  sustainalyticsFieldMappingDefinitions?: Maybe<Array<Maybe<SustainalyticsProductModel>>>;
  sustainalyticsFieldMappings?: Maybe<Array<Maybe<SustainalyticsProductModel>>>;
  sustainalyticsReport?: Maybe<SustainalyticsReport>;
  sustainalyticsReportService?: Maybe<Array<Maybe<SustainalyticsReport>>>;
  sustainalyticsReportUrl?: Maybe<SustainalyticsReportUrl>;
  sustainalyticsUniverseOfAccess?: Maybe<Array<Maybe<SustainalyticsSecurityListModel>>>;
  swifts?: Maybe<Array<Swift>>;
  tAccountChart?: Maybe<TAccountChart>;
  tAccountChartAccountsExport?: Maybe<Scalars['String']['output']>;
  tAccountChartMappingsExport?: Maybe<Scalars['String']['output']>;
  tAccountCharts?: Maybe<Array<Maybe<TAccountChart>>>;
  ticketUsers?: Maybe<Array<TicketUser>>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  timeserie?: Maybe<TimeSeries>;
  timeseries?: Maybe<Array<Maybe<TimeSeries>>>;
  tradingmanager?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  tradingmanager2?: Maybe<Array<Maybe<TradingManagerColumn>>>;
  transaction?: Maybe<Transaction>;
  transactionItems?: Maybe<Array<Maybe<TransactionItem>>>;
  transactionitemStatus?: Maybe<Scalars['Float']['output']>;
  transactions?: Maybe<Array<Transaction>>;
  transferAgentOrder?: Maybe<TransferAgentOrder>;
  transferAgentOrders?: Maybe<Array<Maybe<TransferAgentOrder>>>;
  transferAgentTotalUnits?: Maybe<Array<Maybe<TransferAgentTotalUnit>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  /** Used to assign tickets to teams */
  usersLimited: Array<UserLimited>;
  valuation?: Maybe<Valuation>;
  valuationmappings?: Maybe<Array<Maybe<ValuationMapping>>>;
  valuations?: Maybe<Array<Maybe<Valuation>>>;
};


export type QueryAccountPerformanceArgs = {
  accountId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAccountingRunArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryAccountingRunsArgs = {
  accountingPeriod?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  maxNumber?: InputMaybe<Scalars['Int']['input']>;
  statusIn?: InputMaybe<Array<AccountingRunStatusEnum>>;
};


export type QueryAdjustmentAmountArgs = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryAgreementsArgs = {
  filter?: InputMaybe<AgreementFilterInput>;
};


export type QueryAllInstrumentsInPositionArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientTypes?: InputMaybe<Array<PartyType>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentPositionType?: InputMaybe<InstrumentPositionType>;
  modelTypes?: InputMaybe<Array<InstrumentModelTypeEnum>>;
};


export type QueryAnnualReportsArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryAttachmentArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryAttachmentsArgs = {
  fileIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryBankAccountBalancesArgs = {
  filter?: InputMaybe<BankAccountBalanceFilterInput>;
};


export type QueryBankAccountTransactionsArgs = {
  filter?: InputMaybe<BankAccountTransactionFilterInput>;
};


export type QueryBaseCalendarsArgs = {
  filter?: InputMaybe<BaseCalendarFilterInput>;
};


export type QueryBrokerTransactionsArgs = {
  filter?: InputMaybe<BrokerTransactionFilterInput>;
};


export type QueryCalculateNavArgs = {
  date: Scalars['GraphQLDateString']['input'];
  fundId: Scalars['GraphQLObjectId']['input'];
};


export type QueryCalendarArgs = {
  fundId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  names?: InputMaybe<Array<CalendarNameEnum>>;
  years?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCashBalancesArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
  viewFromDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryCashLadderArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusNotIn?: InputMaybe<Array<TransactionStatus>>;
};


export type QueryClientAccountsArgs = {
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>>;
};


export type QueryCorporateActionsArgs = {
  filter?: InputMaybe<CorporateActionFilterInput>;
};


export type QueryCostsArgs = {
  filter?: InputMaybe<CostFilterInput>;
};


export type QueryCurveDefinitionArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryCustodianPositionsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  include?: InputMaybe<Array<InputMaybe<CustodianPositionType>>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  isinIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<StatusEnum>>;
  typeIn?: InputMaybe<Array<CustodianPositionType>>;
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilterInput>;
};


export type QueryDartArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryExposuresCollateralsArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
};


export type QueryExtensionsArgs = {
  filter?: InputMaybe<ExtensionsFilterInput>;
};


export type QueryExternalAccountsPositionsArgs = {
  custodianAccountNumbers: Array<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFirstTransactionArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
};


export type QueryFirstTransactionsArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
};


export type QueryFredSeriesObservationsArgs = {
  id: Scalars['String']['input'];
  observationEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  observationStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryFredSeriessArgs = {
  id: Scalars['String']['input'];
};


export type QueryFundUnitsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryFxValuationsArgs = {
  date?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
};


export type QueryGetRedisKeyArgs = {
  key: Scalars['String']['input'];
};


export type QueryHistoricalDataArgs = {
  adjustmentAbnormal?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentFollowDPDF?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentNormal?: InputMaybe<Scalars['Boolean']['input']>;
  adjustmentSplit?: InputMaybe<Scalars['Boolean']['input']>;
  calendarCodeOverride?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  enumField?: InputMaybe<HistoricalFieldsEnum>;
  field?: InputMaybe<Scalars['String']['input']>;
  machine?: InputMaybe<RefDataMachine>;
  maxDataPoints?: InputMaybe<Scalars['Int']['input']>;
  nonTradingDayFillMethod?: InputMaybe<NonTradingDayFillMethodEnum>;
  nonTradingDayFillOption?: InputMaybe<NonTradingDayFillOptionEnum>;
  overrideOption?: InputMaybe<OverrideOptionEnum>;
  periodicityAdjustment?: InputMaybe<PeriodicityAdjustmentEnum>;
  periodicitySelection?: InputMaybe<PeriodicitySelectionEnum>;
  pricingOption?: InputMaybe<PricingOptionEnum>;
  returnRelativeDate?: InputMaybe<Scalars['Boolean']['input']>;
  securities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate: Scalars['Date']['input'];
};


export type QueryInstrumentArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInstrumentAccountPositionsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  instrumentIds: Array<Scalars['GraphQLObjectId']['input']>;
};


export type QueryInstrumentsArgs = {
  aliasIn?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<InstrumentFilterInput>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryInstrumentsUsedByArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryIssuerprogramsArgs = {
  filter?: InputMaybe<IssuerProgramsFilterInput>;
};


export type QueryJournalEntriesArgs = {
  accountingPeriod?: InputMaybe<Scalars['String']['input']>;
  accountingRunId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  batch?: InputMaybe<AccountingBatchType>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryJournalEntryArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryLastValuationsArgs = {
  endDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryLimitArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryMarginRequirementsArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  dates?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
};


export type QueryMiniTransactionArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryMiniTransactionsArgs = {
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TransactionFilterInput>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
  type?: InputMaybe<Array<InputMaybe<TransactionType>>>;
};


export type QueryNavArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryNavsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<ReportStatusEnum>>;
};


export type QueryNotesArgs = {
  filter?: InputMaybe<NoteFilterInput>;
};


export type QueryPartiesArgs = {
  filter?: InputMaybe<PartyFilterInput>;
};


export type QueryPartyArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPartyExternalAccountArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  externalAccountId: Scalars['GraphQLObjectId']['input'];
};


export type QueryPartyUsageArgs = {
  partyId: Scalars['GraphQLObjectId']['input'];
};


export type QueryPerformanceArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
  useCache?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformance2Args = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformance3Args = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformanceCaArgs = {
  clientId: Scalars['GraphQLObjectId']['input'];
  filter?: InputMaybe<PerformanceFilterInput>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPerformanceCacheArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryPortfolioSwapConstituentsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioInstrumentId: Scalars['GraphQLObjectId']['input'];
};


export type QueryPositionsArgs = {
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PositionFilter>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPricesArgs = {
  filter?: InputMaybe<PriceFilterInput>;
};


export type QueryQuotesArgs = {
  filter?: InputMaybe<QuoteFilterInput>;
};


export type QueryReconcileExposureCollateralArgs = {
  clientIds: Array<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryReconcileFundUnitsArgs = {
  date: Scalars['GraphQLDateString']['input'];
  filterOutZeroDiff?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReconcilePositionsArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date: Scalars['GraphQLDateString']['input'];
};


export type QueryReferenceDataArgs = {
  enumFields?: InputMaybe<Array<InputMaybe<ReferenceFieldsEnum>>>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  machine?: InputMaybe<RefDataMachine>;
  securities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRegisterExtractsArgs = {
  filter?: InputMaybe<RegisterExtractFilterInput>;
};


export type QueryReportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryReportTypesArgs = {
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryReportsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  lastOnly?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<ReportStatusEnum>>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryRoleArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryRolesArgs = {
  statusIn?: InputMaybe<Array<DocumentStatusEnum>>;
};


export type QueryScreensArgs = {
  filter?: InputMaybe<FilterInput>;
};


export type QueryShareRegisterArgs = {
  filter?: InputMaybe<ShareRegisterFilterInput>;
};


export type QueryShareRegisterDeltaArgs = {
  fromDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  toDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type QueryShareRegisterUnitsArgs = {
  filter?: InputMaybe<ShareRegisterFilterInput>;
};


export type QueryShareRegistersArgs = {
  fromDate: Scalars['GraphQLDateString']['input'];
  toDate: Scalars['GraphQLDateString']['input'];
};


export type QuerySustainalyticsDataModelArgs = {
  fieldClusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldIds?: InputMaybe<Array<Scalars['String']['input']>>;
  identifiers: Array<Scalars['String']['input']>;
  packageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productId: Scalars['String']['input'];
};


export type QuerySustainalyticsDataServiceArgs = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  fieldClusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldIds?: InputMaybe<Array<Scalars['String']['input']>>;
  identifiers?: InputMaybe<Array<Scalars['String']['input']>>;
  packageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySustainalyticsReportArgs = {
  identifier: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type QuerySustainalyticsReportServiceArgs = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySustainalyticsReportUrlArgs = {
  identifier: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
};


export type QuerySwiftsArgs = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  filter?: InputMaybe<SwiftFilterInput>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<SwiftStatusEnum>>;
};


export type QueryTAccountChartArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTAccountChartAccountsExportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTAccountChartMappingsExportArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
  delimiter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTAccountChartsArgs = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTicketsArgs = {
  filter?: InputMaybe<TicketFilterInput>;
};


export type QueryTimeserieArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TimeSeriesFilterInput>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTimeseriesArgs = {
  filter?: InputMaybe<TimeSeriesFilterInput>;
  firstItem?: InputMaybe<Scalars['Boolean']['input']>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
  lastItem?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTradingmanagerArgs = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PositionFilter>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTradingmanager2Args = {
  baseCurrency?: InputMaybe<CurrencyEnum>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  excludeCollateral?: InputMaybe<Scalars['Boolean']['input']>;
  filterZeroPositions?: InputMaybe<Scalars['Boolean']['input']>;
  groupPositionsBy?: InputMaybe<PositionSelectorType>;
  includeAllCashAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  lookThrough?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTransactionArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryTransactionItemsArgs = {
  filter?: InputMaybe<TransactionItemFilterInput>;
};


export type QueryTransactionsArgs = {
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  filter?: InputMaybe<TransactionFilterInput>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
};


export type QueryTransferAgentOrderArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryTransferAgentOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
};


export type QueryTransferAgentTotalUnitsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
};


export type QueryUserArgs = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  personalNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type QueryUsersLimitedArgs = {
  filter?: InputMaybe<LimitedUserFilterInput>;
};


export type QueryValuationArgs = {
  _id: Scalars['GraphQLObjectId']['input'];
};


export type QueryValuationmappingsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  filter?: InputMaybe<ValuationMappingFilterInput>;
  valuationInstrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};


export type QueryValuationsArgs = {
  endDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLSwedenDate']['input']>;
};

export type Quote = {
  __typename?: 'Quote';
  _id: Scalars['GraphQLObjectId']['output'];
  ask?: Maybe<Scalars['String']['output']>;
  bid?: Maybe<Scalars['String']['output']>;
  /** Broker is the quoting party */
  brokerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  close?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  /** DataVendor is for example Bloomberg */
  dataVendorId: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  last?: Maybe<Scalars['String']['output']>;
  /** Market is for example exchange, OTC, NASDAQ, Turquoise etc */
  market?: Maybe<Scalars['String']['output']>;
  mid?: Maybe<Scalars['String']['output']>;
  type: QuoteTypeEnum;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type QuoteFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type QuoteInput = {
  ask?: InputMaybe<Scalars['String']['input']>;
  bid?: InputMaybe<Scalars['String']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  dataVendorId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  last?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  mid?: InputMaybe<Scalars['String']['input']>;
  quoteTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  type: QuoteTypeEnum;
};

export enum QuoteTypeEnum {
  Clean = 'Clean',
  PctOfNominal = 'PctOfNominal',
  PerUnit = 'PerUnit',
  Pips4 = 'Pips4',
  Spread = 'Spread',
  Yield = 'Yield'
}

export enum RangeEnum {
  R0To5 = 'R0To5',
  R5To10 = 'R5To10',
  R10To25 = 'R10To25',
  R25To50 = 'R25To50',
  R50To100 = 'R50To100'
}

export enum ReconcileTypeEnum {
  ExposureCollateral = 'ExposureCollateral',
  NettingSet = 'NettingSet',
  NettingSetBaseCurrency = 'NettingSetBaseCurrency'
}

export type ReconciledExposureCollateralType = {
  __typename?: 'ReconciledExposureCollateralType';
  agreementType: AgreementType;
  baseCurrency: CurrencyEnum;
  client?: Maybe<ClientInfo>;
  clientId: Scalars['GraphQLObjectId']['output'];
  counterpartyExposure: Scalars['Float']['output'];
  counterpartyHaircut: Scalars['Float']['output'];
  counterpartyInitialMarginCollateral: Scalars['Float']['output'];
  counterpartyInitialMarginNetRequirement: Scalars['Float']['output'];
  counterpartyInitialMarginPosted: Scalars['Float']['output'];
  counterpartyInitialMarginSecuritiesCollateral: Scalars['Float']['output'];
  counterpartyMarginCall: Scalars['Float']['output'];
  counterpartyNetExposure: Scalars['Float']['output'];
  counterpartyQuantity: Scalars['Float']['output'];
  counterpartyValuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  counterpartyVariationMarginCollateral: Scalars['Float']['output'];
  counterpartyVariationMarginPosted: Scalars['Float']['output'];
  counterpartyVariationMarginSecuritiesCollateral: Scalars['Float']['output'];
  creditYieldDeltaSum: Scalars['Float']['output'];
  currency: CurrencyEnum;
  exposure: Scalars['Float']['output'];
  exposureDifference: Scalars['Float']['output'];
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  haircut: Scalars['Float']['output'];
  haircutDifference: Scalars['Float']['output'];
  initialMarginCollateral: Scalars['Float']['output'];
  initialMarginCollateralDifference: Scalars['Float']['output'];
  initialMarginNetRequirement: Scalars['Float']['output'];
  initialMarginRequirement: Scalars['Float']['output'];
  initialMarginSecuritiesCollateral: Scalars['Float']['output'];
  initialMarginSecuritiesCollateralDifference: Scalars['Float']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  interestRateYieldDeltaSum: Scalars['Float']['output'];
  marginCall: Scalars['Float']['output'];
  marginCallsMade: Scalars['Boolean']['output'];
  marginType?: Maybe<MarginType>;
  minimumTransferAmount: Scalars['Float']['output'];
  missingReport?: Maybe<Scalars['Boolean']['output']>;
  netExposure: Scalars['Float']['output'];
  netExposureDifference: Scalars['Float']['output'];
  netInitialMarginRequirementDifference: Scalars['Float']['output'];
  noCollateralRequirement: Scalars['Boolean']['output'];
  quantity: Scalars['Float']['output'];
  quantityDifference: Scalars['Float']['output'];
  reconcileType: ReconcileTypeEnum;
  roundingAmount: Scalars['Float']['output'];
  threshold: Scalars['Float']['output'];
  type?: Maybe<ExposureCollateralType>;
  valuationDate: Scalars['GraphQLDateString']['output'];
  variationMarginCollateral: Scalars['Float']['output'];
  variationMarginCollateralDifference: Scalars['Float']['output'];
  variationMarginPosted: Scalars['Float']['output'];
  variationMarginSecuritiesCollateral: Scalars['Float']['output'];
  variationMarginSecuritiesCollateralDifference: Scalars['Float']['output'];
};

export type ReconciliationType = {
  __typename?: 'ReconciliationType';
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  custodian?: Maybe<PartyInfo>;
  custodianDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  custodianId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodianPositionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  custodianQuantity?: Maybe<Scalars['Float']['output']>;
  diff?: Maybe<Scalars['Float']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  positionDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  positionQuantity?: Maybe<Scalars['Float']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type RefDataError = {
  __typename?: 'RefDataError';
  category?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  source?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subcategory?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RefDataFieldException = {
  __typename?: 'RefDataFieldException';
  errors?: Maybe<Array<RefDataError>>;
  fieldId?: Maybe<Array<Scalars['String']['output']>>;
};

export type RefDataFieldExceptionsArray = {
  __typename?: 'RefDataFieldExceptionsArray';
  fieldExceptions?: Maybe<Array<RefDataFieldException>>;
};

export enum RefDataMachine {
  Daka = 'Daka',
  Office = 'Office'
}

export type ReferenceData = {
  __typename?: 'ReferenceData';
  eidDataArray?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fieldData?: Maybe<Array<Maybe<FieldDataItem>>>;
  fieldExceptionsArray?: Maybe<Array<RefDataFieldExceptionsArray>>;
  id?: Maybe<Scalars['GraphQLObjectId']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  security?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  securityError?: Maybe<Array<RefDataError>>;
};

export enum ReferenceFieldsEnum {
  CDS_SPREAD_MID_MODIFIED_DURATION = 'CDS_SPREAD_MID_MODIFIED_DURATION',
  CFI_CODE = 'CFI_CODE',
  CNTRY_OF_DOMICILE = 'CNTRY_OF_DOMICILE',
  COMPANY_IS_PRIVATE = 'COMPANY_IS_PRIVATE',
  CPN_TYP = 'CPN_TYP',
  CRNCY = 'CRNCY',
  DAYS_TO_SETTLE = 'DAYS_TO_SETTLE',
  DISC_MRGN_MID = 'DISC_MRGN_MID',
  DUR_ADJ_MID = 'DUR_ADJ_MID',
  ECBC_COVERED_BD_LABEL_INDICATOR = 'ECBC_COVERED_BD_LABEL_INDICATOR',
  EXCH_CODE = 'EXCH_CODE',
  GB_MANAGEMENT_PROCEEDS = 'GB_MANAGEMENT_PROCEEDS',
  GICS_SUB_INDUSTRY = 'GICS_SUB_INDUSTRY',
  ID_BB_COMPANY = 'ID_BB_COMPANY',
  ID_EXCH_SYMBOL = 'ID_EXCH_SYMBOL',
  ID_ISIN = 'ID_ISIN',
  INDUSTRY_SUBGROUP_NUM = 'INDUSTRY_SUBGROUP_NUM',
  ISSUER = 'ISSUER',
  ISSUER_INDUSTRY = 'ISSUER_INDUSTRY',
  LAST_UPDATE_BID_RT = 'LAST_UPDATE_BID_RT',
  LAST_UPDATE_DT_EXCH_TZ = 'LAST_UPDATE_DT_EXCH_TZ',
  LEGAL_ENTITY_IDENTIFIER = 'LEGAL_ENTITY_IDENTIFIER',
  MARKET_SECTOR_DES = 'MARKET_SECTOR_DES',
  PAR_AMT = 'PAR_AMT',
  PAYMENT_RANK = 'PAYMENT_RANK',
  PX_ASK = 'PX_ASK',
  PX_BID = 'PX_BID',
  PX_CLOSE = 'PX_CLOSE',
  PX_LAST = 'PX_LAST',
  RTG_FITCH = 'RTG_FITCH',
  RTG_FITCH_LONG = 'RTG_FITCH_LONG',
  RTG_FITCH_LT_ISSUER_DEFAULT = 'RTG_FITCH_LT_ISSUER_DEFAULT',
  RTG_MDY_ISSUER = 'RTG_MDY_ISSUER',
  RTG_MOODY = 'RTG_MOODY',
  RTG_MOODY_LONG_TERM = 'RTG_MOODY_LONG_TERM',
  RTG_SP = 'RTG_SP',
  RTG_SP_LONG = 'RTG_SP_LONG',
  RTG_SP_LT_LC_ISSUER_CREDIT = 'RTG_SP_LT_LC_ISSUER_CREDIT',
  SECURITY_NAME = 'SECURITY_NAME',
  SEC_ID_WITH_YK_PARSEKYABLE_RT = 'SEC_ID_WITH_YK_PARSEKYABLE_RT',
  SERIES = 'SERIES',
  TICKER = 'TICKER',
  TIME = 'TIME',
  YAS_ISPREAD_TO_GOVT = 'YAS_ISPREAD_TO_GOVT',
  YLD_YTM_MID = 'YLD_YTM_MID'
}

export type RegisterExtract = {
  __typename?: 'RegisterExtract';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  date: Scalars['GraphQLDateString']['output'];
  items: Array<RegisterExtractItem>;
  source: PartyInfo;
  sourceId: Scalars['GraphQLObjectId']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
};

export type RegisterExtractFilterInput = {
  dateIn?: InputMaybe<Array<Scalars['GraphQLDateString']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
};

export type RegisterExtractInput = {
  /** if _id is omitted, document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  date: Scalars['GraphQLDateString']['input'];
  items: Array<RegisterExtractItemInput>;
  sourceId: Scalars['GraphQLObjectId']['input'];
};

export type RegisterExtractItem = {
  __typename?: 'RegisterExtractItem';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: CountryCodeEnum;
  date: Scalars['GraphQLDateString']['output'];
  detailedCustomerSpecifics: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fax: Scalars['String']['output'];
  fundClassName: Scalars['String']['output'];
  fundId: Scalars['String']['output'];
  fundPrice: Scalars['String']['output'];
  fundShortName: Scalars['String']['output'];
  instrumentId: Scalars['GraphQLObjectId']['output'];
  isin: Scalars['String']['output'];
  legalPerson: Scalars['String']['output'];
  nationalRegistrationId: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  purchaseAmount: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  taClientAccount: Scalars['String']['output'];
  taClientAccountLabel: Scalars['String']['output'];
  taClientName: Scalars['String']['output'];
  taxCountry: CountryCodeEnum;
};

export type RegisterExtractItemInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: CountryCodeEnum;
  date: Scalars['GraphQLDateString']['input'];
  detailedCustomerSpecifics: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fax: Scalars['String']['input'];
  fundClassName: Scalars['String']['input'];
  fundId: Scalars['String']['input'];
  fundPrice: Scalars['String']['input'];
  fundShortName: Scalars['String']['input'];
  instrumentId: Scalars['GraphQLObjectId']['input'];
  isin: Scalars['String']['input'];
  legalPerson: Scalars['String']['input'];
  nationalRegistrationId: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  purchaseAmount: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  taClientAccount: Scalars['String']['input'];
  taClientAccountLabel: Scalars['String']['input'];
  taClientName: Scalars['String']['input'];
  taxCountry: CountryCodeEnum;
};

export type Report = {
  __typename?: 'Report';
  _id: Scalars['GraphQLObjectId']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientName: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  data?: Maybe<Scalars['GraphQLJSON']['output']>;
  date: Scalars['GraphQLDateString']['output'];
  /** fileId is the same as reportId, both is an id of a file */
  fileId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  reportId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  sourceId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ReportStatusEnum>;
  type: Scalars['String']['output'];
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export enum ReportStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  /** When locked, not possible to edit */
  Locked = 'Locked'
}

export type Role = {
  __typename?: 'Role';
  _id: Scalars['String']['output'];
  assets: Array<PermissionAssetEnum>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<RoleClientInfo>;
  comment?: Maybe<Scalars['String']['output']>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  name: Scalars['String']['output'];
  nbrOfActiveUsers: Scalars['Int']['output'];
  nbrOfUsers: Scalars['Int']['output'];
  permissionType: PermissionTypeEnum;
  roleType: RoleTypeEnum;
  status: DocumentStatusEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type RoleClientInfo = {
  __typename?: 'RoleClientInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum RoleTypeEnum {
  Backend = 'Backend',
  Frontend = 'Frontend'
}

export type RoundTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  /** Apply +/- according to how it should be applied to settlementAmount, same currency as settlement currency */
  rounding: Scalars['Float']['input'];
};

export enum ScienceBasedTargetInitiativeEnum {
  Committed = 'Committed',
  NoTarget = 'NoTarget',
  TargetSet = 'TargetSet'
}

export type Screen = {
  __typename?: 'Screen';
  _id: Scalars['GraphQLObjectId']['output'];
  approved?: Maybe<Scalars['Boolean']['output']>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  issuer?: Maybe<Party>;
  issuerProgram?: Maybe<IssuerProgram>;
  /** document in client or issuerprogram collection depending on screenType */
  objectId: Scalars['GraphQLObjectId']['output'];
  screenType?: Maybe<ScreenType>;
  status?: Maybe<DocumentStatusEnum>;
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId: Scalars['GraphQLObjectId']['output'];
  updateUserInfo: UserInfo;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type ScreenInput = {
  approved: Scalars['Boolean']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  objectId: Scalars['GraphQLObjectId']['input'];
  screenType: ScreenType;
  status?: InputMaybe<DocumentStatusEnum>;
};

export enum ScreenType {
  Issuer = 'Issuer',
  IssuerProgram = 'IssuerProgram'
}

export type Selector = {
  __typename?: 'Selector';
  accountIds: Array<Maybe<Scalars['GraphQLObjectId']['output']>>;
  currencies: Array<Maybe<Scalars['String']['output']>>;
  instrumentIds: Array<Maybe<Scalars['GraphQLObjectId']['output']>>;
  statuses: Array<Maybe<Scalars['String']['output']>>;
};

export type SelectorInput = {
  accountIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  currencies: Array<InputMaybe<Scalars['String']['input']>>;
  instrumentIds: Array<InputMaybe<Scalars['GraphQLObjectId']['input']>>;
  statuses: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ShareRegisterFilterInput = {
  tradeDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type ShareRegisterItem = {
  __typename?: 'ShareRegisterItem';
  /** sum of settledAmount on orders */
  amount: Scalars['String']['output'];
  clientAccount: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  /** summed upon to and including date */
  date: Scalars['GraphQLDateString']['output'];
  fund?: Maybe<PartyInfo>;
  /** fundId */
  fundId: Scalars['GraphQLObjectId']['output'];
  instrument: Instrument;
  isin: Scalars['String']['output'];
  /** sum of settledUnits on orders */
  units: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ShareRegisterUnits = {
  __typename?: 'ShareRegisterUnits';
  /** summed upon to and including date */
  date: Scalars['GraphQLDateString']['output'];
  fund?: Maybe<PartyInfo>;
  /** fundId */
  fundId: Scalars['GraphQLObjectId']['output'];
  instrument: Instrument;
  isin: Scalars['String']['output'];
  /** sum of settledUnits on orders */
  units: Scalars['String']['output'];
};

export type ShareholderEngagement = {
  __typename?: 'ShareholderEngagement';
  agm: Scalars['GraphQLDateString']['output'];
  comment: Scalars['String']['output'];
  date: Scalars['GraphQLDateString']['output'];
  method: EngagementMethod;
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
};

export type ShareholderEngagementInput = {
  agm: Scalars['GraphQLDateString']['input'];
  comment: Scalars['String']['input'];
  date: Scalars['GraphQLDateString']['input'];
  method: EngagementMethod;
  norm: StandardScreenNorm;
  principle: StandardScreenPrinciple;
};

export enum SourceType {
  BloombergFit = 'BloombergFit',
  Coacs = 'Coacs',
  Emsx = 'Emsx',
  Fxgo = 'Fxgo',
  Internal = 'Internal',
  MarkitWire = 'MarkitWire',
  Tradeweb = 'Tradeweb'
}

export enum SsiTradeType {
  Collateral = 'Collateral',
  Security = 'Security'
}

export enum StandardScreenNorm {
  BusinessEthics = 'BusinessEthics',
  Environment = 'Environment',
  HumanRights = 'HumanRights',
  LabourRights = 'LabourRights',
  None = 'None'
}

export enum StandardScreenPrinciple {
  None = 'None',
  Principle1 = 'Principle1',
  Principle2 = 'Principle2',
  Principle3 = 'Principle3',
  Principle4 = 'Principle4',
  Principle5 = 'Principle5',
  Principle6 = 'Principle6',
  Principle7 = 'Principle7',
  Principle8 = 'Principle8',
  Principle9 = 'Principle9',
  Principle10 = 'Principle10'
}

export enum StandardScreenStatus {
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant',
  Watchlist = 'Watchlist'
}

export enum StatusEnum {
  Active = 'Active',
  Deleted = 'Deleted'
}

export enum SustainableDevelopmentGoal {
  /** No Poverty */
  Goal1_NoPoverty = 'Goal1_NoPoverty',
  /** Zero Hunger */
  Goal2_ZeroHunger = 'Goal2_ZeroHunger',
  /** Good Health and Well-being */
  Goal3_GoodHealthAndWellBeing = 'Goal3_GoodHealthAndWellBeing',
  /** Quality Education */
  Goal4_QualityEducation = 'Goal4_QualityEducation',
  /** Gender Equality */
  Goal5_GenderEquality = 'Goal5_GenderEquality',
  /** Clean Water and Sanitation */
  Goal6_CleanWaterAndSanitation = 'Goal6_CleanWaterAndSanitation',
  /** Affordable and Clean Energy */
  Goal7_AffordableAndCleanEnergy = 'Goal7_AffordableAndCleanEnergy',
  /** Decent Work and Economic Growth */
  Goal8_DecentWorkAndEconomicGrowth = 'Goal8_DecentWorkAndEconomicGrowth',
  /** Industry, Innovation and Infrastructure */
  Goal9_IndustryInnovationAndInfrastructure = 'Goal9_IndustryInnovationAndInfrastructure',
  /** Reduced Inequality */
  Goal10_ReducedInequality = 'Goal10_ReducedInequality',
  /** Sustainable Cities and Communities */
  Goal11_SustainableCitiesAndCommunities = 'Goal11_SustainableCitiesAndCommunities',
  /** Responsible Consumption and Production */
  Goal12_ResponsibleConsumptionAndProduction = 'Goal12_ResponsibleConsumptionAndProduction',
  /** CLimate Action */
  Goal13_ClimateAction = 'Goal13_ClimateAction',
  /** Life Below Water */
  Goal14_LifeBelowWater = 'Goal14_LifeBelowWater',
  /** Life on Land */
  Goal15_LifeOnLand = 'Goal15_LifeOnLand',
  /** Peace, Justice and Strong Institutions */
  Goal16_PeaceJusticeAndStrongInstitutions = 'Goal16_PeaceJusticeAndStrongInstitutions',
  /** Partnerships to achieve the Goal */
  Goal17_PartnershipsToAchieveTheGoal = 'Goal17_PartnershipsToAchieveTheGoal'
}

export type SustainalyticsDataModel = {
  __typename?: 'SustainalyticsDataModel';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  fieldNames: Array<SustainalyticsFieldDefinitionModel>;
  fields?: Maybe<Scalars['GraphQLJSON']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsDataModelWithStatus = {
  __typename?: 'SustainalyticsDataModelWithStatus';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['GraphQLJSON']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SustainalyticsStatus>;
};

export type SustainalyticsFieldClusterModel = {
  __typename?: 'SustainalyticsFieldClusterModel';
  fieldClusterId?: Maybe<Scalars['String']['output']>;
  fieldClusterName?: Maybe<Scalars['String']['output']>;
  fieldDefinitions?: Maybe<Array<Maybe<SustainalyticsFieldDefinitionModel>>>;
  fieldIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type SustainalyticsFieldDefinitionModel = {
  __typename?: 'SustainalyticsFieldDefinitionModel';
  description?: Maybe<Scalars['String']['output']>;
  fieldId?: Maybe<Scalars['String']['output']>;
  fieldLength?: Maybe<Scalars['String']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<Scalars['String']['output']>;
  possibleValues?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsPackageModel = {
  __typename?: 'SustainalyticsPackageModel';
  clusters?: Maybe<Array<Maybe<SustainalyticsFieldClusterModel>>>;
  packageId?: Maybe<Scalars['String']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsProductModel = {
  __typename?: 'SustainalyticsProductModel';
  packages?: Maybe<Array<Maybe<SustainalyticsPackageModel>>>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsReport = {
  __typename?: 'SustainalyticsReport';
  entityId?: Maybe<Scalars['String']['output']>;
  entityName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  issuerId?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  reports?: Maybe<Array<Maybe<SustainalyticsReportModel>>>;
};

export type SustainalyticsReportModel = {
  __typename?: 'SustainalyticsReportModel';
  lastUpdate?: Maybe<Scalars['String']['output']>;
  reportId?: Maybe<Scalars['String']['output']>;
  reportName?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsReportUrl = {
  __typename?: 'SustainalyticsReportUrl';
  filename?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsSecurityListModel = {
  __typename?: 'SustainalyticsSecurityListModel';
  entityIds?: Maybe<Array<Scalars['String']['output']>>;
  securityListId?: Maybe<Scalars['String']['output']>;
  securityListName?: Maybe<Scalars['String']['output']>;
};

export type SustainalyticsStatus = {
  __typename?: 'SustainalyticsStatus';
  hasPermissions?: Maybe<Scalars['Boolean']['output']>;
  matched?: Maybe<Scalars['String']['output']>;
};

export type Swaplab2Results = {
  __typename?: 'Swaplab2Results';
  bpShiftDelta?: Maybe<Scalars['Float']['output']>;
  bpShiftVega?: Maybe<Scalars['Float']['output']>;
  bsDelta?: Maybe<Scalars['Float']['output']>;
  bsVega?: Maybe<Scalars['Float']['output']>;
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  interestRateYieldDeltaBuckets: Array<YieldDeltaBucket>;
  pctDelta?: Maybe<Scalars['Float']['output']>;
};

export enum SwedishFinancialAuthorityCategoryEnum {
  Banks = 'Banks',
  Emu = 'Emu',
  HousingInstitutes = 'HousingInstitutes',
  MunicipalAdministration = 'MunicipalAdministration',
  OfWhichThePensionsAuthority = 'OfWhichThePensionsAuthority',
  OtherEu = 'OtherEu',
  OtherForeign = 'OtherForeign',
  OtherSwedishFinancialInstitutions = 'OtherSwedishFinancialInstitutions',
  PensionInstitutes = 'PensionInstitutes',
  SocialSecurityFunds = 'SocialSecurityFunds',
  SumForeign = 'SumForeign',
  SwedishFinancialCompanies = 'SwedishFinancialCompanies',
  SwedishHouseholds = 'SwedishHouseholds',
  SwedishInsuranceCompanies = 'SwedishInsuranceCompanies',
  Unknown = 'Unknown'
}

export type Swift = {
  __typename?: 'Swift';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  correspondingTransactionId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  error?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parsed: Scalars['GraphQLJSON']['output'];
  /**
   * Currently the swift original is a report. We want to move it to
   * the "text" field.
   */
  reportIds: Array<Scalars['GraphQLObjectId']['output']>;
  status: SwiftStatusEnum;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type SwiftFilterInput = {
  /** Corporate action event */
  caev?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Corresponding transaction */
  correspondingTransactionId?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** Corporate action event reference */
  externalId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Message type */
  msgType?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record date, format: YYYY-MM-DD */
  rdteEnd?: InputMaybe<Scalars['String']['input']>;
  /** Record date, format: YYYY-MM-DD */
  rdteStart?: InputMaybe<Scalars['String']['input']>;
};

export enum SwiftStatusEnum {
  Confirmed = 'Confirmed',
  Deleted = 'Deleted',
  Pending = 'Pending',
  Preliminary = 'Preliminary',
  /** Messages of messageType 566 gets status settled when matched */
  Settled = 'Settled',
  /** Used for messageType 566 when original swift of messageType 564 has been updated and swift of messageType is insignificant */
  UpdatedPrevious = 'UpdatedPrevious',
  /**
   * Used for messages with corporateActionEvent: DRIP/DVOP, these are received earlier (not received two days after ex-date
   * like other corporateActionEvents)
   */
  Waiting = 'Waiting'
}

export type TAccount = {
  __typename?: 'TAccount';
  description?: Maybe<Scalars['String']['output']>;
  group1?: Maybe<TAccountGroup1Enum>;
  group2?: Maybe<TAccountGroup2Enum>;
  group3?: Maybe<TAccountGroup3Enum>;
  group4?: Maybe<TAccountGroup4Enum>;
  group5?: Maybe<TAccountGroup5Enum>;
  number: Scalars['String']['output'];
  sruCode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TAccountTypeEnum>;
};

export type TAccountChart = {
  __typename?: 'TAccountChart';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  createUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tAccountMappings?: Maybe<Array<TAccountMapping>>;
  tAccounts?: Maybe<Array<TAccount>>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum TAccountGroup1Enum {
  Assets = 'Assets',
  Equity = 'Equity',
  Liabilities = 'Liabilities'
}

export enum TAccountGroup2Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  DividendPaid = 'DividendPaid',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  ProfitLossCurrentYear = 'ProfitLossCurrentYear',
  Q = 'Q',
  R = 'R',
  RetainedEarnings = 'RetainedEarnings',
  S = 'S',
  ShareCapital = 'ShareCapital',
  T = 'T'
}

export enum TAccountGroup3Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  Compensation = 'Compensation',
  Contribution = 'Contribution',
  D = 'D',
  DividendPaid = 'DividendPaid',
  E = 'E',
  Expenses = 'Expenses',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  RetainedEarnings = 'RetainedEarnings',
  Revenue = 'Revenue',
  S = 'S',
  ShareCapital = 'ShareCapital',
  T = 'T',
  Tax = 'Tax'
}

export enum TAccountGroup4Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  ManagementFees = 'ManagementFees',
  N = 'N',
  None = 'None',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T'
}

export enum TAccountGroup5Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  None = 'None',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T'
}

export type TAccountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  group1?: InputMaybe<Scalars['String']['input']>;
  group2?: InputMaybe<Scalars['String']['input']>;
  group3?: InputMaybe<Scalars['String']['input']>;
  group4?: InputMaybe<Scalars['String']['input']>;
  group5?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  sruCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TAccountTypeEnum>;
};

export type TAccountMapping = {
  __typename?: 'TAccountMapping';
  name?: Maybe<Scalars['String']['output']>;
  selector: TAccountMappingSelector;
  values?: Maybe<Array<TAccountMappingKeyValuePair>>;
};

export type TAccountMappingInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  selector: TAccountMappingSelectorInput;
  values?: InputMaybe<Array<TAccountMappingKeyValuePairInput>>;
};

export type TAccountMappingKeyValuePair = {
  __typename?: 'TAccountMappingKeyValuePair';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TAccountMappingKeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TAccountMappingSelector = {
  __typename?: 'TAccountMappingSelector';
  categories?: Maybe<Array<Scalars['String']['output']>>;
  currencies?: Maybe<Array<Scalars['String']['output']>>;
  instruments?: Maybe<Array<InstrumentSelector>>;
  key?: Maybe<TAccountMappingSelectorKeyEnum>;
  notMatchCategories?: Maybe<Scalars['Boolean']['output']>;
  notMatchCurrencies?: Maybe<Scalars['Boolean']['output']>;
  notMatchProductTypes?: Maybe<Scalars['Boolean']['output']>;
  productTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type TAccountMappingSelectorInput = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  currencies?: InputMaybe<Array<Scalars['String']['input']>>;
  instruments?: InputMaybe<Array<InstrumentSelectorInput>>;
  key?: InputMaybe<TAccountMappingSelectorKeyEnum>;
  notMatchCategories?: InputMaybe<Scalars['Boolean']['input']>;
  notMatchCurrencies?: InputMaybe<Scalars['Boolean']['input']>;
  notMatchProductTypes?: InputMaybe<Scalars['Boolean']['input']>;
  productTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum TAccountMappingSelectorKeyEnum {
  Equity = 'Equity',
  OpeningBalance = 'OpeningBalance',
  Rounding = 'Rounding'
}

export enum TAccountTypeEnum {
  Asset = 'Asset',
  Income = 'Income',
  Liability = 'Liability'
}

/**
 * Subset of PermissionAssetEnum, used for validation since a role with any value from TeamPermissionAssetEnum can only
 * have length 1
 */
export enum TeamPermissionAssetEnum {
  /** Used for Operations team role. One role/team. */
  Operations = 'Operations',
  /** Used for PortfolioManagers team role. One role/team. */
  PortfolioManagers = 'PortfolioManagers'
}

export type Ticket = {
  __typename?: 'Ticket';
  _id: Scalars['GraphQLObjectId']['output'];
  client: PartyInfo;
  clientId: Scalars['GraphQLObjectId']['output'];
  createTimestamp: Scalars['Timestamp']['output'];
  creator: UserInfo;
  creatorId: Scalars['GraphQLObjectId']['output'];
  previousStates: Array<TicketState>;
  readByUserIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  references?: Maybe<Array<TicketReference>>;
  state: TicketState;
  title: Scalars['String']['output'];
  type: TicketTypeEnum;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type TicketFilterInput = {
  assigneeIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  creatorIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  statusIn?: InputMaybe<Array<TicketStatusEnum>>;
  typeIn?: InputMaybe<Array<TicketTypeEnum>>;
};

export type TicketInput = {
  /** if _id is omitted, a document will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  references?: InputMaybe<Array<TicketReferenceInput>>;
  state: TicketStateInput;
  title: Scalars['String']['input'];
  type: TicketTypeEnum;
};

export type TicketReference = {
  __typename?: 'TicketReference';
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['output'];
};

export type TicketReferenceInput = {
  collection: CollectionNameEnum;
  documentId: Scalars['GraphQLObjectId']['input'];
};

export type TicketState = {
  __typename?: 'TicketState';
  assigneeIds: Array<Scalars['GraphQLObjectId']['output']>;
  assignees: Array<UserInfo>;
  /** comment is a markdown string */
  comment: Scalars['String']['output'];
  status: TicketStatusEnum;
  timestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
};

export type TicketStateInput = {
  assigneeIds: Array<Scalars['GraphQLObjectId']['input']>;
  comment: Scalars['String']['input'];
  status: TicketStatusEnum;
};

export enum TicketStatusEnum {
  Closed = 'Closed',
  Open = 'Open'
}

export enum TicketTypeEnum {
  AccountingRunStatusError = 'AccountingRunStatusError',
  CorporateActionError = 'CorporateActionError',
  Error = 'Error',
  ExposureCollateralError = 'ExposureCollateralError',
  ExternalDataError = 'ExternalDataError',
  FundUnitReconciliationError = 'FundUnitReconciliationError',
  MarginCall = 'MarginCall',
  MatchBrokerTransactionError = 'MatchBrokerTransactionError',
  PositionReconciliationError = 'PositionReconciliationError'
}

export type TicketUser = {
  __typename?: 'TicketUser';
  _id: Scalars['GraphQLObjectId']['output'];
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients?: Maybe<Array<Maybe<PartyInfo>>>;
  name: Scalars['String']['output'];
};

export type TimeSeries = {
  __typename?: 'TimeSeries';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  dates?: Maybe<Array<Maybe<Scalars['GraphQLDateString']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  /** The level of Value At Risk expressed as number, e.g 0.95 for 95% VaR */
  downSideVar?: Maybe<Scalars['Float']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  instrument?: Maybe<Instrument>;
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  items?: Maybe<Array<Maybe<TimeSeriesItem>>>;
  /** The objectId points either to an instrument or a portfolio */
  objectId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status: TimeSeriesStatus;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  volatility?: Maybe<Scalars['Float']['output']>;
};


export type TimeSeriesDownSideVarArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  level?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TimeSeriesVolatilityArgs = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type TimeSeriesFilterInput = {
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** ignored for timeserie() */
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** ignored for timeserie() */
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  /** ignored for timeserie() */
  instrumentIsinIn?: InputMaybe<Array<Scalars['ISIN']['input']>>;
  /** ignored for timeserie() */
  instrumentNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  /** ignored for timeserie() */
  statusIn?: InputMaybe<Array<TimeSeriesStatus>>;
  /** ignored for timeserie() */
  typeIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TimeSeriesInput = {
  /** If _id is omitted, a TimeSeries will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  /** clientId defaults to SYSTEM */
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /**
   * Type is typically one of: Price(Close),
   * Price(Last), Return(Total), Volume
   */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TimeSeriesItem = {
  __typename?: 'TimeSeriesItem';
  _id: Scalars['GraphQLObjectId']['output'];
  date?: Maybe<Scalars['GraphQLDateString']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type TimeSeriesItemInput = {
  date: Scalars['GraphQLDateString']['input'];
  value: Scalars['Float']['input'];
};

export enum TimeSeriesStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type TimeSeriesUpsertInput = {
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  items: Array<TimeSeriesItemInput>;
  type: Scalars['String']['input'];
};

export enum TimezoneEnum {
  AmericaChicago = 'AmericaChicago',
  AmericaNew_York = 'AmericaNew_York',
  AsiaHong_Kong = 'AsiaHong_Kong',
  AsiaTokyo = 'AsiaTokyo',
  EuropeAmsterdam = 'EuropeAmsterdam',
  EuropeBerlin = 'EuropeBerlin',
  EuropeBrussels = 'EuropeBrussels',
  EuropeCopenhagen = 'EuropeCopenhagen',
  EuropeHelsinki = 'EuropeHelsinki',
  EuropeLondon = 'EuropeLondon',
  EuropeLuxembourg = 'EuropeLuxembourg',
  EuropeOslo = 'EuropeOslo',
  EuropeParis = 'EuropeParis',
  EuropeStockholm = 'EuropeStockholm',
  EuropeZurich = 'EuropeZurich'
}

export enum TradeItemType {
  AccruedInterest = 'AccruedInterest',
  Commission = 'Commission',
  StampDuty = 'StampDuty'
}

export type TradingManagerColumn = {
  __typename?: 'TradingManagerColumn';
  account?: Maybe<PartyAccount>;
  accountDescription?: Maybe<Scalars['String']['output']>;
  /** // Account data */
  accountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  baseCurrency?: Maybe<CurrencyEnum>;
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  client?: Maybe<ClientInfo>;
  /** // Client data */
  clientId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  creditRatings?: Maybe<Scalars['GraphQLJSON']['output']>;
  creditYieldDelta?: Maybe<Scalars['Float']['output']>;
  /** // Position data */
  currency?: Maybe<CurrencyEnum>;
  /** // Exposure */
  exposure?: Maybe<Scalars['Float']['output']>;
  externalAccount?: Maybe<PartyExternalAccount>;
  /** // Custody Account data */
  externalAccountId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<PartyExternalAccountType>;
  financedExposure?: Maybe<Scalars['Float']['output']>;
  financedLocalExposure?: Maybe<Scalars['Float']['output']>;
  financing?: Maybe<Scalars['Float']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  fxRateDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  /** // Connect full instrument and accounts for simple access */
  instrument?: Maybe<Instrument>;
  /** // Instrument data */
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  interestRateYieldDelta?: Maybe<Scalars['Float']['output']>;
  isCashAccount?: Maybe<Scalars['Boolean']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  issuerCreditRatings?: Maybe<Scalars['GraphQLJSON']['output']>;
  /** // Issuer data */
  issuerId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerIndustryCode?: Maybe<Scalars['String']['output']>;
  issuerLei?: Maybe<Scalars['String']['output']>;
  issuerName?: Maybe<Scalars['String']['output']>;
  issuerType?: Maybe<IssuerTypeEnum>;
  localExposure?: Maybe<Scalars['Float']['output']>;
  localFinancing?: Maybe<Scalars['Float']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  maturityDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  modelType?: Maybe<InstrumentModelTypeEnum>;
  name?: Maybe<Scalars['String']['output']>;
  portfolioInstrument?: Maybe<Instrument>;
  /** // Portfolio swap related */
  portfolioInstrumentId: Scalars['GraphQLObjectId']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  /** // Issuer program data */
  sustainabilityClass?: Maybe<IssuerProgramCategory>;
  /** // Yield Deltas from Swaplab2 */
  swaplab2Results?: Maybe<Swaplab2Results>;
  valuationAccruedInterest?: Maybe<Scalars['Float']['output']>;
  valuationCleanPrice?: Maybe<Scalars['Float']['output']>;
  valuationCurrency?: Maybe<CurrencyEnum>;
  /** // Valuation */
  valuationDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  valuationPrice?: Maybe<Scalars['Float']['output']>;
};


export type TradingManagerColumnCreditYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TradingManagerColumnInterestRateYieldDeltaArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};


export type TradingManagerColumnSwaplab2ResultsArgs = {
  date?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  riskBucketYears?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** A Transaction consists of one or more Transaction.items. */
export type Transaction = {
  __typename?: 'Transaction';
  /** _id is the database objectId generated when transaction is first saved. */
  _id: Scalars['GraphQLObjectId']['output'];
  attachments?: Maybe<Array<Attachment>>;
  /**
   * BestExecutionType is an enum telling in which capacity a transaction is
   * conducted under the MiFID II regulation.
   */
  bestExecutionType?: Maybe<BestExecutionType>;
  broker?: Maybe<Party>;
  /**
   * The objectId of the broker of the transaction. The broker is the party with
   * who the transaction i settled.
   */
  brokerId: Scalars['GraphQLObjectId']['output'];
  /**
   * BrokerTradeId is the unique reference of a transaction supplied by the broker.
   * The reference number is used for matching reconciling transaction with broker
   * confirmation.
   */
  brokerTradeId?: Maybe<Scalars['String']['output']>;
  brokerTransaction?: Maybe<BrokerTransaction>;
  /** CarryOwnCostDate is a date string with the date where the transaction should affect adjustment amount. */
  carryOwnCostDate?: Maybe<Scalars['GraphQLDateString']['output']>;
  clearingHouse?: Maybe<Party>;
  /** Clearing House is used for transactions with agreement type clearingAccount/omnibusClearingAccount */
  clearingHouseId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  client?: Maybe<Party>;
  /** ClientId is the objectId of the Party(Type=Client) owning the Transaction. */
  clientId: Scalars['GraphQLObjectId']['output'];
  corporateAction?: Maybe<CorporateAction>;
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Description is a free text field where comments can be stored. */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Error is a free text field where error messages should be posted during trade
   * matching. When trade Status is set to Instructed it should be erased.
   */
  error?: Maybe<Scalars['String']['output']>;
  /**
   * ExternalId is a unique reference of the transaction in delivering system. If
   * transaction is created without a external system a unique number is generated.
   */
  externalId: Scalars['String']['output'];
  /** Items consists of a list of one or more transaction items. */
  items?: Maybe<Array<TransactionItem>>;
  /** Number is generated as a unbroken sequence when accounting journal is Confirmed. */
  number: Scalars['Int']['output'];
  /** parentId makes it possible to link a transaction to another transaction. */
  parentId: Scalars['GraphQLObjectId']['output'];
  /**
   * Source is an enum which differs for different types of trades,
   * other transactions should have source Internal.
   */
  source: SourceType;
  /**
   * Status is an enum describing where in the processing the transaction is at the
   * moment and which user can update or delete it. Status has implications for
   * accounting module.
   */
  status?: Maybe<TransactionStatus>;
  /** tradeDate is calculated from tradeTimestamp with Europe/Stockholm timezone */
  tradeDate: Scalars['GraphQLDateString']['output'];
  /**
   * TradeTimeStamp is the time the transaction is created. If not supplied it will
   * be generated at the time of creation. If only date is given time is set to
   * 12.00.00.
   */
  tradeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Type is used for identifying the the creating transaction object. */
  type?: Maybe<TransactionType>;
  /**
   * UniqueTradeId is the reference number of the transaction reported to central
   * repository under the EMIR regulation.
   */
  uniqueTradeId?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  /** Id of user who updated/created transaction */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type TransactionFilterInput = {
  accountIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalIdIn?: InputMaybe<Array<Scalars['String']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdsIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  sourceIn?: InputMaybe<Array<SourceType>>;
  /** Added start and end date to comply with legacy PositionFilter */
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  statusNotIn?: InputMaybe<Array<TransactionStatus>>;
  tradeTimestampEnd?: InputMaybe<Scalars['Timestamp']['input']>;
  tradeTimestampStart?: InputMaybe<Scalars['Timestamp']['input']>;
  typesIn?: InputMaybe<Array<TransactionType>>;
  typesNotIn?: InputMaybe<Array<TransactionType>>;
  updatedAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  updatedBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  valueDateEnd?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  valueDateStart?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type TransactionIdentifier = {
  /** Used for CreateRedeem transactions which we often receive earlier than expected */
  newValueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  transactionId: Scalars['GraphQLObjectId']['input'];
  transactionItemId: Scalars['GraphQLObjectId']['input'];
};

export type TransactionItem = {
  __typename?: 'TransactionItem';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Account gives the Account object. */
  account?: Maybe<PartyAccount>;
  /**
   * AccountId is the objectId of the Account defined in Party(Client).
   * A value of '000000000000000000000000' is interpreted as no yAccount.
   */
  accountId: Scalars['GraphQLObjectId']['output'];
  /** Amount is the amount of the item in the item currency. */
  amount: Scalars['Float']['output'];
  /**
   * Ids of bankAccountTransactions that the item amount has matched with, used for reconciliation
   * of bank accounts
   */
  bankAccountTransactionIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  /** Array of matched bank account transactions */
  bankAccountTransactions?: Maybe<Array<BankAccountTransaction>>;
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  /** Currency denotes the currency of the item amount. */
  currency?: Maybe<CurrencyEnum>;
  /**
   * Custodian gives the Custodian of the ExternalAccount: Custodian can be the actual
   * custodian of a SecuritiesAccount or the counter party under
   * [ISDA](https://en.wikipedia.org/wiki/ISDA_Master_Agreement),
   * GMSLA or GMRA
   */
  custodian?: Maybe<Party>;
  /**
   * Error is a free text field where error messages should be posted bank account transaction
   * matching. When transactionsStatus is set to Confirmed it should be erased.
   */
  error?: Maybe<Scalars['String']['output']>;
  /** ExternalAccount gives the ExternalAccount object. */
  externalAccount?: Maybe<PartyExternalAccount>;
  /**
   * ExternalAccountId is the objectId of the ExternalAccount defined in Party(Client).
   * A ExternalAccount in a wider definition is a counter party risk netting set and
   * can be a SecuritiesAccount as well as an ISDA CSA.
   * A value of '000000000000000000000000' is interpreted as no ExternalAccount.
   */
  externalAccountId: Scalars['GraphQLObjectId']['output'];
  /**
   * FxRate is set so that the sum of the items will be zero in the Client
   * accounting currency.
   */
  fxRate?: Maybe<Scalars['Float']['output']>;
  /** Instrument gives the Instrument object. */
  instrument?: Maybe<Instrument>;
  /**
   * InstrumentId is mandatory and is the objectId of the instrument in the
   * item. Fot item types in Security, CreateRedeem, Collateral and
   * CollateralPledge the instrumentId points to the Instrument collection
   * while SettlementAmount and PayableReceivable
   * points to instruments defined in the Party(Client) collection.
   */
  instrumentId: Scalars['GraphQLObjectId']['output'];
  /**
   * PerformanceType is an enum that dictates how to handle Normal transaction items,
   * Portfolio transaction items and PortfolioItem transaction items, explained in
   * the enum above. Here Portfolio and PortfolioItem are mutually exclusive and used to
   * either look at the portfolio swap instrument itself or the constituents
   */
  performanceType: TransactionItemPerformanceType;
  /** Portfolio Instrument gives the Instrument object. */
  portfolioInstrument?: Maybe<Instrument>;
  /**
   * PortfolioInstrumentId points to the portfolio swap instrument for all transaction items
   * performanceType = PortfolioItem and for all other, Normal and Portfolio,
   * portfolioInstrumentId = ObjectId("000000000000000000000000").
   */
  portfolioInstrumentId: Scalars['GraphQLObjectId']['output'];
  /** Price is mandatory for Security item type. */
  price?: Maybe<Scalars['Float']['output']>;
  /** Quantity is mandatory for Security item type. */
  quantity?: Maybe<Scalars['Float']['output']>;
  transaction?: Maybe<Transaction>;
  /** Id of transaction which item belongs to. */
  transactionId: Scalars['GraphQLObjectId']['output'];
  transactionStatus: TransactionStatus;
  transactionTradeDate: Scalars['GraphQLDateString']['output'];
  transactionType: TransactionType;
  /** Type is an enum defining the business logic of the item. */
  type: TransactionItemType;
  /**
   * ValueDate denotes the date a SettlementAmount becomes a current account balance
   * or the date a Security is delivered. With Europe/Stockholm timezone
   */
  valueDate?: Maybe<Scalars['GraphQLDateString']['output']>;
};

export type TransactionItemFilterInput = {
  accountIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  endDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalAccountIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  externalAccountTypeIn?: InputMaybe<Array<PartyExternalAccountType>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  includePortfolioSwapConstituents?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  itemTypeIn?: InputMaybe<Array<TransactionItemType>>;
  positionTypeIn?: InputMaybe<Array<PositionTypeEnum>>;
  startDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  statusIn?: InputMaybe<Array<TransactionStatus>>;
  tradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  transactionIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export enum TransactionItemPerformanceType {
  /**
   * Normal is used for all transaction items that have nothing to do with
   * portfolio swaps
   */
  Normal = 'Normal',
  /**
   * Portfolio is used for all transaction items that involves the
   * portfolio swaps directly
   */
  Portfolio = 'Portfolio',
  /**
   * PortfolioItem is used for all transaction items that involves the
   * constituent of portfolio swaps. They are interlinked to the portfolio
   * swaps by portfolioInstrumentId of an transaction item
   */
  PortfolioItem = 'PortfolioItem'
}

export enum TransactionItemType {
  /**
   * AccruedInterest in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  AccruedInterest = 'AccruedInterest',
  /**
   * Collateral is an off-balance position which is not position building in
   * terms of performance or risk calculations but included inventory position for the
   * purpose of reconciling a Securities ExternalAccount. Collateral is not included
   * in accounting or performance calculations.
   */
  Collateral = 'Collateral',
  /**
   * CollateralPledge is an off-balance position which is not position building in
   * terms of performance or risk calculations but included in netting-sets such as
   * ISDA CSA or GMSLA. CollateralPledge is not included in accounting or performance
   * calculations.
   */
  CollateralPledge = 'CollateralPledge',
  /** Same as CollateralPledge, need this to separate if CollateralPledge is initial margin or variation margin */
  CollateralPledgeInitialMargin = 'CollateralPledgeInitialMargin',
  /**
   * Commission in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Commission = 'Commission',
  /**
   * CreateRedeem denoted creations and redemptions of fund units. It is not position
   * building and not included in performance calculations. It belongs to the Balance
   * accounting model.
   */
  CreateRedeem = 'CreateRedeem',
  /** A third CreateRedeem type. Only books amount. Doesn't set quantity and amount in the NAV process. */
  CreateRedeemAdjustmentAmount = 'CreateRedeemAdjustmentAmount',
  /** Do we need this one? Yes! Because NAV trade initialization and population differs from standard CreateRedeem. */
  CreateRedeemAdjustmentShares = 'CreateRedeemAdjustmentShares',
  /**
   * CustodyFee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  CustodyFee = 'CustodyFee',
  /**
   * Dividend in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Dividend = 'Dividend',
  /**
   * DividendPaid denotes dividend paid from fund classes. It is not position
   * building and not included in performance calculations.
   */
  DividendPaid = 'DividendPaid',
  /**
   * Fee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Fee = 'Fee',
  /**
   * ForeignTax in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ForeignTax = 'ForeignTax',
  /**
   * Interest in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Interest = 'Interest',
  /**
   * ManagementCost in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ManagementCost = 'ManagementCost',
  /**
   * ManagementFee in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  ManagementFee = 'ManagementFee',
  /**
   * PayableReceivable denotes balance sheet items other than current accounts or
   * financial instruments such as margins or loans and deposits under GMSLA or
   * GMRA agreements. PayableReceivable belong to the Asset performance model and
   * the Balance accounting model.
   */
  PayableReceivable = 'PayableReceivable',
  /**
   * Rebate is a type of fee , used to be able to differentiate between dividends and fundrebates. We book fundrebates
   * with quantity as stocktrades with price 0 and cash fundrebates as rebates.
   */
  Rebate = 'Rebate',
  /**
   * Security is position building and belongs to the Inventory accounting
   * model and the Asset performance model.
   */
  Security = 'Security',
  /** Similar to CollateralPledge but posting collateral instead of receiving. */
  SecurityLoan = 'SecurityLoan',
  /**
   * SettlementAmounts is payments on current accounts and is position
   * building belongs to the Asset performance and the Balance accounting model.
   */
  SettlementAmount = 'SettlementAmount',
  /**
   * StampDuty in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  StampDuty = 'StampDuty',
  /**
   * Tax in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  Tax = 'Tax',
  /**
   * TaxRestitution in not position building and belongs to the Fee accounting
   * model and the CashFlow performance model.
   */
  TaxRestitution = 'TaxRestitution'
}

export enum TransactionStatus {
  /**
   * Confirmed status is set when trade is confirmed with counterparty by the
   * Operations unit.
   */
  Confirmed = 'Confirmed',
  /**
   * Deleted denotes a transaction that has been deleted but still
   * exists in the database.
   */
  Deleted = 'Deleted',
  /** History is used when setting up a new client with historic transactions. */
  History = 'History',
  /**
   * Instructed denotes when a transaction has been
   * instructed with the Custodian.
   */
  Instructed = 'Instructed',
  /** Internal is used for transactions created only for internal use. */
  Internal = 'Internal',
  /**
   * Pending is a transaction entered by an external system or user that has not
   * been confirmed by the Operations unit.
   */
  Pending = 'Pending',
  /**
   * Preliminary is a status where one or more parameters is
   * not yet confirmed and will be treated as such in accounting
   * terms and will not be given a number until it reaches
   * at least Confirmed status.
   */
  Preliminary = 'Preliminary',
  /**
   * Settled denotes when a security has been delivered in custody
   * account or cash has been settled on a current account.
   */
  Settled = 'Settled'
}

export enum TransactionType {
  BondTrade = 'BondTrade',
  Collateral = 'Collateral',
  CreateRedeem = 'CreateRedeem',
  DerivativeTrade = 'DerivativeTrade',
  Dividend = 'Dividend',
  Fee = 'Fee',
  FundTrade = 'FundTrade',
  FxOutright = 'FxOutright',
  FxSpot = 'FxSpot',
  Insert = 'Insert',
  Interest = 'Interest',
  IrSwap = 'IrSwap',
  StockTrade = 'StockTrade',
  Trade = 'Trade',
  Transfer = 'Transfer'
}

export type TransferAgentOrder = {
  __typename?: 'TransferAgentOrder';
  _id: Scalars['GraphQLObjectId']['output'];
  adjustmentAmount: Scalars['String']['output'];
  adjustmentShares: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  attachments?: Maybe<Array<Attachment>>;
  carryOwnTransactionCost: Scalars['Boolean']['output'];
  client: Party;
  clientAccount?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['GraphQLObjectId']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency: CurrencyEnum;
  description: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  instrument: Instrument;
  isin: Scalars['ISIN']['output'];
  netPrice: Scalars['String']['output'];
  /** If subType is ACCOUNT_SWITCH, referenceExternalId must be set to corresponding externalId */
  referenceExternalId: Scalars['String']['output'];
  settledAmount: Scalars['String']['output'];
  settledUnits: Scalars['String']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  status: TransactionStatus;
  subType: TransferAgentOrderSubTypeEnum;
  tradeDate: Scalars['GraphQLDateString']['output'];
  transactionIds: Array<Scalars['GraphQLObjectId']['output']>;
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  valueDate: Scalars['GraphQLDateString']['output'];
  /** A list of versions of the document */
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type TransferAgentOrderAttachmentsInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
};

export enum TransferAgentOrderEnum {
  BUY = 'BUY',
  SELL = 'SELL'
}

export type TransferAgentOrderInput = {
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  adjustmentAmount?: InputMaybe<Scalars['String']['input']>;
  adjustmentShares?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['String']['input'];
  carryOwnTransactionCost?: InputMaybe<Scalars['Boolean']['input']>;
  clientAccount?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  currency: CurrencyEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  isin: Scalars['ISIN']['input'];
  netPrice?: InputMaybe<Scalars['String']['input']>;
  /** If subType is ACCOUNT_SWITCH referenceExternalId, must be set to corresponding externalId */
  referenceExternalId: Scalars['String']['input'];
  settledAmount?: InputMaybe<Scalars['String']['input']>;
  settledUnits?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['GraphQLObjectId']['input'];
  status: TransactionStatus;
  subType: TransferAgentOrderSubTypeEnum;
  tradeDate: Scalars['GraphQLDateString']['input'];
  transactionIds: Array<Scalars['GraphQLObjectId']['input']>;
  transactionType: TransferAgentOrderEnum;
  units: Scalars['String']['input'];
  valueDate: Scalars['GraphQLDateString']['input'];
};

export enum TransferAgentOrderSubTypeEnum {
  ACCOUNT_SWITCH = 'ACCOUNT_SWITCH',
  NORMAL = 'NORMAL'
}

export type TransferAgentTotalUnit = {
  __typename?: 'TransferAgentTotalUnit';
  _id: Scalars['GraphQLObjectId']['output'];
  date: Scalars['GraphQLDateString']['output'];
  instrument: Instrument;
  isin: Scalars['ISIN']['output'];
  source: Party;
  sourceId: Scalars['GraphQLObjectId']['output'];
  totalUnits: Scalars['Float']['output'];
  updateTimestamp: Scalars['Timestamp']['output'];
};

export type TrialInput = {
  clientId: Scalars['GraphQLObjectId']['input'];
};

export enum TrueFalseNone {
  False = 'False',
  None = 'None',
  True = 'True'
}

export type UpdateAccountingRunInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientTAccountChartId: Scalars['GraphQLObjectId']['input'];
  endDate: Scalars['GraphQLDateString']['input'];
  keepManualJournalEntries?: InputMaybe<Scalars['Boolean']['input']>;
  masterTAccountChartId: Scalars['GraphQLObjectId']['input'];
  type: AccountingRunTypeEnum;
};

export type UpdateBrokerTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  correspondingTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BrokerTransactionStatus>;
};

export type UpdateCurveDefinitionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  definition?: InputMaybe<CurveDefinitionDefinitionInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CurveDefinitionType>;
};

export type UpdateInstrumentInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  benchmarkId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bloombergDailyDownload?: InputMaybe<Scalars['Boolean']['input']>;
  bloombergQuoteMultiplier?: InputMaybe<Scalars['Float']['input']>;
  bloombergTicker?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<InstrumentCategory>;
  cicArea?: InputMaybe<CicAreaEnum>;
  cicType?: InputMaybe<CicTypeEnum>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchange?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['ISIN']['input']>;
  issuerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  /** https://www.iso20022.org/market-identifier-codes */
  mic?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<InstrumentModelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  placeOfSettlementId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  productType?: InputMaybe<InstrumentProductTypeEnum>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InstrumentStatusEnum>;
  type?: InputMaybe<InstrumentTypeEnum>;
};

export type UpdateJournalEntryInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountingRunId: Scalars['GraphQLObjectId']['input'];
  batch?: InputMaybe<AccountingBatchType>;
  clientId: Scalars['GraphQLObjectId']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  externalNumber?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  portfolioTransactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactions?: InputMaybe<Array<AccountingTransactionInput>>;
};

export type UpdateMiniTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accruedInterest?: InputMaybe<Scalars['Float']['input']>;
  agreementType?: InputMaybe<AgreementType>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  bonusShares?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingBrokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  close?: InputMaybe<Scalars['Boolean']['input']>;
  commission?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  dividendPaid?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  foreignTax?: InputMaybe<Scalars['Float']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  initialMargin?: InputMaybe<Scalars['Boolean']['input']>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  itemType?: InputMaybe<FeeInterestItemType>;
  loan?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  reinvestedDividend?: InputMaybe<Scalars['Boolean']['input']>;
  reinvestedRebate?: InputMaybe<Scalars['Boolean']['input']>;
  rounding?: InputMaybe<Scalars['Float']['input']>;
  secondAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  secondAmount?: InputMaybe<Scalars['Float']['input']>;
  secondCurrency?: InputMaybe<CurrencyEnum>;
  secondInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  source?: InputMaybe<SourceType>;
  stampDuty?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransactionStatus>;
  taxRestitution?: InputMaybe<Scalars['Boolean']['input']>;
  tradeTimestamp?: InputMaybe<Scalars['DateStringOrTimestamp']['input']>;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type UpdatePartyInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  accountant?: InputMaybe<Scalars['String']['input']>;
  accountingCompanyType?: InputMaybe<AccountingCompanyTypeEnum>;
  accountingCurrency?: InputMaybe<Scalars['String']['input']>;
  accountingFrequency?: InputMaybe<AccountingFrequencyEnum>;
  accounts?: InputMaybe<Array<InputMaybe<PartyAccountInput>>>;
  address?: InputMaybe<Scalars['String']['input']>;
  aliases?: InputMaybe<Array<AliasInput>>;
  approval?: InputMaybe<Scalars['String']['input']>;
  beneficialOwner?: InputMaybe<BeneficialOwnerInput>;
  bloombergEquityTicker?: InputMaybe<Scalars['String']['input']>;
  businessIdentifierCode?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  complaintsManager?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCodeEnum>;
  creditRatingScale?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditRatings?: InputMaybe<Array<InputMaybe<CreditRatingInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  esg?: InputMaybe<PartyEsgInput>;
  externalAccounts?: InputMaybe<Array<InputMaybe<PartyExternalAccountInput>>>;
  fundInfo?: InputMaybe<PartyFundInfoInput>;
  inLedgerNumber?: InputMaybe<Scalars['Int']['input']>;
  inLedgerTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  instrumentIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  issuerType?: InputMaybe<IssuerTypeEnum>;
  legalEntityIdentifier?: InputMaybe<Scalars['String']['input']>;
  legalNumber?: InputMaybe<Scalars['String']['input']>;
  limitSlackChannel?: InputMaybe<Scalars['String']['input']>;
  limitStartDate?: InputMaybe<Scalars['Date']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  nace?: InputMaybe<NaceClass>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  quantityDecimals?: InputMaybe<Scalars['Int']['input']>;
  regulator?: InputMaybe<Scalars['String']['input']>;
  standardSettlementInstructions?: InputMaybe<Array<PartyStandardSettlementInstructionInput>>;
  status?: InputMaybe<PartyStatusEnum>;
  sustainalyticsId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<TimezoneEnum>;
  types?: InputMaybe<Array<InputMaybe<PartyType>>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  base64?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  data?: InputMaybe<Scalars['GraphQLJSON']['input']>;
  date: Scalars['GraphQLDateString']['input'];
  name: Scalars['String']['input'];
  sourceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ReportStatusEnum>;
  type: Scalars['String']['input'];
};

export type UpdateRoleInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  assets?: InputMaybe<Array<PermissionAssetEnum>>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissionType?: InputMaybe<PermissionTypeEnum>;
  roleType?: InputMaybe<RoleTypeEnum>;
  status?: InputMaybe<DocumentStatusEnum>;
};

export type UpdateTAccountChartInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  clientId: Scalars['GraphQLObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tAccountMappings?: InputMaybe<Array<TAccountMappingInput>>;
  tAccounts?: InputMaybe<Array<TAccountInput>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTransactionInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  bestExecutionType?: InputMaybe<BestExecutionType>;
  brokerId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  brokerTradeId?: InputMaybe<Scalars['String']['input']>;
  carryOwnCostDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  clearingHouseId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<UpdateTransactionItemInput>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TransactionStatus>;
  tradeTimestamp?: InputMaybe<Scalars['Timestamp']['input']>;
  type?: InputMaybe<TransactionType>;
  uniqueTradeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionItemInput = {
  /** _id should be stated when updating an item, not required when adding new items though */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  accountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccountTransactionIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  clientId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  error?: InputMaybe<Scalars['String']['input']>;
  externalAccountId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  fxRate?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['GraphQLObjectId']['input'];
  performanceType?: InputMaybe<TransactionItemPerformanceType>;
  portfolioInstrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  transactionId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  transactionStatus?: InputMaybe<TransactionStatus>;
  transactionTradeDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
  transactionType?: InputMaybe<TransactionType>;
  type: TransactionItemType;
  valueDate?: InputMaybe<Scalars['GraphQLDateString']['input']>;
};

export type UpdateUserInput = {
  _id: Scalars['GraphQLObjectId']['input'];
  bidExpiration?: InputMaybe<Scalars['Int']['input']>;
  clientIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  company?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  frontendRoleId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  logins?: InputMaybe<Array<UserLoginInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  noteIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  password?: InputMaybe<Scalars['String']['input']>;
  personalNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  status?: InputMaybe<UserStatusEnum>;
};

export enum UseOfProceed {
  /** Access to essential services */
  AccessEssentialServices = 'AccessEssentialServices',
  /** Affordable housing */
  AffordableHousing = 'AffordableHousing',
  /** Affordable basic infrastructure */
  AffordableInfraStructure = 'AffordableInfraStructure',
  /** Clean Transportation */
  CleanTransportation = 'CleanTransportation',
  /** climate change adaptation */
  ClimateChangeAdaption = 'ClimateChangeAdaption',
  /** eco-efficient and/or circular economy adapted products, */
  EcoEfficientProducts = 'EcoEfficientProducts',
  /** Employment generation (including SME financing and Microfinance) */
  EmploymentGeneration = 'EmploymentGeneration',
  /** Energy efficiency */
  EnergyEfficiency = 'EnergyEfficiency',
  /** Environmentally sustainable management of living natural resources and land use */
  EnvironmentallySustainableManagement = 'EnvironmentallySustainableManagement',
  /** Green Buildings */
  GreenBuldings = 'GreenBuldings',
  Other = 'Other',
  /** Pollution prevention and control */
  PollutionPreventionAndControl = 'PollutionPreventionAndControl',
  /** Renewable energy */
  RenewableEnergy = 'RenewableEnergy',
  /** Socioeconomic advancement and empowerment */
  SocioEconomicAdvancement = 'SocioEconomicAdvancement',
  /** Food security and sustainable food systems */
  SustainableFoodSystems = 'SustainableFoodSystems',
  /** Sustainable water and wastewater management */
  SustainableWaterManagement = 'SustainableWaterManagement',
  /** Terrestrial and aquatic biodiversity conservation */
  TerrestrialandAcquaticConservation = 'TerrestrialandAcquaticConservation'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['GraphQLObjectId']['output'];
  bidExpiration?: Maybe<Scalars['Int']['output']>;
  clientIds: Array<Scalars['GraphQLObjectId']['output']>;
  clients: Array<PartyInfo>;
  company: Scalars['String']['output'];
  createTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  frontendRole: Role;
  frontendRoleId: Scalars['GraphQLObjectId']['output'];
  lastBankIdLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastLoginTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  lastPasswordUpdate?: Maybe<Scalars['Timestamp']['output']>;
  logins?: Maybe<Array<UserLogin>>;
  name: Scalars['String']['output'];
  noteIds: Array<Scalars['GraphQLObjectId']['output']>;
  notes: Array<Note>;
  password?: Maybe<Scalars['String']['output']>;
  personalNumber: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds: Array<Scalars['GraphQLObjectId']['output']>;
  roles: Array<Role>;
  status: UserStatusEnum;
  /** Latest terms of service from notes collection */
  termsOfService?: Maybe<Note>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  versions?: Maybe<Scalars['GraphQLJSON']['output']>;
};

export type UserFilterInput = {
  status?: InputMaybe<UserStatusEnum>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  _id: Scalars['GraphQLObjectId']['output'];
  company: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  personalNumber: Scalars['String']['output'];
};

export type UserLimited = {
  __typename?: 'UserLimited';
  _id: Scalars['GraphQLObjectId']['output'];
  /** Only returned when filtering by asset */
  asset?: Maybe<PermissionAssetEnum>;
  /** Only returned when filtering by asset */
  clientIds?: Maybe<Array<Scalars['GraphQLObjectId']['output']>>;
  status: UserStatusEnum;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  timeStamp: Scalars['Timestamp']['output'];
  userAgent: Scalars['String']['output'];
};

export type UserLoginInput = {
  timeStamp: Scalars['Timestamp']['input'];
  userAgent: Scalars['String']['input'];
};

export enum UserStatusEnum {
  Confirmed = 'Confirmed',
  Disabled = 'Disabled',
  Pending = 'Pending'
}

export type Valuation = {
  __typename?: 'Valuation';
  _id: Scalars['GraphQLObjectId']['output'];
  attachments?: Maybe<Array<Attachment>>;
  date: Scalars['GraphQLSwedenDate']['output'];
  instrument?: Maybe<Instrument>;
  instrumentId: Scalars['GraphQLObjectId']['output'];
  records?: Maybe<Array<Maybe<ValuationRecord>>>;
  updateTimestamp?: Maybe<Scalars['Timestamp']['output']>;
};

export type ValuationMapping = {
  __typename?: 'ValuationMapping';
  _id: Scalars['GraphQLObjectId']['output'];
  client?: Maybe<Party>;
  clientId: Scalars['GraphQLObjectId']['output'];
  collectionName?: Maybe<MappingCollectionNameEnum>;
  createTimestamp: Scalars['Timestamp']['output'];
  currency?: Maybe<CurrencyEnum>;
  curveDefinition?: Maybe<CurveDefinition>;
  instrument?: Maybe<Instrument>;
  /** instrumentId should be a id to an instrument or null */
  instrumentId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  issuerProgram?: Maybe<IssuerProgram>;
  issuerProgramId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  mappingType?: Maybe<MappingTypeEnum>;
  modelType?: Maybe<InstrumentModelTypeEnum>;
  parameterType?: Maybe<MappingParameterEnum>;
  referenceId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  status?: Maybe<ValuationMappingStatus>;
  updateTimestamp: Scalars['Timestamp']['output'];
  /** Id of the user that updated/created */
  updateUserId?: Maybe<Scalars['GraphQLObjectId']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  validFrom: Scalars['GraphQLDateString']['output'];
  validTo: Scalars['GraphQLDateString']['output'];
  /** A list of versions of the document */
  versions?: Maybe<Array<Maybe<Scalars['GraphQLJSON']['output']>>>;
};

export type ValuationMappingFilterInput = {
  clientIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  idIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  instrumentIdIn?: InputMaybe<Array<Scalars['GraphQLObjectId']['input']>>;
  mappingTypeIn?: InputMaybe<Array<MappingTypeEnum>>;
  parameterTypeIn?: InputMaybe<Array<MappingParameterEnum>>;
  statusIn?: InputMaybe<Array<InputMaybe<ValuationMappingStatus>>>;
};

export type ValuationMappingInput = {
  /** if _id is omitted, an ticket will be created */
  _id?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  clientId: Scalars['GraphQLObjectId']['input'];
  collectionName?: InputMaybe<MappingCollectionNameEnum>;
  currency?: InputMaybe<CurrencyEnum>;
  instrumentId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  issuerProgramId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  mappingType?: InputMaybe<MappingTypeEnum>;
  modelType?: InputMaybe<InstrumentModelTypeEnum>;
  parameterType?: InputMaybe<MappingParameterEnum>;
  referenceId?: InputMaybe<Scalars['GraphQLObjectId']['input']>;
  status?: InputMaybe<ValuationMappingStatus>;
  validFrom: Scalars['GraphQLDateString']['input'];
  validTo: Scalars['GraphQLDateString']['input'];
};

export enum ValuationMappingStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

export type ValuationPrice = {
  __typename?: 'ValuationPrice';
  currency?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PriceType>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ValuationPriceInput = {
  currency: CurrencyEnum;
  type: PriceType;
  value: Scalars['Float']['input'];
};

export type ValuationRecord = {
  __typename?: 'ValuationRecord';
  bloombergTicker?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<Maybe<ValuationPrice>>>;
  source?: Maybe<Scalars['String']['output']>;
  updateUserInfo?: Maybe<UserInfo>;
  writeTimestamp?: Maybe<Scalars['Timestamp']['output']>;
  writeUser?: Maybe<Scalars['String']['output']>;
};

export type YieldDeltaBucket = {
  __typename?: 'YieldDeltaBucket';
  period?: Maybe<QuantLibPeriod>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum PermissionTypeEnum {
  Read = 'Read',
  ReadWrite = 'ReadWrite'
}

export const AccountingBatchTypeDescriptions = {
  A: "Automated, similar to BVU but automatically added adjustment",
  AR: "Automated reverse, similar to BVI but closing previous A",
  BVI: "Balance valuation ingoing, closing previous BVU",
  BVU: "Balance valuation outgoing",
  IB: "Ingoing balance",
  M: "Manual, similar to BVU but manually added",
  MR: "Manual reverse, similar to BVI but closing previous M",
  T: "Transaction"
};

export const AccountingCompanyTypeEnumDescriptions = {
  GeneralPortfolio: null,
  None: null,
  SwedishMutualFund: null,
  SwedishPensionTrust: null
};

export const AccountingFrequencyEnumDescriptions = {
  Annually: null,
  Monthly: null,
  None: null,
  Quarterly: null,
  SemiAnnually: null
};

export const AccountingLanguageEnumDescriptions = {
  Sv: null
};

export const AccountingRunStatusEnumDescriptions = {
  Confirmed: null,
  Deleted: null,
  Pending: null,
  Preliminary: null
};

export const AccountingRunTypeEnumDescriptions = {
  Manual: null,
  Transaction: null
};

export const AccountingTransactionTypeDescriptions = {
  AccruedInterest: null,
  Commission: null,
  CustodyFee: null,
  Dividend: null,
  DividendPaid: null,
  Equity: null,
  Fee: null,
  ForeignTax: null,
  ForwardCash: null,
  InitialCost: null,
  Interest: null,
  ManagementCost: null,
  ManagementFee: null,
  OpeningBalance: null,
  Realized: null,
  RealizedFx: null,
  Rebate: null,
  Rounding: null,
  ShareCapital: null,
  StampDuty: null,
  Tax: null,
  TaxRestitution: null,
  Unrealized: null,
  UnrealizedFx: null,
  ValueChange: null
};

export const AgreementStatusEnumDescriptions = {
  Active: null,
  Deleted: null,
  Pending: null,
  Superseded: null
};

export const AgreementTypeDescriptions = {
  ClearingAccount: null,
  Gmra: null,
  Gmsla: null,
  Isda: null,
  OmnibusClearingAccount: null
};

export const AgreementTypeEnumDescriptions = {
  CashTrading: null,
  Cdea: null,
  ClearingBroker: null,
  CustodyAgreement: null,
  Emir: null,
  EquityDma: null,
  FxTrading: null,
  Gmra: null,
  Gmsla: null,
  Gua: null,
  InvestmentManagement: null,
  InvestmentPolicy: null,
  Isda: null,
  Mt101: null,
  ProcessAgent: null,
  Sftr: null,
  Ssi: null,
  SubscriptionRedemptionForm: null,
  TriParty: null,
  UserAgreement: null
};

export const ApprovedStatusEnumDescriptions = {
  Accepted: null,
  Rejected: null
};

export const BankAccountTransactionStatusEnumDescriptions = {
  Deleted: null,
  Pending: null,
  Settled: null
};

export const BestExecutionTypeDescriptions = {
  Client: "Client denotes transactions entered by a client it self as a part of an asset management agreement.",
  Execution: "Execution denotes a trade executed on behalf a client outside an asset management agreement.",
  Maintenance: "Maintenance denotes transactions such as dividends, coupons etc. that follows from positions held.",
  Portfolio: "Portfolio denotes a trade conducted as under an asset management agreement.",
  Transmission: "Transmission denotes a trade passed on to another broker on behalf of a client outside an asset management agreement."
};

export const BrokerTransactionStatusDescriptions = {
  Confirmed: null,
  Deleted: null,
  Pending: null
};

export const BrokerTransactionTypeDescriptions = {
  Buy: null,
  Sell: null
};

export const CalendarNameEnumDescriptions = {
  SwedenFixedIncome: null,
  UnitedKingdom: null,
  UnitedStatesEquity: null,
  UnitedStatesFixedIncome: null
};

export const CalendarStateEnumDescriptions = {
  Closed: null,
  EarlyClose: null
};

export const CalendarStatusEnumDescriptions = {
  Active: null,
  Deleted: null
};

export const CdsTypeDescriptions = {
  Basket: null,
  Index: null
};

export const CicAreaEnumDescriptions = {
  AE: "UnitedArabEmirates",
  AR: "Argentina",
  AT: "Austria",
  AU: "Australia",
  BE: "Belgium",
  BG: "Bulgaria",
  BM: "Bermuda",
  CA: "Canada",
  CH: "Switzerland",
  CL: "Chile",
  CN: "China",
  CY: "Cyprus",
  CZ: "CzechRepublic",
  DE: "Germany",
  DK: "Denmark",
  EE: "Estonia",
  ES: "Spain",
  FI: "Finland",
  FO: "FaroeIslands",
  FR: "France",
  GB: "UnitedKingdom",
  GG: "Guernsey",
  GR: "Greece",
  HK: "HongKong",
  HR: "Croatia",
  HU: "Hungary",
  IE: "Ireland",
  IL: "Israel",
  IM: "IsleOfMan",
  IR: "Iran",
  IT: "Italy",
  JE: "Jersey",
  JO: "Jordan",
  JP: "Japan",
  KY: "CaymanIslands",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MO: "Macao",
  MT: "Malta",
  MX: "Mexico",
  NL: "Netherlands",
  NO: "Norway",
  NZ: "NewZealand",
  None: "None",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  SE: "Sweden",
  SG: "Singapore",
  SI: "Slovenia",
  SK: "Slovakia",
  US: "UnitedStates",
  XL: "Assets that are not listed in a stock exchange",
  XT: "Assets that are not exchange tradable",
  ZA: "SouthAfrica"
};

export const CicTypeEnumDescriptions = {
  None: "None",
  XX00: "00 Other investments",
  XX11: "11 Central Government bonds",
  XX12: "12 Supra-national bonds",
  XX13: "13 Regional government bonds",
  XX14: "14 Municipal government bonds",
  XX15: "15 Treasury bonds",
  XX16: "16 Covered bonds",
  XX17: "17 National Central bank bonds",
  XX19: "19 Government bonds Other",
  XX21: "21 Corporate bonds",
  XX22: "22 Convertible bonds",
  XX23: "23 Commercial paper",
  XX24: "24 Money market instruments",
  XX25: "25 Hybrid bonds",
  XX26: "26 Common covered bonds",
  XX27: "27 Covered bonds subject to specific law",
  XX28: "28 Subordinated bonds",
  XX29: "29 Corporate bonds Other",
  XX31: "31 Common equity",
  XX32: "32 Equity of real estate related corporation",
  XX33: "33 Equity rights",
  XX34: "34 Preferred equity",
  XX39: "39 Equity Other",
  XX41: "41 Equity funds",
  XX42: "42 Debt funds",
  XX43: "43 Money market funds",
  XX44: "44 Asset allocation funds",
  XX45: "45 Real estate funds",
  XX46: "46 Alternative funds",
  XX47: "47 Private equity funds",
  XX48: "48 Infrastructure funds",
  XX49: "49 Collective Investment Undertakings (funds) other",
  XX51: "51 Structured notes Equity risk",
  XX52: "52 Structured notes Interest rate risk",
  XX53: "53 Structured notes Currency risk",
  XX54: "54 Structured notes Credit risk",
  XX55: "55 Structured notes Real estate risk",
  XX56: "56 Structured notes Commodity risk",
  XX57: "57 Structured notes Catastrophe and Weather risk",
  XX58: "58 Structured notes Mortality risk",
  XX59: "59 Structured notes Structured notes Other",
  XX61: "61 Collateralised securities Equity risk",
  XX62: "62 Collateralised securities Interest rate risk",
  XX63: "63 Collateralised securities Currency risk",
  XX64: "64 Collateralised securities Credit risk",
  XX65: "65 Collateralised securities Real estate risk",
  XX66: "66 Collateralised securities Commodity risk",
  XX67: "67 Collateralised securities Catastrophe and Weather risk",
  XX68: "68 Collateralised securities Mortality risk",
  XX69: "69 Collateralised securities Other",
  XX71: "71 Cash",
  XX72: "72 Cash Transferable deposits ,cash equivalents",
  XX73: "73 Cash Other deposits short term ,less than or equal to one year",
  XX74: "74 Cash Other deposits with term longer than one year",
  XX75: "75 Cash Deposits to cedants",
  XX79: "79 Cash and deposits Other",
  XX81: "81 Uncollateralized loans made",
  XX82: "82 Loans made collateralized with securities",
  XX84: "84 Mortgages",
  XX85: "85 Other collateralized loans made",
  XX86: "86 Loans on policies",
  XX89: "89 Mortgages and loans Other",
  XX91: "91 Property (office and commercial)",
  XX92: "92 Property (residential)",
  XX93: "93 Property (for own use)",
  XX94: "94 Property (under construction)",
  XX95: "95 Plant and equipment (for own use)",
  XX99: "99 Property Other",
  XXA1: "A1 Equity and index futures",
  XXA2: "A2 Interest rate futures",
  XXA3: "A3 Currency futures",
  XXA5: "A5 Commodity futures",
  XXA7: "A7 Catastrophe and Weather risk futures",
  XXA8: "A8 Mortality risk futures",
  XXA9: "A9 Futures Other",
  XXB1: "B1 Equity and index call options",
  XXB2: "B2 Bond call options",
  XXB3: "B3 Currency call options",
  XXB4: "B4 Call Warrants",
  XXB5: "B5 Commodity call options",
  XXB6: "B6 Swaptions call / receiver",
  XXB7: "B7 Catastrophe and Weather risk call options",
  XXB8: "B8 Mortality risk call options",
  XXB9: "B9 Call Options Other",
  XXC1: "C1 Equity and index put options",
  XXC2: "C2 Bond put options",
  XXC3: "C3 Currency put options",
  XXC4: "C4 Put Warrants",
  XXC5: "C5 Commodity put options",
  XXC6: "C6 Swaptions put / payer",
  XXC7: "C7 Catastrophe and Weather risk put options",
  XXC8: "C8 Mortality risk put options",
  XXC9: "C9 Put Options Other",
  XXD1: "D1 Interest rate swaps",
  XXD2: "D2 Currency swaps",
  XXD3: "D3 Interest rate and currency swaps",
  XXD5: "D5 Security swaps",
  XXD7: "D7 Catastrophe and Weather risk swaps",
  XXD8: "D8 Mortality risk swaps",
  XXD9: "D9 Swaps Other",
  XXE1: "E1 Forward interest rate agreement",
  XXE2: "E2 Forward exchange rate agreement",
  XXE7: "E7 Catastrophe and Weather risk forwards",
  XXE8: "E8 Mortality risk forwards",
  XXE9: "E9 Forwards Other",
  XXF1: "F1 Credit default swap",
  XXF2: "F2 Credit spread option",
  XXF3: "F3 Credit spread swap",
  XXF4: "F4 Total return swap",
  XXF9: "F9 Credit derivatives other"
};

export const CollectionNameEnumDescriptions = {
  BrokerTransaction: null,
  CorporateAction: null,
  Instrument: null,
  IssuerProgram: null,
  None: null,
  Party: null,
  Screen: null,
  Swift: null,
  Transaction: null
};

export const CorporateActionEventDescriptions = {
  ACCU: "Accumulation",
  ACTV: "Trading Status: Active",
  ATTI: "Attachment",
  BIDS: "Repurchase Offer",
  BMET: "Bondholder Meeting",
  BONU: "Bonus Issue",
  BPUT: "Put Redemption",
  BRUP: "Bankruptcy",
  CAPD: "Capital Distribution",
  CAPG: "Capital Gains Distribution",
  CAPI: "Capitalisation",
  CERT: "Non-US TEFRA D Certification",
  CHAN: "Change",
  CLSA: "Class Action",
  CMET: "Court Meeting",
  CONS: "Consent",
  CONV: "Conversion",
  COOP: "Company Option",
  CREV: "Credit Event",
  DECR: "Decrease in Value",
  DETI: "Detachment",
  DFLT: "Bond Default",
  DLST: "Trading Status: Delisted",
  DRAW: "Drawing",
  DRCA: "Cash Distribution from Non-Eligible Securities Sales",
  DRIP: "Dividend Reinvestment",
  DSCL: "Disclosure",
  DTCH: "Dutch Auction",
  DVCA: "Cash Dividend",
  DVOP: "Dividend Option",
  DVSC: "Scrip Dividend",
  DVSE: "Stock Dividend",
  EXOF: "Exchange",
  EXRI: "Call on Intermediate Securities",
  EXTM: "Maturity Extension",
  EXWA: "Warrant Exercise",
  INCR: "Increase in Value",
  INFO: "Information",
  INTR: "Interest Payment",
  LIQU: "Liquidation Dividend",
  MCAL: "Full Call",
  MEET: "Annual General Meeting",
  MRGR: "Merger",
  NOOF: "New Offer",
  ODLT: "Odd Lot Offer",
  OTHR: "Other Event",
  PARI: "Parity Adjustment",
  PDEF: "Partial Defeasance",
  PINK: "Pink Sheet Trading",
  PLAC: "Placement",
  PPUB: "Partial Redemption with Pool Factor Reduction",
  PRII: "Prime Rate Interest Payment",
  PRIO: "Priority Issue",
  REDM: "Redemption",
  REMK: "Remarketing",
  RHDI: "Rights Distribution",
  RHTS: "Rights Issue",
  SDRV: "Spin-Off",
  SHPR: "Shareholders' Meeting",
  SMAL: "Smallest Negotiable Unit",
  SOFF: "Stock Offering",
  SPLF: "Stock Split",
  SPLR: "Reverse Stock Split",
  TEND: "Tender Offer",
  WRTH: "Worthless Security"
};

export const CorporateActionStatusEnumDescriptions = {
  Confirmed: null,
  Deleted: null,
  Pending: null,
  Preliminary: null,
  Settled: "Messages of type 566 gets status settled when matched",
  UpdatedPrevious: "Used for messageType 566 when original coacs(swift) of messageType 564 has been updated and coacs(swift) of messageType is insignificant",
  Waiting: "Used for messages with corporateActionEvent: DRIP/DVOP, theese are received earlier (not received two days after ex-date like other corporateActionEvents)"
};

export const CostStatusEnumDescriptions = {
  Active: null,
  Deleted: null
};

export const CountryCodeEnumDescriptions = {
  AE: "UnitedArabEmirates",
  AR: "Argentina",
  AT: "Austria",
  AU: "Australia",
  BE: "Belgium",
  BG: "Bulgaria",
  BM: "Bermuda",
  CA: "Canada",
  CH: "Switzerland",
  CL: "Chile",
  CN: "China",
  CY: "Cyprus",
  CZ: "CzechRepublic",
  DE: "Germany",
  DK: "Denmark",
  EE: "Estonia",
  ES: "Spain",
  FI: "Finland",
  FO: "FaroeIslands",
  FR: "France",
  GB: "UnitedKingdom",
  GG: "Guernsey",
  GR: "Greece",
  HK: "HongKong",
  HR: "Croatia",
  HU: "Hungary",
  IE: "Ireland",
  IL: "Israel",
  IM: "IsleOfMan",
  IR: "Iran",
  IT: "Italy",
  JE: "Jersey",
  JO: "Jordan",
  JP: "Japan",
  KY: "CaymanIslands",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  MO: "Macao",
  MT: "Malta",
  MX: "Mexico",
  NL: "Netherlands",
  NO: "Norway",
  NZ: "NewZealand",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  SE: "Sweden",
  SG: "Singapore",
  SI: "Slovenia",
  SK: "Slovakia",
  US: "UnitedStates",
  Unknown: "Unknown",
  ZA: "SouthAfrica"
};

export const CurrencyEnumDescriptions = {
  AUD: null,
  BRL: null,
  CAD: null,
  CHF: null,
  CNY: null,
  DKK: null,
  EUR: null,
  GBP: null,
  HKD: null,
  ILS: null,
  INR: null,
  ISK: null,
  JPY: null,
  KRW: null,
  MXN: null,
  NOK: null,
  NZD: null,
  RUB: null,
  SEK: null,
  SGD: null,
  TRY: null,
  USD: null,
  ZAR: null
};

export const CurveDefinitionTypeDescriptions = {
  BasisCurve: null,
  CreditCurve: null,
  FixingCurve: null,
  FxCurve: null,
  YieldCurve: null
};

export const CurveDefinitionType2Descriptions = {
  YieldCurveDefinition: null
};

export const CustodianPositionTypeDescriptions = {
  Automatic: null,
  Manual: null
};

export const DayAdjustmentEnumDescriptions = {
  FixedPeriods: null,
  Following: null,
  ModifiedFollowing: null
};

export const DocumentStatusEnumDescriptions = {
  Active: null,
  Deleted: null
};

export const EngagementMethodDescriptions = {
  Dialog: null,
  Voting: null
};

export const EsgActivityEnumDescriptions = {
  ApprovedScreen: null,
  Dialogue: null,
  Divestment: null,
  Investment: null,
  Other: null,
  OtherActivity: null,
  ProxyVoting: null,
  RatingEvent: null,
  ShareholderMeeting: null,
  Voting: null
};

export const EsgAreaEnumDescriptions = {
  BusinessEthics: null,
  Climate: null,
  Environment: null,
  HumanRights: null,
  LabourRights: null,
  Other: null,
  Ratings: null,
  Social: null
};

export const EsgComponentEnumDescriptions = {
  CdsIndexEsgScreenings: null,
  EsgCategory: null,
  GlobalStandardScreen: null,
  KeySector: null,
  ProductInvolvement: null,
  ProductInvolvementRanges: null,
  ScreeningInformation: null,
  SpoProviders: null,
  SustainableDevelopmentGoals: null,
  TransitionCompanies: null,
  UseOfProceeds: null
};

export const EuTaxonomyEnvironmentalObjectiveDescriptions = {
  ClimateChangeAdaption: "Climate change adaptation",
  ClimateChangeMitigation: "Climate change mitigation",
  PollutionPreventionAndControl: "Pollution prevention and control",
  ProtectionAndRestorationBioDiversityAndEcosystems: "Protection and restoration of biodiversity and ecosystems",
  SustainableUseAndProtectionWaterAndMarineResources: "Sustainable use and protection of water and marine resources",
  TransitionToCircularEconomy: "Transition to a circular economy"
};

export const ExposureCollateralTypeDescriptions = {
  CollateralCash: null,
  CollateralSecurity: null,
  Exposure: null
};

export const FeeInterestItemTypeDescriptions = {
  AccruedInterest: null,
  Commission: null,
  CustodyFee: null,
  Fee: null,
  ForeignTax: null,
  Interest: null,
  ManagementCost: "ManagementCost is an type used for costs due to management of funds, e.g. Bloomberg license.",
  ManagementFee: null,
  Rebate: null,
  TaxRestitution: null
};

export const FundReportComponentEnumDescriptions = {
  AttributionMonth: null,
  AtttributionTotal: null,
  BarReturnsMonth: null,
  BarReturnsYearly: null,
  CdsIndexComponents: null,
  CdsIndexEsgScreenings: null,
  CdsIndexHYComponents: null,
  CdsIndexIGComponents: null,
  CountryPieChart: null,
  CreditRating: null,
  CreditSpreadRisk: null,
  CurrencyBarChart: null,
  CurrencyPieChart: null,
  EsgCategory: null,
  InterestRateBuckets: null,
  KeyNumbers: null,
  ReturnVsVolatility: null,
  Sectors: null,
  SustainableDevelopmentGoals: null,
  TopHoldings: null,
  TopIssuers: null,
  TransitionCompanies: null,
  UseOfProceeds: null
};

export const FundReportEnumDescriptions = {
  Benchmark: null,
  Bonds: null,
  Derivatives: null,
  Equities: null,
  Leverage: null
};

export const FundTypeEnumDescriptions = {
  AIF: null,
  UCITS: null
};

export const HistoricalFieldsEnumDescriptions = {
  PX_LAST: null,
  PX_VOLUME: null
};

export const InstrumentCategoryDescriptions = {
  Alternative: null,
  CashAccount: null,
  Equity: null,
  Fx: null,
  HedgeFund: null,
  Interest: null,
  InterestRate: null,
  Liability: null,
  Margin: null,
  None: null,
  RealEstate: null,
  System: null
};

export const InstrumentModelLegFixingTypesEnumDescriptions = {
  Compound: null,
  Single: null
};

export const InstrumentModelLegTypesEnumDescriptions = {
  CashFlowLeg: null,
  EquityBasketOptionLeg: null,
  EquityUnitOptionLeg: null,
  FixedAmountLeg: null,
  FixedRateLeg: null,
  FloatRateLeg: null,
  FtaLeg: null,
  GenericFixedAmountLeg: null,
  GenericFixedRateLeg: null,
  GenericFloatRateLeg: null,
  SwaptionLeg: null
};

export const InstrumentModelObjectTypeEnumDescriptions = {
  Account: null,
  Client: null,
  Instrument: null,
  Portfolio: null
};

export const InstrumentModelTypeEnumDescriptions = {
  Balance: "Balance model is used for bank accounts and such, a placeholder for a currency position.",
  Bond: "Bond model is used for fixed coupon bonds, floating rate bonds and zero coupon bonds. The model needs one fixed amount leg for principal and one coupon leg for coupons. For zero coupon bonds, use fixed rate leg type and 0 coupon.",
  CashFlowEngineering: "DO NOT USE! CashFlowEngineering is not implemented.",
  CdsBasket: "CdsBasket model is used for valuation of a custom CDS Basket traded as a warrant, i.e. as a transferable security.",
  CdsIndex: "CdsIndex model is used for standard CDS index instruments cleared at a CCP.",
  EquityBasketOption: "DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityBasketOption model is used for valuations of equity basket options. Volatility surfaces are stored in system.",
  EquityUnitOption: "DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! EquityUnitOption model is used for valuations of single underlying equity options. Volatility surfaces are stored in system.",
  Fta: "DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Fta model is used for liability valuation.",
  FundExternal: "FundExternal model is used for fund instruments not connected to their own portfolio within the system. The valuation has to be provided from external source.",
  FundInternal: "FundInternal model is used for fund instruments linked to their own portfolio within the system. The issuerId field serves as a link to the portfolio and can be used for more advanced calculations.",
  FxSwap: "FXSwap model is used for the outright leg of an FX Swap position. Model uses a set of FixedAmountLegs in different currencies.",
  GenericDeposit: "GenericDeposit model is used for generic deposit instruments used in curve building. This instrument type should never be connected to a traded position.",
  GenericFxSpot: "GenericFxSpot model is used for generic swap instruments used in curve building. This instrument type should never be connected to a traded position.",
  GenericFxSwap: "GenericFxSwap model is used for generic swap instruments used in curve building. This instrument type should never be connected to a traded position.",
  GenericSwap: "GenericSwap model is used for generic swap instruments used in curve building. This instrument type should never be connected to a traded position.",
  Pending: "SHOULD NEVER BE SET MANUALLY! Pending model is set for trades uploaded where no instrument exists in system. A fund can not be valued while an instrument with model type Pending exists in the underlying portfolio.",
  PortfolioSwap: "DEPRECATED! PortfolioSwap model is the OLD Portfolio Swap model based on keeping positions using swap counterparty as owner of portfolio in system.",
  PortfolioSwapX: "PortfolioSwapX model is the new Portfolio Swap model.",
  RealEstate: "RealEstate model is a spot valuation model which differentiates Real Estate risk factor instruments from other spot valuation models.",
  Spot: "DO NOT USE! NOT IMPLEMENTED IN SWAPLAB2! Spot model is a generic spot model, please use Stock or RealEstate models instead.",
  Stock: "Stock model is a spot valuation model used for all normal equity like instruments, including stock, dividend rights and such. All valuations are done T+0 i.e. no T+2 discounting.",
  Swap: "Swap model is used for interest rate swaps. Currently the model does not support OIS-swaps.",
  Swaption: "Swaption model is used for valuation of swaptions. Using the call/put and pay/recieve properties of the SwaptionLeg gives call: false = Payer Swaption, payleg: false = sold (short) position.",
  ZeroCouponLinear: "ZeroCouponLinear model is used as an amortizing model for not marked valued corporate certificates and other not market valued zero coupon structures. Valuation is a linear amortizing model towards par."
};

export const InstrumentPositionTypeDescriptions = {
  All: null,
  CashAccounts: null,
  Securities: null
};

export const InstrumentProductTypeEnumDescriptions = {
  Account: null,
  BalanceAccount: "For PayableReceivable transactions just a balance since no cash involved",
  Bond: null,
  Cash: null,
  CashAccount: null,
  Fund: null,
  FutureForward: null,
  FxPair: null,
  Index: null,
  InitialMarginAccount: null,
  Liability: null,
  None: null,
  Option: null,
  PrincipalAccount: null,
  RealEstate: null,
  Shares: null,
  Swap: null,
  System: null,
  VariationMarginAccount: null
};

export const InstrumentStatusEnumDescriptions = {
  Confirmed: null,
  Deleted: null,
  Pending: null
};

export const InstrumentTypeEnumDescriptions = {
  Instrument: null,
  PartyInstrument: null
};

export const IorpEnumDescriptions = {
  None: null,
  RiskFree: null,
  SOBL: null
};

export const IssuerProgramCategoryDescriptions = {
  Green: null,
  None: null,
  Social: null,
  SustainabilityLinked: null,
  Sustainable: null,
  Transition: null
};

export const IssuerTypeEnumDescriptions = {
  CreditInstitution: null,
  EuCovered: null,
  Government: null,
  None: null
};

export const JobResultEnumDescriptions = {
  Enqueued: null,
  Failure: null,
  Processing: null,
  Running: null,
  Succeeded: null
};

export const JobStateEnumDescriptions = {
  disabled: null,
  enabled: null
};

export const MappingCollectionNameEnumDescriptions = {
  CurveDefinition: null
};

export const MappingParameterEnumDescriptions = {
  CreditCurve: null,
  DiscountCurve: null,
  FixingCurve: null,
  ForwardCurve: null,
  FxCurve: null
};

export const MappingTypeEnumDescriptions = {
  Currency: null,
  Instrument: null,
  IssuerProgram: null,
  Model: null
};

export const MarginRequirementTypeDescriptions = {
  InitialMarginRequirement: null,
  VariationMarginRequirement: null
};

export const MarginTypeDescriptions = {
  InitialMargin: null,
  VariationMargin: null
};

export const MatchingCoacsStatusDescriptions = {
  Confirmed: null,
  Matched: "Matched with a transaction but found at least on error",
  Mismatch: null,
  Preliminary: null,
  Settled: "Messages of type 566 gets status settled when matched"
};

export const MatchingStatusDescriptions = {
  Confirmed: null,
  Deleted: null,
  Matched: "Matched with a transaction but found at least on error",
  Mismatch: null
};

export const NaceClassDescriptions = {
  A: "(A) Agriculture, Forestry And Fishing",
  A01: "(01) Crop And Animal Production, Hunting And Related Service Activities",
  A02: "(02) Forestry And Logging",
  A03: "(03) Fishing And Aquaculture",
  A011: "(01.1) Growing Of Non-Perennial Crops",
  A012: "(01.2) Growing Of Perennial Crops",
  A013: "(01.3) Plant Propagation",
  A014: "(01.4) Animal Production",
  A015: "(01.5) Mixed Farming",
  A016: "(01.6) Support Activities To Agriculture And Post-Harvest Crop Activities",
  A017: "(01.7) Hunting, Trapping And Related Service Activities",
  A021: "(02.1) Silviculture And Other Forestry Activities",
  A022: "(02.2) Logging",
  A023: "(02.3) Gathering Of Wild Growing Non-Wood Products",
  A024: "(02.4) Support Services To Forestry",
  A031: "(03.1) Fishing",
  A032: "(03.2) Aquaculture",
  A0111: "(01.11) Growing Of Cereals (Except Rice), Leguminous Crops And Oil Seeds",
  A0112: "(01.12) Growing Of Rice",
  A0113: "(01.13) Growing Of Vegetables And Melons, Roots And Tubers",
  A0114: "(01.14) Growing Of Sugar Cane",
  A0115: "(01.15) Growing Of Tobacco",
  A0116: "(01.16) Growing Of Fibre Crops",
  A0119: "(01.19) Growing Of Other Non-Perennial Crops",
  A0121: "(01.21) Growing Of Grapes",
  A0122: "(01.22) Growing Of Tropical And Subtropical Fruits",
  A0123: "(01.23) Growing Of Citrus Fruits",
  A0124: "(01.24) Growing Of Pome Fruits And Stone Fruits",
  A0125: "(01.25) Growing Of Other Tree And Bush Fruits And Nuts",
  A0126: "(01.26) Growing Of Oleaginous Fruits",
  A0127: "(01.27) Growing Of Beverage Crops",
  A0128: "(01.28) Growing Of Spices, Aromatic, Drug And Pharmaceutical Crops",
  A0129: "(01.29) Growing Of Other Perennial Crops",
  A0130: "(01.30) Plant Propagation",
  A0141: "(01.41) Raising Of Dairy Cattle",
  A0142: "(01.42) Raising Of Other Cattle And Buffaloes",
  A0143: "(01.43) Raising Of Horses And Other Equines",
  A0144: "(01.44) Raising Of Camels And Camelids",
  A0145: "(01.45) Raising Of Sheep And Goats",
  A0146: "(01.46) Raising Of Swine/Pigs",
  A0147: "(01.47) Raising Of Poultry",
  A0149: "(01.49) Raising Of Other Animals",
  A0150: "(01.50) Mixed Farming",
  A0161: "(01.61) Support Activities For Crop Production",
  A0162: "(01.62) Support Activities For Animal Production",
  A0163: "(01.63) Post-Harvest Crop Activities",
  A0164: "(01.64) Seed Processing For Propagation",
  A0170: "(01.70) Hunting, Trapping And Related Service Activities",
  A0210: "(02.10) Silviculture And Other Forestry Activities",
  A0220: "(02.20) Logging",
  A0230: "(02.30) Gathering Of Wild Growing Non-Wood Products",
  A0240: "(02.40) Support Services To Forestry",
  A0311: "(03.11) Marine Fishing",
  A0312: "(03.12) Freshwater Fishing",
  A0321: "(03.21) Marine Aquaculture",
  A0322: "(03.22) Freshwater Aquaculture",
  B: "(B) Mining And Quarrying",
  B05: "(05) Mining Of Coal And Lignite",
  B06: "(06) Extraction Of Crude Petroleum And Natural Gas",
  B07: "(07) Mining Of Metal Ores",
  B08: "(08) Other Mining And Quarrying",
  B09: "(09) Mining Support Service Activities",
  B051: "(05.1) Mining Of Hard Coal",
  B052: "(05.2) Mining Of Lignite",
  B061: "(06.1) Extraction Of Crude Petroleum",
  B062: "(06.2) Extraction Of Natural Gas",
  B071: "(07.1) Mining Of Iron Ores",
  B072: "(07.2) Mining Of Non-Ferrous Metal Ores",
  B081: "(08.1) Quarrying Of Stone, Sand And Clay",
  B089: "(08.9) Mining And Quarrying N.E.C.",
  B091: "(09.1) Support Activities For Petroleum And Natural Gas Extraction",
  B099: "(09.9) Support Activities For Other Mining And Quarrying",
  B0510: "(05.10) Mining Of Hard Coal",
  B0520: "(05.20) Mining Of Lignite",
  B0610: "(06.10) Extraction Of Crude Petroleum",
  B0620: "(06.20) Extraction Of Natural Gas",
  B0710: "(07.10) Mining Of Iron Ores",
  B0721: "(07.21) Mining Of Uranium And Thorium Ores",
  B0729: "(07.29) Mining Of Other Non-Ferrous Metal Ores",
  B0811: "(08.11) Quarrying Of Ornamental And Building Stone, Limestone, Gypsum, Chalk And Slate",
  B0812: "(08.12) Operation Of Gravel And Sand Pits; Mining Of Clays And Kaolin",
  B0891: "(08.91) Mining Of Chemical And Fertiliser Minerals",
  B0892: "(08.92) Extraction Of Peat",
  B0893: "(08.93) Extraction Of Salt",
  B0899: "(08.99) Other Mining And Quarrying N.E.C.",
  B0910: "(09.10) Support Activities For Petroleum And Natural Gas Extraction",
  B0990: "(09.90) Support Activities For Other Mining And Quarrying",
  C: "(C) Manufacturing",
  C10: "(10) Manufacture Of Food Products",
  C11: "(11) Manufacture Of Beverages",
  C12: "(12) Manufacture Of Tobacco Products",
  C13: "(13) Manufacture Of Textiles",
  C14: "(14) Manufacture Of Wearing Apparel",
  C15: "(15) Manufacture Of Leather And Related Products",
  C16: "(16) Manufacture Of Wood And Of Products Of Wood And Cork, Except Furniture; Manufacture Of Articles Of Straw And Plaiting Materials",
  C17: "(17) Manufacture Of Paper And Paper Products",
  C18: "(18) Printing And Reproduction Of Recorded Media",
  C19: "(19) Manufacture Of Coke And Refined Petroleum Products",
  C20: "(20) Manufacture Of Chemicals And Chemical Products",
  C21: "(21) Manufacture Of Basic Pharmaceutical Products And Pharmaceutical Preparations",
  C22: "(22) Manufacture Of Rubber And Plastic Products",
  C23: "(23) Manufacture Of Other Non-Metallic Mineral Products",
  C24: "(24) Manufacture Of Basic Metals",
  C25: "(25) Manufacture Of Fabricated Metal Products, Except Machinery And Equipment",
  C26: "(26) Manufacture Of Computer, Electronic And Optical Products",
  C27: "(27) Manufacture Of Electrical Equipment",
  C28: "(28) Manufacture Of Machinery And Equipment N.E.C.",
  C29: "(29) Manufacture Of Motor Vehicles, Trailers And Semi-Trailers",
  C30: "(30) Manufacture Of Other Transport Equipment",
  C31: "(31) Manufacture Of Furniture",
  C32: "(32) Other Manufacturing",
  C33: "(33) Repair And Installation Of Machinery And Equipment",
  C101: "(10.1) Processing And Preserving Of Meat And Production Of Meat Products",
  C102: "(10.2) Processing And Preserving Of Fish, Crustaceans And Molluscs",
  C103: "(10.3) Processing And Preserving Of Fruit And Vegetables",
  C104: "(10.4) Manufacture Of Vegetable And Animal Oils And Fats",
  C105: "(10.5) Manufacture Of Dairy Products",
  C106: "(10.6) Manufacture Of Grain Mill Products, Starches And Starch Products",
  C107: "(10.7) Manufacture Of Bakery And Farinaceous Products",
  C108: "(10.8) Manufacture Of Other Food Products",
  C109: "(10.9) Manufacture Of Prepared Animal Feeds",
  C110: "(11.0) Manufacture Of Beverages",
  C120: "(12.0) Manufacture Of Tobacco Products",
  C131: "(13.1) Preparation And Spinning Of Textile Fibres",
  C132: "(13.2) Weaving Of Textiles",
  C133: "(13.3) Finishing Of Textiles",
  C139: "(13.9) Manufacture Of Other Textiles",
  C141: "(14.1) Manufacture Of Wearing Apparel, Except Fur Apparel",
  C142: "(14.2) Manufacture Of Articles Of Fur",
  C143: "(14.3) Manufacture Of Knitted And Crocheted Apparel",
  C151: "(15.1) Tanning And Dressing Of Leather; Manufacture Of Luggage, Handbags, Saddlery And Harness; Dressing And Dyeing Of Fur",
  C152: "(15.2) Manufacture Of Footwear",
  C161: "(16.1) Sawmilling And Planing Of Wood",
  C162: "(16.2) Manufacture Of Products Of Wood, Cork, Straw And Plaiting Materials",
  C171: "(17.1) Manufacture Of Pulp, Paper And Paperboard",
  C172: "(17.2) Manufacture Of Articles Of Paper And Paperboard",
  C181: "(18.1) Printing And Service Activities Related To Printing",
  C182: "(18.2) Reproduction Of Recorded Media",
  C191: "(19.1) Manufacture Of Coke Oven Products",
  C192: "(19.2) Manufacture Of Refined Petroleum Products",
  C201: "(20.1) Manufacture Of Basic Chemicals, Fertilisers And Nitrogen Compounds, Plastics And Synthetic Rubber In Primary Forms",
  C202: "(20.2) Manufacture Of Pesticides And Other Agrochemical Products",
  C203: "(20.3) Manufacture Of Paints, Varnishes And Similar Coatings, Printing Ink And Mastics",
  C204: "(20.4) Manufacture Of Soap And Detergents, Cleaning And Polishing Preparations, Perfumes And Toilet Preparations",
  C205: "(20.5) Manufacture Of Other Chemical Products",
  C206: "(20.6) Manufacture Of Man-Made Fibres",
  C211: "(21.1) Manufacture Of Basic Pharmaceutical Products",
  C212: "(21.2) Manufacture Of Pharmaceutical Preparations",
  C221: "(22.1) Manufacture Of Rubber Products",
  C222: "(22.2) Manufacture Of Plastics Products",
  C231: "(23.1) Manufacture Of Glass And Glass Products",
  C232: "(23.2) Manufacture Of Refractory Products",
  C233: "(23.3) Manufacture Of Clay Building Materials",
  C234: "(23.4) Manufacture Of Other Porcelain And Ceramic Products",
  C235: "(23.5) Manufacture Of Cement, Lime And Plaster",
  C236: "(23.6) Manufacture Of Articles Of Concrete, Cement And Plaster",
  C237: "(23.7) Cutting, Shaping And Finishing Of Stone",
  C239: "(23.9) Manufacture Of Abrasive Products And Non-Metallic Mineral Products N.E.C.",
  C241: "(24.1) Manufacture Of Basic Iron And Steel And Of Ferro-Alloys",
  C242: "(24.2) Manufacture Of Tubes, Pipes, Hollow Profiles And Related Fittings, Of Steel",
  C243: "(24.3) Manufacture Of Other Products Of First Processing Of Steel",
  C244: "(24.4) Manufacture Of Basic Precious And Other Non-Ferrous Metals",
  C245: "(24.5) Casting Of Metals",
  C251: "(25.1) Manufacture Of Structural Metal Products",
  C252: "(25.2) Manufacture Of Tanks, Reservoirs And Containers Of Metal",
  C253: "(25.3) Manufacture Of Steam Generators, Except Central Heating Hot Water Boilers",
  C254: "(25.4) Manufacture Of Weapons And Ammunition",
  C255: "(25.5) Forging, Pressing, Stamping And Roll-Forming Of Metal; Powder Metallurgy",
  C256: "(25.6) Treatment And Coating Of Metals; Machining",
  C257: "(25.7) Manufacture Of Cutlery, Tools And General Hardware",
  C259: "(25.9) Manufacture Of Other Fabricated Metal Products",
  C261: "(26.1) Manufacture Of Electronic Components And Boards",
  C262: "(26.2) Manufacture Of Computers And Peripheral Equipment",
  C263: "(26.3) Manufacture Of Communication Equipment",
  C264: "(26.4) Manufacture Of Consumer Electronics",
  C265: "(26.5) Manufacture Of Instruments And Appliances For Measuring, Testing And Navigation; Watches And Clocks",
  C266: "(26.6) Manufacture Of Irradiation, Electromedical And Electrotherapeutic Equipment",
  C267: "(26.7) Manufacture Of Optical Instruments And Photographic Equipment",
  C268: "(26.8) Manufacture Of Magnetic And Optical Media",
  C271: "(27.1) Manufacture Of Electric Motors, Generators, Transformers And Electricity Distribution And Control Apparatus",
  C272: "(27.2) Manufacture Of Batteries And Accumulators",
  C273: "(27.3) Manufacture Of Wiring And Wiring Devices",
  C274: "(27.4) Manufacture Of Electric Lighting Equipment",
  C275: "(27.5) Manufacture Of Domestic Appliances",
  C279: "(27.9) Manufacture Of Other Electrical Equipment",
  C281: "(28.1) Manufacture Of General-Purpose Machinery",
  C282: "(28.2) Manufacture Of Other General-Purpose Machinery",
  C283: "(28.3) Manufacture Of Agricultural And Forestry Machinery",
  C284: "(28.4) Manufacture Of Metal Forming Machinery And Machine Tools",
  C289: "(28.9) Manufacture Of Other Special-Purpose Machinery",
  C291: "(29.1) Manufacture Of Motor Vehicles",
  C292: "(29.2) Manufacture Of Bodies (Coachwork) For Motor Vehicles; Manufacture Of Trailers And Semi-Trailers",
  C293: "(29.3) Manufacture Of Parts And Accessories For Motor Vehicles",
  C301: "(30.1) Building Of Ships And Boats",
  C302: "(30.2) Manufacture Of Railway Locomotives And Rolling Stock",
  C303: "(30.3) Manufacture Of Air And Spacecraft And Related Machinery",
  C304: "(30.4) Manufacture Of Military Fighting Vehicles",
  C309: "(30.9) Manufacture Of Transport Equipment N.E.C.",
  C310: "(31.0) Manufacture Of Furniture",
  C321: "(32.1) Manufacture Of Jewellery, Bijouterie And Related Articles",
  C322: "(32.2) Manufacture Of Musical Instruments",
  C323: "(32.3) Manufacture Of Sports Goods",
  C324: "(32.4) Manufacture Of Games And Toys",
  C325: "(32.5) Manufacture Of Medical And Dental Instruments And Supplies",
  C329: "(32.9) Manufacturing N.E.C.",
  C331: "(33.1) Repair Of Fabricated Metal Products, Machinery And Equipment",
  C332: "(33.2) Installation Of Industrial Machinery And Equipment",
  C1011: "(10.11) Processing And Preserving Of Meat",
  C1012: "(10.12) Processing And Preserving Of Poultry Meat",
  C1013: "(10.13) Production Of Meat And Poultry Meat Products",
  C1020: "(10.20) Processing And Preserving Of Fish, Crustaceans And Molluscs",
  C1031: "(10.31) Processing And Preserving Of Potatoes",
  C1032: "(10.32) Manufacture Of Fruit And Vegetable Juice",
  C1039: "(10.39) Other Processing And Preserving Of Fruit And Vegetables",
  C1041: "(10.41) Manufacture Of Oils And Fats",
  C1042: "(10.42) Manufacture Of Margarine And Similar Edible Fats",
  C1051: "(10.51) Operation Of Dairies And Cheese Making",
  C1052: "(10.52) Manufacture Of Ice Cream",
  C1061: "(10.61) Manufacture Of Grain Mill Products",
  C1062: "(10.62) Manufacture Of Starches And Starch Products",
  C1071: "(10.71) Manufacture Of Bread; Manufacture Of Fresh Pastry Goods And Cakes",
  C1072: "(10.72) Manufacture Of Rusks And Biscuits; Manufacture Of Preserved Pastry Goods And Cakes",
  C1073: "(10.73) Manufacture Of Macaroni, Noodles, Couscous And Similar Farinaceous Products",
  C1081: "(10.81) Manufacture Of Sugar",
  C1082: "(10.82) Manufacture Of Cocoa, Chocolate And Sugar Confectionery",
  C1083: "(10.83) Processing Of Tea And Coffee",
  C1084: "(10.84) Manufacture Of Condiments And Seasonings",
  C1085: "(10.85) Manufacture Of Prepared Meals And Dishes",
  C1086: "(10.86) Manufacture Of Homogenised Food Preparations And Dietetic Food",
  C1089: "(10.89) Manufacture Of Other Food Products N.E.C.",
  C1091: "(10.91) Manufacture Of Prepared Feeds For Farm Animals",
  C1092: "(10.92) Manufacture Of Prepared Pet Foods",
  C1101: "(11.01) Distilling, Rectifying And Blending Of Spirits",
  C1102: "(11.02) Manufacture Of Wine From Grape",
  C1103: "(11.03) Manufacture Of Cider And Other Fruit Wines",
  C1104: "(11.04) Manufacture Of Other Non-Distilled Fermented Beverages",
  C1105: "(11.05) Manufacture Of Beer",
  C1106: "(11.06) Manufacture Of Malt",
  C1107: "(11.07) Manufacture Of Soft Drinks; Production Of Mineral Waters And Other Bottled Waters",
  C1200: "(12.00) Manufacture Of Tobacco Products",
  C1310: "(13.10) Preparation And Spinning Of Textile Fibres",
  C1320: "(13.20) Weaving Of Textiles",
  C1330: "(13.30) Finishing Of Textiles",
  C1391: "(13.91) Manufacture Of Knitted And Crocheted Fabrics",
  C1392: "(13.92) Manufacture Of Made-Up Textile Articles, Except Apparel",
  C1393: "(13.93) Manufacture Of Carpets And Rugs",
  C1394: "(13.94) Manufacture Of Cordage, Rope, Twine And Netting",
  C1395: "(13.95) Manufacture Of Non-Wovens And Articles Made From Non-Wovens, Except Apparel",
  C1396: "(13.96) Manufacture Of Other Technical And Industrial Textiles",
  C1399: "(13.99) Manufacture Of Other Textiles N.E.C.",
  C1411: "(14.11) Manufacture Of Leather Clothes",
  C1412: "(14.12) Manufacture Of Workwear",
  C1413: "(14.13) Manufacture Of Other Outerwear",
  C1414: "(14.14) Manufacture Of Underwear",
  C1419: "(14.19) Manufacture Of Other Wearing Apparel And Accessories",
  C1420: "(14.20) Manufacture Of Articles Of Fur",
  C1431: "(14.31) Manufacture Of Knitted And Crocheted Hosiery",
  C1439: "(14.39) Manufacture Of Other Knitted And Crocheted Apparel",
  C1511: "(15.11) Tanning And Dressing Of Leather; Dressing And Dyeing Of Fur",
  C1512: "(15.12) Manufacture Of Luggage, Handbags And The Like, Saddlery And Harness",
  C1520: "(15.20) Manufacture Of Footwear",
  C1610: "(16.10) Sawmilling And Planing Of Wood",
  C1621: "(16.21) Manufacture Of Veneer Sheets And Wood-Based Panels",
  C1622: "(16.22) Manufacture Of Assembled Parquet Floors",
  C1623: "(16.23) Manufacture Of Other Builders' Carpentry And Joinery",
  C1624: "(16.24) Manufacture Of Wooden Containers",
  C1629: "(16.29) Manufacture Of Other Products Of Wood; Manufacture Of Articles Of Cork, Straw And Plaiting Materials",
  C1711: "(17.11) Manufacture Of Pulp",
  C1712: "(17.12) Manufacture Of Paper And Paperboard",
  C1721: "(17.21) Manufacture Of Corrugated Paper And Paperboard And Of Containers Of Paper And Paperboard",
  C1722: "(17.22) Manufacture Of Household And Sanitary Goods And Of Toilet Requisites",
  C1723: "(17.23) Manufacture Of Paper Stationery",
  C1724: "(17.24) Manufacture Of Wallpaper",
  C1729: "(17.29) Manufacture Of Other Articles Of Paper And Paperboard",
  C1811: "(18.11) Printing Of Newspapers",
  C1812: "(18.12) Other Printing",
  C1813: "(18.13) Pre-Press And Pre-Media Services",
  C1814: "(18.14) Binding And Related Services",
  C1820: "(18.20) Reproduction Of Recorded Media",
  C1910: "(19.10) Manufacture Of Coke Oven Products",
  C1920: "(19.20) Manufacture Of Refined Petroleum Products",
  C2011: "(20.11) Manufacture Of Industrial Gases",
  C2012: "(20.12) Manufacture Of Dyes And Pigments",
  C2013: "(20.13) Manufacture Of Other Inorganic Basic Chemicals",
  C2014: "(20.14) Manufacture Of Other Organic Basic Chemicals",
  C2015: "(20.15) Manufacture Of Fertilisers And Nitrogen Compounds",
  C2016: "(20.16) Manufacture Of Plastics In Primary Forms",
  C2017: "(20.17) Manufacture Of Synthetic Rubber In Primary Forms",
  C2020: "(20.20) Manufacture Of Pesticides And Other Agrochemical Products",
  C2030: "(20.30) Manufacture Of Paints, Varnishes And Similar Coatings, Printing Ink And Mastics",
  C2041: "(20.41) Manufacture Of Soap And Detergents, Cleaning And Polishing Preparations",
  C2042: "(20.42) Manufacture Of Perfumes And Toilet Preparations",
  C2051: "(20.51) Manufacture Of Explosives",
  C2052: "(20.52) Manufacture Of Glues",
  C2053: "(20.53) Manufacture Of Essential Oils",
  C2059: "(20.59) Manufacture Of Other Chemical Products N.E.C.",
  C2060: "(20.60) Manufacture Of Man-Made Fibres",
  C2110: "(21.10) Manufacture Of Basic Pharmaceutical Products",
  C2120: "(21.20) Manufacture Of Pharmaceutical Preparations",
  C2211: "(22.11) Manufacture Of Rubber Tyres And Tubes; Retreading And Rebuilding Of Rubber Tyres",
  C2219: "(22.19) Manufacture Of Other Rubber Products",
  C2221: "(22.21) Manufacture Of Plastic Plates, Sheets, Tubes And Profiles",
  C2222: "(22.22) Manufacture Of Plastic Packing Goods",
  C2223: "(22.23) Manufacture Of Builders’ Ware Of Plastic",
  C2229: "(22.29) Manufacture Of Other Plastic Products",
  C2311: "(23.11) Manufacture Of Flat Glass",
  C2312: "(23.12) Shaping And Processing Of Flat Glass",
  C2313: "(23.13) Manufacture Of Hollow Glass",
  C2314: "(23.14) Manufacture Of Glass Fibres",
  C2319: "(23.19) Manufacture And Processing Of Other Glass, Including Technical Glassware",
  C2320: "(23.20) Manufacture Of Refractory Products",
  C2331: "(23.31) Manufacture Of Ceramic Tiles And Flags",
  C2332: "(23.32) Manufacture Of Bricks, Tiles And Construction Products, In Baked Clay",
  C2341: "(23.41) Manufacture Of Ceramic Household And Ornamental Articles",
  C2342: "(23.42) Manufacture Of Ceramic Sanitary Fixtures",
  C2343: "(23.43) Manufacture Of Ceramic Insulators And Insulating Fittings",
  C2344: "(23.44) Manufacture Of Other Technical Ceramic Products",
  C2349: "(23.49) Manufacture Of Other Ceramic Products",
  C2351: "(23.51) Manufacture Of Cement",
  C2352: "(23.52) Manufacture Of Lime And Plaster",
  C2361: "(23.61) Manufacture Of Concrete Products For Construction Purposes",
  C2362: "(23.62) Manufacture Of Plaster Products For Construction Purposes",
  C2363: "(23.63) Manufacture Of Ready-Mixed Concrete",
  C2364: "(23.64) Manufacture Of Mortars",
  C2365: "(23.65) Manufacture Of Fibre Cement",
  C2369: "(23.69) Manufacture Of Other Articles Of Concrete, Plaster And Cement",
  C2370: "(23.70) Cutting, Shaping And Finishing Of Stone",
  C2391: "(23.91) Production Of Abrasive Products",
  C2399: "(23.99) Manufacture Of Other Non-Metallic Mineral Products N.E.C.",
  C2410: "(24.10) Manufacture Of Basic Iron And Steel And Of Ferro-Alloys",
  C2420: "(24.20) Manufacture Of Tubes, Pipes, Hollow Profiles And Related Fittings, Of Steel",
  C2431: "(24.31) Cold Drawing Of Bars",
  C2432: "(24.32) Cold Rolling Of Narrow Strip",
  C2433: "(24.33) Cold Forming Or Folding",
  C2434: "(24.34) Cold Drawing Of Wire",
  C2441: "(24.41) Precious Metals Production",
  C2442: "(24.42) Aluminium Production",
  C2443: "(24.43) Lead, Zinc And Tin Production",
  C2444: "(24.44) Copper Production",
  C2445: "(24.45) Other Non-Ferrous Metal Production",
  C2446: "(24.46) Processing Of Nuclear Fuel",
  C2451: "(24.51) Casting Of Iron",
  C2452: "(24.52) Casting Of Steel",
  C2453: "(24.53) Casting Of Light Metals",
  C2454: "(24.54) Casting Of Other Non-Ferrous Metals",
  C2511: "(25.11) Manufacture Of Metal Structures And Parts Of Structures",
  C2512: "(25.12) Manufacture Of Doors And Windows Of Metal",
  C2521: "(25.21) Manufacture Of Central Heating Radiators And Boilers",
  C2529: "(25.29) Manufacture Of Other Tanks, Reservoirs And Containers Of Metal",
  C2530: "(25.30) Manufacture Of Steam Generators, Except Central Heating Hot Water Boilers",
  C2540: "(25.40) Manufacture Of Weapons And Ammunition",
  C2550: "(25.50) Forging, Pressing, Stamping And Roll-Forming Of Metal; Powder Metallurgy",
  C2561: "(25.61) Treatment And Coating Of Metals",
  C2562: "(25.62) Machining",
  C2571: "(25.71) Manufacture Of Cutlery",
  C2572: "(25.72) Manufacture Of Locks And Hinges",
  C2573: "(25.73) Manufacture Of Tools",
  C2591: "(25.91) Manufacture Of Steel Drums And Similar Containers",
  C2592: "(25.92) Manufacture Of Light Metal Packaging",
  C2593: "(25.93) Manufacture Of Wire Products, Chain And Springs",
  C2594: "(25.94) Manufacture Of Fasteners And Screw Machine Products",
  C2599: "(25.99) Manufacture Of Other Fabricated Metal Products N.E.C.",
  C2611: "(26.11) Manufacture Of Electronic Components",
  C2612: "(26.12) Manufacture Of Loaded Electronic Boards",
  C2620: "(26.20) Manufacture Of Computers And Peripheral Equipment",
  C2630: "(26.30) Manufacture Of Communication Equipment",
  C2640: "(26.40) Manufacture Of Consumer Electronics",
  C2651: "(26.51) Manufacture Of Instruments And Appliances For Measuring, Testing And Navigation",
  C2652: "(26.52) Manufacture Of Watches And Clocks",
  C2660: "(26.60) Manufacture Of Irradiation, Electromedical And Electrotherapeutic Equipment",
  C2670: "(26.70) Manufacture Of Optical Instruments And Photographic Equipment",
  C2680: "(26.80) Manufacture Of Magnetic And Optical Media",
  C2711: "(27.11) Manufacture Of Electric Motors, Generators And Transformers",
  C2712: "(27.12) Manufacture Of Electricity Distribution And Control Apparatus",
  C2720: "(27.20) Manufacture Of Batteries And Accumulators",
  C2731: "(27.31) Manufacture Of Fibre Optic Cables",
  C2732: "(27.32) Manufacture Of Other Electronic And Electric Wires And Cables",
  C2733: "(27.33) Manufacture Of Wiring Devices",
  C2740: "(27.40) Manufacture Of Electric Lighting Equipment",
  C2751: "(27.51) Manufacture Of Electric Domestic Appliances",
  C2752: "(27.52) Manufacture Of Non-Electric Domestic Appliances",
  C2790: "(27.90) Manufacture Of Other Electrical Equipment",
  C2811: "(28.11) Manufacture Of Engines And Turbines, Except Aircraft, Vehicle And Cycle Engines",
  C2812: "(28.12) Manufacture Of Fluid Power Equipment",
  C2813: "(28.13) Manufacture Of Other Pumps And Compressors",
  C2814: "(28.14) Manufacture Of Other Taps And Valves",
  C2815: "(28.15) Manufacture Of Bearings, Gears, Gearing And Driving Elements",
  C2821: "(28.21) Manufacture Of Ovens, Furnaces And Furnace Burners",
  C2822: "(28.22) Manufacture Of Lifting And Handling Equipment",
  C2823: "(28.23) Manufacture Of Office Machinery And Equipment (Except Computers And Peripheral Equipment)",
  C2824: "(28.24) Manufacture Of Power-Driven Hand Tools",
  C2825: "(28.25) Manufacture Of Non-Domestic Cooling And Ventilation Equipment",
  C2829: "(28.29) Manufacture Of Other General-Purpose Machinery N.E.C.",
  C2830: "(28.30) Manufacture Of Agricultural And Forestry Machinery",
  C2841: "(28.41) Manufacture Of Metal Forming Machinery",
  C2849: "(28.49) Manufacture Of Other Machine Tools",
  C2891: "(28.91) Manufacture Of Machinery For Metallurgy",
  C2892: "(28.92) Manufacture Of Machinery For Mining, Quarrying And Construction",
  C2893: "(28.93) Manufacture Of Machinery For Food, Beverage And Tobacco Processing",
  C2894: "(28.94) Manufacture Of Machinery For Textile, Apparel And Leather Production",
  C2895: "(28.95) Manufacture Of Machinery For Paper And Paperboard Production",
  C2896: "(28.96) Manufacture Of Plastics And Rubber Machinery",
  C2899: "(28.99) Manufacture Of Other Special-Purpose Machinery N.E.C.",
  C2910: "(29.10) Manufacture Of Motor Vehicles",
  C2920: "(29.20) Manufacture Of Bodies (Coachwork) For Motor Vehicles; Manufacture Of Trailers And Semi-Trailers",
  C2931: "(29.31) Manufacture Of Electrical And Electronic Equipment For Motor Vehicles",
  C2932: "(29.32) Manufacture Of Other Parts And Accessories For Motor Vehicles",
  C3011: "(30.11) Building Of Ships And Floating Structures",
  C3012: "(30.12) Building Of Pleasure And Sporting Boats",
  C3020: "(30.20) Manufacture Of Railway Locomotives And Rolling Stock",
  C3030: "(30.30) Manufacture Of Air And Spacecraft And Related Machinery",
  C3040: "(30.40) Manufacture Of Military Fighting Vehicles",
  C3091: "(30.91) Manufacture Of Motorcycles",
  C3092: "(30.92) Manufacture Of Bicycles And Invalid Carriages",
  C3099: "(30.99) Manufacture Of Other Transport Equipment N.E.C.",
  C3101: "(31.01) Manufacture Of Office And Shop Furniture",
  C3102: "(31.02) Manufacture Of Kitchen Furniture",
  C3103: "(31.03) Manufacture Of Mattresses",
  C3109: "(31.09) Manufacture Of Other Furniture",
  C3211: "(32.11) Striking Of Coins",
  C3212: "(32.12) Manufacture Of Jewellery And Related Articles",
  C3213: "(32.13) Manufacture Of Imitation Jewellery And Related Articles",
  C3220: "(32.20) Manufacture Of Musical Instruments",
  C3230: "(32.30) Manufacture Of Sports Goods",
  C3240: "(32.40) Manufacture Of Games And Toys",
  C3250: "(32.50) Manufacture Of Medical And Dental Instruments And Supplies",
  C3291: "(32.91) Manufacture Of Brooms And Brushes",
  C3299: "(32.99) Other Manufacturing N.E.C.",
  C3311: "(33.11) Repair Of Fabricated Metal Products",
  C3312: "(33.12) Repair Of Machinery",
  C3313: "(33.13) Repair Of Electronic And Optical Equipment",
  C3314: "(33.14) Repair Of Electrical Equipment",
  C3315: "(33.15) Repair And Maintenance Of Ships And Boats",
  C3316: "(33.16) Repair And Maintenance Of Aircraft And Spacecraft",
  C3317: "(33.17) Repair And Maintenance Of Other Transport Equipment",
  C3319: "(33.19) Repair Of Other Equipment",
  C3320: "(33.20) Installation Of Industrial Machinery And Equipment",
  D: "(D) Electricity, Gas, Steam And Air Conditioning Supply",
  D35: "(35) Electricity, Gas, Steam And Air Conditioning Supply",
  D351: "(35.1) Electric Power Generation, Transmission And Distribution",
  D352: "(35.2) Manufacture Of Gas; Distribution Of Gaseous Fuels Through Mains",
  D353: "(35.3) Steam And Air Conditioning Supply",
  D3511: "(35.11) Production Of Electricity",
  D3512: "(35.12) Transmission Of Electricity",
  D3513: "(35.13) Distribution Of Electricity",
  D3514: "(35.14) Trade Of Electricity",
  D3521: "(35.21) Manufacture Of Gas",
  D3522: "(35.22) Distribution Of Gaseous Fuels Through Mains",
  D3523: "(35.23) Trade Of Gas Through Mains",
  D3530: "(35.30) Steam And Air Conditioning Supply",
  E: "(E) Water Supply; Sewerage; Waste Managment And Remediation Activities",
  E36: "(36) Water Collection, Treatment And Supply",
  E37: "(37) Sewerage",
  E38: "(38) Waste Collection, Treatment And Disposal Activities; Materials Recovery",
  E39: "(39) Remediation Activities And Other Waste Management Services",
  E360: "(36.0) Water Collection, Treatment And Supply",
  E370: "(37.0) Sewerage",
  E381: "(38.1) Waste Collection",
  E382: "(38.2) Waste Treatment And Disposal",
  E383: "(38.3) Materials Recovery",
  E390: "(39.0) Remediation Activities And Other Waste Management Services",
  E3600: "(36.00) Water Collection, Treatment And Supply",
  E3700: "(37.00) Sewerage",
  E3811: "(38.11) Collection Of Non-Hazardous Waste",
  E3812: "(38.12) Collection Of Hazardous Waste",
  E3821: "(38.21) Treatment And Disposal Of Non-Hazardous Waste",
  E3822: "(38.22) Treatment And Disposal Of Hazardous Waste",
  E3831: "(38.31) Dismantling Of Wrecks",
  E3832: "(38.32) Recovery Of Sorted Materials",
  E3900: "(39.00) Remediation Activities And Other Waste Management Services",
  F: "(F) Construction",
  F41: "(41) Construction Of Buildings",
  F42: "(42) Civil Engineering",
  F43: "(43) Specialised Construction Activities",
  F411: "(41.1) Development Of Building Projects",
  F412: "(41.2) Construction Of Residential And Non-Residential Buildings",
  F421: "(42.1) Construction Of Roads And Railways",
  F422: "(42.2) Construction Of Utility Projects",
  F429: "(42.9) Construction Of Other Civil Engineering Projects",
  F431: "(43.1) Demolition And Site Preparation",
  F432: "(43.2) Electrical, Plumbing And Other Construction Installation Activities",
  F433: "(43.3) Building Completion And Finishing",
  F439: "(43.9) Other Specialised Construction Activities",
  F4110: "(41.10) Development Of Building Projects",
  F4120: "(41.20) Construction Of Residential And Non-Residential Buildings",
  F4211: "(42.11) Construction Of Roads And Motorways",
  F4212: "(42.12) Construction Of Railways And Underground Railways",
  F4213: "(42.13) Construction Of Bridges And Tunnels",
  F4221: "(42.21) Construction Of Utility Projects For Fluids",
  F4222: "(42.22) Construction Of Utility Projects For Electricity And Telecommunications",
  F4291: "(42.91) Construction Of Water Projects",
  F4299: "(42.99) Construction Of Other Civil Engineering Projects N.E.C.",
  F4311: "(43.11) Demolition",
  F4312: "(43.12) Site Preparation",
  F4313: "(43.13) Test Drilling And Boring",
  F4321: "(43.21) Electrical Installation",
  F4322: "(43.22) Plumbing, Heat And Air-Conditioning Installation",
  F4329: "(43.29) Other Construction Installation",
  F4331: "(43.31) Plastering",
  F4332: "(43.32) Joinery Installation",
  F4333: "(43.33) Floor And Wall Covering",
  F4334: "(43.34) Painting And Glazing",
  F4339: "(43.39) Other Building Completion And Finishing",
  F4391: "(43.91) Roofing Activities",
  F4399: "(43.99) Other Specialised Construction Activities N.E.C.",
  G: "(G) Wholesale And Retail Trade; Repair Of Motor Vehicles And Motorcycles",
  G45: "(45) Wholesale And Retail Trade And Repair Of Motor Vehicles And Motorcycles",
  G46: "(46) Wholesale Trade, Except Of Motor Vehicles And Motorcycles",
  G47: "(47) Retail Trade, Except Of Motor Vehicles And Motorcycles",
  G451: "(45.1) Sale Of Motor Vehicles",
  G452: "(45.2) Maintenance And Repair Of Motor Vehicles",
  G453: "(45.3) Sale Of Motor Vehicle Parts And Accessories",
  G454: "(45.4) Sale, Maintenance And Repair Of Motorcycles And Related Parts And Accessories",
  G461: "(46.1) Wholesale On A Fee Or Contract Basis",
  G462: "(46.2) Wholesale Of Agricultural Raw Materials And Live Animals",
  G463: "(46.3) Wholesale Of Food, Beverages And Tobacco",
  G464: "(46.4) Wholesale Of Household Goods",
  G465: "(46.5) Wholesale Of Information And Communication Equipment",
  G466: "(46.6) Wholesale Of Other Machinery, Equipment And Supplies",
  G467: "(46.7) Other Specialised Wholesale",
  G469: "(46.9) Non-Specialised Wholesale Trade",
  G471: "(47.1) Retail Sale In Non-Specialised Stores",
  G472: "(47.2) Retail Sale Of Food, Beverages And Tobacco In Specialised Stores",
  G473: "(47.3) Retail Sale Of Automotive Fuel In Specialised Stores",
  G474: "(47.4) Retail Sale Of Information And Communication Equipment In Specialised Stores",
  G475: "(47.5) Retail Sale Of Other Household Equipment In Specialised Stores",
  G476: "(47.6) Retail Sale Of Cultural And Recreation Goods In Specialised Stores",
  G477: "(47.7) Retail Sale Of Other Goods In Specialised Stores",
  G478: "(47.8) Retail Sale Via Stalls And Markets",
  G479: "(47.9) Retail Trade Not In Stores, Stalls Or Markets",
  G4511: "(45.11) Sale Of Cars And Light Motor Vehicles",
  G4519: "(45.19) Sale Of Other Motor Vehicles",
  G4520: "(45.20) Maintenance And Repair Of Motor Vehicles",
  G4531: "(45.31) Wholesale Trade Of Motor Vehicle Parts And Accessories",
  G4532: "(45.32) Retail Trade Of Motor Vehicle Parts And Accessories",
  G4540: "(45.40) Sale, Maintenance And Repair Of Motorcycles And Related Parts And Accessories",
  G4611: "(46.11) Agents Involved In The Sale Of Agricultural Raw Materials, Live Animals, Textile Raw Materials And Semi-Finished Goods",
  G4612: "(46.12) Agents Involved In The Sale Of Fuels, Ores, Metals And Industrial Chemicals",
  G4613: "(46.13) Agents Involved In The Sale Of Timber And Building Materials",
  G4614: "(46.14) Agents Involved In The Sale Of Machinery, Industrial Equipment, Ships And Aircraft",
  G4615: "(46.15) Agents Involved In The Sale Of Furniture, Household Goods, Hardware And Ironmongery",
  G4616: "(46.16) Agents Involved In The Sale Of Textiles, Clothing, Fur, Footwear And Leather Goods",
  G4617: "(46.17) Agents Involved In The Sale Of Food, Beverages And Tobacco",
  G4618: "(46.18) Agents Specialised In The Sale Of Other Particular Products",
  G4619: "(46.19) Agents Involved In The Sale Of A Variety Of Goods",
  G4621: "(46.21) Wholesale Of Grain, Unmanufactured Tobacco, Seeds And Animal Feeds",
  G4622: "(46.22) Wholesale Of Flowers And Plants",
  G4623: "(46.23) Wholesale Of Live Animals",
  G4624: "(46.24) Wholesale Of Hides, Skins And Leather",
  G4631: "(46.31) Wholesale Of Fruit And Vegetables",
  G4632: "(46.32) Wholesale Of Meat And Meat Products",
  G4633: "(46.33) Wholesale Of Dairy Products, Eggs And Edible Oils And Fats",
  G4634: "(46.34) Wholesale Of Beverages",
  G4635: "(46.35) Wholesale Of Tobacco Products",
  G4636: "(46.36) Wholesale Of Sugar And Chocolate And Sugar Confectionery",
  G4637: "(46.37) Wholesale Of Coffee, Tea, Cocoa And Spices",
  G4638: "(46.38) Wholesale Of Other Food, Including Fish, Crustaceans And Molluscs",
  G4639: "(46.39) Non-Specialised Wholesale Of Food, Beverages And Tobacco",
  G4641: "(46.41) Wholesale Of Textiles",
  G4642: "(46.42) Wholesale Of Clothing And Footwear",
  G4643: "(46.43) Wholesale Of Electrical Household Appliances",
  G4644: "(46.44) Wholesale Of China And Glassware And Cleaning Materials",
  G4645: "(46.45) Wholesale Of Perfume And Cosmetics",
  G4646: "(46.46) Wholesale Of Pharmaceutical Goods",
  G4647: "(46.47) Wholesale Of Furniture, Carpets And Lighting Equipment",
  G4648: "(46.48) Wholesale Of Watches And Jewellery",
  G4649: "(46.49) Wholesale Of Other Household Goods",
  G4651: "(46.51) Wholesale Of Computers, Computer Peripheral Equipment And Software",
  G4652: "(46.52) Wholesale Of Electronic And Telecommunications Equipment And Parts",
  G4661: "(46.61) Wholesale Of Agricultural Machinery, Equipment And Supplies",
  G4662: "(46.62) Wholesale Of Machine Tools",
  G4663: "(46.63) Wholesale Of Mining, Construction And Civil Engineering Machinery",
  G4664: "(46.64) Wholesale Of Machinery For The Textile Industry And Of Sewing And Knitting Machines",
  G4665: "(46.65) Wholesale Of Office Furniture",
  G4666: "(46.66) Wholesale Of Other Office Machinery And Equipment",
  G4669: "(46.69) Wholesale Of Other Machinery And Equipment",
  G4671: "(46.71) Wholesale Of Solid, Liquid And Gaseous Fuels And Related Products",
  G4672: "(46.72) Wholesale Of Metals And Metal Ores",
  G4673: "(46.73) Wholesale Of Wood, Construction Materials And Sanitary Equipment",
  G4674: "(46.74) Wholesale Of Hardware, Plumbing And Heating Equipment And Supplies",
  G4675: "(46.75) Wholesale Of Chemical Products",
  G4676: "(46.76) Wholesale Of Other Intermediate Products",
  G4677: "(46.77) Wholesale Of Waste And Scrap",
  G4690: "(46.90) Non-Specialised Wholesale Trade",
  G4711: "(47.11) Retail Sale In Non-Specialised Stores With Food, Beverages Or Tobacco Predominating",
  G4719: "(47.19) Other Retail Sale In Non-Specialised Stores",
  G4721: "(47.21) Retail Sale Of Fruit And Vegetables In Specialised Stores",
  G4722: "(47.22) Retail Sale Of Meat And Meat Products In Specialised Stores",
  G4723: "(47.23) Retail Sale Of Fish, Crustaceans And Molluscs In Specialised Stores",
  G4724: "(47.24) Retail Sale Of Bread, Cakes, Flour Confectionery And Sugar Confectionery In Specialised Stores",
  G4725: "(47.25) Retail Sale Of Beverages In Specialised Stores",
  G4726: "(47.26) Retail Sale Of Tobacco Products In Specialised Stores",
  G4729: "(47.29) Other Retail Sale Of Food In Specialised Stores",
  G4730: "(47.30) Retail Sale Of Automotive Fuel In Specialised Stores",
  G4741: "(47.41) Retail Sale Of Computers, Peripheral Units And Software In Specialised Stores",
  G4742: "(47.42) Retail Sale Of Telecommunications Equipment In Specialised Stores",
  G4743: "(47.43) Retail Sale Of Audio And Video Equipment In Specialised Stores",
  G4751: "(47.51) Retail Sale Of Textiles In Specialised Stores",
  G4752: "(47.52) Retail Sale Of Hardware, Paints And Glass In Specialised Stores",
  G4753: "(47.53) Retail Sale Of Carpets, Rugs, Wall And Floor Coverings In Specialised Stores",
  G4754: "(47.54) Retail Sale Of Electrical Household Appliances In Specialised Stores",
  G4759: "(47.59) Retail Sale Of Furniture, Lighting Equipment And Other Household Articles In Specialised Stores",
  G4761: "(47.61) Retail Sale Of Books In Specialised Stores",
  G4762: "(47.62) Retail Sale Of Newspapers And Stationery In Specialised Stores",
  G4763: "(47.63) Retail Sale Of Music And Video Recordings In Specialised Stores",
  G4764: "(47.64) Retail Sale Of Sporting Equipment In Specialised Stores",
  G4765: "(47.65) Retail Sale Of Games And Toys In Specialised Stores",
  G4771: "(47.71) Retail Sale Of Clothing In Specialised Stores",
  G4772: "(47.72) Retail Sale Of Footwear And Leather Goods In Specialised Stores",
  G4773: "(47.73) Dispensing Chemist In Specialised Stores",
  G4774: "(47.74) Retail Sale Of Medical And Orthopaedic Goods In Specialised Stores",
  G4775: "(47.75) Retail Sale Of Cosmetic And Toilet Articles In Specialised Stores",
  G4776: "(47.76) Retail Sale Of Flowers, Plants, Seeds, Fertilisers, Pet Animals And Pet Food In Specialised Stores",
  G4777: "(47.77) Retail Sale Of Watches And Jewellery In Specialised Stores",
  G4778: "(47.78) Other Retail Sale Of New Goods In Specialised Stores",
  G4779: "(47.79) Retail Sale Of Second-Hand Goods In Stores",
  G4781: "(47.81) Retail Sale Via Stalls And Markets Of Food, Beverages And Tobacco Products",
  G4782: "(47.82) Retail Sale Via Stalls And Markets Of Textiles, Clothing And Footwear",
  G4789: "(47.89) Retail Sale Via Stalls And Markets Of Other Goods",
  G4791: "(47.91) Retail Sale Via Mail Order Houses Or Via Internet",
  G4799: "(47.99) Other Retail Sale Not In Stores, Stalls Or Markets",
  H: "(H) Transporting And Storage",
  H49: "(49) Land Transport And Transport Via Pipelines",
  H50: "(50) Water Transport",
  H51: "(51) Air Transport",
  H52: "(52) Warehousing And Support Activities For Transportation",
  H53: "(53) Postal And Courier Activities",
  H491: "(49.1) Passenger Rail Transport, Interurban",
  H492: "(49.2) Freight Rail Transport",
  H493: "(49.3) Other Passenger Land Transport",
  H494: "(49.4) Freight Transport By Road And Removal Services",
  H495: "(49.5) Transport Via Pipeline",
  H501: "(50.1) Sea And Coastal Passenger Water Transport",
  H502: "(50.2) Sea And Coastal Freight Water Transport",
  H503: "(50.3) Inland Passenger Water Transport",
  H504: "(50.4) Inland Freight Water Transport",
  H511: "(51.1) Passenger Air Transport",
  H512: "(51.2) Freight Air Transport And Space Transport",
  H521: "(52.1) Warehousing And Storage",
  H522: "(52.2) Support Activities For Transportation",
  H531: "(53.1) Postal Activities Under Universal Service Obligation",
  H532: "(53.2) Other Postal And Courier Activities",
  H4910: "(49.10) Passenger Rail Transport, Interurban",
  H4920: "(49.20) Freight Rail Transport",
  H4931: "(49.31) Urban And Suburban Passenger Land Transport",
  H4932: "(49.32) Taxi Operation",
  H4939: "(49.39) Other Passenger Land Transport N.E.C.",
  H4941: "(49.41) Freight Transport By Road",
  H4942: "(49.42) Removal Services",
  H4950: "(49.50) Transport Via Pipeline",
  H5010: "(50.10) Sea And Coastal Passenger Water Transport",
  H5020: "(50.20) Sea And Coastal Freight Water Transport",
  H5030: "(50.30) Inland Passenger Water Transport",
  H5040: "(50.40) Inland Freight Water Transport",
  H5110: "(51.10) Passenger Air Transport",
  H5121: "(51.21) Freight Air Transport",
  H5122: "(51.22) Space Transport",
  H5210: "(52.10) Warehousing And Storage",
  H5221: "(52.21) Service Activities Incidental To Land Transportation",
  H5222: "(52.22) Service Activities Incidental To Water Transportation",
  H5223: "(52.23) Service Activities Incidental To Air Transportation",
  H5224: "(52.24) Cargo Handling",
  H5229: "(52.29) Other Transportation Support Activities",
  H5310: "(53.10) Postal Activities Under Universal Service Obligation",
  H5320: "(53.20) Other Postal And Courier Activities",
  I: "(I) Accommodation And Food Service Activities",
  I55: "(55) Accommodation",
  I56: "(56) Food And Beverage Service Activities",
  I551: "(55.1) Hotels And Similar Accommodation",
  I552: "(55.2) Holiday And Other Short-Stay Accommodation",
  I553: "(55.3) Camping Grounds, Recreational Vehicle Parks And Trailer Parks",
  I559: "(55.9) Other Accommodation",
  I561: "(56.1) Restaurants And Mobile Food Service Activities",
  I562: "(56.2) Event Catering And Other Food Service Activities",
  I563: "(56.3) Beverage Serving Activities",
  I5510: "(55.10) Hotels And Similar Accommodation",
  I5520: "(55.20) Holiday And Other Short-Stay Accommodation",
  I5530: "(55.30) Camping Grounds, Recreational Vehicle Parks And Trailer Parks",
  I5590: "(55.90) Other Accommodation",
  I5610: "(56.10) Restaurants And Mobile Food Service Activities",
  I5621: "(56.21) Event Catering Activities",
  I5629: "(56.29) Other Food Service Activities",
  I5630: "(56.30) Beverage Serving Activities",
  J: "(J) Information And Communication",
  J58: "(58) Publishing Activities",
  J59: "(59) Motion Picture, Video And Television Programme Production, Sound Recording And Music Publishing Activities",
  J60: "(60) Programming And Broadcasting Activities",
  J61: "(61) Telecommunications",
  J62: "(62) Computer Programming, Consultancy And Related Activities",
  J63: "(63) Information Service Activities",
  J581: "(58.1) Publishing Of Books, Periodicals And Other Publishing Activities",
  J582: "(58.2) Software Publishing",
  J591: "(59.1) Motion Picture, Video And Television Programme Activities",
  J592: "(59.2) Sound Recording And Music Publishing Activities",
  J601: "(60.1) Radio Broadcasting",
  J602: "(60.2) Television Programming And Broadcasting Activities",
  J611: "(61.1) Wired Telecommunications Activities",
  J612: "(61.2) Wireless Telecommunications Activities",
  J613: "(61.3) Satellite Telecommunications Activities",
  J619: "(61.9) Other Telecommunications Activities",
  J620: "(62.0) Computer Programming, Consultancy And Related Activities",
  J631: "(63.1) Data Processing, Hosting And Related Activities; Web Portals",
  J639: "(63.9) Other Information Service Activities",
  J5811: "(58.11) Book Publishing",
  J5812: "(58.12) Publishing Of Directories And Mailing Lists",
  J5813: "(58.13) Publishing Of Newspapers",
  J5814: "(58.14) Publishing Of Journals And Periodicals",
  J5819: "(58.19) Other Publishing Activities",
  J5821: "(58.21) Publishing Of Computer Games",
  J5829: "(58.29) Other Software Publishing",
  J5911: "(59.11) Motion Picture, Video And Television Programme Production Activities",
  J5912: "(59.12) Motion Picture, Video And Television Programme Post-Production Activities",
  J5913: "(59.13) Motion Picture, Video And Television Programme Distribution Activities",
  J5914: "(59.14) Motion Picture Projection Activities",
  J5920: "(59.20) Sound Recording And Music Publishing Activities",
  J6010: "(60.10) Radio Broadcasting",
  J6020: "(60.20) Television Programming And Broadcasting Activities",
  J6110: "(61.10) Wired Telecommunications Activities",
  J6120: "(61.20) Wireless Telecommunications Activities",
  J6130: "(61.30) Satellite Telecommunications Activities",
  J6190: "(61.90) Other Telecommunications Activities",
  J6201: "(62.01) Computer Programming Activities",
  J6202: "(62.02) Computer Consultancy Activities",
  J6203: "(62.03) Computer Facilities Management Activities",
  J6209: "(62.09) Other Information Technology And Computer Service Activities",
  J6311: "(63.11) Data Processing, Hosting And Related Activities",
  J6312: "(63.12) Web Portals",
  J6391: "(63.91) News Agency Activities",
  J6399: "(63.99) Other Information Service Activities N.E.C.",
  K: "(K) Financial And Insurance Activities",
  K64: "(64) Financial Service Activities, Except Insurance And Pension Funding",
  K65: "(65) Insurance, Reinsurance And Pension Funding, Except Compulsory Social Security",
  K66: "(66) Activities Auxiliary To Financial Services And Insurance Activities",
  K641: "(64.1) Monetary Intermediation",
  K642: "(64.2) Activities Of Holding Companies",
  K643: "(64.3) Trusts, Funds And Similar Financial Entities",
  K649: "(64.9) Other Financial Service Activities, Except Insurance And Pension Funding",
  K651: "(65.1) Insurance",
  K652: "(65.2) Reinsurance",
  K653: "(65.3) Pension Funding",
  K661: "(66.1) Activities Auxiliary To Financial Services, Except Insurance And Pension Funding",
  K662: "(66.2) Activities Auxiliary To Insurance And Pension Funding",
  K663: "(66.3) Fund Management Activities",
  K6411: "(64.11) Central Banking",
  K6419: "(64.19) Other Monetary Intermediation",
  K6420: "(64.20) Activities Of Holding Companies",
  K6430: "(64.30) Trusts, Funds And Similar Financial Entities",
  K6491: "(64.91) Financial Leasing",
  K6492: "(64.92) Other Credit Granting",
  K6499: "(64.99) Other Financial Service Activities, Except Insurance And Pension Funding N.E.C.",
  K6511: "(65.11) Life Insurance",
  K6512: "(65.12) Non-Life Insurance",
  K6520: "(65.20) Reinsurance",
  K6530: "(65.30) Pension Funding",
  K6611: "(66.11) Administration Of Financial Markets",
  K6612: "(66.12) Security And Commodity Contracts Brokerage",
  K6619: "(66.19) Other Activities Auxiliary To Financial Services, Except Insurance And Pension Funding",
  K6621: "(66.21) Risk And Damage Evaluation",
  K6622: "(66.22) Activities Of Insurance Agents And Brokers",
  K6629: "(66.29) Other Activities Auxiliary To Insurance And Pension Funding",
  K6630: "(66.30) Fund Management Activities",
  L: "(L) Real Estate Activities",
  L68: "(68) Real Estate Activities",
  L681: "(68.1) Buying And Selling Of Own Real Estate",
  L682: "(68.2) Renting And Operating Of Own Or Leased Real Estate",
  L683: "(68.3) Real Estate Activities On A Fee Or Contract Basis",
  L6810: "(68.10) Buying And Selling Of Own Real Estate",
  L6820: "(68.20) Renting And Operating Of Own Or Leased Real Estate",
  L6831: "(68.31) Real Estate Agencies",
  L6832: "(68.32) Management Of Real Estate On A Fee Or Contract Basis",
  M: "(M) Professional, Scientific And Technical Activities",
  M69: "(69) Legal And Accounting Activities",
  M70: "(70) Activities Of Head Offices; Management Consultancy Activities",
  M71: "(71) Architectural And Engineering Activities; Technical Testing And Analysis",
  M72: "(72) Scientific Research And Development",
  M73: "(73) Advertising And Market Research",
  M74: "(74) Other Professional, Scientific And Technical Activities",
  M75: "(75) Veterinary Activities",
  M691: "(69.1) Legal Activities",
  M692: "(69.2) Accounting, Bookkeeping And Auditing Activities; Tax Consultancy",
  M701: "(70.1) Activities Of Head Offices",
  M702: "(70.2) Management Consultancy Activities",
  M711: "(71.1) Architectural And Engineering Activities And Related Technical Consultancy",
  M712: "(71.2) Technical Testing And Analysis",
  M721: "(72.1) Research And Experimental Development On Natural Sciences And Engineering",
  M722: "(72.2) Research And Experimental Development On Social Sciences And Humanities",
  M731: "(73.1) Advertising",
  M732: "(73.2) Market Research And Public Opinion Polling",
  M741: "(74.1) Specialised Design Activities",
  M742: "(74.2) Photographic Activities",
  M743: "(74.3) Translation And Interpretation Activities",
  M749: "(74.9) Other Professional, Scientific And Technical Activities N.E.C.",
  M750: "(75.0) Veterinary Activities",
  M6910: "(69.10) Legal Activities",
  M6920: "(69.20) Accounting, Bookkeeping And Auditing Activities; Tax Consultancy",
  M7010: "(70.10) Activities Of Head Offices",
  M7021: "(70.21) Public Relations And Communication Activities",
  M7022: "(70.22) Business And Other Management Consultancy Activities",
  M7111: "(71.11) Architectural Activities",
  M7112: "(71.12) Engineering Activities And Related Technical Consultancy",
  M7120: "(71.20) Technical Testing And Analysis",
  M7211: "(72.11) Research And Experimental Development On Biotechnology",
  M7219: "(72.19) Other Research And Experimental Development On Natural Sciences And Engineering",
  M7220: "(72.20) Research And Experimental Development On Social Sciences And Humanities",
  M7311: "(73.11) Advertising Agencies",
  M7312: "(73.12) Media Representation",
  M7320: "(73.20) Market Research And Public Opinion Polling",
  M7410: "(74.10) Specialised Design Activities",
  M7420: "(74.20) Photographic Activities",
  M7430: "(74.30) Translation And Interpretation Activities",
  M7490: "(74.90) Other Professional, Scientific And Technical Activities N.E.C.",
  M7500: "(75.00) Veterinary Activities",
  N: "(N) Administrative And Support Service Activities",
  N77: "(77) Rental And Leasing Activities",
  N78: "(78) Employment Activities",
  N79: "(79) Travel Agency, Tour Operator And Other Reservation Service And Related Activities",
  N80: "(80) Security And Investigation Activities",
  N81: "(81) Services To Buildings And Landscape Activities",
  N82: "(82) Office Administrative, Office Support And Other Business Support Activities",
  N771: "(77.1) Renting And Leasing Of Motor Vehicles",
  N772: "(77.2) Renting And Leasing Of Personal And Household Goods",
  N773: "(77.3) Renting And Leasing Of Other Machinery, Equipment And Tangible Goods",
  N774: "(77.4) Leasing Of Intellectual Property And Similar Products, Except Copyrighted Works",
  N781: "(78.1) Activities Of Employment Placement Agencies",
  N782: "(78.2) Temporary Employment Agency Activities",
  N783: "(78.3) Other Human Resources Provision",
  N791: "(79.1) Travel Agency And Tour Operator Activities",
  N799: "(79.9) Other Reservation Service And Related Activities",
  N801: "(80.1) Private Security Activities",
  N802: "(80.2) Security Systems Service Activities",
  N803: "(80.3) Investigation Activities",
  N811: "(81.1) Combined Facilities Support Activities",
  N812: "(81.2) Cleaning Activities",
  N813: "(81.3) Landscape Service Activities",
  N821: "(82.1) Office Administrative And Support Activities",
  N822: "(82.2) Activities Of Call Centres",
  N823: "(82.3) Organisation Of Conventions And Trade Shows",
  N829: "(82.9) Business Support Service Activities N.E.C.",
  N7711: "(77.11) Renting And Leasing Of Cars And Light Motor Vehicles",
  N7712: "(77.12) Renting And Leasing Of Trucks",
  N7721: "(77.21) Renting And Leasing Of Recreational And Sports Goods",
  N7722: "(77.22) Renting Of Video Tapes And Disks",
  N7729: "(77.29) Renting And Leasing Of Other Personal And Household Goods",
  N7731: "(77.31) Renting And Leasing Of Agricultural Machinery And Equipment",
  N7732: "(77.32) Renting And Leasing Of Construction And Civil Engineering Machinery And Equipment",
  N7733: "(77.33) Renting And Leasing Of Office Machinery And Equipment (Including Computers)",
  N7734: "(77.34) Renting And Leasing Of Water Transport Equipment",
  N7735: "(77.35) Renting And Leasing Of Air Transport Equipment",
  N7739: "(77.39) Renting And Leasing Of Other Machinery, Equipment And Tangible Goods N.E.C.",
  N7740: "(77.40) Leasing Of Intellectual Property And Similar Products, Except Copyrighted Works",
  N7810: "(78.10) Activities Of Employment Placement Agencies",
  N7820: "(78.20) Temporary Employment Agency Activities",
  N7830: "(78.30) Other Human Resources Provision",
  N7911: "(79.11) Travel Agency Activities",
  N7912: "(79.12) Tour Operator Activities",
  N7990: "(79.90) Other Reservation Service And Related Activities",
  N8010: "(80.10) Private Security Activities",
  N8020: "(80.20) Security Systems Service Activities",
  N8030: "(80.30) Investigation Activities",
  N8110: "(81.10) Combined Facilities Support Activities",
  N8121: "(81.21) General Cleaning Of Buildings",
  N8122: "(81.22) Other Building And Industrial Cleaning Activities",
  N8129: "(81.29) Other Cleaning Activities",
  N8130: "(81.30) Landscape Service Activities",
  N8211: "(82.11) Combined Office Administrative Service Activities",
  N8219: "(82.19) Photocopying, Document Preparation And Other Specialised Office Support Activities",
  N8220: "(82.20) Activities Of Call Centres",
  N8230: "(82.30) Organisation Of Conventions And Trade Shows",
  N8291: "(82.91) Activities Of Collection Agencies And Credit Bureaus",
  N8292: "(82.92) Packaging Activities",
  N8299: "(82.99) Other Business Support Service Activities N.E.C.",
  None: "None",
  O: "(O) Public Administration And Defence; Compulsory Social Security",
  O84: "(84) Public Administration And Defence; Compulsory Social Security",
  O841: "(84.1) Administration Of The State And The Economic And Social Policy Of The Community",
  O842: "(84.2) Provision Of Services To The Community As A Whole",
  O843: "(84.3) Compulsory Social Security Activities",
  O8411: "(84.11) General Public Administration Activities",
  O8412: "(84.12) Regulation Of The Activities Of Providing Health Care, Education, Cultural Services And Other Social Services, Excluding Social Security",
  O8413: "(84.13) Regulation Of And Contribution To More Efficient Operation Of Businesses",
  O8421: "(84.21) Foreign Affairs",
  O8422: "(84.22) Defence Activities",
  O8423: "(84.23) Justice And Judicial Activities",
  O8424: "(84.24) Public Order And Safety Activities",
  O8425: "(84.25) Fire Service Activities",
  O8430: "(84.30) Compulsory Social Security Activities",
  P: "(P) Education",
  P85: "(85) Education",
  P851: "(85.1) Pre-Primary Education",
  P852: "(85.2) Primary Education",
  P853: "(85.3) Secondary Education",
  P854: "(85.4) Higher Education",
  P855: "(85.5) Other Education",
  P856: "(85.6) Educational Support Activities",
  P8510: "(85.10) Pre-Primary Education",
  P8520: "(85.20) Primary Education",
  P8531: "(85.31) General Secondary Education",
  P8532: "(85.32) Technical And Vocational Secondary Education",
  P8541: "(85.41) Post-Secondary Non-Tertiary Education",
  P8542: "(85.42) Tertiary Education",
  P8551: "(85.51) Sports And Recreation Education",
  P8552: "(85.52) Cultural Education",
  P8553: "(85.53) Driving School Activities",
  P8559: "(85.59) Other Education N.E.C.",
  P8560: "(85.60) Educational Support Activities",
  Q: "(Q) Human Health And Social Work Activities",
  Q86: "(86) Human Health Activities",
  Q87: "(87) Residential Care Activities",
  Q88: "(88) Social Work Activities Without Accommodation",
  Q861: "(86.1) Hospital Activities",
  Q862: "(86.2) Medical And Dental Practice Activities",
  Q869: "(86.9) Other Human Health Activities",
  Q871: "(87.1) Residential Nursing Care Activities",
  Q872: "(87.2) Residential Care Activities For Mental Retardation, Mental Health And Substance Abuse",
  Q873: "(87.3) Residential Care Activities For The Elderly And Disabled",
  Q879: "(87.9) Other Residential Care Activities",
  Q881: "(88.1) Social Work Activities Without Accommodation For The Elderly And Disabled",
  Q889: "(88.9) Other Social Work Activities Without Accommodation",
  Q8610: "(86.10) Hospital Activities",
  Q8621: "(86.21) General Medical Practice Activities",
  Q8622: "(86.22) Specialist Medical Practice Activities",
  Q8623: "(86.23) Dental Practice Activities",
  Q8690: "(86.90) Other Human Health Activities",
  Q8710: "(87.10) Residential Nursing Care Activities",
  Q8720: "(87.20) Residential Care Activities For Mental Retardation, Mental Health And Substance Abuse",
  Q8730: "(87.30) Residential Care Activities For The Elderly And Disabled",
  Q8790: "(87.90) Other Residential Care Activities",
  Q8810: "(88.10) Social Work Activities Without Accommodation For The Elderly And Disabled",
  Q8891: "(88.91) Child Day-Care Activities",
  Q8899: "(88.99) Other Social Work Activities Without Accommodation N.E.C.",
  R: "(R) Arts, Entertainment And Recreation",
  R90: "(90) Creative, Arts And Entertainment Activities",
  R91: "(91) Libraries, Archives, Museums And Other Cultural Activities",
  R92: "(92) Gambling And Betting Activities",
  R93: "(93) Sports Activities And Amusement And Recreation Activities",
  R900: "(90.0) Creative, Arts And Entertainment Activities",
  R910: "(91.0) Libraries, Archives, Museums And Other Cultural Activities",
  R920: "(92.0) Gambling And Betting Activities",
  R931: "(93.1) Sports Activities",
  R932: "(93.2) Amusement And Recreation Activities",
  R9001: "(90.01) Performing Arts",
  R9002: "(90.02) Support Activities To Performing Arts",
  R9003: "(90.03) Artistic Creation",
  R9004: "(90.04) Operation Of Arts Facilities",
  R9101: "(91.01) Library And Archives Activities",
  R9102: "(91.02) Museums Activities",
  R9103: "(91.03) Operation Of Historical Sites And Buildings And Similar Visitor Attractions",
  R9104: "(91.04) Botanical And Zoological Gardens And Nature Reserves Activities",
  R9200: "(92.00) Gambling And Betting Activities",
  R9311: "(93.11) Operation Of Sports Facilities",
  R9312: "(93.12) Activities Of Sport Clubs",
  R9313: "(93.13) Fitness Facilities",
  R9319: "(93.19) Other Sports Activities",
  R9321: "(93.21) Activities Of Amusement Parks And Theme Parks",
  R9329: "(93.29) Other Amusement And Recreation Activities",
  S: "(S) Other Services Activities",
  S94: "(94) Activities Of Membership Organisations",
  S95: "(95) Repair Of Computers And Personal And Household Goods",
  S96: "(96) Other Personal Service Activities",
  S941: "(94.1) Activities Of Business, Employers And Professional Membership Organisations",
  S942: "(94.2) Activities Of Trade Unions",
  S949: "(94.9) Activities Of Other Membership Organisations",
  S951: "(95.1) Repair Of Computers And Communication Equipment",
  S952: "(95.2) Repair Of Personal And Household Goods",
  S960: "(96.0) Other Personal Service Activities",
  S9411: "(94.11) Activities Of Business And Employers Membership Organisations",
  S9412: "(94.12) Activities Of Professional Membership Organisations",
  S9420: "(94.20) Activities Of Trade Unions",
  S9491: "(94.91) Activities Of Religious Organisations",
  S9492: "(94.92) Activities Of Political Organisations",
  S9499: "(94.99) Activities Of Other Membership Organisations N.E.C.",
  S9511: "(95.11) Repair Of Computers And Peripheral Equipment",
  S9512: "(95.12) Repair Of Communication Equipment",
  S9521: "(95.21) Repair Of Consumer Electronics",
  S9522: "(95.22) Repair Of Household Appliances And Home And Garden Equipment",
  S9523: "(95.23) Repair Of Footwear And Leather Goods",
  S9524: "(95.24) Repair Of Furniture And Home Furnishings",
  S9525: "(95.25) Repair Of Watches, Clocks And Jewellery",
  S9529: "(95.29) Repair Of Other Personal And Household Goods",
  S9601: "(96.01) Washing And (Dry-)Cleaning Of Textile And Fur Products",
  S9602: "(96.02) Hairdressing And Other Beauty Treatment",
  S9603: "(96.03) Funeral And Related Activities",
  S9604: "(96.04) Physical Well-Being Activities",
  S9609: "(96.09) Other Personal Service Activities N.E.C.",
  T: "(T) Activities Of Households As Employers; Undifferentiated Goods - And Services - Producing Activities Of Households For Own Use",
  T97: "(97) Activities Of Households As Employers Of Domestic Personnel",
  T98: "(98) Undifferentiated Goods- And Services-Producing Activities Of Private Households For Own Use",
  T970: "(97.0) Activities Of Households As Employers Of Domestic Personnel",
  T981: "(98.1) Undifferentiated Goods-Producing Activities Of Private Households For Own Use",
  T982: "(98.2) Undifferentiated Service-Producing Activities Of Private Households For Own Use",
  T9700: "(97.00) Activities Of Households As Employers Of Domestic Personnel",
  T9810: "(98.10) Undifferentiated Goods-Producing Activities Of Private Households For Own Use",
  T9820: "(98.20) Undifferentiated Service-Producing Activities Of Private Households For Own Use",
  U: "(U) Activities Of Extraterritorial Organisations And Bodies",
  U99: "(99) Activities Of Extraterritorial Organisations And Bodies",
  U990: "(99.0) Activities Of Extraterritorial Organisations And Bodies",
  U9900: "(99.00) Activities Of Extraterritorial Organisations And Bodies"
};

export const NavTransactionTypeDescriptions = {
  CreateRedeemAmount: null,
  CreateRedeemUnits: null,
  FxSpot: null,
  ManagementFee: null,
  Transfer: null
};

export const NonTradingDayFillMethodEnumDescriptions = {
  NIL_VALUE: null,
  PREVIOUS_VALUE: null
};

export const NonTradingDayFillOptionEnumDescriptions = {
  ACTIVE_DAYS_ONLY: null,
  ALL_CALENDAR_DAYS: null,
  NON_TRADING_WEEKDAYS: null
};

export const NoteAssetEnumDescriptions = {
  Admin: null,
  Base: null,
  Esg: null
};

export const NoteStatusEnumDescriptions = {
  Active: null,
  Deleted: null
};

export const OverrideOptionEnumDescriptions = {
  OVERRIDE_OPTION_CLOSE: null,
  OVERRIDE_OPTION_GPA: null
};

export const PartyAccountTypeDescriptions = {
  Physical: null,
  PortfolioInstrument: null,
  Sum: null
};

export const PartyExternalAccountTypeDescriptions = {
  CashAccount: null,
  ClearingAccount: null,
  Equity: null,
  FundAccount: null,
  Gmra: null,
  Gmsla: null,
  Isda: null,
  OmnibusClearingAccount: null,
  SecuritiesAccount: null,
  ShareRegistry: null
};

export const PartyStandardSettlementInstructionTypeDescriptions = {
  Equity: null,
  FixedIncome: null
};

export const PartyStatusEnumDescriptions = {
  Confirmed: null,
  Deleted: null,
  Inactive: null
};

export const PartyTypeDescriptions = {
  Broker: null,
  ClearingBroker: null,
  ClearingHouse: null,
  Client: null,
  CreditRatingAgency: null,
  Custodian: null,
  EsgDataProvider: null,
  Fund: null,
  Issuer: null,
  Mandate: "Mandate denotes Discretionary Mandate",
  MarketDataProvider: null,
  PlaceOfSettlement: null,
  PrivateClient: null
};

export const PeriodicityAdjustmentEnumDescriptions = {
  ACTUAL: null,
  CALENDAR: null,
  FISCAL: null
};

export const PeriodicitySelectionEnumDescriptions = {
  DAILY: null,
  MONTHLY: null,
  QUARTERLY: null,
  SEMI_ANNUALLY: null,
  WEEKLY: null,
  YEARLY: null
};

export const PermissionAssetEnumDescriptions = {
  Accounting: null,
  Admin: null,
  Agreement: null,
  Attachment: null,
  BackOffice: null,
  BankAccountTransaction: null,
  Base: null,
  Bloomberg: null,
  Collateral: null,
  ConfirmAccounting: null,
  Customer: null,
  Esg: null,
  Instrument: null,
  Job: null,
  Limit: null,
  Nav: null,
  Note: null,
  Operations: "Team asset",
  Party: null,
  Performance: null,
  Playground: null,
  PortfolioManager: "PortfolioManager used in frontend",
  PortfolioManagers: "Team asset",
  Position: null,
  Price: null,
  Property: null,
  Quote: null,
  Reconciliation: null,
  Report: null,
  Sustainalytics: null,
  Ticket: null,
  TimeSeries: null,
  Transaction: null,
  User: null,
  Valuation: null
};

export const PositionSelectorTypeDescriptions = {
  AccountId: null,
  ClientId: null,
  ExternalAccountId: null
};

export const PositionTypeEnumDescriptions = {
  CreateRedeem: null,
  OffBalance: null,
  OnBalance: null
};

export const PriceTypeDescriptions = {
  AccruedInterest: null,
  CleanPrice: null,
  Price: null,
  PriceEstimate: null
};

export const PriceTypeEnumDescriptions = {
  Price: null,
  PriceMapping: null,
  Valuation: null,
  ValuationMapping: null
};

export const PricingOptionEnumDescriptions = {
  PRICING_OPTION_PRICE: null,
  PRICING_OPTION_YIELD: null
};

export const ProductInvolvementIndicatorDescriptions = {
  AdultEntertainment: null,
  AlcoholicBeverages: null,
  ControversialWeapons: null,
  Gambling: null,
  MilitaryContracting: null,
  OilGas: null,
  OilSands: null,
  SmallArms: null,
  ThermalCoal: null,
  TobaccoProducts: null
};

export const QuantLibTimeUnitEnumDescriptions = {
  Days: null,
  Hours: null,
  Microseconds: null,
  Milliseconds: null,
  Minutes: null,
  Months: null,
  Seconds: null,
  Weeks: null,
  Years: null
};

export const QuoteTypeEnumDescriptions = {
  Clean: null,
  PctOfNominal: null,
  PerUnit: null,
  Pips4: null,
  Spread: null,
  Yield: null
};

export const RangeEnumDescriptions = {
  R0To5: null,
  R5To10: null,
  R10To25: null,
  R25To50: null,
  R50To100: null
};

export const ReconcileTypeEnumDescriptions = {
  ExposureCollateral: null,
  NettingSet: null,
  NettingSetBaseCurrency: null
};

export const RefDataMachineDescriptions = {
  Daka: null,
  Office: null
};

export const ReferenceFieldsEnumDescriptions = {
  CDS_SPREAD_MID_MODIFIED_DURATION: null,
  CFI_CODE: null,
  CNTRY_OF_DOMICILE: null,
  COMPANY_IS_PRIVATE: null,
  CPN_TYP: null,
  CRNCY: null,
  DAYS_TO_SETTLE: null,
  DISC_MRGN_MID: null,
  DUR_ADJ_MID: null,
  ECBC_COVERED_BD_LABEL_INDICATOR: null,
  EXCH_CODE: null,
  GB_MANAGEMENT_PROCEEDS: null,
  GICS_SUB_INDUSTRY: null,
  ID_BB_COMPANY: null,
  ID_EXCH_SYMBOL: null,
  ID_ISIN: null,
  INDUSTRY_SUBGROUP_NUM: null,
  ISSUER: null,
  ISSUER_INDUSTRY: null,
  LAST_UPDATE_BID_RT: null,
  LAST_UPDATE_DT_EXCH_TZ: null,
  LEGAL_ENTITY_IDENTIFIER: null,
  MARKET_SECTOR_DES: null,
  PAR_AMT: null,
  PAYMENT_RANK: null,
  PX_ASK: null,
  PX_BID: null,
  PX_CLOSE: null,
  PX_LAST: null,
  RTG_FITCH: null,
  RTG_FITCH_LONG: null,
  RTG_FITCH_LT_ISSUER_DEFAULT: null,
  RTG_MDY_ISSUER: null,
  RTG_MOODY: null,
  RTG_MOODY_LONG_TERM: null,
  RTG_SP: null,
  RTG_SP_LONG: null,
  RTG_SP_LT_LC_ISSUER_CREDIT: null,
  SECURITY_NAME: null,
  SEC_ID_WITH_YK_PARSEKYABLE_RT: null,
  SERIES: null,
  TICKER: null,
  TIME: null,
  YAS_ISPREAD_TO_GOVT: null,
  YLD_YTM_MID: null
};

export const ReportStatusEnumDescriptions = {
  Active: null,
  Deleted: null,
  Locked: "When locked, not possible to edit"
};

export const RoleTypeEnumDescriptions = {
  Backend: null,
  Frontend: null
};

export const ScienceBasedTargetInitiativeEnumDescriptions = {
  Committed: null,
  NoTarget: null,
  TargetSet: null
};

export const ScreenTypeDescriptions = {
  Issuer: null,
  IssuerProgram: null
};

export const SourceTypeDescriptions = {
  BloombergFit: null,
  Coacs: null,
  Emsx: null,
  Fxgo: null,
  Internal: null,
  MarkitWire: null,
  Tradeweb: null
};

export const SsiTradeTypeDescriptions = {
  Collateral: null,
  Security: null
};

export const StandardScreenNormDescriptions = {
  BusinessEthics: null,
  Environment: null,
  HumanRights: null,
  LabourRights: null,
  None: null
};

export const StandardScreenPrincipleDescriptions = {
  None: null,
  Principle1: null,
  Principle2: null,
  Principle3: null,
  Principle4: null,
  Principle5: null,
  Principle6: null,
  Principle7: null,
  Principle8: null,
  Principle9: null,
  Principle10: null
};

export const StandardScreenStatusDescriptions = {
  Compliant: null,
  NonCompliant: null,
  Watchlist: null
};

export const StatusEnumDescriptions = {
  Active: null,
  Deleted: null
};

export const SustainableDevelopmentGoalDescriptions = {
  Goal1_NoPoverty: "No Poverty",
  Goal2_ZeroHunger: "Zero Hunger",
  Goal3_GoodHealthAndWellBeing: "Good Health and Well-being",
  Goal4_QualityEducation: "Quality Education",
  Goal5_GenderEquality: "Gender Equality",
  Goal6_CleanWaterAndSanitation: "Clean Water and Sanitation",
  Goal7_AffordableAndCleanEnergy: "Affordable and Clean Energy",
  Goal8_DecentWorkAndEconomicGrowth: "Decent Work and Economic Growth",
  Goal9_IndustryInnovationAndInfrastructure: "Industry, Innovation and Infrastructure",
  Goal10_ReducedInequality: "Reduced Inequality",
  Goal11_SustainableCitiesAndCommunities: "Sustainable Cities and Communities",
  Goal12_ResponsibleConsumptionAndProduction: "Responsible Consumption and Production",
  Goal13_ClimateAction: "CLimate Action",
  Goal14_LifeBelowWater: "Life Below Water",
  Goal15_LifeOnLand: "Life on Land",
  Goal16_PeaceJusticeAndStrongInstitutions: "Peace, Justice and Strong Institutions",
  Goal17_PartnershipsToAchieveTheGoal: "Partnerships to achieve the Goal"
};

export const SwedishFinancialAuthorityCategoryEnumDescriptions = {
  Banks: null,
  Emu: null,
  HousingInstitutes: null,
  MunicipalAdministration: null,
  OfWhichThePensionsAuthority: null,
  OtherEu: null,
  OtherForeign: null,
  OtherSwedishFinancialInstitutions: null,
  PensionInstitutes: null,
  SocialSecurityFunds: null,
  SumForeign: null,
  SwedishFinancialCompanies: null,
  SwedishHouseholds: null,
  SwedishInsuranceCompanies: null,
  Unknown: null
};

export const SwiftStatusEnumDescriptions = {
  Confirmed: null,
  Deleted: null,
  Pending: null,
  Preliminary: null,
  Settled: "Messages of messageType 566 gets status settled when matched",
  UpdatedPrevious: "Used for messageType 566 when original swift of messageType 564 has been updated and swift of messageType is insignificant",
  Waiting: "Used for messages with corporateActionEvent: DRIP/DVOP, these are received earlier (not received two days after ex-date like other corporateActionEvents)"
};

export const TAccountGroup1EnumDescriptions = {
  Assets: null,
  Equity: null,
  Liabilities: null
};

export const TAccountGroup2EnumDescriptions = {
  A: null,
  B: null,
  C: null,
  D: null,
  DividendPaid: null,
  E: null,
  F: null,
  G: null,
  H: null,
  I: null,
  J: null,
  K: null,
  L: null,
  M: null,
  N: null,
  O: null,
  P: null,
  ProfitLossCurrentYear: null,
  Q: null,
  R: null,
  RetainedEarnings: null,
  S: null,
  ShareCapital: null,
  T: null
};

export const TAccountGroup3EnumDescriptions = {
  A: null,
  B: null,
  C: null,
  Compensation: null,
  Contribution: null,
  D: null,
  DividendPaid: null,
  E: null,
  Expenses: null,
  F: null,
  G: null,
  H: null,
  I: null,
  J: null,
  K: null,
  L: null,
  M: null,
  N: null,
  O: null,
  P: null,
  Q: null,
  R: null,
  RetainedEarnings: null,
  Revenue: null,
  S: null,
  ShareCapital: null,
  T: null,
  Tax: null
};

export const TAccountGroup4EnumDescriptions = {
  A: null,
  B: null,
  C: null,
  D: null,
  E: null,
  F: null,
  G: null,
  H: null,
  I: null,
  J: null,
  K: null,
  L: null,
  M: null,
  ManagementFees: null,
  N: null,
  None: null,
  O: null,
  P: null,
  Q: null,
  R: null,
  S: null,
  T: null
};

export const TAccountGroup5EnumDescriptions = {
  A: null,
  B: null,
  C: null,
  D: null,
  E: null,
  F: null,
  G: null,
  H: null,
  I: null,
  J: null,
  K: null,
  L: null,
  M: null,
  N: null,
  None: null,
  O: null,
  P: null,
  Q: null,
  R: null,
  S: null,
  T: null
};

export const TAccountMappingSelectorKeyEnumDescriptions = {
  Equity: null,
  OpeningBalance: null,
  Rounding: null
};

export const TAccountTypeEnumDescriptions = {
  Asset: null,
  Income: null,
  Liability: null
};

export const TeamPermissionAssetEnumDescriptions = {
  Operations: "Used for Operations team role. One role/team.",
  PortfolioManagers: "Used for PortfolioManagers team role. One role/team."
};

export const TicketStatusEnumDescriptions = {
  Closed: null,
  Open: null
};

export const TicketTypeEnumDescriptions = {
  AccountingRunStatusError: null,
  CorporateActionError: null,
  Error: null,
  ExposureCollateralError: null,
  ExternalDataError: null,
  FundUnitReconciliationError: null,
  MarginCall: null,
  MatchBrokerTransactionError: null,
  PositionReconciliationError: null
};

export const TimeSeriesStatusDescriptions = {
  Active: null,
  Deleted: null
};

export const TimezoneEnumDescriptions = {
  AmericaChicago: null,
  AmericaNew_York: null,
  AsiaHong_Kong: null,
  AsiaTokyo: null,
  EuropeAmsterdam: null,
  EuropeBerlin: null,
  EuropeBrussels: null,
  EuropeCopenhagen: null,
  EuropeHelsinki: null,
  EuropeLondon: null,
  EuropeLuxembourg: null,
  EuropeOslo: null,
  EuropeParis: null,
  EuropeStockholm: null,
  EuropeZurich: null
};

export const TradeItemTypeDescriptions = {
  AccruedInterest: null,
  Commission: null,
  StampDuty: null
};

export const TransactionItemPerformanceTypeDescriptions = {
  Normal: "Normal is used for all transaction items that have nothing to do with portfolio swaps",
  Portfolio: "Portfolio is used for all transaction items that involves the portfolio swaps directly",
  PortfolioItem: "PortfolioItem is used for all transaction items that involves the constituent of portfolio swaps. They are interlinked to the portfolio swaps by portfolioInstrumentId of an transaction item"
};

export const TransactionItemTypeDescriptions = {
  AccruedInterest: "AccruedInterest in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  Collateral: "Collateral is an off-balance position which is not position building in terms of performance or risk calculations but included inventory position for the purpose of reconciling a Securities ExternalAccount. Collateral is not included in accounting or performance calculations.",
  CollateralPledge: "CollateralPledge is an off-balance position which is not position building in terms of performance or risk calculations but included in netting-sets such as ISDA CSA or GMSLA. CollateralPledge is not included in accounting or performance calculations.",
  CollateralPledgeInitialMargin: "Same as CollateralPledge, need this to separate if CollateralPledge is initial margin or variation margin",
  Commission: "Commission in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  CreateRedeem: "CreateRedeem denoted creations and redemptions of fund units. It is not position building and not included in performance calculations. It belongs to the Balance accounting model.",
  CreateRedeemAdjustmentAmount: "A third CreateRedeem type. Only books amount. Doesn't set quantity and amount in the NAV process.",
  CreateRedeemAdjustmentShares: "Do we need this one? Yes! Because NAV trade initialization and population differs from standard CreateRedeem.",
  CustodyFee: "CustodyFee in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  Dividend: "Dividend in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  DividendPaid: "DividendPaid denotes dividend paid from fund classes. It is not position building and not included in performance calculations.",
  Fee: "Fee in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  ForeignTax: "ForeignTax in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  Interest: "Interest in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  ManagementCost: "ManagementCost in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  ManagementFee: "ManagementFee in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  PayableReceivable: "PayableReceivable denotes balance sheet items other than current accounts or financial instruments such as margins or loans and deposits under GMSLA or GMRA agreements. PayableReceivable belong to the Asset performance model and the Balance accounting model.",
  Rebate: "Rebate is a type of fee , used to be able to differentiate between dividends and fundrebates. We book fundrebates with quantity as stocktrades with price 0 and cash fundrebates as rebates.",
  Security: "Security is position building and belongs to the Inventory accounting model and the Asset performance model.",
  SecurityLoan: "Similar to CollateralPledge but posting collateral instead of receiving.",
  SettlementAmount: "SettlementAmounts is payments on current accounts and is position building belongs to the Asset performance and the Balance accounting model.",
  StampDuty: "StampDuty in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  Tax: "Tax in not position building and belongs to the Fee accounting model and the CashFlow performance model.",
  TaxRestitution: "TaxRestitution in not position building and belongs to the Fee accounting model and the CashFlow performance model."
};

export const TransactionStatusDescriptions = {
  Confirmed: "Confirmed status is set when trade is confirmed with counterparty by the Operations unit.",
  Deleted: "Deleted denotes a transaction that has been deleted but still exists in the database.",
  History: "History is used when setting up a new client with historic transactions.",
  Instructed: "Instructed denotes when a transaction has been instructed with the Custodian.",
  Internal: "Internal is used for transactions created only for internal use.",
  Pending: "Pending is a transaction entered by an external system or user that has not been confirmed by the Operations unit.",
  Preliminary: "Preliminary is a status where one or more parameters is not yet confirmed and will be treated as such in accounting terms and will not be given a number until it reaches at least Confirmed status.",
  Settled: "Settled denotes when a security has been delivered in custody account or cash has been settled on a current account."
};

export const TransactionTypeDescriptions = {
  BondTrade: null,
  Collateral: null,
  CreateRedeem: null,
  DerivativeTrade: null,
  Dividend: null,
  Fee: null,
  FundTrade: null,
  FxOutright: null,
  FxSpot: null,
  Insert: null,
  Interest: null,
  IrSwap: null,
  StockTrade: null,
  Trade: null,
  Transfer: null
};

export const TransferAgentOrderEnumDescriptions = {
  BUY: null,
  SELL: null
};

export const TransferAgentOrderSubTypeEnumDescriptions = {
  ACCOUNT_SWITCH: null,
  NORMAL: null
};

export const TrueFalseNoneDescriptions = {
  False: null,
  None: null,
  True: null
};

export const UseOfProceedDescriptions = {
  AccessEssentialServices: "Access to essential services",
  AffordableHousing: "Affordable housing",
  AffordableInfraStructure: "Affordable basic infrastructure",
  CleanTransportation: "Clean Transportation",
  ClimateChangeAdaption: "climate change adaptation",
  EcoEfficientProducts: "eco-efficient and/or circular economy adapted products,",
  EmploymentGeneration: "Employment generation (including SME financing and Microfinance)",
  EnergyEfficiency: "Energy efficiency",
  EnvironmentallySustainableManagement: "Environmentally sustainable management of living natural resources and land use",
  GreenBuldings: "Green Buildings",
  Other: null,
  PollutionPreventionAndControl: "Pollution prevention and control",
  RenewableEnergy: "Renewable energy",
  SocioEconomicAdvancement: "Socioeconomic advancement and empowerment",
  SustainableFoodSystems: "Food security and sustainable food systems",
  SustainableWaterManagement: "Sustainable water and wastewater management",
  TerrestrialandAcquaticConservation: "Terrestrial and aquatic biodiversity conservation"
};

export const UserStatusEnumDescriptions = {
  Confirmed: null,
  Disabled: null,
  Pending: null
};

export const ValuationMappingStatusDescriptions = {
  Active: null,
  Deleted: null
};

export const permissionTypeEnumDescriptions = {
  Read: null,
  ReadWrite: null
};