import React, { ReactElement } from "react";
import { gql, useQuery } from "urql";
import stableStringify from "json-stable-stringify";
import { isValidMongoDBObjectID } from "../../../../components/src/common/Utils";
import { useParams } from "react-router-dom";

const SCHEDULER_JOB_RUNS = gql`
    query schedulerJobRuns($filter: SchedulerJobRunFilterInput!) {
        schedulerJobRuns(filter: $filter) {
            log
        }
    }
`;

export const JobRunPage = (): ReactElement => {
    const params: any = useParams();
    let id = "000000000000000000000000";
    if (isValidMongoDBObjectID(params.id)) {
        id = params.id;
    }
    const [{ fetching, error, data }] = useQuery({ query: SCHEDULER_JOB_RUNS, variables: { filter: { idIn: id } } });

    console.log(data);
    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    if (!isValidMongoDBObjectID(params.id)) {
        return <p>error: not a valid objectId</p>;
    }

    return <pre>{stableStringify(data.schedulerJobRuns, { space: "  " })}</pre>;
};
