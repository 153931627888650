import React, { Fragment, useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { sortBy, cloneDeep, keyBy } from "lodash";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { YesNoModal } from "../components/YesNoModal";
import {
    TextField,
    SubmitButton,
    SelectField,
    CheckboxItem,
    DateField,
    TextAreaField,
    SearchListField
} from "../../../components/src/form";
import { useAlertTimeOut, usePrevious } from "../../../components/src/common/Utils";

enum ScreenType {
    IssuerProgram = "IssuerProgram",
    Issuer = "Issuer"
}

interface FormData {
    _id?: string;
    issuerId: string; // should not be sent to backend
    objectId?: string; // from client or issuerprogram collection
    clientId?: string;
    screenType?: ScreenType;
    approved?: boolean;
    date?: string;
    comment?: string;
    status?: string;
}

const today = new Date().toISOString().slice(0, 10);

const defaultFormData: FormData = {
    _id: "000000000000000000000000",
    issuerId: "000000000000000000000000",
    objectId: "000000000000000000000000",
    clientId: "000000000000000000000000",
    screenType: ScreenType.Issuer,
    approved: false,
    date: today,
    comment: "",
    status: "Active"
};

const GET_SCREENS = gql`
    query screens($filter: FilterInput) {
        screens(filter: $filter) {
            _id
            objectId
            screenType
            clientId
            approved
            date
            comment
            status
            issuer {
                _id
            }
        }
    }
`;

const CREATE_SCREEN = gql`
    mutation createScreen($input: ScreenInput!) {
        create: createScreen(input: $input) {
            _id
            objectId
            screenType
            clientId
            approved
            date
            comment
            status
            issuer {
                _id
            }
        }
    }
`;

export const UPDATE_SCREEN = gql`
    mutation updateScreen($_id: GraphQLObjectId!, $input: ScreenInput!) {
        update: updateScreen(_id: $_id, input: $input) {
            _id
            objectId
            screenType
            clientId
            approved
            date
            comment
            status
            issuer {
                _id
            }
        }
    }
`;

const GET_DATA = gql`
    query getData {
        issuers: parties(filter: { typeIn: [Issuer] }) {
            _id
            name
        }
        issuerprograms: issuerprograms {
            _id
            name
            issuerId
        }
        clients: parties(filter: { typeIn: [Fund, Client] }) {
            _id
            name
        }
    }
`;

interface Props {
    refetch?: () => void;
}

export function ScreenPage(props: Props): React.ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const { id }: any = useParams();
    const previousId = usePrevious(id);

    let screenId = "000000000000000000000000";

    if (id && id !== "new") {
        screenId = id;
    }

    const [{ fetching: loadingScreen, error: errorScreen, data: dataScreen }, refetch] = useQuery({
        query: GET_SCREENS,
        variables: { filter: { idIn: [screenId] } },
        requestPolicy: "network-only"
    });
    const [{ fetching: loading, error, data }] = useQuery({ query: GET_DATA });

    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [formData, setFormData] = useState(null);
    const [__stateCreate, createMutation] = useMutation(CREATE_SCREEN);
    const [__stateUpdate, updateMutation] = useMutation(UPDATE_SCREEN);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });

    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (previousId !== id) {
            setFormData(null);
        } else if (dataScreen) {
            if (formData === null) {
                if (id && id !== "new" && dataScreen.screens && dataScreen.screens.length > 0) {
                    const screen: FormData = {
                        _id: dataScreen.screens[0]._id,
                        issuerId: dataScreen.screens[0].issuer._id,
                        objectId: dataScreen.screens[0].objectId,
                        clientId: dataScreen.screens[0].clientId,
                        screenType: dataScreen.screens[0].screenType,
                        approved: dataScreen.screens[0].approved,
                        date: dataScreen.screens[0].date,
                        comment: dataScreen.screens[0].comment,
                        status: dataScreen.screens[0].status
                    };
                    setFormData(screen);
                } else {
                    setFormData(cloneDeep(defaultFormData));
                }
            }
        }
    }, [previousId, id, loading, error, formData, dataScreen]);

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );
    if (error)
        return (
            <div className="loader">
                <h3>Failed loading data</h3>
            </div>
        );

    if (loadingScreen) return <p>Loading</p>;
    if (errorScreen) return <p>error: {JSON.stringify(errorScreen, null, 2)}</p>;

    if (screenId !== "000000000000000000000000" && !dataScreen.screens[0])
        return (
            <div className="loader">
                <h3>No screen with that id</h3>
            </div>
        );
    let clients;

    if (data && data.clients) {
        clients = data.clients.map((c) => {
            return { value: c.name, name: c.name, _id: c._id, key: c._id };
        });
    } else {
        clients = [];
    }
    if (!formData) return <div></div>;

    let isCreateMode = false;
    if (id === "new") {
        isCreateMode = true;
    }

    const pageTitle = isCreateMode ? "New screen" : "Edit screen";
    const submitButtonLabel = isCreateMode ? "Create" : "Update";

    const issuerprogramById = {};

    let issuerprograms = [];
    for (let i = 0; i < data.issuerprograms.length; i++) {
        const issuerprogram = data.issuerprograms[i];
        if (formData.screenType === ScreenType.IssuerProgram) {
            if (formData.issuerId === issuerprogram.issuerId) {
                issuerprogramById[issuerprogram._id] = { name: issuerprogram.name, _id: issuerprogram._id };
                issuerprograms.push({
                    value: issuerprogram.name,
                    name: issuerprogram.name,
                    _id: issuerprogram._id,
                    key: issuerprogram._id
                });
            }
        } else {
            issuerprogramById[issuerprogram._id] = { name: issuerprogram.name, _id: issuerprogram._id };
            issuerprograms.push({ value: issuerprogram.name, name: issuerprogram.name, _id: issuerprogram._id, key: issuerprogram._id });
        }
    }

    issuerprograms = sortBy(issuerprograms, "name");
    issuerprograms = [{ key: "000000000000000000000000", name: "None", value: "None", _id: "000000000000000000000000" }, ...issuerprograms];

    const issuerById = keyBy(data.issuers, "_id");

    //console.log("issuerprograms: ", issuerprograms);
    //console.log("issuerprogramById: ", issuerprogramById);
    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete screen with id " + modal.payload.id + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        let mutationData;
                        updateMutation({ _id: modal.payload.id, input: modal.payload.input })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    mutationData = result.data.update;
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The screen with id '${modal.payload.id}' was updated and deleted successfully!`
                                    });
                                }
                            })
                            .catch((error) => {
                                console.error(error.toString());
                            })
                            .finally(() => {
                                refetch();
                                if (mutationData && mutationData._id) {
                                    setFormData(mutationData);
                                }
                                if (props.refetch) {
                                    props.refetch();
                                }
                            });
                    }}
                />
            ) : null}

            <div className="container page">
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                        <Link to="/screens">Screens</Link>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                        <h3>{pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <Formik
                        enableReinitialize={true}
                        validateOnMount={true}
                        initialValues={formData}
                        validate={(validateFormData) => {
                            const errors: any = {};
                            if (validateFormData.objectId === "00000000000000") {
                                if (validateFormData.screenType === ScreenType.Issuer) {
                                    errors.objectId = "No Issuer selected";
                                } else {
                                    errors.objectId = "No Issuer Program selected";
                                }
                            } else {
                                if (validateFormData.screenType === ScreenType.Issuer) {
                                    if (!(validateFormData.objectId in issuerById)) {
                                        errors.objectId = "No issuer with that id";
                                    }
                                } else {
                                    if (!(validateFormData.objectId in issuerprogramById)) {
                                        errors.objectId = "No issuer program with that id";
                                    }
                                }
                            }
                            //validateFormData.date comes as Date but needs to be YYYY-MM-DD
                            if (validateFormData.date instanceof Date) {
                                validateFormData.date = validateFormData.date.toISOString().slice(0, 10);
                            }
                            setFormData(validateFormData);

                            return Object.keys(errors).length > 0 ? errors : {};
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            const submitValues: FormData = values;

                            const input: any = {
                                objectId: submitValues.objectId,
                                screenType: submitValues.screenType,
                                clientId: submitValues.clientId,
                                approved: submitValues.approved,
                                date: submitValues.date,
                                comment: submitValues.comment,
                                status: submitValues.status
                            };

                            let mutationData: FormData = null;
                            if (isCreateMode) {
                                await createMutation({ input })
                                    .then((result) => {
                                        if (result.error) {
                                            setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                        } else {
                                            mutationData = result.data.create;
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `New screen '${mutationData._id}' created successfully!`
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error.toString());
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                        // redirect to edit Order page on success
                                        if (mutationData && mutationData._id) {
                                            const path = location.pathname.split("/");
                                            path.pop();
                                            path.push(mutationData._id);
                                            navigate(path.join("/"), { replace: true });
                                            refetch();
                                            setFormData(mutationData);
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        }
                                    });
                            } else {
                                if (input.status && input.status === "Deleted") {
                                    setModal({ showModal: true, payload: { input: input, id: id } });
                                } else {
                                    await updateMutation({ _id: id, input })
                                        .then((result) => {
                                            if (result.error) {
                                                setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                            } else {
                                                mutationData = result.data.update;
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `The screen '${id}' updated successfully!`
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            console.error(error.toString());
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            refetch();
                                            if (mutationData && mutationData._id) {
                                                setFormData(mutationData);
                                            }
                                            if (props.refetch) {
                                                props.refetch();
                                            }
                                        });
                                }
                            }
                        }}
                    >
                        {({ isSubmitting, values }) => (
                            <Fragment>
                                <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12">
                                    <Form autoComplete="off">
                                        <div className="form row">
                                            <div className="col-lg-12 col-md-8 col-sm-12 col-xs-12">
                                                <TextField className="" name="_id" label="Id" disabled={true} />
                                                <SelectField
                                                    className=""
                                                    name="clientId"
                                                    label={<Link to={"/parties/" + values.clientId}>ClientId</Link>}
                                                    options={clients}
                                                    disabled={isSubmitting}
                                                />
                                                <CheckboxItem name="approved" label="Approved" />
                                                <SelectField
                                                    name="screenType"
                                                    label="Screen type"
                                                    options={[ScreenType.IssuerProgram, ScreenType.Issuer]}
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                                {values.screenType === ScreenType.Issuer ? (
                                                    <div>
                                                        <SearchListField
                                                            className=""
                                                            name="objectId"
                                                            label={<Link to={"/parties/" + values.objectId}>Issuer</Link>}
                                                            options={data.issuers}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div>
                                                            <SearchListField
                                                                className=""
                                                                name="issuerId"
                                                                label={<Link to={"/parties/" + values.objectId}>Issuer</Link>}
                                                                options={data.issuers}
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                        <div>
                                                            <SelectField
                                                                className=""
                                                                name="objectId"
                                                                label={
                                                                    <Link to={"/issuerprograms/" + values.objectId}>Issuer program</Link>
                                                                }
                                                                options={issuerprograms}
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <DateField className="" name="date" label="Date" disabled={isSubmitting} />
                                                <TextAreaField
                                                    name="comment"
                                                    label="Comment"
                                                    className=""
                                                    disabled={isSubmitting}
                                                    rows={4}
                                                    cols={30}
                                                />
                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    options={["Active", "Deleted"]}
                                                    className=""
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                        </div>

                                        <SubmitButton disabled={isSubmitting} label={submitButtonLabel} />

                                        {alert.visible ? (
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                    </Form>
                                </div>
                            </Fragment>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
