import React from "react";
import Plotly from "plotly.js-finance-dist"; //This is the plotly we use
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { PlotlyDefaults } from "../index";

const Plot = createPlotlyComponent(Plotly);

interface ISustainableDevelopmentGoalsProps {
    sustainableDevelopmentGoals: any;
    totalExposure: number;
    layout?: Partial<Plotly.Layout>;
    config?: PartialDeep<Plotly.Config>;
    style?: React.CSSProperties | undefined;
}

const SustainableDevelopmentGoalUrls = {
    Goal1_NoPoverty: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_1",
    Goal2_ZeroHunger: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_2",
    Goal3_GoodHealthAndWellBeing: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_3",
    Goal4_QualityEducation: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_4",
    Goal5_GenderEquality: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_5",
    Goal6_CleanWaterAndSanitation: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_6",
    Goal7_AffordableAndCleanEnergy: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_7",
    Goal8_DecentWorkAndEconomicGrowth: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_8",
    Goal9_IndustryInnovationAndInfrastructure: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_9",
    Goal10_ReducedInequality: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_10",
    Goal11_SustainableCitiesAndCommunities: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_11",
    Goal12_ResponsibleConsumptionAndProduction: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_12",
    Goal13_ClimateAction: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_13",
    Goal14_LifeBelowWater: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_14",
    Goal15_LifeOnLand: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_15",
    Goal16_PeaceJusticeAndStrongInstitutions: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_16",
    Goal17_PartnershipsToAchieveTheGoal: "https://en.wikipedia.org/wiki/Sustainable_Development_Goal_17"
};

export const SustainableDevelopmentGoals = ({
    sustainableDevelopmentGoals,
    totalExposure,
    layout,
    config,
    ...props
}: ISustainableDevelopmentGoalsProps): React.ReactElement => {
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const localLayout: Partial<Plotly.Layout> = {
        autosize: false,
        yaxis: {
            showticklabels: true,
            automargin: true,
            ticktext: Object.keys(sustainableDevelopmentGoals),
            tickvals: Object.keys(sustainableDevelopmentGoals).map((d, i) => i),
            showgrid: false
        },
        xaxis: {
            tickformat: ".0%",
            hoverformat: ".2%"
        },
        width: 460,
        height: 250,
        margin: { t: 50 },
        hovermode: "closest"
    };

    let thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);
    if (layout) {
        thisLayout = PlotlyDefaults.mergeLayout(thisLayout, layout);
    }

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    let thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    if (config) {
        thisConfig = PlotlyDefaults.mergeConfig(thisConfig, config);
    }

    return (
        <div>
            <h3>Sustainable Development Goals</h3>
            <Plot
                onClick={(data: any) => {
                    if (data && data.points && data.points.length > 0) {
                        const label = data.points[0].label;
                        if (SustainableDevelopmentGoalUrls[label]) {
                            const win = window.open(SustainableDevelopmentGoalUrls[label], "_blank");
                            win.focus();
                        }
                    }
                }}
                data={[
                    {
                        y: Object.keys(sustainableDevelopmentGoals),
                        x: Object.values(sustainableDevelopmentGoals).map((d: any) => d / totalExposure),
                        marker: {
                            color: [
                                "#e5243b",
                                "#DDA63A",
                                "#4C9F38",
                                "#C5192D",
                                "#FF3A21",
                                "#26BDE2",
                                "#FCC30B",
                                "#A21942",
                                "#FD6925",
                                "#DD1367",
                                "#FD9D24",
                                "#BF8B2E",
                                "#3F7E44",
                                "#0A97D9",
                                "#56C02B",
                                "#00689D",
                                "#19486A"
                            ]
                        },
                        type: "bar",
                        orientation: "h"
                    }
                ]}
                layout={thisLayout}
                config={thisConfig}
                {...props}
            />
        </div>
    );
};
